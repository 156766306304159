import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Menu,
  rem,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconSearch,
  IconCalendar,
  IconCheck,
  IconClipboardList,
  IconEdit,
  IconCalendarStats,
  IconCaretDown,
  IconFileExport,
  IconFileUpload,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import "../../../Styles/tab.component.css";
import { TblWarrantyServiceRequestFormDetail } from "../../../model/TblWarrantyServiceRequestForm";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { DatePickerInput } from "@mantine/dates";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import AssignWarrantyCompany from "./AssignWarrantyCompany";
import { modals } from "@mantine/modals";
import UpdateAssignWarrantyCompany from "./UpdateAssignWarrantyCompany";
import { useLocation } from "react-router-dom";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import {
  getBranchSelect,
  getTblDMBrandSelect,
} from "../../../service/getSelectApi";
import { green } from "../../../const/variables";
import { exportDataToExcel } from "../../../common/ExportExcelToAPI/ExportExcelToAPI";

const WarrantyCompany = () => {
  //data and fetching state
  const location = useLocation();
  const [dataProps, setDataProps] = useState([]);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarrantyServiceRequestFormDetail[]>([]);
  const [, setDataTemp] = useState<TblWarrantyServiceRequestFormDetail[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [dataCustomerSelect, setDataCustomerSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataEmployee, setDataEmployee] = useState<any[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBrandSelect, setDataBrandSelect] = useState<ComboboxItem[]>([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [keySearch, setKeySearch] = useState<any>({
    startDate: "",
    endDate: "",
    status: "",
    processingMethod: "",
    employeeIncharge: "",
    custId: "",
    searchAll: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "deadline",
        header: "Hạn xử lý",
        Cell: ({ row }) => (
          <Badge
            color={handleColorDeadline(
              row.original.deadline !== null
                ? new Date(row.original.deadline)
                : null,
              row.original.itemStatus,
              row.original.brandId,
              row.original.createDate
            )}
            radius={"sm"}
          >
            {row.original.deadline && formatDateTransfer(row.original.deadline)}
          </Badge>
        ),
      },
      {
        accessorKey: "requestNum",
        header: "Số phiếu yêu cầu",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
        size: 200,
      },
      {
        accessorKey: "createDate",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "orderCode",
        header: "Số chứng từ mua",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {row.original.itemName}
          </Text>
        ),
        size: 700,
      },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng",
        enableColumnActions: false,
        Cell: ({ row }) => <AvatarUtils value={row.original.custMeeting} />,
        size: 225,
      },
      {
        accessorKey: "custPhone",
        header: "SĐT",
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ",
        size: 550,
      },
      {
        accessorKey: "content",
        header: "Nội dung",
      },
      {
        accessorKey: "scheduleTime",
        header: "Ngày hẹn",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "empCallName",
        header: "Nhân viên liên hệ",
        Cell: ({ row }) => <AvatarUtils value={row.original.empCallName} />,
      },
      {
        accessorKey: "dateCall",
        header: "Thời gian liên hệ",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "brandId",
        header: "Hãng",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              row.original.brandId?.toString() ?? "",
              dataBrandSelect,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "empBrand",
        header: "Nhân viên hãng nhận",
        Cell: ({ row }) => <AvatarUtils value={row.original.empBrand} />,
      },
      {
        accessorKey: "phoneBrand",
        header: "SĐT hãng",
      },
      {
        accessorKey: "addressBrand",
        header: "Địa chỉ hãng",
      },
      {
        accessorKey: "noteBrand",
        header: "Hãng ghi chú",
      },
      {
        accessorKey: "branchId",
        header: "Nơi nhận yêu cầu",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              row.original.branchId?.toString(),
              dataBranchSelect,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "noteAssignRequest",
        header: "Ghi chú phân công",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableResizing: false,
        Cell: ({ row }) => (
          <Flex gap={"xs"}>
            <Tooltip label="Liên hệ NCC">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="teal"
                onClick={() => {
                  modalAssignment(
                    row.original.detailId,
                    row.original.branchId,
                    row.original.id,
                    row.original.orderCode
                  );
                  setDataProps([]);
                }}
                disabled={row.original.brandId}
              >
                <IconClipboardList size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Sửa liên hệ">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={() => {
                  modalUpdateAssignment(
                    row.original.detailId,
                    row.original.branchId,
                    row.original.id
                  );
                  setDataProps([]);
                }}
                disabled={row.original.brandId === null}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ),
        size: 85,
      },
    ],
    [dataBrandSelect]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleColorDeadline = (
    value: string | Date | null,
    status: string,
    brandId: number,
    createDate: string
  ) => {
    if (value === null) {
      return "rgba(255, 255, 255, 0)";
    }

    const newDate = new Date();
    const deadlineDate = typeof value === "string" ? new Date(value) : value;
    const timeCreateDate =
      typeof createDate === "string" ? new Date(createDate) : createDate;

    const diffInTime = deadlineDate.getTime() - newDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    const timeCheckAssign = newDate.getTime() - timeCreateDate.getTime();
    const dayCheckAssign = timeCheckAssign / (1000 * 3600 * 24);

    if (dayCheckAssign > 3 && brandId === null) {
      return "#7b2eda";
    }

    if (status === "TRA_KHACH") {
      return "#388E3C";
    } else {
      if (diffInDays < 3 && diffInDays > 0 && brandId !== null) {
        return "yellow";
      } else if (diffInDays < 0 && brandId !== null) {
        return "red";
      } else if (
        diffInDays >= 3 ||
        value === null ||
        value.toString().length < 1
      ) {
        return "#339AF0";
      }
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData: any) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData: any) => ({ ...prevData, [key]: "" }));
    }
  };

  function modalAssignment(
    id: string | number,
    branchId: number,
    detailId: number,
    orderCode: string
  ) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thông tin liên hệ nhà cung cấp!</Title>
        </>
      ),
      size: "auto",
      children: (
        <AssignWarrantyCompany
          id={id}
          detailId={detailId}
          onClose={setDeleteViewStatus}
          branchIdProp={branchId}
          orderCode={orderCode}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function modalUpdateAssignment(
    id: string | number,
    branchId: number,
    detailId: number
  ) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa thông tin liên hệ nhà cung cấp!</Title>
        </>
      ),
      size: "auto",
      children: (
        <UpdateAssignWarrantyCompany
          id={id}
          detailId={detailId}
          onClose={setDeleteViewStatus}
          branchIdProp={branchId}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.startDate) {
      url += `&StartDate=${keySearch.startDate}`;
    }

    if (keySearch.endDate) {
      url += `&EndDate=${keySearch.endDate}`;
    }

    if (keySearch.processingMethod) {
      url += `&ProcessingMethod=${keySearch.processingMethod}`;
    }

    if (keySearch.status) {
      url += `&RequestStatus=${keySearch.status}`;
    }

    if (keySearch.employeeIncharge) {
      url += `&EmployeeIncharge=${keySearch.employeeIncharge}`;
    }

    if (keySearch.custId) {
      url += `&CustId=${keySearch.custId}`;
    }

    if (keySearch.searchAll) {
      url += `&KeySearch=${keySearch.searchAll}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblWarrantyServiceRequestFormDetail[]>
      >(`/api/v1/TblWarrantyServiceRequestForm/get-request-by-vendor${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        await Promise.all([getDataBrandSelect(), getDataBranchSelect()]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const handleExport = async () => {
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.startDate) {
      url += `&StartDate=${keySearch.startDate}`;
    }

    if (keySearch.endDate) {
      url += `&EndDate=${keySearch.endDate}`;
    }

    if (keySearch.processingMethod) {
      url += `&ProcessingMethod=${keySearch.processingMethod}`;
    }

    if (keySearch.status) {
      url += `&RequestStatus=${keySearch.status}`;
    }

    if (keySearch.employeeIncharge) {
      url += `&EmployeeIncharge=${keySearch.employeeIncharge}`;
    }

    if (keySearch.custId) {
      url += `&CustId=${keySearch.custId}`;
    }

    if (keySearch.searchAll) {
      url += `&KeySearch=${keySearch.searchAll}`;
    }

    const baseUrl = `https://apis-pos-dev.hacom.vn/api-mdm/api/v1/ExportExcel/export-tbl-warranty-service-request-form-by-brand${url}`;
    const fileName = "danh_muc_theo_doi_hang_xu_ly.xlsx";

    await exportDataToExcel(baseUrl, fileName);
  };

  const fetchDataCustomer = async () => {
    let url = `?Skip=0&Take=${100}`;

    if (customerId) {
      url += `&KeySearch=${customerId.trim()}`;
    }

    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmCustomer/get-customer-for-delivery${url}`
      );
      if (response && response.success) {
        const filteredData = response.data.lists.filter(
          (customer: any) =>
            customer.customerCode !== null || customer.customerName !== null
        );
        const mappedData = filteredData.map((item: any) => ({
          value: item.customerId.toString(),
          label: item.customerName,
        }));
        setDataCustomerSelect(mappedData);
      }
    } catch (error) {
      setDataCustomerSelect([]);
      return;
    }
  };

  const getDataBrandSelect = async () => {
    const getData = await getTblDMBrandSelect();
    setDataBrandSelect(
      getData
        ?.filter((item: any) => item.value !== null || item.text !== null)
        .map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataBranchSelect = async () => {
    const getData = await getBranchSelect();
    setDataBranchSelect(
      getData
        ?.filter(
          (item: any) =>
            item.value !== null || item.text !== null || item.value !== "-1"
        )
        .map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (162.5 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  useEffect(() => {
    if (customerId && customerId.trim().length > 2) {
      const delayDebounce = setTimeout(() => {
        fetchDataCustomer();
      }, 300);

      return () => clearTimeout(delayDebounce);
    } else {
      setDataCustomerSelect([]);
    }
  }, [customerId]);

  useEffect(() => {
    if (location.state?.selectIds) {
      setDataProps(location.state.selectIds);
    }
  }, [location.state]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex w={"100%"} justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex gap={"sm"} align={"center"} wrap={"wrap"}>
            <Text fw={500} size="12.5px">
              Hạn xử lý:
            </Text>
            <Badge color="red" />
            <Text fw={500} size="12.5px">
              Quá hạn
            </Text>
            <Badge color="#339AF0" />
            <Text fw={500} size="12.5px">
              Chưa đến hạn
            </Text>
            <Badge color="#7b2eda" />
            <Text fw={500} size="12.5px">
              Chưa phân công
            </Text>
            <Badge color="yellow" />
            <Text fw={500} size="12.5px">
              Sắp đến hạn
            </Text>
            <Badge color="#388E3C" />
            <Text fw={500} size="12.5px">
              Đã xử lý
            </Text>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={() => handleExport()}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Từ ngày - Đến ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "startDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Hạn xử lý"
              leftSection={<IconCalendarStats color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              // onChange={(e) => {
              //   handleChangeSearchValue(
              //     formatDateNotTimeZone(e[0]) ?? "",
              //     "startDate"
              //   );
              //   handleChangeSearchValue(
              //     formatDateNotTimeZone(e[1]) ?? "",
              //     "endDate"
              //   );
              // }}
              clearable
            />
          </Grid.Col>
          {/* <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Người nhận phân công"
              data={dataEmployeeSelect}
              leftSection={<IconUserCode color="#15aabf" />}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "employeeIncharge")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Khách hàng"
              data={dataCustomerSelect}
              leftSection={<IconUsers color="#15aabf" />}
              type="text"
              searchable
              clearable
              searchValue={customerId ?? ""}
              onSearchChange={setCustomerId}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "custId")
              }
              comboboxProps={{ width: 325 }}
              nothingFoundMessage={
                customerId && customerId?.length > 2
                  ? "Không tìm thấy dự liệu !"
                  : "Vui lòng nhập tối thiểu 3 kí tự !"
              }
            />
          </Grid.Col> */}
          <Grid.Col span={{ base: 6, md: 6, lg: 2.4 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(
                  e.currentTarget.value ?? "",
                  "searchAll"
                )
              }
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  await fetchData();
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.5 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineSelectAllCheckboxProps: {
      style: {
        display: "none",
      },
    },
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "requestNum"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default WarrantyCompany;
