export const BASE_API_URL = process.env.REACT_APP_Demo_APP_API_URL;
export const BASE_FE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const BASE_API_AUTH_URL = process.env.REACT_APP_Auth_APP_API_URL;
export const BASE_API_POS = process.env.REACT_APP_Pos_APP_API_URL;
export const BASE_API_MDM = process.env.REACT_APP_MDM_APP_API_URL;
export const BASE_API_DELIVERY = process.env.REACT_APP_DELIVERY_API_URL;
export const BASE_API_SCA = process.env.REACT_APP_SCA_API_URL;
export const BASE_API_GHTK = process.env.REACT_APP_GHTK_API_URL;
export const BASE_API_Log = process.env.REACT_APP_Log_APP_API_URL;
export const BASE_API_PAYMENT = process.env.REACT_APP_PAYMENT_API_URL;
export const BASE_FE_SUPABASE = process.env.REACT_APP_SUPABASE;
export const BASE_SSO = process.env.REACT_APP_SSO_URL;
export const Version = process.env.REACT_APP_Version;
export const HashCommit = process.env.REACT_APP_HashCommit;
export const DateBuild= process.env.REACT_APP_DateBuild;
