import { Badge, Group } from "@mantine/core";
import {
  IconCheck,
  IconClock,
  IconTransferOut,
  IconTransformFilled,
  IconX,
} from "@tabler/icons-react";
import { olive_green } from "../../const/variables";

// Define the possible status values
type OrderStatusType =
  | "pending"
  | "completed"
  | "canceled"
  | "unknown"
  | "noCreate"
  | string;

type LineType = "TANG" | "CHINH" | "MUA_KEM" | string;

// Props interface for OrderStatus component
interface OrderStatusProps {
  status: OrderStatusType;
}
interface LineTypeProps {
  status: LineType;
}

// OrderStatus component
export const LineType: React.FC<LineTypeProps> = ({ status }) => {
  let statusInfo: {
    color: string;
    text: string;
  };

  switch (status) {
    case "KM":
      statusInfo = {
        color: "red",
        text: "Quà tặng",
      };
      break;
    case "HH":
      statusInfo = {
        color: "green",
        text: "SP chính",
      };
      break;
    case "HK":
      statusInfo = {
        color: "yellow",
        text: "Mua kèm",
      };
      break;
    case "HD":
      statusInfo = {
        color: "indigo",
        text: "Hàng đổi",
      };
      break;
    case "HT":
      statusInfo = {
        color: "grape",
        text: "Hàng trả",
      };
      break;
    case "DV":
      statusInfo = {
        color: "blue",
        text: "Dịch vụ",
      };
      break;
    default:
      statusInfo = {
        color: "gray",
        text: "",
      };
  }

  return (
    <Group justify="center">
      {statusInfo.color != "gray" && (
        <Badge color={statusInfo.color} size="md">
          {statusInfo.text}
        </Badge>
      )}
    </Group>
  );
};

// OrderStatus component
export const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
  let statusInfo: {
    color: string;
    text: string;
    icon: React.ReactNode;
  };

  switch (status) {
    case "DON_HANG_TAM":
      statusInfo = {
        color: "yellow",
        text: "Đơn hàng tạm",
        icon: <IconClock size={18} />,
      };
      break;
    case "DON_HANG_CHOT":
      statusInfo = {
        color: "violet",
        text: "Đơn hàng chốt",
        icon: <IconClock size={18} />,
      };
      break;
    case "KHO_XUAT_HANG":
      statusInfo = {
        color: "blue",
        text: "Kho xuất hàng",
        icon: <IconTransferOut size={18} />,
      };
      break;
    case "DON_HANG_HOAN_THANH":
      statusInfo = {
        color: "green",
        text: "Đơn hàng hoàn thành",
        icon: <IconCheck size={18} />,
      };
      break;
    case "DON_HANG_HUY":
      statusInfo = {
        color: "red",
        text: "Đã hủy",
        icon: <IconX size={18} />,
      };
      break;
    case "DON_HANG_DONG":
      statusInfo = {
        color: "red",
        text: "Đã đóng",
        icon: <IconX size={18} />,
      };
      break;
    case "DON_HANG_DOI_TRA_TAM":
      statusInfo = {
        color: "cyan",
        text: "Đơn đổi trả tạm",
        icon: <IconTransformFilled size={18} />,
      };
      break;
    case "DON_HANG_DOI_TRA":
      statusInfo = {
        color: "green",
        text: "Đơn đổi trả",
        icon: <IconTransformFilled size={18} />,
      };
      break;
    case "noCreate":
      statusInfo = {
        color: "Grey",
        text: "Đơn hàng chưa lưu",
        icon: <IconX size={18} />,
      };
      break;
    case "DON_HANG_CHO_DUYET_GIAM":
      statusInfo = {
        color: olive_green.light,
        text: "Đơn chờ duyệt giảm",
        icon: <IconX size={18} />,
      };
      break;
    case "DON_HANG_DA_DUYET_GIAM":
      statusInfo = {
        color: olive_green.medium,
        text: "Đơn đã duyệt giảm",
        icon: <IconX size={18} />,
      };
      break;
    default:
      statusInfo = {
        color: "gray",
        text: "Không xác định",
        icon: null,
      };
  }

  return (
    <Group justify="center">
      <Badge color={statusInfo.color} size="lg" leftSection={statusInfo.icon}>
        {statusInfo.text}
      </Badge>
    </Group>
  );
};

export const PaymentStatus: React.FC<OrderStatusProps> = ({ status }) => {
  let statusInfo: {
    color: string;
    text: string;
    icon: React.ReactNode;
  };

  switch (status) {
    case "DONE":
      statusInfo = {
        color: "green",
        text: "Thanh toán thành công",
        icon: <IconCheck size={18} />,
      };
      break;
    default:
      statusInfo = {
        color: "gray",
        text: "Không xác định",
        icon: null,
      };
  }

  return (
    <Group justify="center">
      <Badge color={statusInfo.color} size="lg" leftSection={statusInfo.icon}>
        {statusInfo.text}
      </Badge>
    </Group>
  );
};
