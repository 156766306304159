import {
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Text,
  Select,
  TextInput,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconArrowBackUp, IconCheck } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblOutwardWarrantyVendor } from "../../../model/TblOutWard";
import { useForm } from "@mantine/form";
import { DatePickerInput } from "@mantine/dates";
import { getTblDmDeliverPrioritySelect } from "../../../service/getSelectApi";

const ReturnCustomers = ({
  dataRowProps,
  handleChange,
  handleWarrantyOutward,
  id,
}: {
  dataRowProps: any;
  handleChange: any;
  handleWarrantyOutward: any;
  id: any;
}) => {
  const entity = {
    createDate: new Date().toISOString(),
    createBy: Number(
      JSON.parse(localStorage.getItem("userLogin") || "{}")?.id || ""
    ),
    customerId: null,
    customerName: null,
    invId: dataRowProps[0]?.invOrgId,
    priority: null,
    departmentId: "KHONG",
    telephoneNumber: null,
    deliveryAppointmentTime: new Date()?.toISOString(),
    fromBranchAddress: null,
    customerProvinceId: null,
    customerDistrictId: null,
    customerCommuneId: null,
    customerStreetId: null,
    customerAddress: null,
    toAddress: null,
    description: null,
    content: null,
  };

  const form = useForm<TblOutwardWarrantyVendor>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {},
  });

  const [dataDeliverPriority, setDataDeliverPriority] = useState<
    ComboboxItem[]
  >([]);

  const [isShipping, setIsShipping] = useState<boolean>(false);

  const columnsProduct = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "soItemCode",
        header: "Mã sản phẩm",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "soItemName",
        header: "Tên sản phẩm",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "soSerial",
        header: "Serial",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.original.newSerial !== null
              ? row.original.newSerial
              : row.original.soSerial}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "warrantyItemNew",
        header: "Hạn BH mới",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "soSerialStatus",
        header: "Trạng thái serial",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "invOrgName",
        header: "Kho",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
    ],
    [dataRowProps]
  );

  const getDataRequestForm = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblWarrantyServiceRequestForm/detail?id=${id}`
    );

    if (res && res?.success) {
      const result = res.data;
      form.setValues((prev) => ({
        ...prev,
        customerName: result?.custMeeting,
        telephoneNumber: result?.custPhone,
        customerProvinceId: result?.provinceId,
        customerDistrictId: result?.districtId,
        customerCommuneId: result?.communeId,
        customerStreetId: result?.streetId,
        customerAddress: result?.additionalAddress,
        toAddress: result?.meetingAddress,
      }));
    }
  };

  const getDataDeliverPrioritySelect = async () => {
    const res = await getTblDmDeliverPrioritySelect();
    setDataDeliverPriority(
      res
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      getDataRequestForm();
    }
  }, [id]);

  const tableProduct = useMantineReactTable({
    columns: columnsProduct,
    data: dataRowProps,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    renderTopToolbarCustomActions: ({ table }) => <></>,
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 240, minHeight: 240 },
    },
    enableStickyHeader: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <Box
      pt={10}
      component="form"
      mx="auto"
      w={"82.5vw"}
      maw={1250}
      onSubmit={form.onSubmit((e: TblOutwardWarrantyVendor) => {
        handleWarrantyOutward(isShipping, e);
      })}
    >
      <Grid>
        <Grid.Col span={{ base: 12, md: 12, lg: 4.5 }}>
          <Fieldset legend="Thông tin khách hàng" p={"5px 15px 10px 15px"}>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="Tên khách hàng hẹn gặp"
                  value={form.getValues()?.customerName ?? ""}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="Số điện thoại"
                  value={form.getValues()?.telephoneNumber ?? ""}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="Địa chỉ"
                  value={form.getValues()?.toAddress ?? ""}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 7.5 }}>
          <Fieldset legend="Thông tin xuất trả khách" p={"5px 15px 10px 15px"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                <DatePickerInput
                  label="Ngày xuất trả"
                  value={
                    form.getValues().createDate
                      ? new Date(form.getValues().createDate ?? "")
                      : null
                  }
                  valueFormat="DD-MM-YYYY"
                  variant="filled"
                  readOnly
                  {...form.getInputProps("createDate")}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                <TextInput
                  label="Nhân viên xuất trả"
                  value={`${
                    JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
                      ?.code || ""
                  } - ${
                    JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
                      ?.fullname || ""
                  }`}
                  variant="filled"
                  readOnly
                  {...form.getInputProps("createBy")}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                <DatePickerInput
                  label="Ngày xuất trả"
                  placeholder="Ngày xuất trả"
                  valueFormat="DD-MM-YYYY"
                  minDate={new Date()}
                  value={
                    form.getValues().deliveryAppointmentTime
                      ? new Date(
                          form.getValues()?.deliveryAppointmentTime ?? ""
                        )
                      : null
                  }
                  {...form.getInputProps("deliveryAppointmentTime")}
                  onChange={(e) =>
                    form.setValues((prev) => ({
                      ...prev,
                      deliveryAppointmentTime: e
                        ? new Date(e ?? "")?.toISOString()
                        : null,
                    }))
                  }
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                <Select
                  label="Bộ phận giao hàng"
                  placeholder="Nhập bộ phận giao hàng"
                  data={[
                    { label: "Không", value: "KHONG" },
                    { label: "Nội bộ", value: "NOI_BO" },
                    { label: "GHTK", value: "GHTK" },
                    { label: "Nhất tín", value: "NHAT_TIN" },
                    { label: "SCA", value: "SCA" },
                    { label: "Tín thành", value: "TIN_THANH" },
                    { label: "Viettel", value: "VIETTEL" },
                    { label: "VN Post", value: "VN_POST" },
                  ]}
                  value={form.getValues().departmentId}
                  searchable
                  nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                  withAsterisk
                  {...form.getInputProps("departmentId")}
                  onChange={(e) => {
                    form.setValues((prev) => ({
                      ...prev,
                      departmentId: e ? e : "KHONG",
                      priority: e !== "NOI_BO" ? null : prev.priority,
                    }));
                    setIsShipping(e === "NOI_BO" ? true : false);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                <Select
                  label="Mức độ ưu tiên"
                  placeholder="Nhập mức độ ưu tiên"
                  data={dataDeliverPriority}
                  value={
                    form.getValues().priority
                      ? form.getValues()?.priority?.toString()
                      : null
                  }
                  searchable
                  clearable
                  nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                  disabled={form.getValues().departmentId === "KHONG"}
                  onChange={(e) =>
                    form.setValues((prev) => ({
                      ...prev,
                      priority: e ? e : null,
                    }))
                  }
                  onClick={() => {
                    if (dataDeliverPriority.length === 0) {
                      getDataDeliverPrioritySelect();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                <TextInput
                  label="Nội dung giao hàng"
                  placeholder="Nội dung giao hàng"
                  {...form.getInputProps("content")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="Mô tả"
                  placeholder="Mô tả"
                  {...form.getInputProps("description")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
      </Grid>
      <MantineReactTable table={tableProduct} />
      <Flex justify={"end"} gap={"md"} mt={10}>
        <Button
          color="red"
          leftSection={<IconArrowBackUp size={14} />}
          variant="outline"
          onClick={() => modals.closeAll()}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color="teal"
          leftSection={<IconCheck size={14} />}
          variant="outline"
        >
          Lưu
        </Button>
      </Flex>
    </Box>
  );
};

export default ReturnCustomers;
