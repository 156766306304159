import {
  ActionIcon,
  Badge,
  Box,
  Button,
  FileButton,
  Flex,
  Grid,
  Input,
  Loader,
  LoadingOverlay,
  Menu,
  rem,
  Select,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput, DateTimePicker } from "@mantine/dates";
import { useDisclosure, useHotkeys } from "@mantine/hooks";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  IconBuildingCommunity,
  IconCalendarSearch,
  IconCaretDown,
  IconCheck,
  IconChecklist,
  IconEdit,
  IconEye,
  IconFileDownload,
  IconFileExport,
  IconFileUpload,
  IconIdOff,
  IconNotes,
  IconNumber,
  IconPlus,
  IconReportAnalytics,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { isNullOrUndefinedArry } from "../../../_base/extension/StringExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  getListPriceList,
  updateIsDrapPriceList,
  updateStatusPriceList,
} from "../../../api/apiPriceList";
import { _localization } from "../../../config/location";
import "../../../Styles/tab.component.css";
import { tblPriceList } from "../../../model/TblPriceList";
import DeleteView from "./DeleteView";
import { modals } from "@mantine/modals";
import { getListSelectBranch } from "../../../api/apiDMBranch";
import moment from "moment";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { TblDMInventory } from "../../../model/TblDMInventory";
import {
  formatDates,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { green } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import InputSearch from "../../../common/InputSearch/InputSearch";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { BASE_API_MDM, BASE_API_POS } from "../../../config";

const ListPrice = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const [keySearch, setKeySearch] = useState<string>("");
  const [openedMenu, setOpenedMenu] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [height, setHeight] = useState(0);
  const [dataBranch, setDataBranch] = useState<any[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<TblDMInventory[]>([]);
  const [dataWarehouseLoaded, setDataWarehouseLoaded] = useState(false);
  const [search, setSearch] = useState({
    priceListNum: "",
    listIdBranch: "",
    activeDate: "",
    inactiveDate: "",
    isDrap: "",
    status: "",
    keySearch: "",
  });

  const handleExport = async () => {
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.priceListNum) {
      url += `&PriceListNum=${search.priceListNum}`;
    }
    if (search.listIdBranch) {
      url += `&ListIdBranch=${search.listIdBranch}`;
    }
    if (search.status) {
      url += `&Status=${search.status}`;
    }
    if (search.isDrap) {
      url += `&IsDrap=${search.isDrap}`;
    }
    if (search.activeDate && search.activeDate !== "Invalid date") {
      url += `&ActiveDate=${search.activeDate}`;
      console.log(search.activeDate);
    }
    if (search.inactiveDate && search.inactiveDate !== "Invalid date") {
      url += `&InactiveDate=${search.inactiveDate}`;
    }

    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        url = url + "&sort=" + _q.id + "&isOrder=" + _q.desc;
      }
    }
    // const _url = BASE_API_POS + `/api/v1/TblPriceList/export${url}`;
    const _url = BASE_API_MDM + `/api/v1/ExportExcel/export-bao-cao-bang-gia${url}`;

    const fullUrl = new URL(_url);
    window.open(
      fullUrl.toString(),
      "_blank"
      //"toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600"
    );
  };

  const formatDateString = (isoString: string) => {
    const date = new Date(isoString);
    const adjustedDate = date.setHours(date.getHours() + 7);
    return moment(new Date(adjustedDate)).format("HH:mm:ss DD-MM-YYYY");
  };

  function getColorDrapStatus(text: any) {
    switch (text) {
      case 0:
        return "teal";
      case 1:
        return "yellow";
      default:
        return "blue";
    }
  }

  function getColorStatus(text: any) {
    switch (text) {
      case 1:
        return "teal";
      case 2:
        return "red";
      default:
        return "yellow";
    }
  }

  const [file, setFile] = useState<File | null>(null);
  const [visible, { toggle, open, close }] = useDisclosure(false);

  useEffect(() => {
    if (file) {
      if (
        file &&
        (file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel")
      )
        uploadFile(file);
      else NotificationExtension.Fails("File không đúng định đạng Excel !");
    }
  }, [file]);

  const uploadFile = async (e: File) => {
    if (!e) {
      NotificationExtension.Fails("File rỗng hoặc chưa chọn file !");
      return;
    }
    open();
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    const formData = new FormData();
    formData.append("FormFile", e);

    var resSaveFile = await repositoryDelivery.post<MessageResponse<boolean>>(
      "/api/v1/ImportExelPriceList/import-excel-price-list",
      formData
    );
    if (resSaveFile && resSaveFile.httpStatusCode === 200) {
      if (resSaveFile.success) {
        setFile(null);
        NotificationExtension.Success("Import file thành công!");
        await fetchData();
      } else NotificationExtension.Fails("Import file thất bại !");
    }
    setFile(null);
    close();
  };

  const [data, setData] = useState<tblPriceList[]>([]);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "tblPriceListModel.priceListNum",
        header: "Số bảng giá",
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() =>
                navigate(
                  "/config-system/list-details-price-detail/" +
                    row.original.tblPriceListModel.id
                )
              }
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "tblPriceListModel.description",
        header: "Mô tả",
      },
      {
        accessorKey: "tblPriceListModel.listIdBranch",
        header: "Tên trung tâm",
        Cell: ({ renderedCellValue }: any) => (
          <Box
            w={200}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Tooltip
              label={
                <div
                  dangerouslySetInnerHTML={{
                    __html: getNamesFromIds(renderedCellValue, "col"),
                  }}
                />
              }
              w={200}
              multiline
              withArrow
              position="bottom"
              color="gray"
            >
              <Text style={{ whiteSpace: "normal" }} lineClamp={2}>
                {getNamesFromIds(renderedCellValue, "row")}
              </Text>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "tblPriceListModel.activeDate",
        header: "Ngày hoạt động",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tblPriceListModel.inactiveDate",
        header: "Ngày hết hạn",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tblPriceListModel.status",
        header: "Sử dụng",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(renderedCellValue || 0)}>
              {renderedCellValue === 1
                ? "Đồng ý duyệt"
                : renderedCellValue === 2
                ? "Từ chối duyệt"
                : "Chưa duyệt"}
            </Badge>
          </>
        ),
      },
      // {
      //   accessorKey: "tblPriceListModel.isDrap",
      //   header: "Nháp",
      //   size: 30,
      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <Badge color={getColorDrapStatus(renderedCellValue || 0)}>
      //         {renderedCellValue === 0 ? "Đã xác nhận" : "Bản nháp"}
      //       </Badge>
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "tblPriceListModel.createBy",
      //   header: "Người tạo",
      //   size: 30,
      // },
      {
        accessorKey: "tblPriceListModel.createDate",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      // {
      //   accessorKey: "tblPriceListModel.lastUpdateBy",
      //   header: "Người sửa",
      //   size: 30,
      // },

      {
        accessorKey: "tblPriceListModel.lastUpdateDate",
        header: "Ngày sửa",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      // {
      //   accessorKey: "tblPriceListModel.approveBy",
      //   header: "Người duyệt",
      //   size: 30,
      // },
      {
        accessorKey: "tblPriceListModel.approveDate",
        header: "Ngày duyệt",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 175,
        enableResizing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {processTaskActionMenu(row)}
          </Box>
        ),
      },
    ],
    [data, dataBranch]
  );

  function processTaskActionMenu(row: MRT_Row<any>): any {
    return (
      <>
        <Tooltip label="Sửa bảng giá">
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color="yellow"
            disabled={row.original.tblPriceListModel.status === 1}
            onClick={() =>
              navigate(
                "/config-system/list-details-price-edit/" +
                  row.original.tblPriceListModel.id
              )
            }
          >
            <IconEdit size={20} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xem chi tiết báo giá">
          <ActionIcon variant="light" aria-label="Settings" color="cyan">
            <IconEye
              size={20}
              onClick={() =>
                navigate(
                  "/config-system/list-details-price-detail/" +
                    row.original.tblPriceListModel.id
                )
              }
            />
          </ActionIcon>
        </Tooltip>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={"Duyệt bảng giá"}>
              <ActionIcon variant="light" aria-label="Settings" color="orange">
                <IconStatusChange size={20} />
              </ActionIcon>
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={<IconX />}
              disabled={row.original.tblPriceListModel.status === 0}
              onClick={() => handleEditStatus(row.id, 0)}
            >
              Chưa duyệt
            </Menu.Item>
            <Menu.Item
              leftSection={<IconCheck />}
              disabled={
                row.original.tblPriceListModel.status === 1 ||
                row.original.tblPriceListModel.isDrap === 1
              }
              onClick={() => handleEditStatus(row.id, 1)}
            >
              Đồng ý duyệt
            </Menu.Item>
            <Menu.Item
              leftSection={<IconIdOff />}
              disabled={
                row.original.tblPriceListModel.status === 2 ||
                row.original.tblPriceListModel.isDrap === 1
              }
              onClick={() => handleEditStatus(row.id, 2)}
            >
              Từ chối duyệt
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <Tooltip label="Xóa báo giá">
          <ActionIcon variant="light" aria-label="Settings" color="red">
            <IconTrash
              size={20}
              onClick={() => handleDeletePriceList([row?.id])}
            />
          </ActionIcon>
        </Tooltip>
      </>
    );
  }

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleEditStatus = async (id: number | string, status: number) => {
    const dataEdit = { id: id, status: status };
    const res = await repositoryPos.post<MessageResponse<boolean>>(
      "/api/v1/TblPriceList/update-approve",
      dataEdit
    );
    if (res && res.httpStatusCode === 200) {
      if (res.success) {
        await fetchData();
        NotificationExtension.Success("Thành công!");
      } else NotificationExtension.Fails("Thất bại!");
    }
  };

  const handleEditIsDrap = async (id: number | string, isDrap: number) => {
    const dataEdit = { id: id, drap: isDrap ? 0 : 1 };

    await repositoryPos.post("/api/v1/TblPriceList/update-draft", dataEdit);
    fetchData();
  };

  const handleAddPriceList = (
    id?: number | string,
    isDetail?: boolean | string | null,
    isEdit?: boolean | string | null
  ) => {
    navigate("/config-system/list-details-price/");
  };

  const handleDeletePriceList = (ids: (number | string)[]) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xóa bảng giá</Title>
        </>
      ),
      children: <DeleteView onSearch={() => fetchData()} idItems={ids} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  function getNamesFromIds(idsString: string, form: "row" | "col") {
    // Kiểm tra nếu dataWarehouse chưa được tải
    if (!dataBranch) {
      return "";
    }

    // Tách chuỗi idsString thành mảng các id, loại bỏ khoảng trắng thừa
    const idsArray = idsString?.split(",")?.map((id) => id.trim());
    // Tìm các phần tử trong dataWarehouse có idBranch trùng với các id trong idsArray
    const names = idsArray
      ?.map((id) => {
        const foundItem = dataBranch?.find(
          (item) => item.value.toString() === id.toString()
        );
        return foundItem ? foundItem.text : null;
      })
      .filter((name) => name !== null); // Loại bỏ các giá trị null

    // Ghép các tên thành chuỗi và trả về
    if (form === "row") {
      return names?.join(", ");
    } else if (form === "col") {
      return names?.join("<br>");
    } else {
      throw new Error('Invalid form parameter. Use "row" or "col".');
    }
  }

  useHotkeys([
    // ['mod+J', () => console.log('Toggle color scheme')],
    // ['ctrl+F5', () => { openNew() }],
    // ['F5', () => { openNew() }],
    // ['alt+mod+shift+X', () => console.log('Rick roll')],
    [
      "F11",
      () => {
        handleAddPriceList();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    setData([]);
    setRowCount(0);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.priceListNum) {
      url += `&PriceListNum=${search.priceListNum}`;
    }
    if (search.listIdBranch) {
      url += `&ListIdBranch=${search.listIdBranch}`;
    }
    if (search.status) {
      url += `&Status=${search.status}`;
    }
    if (search.isDrap) {
      url += `&IsDrap=${search.isDrap}`;
    }
    if (search.activeDate && search.activeDate !== "Invalid date") {
      url += `&ActiveDate=${search.activeDate}`;
      console.log(search.activeDate);
    }
    if (search.inactiveDate && search.inactiveDate !== "Invalid date") {
      url += `&InactiveDate=${search.inactiveDate}`;
    }

    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        url = url + "&sort=" + _q.id + "&isOrder=" + _q.desc;
      }
    }

    try {
      let callapi = await repositoryPos.get(
        `/api/v1/TblPriceList/get-list${url}`
      );
      if (isNullOrUndefined(callapi) || isNullOrUndefinedArry(callapi?.data)) {
        setRowCount(0);
        setData([]);
      } else {
        setData(callapi?.data ?? []);

        setRowCount(callapi?.totalCount ?? 0);
        table.resetRowSelection();
      }
      return callapi?.data;
    } catch (error: any) {
      setIsError(true);
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
      setOpenedMenu(true);
    }
  };

  const fetchDataWarehouse = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblDmInventory/get-list?Skip=0&Take=1000`
      );
      if (response && response.success) {
        setDataWarehouse(response.data);
        setDataWarehouseLoaded(true);
      }
    } catch (error) {
      setDataWarehouse([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  // useEffect(() => {
  //   fetchDataWarehouse();
  // }, []);

  useEffect(() => {
    fetchData();
  }, [pagination, sorting]);

  useEffect(() => {
    const fetchDataBranch = async () => {
      const response = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmInventory/get-select-branch"
      );

      setDataBranch(response?.data);
    };
    fetchDataBranch();
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            align="center"
            direction="row"
            wrap="wrap"
          >
            {data.length < 1 && (
              <Button
                leftSection={<IconPlus size={14} />}
                color="blue"
                variant="outline"
                onClick={() => handleAddPriceList()}
              >
                Thêm bảng giá (F11)
              </Button>
            )}

            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={() => {
                handleDeletePriceList(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>
            <FileButton onChange={setFile}>
              {(props) => (
                <Button
                  leftSection={
                    <IconFileDownload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  {...props}
                >
                  Import Excel
                </Button>
              )}
            </FileButton>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            {/* <Input
              flex={1}
              placeholder="Số bảng giá"
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "priceListNum");
              }}
              leftSection={<IconNumber color="#15aabf" />}
            /> */}
            <InputSearch
              width="100%"
              placeholder="Số bảng giá"
              onSearch={fetchData}
              defaultValue={search.keySearch}
              onChange={(value) => {
                handleChangeSearchValue(value, "priceListNum");
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3.5 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              allowSingleDateInRange
              placeholder="Chọn khoảng thời gian hết hạn"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  e[0] ? e[0].toISOString() : "",
                  "activeDate"
                );
                handleChangeSearchValue(
                  e[1] ? e[1].toISOString() : "",
                  "inactiveDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Trung tâm"
              clearable
              data={dataBranch?.map((branch) => ({
                value: branch.value,
                label: branch.text,
              }))}
              onChange={(e) => {
                handleChangeSearchValue(e ?? "", "listIdBranch");
              }}
              leftSection={<IconBuildingCommunity color="#15aabf" />}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Trạng thái duyệt"
              clearable
              data={[
                {
                  value: "0",
                  label: "Chưa duyệt",
                },
                {
                  value: "1",
                  label: "Đồng ý duyệt",
                },
                {
                  value: "2",
                  label: "Từ chối duyệt",
                },
              ]}
              onChange={(e) => {
                handleChangeSearchValue(e ?? "", "status");
              }}
              leftSection={<IconStatusChange color="#15aabf" />}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Trạng thái bảng giá"
              clearable
              data={[
                {
                  value: "0",
                  label: "Không xác nhận",
                },
                {
                  value: "1",
                  label: "Đã xác nhận",
                },
              ]}
              onChange={(e) => {
                handleChangeSearchValue(e ?? "", "isDrap");
              }}
              leftSection={<IconReportAnalytics color="#15aabf" />}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
              w={"100%"}
            >
              Lọc bảng giá
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row?.tblPriceListModel?.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold",
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      // showProgressBars: isRefetching,
      // showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 5 }}
        loaderProps={{
          children: (
            <Flex direction={"column"} align="center" gap="xs">
              <Loader type="dots" size={35}></Loader>
              <Title size={15}> Đang upload tài liệu...</Title>
            </Flex>
          ),
        }}
      />

      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ListPrice;
