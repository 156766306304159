import { BaseDbEntity, BaseEntity } from "../model/_base/BaseEntity";
import { Tables } from "./table";

let version = 1;
type ValidTableKeys = keyof typeof Tables;
const nameDb = "hacom-db-" + ( window.location.hostname.includes("pos.hacom.vn") ? "prod" : "dev");

// export const initDB = (_table: string): Promise<boolean> => {
//   return new Promise((resolve, reject) => {
//     try {
//       const request: IDBOpenDBRequest = indexedDB.open(nameDb, version);

//       request.onupgradeneeded = () => {
//         const db = request.result;

//         // Tạo ObjectStore nếu chưa tồn tại
//         if (!db.objectStoreNames.contains(_table)) {
//           console.log(`Creating object store for table: ${_table}`);
//           db.createObjectStore(_table, { keyPath: "id" });
//         }
//       };

//       request.onsuccess = () => {
//         const db = request.result;
//         version = db.version;
//         console.log("Database initialized successfully. Version:", version);

//         // Đóng kết nối để tránh bị khóa
//         db.close();
//         resolve(true);
//       };

//       request.onerror = (event) => {
//         console.error(
//           "Error opening database:",
//           (event.target as IDBRequest).error?.message
//         );
//         resolve(false); // Hoặc reject nếu muốn xử lý lỗi qua catch
//       };
//     } catch (error) {
//       console.error("Unexpected error initializing database:", error);
//       reject(error);
//     }
//   });
// };

const addData = <T extends BaseDbEntity>(
  storeName: string,
  data: T
): Promise<T> => {
  return new Promise((resolve, reject) => {
    try {
      const startTime = performance.now();  // Bắt đầu đo thời gian thực thi

      // Khởi tạo giá trị mặc định cho đối tượng
      data.id = Date.now();
      data.createdate = new Date();
      data.createBy =
        localStorage.getItem("userName") ?? localStorage.getItem("empId") ?? "";

      const request: IDBOpenDBRequest = indexedDB.open(nameDb, version);

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;

        // Kiểm tra xem object store đã tồn tại chưa, nếu chưa thì tạo mới
        if (!db.objectStoreNames.contains(storeName)) {
          console.log(`Object store "${storeName}" không tồn tại. Tạo mới...`);
          db.createObjectStore(storeName, { keyPath: "id" });
        }
      };

      request.onsuccess = () => {
        console.log("Database opened successfully for adding data");
        const db = request.result;

        // Bắt đầu transaction
        const tx = db.transaction(storeName, "readwrite");
        const store = tx.objectStore(storeName);

        // Thêm dữ liệu vào store
        const addRequest = store.add(data);

        addRequest.onsuccess = () => {
          console.log("Data added successfully:", data);
          // Đo thời gian thực thi
          const endTime = performance.now();
          console.log(`Thời gian thực thi addData: ${(endTime - startTime).toFixed(2)} ms`);
          resolve(data);
        };

        addRequest.onerror = () => {
          const error = addRequest.error?.message;
          console.error("Error adding data:", error);
          const errorData: any = { id: 0 };
          resolve(errorData);
        };

        // Xử lý kết thúc transaction
        tx.oncomplete = () => {
          console.log("Transaction completed successfully");
          db.close();
        };

        tx.onerror = (event) => {
          console.error("Transaction failed:", (event.target as any).error);
          const errorData: any = { id: 0 };
          resolve(errorData);
        };
      };

      request.onerror = () => {
        const error = request.error?.message;
        console.error("Error opening database:", error);
        const errorData: any = { id: 0 };
        resolve(errorData);
      };
    } catch (error) {
      console.error("Unexpected error while adding data:", error);
      reject(null);
    }
  });
};


const getLoggingData = (table:string) => {
  return new Promise((resolve, reject) => {
    // Mở cơ sở dữ liệu


    const request = indexedDB.open(nameDb, 1); // Sử dụng phiên bản cơ sở dữ liệu là 1 hoặc phiên bản bạn đã định nghĩa
    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;

      // Kiểm tra xem object store đã tồn tại chưa, nếu chưa thì tạo mới
      if (!db.objectStoreNames.contains(table)) {
        console.log(`Object store "${table}" không tồn tại. Tạo mới...`);
        db.createObjectStore(table, { keyPath: "id" });
      }
    };
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction(table, "readonly"); // Truy cập store 'logging' trong chế độ đọc
      const store = transaction.objectStore(table);

      const allRecordsRequest = store.getAll(); // Lấy tất cả các bản ghi trong store

      allRecordsRequest.onsuccess = () => {
        // Trả về dữ liệu khi thành công
        resolve(allRecordsRequest.result);
      };

      allRecordsRequest.onerror = () => {
        // Xử lý lỗi khi lấy dữ liệu
        reject("Error reading from logging store.");
      };

      transaction.onerror = () => {
        // Xử lý lỗi khi thực hiện transaction
        reject("Transaction failed.");
      };
    };

    request.onerror = () => {
      // Xử lý lỗi khi mở cơ sở dữ liệu
      reject("Failed to open database.");
    };
  });
};


export const DbExtension = {
  addData,
  getLoggingData
};
