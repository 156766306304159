import { useEffect, useRef, useState } from "react";
import { TblDeliveryDetails } from "../../../model/TblDeliveryCreate";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import DetailProduct from "./DetailProduct";

const DeliveryDetailByDeliveryId = ({ id }: { id: any }) => {
  const [dataDeliveryDetailByDeliveryId, setDataDeliveryDetailByDeliveryId] =
    useState<TblDeliveryDetails[]>([]);

  const heightRes = useRef<HTMLDivElement | null>(null);

  const getDataDeliveryDetailByDeliveryId = async () => {
    try {
      const callApi = await repositoryDelivery.get<
        MessageResponse<TblDeliveryDetails[]>
      >(
        `/api/v1/TblDelivery/get-delivery-detail-by-delivery-id?deliveryId=${id}`
      );
      if (callApi) {
        const dataApi = callApi.data;

        if (dataApi != null) {
          setDataDeliveryDetailByDeliveryId(dataApi);
        } else {
          NotificationExtension.Fails("Dữ liệu không tồn tại");
        }
      }
    } catch (error) {
      console.error(error);
      NotificationExtension.Fails("An error occurred while fetching data");
    }
  };

  useEffect(() => {
    if (id) {
      getDataDeliveryDetailByDeliveryId();
    }
  }, [id]);

  useEffect(() => {
    const updateHeight = () => {
      if (heightRes.current) {
        const height = window.innerHeight - 220;
        heightRes.current.style.height = `${height}px`;
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <>
      <DetailProduct id={id} isDetail={true} sourceCode={null} sourceType={3} />
    </>
  );
};

export default DeliveryDetailByDeliveryId;
