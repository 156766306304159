import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Group,
  Input,
  MultiSelect,
  MultiSelectProps,
  Text,
} from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import {
  IconSearch,
  IconShoppingCart,
  IconUser,
  IconUsers,
} from "@tabler/icons-react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { AvatarUtils } from "../../../../common/ColorByName/AvatarUtils";
import { _localization } from "../../../../config/location";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { ItemDataList } from "../../../../model/TblOrderHeader";
import {
  getTblDmEmployeeSelect,
  getTblDmSaleChanelSelect,
} from "../../../../service/getSelectApi";
import { getValueById } from "../../../../_base/helper/FunctionHelper";

const ReportProfitLossByInvoice = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<ItemDataList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  //Kho
  const [dataInventory, setDataInventory] = useState<SelectListItemModel[]>([]);
  //Bộ phận
  const [dataDepartment, setDataDepartment] = useState<SelectListItemModel[]>(
    []
  );
  const [dataSaleChannel, setDataSaleChannel] = useState<ComboboxItem[]>([]);
  const [dataSaleChannelSearch, setDataSaleChannelSearch] = useState<
    ComboboxItem[]
  >([]);
  const [dataEmployee, setDataEmployee] = useState<ComboboxItem[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      StartTime: "",
      EndTime: "",
    },
  });

  const [search, setSearch] = useState({
    itemVt: [], //chi nhánh
    bpIds: [], //bộ phận
    saleChannelId: [],
    saleId: [],
  });

  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }

    if (search.itemVt && search.itemVt.length > 0) {
      search.itemVt.forEach((itemVt) => {
        url += `&ItemId=${itemVt}`;
      });
    }
    if (search.bpIds && search.bpIds.length > 0) {
      search.bpIds.forEach((bpIds) => {
        url += `&DeparmentId=${bpIds}`;
      });
    }
    if (search.saleChannelId && search.saleChannelId.length > 0) {
      search.saleChannelId.forEach((item) => {
        url += `&SaleChanelId=${item}`;
      });
    }
    if (search.saleId && search.saleId.length > 0) {
      search.saleId.forEach((item) => {
        url += `&SaleId=${item}`;
      });
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/get-list-profit-loss-order-header${url}`
      );

      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        Promise.all([getDataSaleChannel()]);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  // Lấy danh sách mã bộ phận
  const fetchDataBp = async () => {
    try {
      const response = await repositoryPos.get(`/api/v1/TblDepartment/get-all`);
      if (response && response.success) {
        const transformedData = response.data
          ?.filter((i: any) => i.departmentId && i.deparmentCode)
          .map((item: any) => ({
            value: String(item.departmentId),
            label: item.deparmentCode + " - " + item.name,
          }));
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.label))
        ).map((label) =>
          transformedData.find((item: any) => item.label === label)
        );
        console.log(transformedData);
        console.log(uniqueData);
        setDataDepartment(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const getDataSaleChannel = async () => {
    const res = await getTblDmSaleChanelSelect();

    setDataSaleChannel(
      res
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );

    setDataSaleChannelSearch(
      res
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataEmployee = async () => {
    const res = await getTblDmEmployeeSelect();

    setDataEmployee(
      res
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const formatDate = (date: string | Date): string => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return "Ngày không hợp lệ";
    }
    let year = parsedDate.getFullYear();
    if (year < 1000) {
      year += 2000;
      parsedDate.setFullYear(year);
    }
    // Trả về ngày ở định dạng dd/MM/yyyy
    return parsedDate.toLocaleDateString("vi-VN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };

  const columns = React.useMemo<MRT_ColumnDef<ItemDataList>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="violet">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "maNvkd",
        header: "Mã nhân viên kinh doanh",
      },
      {
        accessorKey: "tenNvkd",
        header: "Tên nhân viên kinh doanh",
        Cell: ({ renderedCellValue }) => (
          <>
            <AvatarUtils
              value={renderedCellValue?.toString().split("-")[0] ?? ""}
            />
          </>
        ),
      },
      {
        accessorKey: "deparmentCode",
        header: "Mã phòng ban",
        // Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng",
        // footer: "Tổng doanh thu:",
        // Footer(props) {
        //   return (
        //     <div>
        //       <Text c="red" fw={800}>
        //         {props.column.columnDef.footer}
        //       </Text>
        //     </div>
        //   );
        // },
      },
      {
        accessorKey: "tongDoanhThu",
        header: "Tổng doanh thu",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tongDoanhThu || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng tiền doanh thu
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tongCk",
        header: "Tổng chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tongCk || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng tiền chiết khấu
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tongSauCk",
        header: "Tổng sau chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tongSauCk || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng tiền sau chiết khấu
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "giaVon",
        header: "Giá vốn",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.giaVon || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng tiền giá vốn
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "saleChanelId",
        header: "Kênh bán hàng",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              renderedCellValue?.toString() ?? "",
              dataSaleChannel,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "transactionDate",
        header: "Ngày giao dịch",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {renderedCellValue
              ? formatDate(renderedCellValue)
              : "Không có dữ liệu"}
          </>
        ),
      },
    ],
    [dataSaleChannel]
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableColumnResizing: true,
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT"],
        // right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    enableStickyFooter: true,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "10px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (160 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataDepartment.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        <Input
          style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
          placeholder="Từ khoá"
          type="text"
          leftSection={<IconSearch color="#15aabf" />}
          key={formSeachInput.key("keySearch")}
          {...formSeachInput.getInputProps("keySearch")}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              fetchData();
            }
          }}
        />
        <MultiSelect
          style={{ display: "flex", alignItems: "center" }}
          placeholder="Chọn phòng ban"
          clearable
          searchable
          data={dataDepartment} // Hiển thị kết quả từ API
          value={search.bpIds}
          renderOption={renderMultiSelectOption}
          // limit={15}
          leftSection={<IconUsers size={20} color="#15aabf" />}
          onClick={() => {
            if (dataDepartment.length === 0) {
              fetchDataBp();
            }
          }}
          onChange={(value) => {
            handleChangeSearchValue(value ? value : null, "bpIds");
          }}
        />
        <MultiSelect
          placeholder="Kênh bán hàng"
          clearable
          searchable
          data={dataSaleChannelSearch} // Hiển thị kết quả từ API
          value={search.saleChannelId}
          // renderOption={renderMultiSelectOption}
          // limit={15}
          leftSection={<IconShoppingCart size={20} color="#15aabf" />}
          onChange={(value) => {
            handleChangeSearchValue(value ? value : null, "saleChannelId");
          }}
        />
        <MultiSelect
          placeholder="Nhân viên bán hàng"
          clearable
          searchable
          data={dataEmployee} // Hiển thị kết quả từ API
          value={search.saleId}
          // renderOption={renderMultiSelectOption}
          // limit={15}
          leftSection={<IconUser size={20} color="#15aabf" />}
          onClick={() => {
            if (dataEmployee.length === 0) {
              getDataEmployee();
            }
          }}
          onChange={(value) => {
            handleChangeSearchValue(value ? value : null, "saleId");
          }}
        />
        {/* <Select
                    size="sm"
                    placeholder="Phòng ban"
                    searchable
                    clearable
                    leftSection={<IconUsers size={20} color="#15aabf" />}
                    data={dataDepartment}
                    onClick={() => {
                        if (dataDepartment.length === 0) {
                            fetchDataBp();
                        }
                    }}
                    onChange={(value) =>
                        handleChangeSearchValue(value ? [value] : null, "Bpids")
                    } // Cập nhật Bpids
                /> */}

        <Button
          type="submit"
          leftSection={<IconSearch size={14} />}
          color="blue"
          variant="outline"
          onClick={async () => {
            await fetchData();
          }}
        >
          Tìm kiếm
        </Button>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ReportProfitLossByInvoice;
