import { Button, Checkbox, Fieldset, Flex } from "@mantine/core";
import React, { useState } from "react";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";
import { useHotkeys } from "@mantine/hooks";
type Props = {
  id: number;
  type: string;
  onhandlePrint: (id: string | number, type: string | null) => void;
  setValuePrintOptionModal: any;
};
const OptionPrintOutWard = ({
  id,
  type,
  onhandlePrint,
  setValuePrintOptionModal,
}: Props) => {
  const [valueOptionCheck, setValueOptionCheck] = useState({
    option1: "Sl",
    option2: "coGia",
  });
  const onhandleSubmit = () => {
    setValuePrintOptionModal(valueOptionCheck);
    if (valueOptionCheck.option1 === "" || valueOptionCheck.option2 === "") {
      NotificationExtension.Fails(
        "Vui lòng chọn đầy đủ lựa chọn hiển thị 1 và 2 của phiếu in"
      );
    } else {
      modals.closeAll();
      setTimeout(() => {
        onhandlePrint(id, type);
      }, 1000);
    }
  };

  useHotkeys([
    [
      "enter",
      () => {
        onhandleSubmit();
      },
    ],
  ]);
  return (
    <div
      style={{
        marginTop: "20px",
        padding: "10px",
      }}
    >
      <Flex
        direction={"row"}
        align={"center"}
        gap={"sm"}
        w={"100%"}
        justify={"space-between"}
      >
        <Fieldset legend="Lựa chọn hiển thị 1">
          <Flex direction={"column"} gap={"lg"} align={"left"} justify={"left"}>
            <Checkbox
              checked={valueOptionCheck.option1 === "Sl" ? true : false}
              value="Sl"
              label="Số lượng"
              onChange={() =>
                setValueOptionCheck((prev: any) => ({
                  ...prev,
                  option1: prev.option1 === "Sl" ? "" : "Sl",
                }))
              }
            />
            <Checkbox
              checked={valueOptionCheck.option1 === "Serial" ? true : false}
              value="Serial"
              label="Serial"
              onChange={() =>
                setValueOptionCheck((prev: any) => ({
                  ...prev,
                  option1: prev.option1 === "Serial" ? "" : "Serial",
                }))
              }
            />
          </Flex>
        </Fieldset>
        <Fieldset legend="Lựa chọn hiển thị 2">
          <Flex direction={"column"} gap={"lg"} align={"left"} justify={"left"}>
            <Checkbox
              checked={valueOptionCheck.option2 === "coGia" ? true : false}
              value="coGia"
              label="Có giá"
              onChange={() =>
                setValueOptionCheck((prev: any) => ({
                  ...prev,
                  option2: prev.option2 === "coGia" ? "" : "coGia",
                }))
              }
            />
            <Checkbox
              checked={valueOptionCheck.option2 === "khongGia" ? true : false}
              value="khongGia"
              label="Không giá"
              onChange={() =>
                setValueOptionCheck((prev: any) => ({
                  ...prev,
                  option2: prev.option2 === "khongGia" ? "" : "khongGia",
                }))
              }
            />
          </Flex>
        </Fieldset>
      </Flex>

      <Flex direction={"row"} justify={"center"} mt={"md"} w={"100%"}>
        <Button variant="filled" onClick={() => onhandleSubmit()}>
          In phiếu
        </Button>
      </Flex>
    </div>
  );
};

export default OptionPrintOutWard;
