import { Divider, Flex, Grid, Table, Text, TextInput } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { DatePickerInput } from "@mantine/dates";
import DetailProduct from "./DetailProduct";

const DeliveryDetailBySellItem = ({
  sourceCode,
  id,
  setParkingFee,
}: {
  setParkingFee: any;
  sourceCode: any;
  id: any;
}) => {
  const [dataDeliveryDetailBySellItem, setDataDeliveryDetailBySellItem] =
    useState<any>();

  const heightRes = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setParkingFee(dataDeliveryDetailBySellItem?.parkingFee);
  }, [dataDeliveryDetailBySellItem]);

  useEffect(() => {
    const updateHeight = () => {
      if (heightRes.current) {
        const height = window.innerHeight - 610;
        heightRes.current.style.height = `${height}px`;
      }
    };
    const timeoutId = setTimeout(updateHeight, 100);
    window.addEventListener("resize", updateHeight);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Số đơn hàng"
            value={dataDeliveryDetailBySellItem?.sourceCode ?? ""}
            variant="filled"
            readOnly
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <DatePickerInput
            label="Ngày đặt hàng"
            value={
              dataDeliveryDetailBySellItem?.createDate
                ? new Date(dataDeliveryDetailBySellItem?.createDate ?? "")
                : null
            }
            valueFormat="DD/MM/YYYY"
            variant="filled"
            readOnly
            size="xs"
          />
        </Grid.Col>
        {/* <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <Select
                label="Loại đơn hàng"
                data={dataOrderTypeSelect}
                value={item.orderTypeId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col> */}
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Tổng giá trị"
            value={
              dataDeliveryDetailBySellItem?.amountTotal !== null
                ? `${dataDeliveryDetailBySellItem?.amountTotal?.toLocaleString()} đ`
                : "0 đ"
            }
            variant="filled"
            readOnly
            size="xs"
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Đã trả"
            value={
              dataDeliveryDetailBySellItem?.amountPaid !== null
                ? `${
                    dataDeliveryDetailBySellItem?.amountPaid?.toLocaleString() ??
                    ""
                  } đ`
                : "0 đ"
            }
            variant="filled"
            readOnly
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Còn lại"
            value={
              dataDeliveryDetailBySellItem?.amountRemaining !== null
                ? `${
                    dataDeliveryDetailBySellItem?.amountRemaining.toLocaleString() ??
                    ""
                  } đ`
                : "0 đ"
            }
            variant="filled"
            readOnly
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Tình trạng thanh toán"
            value={
              dataDeliveryDetailBySellItem?.paymentStatus === "DA_THANH_TOAN"
                ? "Đã thanh toán"
                : dataDeliveryDetailBySellItem?.paymentStatus ===
                  "THANH_TOAN_MOT_PHAN"
                ? "Thanh toán 1 phần"
                : dataDeliveryDetailBySellItem?.paymentStatus ===
                  "KHONG_CAN_THANH_TOAN"
                ? "Không thanh toán"
                : "Chưa thanh toán"
            }
            variant="filled"
            readOnly
            size="xs"
          />
        </Grid.Col>
        {/* <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Select
            label="Trạng thái đơn hàng"
            data={dataOrderStatus}
            rightSection={" "}
            variant="filled"
            readOnly
            size="xs"
          />
        </Grid.Col> */}
      </Grid>
      <Divider my="xs" label="Danh sách sản phẩm" labelPosition="center" />
      <DetailProduct
        id={id}
        sourceCode={null}
        sourceType={1}
        isDetail={true}
        setDataOrder={setDataDeliveryDetailBySellItem}
      />
      <Divider my="xs" label="Sản phẩm trả lại" labelPosition="center" />
      {dataDeliveryDetailBySellItem?.returnedItems !== null ? (
        <Table withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>STT</Table.Th>
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Số lượng</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataDeliveryDetailBySellItem?.returnedItems &&
              JSON.parse(dataDeliveryDetailBySellItem?.returnedItems)?.map(
                (item: any, index: any) => (
                  <Table.Tr key={index}>
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td>{item.ItemCode}</Table.Td>
                    <Table.Td>{item.Quantity}</Table.Td>
                  </Table.Tr>
                )
              )}
          </Table.Tbody>
        </Table>
      ) : (
        <Flex justify={"center"}>
          <Text fs={"italic"} size="11.5px" c={"gray"}>
            Không có sản phẩm trả lại
          </Text>
        </Flex>
      )}
    </>
  );
};

export default DeliveryDetailBySellItem;
