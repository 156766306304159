import { IconMail, IconPhone, IconWorldWww } from "@tabler/icons-react";
import QRCode from "react-qr-code";
import styles from "./style.module.css";
import Barcode from "react-barcode";
import VCB930 from "../../assets/images/VCB-930-QR.png";
import qrTinhHDBH from "../../assets/images/qrTinhHDBH.png";

type Item = {
  itemCode: string;
  itemName: string;
  quantity: number;
  uom: string;
  price: number;
  totalLineAmount: number;
  vatRate: number;
  vatAmount: number;
};
type Order = {
  orderId: number;
  orderNumber: string; // số HĐ
  createDate: string; //tg
  casherCode: string | null; //thu ngân
  casherName: string | null; //tên thu ngân
  custCode: string; //mã số kh
  custName: string; //tên kh
  telephoneNumber: string; //sđt
  amountDiscount: number;
  amountVoucher: number | null;
  pointExchangeAmout: number | null; //số tiền tích lũy
  amountTotal: number; //tổng tiền hàng
  payAmount: number | null; //tổng của các loại thanh toán trước
  cashAmount: number | null; //thanh toán tiền mặt
  vatAmount: number | null; //số tiền cần thanh toán
  custAmountTransfer: number | null;
  cardAmount: number | null; //thanh toán thẻ
  codAmount: number | null; //thanh toán sau (cod)
  paymentMethod: string | null;
  discountAdded: number | null; //Tổng giảm trừ
  discountAddedTotal: number;
  approveDate: string | null;
  approveStatus: string | null;
  orderStatus: string;
  items: Item[];
  invoiceNumber?: string; // Mã số mua hàng
  salerCode?: string; // Mã nhân viên bán hàng
  shippingFree?: number; // Phí vận chuyển
  totalQuantity?: number; // Tổng số lượng
  attribute20?: string; // tên phương thức thanh toán sau
  add1?: string; //địa chỉ đầu phiếu
  add2?: string;
  add3?: string;
  add4?: string | null;
  add5?: string | null;
  add6?: string;
  custTransferTotal?: number | null; //tiền chuyển khoản
};
const PrintedInvoice = ({
  invoiceData: data,
  valueQRCode,
  componentRef,
}: {
  invoiceData: Order;
  valueQRCode: any;
  componentRef: any;
}) => {
  const currentTime = new Date();
  // Định dạng ngày giờ, bao gồm cả giây
  const formattedDateTime = currentTime
    .toLocaleString("en-US", {
      year: "2-digit", // Lấy hai chữ số cuối của năm
      month: "2-digit", // Lấy tháng dưới dạng hai chữ số
      day: "2-digit", // Lấy ngày dưới dạng hai chữ số
      hour: "2-digit", // Lấy giờ dưới dạng hai chữ số
      minute: "2-digit", // Lấy phút dưới dạng hai chữ số
      second: "2-digit", // Lấy giây dưới dạng hai chữ số
      hourCycle: "h23", // Sử dụng định dạng 24 giờ
    })
    .replace(/\//g, ".")
    .replace(/, /g, " ");

  // Đổi vị trí ngày và tháng
  const rearrangedDateTime = formattedDateTime.replace(
    /^(\d{2})\.(\d{2})\.(\d{4}) (.+)$/,
    "$2.$1.$3 $4"
  );
  const formatCurrency = (value: any) => {
    if (value == null) {
      return 0;
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  function formatDate(dateString: string): string {
    const date = new Date(dateString);

    // Tạo các phần cần thiết cho định dạng
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Lấy 2 chữ số cuối của năm

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Trả về chuỗi theo định dạng mong muốn
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <div
      ref={componentRef}
      style={{
        width: "80mm !important",
        color: "#000",
        height: "auto",
        // padding: "0 8px",
        paddingTop: "8px",
        paddingRight: "15px",
        paddingLeft: "8px",
        // margin: "0 4px",
      }}
      // className={styles.container}
    >
      <div
        className={styles.textHeader}
        style={{
          textAlign: "center",
        }}
      >
        <h4
          className={styles.text}
          style={{
            margin: 0,
            lineHeight: 1.6,
            fontSize: "12px",
            marginTop: "5px",
            fontWeight: "700",
          }}
        >
          Công Ty Cổ Phần Đầu Tư Công Nghệ HACOM
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0 2px",
            marginRight: "2px",
          }}
        >
          <div
            className={styles.text}
            style={{
              fontSize: "9.7px",
              margin: "0",
              lineHeight: "1.6",
              marginTop: "2px",
              padding: "0 3px",
              paddingLeft: "5.2px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
            }}
          >
            {/* <span>.</span> CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà Trưng,
            TP. Hà Nội */}
            <span>.</span> CN: {data?.add1}
          </div>

          <div
            style={{
              marginTop: "-2px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              fontSize: "9.7px",
              alignItems: "center",
              padding: "0 3px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "2px",
              }}
            >
              <span>.</span>
              <IconPhone
                stroke={2}
                style={{
                  margin: 0,
                  width: "10px",
                  marginRight: "1px",
                  marginTop: "-2px",
                }}
              />
              <span
                className={styles.text}
                style={{
                  margin: 0,
                  lineHeight: "1.6",
                }}
              >
                19001903
              </span>
            </div>
            <span style={{ margin: "0 2px" }}>|</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconMail
                stroke={2}
                style={{ width: "11px", marginRight: "2px" }}
              />
              <span
                className={styles.text}
                style={{
                  margin: 0,
                  lineHeight: "1.6",
                }}
              >
                {" "}
                dichvukhachhang@hacom.vn
              </span>
            </div>
            <span style={{ margin: "0 2px" }}>|</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconWorldWww
                stroke={2}
                style={{ width: "11px", marginRight: "2px" }}
              />
              <span
                className={styles.text}
                style={{ fontSize: "10px", margin: 0, lineHeight: "1.6" }}
              >
                Hacom.vn
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3
          className={styles.text}
          style={{
            margin: 0,
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "700",
            marginTop: "3px",
            marginBottom: "7px",
            lineHeight: "1.4",
            padding: "0 2px",
          }}
        >
          HÓA ĐƠN BÁN HÀNG
        </h3>
        <div
          style={{
            height: "50px",
            width: "100%",
            padding: "0 5px",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <Barcode
            width={2}
            height={40}
            margin={0}
            value={data?.orderNumber ?? ""} // Đảm bảo 13 chữ số cho EAN13
            displayValue={false}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "10px",
            justifyContent: "space-between",
            margin: "0 3px",
            marginBottom: "7px",
            padding: "0 3px",
            marginRight: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              className={styles.text}
              style={{
                margin: 0,
                lineHeight: "1.2",
              }}
            >
              <span>Thời gian: {formatDate(data?.createDate)}</span>
            </p>
            <p
              className={styles.text}
              style={{
                margin: 0,
                lineHeight: "1.2",
              }}
            >
              <span>NVBH: </span>
              <span>{data?.salerCode}</span>
            </p>
            <p
              className={styles.text}
              style={{
                margin: 0,
                lineHeight: "1.2",
              }}
            >
              <span>Thu Ngân: {data?.casherCode}</span>
            </p>
            <p
              className={styles.text}
              style={{
                margin: 0,
                lineHeight: "1.2",
              }}
            >
              <span>Lần in: 01</span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "2px",
            }}
          >
            <p
              className={styles.text}
              style={{
                margin: 0,
                lineHeight: "1.2",
              }}
            >
              <span>Số ĐH: {data?.orderNumber}</span>
            </p>
            <p
              className={styles.text}
              style={{
                margin: 0,
                lineHeight: "1.2",
              }}
            >
              <span>KH: {data?.custCode}</span>
            </p>{" "}
            <p
              className={styles.text}
              style={{
                margin: 0,
                lineHeight: "1.2",
              }}
            >
              <span>Thời gian in: {rearrangedDateTime}</span>
            </p>
            <p
              className={styles.text}
              style={{
                margin: 0,
                lineHeight: "1.2",
              }}
            >
              <span>Số điện thoại: {data?.telephoneNumber}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          borderTop: "1px dotted #000",
          borderBottom: "1px dotted #000",
          padding: "0 5px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
            fontSize: "12px",
            marginBottom: "8px",
            textAlign: "center",
          }}
        >
          <span>Hàng hóa</span>
          <span>|</span>
          <span>Mã hàng</span> <span>|</span>
          <span>SL</span> <span>|</span> <span>ĐVT</span> <span>|</span>{" "}
          <span>Giá bán</span>| <span>Thành tiền</span>
        </div>
        {data?.items?.map((value: any, index: number) => (
          <div key={index} style={{ fontSize: "11.8px" }}>
            <div
              className={styles.text}
              style={{
                marginBottom: "2px 0",
                marginTop: "4px",
                // margin: 0,
                width: "100% !important",
                textAlign: "justify",
                textAlignLast: "left",
                marginRight: "2px",
                lineHeight: "1",
                // marginTop: "-10px",
              }}
            >
              <p style={{ margin: 0, lineHeight: "1.2" }}>
                {index + 1}
                {". "}
                {value?.itemName}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ fontWeight: 400 }}>
                {value?.itemCode} x{" "}
                {value?.quantity < 10 ? `0${value?.quantity}` : value?.quantity}{" "}
                {value?.uom} x {formatCurrency(value?.price)} =
              </div>
              <div>
                <p
                  className={styles.text}
                  style={{
                    margin: 0,
                    marginRight: "4px",
                  }}
                >
                  {formatCurrency(value?.totalLineAmount)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          borderBottom: "1px dotted #000",
          padding: "6px",
          fontSize: "12px",
          margin: "-2px 0",
          marginRight: "2px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            Tổng số lượng
          </p>
          <p
            className={styles.text}
            style={{
              fontWeight: "700",
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            {(data?.totalQuantity ?? 0) < 0
              ? data?.totalQuantity // Nếu nhỏ hơn 0, hiển thị giá trị âm
              : (data?.totalQuantity ?? 0) < 10
              ? `${data?.totalQuantity ?? ""}` // Nếu từ 0 đến 9, thêm 0 phía trước
              : data?.totalQuantity ?? 0}{" "}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            Tổng tiền hàng
          </p>
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            {formatCurrency(data?.amountTotal)}
          </p>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* phí vận chuyển chưa có */}
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            Phí vận chuyển
          </p>
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            {formatCurrency(data?.shippingFree) ?? 0}
          </p>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            Tổng giảm trừ
          </p>
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            {formatCurrency(data?.discountAdded) ?? 0}
          </p>
        </div>{" "}
        {/* <Flex style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
                <p className={styles.text}>Mã số mua hàng</p>
                <p className={styles.text}>{data?.invoiceNumber}</p>
              </Flex>{" "} */}
        {/* <Flex style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <p className={styles.text}>Tiền tích lũy sử dụng</p>
            <p className={styles.text}>{formatCurrency(data?.amountVoucher)}</p>
          </Flex>{" "} */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            Số tiền cần thanh toán
          </p>
          <p
            className={styles.text}
            style={{
              fontWeight: "700",
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            {formatCurrency(data?.vatAmount)}
          </p>
        </div>{" "}
        <div>
          <p
            className={styles.text}
            style={{
              textAlign: "center",
              fontStyle: "italic",
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            (Giá đã bao gồm thuế GTGT)
          </p>
        </div>{" "}
        {/* <Flex style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
                <p className={styles.text}>Số tiền tích lũy</p>
                <p className={styles.text}>
                  {formatCurrency(data?.pointExchangeAmout)}
                </p>
              </Flex>{" "} */}
      </div>
      <div
        style={{
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12px",
          margin: "-1px 0",
          marginRight: "2px",
          paddingBottom: "-4px",
        }}
      >
        <p
          className={styles.text}
          style={{ fontWeight: "700", margin: 0, lineHeight: "1.6" }}
        >
          Thanh toán trước
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            Tiền mặt
          </p>
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            {formatCurrency(data?.cashAmount)}
          </p>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            Chuyển khoản
          </p>
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            {formatCurrency(data?.custTransferTotal)}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            Thanh toán thẻ
          </p>
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            {formatCurrency(data?.cardAmount)}
          </p>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p
            className={styles.text}
            style={{ fontWeight: "700", margin: 0, lineHeight: "1.6" }}
          >
            Tổng
          </p>
          <p
            className={styles.text}
            style={{ fontWeight: "700", margin: 0, lineHeight: "1.6" }}
          >
            {formatCurrency(data?.payAmount)}
          </p>
        </div>
        <p
          className={styles.text}
          style={{ fontWeight: "700", margin: 0, lineHeight: "1.6" }}
        >
          Thanh toán sau
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p
            className={styles.text}
            style={{
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            {data?.attribute20 === "COD" ? "C.O.D" : data?.attribute20}
          </p>
          <p
            className={styles.text}
            style={{ fontWeight: "600", margin: 0, lineHeight: "1.6" }}
          >
            {formatCurrency(data?.codAmount)}
          </p>
        </div>{" "}
      </div>
      {valueQRCode !== null ? (
        <>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "start",
              borderBottom: "1px dotted #000",
              padding: "5px 6px",
              paddingBottom: "-4px",
              fontSize: "12px",
            }}
          >
            <p
              className={styles.text}
              style={{
                flex: "60%",
                fontSize: "12px",
                paddingRight: "3px",
                margin: 0,
                lineHeight: "1.6",
              }}
            >
              Quý khách vui lòng quét mã QR để thanh toán chuyển khoản
            </p>
            <img
              src={valueQRCode}
              alt="qrCode"
              style={{
                width: "70px",
                height: "70px",
                // marginRight: "3px",
              }}
            />
          </div> */}
        </>
      ) : (
        <></>
      )}
      {Number(data?.codAmount) !== 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "start",
            borderBottom: "1px dotted #000",
            padding: "5px 6px",
            paddingBottom: "-4px",
            fontSize: "12px",
          }}
        >
          <p
            className={styles.text}
            style={{
              flex: "60%",
              fontSize: "12px",
              paddingRight: "3px",
              margin: 0,
              lineHeight: "1.6",
            }}
          >
            Quý khách vui lòng quét mã QR để thanh toán chuyển khoản
          </p>
          <img
            src={qrTinhHDBH}
            alt="qrCode"
            style={{
              width: "70px",
              height: "70px",
              // marginRight: "3px",
            }}
          />
        </div>
      )}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12px",
        }}
      >
        <p
          className={styles.text}
          style={{
            margin: 0,
            lineHeight: "1.6",
          }}
        >
          Mọi góp ý, thắc mắc, khiếu nại Quý Khách vui lòng liên hệ với bộ phận
          CSKH theo hotline{" "}
          <span
            style={{
              fontWeight: "700",
            }}
          >
            19001903
          </span>{" "}
          hoặc tại{" "}
          <span style={{ fontStyle: "italic", color: "blue" }}>
            hacom.vn/lien-he
          </span>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12px",
        }}
      >
        <p
          className={styles.text}
          style={{
            margin: 0,
            lineHeight: "1.6",
          }}
        >
          <span style={{ fontWeight: "700" }}> HACOM </span>chỉ xuất hóa đơn VAT
          trong ngày.
        </p>
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12px",
        }}
      >
        <p
          className={styles.text}
          style={{
            margin: 0,
            lineHeight: "1.6",
          }}
        >
          Chính sách bảo hành vui lòng xem tại: <br />
          <span
            style={{
              fontStyle: "italic",
              color: "blue",
            }}
          >
            hacom.vn/hacom-chinh-sach-bao-hanh
          </span>
        </p>
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12px",
        }}
      >
        <p
          className={styles.text}
          style={{
            flex: "55%",
            fontSize: "12px",
            paddingRight: "3px",
            margin: 0,
            lineHeight: "1.6",
          }}
        >
          Quý Khách đánh giá chất lượng hàng hóa, dịch vụ tại
        </p>
        <div
          style={{
            marginRight: "7px",
          }}
        >
          <QRCode
            value={"https://hacom.vn"}
            style={{
              width: "57px",
              height: "60px",
              // marginRight: "10px",
            }}
          />
        </div>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          padding: "5px",
          fontSize: "10px",
        }}
      >
        <p
          className={styles.text}
          style={{
            margin: 0,
            lineHeight: "1.6",
          }}
        >
          Cảm ơn Quý Khách đã mua sắm tại{" "}
          <span
            style={{
              fontWeight: "700",
            }}
          >
            HACOM
          </span>
          .
          <br />
          Hãy mua hàng tại{" "}
          <span
            style={{
              fontWeight: "700",
            }}
          >
            hacom.vn
          </span>{" "}
          để được hưởng giá ưu đãi hơn
        </p>
      </div>{" "}
    </div>
  );
};

export default PrintedInvoice;
