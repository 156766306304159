import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Code,
  Flex,
  Group,
  Input,
  List,
  Menu,
  rem,
  Select,
  Text,
  Textarea,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { DatePicker, DatePickerInput, DatesProvider } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconAdjustments,
  IconCaretDown,
  IconCircleCheck,
  IconCircleDashed,
  IconEdit,
  IconFileExport,
  IconInfoCircle,
  IconPlus,
  IconRefresh,
  IconSearch,
  IconStatusChange,
  IconTable,
  IconTimeDuration15,
  IconTrash,
  IconUpload,
  IconWash,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  AllCommunityModule,
  CellSelectionChangedEvent,
  DefaultMenuItem,
  GetContextMenuItemsParams,
  INumberCellEditorParams,
  RowSelectionOptions,
  StatusPanelDef,
  ValueFormatterParams,
} from "ag-grid-community";
import { AG_GRID_LOCALE_VN } from "@ag-grid-community/locale";
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import {
  AdvancedFilterModule,
  ClientSideRowModelModule,
  ClipboardModule,
  ColDef,
  ColGroupDef,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  GetRowIdParams,
  GridChartsModule,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsRequest,
  MasterDetailModule,
  MenuItemDef,
  MenuModule,
  ModuleRegistry,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowClassParams,
  RowGroupingModule,
  RowStyle,
  ServerSideRowModelModule,
  SetFilterModule,
  SparklinesModule,
  StatusBarModule,
} from "ag-grid-enterprise";
import { modals } from "@mantine/modals";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import {
  baseTheme,
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryLog,
  repositoryMdm,
} from "../../_base/_const/_constVar";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { MessageResponse } from "../../model/MessageResponse";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";

ModuleRegistry.registerModules([ClientSideRowModelModule, AllCommunityModule]);

type dataSubmit = {
  key: string;
  dateTimes: Array<Date>;
};

export interface HistoryReqService {
  /**
   * Mã đơn hàng, mã kho, ...
   */
  key: string;

  /**
   * Mô tả
   */
  des?: string;

  /**
   * Body hoặc param mà req nhận
   */
  body?: string;

  /**
   * Nội dung cần lưu trong lúc xử lý
   */
  bodyhandler?: string;

  /**
   * Ngày tạo
   */
  createdate?: Date;

  /**
   * Tên người dùng
   */
  username?: string;
}

const CheckDataLogView = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<HistoryReqService[]>([]);
  const [height, setHeight] = useState(0);
  const [value, setValue] = useState<Date[]>([]);
  const inputRef = useRef<string>("");
  const form = useForm({
    mode: "controlled",
    initialValues: {
      keySearch: "",
      skip: 0,
      take: 100,
      typeMessage: "TblSalesOrder",
      status: "",
    },
  });

  //Call api danh sách thiếu sót đơn đồng bộ
  const fetchData = async () => {
    try {
      const _data = {
        key: inputRef.current ?? "",
        dateTimes: value ?? [],
      };
      const response = await repositoryLog.post<
        MessageResponse<HistoryReqService[]>
      >("/api/v1/logging/get-list-logging", _data);
      if (response && response.success) {
        setRowData(response.data);
      } else {
        setData([]);
      }
    } catch (error) {
      // NotificationExtension.Fails("Không tìm thấy dữ liệu");
      setRowData([]);
      console.log("error", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // console.log("selectIds", selectIds);
  // console.log("data", data);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (260 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const gridRef = useRef<AgGridReact<HistoryReqService>>(null);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: height, marginTop: "15px" }),
    [height]
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<HistoryReqService[]>([]);
  const columnDefs = useMemo<ColDef<HistoryReqService>[]>(
    () => [
      {
        field: "key",
        headerName: "Mã Log",
        filter: "agTextColumnFilter",
        width: 200,
        pinned: "left",
      },

      {
        field: "body",
        headerName: "Nội dung Req",
        filter: "agTextColumnFilter",
        width: 500,
        cellRenderer: (params: CustomCellRendererProps<HistoryReqService>) => {
          return (
            <>
              {params.data?.body && (
                <ActionIcon
                  variant="outline"
                  aria-label="Settings"
                  mr={"xs"}
                  onClick={() => {
                    modals.openConfirmModal({
                      title: "Chi tiết ",
                      children: (
                        <Textarea
                          value={params.data?.body}
                          autosize
                          minRows={2}
                        >
                          {" "}
                        </Textarea>
                      ),
                      size: "80%",
                      confirmProps: { display: "none" },
                      cancelProps: { display: "none" },
                    });
                  }}
                >
                  <IconInfoCircle
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              )}

              {params.data?.body}
            </>
          );
        },
      },
      {
        field: "bodyhandler",
        headerName: "Nội dung trong lúc xử lý",
        filter: "agTextColumnFilter",
        width: 500,
        cellRenderer: (params: CustomCellRendererProps<HistoryReqService>) => {
          return (
            <>
              {params.data?.bodyhandler && (
                <ActionIcon
                  variant="outline"
                  aria-label="Settings"
                  mr={"xs"}
                  onClick={() => {
                    modals.openConfirmModal({
                      title: "Chi tiết ",
                      children: (
                        <Textarea
                          value={params.data?.bodyhandler}
                          autosize
                          minRows={2}
                        >
                          {" "}
                        </Textarea>
                      ),
                      size: "80%",
                      confirmProps: { display: "none" },
                      cancelProps: { display: "none" },
                    });
                  }}
                >
                  <IconInfoCircle
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              )}

              {params.data?.bodyhandler}
            </>
          );
        },
      },
      {
        field: "des",
        headerName: "Mô tả",
        filter: "agTextColumnFilter",
        width: 500,
      },
      {
        field: "username",
        headerName: "Người thao tác",
        filter: "agTextColumnFilter",
      },
      {
        field: "createdate",
        headerName: "Ngày thao tác",
        filter: "agDateColumnFilter",
        pinned: "right",
        cellRenderer: (params: CustomCellRendererProps<HistoryReqService>) => {
          return (
            <>{params && formatDateTransfer(params.data?.createdate, -7)}</>
          );
        },
      },
    ],
    [rowData]
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      filter: true,
      floatingFilter: true,
    };
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent" },
        { statusPanel: "agTotalRowCountComponent" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  return (
    <>
      <_breadcrumb></_breadcrumb>

      <Flex
        direction={"row"}
        align={"center"}
        justify={"space-between"}
        style={{
          border: "1px solid #dee2e6",
          overflowX: "hidden",
          padding: "10px 10px",
        }}
      >
        <Group>
          <Text fw={700}>
            CHÚ Ý: kho thao tác sẽ thực hiện ngay, cân nhắc trước khi thao tác
          </Text>
        </Group>
      </Flex>
      <Flex
        direction={"row"}
        mt={10}
        justify={"space-between"}
        align={"center"}
      >
        <form
          onSubmit={form.onSubmit(async (e) => {
            // await fetchData(e);
          })}
        >
          {/* <LoadingOverlay
              visible={visible}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            /> */}
          <Flex direction={"row"} align={"center"} gap={"md"} wrap="wrap">
            <Input
              size="sm"
              w={200}
              placeholder="Từ khóa"
              onChange={(e) => {
                inputRef.current = e.target.value;
              }}
              leftSection={<IconSearch color="#15aabf" />}
            />
            <DatesProvider settings={{ timezone: "America/New_York" }}>
              <DatePickerInput
                placeholder="Ngày tạo"
                locale="vi"
                type="multiple"
                value={value}
                onChange={setValue}
              />
            </DatesProvider>

            <Button
              type="submit"
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Flex>
        </form>
      </Flex>
      <div style={containerStyle}>
        <div style={gridStyle} className={"ag-theme-quartz"}>
          <AgGridReact<HistoryReqService>
            ref={gridRef}
            rowData={rowData}
            theme={baseTheme}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressColumnVirtualisation={true}
            suppressRowVirtualisation={true}
            pagination={true}
            localeText={AG_GRID_LOCALE_VN}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            cacheBlockSize={paginationPageSize}
            maxBlocksInCache={3}
            statusBar={statusBar}
            //cellSelection
            // onCellSelectionChanged={(
            //   event: CellSelectionChangedEvent<HistoryReqService>
            // ) => {
            // lấy index từ a->b từ list log ra rowstart và rowend
            //     console.log("event", event.api.getCellRanges());
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default CheckDataLogView;
