import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconLogout, IconX } from "@tabler/icons-react";
import { SocketExtension } from "../../../../_base/socket/socket";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { use } from "alasql";

export const LogoutUserView = ({
  ids,
  onClose,
  userName,
}: LogoutUserViewProps) => {
  return (
    <div>
      <Text size="20px" mt={5}>
        {`Bạn có chắc chắn đăng xuất ${
          ids.length === 1 ? "những " : " "
        }người dùng này`}
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="yellow"
          // onClick={handleDeleteBranch}
          leftSection={<IconLogout size={18} />}
          onClick={async () => {
            SocketExtension.SendMessageToUserName<any>(userName, {
              message: "",
              logout: true,
            });
            NotificationExtension.Info("Đăng xuất thành công !");
          }}
        >
          Đăng xuất
        </Button>
      </Group>
    </div>
  );
};
{
  /* <Button
                onClick={() => {
                  // var res = SocketExtension.SendMessageToUserName<any>(
                  //   "BACKGROUP",
                  //   {
                  //     message: "test noti",

                  //     //  type: TypePushSocket.NotSelf,
                  //   }
                  // );
                  const user = "chien123";
                  var res = SocketExtension.SendMessageToEmp<any>(2284, {
                    message:
                      "Bắn message to nhân viên có mã 2284 !" + user,

                    //  type: TypePushSocket.NotSelf,
                  });
                  console.log(res);
                }}
              >
                Click push
              </Button> */
}
type LogoutUserViewProps = {
  ids: string[] | number[];
  onClose: any;
  userName: string;
};
