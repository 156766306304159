import { Image } from "@mantine/core";
import {
  IconArrowBadgeRightFilled,
  IconMail,
  IconPhone,
  IconWorldWww,
} from "@tabler/icons-react";
import { formatDateTime } from "../../../../common/FormatDate/FormatDate";
import styles from "../styles/PrintStyle.module.css";
interface PrintedInvoiceProps {
  dataPrint: any;
  typePrint: string;
  userInfo: any;
  currentDate: any;
  componentRef: any;
  locationData?: any;
  valuePrintOptionModal?: any;
}

const PrintWarehouseOutWard = ({
  dataPrint,
  typePrint,
  userInfo,
  currentDate,
  componentRef,
  locationData,
  valuePrintOptionModal,
}: PrintedInvoiceProps) => {
  // console.log("typePrint", typePrint); //XUAT_BAN , XUAT_TRA_NCC , XUAT_DOI_TRA
  const dataTransaction = dataPrint?.tblInventoryTransactionModel;
  // const typeOfDebtPayment = JSON.parse(dataTransaction?.attribute2 || "{}");
  const dataSerials = dataPrint?.inventoryTransactionSerialModels;
  console.log("dataTransaction", dataTransaction);
  const totalQuantity = dataSerials?.reduce(
    (total: number, item: any) => total + item.primaryQuantity,
    0
  );

  const formatCurrency = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };

  const formatPhoneNumber = () => {
    const phone = dataTransaction?.cusTelephone;
    // Kiểm tra nếu không có dữ liệu thì trả về chuỗi rỗng
    if (!phone) return "";

    // Loại bỏ khoảng trắng trong chuỗi
    const phoneNumber = phone.replace(/\s+/g, "");

    // Nếu bắt đầu bằng "0" thì giữ nguyên, nếu không thì thêm "0"
    return phoneNumber.startsWith("0") ? phoneNumber : "0" + phoneNumber;
  };

  //Lấy tên thành phố
  const getCityFromAddress = (address: string) => {
    if (!address) return ""; // Trường hợp address bị null hoặc undefined
    const parts = address?.split(" - ");
    return parts[parts.length - 1]; // Lấy phần cuối cùng làm tên thành phố
  };

  //tính tổng tiền
  const totalAmount = dataSerials?.reduce((total: Number, item: any) => {
    if (item?.soPrice && item?.primaryQuantity) {
      return (
        Number(total) + Number(item?.soPrice) * Number(item?.primaryQuantity)
      );
    }
    return total;
  }, 0);
  return (
    <div
      ref={componentRef}
      className={styles.containerPrint}
      style={{
        height: "100%",
        margin: "0 auto",
        marginLeft: "40px",
        marginRight: "30px",
        // marginTop: "10px",
        fontFamily: `"Times New Roman", Times, serif`,
        position: "relative",
      }}
    >
      <div
        className={styles.contentMain}
        style={{
          // paddingTop: "10px",
          width: "100%",
          position: "relative",
          margin: "0 auto",
          marginRight: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "2fr 1.5fr 2fr",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h6 style={{ margin: 0, fontSize: "10px", fontWeight: 600 }}>
              Công Ty Cổ Phần Đầu Tư Công Nghệ HACOM
            </h6>
            <p style={{ margin: 0, fontSize: "9px" }}>
              {/* CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà Trưng, TP. Hà Nội */}
              CN: {dataTransaction?.fromBranchAddress}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                alignItems: "center",
                fontSize: "10px",
                marginTop: "-5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <IconPhone
                  stroke={2}
                  style={{
                    width: "10px",
                  }}
                />
                <p style={{ margin: 0, fontSize: "10px" }}>19001903{" |"}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <IconMail
                  stroke={2}
                  style={{
                    width: "10px",
                  }}
                />
                <p style={{ margin: 0, fontSize: "10px" }}>
                  dichvukhachhang@hacom.vn{" |"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "3px",
                }}
                // className={styles.text}
              >
                <IconWorldWww
                  stroke={2}
                  style={{
                    width: "10px",
                  }}
                />
                <p style={{ margin: 0, fontSize: "10px" }}>Hacom.vn</p>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "15px" }}>
            <h1
              style={{
                fontWeight: "700",
                lineHeight: "1",
                fontSize: "18px",
                textAlign: "center",
                alignItems: "center",
                margin: 0,
              }}
            >
              PHIẾU XUẤT KHO
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                gap: "3px",
                fontSize: "12px",
              }}
              // className={styles.warehouseCode}
            >
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  margin: 0,
                }}
              >
                Mã Xuất Kho:{" "}
              </p>{" "}
              {dataTransaction?.transactionCode}
            </div>
            <div
              // className={styles.warehouseCode}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                gap: "3px",
                fontSize: "12px",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  margin: 0,
                }}
              >
                Mã Yêu Cầu Xuất Kho:{" "}
              </p>
              {dataTransaction?.sourceCode}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                fontSize: "14px",
              }}
            >
              <div>
                {getCityFromAddress(dataTransaction?.fromBranchAddress)} ngày{" "}
                <strong style={{ fontStyle: "italic" }}>
                  {formatDateTime(dataTransaction?.finishDate)}
                </strong>{" "}
              </div>
            </div>
          </div>
        </div>

        {/* bảng thông tin bên xuất, bên nhận */}
        <div
          // className={styles.customerInformation}
          style={{
            width: "100%",
            marginTop: "10px",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid black",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={2}
                  style={{ width: "50%", border: "1px solid black" }}
                >
                  BÊN XUẤT
                </th>
                <th
                  colSpan={2}
                  style={{ width: "50%", border: "1px solid black" }}
                >
                  BÊN NHẬN
                </th>
              </tr>
            </thead>
            <tbody>
              {dataTransaction?.tenCty !== null ? (
                <>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Phòng ban
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {dataTransaction?.fromBranchName}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Tên doanh nghiệp
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {" "}
                          {dataTransaction?.tenCty}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Tên Nhà Cung Cấp
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.cusName}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Địa điểm làm việc
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {dataTransaction?.fromBranchAddress}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Họ và tên
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.dvtn !== null
                            ? locationData?.customerName
                            : `${dataTransaction?.cusName}-${dataTransaction?.cusCode}`}
                          {/* {`${dataTransaction?.cusName}-${dataTransaction?.cusCode}`} */}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Mã Nhà Cung Cấp
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.cusCode}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Bộ phận
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {dataTransaction?.departmentName}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Số điện thoại
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {dataTransaction?.cusTelephone} */}
                          {/* {formatPhoneNumber()} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.telephoneNumber
                            : //  formatPhoneNumber()
                              dataTransaction?.cusTelephone}
                        </td>{" "}
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Người nhận
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.toEmpName}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Chức danh
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {dataTransaction?.createRoleName}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Tỉnh / Thành
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {dataTransaction?.cusProvinceName} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.provinceName
                            : dataTransaction?.cusProvinceName}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Số điện thoại
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.cusTelephone}
                          {/* {formatPhoneNumber()} */}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Họ và tên
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {/* {dataTransaction?.createName} */}
                      {dataTransaction?.createName
                        ? dataTransaction?.createName +
                          " - " +
                          dataTransaction?.createCode
                        : ""}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        {" "}
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Quận / Huyện
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {dataTransaction?.cusDestrictName} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.districtName
                            : dataTransaction?.cusDestrictName}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {""}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {""}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Kho
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {/* {dataTransaction?.fromInvName} */}
                      {dataTransaction?.fromInvCode
                        ? `${dataTransaction?.fromInvCode} - ${
                            dataTransaction?.fromInvName || ""
                          }`
                        : dataTransaction?.fromInvName || ""}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Phường / Xã
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {dataTransaction?.cusCommuneName} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.wardName
                            : dataTransaction?.cusCommuneName}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {""}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {""}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  {dataTransaction?.sourceType === "XUAT_BAN" ||
                  dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                    <>
                      <tr>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Hình thức thanh toán
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {typeOfDebtPayment?.payLaterMethod} */}
                          {/* {dataTransaction?.attribute2} */}
                          {dataTransaction?.attribute2?.includes("Công Nợ")
                            ? "Công Nợ"
                            : dataTransaction?.attribute2}
                        </td>

                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Địa chỉ cụ thể
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {dataTransaction?.cusAddress} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.customerAddress
                            : dataTransaction?.cusAddress}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Thời gian công nợ
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {typeOfDebtPayment?.debtInfo
                        ? `${typeOfDebtPayment?.debtInfo.dueDate} ngày`
                        : "0"} */}
                          {dataTransaction?.attribute2?.includes("Công Nợ")
                            ? `${dataTransaction?.attribute2.replace(
                                /[^0-9]/g,
                                ""
                              )} ngày`
                            : ""}
                        </td>

                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Giao hàng
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.gv}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        ></td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        ></td>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Đơn vị tiếp nhận
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.gv === "Nhận tại cửa hàng"
                            ? "Kho"
                            : dataTransaction?.dvtn}
                        </td>
                      </tr>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Phòng ban
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {dataTransaction?.fromBranchName}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        {" "}
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Họ và tên
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {" "}
                          {/* {`${dataTransaction?.cusName}-${dataTransaction?.cusCode}`} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.customerName
                            : `${dataTransaction?.cusName}-${dataTransaction?.cusCode}`}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Tên Nhà Cung Cấp
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.cusName}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Địa điểm làm việc
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {dataTransaction?.fromBranchAddress}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Số điện thoại
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {" "}
                          {/* {dataTransaction?.cusTelephone} */}
                          {/* {formatPhoneNumber()} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.telephoneNumber
                            : // formatPhoneNumber()
                              dataTransaction?.cusTelephone}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Mã Nhà Cung Cấp
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.cusCode}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Bộ phận
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {dataTransaction?.departmentName}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Tỉnh / Thành
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {dataTransaction?.cusProvinceName} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.provinceName
                            : dataTransaction?.cusProvinceName}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Người nhận
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.toEmpName}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Chức danh
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {dataTransaction?.createRoleName}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Quận / Huyện
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {dataTransaction?.cusDestrictName} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.districtName
                            : dataTransaction?.cusDestrictName}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Số điện thoại
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.cusTelephone}
                          {/* {formatPhoneNumber()} */}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Họ và tên
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {/* {dataTransaction?.createName} */}
                      {dataTransaction?.createName
                        ? dataTransaction?.createName +
                          " - " +
                          dataTransaction?.createCode
                        : ""}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        {" "}
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Phường / Xã
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {dataTransaction?.cusCommuneName} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.wardName
                            : dataTransaction?.cusCommuneName}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {""}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {""}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      Kho
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        paddingLeft: "4px",
                      }}
                    >
                      {/* {dataTransaction?.fromInvName} */}
                      {dataTransaction?.fromInvCode
                        ? `${dataTransaction?.fromInvCode} - ${
                            dataTransaction?.fromInvName || ""
                          }`
                        : dataTransaction?.fromInvName || ""}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_BAN" ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Địa chỉ cụ thể
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {dataTransaction?.cusAddress} */}
                          {dataTransaction?.dvtn !== null
                            ? locationData?.customerAddress
                            : dataTransaction?.cusAddress}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {""}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {""}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                  {dataTransaction?.sourceType === "XUAT_BAN" ||
                  dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                    <>
                      <tr>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Hình thức thanh toán
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {typeOfDebtPayment?.payLaterMethod} */}
                          {/* {dataTransaction?.attribute2} */}
                          {dataTransaction?.attribute2?.includes("Công Nợ")
                            ? "Công Nợ"
                            : dataTransaction?.attribute2}
                        </td>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Giao hàng
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.gv}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Thời gian công nợ
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {/* {typeOfDebtPayment?.debtInfo
                        ? `${typeOfDebtPayment?.debtInfo.dueDate} ngày`
                        : "0"} */}
                          {dataTransaction?.attribute2?.includes("Công Nợ")
                            ? `${dataTransaction?.attribute2.replace(
                                /[^0-9]/g,
                                ""
                              )} ngày`
                            : ""}
                        </td>

                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          Đơn vị tiếp nhận
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            paddingLeft: "4px",
                          }}
                        >
                          {dataTransaction?.gv === "Nhận tại cửa hàng"
                            ? "Kho"
                            : dataTransaction?.dvtn}
                        </td>
                      </tr>
                    </>
                  ) : null}
                </>
              )}
            </tbody>
          </table>
        </div>

        {/* lý do */}
        <div style={{ margin: "5px 0" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ margin: 0, marginRight: "5px" }}>Lý do:</p>
            <p style={{ margin: 0 }}>
              {dataTransaction?.sourceType === "XUAT_BAN"
                ? "Xuất Bán"
                : dataTransaction?.sourceType === "XUAT_DOI_TRA"
                ? "Xuất đổi trả"
                : dataTransaction?.sourceType === "XUAT_TRA_NCC"
                ? "Xuất trả Nhà Cung Cấp"
                : ""}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <p style={{ margin: 0 }}>Ghi chú:</p>
            <p style={{ margin: 0 }}>{dataTransaction?.description}</p>
          </div>
        </div>
        {/* table sản phẩm */}
        <div
          // className={styles.ProductMain}
          style={{
            width: "100%",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid black",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  STT
                </th>
                <th
                  style={{
                    width: "40px",
                    border: "1px solid black",
                  }}
                >
                  Mã Hàng
                </th>
                {(dataTransaction?.sourceType === "XUAT_BAN" &&
                  valuePrintOptionModal?.option1 === "Serial") ||
                dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                  <th
                    style={{
                      width: "20px",
                      border: "1px solid black",
                    }}
                  >
                    Serial
                  </th>
                ) : null}

                <th
                  // className={styles.thNameProduct}
                  style={{
                    width: "370px",
                    border: "1px solid black",
                  }}
                >
                  Tên sản phẩm
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  ĐVT
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  SL
                </th>
                {dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                  <>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      ĐVT2
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      SL2
                    </th>
                  </>
                ) : dataTransaction?.sourceType === "XUAT_BAN" &&
                  valuePrintOptionModal?.option2 === "coGia" ? (
                  <>
                    <>
                      <th
                        style={{
                          border: "1px solid black",
                          width: "60px",
                        }}
                      >
                        Giá bán
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          width: "130px",
                        }}
                      >
                        Thành tiền
                      </th>
                    </>
                  </>
                ) : (
                  ""
                )}

                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Tình trạng
                </th>
                {dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                  <></>
                ) : dataTransaction?.sourceType === "XUAT_BAN" ||
                  dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                  <>
                    <th
                      style={{
                        border: "1px solid black",
                        width: "150px",
                      }}
                    >
                      Thời gian BH
                    </th>
                  </>
                ) : (
                  ""
                )}
                <th
                  style={{
                    border: "1px solid black",
                    width: "150px",
                  }}
                >
                  Ghi chú
                </th>
              </tr>
            </thead>
            <tbody>
              <>
                <tr>
                  <td
                    colSpan={
                      (dataTransaction?.sourceType === "XUAT_BAN" &&
                        valuePrintOptionModal?.option1 === "Serial") ||
                      dataTransaction?.sourceType === "XUAT_DOI_TRA"
                        ? 6
                        : 5
                    }
                    style={{
                      textAlign: "end",
                      marginRight: "5px",
                      border: "none",
                      paddingRight: "5.5px",
                    }}
                  >
                    {totalQuantity}
                  </td>
                  <td style={{ border: "none" }}></td>
                  {dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                    <>
                      <td style={{ border: "none" }}></td>
                    </>
                  ) : dataTransaction?.sourceType === "XUAT_BAN" ? (
                    <>
                      {valuePrintOptionModal &&
                        valuePrintOptionModal?.option2 === "coGia" && (
                          <>
                            <td style={{ textAlign: "end", border: "none" }}>
                              {formatCurrency(totalAmount)}
                            </td>
                          </>
                        )}
                    </>
                  ) : (
                    ""
                  )}
                  <td style={{ border: "none" }} colSpan={2}></td>
                </tr>
              </>

              {dataSerials?.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td
                      style={{ textAlign: "center", border: "1px solid black" }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {" "}
                      {item?.itemCode}
                    </td>
                    {(dataTransaction?.sourceType === "XUAT_BAN" &&
                      valuePrintOptionModal?.option1 === "Serial") ||
                    dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <td
                        style={{
                          width: "20px",
                          wordWrap: "break-word",
                          border: "1px solid black",
                        }}
                      >
                        {item?.serial}
                      </td>
                    ) : null}

                    <td
                      style={{
                        // width: "380px !important",
                        textAlign: "start",
                        border: "1px solid black",
                        paddingLeft: "3px",
                      }}
                    >
                      {item?.itemName}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {item?.primaryUomCode}{" "}
                    </td>
                    <td
                      style={{ textAlign: "center", border: "1px solid black" }}
                    >
                      {item?.primaryQuantity}
                    </td>
                    {dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <>
                        <td
                          style={{
                            border: "1px solid black",
                          }}
                        >
                          {item?.subUomCode}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                          }}
                        >
                          {item?.subQuantity}
                        </td>
                      </>
                    ) : dataTransaction?.sourceType === "XUAT_BAN" &&
                      valuePrintOptionModal?.option2 === "coGia" ? (
                      <>
                        <>
                          <td
                            style={{
                              textAlign: "end",
                              border: "1px solid black",
                            }}
                          >
                            {formatCurrency(item?.soPrice)}
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              border: "1px solid black",
                            }}
                          >
                            {formatCurrency(
                              item?.soPrice * item?.primaryQuantity
                            )}
                          </td>
                        </>
                      </>
                    ) : (
                      ""
                    )}

                    <td
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {item?.serialStatus}
                    </td>
                    {/* <td>Mới 100%</td> */}

                    {dataTransaction?.sourceType === "XUAT_TRA_NCC" ? (
                      <></>
                    ) : dataTransaction?.sourceType === "XUAT_BAN" ||
                      dataTransaction?.sourceType === "XUAT_DOI_TRA" ? (
                      <>
                        <td
                          style={{
                            textAlign: "end",
                            border: "1px solid black",
                          }}
                        >
                          {/* 30 ngày */}
                          {item?.attribute3 == 0 || item?.attribute3 == null
                            ? ""
                            : item?.attribute3 + " tháng"}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                    <td
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {item?.note}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/*  */}
        <div style={{ pageBreakInside: "avoid" }}>
          <div style={{ marginTop: "5px" }}>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                fontSize: "14px",
              }}
            >
              <div>
                Hà Nội ngày{" "}
                <strong style={{ fontStyle: "italic" }}>{currentDate}</strong>{" "}
              </div>
            </div> */}

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                width: "100%",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <p style={{ fontWeight: "700" }}>BÊN XUẤT</p>
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {dataTransaction?.createName}
                  </p>
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <p style={{ fontWeight: "700" }}>GIAO VẬN</p>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <p style={{ fontWeight: "700" }}>BÊN NHẬN</p>
              </div>
            </div>
          </div>
        </div>
        {/* thông tin bảo hành */}
        <div
          style={{
            borderTop: "1px solid black",
            pageBreakInside: "auto",
            margin: 0,
            paddingTop: "10px",
            paddingLeft: "15px",
          }}
        >
          <ul
            style={{
              margin: 0,
              padding: 0,
              listStyleType: "square",
            }}
          >
            <li>
              Quý Khách vui lòng kiểm tra hàng hóa trước khi nhận hàng. Hàng đã
              mua vui lòng không đổi trả.
            </li>
            <li>
              Đối với khách mua hàng trực tiếp tại cửa hàng: Quý Khách vui lòng
              kiểm tra hàng hóa trước khi rời cửa hàng.
            </li>
            <li>
              Đối với khách mua hàng online: Quý Khách vui lòng đồng kiểm với
              nhân viên giao hàng, mọi vấn đề liên quan đến thiếu hàng, sai
              hàng, hàng lỗi vật lý… vui lòng phản hồi ngay lập tức với chúng
              tôi.{" "}
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                HACOM
              </span>{" "}
              từ chối giải quyết nếu Quý Khách phản hồi sau khi nhận hàng.{" "}
            </li>
            <li>
              Nếu thu ngân không xuất hóa đơn hoặc phát hiện có dấu hiệu gian
              lận thì Quý Khách vui lòng gọi vào số{" "}
              <span style={{ fontWeight: 600 }}>19001903</span> để phản ánh.
            </li>
            <li>
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                HACOM
              </span>{" "}
              chỉ xuất hóa đơn VAT trong ngày.
            </li>

            <li>
              Quý Khách vui lòng giữ lại đầy đủ bao bì, linh phụ kiện(nếu có)
              trong <span style={{ fontWeight: 600 }}>15</span> ngày & đọc kỹ:
              <ul
                style={{
                  margin: 0,
                  padding: 0,
                  listStyleType: "square",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "200px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <IconArrowBadgeRightFilled
                        style={{
                          width: "15px",
                        }}
                      />{" "}
                      <span style={{ fontWeight: 600 }}>
                        Quy Định Trả Hàng Tính Phí
                      </span>{" "}
                    </div>

                    <div>
                      <span
                        style={{
                          fontStyle: "italic",
                          textDecoration: "underline",
                        }}
                      >
                        :hacom.vn/hacom-chinh-sach-nhap-lai-tinh-phi
                      </span>
                    </div>
                  </div>
                </li>
                <li
                  style={{
                    listStyleType: "none",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "200px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <IconArrowBadgeRightFilled
                        style={{
                          width: "15px",
                        }}
                      />
                      <span style={{ fontWeight: 600 }}>
                        Chính Sách Bảo Hành
                      </span>{" "}
                    </div>

                    <div>
                      <span
                        style={{
                          fontStyle: "italic",
                          textDecoration: "underline",
                        }}
                      >
                        :hacom.vn/hacom-chinh-sach-bao-hanh
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li>
              Quý Khách tra cứu thông tin bảo hành tại:
              <span
                style={{
                  fontStyle: "italic",
                  textDecoration: "underline",
                }}
              >
                {" "}
                hacom.vn/tra-bao-hanh
              </span>
            </li>
            <li>
              Mọi góp ý, thắc mắc, khiếu nại Quý Khách vui lòng liên hệ với bộ
              phận CSKH theo hotline{" "}
              <span style={{ fontWeight: 600 }}>19001903</span> hoặc tại{" "}
              <span
                style={{
                  fontStyle: "italic",
                  textDecoration: "underline",
                }}
              >
                hacom.vn/lien-he
              </span>
            </li>

            {/* <li>Quý Khách đánh giá chất lượng hàng hóa, dịch vụ tại: ………………</li> */}
            <li>
              Việc ký vào văn bản này đồng nghĩa với việc Quý Khách xác nhận
              đồng ý với các quy định, chính sách của{" "}
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                HACOM
              </span>{" "}
              & xác nhận đã nhận đúng, đầy đủ hàng hóa kèm linh phụ kiện(nếu có)
              & hàng hóa không bị hư hỏng vật lý.
            </li>
          </ul>
        </div>
      </div>
      <div
        // className={styles.backgroudHacom}
        style={{
          position: "absolute",
          width: "100%",
          zIndex: -1,
          opacity: 0.1,
          top: "40%",
        }}
      >
        <Image
          radius="md"
          src="https://hanoicomputercdn.com/media/lib/17-10-2024/logo-hacom-since-2001_new.png"
          // className={styles.logo}
          style={{
            width: "90%",
            margin: "0 auto",
          }}
        />
      </div>
    </div>
  );
};

export default PrintWarehouseOutWard;
