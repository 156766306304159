import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Menu,
  rem,
  Select,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCaretDown,
  IconFileExport,
  IconPlus,
  IconRefresh,
  IconSearch,
  IconStatusChange,
  IconTable,
  IconTimeDuration15,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
  formatToReverseDate,
} from "../../../common/FormatDate/FormatDate";
import { BASE_API_MDM } from "../../../config";
import { _localization } from "../../../config/location";

type dataSubmit = {
  keySearch: string;
  typeMessage: string;
  startDate: string;
  endDate: string;
  status: string;
};

const formatDateNotTimeZoneee = (
  date: Date | string | null | undefined
): string => {
  if (date === null || date === undefined) {
    return "";
  }
  return moment(date).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
};
type Props = {
  type: number;
};
const SyncCheckingExist = ({ type }: Props) => {
  console.log("type", type);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [pagination, setPagination] = useState(paginationBase);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [visible, { toggle, open, close }] = useDisclosure(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [selectIds, setSelectIds] = useState<string[]>([]); // lưu những dữ liệu đã tích

  const form = useForm({
    mode: "controlled",
    initialValues: {
      keySearch: "",
      skip: 0,
      take: 100,
      typeMessage: "",
      startDate: formatDateNotTimeZoneee(new Date()),
      endDate: formatDateNotTimeZoneee(new Date()),
      status: "",
    },
  });
  const dataSelectTable = [
    {
      value: "",
      label: "Tất cả bảng",
    },
    {
      value: "TblDmCustomer",
      label: "Khách hàng",
    },
    {
      value: "TblSalesOrder",
      label: "Hóa đơn bán lẻ",
    },
    {
      value: "TblSalesReturn",
      label: "Đơn trả lại",
    },
    {
      value: "TblDmFee",
      label: "Danh mục mã phí",
    },

    {
      value: "TblCashReceipt",
      label: "Phiếu thu",
    },
    {
      value: "TblCashDisbursement",
      label: "Phiếu chi",
    },
    {
      value: "TblCreditNote",
      label: "Phiếu báo có",
    },
    {
      value: "TblDebitNote",
      label: "Phiếu báo nợ",
    },
    {
      value: "TblReceiptNote",
      label: "Phiếu nhập",
    },
    {
      value: "TblDeliveryNote",
      label: "Phiếu xuất",
    },
    {
      value: "TblWarehouseTransfer",
      label: "Phiếu xuất bán",
    },
  ];
  const dataSelectTable2 = [
    {
      value: "",
      label: "Tất cả bảng",
    },
    {
      value: "TblDmVendor",
      label: "Nhà cung cấp",
    },
    {
      value: "TblPurchaseReceipt",
      label: "Phiếu nhập mua hàng hàng NCC",
    },
    {
      value: "TblPurchaseReturned",
      label: "Phiếu xuất trả NCC",
    },
  ];
  //Call api danh sách thiếu sót đơn đồng bộ
  const fetchData = async (data: dataSubmit) => {
    console.log("data", data);
    const processedData = {
      ...data,
      typeMessage: data.typeMessage ?? "",
      status:
        data.status === "true"
          ? true
          : data.status === "false"
          ? false
          : data.status,
      skip: pagination?.pageIndex * pagination.pageSize,
      take: pagination.pageSize,
    };
    if (processedData.startDate === "" || processedData.endDate === "") {
      NotificationExtension.Fails(
        "Vui lòng chọn ngày bắt đầu và ngày kết thúc"
      );
      return;
    }
    open();
    try {
      const response = await repositoryMdm.post(
        "/api/v1/SyncData/get-sync-checking-exist",
        processedData
      );
      if (response && response.success) {
        setData(response.data);
        // console.log("response", response.data);
        setRowCount(response.totalCount);
        // setRowCount(100);
      } else {
        // NotificationExtension.Fails("Không tìm thấy dữ liệu");
        setData([]);
        setRowCount(0);
      }
    } catch (error) {
      // NotificationExtension.Fails("Không tìm thấy dữ liệu");
      setData([]);
      setRowCount(0);
      console.log("error", error);
    } finally {
      close();
    }
  };
  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "doi_chieu_dong_bo_du_lieu.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };
  const exportDataExcel = async () => {
    const dataSSubmit = {
      typeMessage: form.getValues().typeMessage,
      startDate: form.getValues().startDate,
      endDate: form.getValues().endDate,
      status:
        form.getValues().status === "true"
          ? true
          : form.getValues().status === "false"
          ? false
          : null,
    };
    const url = `/api/v1/ExportExcel/export-checking-list-sync`;

    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "POST",
      headers: {
        // "Content-Type": "application/vnd.ms-excel",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
      body: JSON.stringify(dataSSubmit),
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "doi_chieu_dong_bo_du_lieu.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  //Đồng bộ tất cả
  const handleDongBoAll = async (type: number) => {
    const Codes = data.map((item: any) => item.data_json);
    if (Codes) {
      const valueSubmit = {
        typeMessage: form.getValues().typeMessage,
        codes: selectIds.length > 0 ? selectIds : Codes,
        startDate: formatToReverseDate(form.getValues().startDate),
        endDate: formatToReverseDate(form.getValues().endDate),
        type: type, //type 0 là tạo mới, 1 là cập nhật,2 là delete
        skip: 0,
        take: 100,
      };
      if (valueSubmit) {
        try {
          const response = await repositoryMdm.post(
            "/api/v1/SyncData/repeat-sync-checking-exist",
            valueSubmit
          );
          if (response && response.success) {
            setTimeout(() => {
              fetchData(form.getValues());
            }, 1700);
            setTimeout(() => {
              if (type === 0) {
                NotificationExtension.Success("Tạo lại đơn thành công");
              } else if (type === 1) {
                NotificationExtension.Success("Cập nhật dữ liệu thành công");
              }
            }, 500);
          } else {
            setTimeout(() => {
              if (type === 0) {
                NotificationExtension.Success("Tạo lại đơn không thành công");
              } else if (type === 1) {
                NotificationExtension.Success(
                  "Cập nhật dữ liệu không thành công"
                );
              }
            }, 500);
          }
        } catch (error) {
          if (type === 0) {
            NotificationExtension.Success("Tạo lại đơn không thành công");
          } else if (type === 1) {
            NotificationExtension.Success("Cập nhật dữ liệu không thành công");
          }
        }
      }
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  // Xử lý đồng bộ, cập nhật
  const handleDongBo = async (value: any, type: number) => {
    setIsLoading(true);
    setIsRefetching(true);
    const valueSubmit = {
      typeMessage: form.getValues().typeMessage,
      codes: value,
      startDate: formatToReverseDate(form.getValues().startDate),
      endDate: formatToReverseDate(form.getValues().endDate),
      type: type, //type 0 là tạo mới, 1 là cập nhật,2 là delete
      skip: 0,
      take: 100,
    };

    if (valueSubmit) {
      try {
        const response = await repositoryMdm.post(
          "/api/v1/SyncData/repeat-sync-checking-exist",
          valueSubmit
        );
        if (response && response.success) {
          setTimeout(() => {
            fetchData(form.getValues());
          }, 1700);
          setTimeout(() => {
            if (type === 0) {
              NotificationExtension.Success("Gửi đồng bộ dữ liệu thành công");
            } else if (type === 1) {
              NotificationExtension.Success("Cập nhật dữ liệu thành công");
            }
          }, 500);
        } else {
          if (type === 0) {
            NotificationExtension.Success("Gửi đồng bộ dữ liệu thành công");
          } else if (type === 1) {
            NotificationExtension.Success("Cập nhật dữ liệu thành công");
          }
        }
      } catch (error) {
        if (type === 0) {
          NotificationExtension.Success("Gửi đồng bộ dữ liệu không thành công");
        } else if (type === 1) {
          NotificationExtension.Success("Cập nhật dữ liệu không thành công");
        }
      }
    }
    setIsLoading(false);
    setIsRefetching(false);
  };
  // console.log("data", data);
  // console.log("form.getValues()", form.getValues());
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "data_json",
        header: "Dữ liệu",
        // size: 200,
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge variant="dot" color="green" size="lg" radius="sm">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "type_message",
        header: "Tên thể loại bảng",
        // size: 50,
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
      },

      {
        accessorKey: "ngay_ct",
        header: "Ngày chứng từ",
        // size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
        // size: 170,
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge
              color={renderedCellValue === false ? "red" : "green"}
              radius="sm"
            >
              {renderedCellValue === false ? "Không thành công" : "Thành công"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "error",
        header: "Lỗi",
        Cell: ({ renderedCellValue }) => (
          <Textarea
            readOnly
            autosize
            style={{ width: "500px" }}
            value={
              `${renderedCellValue}` == "null" ? "" : `${renderedCellValue}`
            }
            minRows={3}
            maxRows={3}
          />
        ),
      },
      {
        accessorKey: "count_repeat",
        header: "Lần chạy lại",
        // size: 10,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "create_date",
        header: "Ngày tạo",
        // size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        // size: 20,
        Cell: ({ row }) => (
          <Box>
            <Flex direction={"row"} align={"center"} gap={"sm"}>
              <Tooltip label="Đồng bộ">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="violet"
                  onClick={() => handleDongBo([row.original.data_json], 0)}
                >
                  <IconUpload size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              {/* <Tooltip label="Cập nhật">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="orange"
                  onClick={() => handleDongBo([row.original.data_json], 1)}
                >
                  <IconEdit size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip> */}
            </Flex>
          </Box>
        ),
      },
    ],
    [data, rowCount]
  );
  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableColumnResizing: true,

    enableTopToolbar: false,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "data_json"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: true,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 550,
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    fetchData(form.getValues());
  }, [pagination]);

  useEffect(() => {
    // Lấy danh sách chỉ số của các hàng được chọn
    const selectedIndexes = Object.keys(rowSelection).map(Number);
    // Lấy danh sách ID dựa trên các chỉ số
    const data_jsons = selectedIndexes.map((index) => data[index]?.data_json);
    // Cập nhật mảng ID đã chọn
    setSelectIds(data_jsons);
  }, [rowSelection, data]);
  // console.log("selectIds", selectIds);
  // console.log("data", data);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (260 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <Flex
        direction={"row"}
        align={"center"}
        justify={"end"}
        style={{
          border: "1px solid #dee2e6",
          overflowX: "hidden",
          padding: "10px 10px",
        }}
      >
        <Menu shadow="md" width={220}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(16), height: rem(16) }} />
              }
            >
              Chức năng đồng bộ
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconPlus style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleDongBoAll(0)}
            >
              Tạo lại đơn
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconRefresh style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleDongBoAll(1)}
            >
              Cập nhật đơn
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleDongBoAll(2)}
            >
              Xóa đơn
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              // onClick={handleExport}
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <Flex
        direction={"row"}
        mt={10}
        justify={"space-between"}
        align={"center"}
      >
        <form
          onSubmit={form.onSubmit(async (e) => {
            await fetchData(e);
          })}
        >
          {/* <LoadingOverlay
            visible={visible}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          /> */}
          <Flex direction={"row"} align={"center"} gap={"md"} wrap="wrap">
            <Input
              size="sm"
              w={200}
              placeholder="Từ khóa"
              {...form.getInputProps("keySearch")}
              leftSection={<IconSearch color="#15aabf" />}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  form.onSubmit(async (e) => {
                    await fetchData(e);
                  });
                }
              }}
            />
            <Select
              size="sm"
              w={200}
              leftSection={<IconTable color="#15aabf" />}
              placeholder="Chọn tên thể loại bảng"
              searchable
              clearable
              data={type === 1 ? dataSelectTable : dataSelectTable2}
              key={form.key("typeMessage")}
              {...form.getInputProps("typeMessage")}
            />
            <Select
              size="sm"
              w={200}
              leftSection={<IconStatusChange color="#15aabf" />}
              placeholder="Chọn trạng thái"
              searchable
              clearable
              data={[
                {
                  value: "true",
                  label: "Thành công",
                },
                {
                  value: "false",
                  label: "Không thành công",
                },
              ]}
              value={form.getValues().status}
              key={form.key("status")}
              onChange={(e) => {
                form.setFieldValue("status", e || "");
              }}
              renderOption={(option) => {
                return (
                  <Badge
                    color={option.option.value === "true" ? "green" : "red"}
                  >
                    {option.option.label}
                  </Badge>
                );
              }}
            />
            <DatePickerInput
              size="sm"
              w={200}
              placeholder="Ngày bắt đầu"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              onChange={(e) => {
                form.setFieldValue(
                  "startDate",
                  formatDateNotTimeZone(e) || "" // Định dạng StartTime
                );
              }}
              clearable
              defaultValue={new Date()}
            />
            <DatePickerInput
              size="sm"
              w={200}
              placeholder="Ngày kết thúc"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              onChange={(e) => {
                form.setFieldValue(
                  "endDate",
                  formatDateNotTimeZone(e) || "" // Định dạng StartTime
                );
              }}
              clearable
              defaultValue={new Date()}
            />

            <Button
              type="submit"
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
            >
              Tìm kiếm
            </Button>
          </Flex>
        </form>
        <div>
          {/* <Box>
            <Button
              color="blue"
              variant="outline"
              leftSection={<IconUpload size={14} />}
              onClick={() => handleDongBoAll()}
            >
              Đồng bộ tất cả
            </Button>
          </Box> */}
        </div>
      </Flex>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default SyncCheckingExist;
