import React, { useEffect, useState } from "react";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import {
  Badge,
  Button,
  Flex,
  Grid,
  Group,
  Input,
  Modal,
  NumberFormatter,
  NumberInput,
  Select,
  SelectProps,
  Table,
  Text,
  Textarea,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBuildingSkyscraper,
  IconCheck,
  IconPlus,
  IconSearch,
  IconTimeDuration15,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { _localization } from "../../../config/location";
import { green } from "../../../const/variables";
import { useNavigate } from "react-router-dom";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { IconMinus } from "@tabler/icons-react";

const data = [
  {
    receiptNumber: "PTĐ20240912000123",
    status: "Đã điều chỉnh",
    createdDate: "2024-09-12 10:30",
    completionTime: "2024-09-12 12:00",
    payertype: "Cá nhân",
    funddepartment: "Phòng Kinh doanh",
    fundbranch: "Quản lý",
    payerdescription: "Thanh toán dịch vụ",
    payernote: "Khẩn cấp",
    totalAmount: 5000000,
  },
  {
    receiptNumber: "PTĐ20240911000124",
    status: "Chưa điều chỉnh",
    createdDate: "2024-09-11 09:00",
    completionTime: null,
    payertype: "Doanh nghiệp",
    funddepartment: "Phòng Tài chính",
    fundbranch: "Giám đốc",
    payerdescription: "Đăng ký hàng năm",
    payernote: "Chờ phê duyệt",
    totalAmount: 12000000,
  },
  {
    receiptNumber: "PTĐ20240910000125",
    status: "Đã từ chối",
    createdDate: "2024-09-10 15:45",
    completionTime: "2024-09-10 17:00",
    payertype: "Cá nhân",
    funddepartment: "Phòng Nhân sự",
    fundbranch: "Trợ lý",
    payerdescription: "Phí dịch vụ",
    payernote: "",
    totalAmount: 3000000,
  },
  // Thêm dữ liệu giả khác nếu cần
];

const AdjustmentSlip = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const sizeGrid = { base: 6, sm: 3, md: 3, lg: 3, xl: 3 };
  const [opened, { open, close }] = useDisclosure(false);

  const TypeTicket: SelectProps["renderOption"] = ({ option, checked }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          option.value === "1"
            ? "green"
            : option.value === "2"
            ? "yellow"
            : "red"
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "receiptNumber",
        header: "Mã phiếu thu",
        size: 250,
        Cell: ({ renderedCellValue, cell, row }) => (
          <Tooltip label="Chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              // onDoubleClick={async () => {
              //   navigate("/bill/detail-payment-receipt", {
              //     state: {
              //       id: row.original.id,
              //       type: row.original.type,
              //       receptype: row.original.typeReceipt,
              //     },
              //   });
              //   // detailReceipts(row.original.id, row.original.type);
              // }}
            >
              {renderedCellValue === null
                ? "Không xác định"
                : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },

      {
        accessorKey: "createdDate",
        header: "Thời gian tạo ",
      },
      {
        accessorKey: "completionTime",
        header: "Thời gian hoàn thành ",
        size: 200,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        Cell: ({ renderedCellValue, row }) => {
          const payerType = row.original.status;
          let badgeColor;

          switch (payerType) {
            case "Đã điều chỉnh":
              badgeColor = "green";
              break;
            case "Chưa điều chỉnh":
              badgeColor = "yellow";
              break;
            case "Đã từ chối":
              badgeColor = "red";
              break;
            default:
              badgeColor = "gray";
          }

          return <Badge color={badgeColor}>{renderedCellValue}</Badge>;
        },
      },
      {
        accessorKey: "payertype",
        header: "Loại phiếu thu",
      },
      {
        accessorKey: "funddepartment",
        header: "Phòng ban người nộp tiền",
        size: 225,
      },
      {
        accessorKey: "fundbranch",
        header: "Chức danh người nộp tiền",
        size: 225,
      },
      {
        accessorKey: "payerdescription",
        header: "Nội dung",
      },
      {
        accessorKey: "payernote",
        header: "Ghi chú",
      },
      {
        accessorKey: "totalAmount",
        header: "Tổng số tiền",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={700}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              thousandSeparator=","
            />
          </Text>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable<any>({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false,
    // getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["receiptNumber", "mrt-row-select"],
        right: ["totalAmount"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    // enableExpanding: true,
    // getSubRows: (originalRow) => originalRow.orderDetail,
    mantineTableProps: {
      striped: true,
    },
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);
  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px 15px" }}
        ref={headerRef}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <Grid>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Từ khóa"
                  type="text"
                  //   onChange={(e) => {
                  //     handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  //   }}
                  leftSection={<IconSearch color="#15aabf" />}
                />
              </Grid.Col>
              <Grid.Col
                span={sizeGrid}
                // style={{ flexBasis: "100%", maxWidth: "320px", flex: "1" }}
              >
                <DatePickerInput
                  type="range"
                  leftSection={<IconTimeDuration15 color="#15aabf" />}
                  size="sm"
                  placeholder="Chọn ngày"
                  locale="vi"
                  //   value={value}
                  valueFormat="DD/MM/YYYY"
                  //   onChange={handleRangeChange}
                  clearable
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Tình trạng"
                  limit={50}
                  data={[
                    { value: "1", label: "Đã điều chỉnh" },
                    { value: "2", label: "Chưa điều chỉnh" },
                    { value: "3", label: "Đã từ chối" },
                  ]}
                  renderOption={TypeTicket}
                  leftSection={<IconBuildingSkyscraper color="#15aabf" />}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  //   data={dataFetch
                  //     .filter((item) => item.text !== "Tất cả")
                  //     .map((item) => {
                  //       return {
                  //         label: item.text ?? "",
                  //         value: String(item.value ?? ""),
                  //       };
                  //     })}
                  //   onClick={async () => {
                  //     if (!dataFetch || dataFetch.length < 1) {
                  //       await fetchDataSelect();
                  //     }
                  //     return;
                  //   }}
                  //   onChange={(value) => {
                  //     handleChangeSearchValue(value ?? "", "department");
                  //   }}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    // await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <Group justify="flex-end">
              <Button
                onClick={async () => {
                  navigate("/bill/add-adjustment-slip");
                  // detailReceipts(row.original.id, row.original.type);
                }}
                variant="filled"
                leftSection={<IconPlus size={18} />}
              >
                Thêm mới
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default AdjustmentSlip;
