import {
  Badge,
  Box,
  Button,
  Group,
  Input,
  Menu,
  MultiSelect,
  MultiSelectProps,
  Select,
  Text,
  rem,
} from "@mantine/core";
import {
  IconBarcode,
  IconCaretDown,
  IconFileBarcode,
  IconFileExport,
  IconPhone,
  IconSearch,
  IconUser,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { BASE_API_MDM } from "../../../config";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { SelectListItemModel } from "../../../model/SelectListItem";

interface DatacustomerCodeModel {
  branchId: number | null;
  loaiCt: string | null;
  soCt: string | null;
  ngayCt: string | null;
  serial: string | null;
  serialThayThe: string | null;
  custId: number;
  itemId: string | null;
  maVt: string | null;
  tenVt: string | null;
  maKh: string;
  tenKh: string | null;
  soDt: string | null;
  dienGiai: string | null;
  thoiHanBhNcc: string | null;
  soCtGoc: string | null;
  text: string;
  value: string;
}

const BarcodeHistory = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<Department[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [dataCustomerCode, setDataCustomerCode] = useState<
    SelectListItemModel[]
  >([]);
  const [dataVTCode, setDataVTCode] = useState<SelectListItemModel[]>([]);
  // const [dataItemCode, setDataItemCode] = useState<any>([]);
  // const [change, setchange] = useState<{ maCn: boolean; maKho: boolean }>({
  //   maCn: true,
  //   maKho: true,
  // });
  const [dataItemCode, setDataItemCode] = useState<SelectListItemModel[]>([]);
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        enableColumnActions: false,
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "soCtGoc",
        enableColumnActions: false,
        header: "Chứng từ gốc",

        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
        size: 220,
      },
      {
        accessorKey: "soCt",
        enableColumnActions: false,
        header: "Số chứng từ",
        size: 200,
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "branchCode",
        enableColumnActions: false,
        header: "Mã chi nhánh",
        Cell: ({ row, renderedCellValue }) =>
          renderedCellValue === null ? null : (
            <>
              <Badge variant="light" color={"violet"}>
                {renderedCellValue}
              </Badge>
            </>
          ),
      },
      {
        accessorKey: "branchName",
        enableColumnActions: false,
        header: "Tên chi nhánh",
      },
      {
        accessorKey: "inventoryCode",
        enableColumnActions: false,
        header: "Mã kho",
        Cell: ({ row, renderedCellValue }) =>
          renderedCellValue === null ? null : (
            <>
              <Badge variant="light" color={"blue"}>
                {renderedCellValue}
              </Badge>
            </>
          ),
      },
      {
        accessorKey: "inventoryName",
        enableColumnActions: false,
        header: "Tên kho",
      },
      {
        accessorKey: "loaiCt",
        enableColumnActions: false,
        header: "Loại chứng từ",
      },
      {
        accessorKey: "ngayCt",
        enableColumnActions: false,
        header: "Ngày chứng từ",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "serial",
        enableColumnActions: false,
        header: "Mã vạch",
        Cell: ({ renderedCellValue, cell, row }) => (
          <Box
            style={{
              borderRadius: "30px",
              backgroundColor: "#E8F3FC",
              padding: "4px",
            }}
          >
            <Text variant="light" p="4px 8px" size="xs" color="blue" fw="bold">
              {renderedCellValue ?? ""}
            </Text>
          </Box>
        ),
      },
      {
        accessorKey: "serialThayThe",
        enableColumnActions: false,
        header: "Mã vạch thay thế",
        Cell: ({ renderedCellValue, cell, row }) => (
          <Box
            style={{
              borderRadius: "30px",
              backgroundColor: "#E8F3FC",
              padding: "4px",
            }}
          >
            <Text variant="light" p="4px 8px" size="xs" color="blue" fw="bold">
              {renderedCellValue ?? ""}
            </Text>
          </Box>
        ),
      },
      {
        accessorKey: "maVt",
        enableColumnActions: false,
        header: "Mã vật tư",
        Cell: ({ renderedCellValue, cell, row }) => (
          <Box
            style={{
              borderRadius: "30px",
              backgroundColor: "#E8F3FC",
              padding: "4px",
            }}
          >
            <Text variant="light" p="4px 8px" size="xs" color="blue" fw="bold">
              {renderedCellValue}
            </Text>
          </Box>
        ),
      },
      {
        accessorKey: "tenVt",
        enableColumnActions: false,
        header: "Tên vật tư",
        size: 700,
      },
      {
        accessorKey: "maKh",
        enableColumnActions: false,
        header: "Mã khách hàng",
        Cell: ({ renderedCellValue, cell, row }) => (
          <Box
            style={{
              borderRadius: "30px",
              backgroundColor: "#E8F3FC",
              padding: "4px",
            }}
          >
            <Text variant="light" p="4px 8px" size="xs" color="blue" fw="bold">
              {renderedCellValue ?? ""}
            </Text>
          </Box>
        ),
      },
      {
        accessorKey: "tenKh",
        enableColumnActions: false,
        header: "Tên khách hàng",
      },
      {
        accessorKey: "soDt",
        enableColumnActions: false,
        header: "Số điện thoại",
      },
      {
        accessorKey: "dienGiai",
        enableColumnActions: false,
        header: "Diễn giải",
      },

      {
        accessorKey: "thoiHanBhNcc",
        enableColumnActions: false,
        header: "Hạn bảo hành NCC",
      },
    ],
    []
  );
  const handleChangeSearch = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };
  const [search, setSearch] = useState({
    keySearch: "",
    itemCode: [],
    serial: "",
    maKh: "",
    maVt: "",
    soDienThoai: "",
  });
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch?.trim()?.toLowerCase()}`;
    }
    if (search.serial) {
      url += `&Serial=${search.serial?.trim()}`;
    }
    if (search.maKh) {
      url += `&MaKh=${search.maKh}`;
    }
    if (search.itemCode.length > 0) {
      url += `&MaVt=${search.itemCode}`;
    }
    if (search.soDienThoai) {
      url += `&SoDt=${search.soDienThoai.trim()}`;
    }
    try {
      const response = await repositoryMdm.get(
        `api/v1/VSerialHistory/get-list${url}`
      );

      if (response && response.success) {
        let result = response.data.lists;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  const exportDataExcel = async () => {
    // const baseUrl = `https://apis-pos-dev.hacom.vn/api-mdm/api/v1/ExportExcel/export-lich-su-ma-vach`;
    // const fileName = "bao_cao_lich_su_ma_vach.xlsx";
    // exportDataToExcel(baseUrl, fileName);
    const url = `/api/v1/ExportExcel/export-lich-su-ma-vach`;
    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "bao_cao_lich_su_ma_vach.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  const DatacustomerCode = async () => {
    try {
      const response = await repositoryMdm.get<
        MessageResponse<DatacustomerCodeModel[]>
      >(`api/v1/VSerialHistory/get-select-customer-code`);
      if (response && response.success) {
        const transformedData: Array<SelectListItemModel> = [];
        const seenValues = new Set<string>();
        response.data
          ?.filter((item) => item.value !== null && item.text !== null)
          ?.forEach((customer) => {
            if (!seenValues.has(customer.value)) {
              seenValues.add(customer.value);
              transformedData.push({
                value: customer.value,
                label: customer.text,
              });
            }
          });
        setDataCustomerCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataItemCode = async (keySearch: string) => {
    try {
      let url = "";
      if (search.maKh) {
        url = `?makh=${search.maKh}`;
      }
      if (keySearch && keySearch.length > 0) {
        url += `&ItemCodes=${keySearch}`;
      }
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-list-item-by-inv?Skip=0&Take=50` +
          url
      );
      if (response && response.success) {
        const transformedData = response.data
          ?.filter((i: any) => i.maSp && i.tenSp)
          .map((branch: any) => ({
            value: branch.maSp,
            label: branch.maSp + " - " + branch.tenSp,
          }));
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.value))
        ).map((value) =>
          transformedData.find((item: any) => item.value === value)
        );
        setDataItemCode(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,

    enableColumnFilters: false,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "soCt"],
        right: [""],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    // renderTopToolbarCustomActions: ({ table }) => (
    //   <div ref={headerRef}>
    //     <_breadcrumb></_breadcrumb>
    //     <Box
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         gap: "10px",
    //         flexWrap: "wrap",
    //       }}
    //     >
    //       <Input
    //         style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
    //         placeholder="Nhập từ khóa "
    //         type="text"
    //         leftSection={<IconSearch size={"20"} color="#15aabf" />}
    //         // key={form.key("keySearch")}
    //         // {...form.getInputProps("keySearch")}
    //         onChange={(e) => {
    //           handleChangeSearch(e.target.value ?? "", "keySearch");
    //         }}
    //         onKeyDown={async (e) => {
    //           if (e.key === "Enter") {
    //             await fetchData();
    //           }
    //         }}
    //       />
    //       <Input
    //         style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
    //         placeholder="Số điện thoại"
    //         type="text"
    //         leftSection={<IconPhone size={"20"} color="#15aabf" />}
    //         onChange={(e) => {
    //           handleChangeSearch(e.target.value ?? "", "soDienThoai");
    //         }}
    //       />
    //       <Input
    //         style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
    //         placeholder="Nhập serial"
    //         type="text"
    //         leftSection={<IconFileBarcode size={"20"} color="#15aabf" />}
    //         onChange={(e) => {
    //           handleChangeSearch(e.target.value ?? "", "serial");
    //         }}
    //       />
    //       {/* <MultiSelect
    //         size="sm"
    //         placeholder="Mã khách hàng"
    //         searchable
    //         hidePickedOptions
    //         clearable
    //         data={dataCustomerCode.map((x) => x.value)}
    //         value={search.maKh}
    //         leftSection={<IconUser size={20} color="#15aabf" />}
    //         onClick={() => {
    //           if (dataCustomerCode.length === 0) {
    //             DatacustomerCode();
    //           }
    //         }}
    //         renderOption={renderMultiSelectOption}
    //       /> */}
    //       <Select
    //         size="sm"
    //         placeholder="Mã khách hàng"
    //         searchable
    //         clearable
    //         leftSection={<IconUser size={20} color="#15aabf" />}
    //         // disabled={search.Cnids.length === 0}
    //         onClick={() => {
    //           if (dataCustomerCode.length === 0) {
    //             DatacustomerCode();
    //           }
    //         }}
    //         data={dataCustomerCode}
    //         onChange={(value) =>
    //           handleChangeSearchValue(value ? [value] : null, "maKh")
    //         } // Cập nhật KhoIds
    //       />
    //       <MultiSelect
    //         size="sm"
    //         placeholder="Mã vật tư"
    //         clearable
    //         searchable
    //         data={dataItemCode.map((x) => x.value)} // Hiển thị kết quả từ API
    //         value={search.itemCode}
    //         renderOption={renderMultiSelectOptionVT}
    //         leftSection={<IconBarcode size={20} color="#15aabf" />}
    //         onSearchChange={(value) => {
    //           if (value.length >= 3) {
    //             DataItemCode(value); // Gọi API với từ khóa tìm kiếm
    //           }
    //         }}
    //         onChange={(value) => {
    //           handleChangeSearchValue(value, "itemCode");
    //         }}
    //       />
    //       <Button
    //         color="blue"
    //         variant="outline"
    //         onClick={async () => {
    //           await fetchData();
    //         }}
    //       >
    //         Tìm kiếm
    //       </Button>
    //     </Box>
    //   </div>
    // ),
    // renderToolbarInternalActions: ({ table }) => (
    //   <>
    //     <Menu shadow="md" width={200}>
    //       <Menu.Target>
    //         <Button
    //           // size="xs"
    //           rightSection={
    //             <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
    //           }
    //         >
    //           Chức năng
    //         </Button>
    //       </Menu.Target>

    //       <Menu.Dropdown>
    //         <Menu.Label>Application</Menu.Label>
    //         <Menu.Item
    //           leftSection={
    //             <IconFileExport style={{ width: rem(14), height: rem(14) }} />
    //           }
    //           onClick={() => exportDataExcel()}
    //         >
    //           Export Excel
    //         </Menu.Item>
    //         <Menu.Item
    //           leftSection={
    //             <IconX style={{ width: rem(14), height: rem(14) }} />
    //           }
    //         >
    //           Đóng
    //         </Menu.Item>
    //       </Menu.Dropdown>
    //     </Menu>
    //   </>
    // ),
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height + 30, minHeight: height + 30 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (210 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination]);

  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>
        {dataCustomerCode.find((x) => x.value === option.value)?.label}
      </Text>
    </Group>
  );
  const renderMultiSelectOptionVT: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataItemCode.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );

  //#region new

  //#endregion
  return (
    <>
      <div ref={headerRef}>
        <Group align="center" justify="space-between">
          <_breadcrumb></_breadcrumb>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() => exportDataExcel()}
              >
                Export Excel
              </Menu.Item>
              <Menu.Item
                leftSection={
                  <IconX style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Đóng
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập từ khóa "
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            // key={form.key("keySearch")}
            // {...form.getInputProps("keySearch")}
            onChange={(e) => {
              handleChangeSearch(e.target.value ?? "", "keySearch");
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Số điện thoại"
            type="text"
            leftSection={<IconPhone size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearch(e.target.value ?? "", "soDienThoai");
            }}
          />
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập serial"
            type="text"
            leftSection={<IconFileBarcode size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearch(e.target.value ?? "", "serial");
            }}
          />
          {/* <MultiSelect
            size="sm"
            placeholder="Mã khách hàng"
            searchable
            hidePickedOptions
            clearable
            data={dataCustomerCode.map((x) => x.value)}
            value={search.maKh}
            leftSection={<IconUser size={20} color="#15aabf" />}
            onClick={() => {
              if (dataCustomerCode.length === 0) {
                DatacustomerCode();
              }
            }}
            renderOption={renderMultiSelectOption}
          /> */}
          <Select
            size="sm"
            placeholder="Mã khách hàng"
            searchable
            clearable
            leftSection={<IconUser size={20} color="#15aabf" />}
            // disabled={search.Cnids.length === 0}
            onClick={() => {
              if (dataCustomerCode.length === 0) {
                DatacustomerCode();
              }
            }}
            data={dataCustomerCode}
            onChange={(value) =>
              handleChangeSearchValue(value ? [value] : null, "maKh")
            } // Cập nhật KhoIds
          />
          <MultiSelect
            size="sm"
            placeholder="Mã vật tư"
            clearable
            searchable
            data={dataItemCode.map((x) => x.value)} // Hiển thị kết quả từ API
            value={search.itemCode}
            renderOption={renderMultiSelectOptionVT}
            leftSection={<IconBarcode size={20} color="#15aabf" />}
            onSearchChange={(value) => {
              if (value.length >= 3) {
                DataItemCode(value); // Gọi API với từ khóa tìm kiếm
              }
            }}
            onChange={(value) => {
              handleChangeSearchValue(value, "itemCode");
            }}
          />
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
      <Box mt={10}>
        <MantineReactTable table={table} />
      </Box>
    </>
  );
};

export default BarcodeHistory;
type Department = {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  deparmentCode: string;
  creationDate: string | null;
  createdBy: string | null;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
};
