import {
  Badge,
  Box,
  Button,
  FileButton,
  Flex,
  Grid,
  Input,
  Menu,
  rem,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  IconArrowBigUp,
  IconCaretDown,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { green } from "../../../const/variables";
import DropZoneFile from "../../DebtGroup/DropZoneFile";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { BASE_API_MDM } from "../../../config";
import { IconCheck } from "@tabler/icons-react";
type Invoice = {
  stt: number;
  id: number;
  maCty: string;
  tenCt: string;
  soCt: string;
  ngayCt: string;
  maVach: string;
  maVatTu: string;
  tenVatTu: string;
  maKh: string;
  dienGiai: string;
  maKho: string;
  ongBa: string;
  tenKh: string;
  hanBhNcc: string;
  soCtGoc: string | null;
  maVachCha: string | null;
  thayTheCho: string;
  hanBhCty: string;
  thayTheBoi: string | null;
  maVach1: string;
  tenNx: string;
  sttRec: string;
  maCt: string;
  maCtGoc: string | null;
  ngayCtGoc: string | null;
  maTtmv: string | null;
  tenTtmv: string | null;
  dienGiaiBarcode: string | null;
  nxt: string;
  sort: string;
  bold: string;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
};

const WarrantyConversionData = () => {
  const navigate = useNavigate();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const sizeGrid = { base: 6, sm: 3, md: 3, lg: 3, xl: 1.7 };

  const [startRow, setStartRow] = useState<number | null>(null); // state để lưu số dòng bắt đầu
  const [selectIds, setSelectIds] = useState<string[]>([]); // lưu những dữ liệu đã tích

  //Form Search
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
    },
  });
  const columns = React.useMemo<MRT_ColumnDef<Invoice>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "stt",
        size: 90,
        Cell: ({ renderedCellValue, row }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maCty",
        header: "Mã công ty",
        size: 120,

        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge
              variant="dot"
              radius="xs"
              // onDoubleClick={() => handleCheckView(row)}
              style={{
                cursor: "pointer",
              }}
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tenCt",
        header: "Tên công ty",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "soCt",
        header: "Số chứng từ",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },

      {
        accessorKey: "ngayCt",
        header: "ngày chứng từ",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maVach",
        header: "Mã vạch",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maVatTu",
        header: "Mã vật tư",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenVatTu",
        header: "Tên vật tư",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "dienGiai",
        header: "Diễn giải",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maKho",
        header: "Mã kho",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "ongBa",
        header: "Ông / bà",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "hanBhNcc",
        header: "Hạn BH NCC",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "soCtGoc",
        header: "Số chứng từ gốc",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maVachCha",
        header: "Mã vạch cha",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "thayTheCho",
        header: "Thay thế cho",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "hanBhCty",
        header: "Hạn BH công ty",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "thayTheBoi",
        header: "Thay thế bởi",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maVach1",
        header: "Mã vạch 1",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenNx",
        header: "Tên Nhập xuất",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "sttRec",
        header: "STT Rec",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maCt",
        header: "Mã chứng từ",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maCtGoc",
        header: "Mã chứng từ gốc",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "ngayCtGoc",
        header: "Ngày chứng từ gốc",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maTtmv",
        header: "ma_ttmv",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenTtmv",
        header: "ten_ttmv",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "dienGiaiBarcode",
        header: "Diễn giải barcode",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "nxt",
        header: "nxt",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "sort",
        header: "sort",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "bold",
        header: "bold",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
    ],
    [selectIds]
  );

  const table = useMantineReactTable({
    columns,
    data: data ?? [],
    enableColumnResizing: true,
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginBottom: 5,
        display: "none",
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "stt", "maCty"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: true,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",

    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  // const handleImportExcel = async (file: any) => {
  //   if (!file) {
  //     NotificationExtension.Warn("Vui lòng chọn lại tệp !");
  //     return;
  //   } else {
  //     // modals.closeAll();
  //     // NotificationExtension.Success("Import excel thành công !");
  //   }

  //   const fileReader = new FileReader();
  //   fileReader.onload = async (e) => {
  //     const data = e.target?.result;
  //     if (data) {
  //       const workbook = XLSX.read(data, { type: "binary" });
  //       const sheetName = workbook.SheetNames[0];
  //       let worksheet = workbook.Sheets[sheetName];
  //       // worksheet = XLSX.utils.sheet_add_aoa(worksheet, [["serial"]], {
  //       //   origin: "A1",
  //       // });
  //       const jsonData = XLSX.utils.sheet_to_json(worksheet);
  //       console.log("jsonData", jsonData);
  //       const dataSubmit = jsonData?.map((item: any, index) => ({
  //         stt: item.stt,
  //         MA_CN: item.MA_CN,
  //         TEN_CN: item.TEN_CN,
  //         MA_KHO: item.MA_KHO,
  //         TEN_KHO: item.TEN_KHO,
  //         MA_VT: item.MA_VT,
  //         TEN_VT: item.TEN_VT,
  //         ma_Cty: item.ma_Cty,
  //         so_phieu_nhan_bao_hanh: item.so_phieu_nhan_bao_hanh,
  //         Ngay_ct: item.Ngay_ct,
  //         Ngay_hen_tra: item.Ngay_hen_tra,
  //         So_ct: item.So_ct,
  //         Ma_KH: item.Ma_KH,
  //         Nguoi_giao_dich: item.Nguoi_giao_dich,
  //         tel: item.tel,
  //         Dien_giai: item.Dien_giai,
  //         Ma_VT: item.Ma_VT,
  //         Ten_VT: item.Ten_VT,
  //         Ma_vach: item.Ma_vach,
  //         ma_kho: item.ma_kho,
  //         Ma_vach_tra_thay_the: item.Ma_vach_tra_thay_the,
  //         Tinh_trang: item.Tinh_trang,
  //         TGBH_NCC: item.TGBH_NCC,
  //         ngay_xuat_ban: item.ngay_xuat_ban,
  //         Ma_VT_tra_thay_the: item.Ma_VT_tra_thay_the,
  //         NVBH: item.NVBH,
  //         Ma_vach_nhap_thay_the: item.Ma_vach_nhap_thay_the,
  //         Ten_KH: item.Ten_KH,
  //         Ten_NVBH: item.Ten_NVBH,
  //         Ma_loi: item.Ma_loi,
  //         Ten_loi: item.Ten_loi,
  //         Ten_VT_tra_thay_the: item.Ten_VT_tra_thay_the,
  //         Ma_VT_nhap_thay_the: item.Ma_VT_nhap_thay_the,
  //         Ten_VT_nhap_thay_the: item.Ten_VT_nhap_thay_the,
  //         ten_dv: item.ten_dv,
  //         ten_nhvt: item.ten_nhvt,
  //         Tem_BaoHanh: item.Tem_BaoHanh,
  //         trang_thai: item.trang_thai,
  //         trang_thai_tra_kh: item.trang_thai_tra_kh,
  //         ten_trang_thai_tra_kh: item.ten_trang_thai_tra_kh,
  //         stt_rec: item.stt_rec,
  //         stt_Rec0: item.stt_Rec0,
  //         ma_Ct: item.ma_Ct,
  //         ten_kho: item.ten_kho,
  //         dvt: item.dvt,
  //         tgbh_cty: item.tgbh_cty,
  //         ma_nhvt: item.ma_nhvt,
  //         dvt_nhap_thay_the: item.dvt_nhap_thay_the,
  //         tgbh_ncc_nhap_thay_the: item.tgbh_ncc_nhap_thay_the,
  //         tgbh_cty_nhap_thay_the: item.tgbh_cty_nhap_thay_the,
  //         dvt_tra_thay_the: item.dvt_tra_thay_the,
  //         tgbh_ncc_tra_thay_the: item.tgbh_ncc_tra_thay_the,
  //         tgbh_cty_tra_thay_the: item.tgbh_cty_tra_thay_the,
  //         ghi_chu: item.ghi_chu,
  //         phu_kien_di_kem: item.phu_kien_di_kem,
  //         dien_giai_ct: item.dien_giai_ct,
  //         so_ct_xuat_ban: item.so_ct_xuat_ban,
  //         ma_kh_xuat_ban: item.ma_kh_xuat_ban,
  //         ten_kh_xuat_ban: item.ten_kh_xuat_ban,
  //       }));

  //       if (dataSubmit) {
  //         setDataReview(dataSubmit);
  //       }
  //     }
  //   };
  //   fileReader.readAsBinaryString(file);
  // };

  //

  // Import Excel
  const handleImportExcel = async (file: any) => {
    if (!file) {
      NotificationExtension.Warn("Vui lòng chọn lại tệp !");
      return;
    }
    // const formData = new FormData();
    // formData.append("file", file);
    // formData.append("startRow", startRow?.toString() || "");
    const url = `/api/v1/ImportExcel/Import-transfer-warranty-data?numberRows=${startRow}`;
    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file); // Thêm file vào FormData
    const response = await fetch(_url, {
      method: "POST",
      headers: {
        // "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
      body: formData,
    });
    if (!response.ok) {
      throw new Error("Failed to download file");
    } else {
      NotificationExtension.Success("Import excel thành công !");
      setTimeout(() => {
        fetchData();
      }, 1000);
    }
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/ImportExcel/get-list-import-data${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
      console.log("error", error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  const handleDelete = async () => {
    try {
      const response = await repositoryMdm.post(
        "/api/v1/ImportExcel/delete-import-data",
        selectIds
      );
      if (response && response.success) {
        setSelectIds([]);
        setRowSelection({});
        NotificationExtension.Success("Xóa dữ liệu thành công !");
        modals.closeAll();
        setTimeout(() => {
          fetchData();
        }, 1500);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setSelectIds([]);
    }
  };
  const openModalDelete = () => {
    modals.openConfirmModal({
      title: "Xóa dữ liệu",
      children: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Text
            size="xl"
            style={{
              padding: "10px 0",
            }}
          >
            {selectIds.length > 0
              ? " Bạn có muốn xóa những dữ liệu đã chọn?"
              : " Bạn có muốn xóa tất cả dữ liệu?"}
          </Text>
          <Flex
            direction={"row"}
            align={"center"}
            justify={"center"}
            gap={10}
            mt={10}
          >
            <Button
              variant="outline"
              color="gray"
              leftSection={<IconX stroke={2} />}
              onClick={modals.closeAll}
            >
              Thoát
            </Button>
            <Button
              variant="outline"
              color="red"
              leftSection={<IconCheck stroke={2} />}
              onClick={() => handleDelete()}
            >
              Xóa
            </Button>
          </Flex>
        </div>
      ),
      size: "400px",
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    // Lấy danh sách chỉ số của các hàng được chọn
    const selectedIndexes = Object.keys(rowSelection).map(Number);
    console.log("selectedIndexes", selectedIndexes);
    // Lấy danh sách ID dựa trên các chỉ số
    const ids = selectedIndexes.map((index) => data[index]?.soCt);
    // Cập nhật mảng ID đã chọn
    setSelectIds(ids);
  }, [rowSelection, data]);
  console.log("selectIds", selectIds);
  console.log("rowSelection", rowSelection);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (190 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        style={{
          border: "1px solid #dee2e6",
          padding: "5px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
        <Flex direction={"row"} align="center" gap={10}>
          <Button onClick={() => openModalDelete()}>Xóa dữ liệu</Button>
          <Menu shadow="md" width={250}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item c="#000" fw={600} closeMenuOnClick={false}>
                <Input
                  type="number"
                  value={startRow ?? ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setStartRow(value === "" ? null : Number(value)); // Nếu người dùng xóa đi, set là null
                  }}
                  placeholder="Nhập dòng bắt đầu import (VD: 9)"
                  style={{ marginBottom: "10px" }}
                />
                <div>
                  <FileButton
                    onChange={(file) => file && handleImportExcel(file)}
                    accept=".xlsx, .xls"
                  >
                    {(props) => (
                      <Button
                        variant="light"
                        color="indigo"
                        disabled={startRow == null}
                        {...props}
                        style={{
                          cursor: "pointer",
                          display: "inline-block",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        Import Excel
                      </Button>
                    )}
                  </FileButton>
                </div>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Flex>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <Grid style={{ width: "100%" }} mt={10}>
          <Grid.Col span={sizeGrid}>
            <Input
              size="sm"
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              key={formSeachInput.key("keySearch")}
              {...formSeachInput.getInputProps("keySearch")}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  fetchData();
                }
              }}
            />
          </Grid.Col>

          <Grid.Col span={sizeGrid}>
            <Button
              type="submit"
              leftSection={<IconSearch size={20} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>{" "}
          </Grid.Col>
        </Grid>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default WarrantyConversionData;
