import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Group,
  Input,
  List,
  Menu,
  rem,
  Select,
  Text,
  Textarea,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconAdjustments,
  IconCaretDown,
  IconCircleCheck,
  IconCircleDashed,
  IconEdit,
  IconFileExport,
  IconInfoCircle,
  IconRefresh,
  IconSearch,
  IconStatusChange,
  IconTable,
  IconTimeDuration15,
  IconTrash,
  IconUpload,
  IconWash,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  baseTheme,
  openModalSave,
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
  formatToReverseDate,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import moment from "moment";
import { IconPlus } from "@tabler/icons-react";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import { BASE_API_MDM } from "../../../config";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";

import {
  AllCommunityModule,
  CellSelectionChangedEvent,
  DefaultMenuItem,
  GetContextMenuItemsParams,
  INumberCellEditorParams,
  RowSelectionOptions,
  StatusPanelDef,
  ValueFormatterParams,
} from "ag-grid-community";
import { AG_GRID_LOCALE_VN } from "@ag-grid-community/locale";
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import {
  AdvancedFilterModule,
  ClientSideRowModelModule,
  ClipboardModule,
  ColDef,
  ColGroupDef,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  GetRowIdParams,
  GridChartsModule,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsRequest,
  MasterDetailModule,
  MenuItemDef,
  MenuModule,
  ModuleRegistry,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowClassParams,
  RowGroupingModule,
  RowStyle,
  ServerSideRowModelModule,
  SetFilterModule,
  SparklinesModule,
  StatusBarModule,
} from "ag-grid-enterprise";
import { modals } from "@mantine/modals";
import { MessageResponse } from "../../../model/MessageResponse";

ModuleRegistry.registerModules([ClientSideRowModelModule, AllCommunityModule]);

type dataSubmit = {
  keySearch: string;
  typeMessage: string;
  startDate: string;
  endDate: string;
  status: string;
};
export interface CheckAsiaModel {
  // loại message
  type: string;
  // tên chứng từ
  numberName: string;
  // kiểu, chưa có trên asia, sai số liệu, có trên asia mà không có trong db
  typeCheck: string;
  // ngày chứng từ
  date: string;
  data: string;
}
type PushToSync = {
  typeMessage: string;
  codes: Array<string>;
  type: number; //type 0 là tạo mới, 1 là cập nhật,2 là delete
};
const formatDateNotTimeZoneee = (
  date: Date | string | null | undefined
): string => {
  if (date === null || date === undefined) {
    return "";
  }
  return moment(date).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
};

const CheckDataView = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<CheckAsiaModel[]>([]);
  const [height, setHeight] = useState(0);

  const form = useForm({
    mode: "controlled",
    initialValues: {
      keySearch: "",
      skip: 0,
      take: 100,
      typeMessage: "TblSalesOrder",
      startDate: formatDateNotTimeZoneee(new Date()),
      endDate: formatDateNotTimeZoneee(new Date()),
      status: "",
    },
  });
  const dataSelectTable = [
    {
      value: "",
      label: "Tất cả bảng",
    },
    {
      value: "TblSalesOrder",
      label: "Hóa đơn bán lẻ",
    },
    {
      value: "TblSalesReturn",
      label: "Đơn trả lại",
    },
    {
      value: "TblDmFee",
      label: "Danh mục mã phí",
    },
    // {
    //   value: "TblInternalInvoice",
    //   label: "Hóa đơn nội bộ",
    // },
    {
      value: "TblCashReceipt",
      label: "Phiếu thu",
    },
    {
      value: "TblCashDisbursement",
      label: "Phiếu chi",
    },
    {
      value: "TblCreditNote",
      label: "Phiếu báo có",
    },
    {
      value: "TblDebitNote",
      label: "Phiếu báo nợ",
    },
    {
      value: "TblReceiptNote",
      label: "Phiếu nhập",
    },
    {
      value: "TblDeliveryNote",
      label: "Phiếu xuất",
    },
    {
      value: "TblWarehouseTransfer",
      label: "Phiếu xuất bán",
    },
  ];

  //Call api danh sách thiếu sót đơn đồng bộ
  const fetchData = async (data: dataSubmit) => {
    console.log("data", data);
    const processedData = {
      ...data,
      typeMessage: data.typeMessage ?? "",
      status:
        data.status === "true"
          ? true
          : data.status === "false"
          ? false
          : data.status,
    };
    if (processedData.startDate === "" && processedData.endDate === "") {
      NotificationExtension.Fails(
        "Vui lòng chọn ngày bắt đầu và ngày kết thúc"
      );
      return;
    }
    try {
      const response = await repositoryMdm.post(
        "/api/v1/SyncData/chec-data",
        processedData
      );
      if (response && response.success) {
        setRowData(response.data);
      } else {
        setData([]);
      }
    } catch (error) {
      // NotificationExtension.Fails("Không tìm thấy dữ liệu");
      setRowData([]);
      console.log("error", error);
    } finally {
    }
  };
  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "doi_chieu_dong_bo_du_lieu.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };
  const exportDataExcel = async () => {
    const dataSSubmit = {
      typeMessage: form.getValues().typeMessage,
      startDate: form.getValues().startDate,
      endDate: form.getValues().endDate,
      status:
        form.getValues().status === "true"
          ? true
          : form.getValues().status === "false"
          ? false
          : null,
    };
    const url = `/api/v1/ExportExcel/export-checking-list-sync`;

    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "POST",
      headers: {
        // "Content-Type": "application/vnd.ms-excel",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
      body: JSON.stringify(dataSSubmit),
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "doi_chieu_dong_bo_du_lieu.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  //Đồng bộ tất cả
  const handleDongBoAll = async (type: number) => {
    const Codes = data.map((item: any) => item.data_json);
    if (Codes) {
      const valueSubmit = {
        typeMessage: form.getValues().typeMessage,
        startDate: formatToReverseDate(form.getValues().startDate),
        endDate: formatToReverseDate(form.getValues().endDate),
        type: type, //type 0 là tạo mới, 1 là cập nhật,2 là delete
        skip: 0,
        take: 100,
      };
      if (valueSubmit) {
        try {
          const response = await repositoryMdm.post(
            "/api/v1/SyncData/repeat-sync-checking-exist",
            valueSubmit
          );
          if (response && response.success) {
            setTimeout(() => {
              fetchData(form.getValues());
            }, 1700);
            setTimeout(() => {
              if (type === 0) {
                NotificationExtension.Success("Tạo lại đơn thành công");
              } else if (type === 1) {
                NotificationExtension.Success("Cập nhật dữ liệu thành công");
              }
            }, 500);
          } else {
            setTimeout(() => {
              if (type === 0) {
                NotificationExtension.Success("Tạo lại đơn không thành công");
              } else if (type === 1) {
                NotificationExtension.Success(
                  "Cập nhật dữ liệu không thành công"
                );
              }
            }, 500);
          }
        } catch (error) {
          if (type === 0) {
            NotificationExtension.Success("Tạo lại đơn không thành công");
          } else if (type === 1) {
            NotificationExtension.Success("Cập nhật dữ liệu không thành công");
          }
        }
      }
    }
  };
  // Xử lý đồng bộ, cập nhật
  const handleDongBo = async (value: any, type: number) => {
    const valueSubmit = {
      typeMessage: form.getValues().typeMessage,
      codes: value,
      startDate: formatToReverseDate(form.getValues().startDate),
      endDate: formatToReverseDate(form.getValues().endDate),
      type: type, //type 0 là tạo mới, 1 là cập nhật,2 là delete
      skip: 0,
      take: 100,
    };

    if (valueSubmit) {
      try {
        const response = await repositoryMdm.post(
          "/api/v1/SyncData/repeat-sync-checking-exist",
          valueSubmit
        );
        if (response && response.success) {
          setTimeout(() => {
            fetchData(form.getValues());
          }, 1700);
          setTimeout(() => {
            if (type === 0) {
              NotificationExtension.Success("Gửi đồng bộ dữ liệu thành công");
            } else if (type === 1) {
              NotificationExtension.Success("Cập nhật dữ liệu thành công");
            }
          }, 500);
        } else {
          if (type === 0) {
            NotificationExtension.Success("Gửi đồng bộ dữ liệu thành công");
          } else if (type === 1) {
            NotificationExtension.Success("Cập nhật dữ liệu thành công");
          }
        }
      } catch (error) {
        if (type === 0) {
          NotificationExtension.Success("Gửi đồng bộ dữ liệu không thành công");
        } else if (type === 1) {
          NotificationExtension.Success("Cập nhật dữ liệu không thành công");
        }
      }
    }
  };
  // console.log("data", data);
  // console.log("form.getValues()", form.getValues());
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "data_json",
        header: "Dữ liệu",
        // size: 200,
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge variant="dot" color="green" size="lg" radius="sm">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "type_message",
        header: "Tên thể loại bảng",
        // size: 50,
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
      },

      {
        accessorKey: "ngay_ct",
        header: "Ngày chứng từ",
        // size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
        // size: 170,
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge
              color={renderedCellValue === false ? "red" : "green"}
              radius="sm"
            >
              {renderedCellValue === false ? "Không thành công" : "Thành công"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "error",
        header: "Lỗi",
        Cell: ({ renderedCellValue }) => (
          <Textarea
            readOnly
            autosize
            style={{ width: "500px" }}
            value={
              `${renderedCellValue}` == "null" ? "" : `${renderedCellValue}`
            }
            minRows={3}
            maxRows={3}
          />
        ),
      },
      {
        accessorKey: "count_repeat",
        header: "Lần chạy lại",
        // size: 10,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "create_date",
        header: "Ngày tạo",
        // size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        // size: 20,
        Cell: ({ row }) => (
          <Box>
            <Flex direction={"row"} align={"center"} gap={"sm"}>
              <Tooltip label="Đồng bộ">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="violet"
                  onClick={() => handleDongBo([row.original.data_json], 0)}
                >
                  <IconUpload size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              {/* <Tooltip label="Cập nhật">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="orange"
                    onClick={() => handleDongBo([row.original.data_json], 1)}
                  >
                    <IconEdit size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip> */}
            </Flex>
          </Box>
        ),
      },
    ],
    [data]
  );

  const handleDongBoV2 = async (model: Array<PushToSync>) => {
    // const valueSubmit = {
    //   typeMessage: form.getValues().typeMessage,
    //   codes: value,
    //   type: type, //type 0 là tạo mới, 1 là cập nhật,2 là delete
    // };
    console.log("model", model);
    //   return;
    if (model) {
      try {
        const response = await repositoryMdm.post<MessageResponse<boolean>>(
          "/api/v1/SyncData/repeat-sync-checking-exist-list",
          model
        );
        if (response?.httpStatusCode === 200)
          if (response && response.success) {
            await fetchData(form.getValues());
            NotificationExtension.Success("Gửi đồng bộ dữ liệu thành công !");
          } else {
            NotificationExtension.Fails("Gửi đồng bộ dữ liệu thất bại !");
          }
      } catch (error) {
        NotificationExtension.Fails("Gửi đồng bộ dữ liệu thất bại do lỗi !");
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    fetchData(form.getValues());
  }, []);
  // console.log("selectIds", selectIds);
  // console.log("data", data);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (260 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const gridRef = useRef<AgGridReact<CheckAsiaModel>>(null);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: height, marginTop: "15px" }),
    [height]
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<CheckAsiaModel[]>([]);
  const columnDefs = useMemo<ColDef<CheckAsiaModel>[]>(
    () => [
      {
        field: "numberName",
        headerName: "Tên chứng từ",
        editable: false,
        width: 200,
        filter: "agTextColumnFilter",
      },
      {
        field: "type",
        headerName: "Loại đồng bộ",
        editable: false,
        lockPinned: true,
        // pinned: "left",
        filter: "agTextColumnFilter",
        width: 200,
      },
      {
        field: "date",
        headerName: "Ngày chứng từ",
        filter: "agDateColumnFilter",
        width: 200,
      },
      {
        field: "typeCheck",
        headerName: "Kiểu phát hiện",
        filter: "agTextColumnFilter",
        width: 300,
      },
      {
        field: "data",
        headerName: "Mô tả",
        filter: "agTextColumnFilter",
        width: 1000,
        cellRenderer: (params: CustomCellRendererProps<CheckAsiaModel>) => {
          return (
            <>
              {params.data?.data && (
                <ActionIcon
                  variant="outline"
                  aria-label="Settings"
                  mr={"xs"}
                  onClick={() => {
                    modals.openConfirmModal({
                      title: "Chi tiết ",
                      children: (
                        <Box mx="auto">
                          <List
                            mt={10}
                            spacing="xs"
                            size="sm"
                            center
                            icon={
                              <ThemeIcon color="teal" size={24} radius="xl">
                                <IconCircleCheck
                                  style={{ width: rem(16), height: rem(16) }}
                                />
                              </ThemeIcon>
                            }
                          >
                            {params.data?.data
                              ?.split(".")
                              ?.filter((x) => x && x.length > 0)
                              .map((item, index) => (
                                <List.Item
                                  key={index}
                                  icon={
                                    <ThemeIcon
                                      color="blue"
                                      size={24}
                                      radius="xl"
                                    >
                                      <IconCircleDashed
                                        style={{
                                          width: rem(16),
                                          height: rem(16),
                                        }}
                                      />
                                    </ThemeIcon>
                                  }
                                >
                                  {item}
                                </List.Item>
                              ))}
                          </List>
                        </Box>
                      ),
                      size: "80%",
                      confirmProps: { display: "none" },
                      cancelProps: { display: "none" },
                    });
                  }}
                >
                  <IconInfoCircle
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              )}

              {params.data?.data}
            </>
          );
        },
      },
    ],
    [rowData]
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      filter: true,
      floatingFilter: true,
    };
  }, []);

  const getContextMenuItems = useCallback(
    (
      params: GetContextMenuItemsParams<CheckAsiaModel>
    ): (DefaultMenuItem | MenuItemDef)[] => {
      const _number = params?.node?.data?.numberName;
      var result: (DefaultMenuItem | MenuItemDef)[] = [
        {
          name: "Đẩy lên Asia chứng từ: " + _number,
          action: async () => {
            if (!params?.node?.data?.numberName) {
              NotificationExtension.Info("Mã chứng từ rỗng !");
              return;
            } else {
              await handleDongBoV2([
                {
                  codes: [params?.node?.data?.numberName],
                  type: 0,
                  typeMessage: params?.node?.data?.type,
                },
              ]);
              //   openModalSave(
              //     "Xác nhận !",
              //     <>Bạn có thực sự muốn thực hiện thao tác này !</>,
              //     handleDongBoV2([
              //       {
              //         codes: [params?.node?.data?.numberName],
              //         type: 0,
              //         typeMessage: params?.node?.data?.type,
              //       },
              //     ])
              //   );

              //   modals.openConfirmModal({
              //     title: "Xác nhận !",
              //     children: message,
              //     labels: { confirm: "Lưu", cancel: "Hủy bỏ" },
              //     onCancel: () => console.log("Cancel"),
              //     onConfirm: async () => onSave,
              //   });
            }
          },
          cssClasses: ["red", "bold"],
        },
        {
          name: "Cập nhật lại trên Asia chứng từ: " + _number,
          action: async () => {
            if (!params?.node?.data?.numberName) {
              NotificationExtension.Info("Mã chứng từ rỗng !");
              return;
            } else
              await handleDongBoV2([
                {
                  codes: [params?.node?.data?.numberName],
                  type: 1,
                  typeMessage: params?.node?.data?.type,
                },
              ]);
          },
          cssClasses: ["red", "bold"],
        },
        {
          // custom item
          name: "Xóa trên Asia chứng từ: " + _number,
          action: async () => {
            if (!params?.node?.data?.numberName) {
              NotificationExtension.Info("Mã chứng từ rỗng !");
              return;
            } else
              await handleDongBoV2([
                {
                  codes: [params?.node?.data?.numberName],
                  type: 2,
                  typeMessage: params?.node?.data?.type,
                },
              ]);
          },
          cssClasses: ["red", "bold"],
        },

        // "cut",
        "copy",
        "export",
      ];
      return result;
    },
    [window, rowData]
  );
  const rowSelection = useMemo<
    RowSelectionOptions | "single" | "multiple"
  >(() => {
    return { mode: "multiRow" };
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent" },
        { statusPanel: "agTotalRowCountComponent" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  return (
    <>
      <_breadcrumb></_breadcrumb>

      <Flex
        direction={"row"}
        align={"center"}
        justify={"space-between"}
        style={{
          border: "1px solid #dee2e6",
          overflowX: "hidden",
          padding: "10px 10px",
        }}
      >
        <Group>
          <Text fw={700}>
            CHÚ Ý: kho thao tác sẽ thực hiện ngay, cân nhắc trước khi thao tác
          </Text>
        </Group>
        <Group>
          <Button
            size="xs"
            leftSection={<IconPlus />}
            onClick={async () => {
              const select = gridRef.current?.api.getSelectedRows();
              console.log("select", select);
              if (!select || select.length === 0) {
                NotificationExtension.Info("Mã chứng từ rỗng !");
                return;
              } else {
                const data: Array<PushToSync> = select.map((x) => ({
                  codes: [x.numberName],
                  type: 0,
                  typeMessage: x.type,
                }));
                await handleDongBoV2(data);
              }
            }}
          >
            Đẩy lại lên Asia
          </Button>

          <Button
            size="xs"
            ml={"xs"}
            leftSection={<IconWash />}
            onClick={async () => {
              const select = gridRef.current?.api.getSelectedRows();
              console.log("select", select);
              if (!select || select.length === 0) {
                NotificationExtension.Info("Mã chứng từ rỗng !");
                return;
              } else {
                const data: Array<PushToSync> = select.map((x) => ({
                  codes: [x.numberName],
                  type: 1,
                  typeMessage: x.type,
                }));
                await handleDongBoV2(data);
              }
            }}
          >
            Cập nhật lại lên Asia
          </Button>

          <Button
            size="xs"
            ml={"xs"}
            leftSection={<IconTrash />}
            onClick={async () => {
              const select = gridRef.current?.api.getSelectedRows();
              console.log("select", select);
              if (!select || select.length === 0) {
                NotificationExtension.Info("Mã chứng từ rỗng !");
                return;
              } else {
                const data: Array<PushToSync> = select.map((x) => ({
                  codes: [x.numberName],
                  type: 2,
                  typeMessage: x.type,
                }));
                await handleDongBoV2(data);
              }
            }}
          >
            Xóa trên Asia
          </Button>
        </Group>
      </Flex>
      <Flex
        direction={"row"}
        mt={10}
        justify={"space-between"}
        align={"center"}
      >
        <form
          onSubmit={form.onSubmit(async (e) => {
            await fetchData(e);
          })}
        >
          {/* <LoadingOverlay
              visible={visible}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            /> */}
          <Flex direction={"row"} align={"center"} gap={"md"} wrap="wrap">
            <Input
              size="sm"
              w={200}
              placeholder="Từ khóa"
              {...form.getInputProps("keySearch")}
              leftSection={<IconSearch color="#15aabf" />}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  form.onSubmit(async (e) => {
                    await fetchData(e);
                  });
                }
              }}
            />
            <Select
              size="sm"
              w={200}
              leftSection={<IconTable color="#15aabf" />}
              placeholder="Chọn tên thể loại bảng"
              searchable
              clearable
              data={dataSelectTable}
              key={form.key("typeMessage")}
              {...form.getInputProps("typeMessage")}
            />
            <Select
              size="sm"
              w={200}
              leftSection={<IconStatusChange color="#15aabf" />}
              placeholder="Chọn trạng thái"
              searchable
              clearable
              data={[
                {
                  value: "true",
                  label: "Thành công",
                },
                {
                  value: "false",
                  label: "Không thành công",
                },
              ]}
              value={form.getValues().status}
              key={form.key("status")}
              onChange={(e) => {
                form.setFieldValue("status", e || "");
              }}
              renderOption={(option) => {
                return (
                  <Badge
                    color={option.option.value === "true" ? "green" : "red"}
                  >
                    {option.option.label}
                  </Badge>
                );
              }}
            />
            <DatePickerInput
              size="sm"
              w={200}
              placeholder="Ngày bắt đầu"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              onChange={(e) => {
                form.setFieldValue(
                  "startDate",
                  formatDateNotTimeZone(e) || "" // Định dạng StartTime
                );
              }}
              clearable
              defaultValue={new Date()}
            />
            <DatePickerInput
              size="sm"
              w={200}
              placeholder="Ngày kết thúc"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              onChange={(e) => {
                form.setFieldValue(
                  "endDate",
                  formatDateNotTimeZone(e) || "" // Định dạng StartTime
                );
              }}
              clearable
              defaultValue={new Date()}
            />

            <Button
              type="submit"
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
            >
              Tìm kiếm
            </Button>
          </Flex>
        </form>
      </Flex>
      <div style={containerStyle}>
        <div style={gridStyle} className={"ag-theme-quartz"}>
          <AgGridReact<CheckAsiaModel>
            ref={gridRef}
            rowData={rowData}
            theme={baseTheme}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressColumnVirtualisation={true}
            suppressRowVirtualisation={true}
            pagination={true}
            localeText={AG_GRID_LOCALE_VN}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            cacheBlockSize={paginationPageSize}
            maxBlocksInCache={3}
            getContextMenuItems={getContextMenuItems}
            rowSelection={rowSelection}
            statusBar={statusBar}
            //cellSelection
            // onCellSelectionChanged={(
            //   event: CellSelectionChangedEvent<CheckAsiaModel>
            // ) => {
            // lấy index từ a->b từ list log ra rowstart và rowend
            //     console.log("event", event.api.getCellRanges());
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default CheckDataView;
