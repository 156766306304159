//#region start
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  Menu,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowBack,
  IconCheck,
  IconScan,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

import { DateTimePicker } from "@mantine/dates";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { useNavigate } from "react-router-dom";
import { _localization } from "../../../../config/location";
import BorrowedGoodList from "./BorrowedGoodList";
import {
  repositoryDelivery,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { useForm } from "@mantine/form";
import { WarrantyExportItemBorrowTicket } from "../../../../model/BorrowedGood";
import { useDebouncedState } from "@mantine/hooks";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";

const CreateExportBorrwedGood = () => {
  const entity = {
    id: 0,
    receiveId: null,
    borrowCode: null,
    totalDeposit: null,
    note: null,
    status: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    attribute6: null,
    departmentId:
      JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
        ?.departmentId || "",
    inventoryId: null,
    branchId: null,
    roleId:
      JSON.parse(localStorage.getItem("userLoginHeader") || "{}")?.roleId || "",
    tblWarrantyExportItemBorrowTicketDetailCommands: [],
  };

  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [keySearch, setKeySearch] = useDebouncedState<string>("", 300);
  const [searchItem, setSearchItem] = useDebouncedState<string>("", 300);
  const [scanSerial, setScanSerial] = useDebouncedState<string>("", 300);
  const [totalDeposit, setTotalDeposit] = useState<number>(0);

  const [serialSelect, setSerialSelect] = useState<Set<any>>(new Set());
  const [dataReceiveTicket, setDataReceiveTicket] = useState<any>(null);
  const [dataProductSubmit, setDataProductSubmit] = useState<any[]>([]);
  const [loanProducts, setLoanProducts] = useState<any[]>([]);
  const [scanLoanProducts, setScanLoanProducts] = useState<any[]>([]);

  const form = useForm<WarrantyExportItemBorrowTicket>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "type",
        header: "Loại",
        size: 100,
        Cell: ({ row, renderedCellValue }: any) => {
          return (
            <>
              {renderedCellValue === true ? (
                <Badge color="blue" radius="md">
                  Bảo hành
                </Badge>
              ) : (
                <Badge color="green" radius="md">
                  Cho mượn
                </Badge>
              )}
            </>
          );
        },
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 100,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
        size: 100,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        size: 100,
        Cell: ({ row }) => (
          <Text fw={500} size="11.5px">
            1
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "attribute1",
        header: "Phụ kiện theo kèm",
        Cell: ({ renderedCellValue, row }) =>
          row.original.type ? (
            <Text fw={500} size="11.5px">
              {renderedCellValue}
            </Text>
          ) : (
            <TextInput
              placeholder="Phụ kiện kèm theo"
              value={renderedCellValue?.toString() ?? ""}
              onChange={(e) =>
                handleChangeValue(row.original.serial?.toString(), {
                  attribute1: e.currentTarget.value,
                })
              }
              size="xs"
            />
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 170,
        Cell: ({ renderedCellValue, row }) =>
          row.original.type ? (
            <Text fw={500} size="11.5px">
              {renderedCellValue}
            </Text>
          ) : (
            <Select
              placeholder="Tình trạng"
              value={renderedCellValue?.toString() ?? ""}
              data={[
                { label: "Mới 100%", value: "Mới 100%" },
                { label: "Đã qua sử dụng", value: "Đã qua sử dụng" },
              ]}
              onChange={(e) =>
                handleChangeValue(row.original.serial?.toString(), {
                  status: e ? e : "Mới 100%",
                })
              }
              size="xs"
            />
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "orgPrice",
        header: "Giá",
        size: 150,
        Cell: ({ renderedCellValue, row }: any) => (
          <>{Number(renderedCellValue)?.toLocaleString("vi-VN")} </>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "sellingPrice",
        header: "Giá hiện hành",
        size: 150,
        Cell: ({ renderedCellValue, row }: any) =>
          dataReceiveTicket?.inventoryCode?.split("_")[1] !== "BH" &&
          row.original.type ? (
            <>{Number(renderedCellValue)?.toLocaleString("vi-VN")}</>
          ) : (
            <NumberInput
              placeholder="Nhập giá hiện hành"
              value={row.original.sellingPrice}
              min={0}
              allowNegative
              hideControls
              thousandSeparator=" "
              onChange={(e) => {
                if (row.original.type === true) {
                  handleChangeValue(row.original.serial?.toString(), {
                    sellingPrice: Number(e),
                    actualPrice: (Number(e) * 90) / 100,
                  });
                } else {
                  handleChangeValue(row.original.serial?.toString(), {
                    sellingPrice: Number(e),
                    actualPrice: Number(e),
                  });
                }
              }}
              size="xs"
            />
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "actualPrice",
        header: "Giá trị thực",
        size: 150,
        Cell: ({ renderedCellValue, row }: any) =>
          dataReceiveTicket?.inventoryCode?.split("_")[1] !== "BH" &&
          row.original.type ? (
            <>{Number(renderedCellValue)?.toLocaleString("vi-VN")}</>
          ) : (
            <NumberInput
              placeholder="Nhập giá trị thực"
              value={row.original.actualPrice}
              min={0}
              allowNegative
              hideControls
              thousandSeparator=" "
              onChange={(e) => {
                handleChangeValue(row.original.serial?.toString(), {
                  actualPrice: Number(e),
                });
              }}
              size="xs"
            />
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <TextInput
            placeholder="Ghi chú"
            value={renderedCellValue?.toString() ?? ""}
            onChange={(e) =>
              handleChangeValue(row.original.serial?.toString(), {
                note: e.currentTarget.value,
              })
            }
            size="xs"
          />
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "action",
        header: "Xóa",
        size: 50,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={() => {
                  handleDeleteProduct(row.index);
                }}
                disabled={row.original.type}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    [dataReceiveTicket, dataProductSubmit]
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const transformString = (inputString: string) => {
    const parts = inputString?.split("_");

    if (parts && parts.length === 2) {
      const newString = `BH${parts[0].substring(1)}_03`;
      return newString;
    }

    return inputString;
  };

  const handleDeleteProduct = (index: number) => {
    const serial = dataProductSubmit[index]?.serial?.toString();

    if (serial) {
      setSerialSelect((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(serial)) {
          newSet.delete(serial);
        }
        return newSet;
      });
      setDataProductSubmit((prevData) =>
        prevData.filter((_, i) => i !== index)
      );
    }
  };

  const handleSelectProduct = (listData: any, index: number) => {
    setDataProductSubmit((prev) => [
      ...prev,
      {
        id: 0,
        status: "Mới 100%",
        actualPrice: 0,
        note: null,
        type: false,
        sellingPrice: 0,
        attribute1: null,
        attribute2: null,
        attribute3: null,
        attribute4: 1,
        attribute5: null,
        attribute6: null,
        deposit: null,
        orgPrice: listData[index].donGiaNhap,
        serial: listData[index].maBarcode,
        itemBorrowId: 0,
        itemCode: listData[index].maSp,
        itemName: listData[index].tenSp,
        uomName: listData[index].dvt,
        quantity: 1,
      },
    ]);
  };

  const handleChangeValue = (serial: string, updates: Record<string, any>) => {
    const updatedData = dataProductSubmit?.map((item: any) =>
      item.serial?.toString() === serial?.toString()
        ? {
            ...item,
            ...updates,
          }
        : item
    );
    setDataProductSubmit(updatedData);
  };

  const getCreate = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblWarrantyExportItemBorrowTicket/create-pxm?prefix=PYCXM"
    );

    if (res && res?.success) {
      const result = res?.data;
      form.setValues((prev) => ({
        ...prev,
        borrowCode: result.borrowCode,
        attribute2: result.borrowCode,
      }));
    }
  };

  const getDataLoanProducts = async (invCode: string) => {
    let url = `?Skip=0&Take=50&LocationInWarehouse=${transformString(invCode)}`;
    if (searchItem.length > 2) {
      url += `&KeySearch=${searchItem}`;
    }
    const res = await repositoryDelivery.get(
      `/api/v1/TblInventorySerialOnhand/get-list-serial${url}`
    );

    if (res && res?.success) {
      setLoanProducts(res.data);
    }
  };

  const getDataScanLoanProducts = async (invCode: string) => {
    let url = `?Skip=0&Take=50&LocationInWarehouse=${transformString(invCode)}`;
    if (scanSerial.length > 2) {
      url += `&KeySearch=${scanSerial}`;
    }
    const res = await repositoryDelivery.get(
      `/api/v1/TblInventorySerialOnhand/get-list-serial${url}`
    );

    if (res && res?.success) {
      setScanLoanProducts(res.data);
    }
  };

  const handleCreate = async () => {
    if (dataProductSubmit.filter((item) => item.type === false).length > 0) {
      const updatedDataProductSubmit = dataProductSubmit.map((item) => ({
        ...item,
        deposit: totalDeposit,
      }));

      const res = await repositoryMdm.post(
        "/api/v1/TblWarrantyExportItemBorrowTicket/create",
        {
          ...form.getValues(),
          totalDeposit: totalDeposit,
          tblWarrantyExportItemBorrowTicketDetailCommands:
            updatedDataProductSubmit,
        }
      );
      if (res && res?.success) {
        NotificationExtension.Success("Tạo phiếu yêu cầu hàng mượn thành công");
        setTimeout(() => {
          navigate("/warranty/list-borrowed-goods");
        }, 1000);
      }
    } else {
      NotificationExtension.Fails("Bạn chưa chọn hàng xuất mượn !");
    }
  };

  useEffect(() => {
    getCreate();
  }, []);

  useEffect(() => {
    if (dataReceiveTicket !== undefined && dataReceiveTicket !== null) {
      getDataLoanProducts(dataReceiveTicket?.inventoryCode);
    }
  }, [searchItem, dataReceiveTicket]);

  useEffect(() => {
    if (scanSerial && scanSerial.length > 2) {
      getDataScanLoanProducts(dataReceiveTicket?.inventoryCode);
    }
  }, [scanSerial]);

  useEffect(() => {
    const totalTrueType = dataProductSubmit
      .filter((item) => item.type === true)
      .reduce((sum, item) => sum + item.actualPrice, 0);

    const totalFalseType = dataProductSubmit
      .filter((item) => item.type === false)
      .reduce((sum, item) => sum + item.actualPrice, 0);

    const difference = totalFalseType - totalTrueType;

    if (Number(difference) > 0) {
      setTotalDeposit(Number(difference));
    } else {
      setTotalDeposit(0);
    }
  }, [dataProductSubmit]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (212.5 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data: dataProductSubmit ? dataProductSubmit ?? [] : [],
    positionToolbarAlertBanner: "bottom",
    renderToolbarInternalActions: () => <></>,
    renderTopToolbarCustomActions: ({ table }) => (
      <Flex align={"center"} w={"100%"} gap={"md"}>
        <Menu width={1000} trigger="hover">
          <Menu.Target>
            <TextInput
              placeholder="Tìm kiếm sản phẩm"
              leftSection={<IconSearch size={16} />}
              defaultValue={searchItem}
              onChange={(e) => setSearchItem(e.currentTarget.value)}
              disabled={
                dataReceiveTicket === undefined || dataReceiveTicket === null
              }
              size="xs"
            />
          </Menu.Target>
          <Menu.Dropdown>
            <Table.ScrollContainer minWidth={200} mah={480} type="native">
              <Table striped withTableBorder>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Chọn</Table.Th>
                    <Table.Th>Mã sản phẩm</Table.Th>
                    <Table.Th>Serial</Table.Th>
                    <Table.Th>Sản phẩm</Table.Th>
                    <Table.Th>Đơn vị tính</Table.Th>
                    <Table.Th>Giá</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {loanProducts && loanProducts.length > 0 ? (
                    loanProducts?.map((item, index) => (
                      <Table.Tr key={index}>
                        <Table.Td>
                          <Button
                            variant="filled"
                            color="teal"
                            size="xs"
                            onClick={() => {
                              handleSelectProduct(loanProducts, index);
                              setSerialSelect((prev) => {
                                const newSet = new Set(prev);
                                newSet.add(item.maBarcode);
                                return newSet;
                              });
                            }}
                            disabled={serialSelect.has(item.maBarcode)}
                          >
                            {serialSelect.has(item.maBarcode)
                              ? "Đã chọn"
                              : "Chọn"}
                          </Button>
                        </Table.Td>
                        <Table.Td>{item.maSp}</Table.Td>
                        <Table.Td>{item.maBarcode}</Table.Td>
                        <Table.Td>{item.tenSp}</Table.Td>
                        <Table.Td>{item.dvt}</Table.Td>
                        <Table.Td>{item.donGiaNhap}</Table.Td>
                      </Table.Tr>
                    ))
                  ) : (
                    <Table.Tr>
                      <Table.Th colSpan={6} ta={"center"}>
                        Không tìm thấy sản phẩm !
                      </Table.Th>
                    </Table.Tr>
                  )}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
          </Menu.Dropdown>
        </Menu>
        <Text fs={"italic"} c={"gray"} size="11.5px">
          hoặc
        </Text>
        <Menu width={1000} trigger="hover">
          <Menu.Target>
            <TextInput
              placeholder="Quét serial"
              leftSection={<IconScan size={16} />}
              defaultValue={scanSerial}
              onChange={(e) => setScanSerial(e.currentTarget.value)}
              disabled={
                dataReceiveTicket === undefined || dataReceiveTicket === null
              }
              size="xs"
            />
          </Menu.Target>
          <Menu.Dropdown>
            <Table.ScrollContainer minWidth={200} mah={480} type="native">
              <Table striped withTableBorder>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Chọn</Table.Th>
                    <Table.Th>Mã sản phẩm</Table.Th>
                    <Table.Th>Serial</Table.Th>
                    <Table.Th>Sản phẩm</Table.Th>
                    <Table.Th>Đơn vị tính</Table.Th>
                    <Table.Th>Giá</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {scanLoanProducts && scanLoanProducts.length === 1 ? (
                    scanLoanProducts?.map((item, index) => (
                      <Table.Tr key={index}>
                        <Table.Td>
                          <Button
                            variant="filled"
                            color="teal"
                            size="xs"
                            onClick={() => {
                              handleSelectProduct(scanLoanProducts, index);
                              setSerialSelect((prev) => {
                                const newSet = new Set(prev);
                                newSet.add(item.maBarcode);
                                return newSet;
                              });
                            }}
                            disabled={serialSelect.has(item.maBarcode)}
                          >
                            {serialSelect.has(item.maBarcode)
                              ? "Đã chọn"
                              : "Chọn"}
                          </Button>
                        </Table.Td>
                        <Table.Td>{item.maSp}</Table.Td>
                        <Table.Td>{item.maBarcode}</Table.Td>
                        <Table.Td>{item.tenSp}</Table.Td>
                        <Table.Td>{item.dvt}</Table.Td>
                        <Table.Td>{item.donGiaNhap}</Table.Td>
                      </Table.Tr>
                    ))
                  ) : (
                    <Table.Tr>
                      <Table.Th colSpan={6} ta={"center"}>
                        {scanSerial.length === 0
                          ? "Vui lòng quét serial để tìm kiếm sản phẩm !"
                          : "Không tìm thấy sản phẩm !"}
                      </Table.Th>
                    </Table.Tr>
                  )}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
          </Menu.Dropdown>
        </Menu>
      </Flex>
    ),
    enableToolbarInternalActions: false,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
        width: "100%",
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height - 148, minHeight: height - 148 },
    },
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 500,
        fontSize: "11.5px",
        padding: "5px 12px",
      },
    }),
    initialState: {
      columnPinning: {
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },

    enableStickyHeader: true,
    manualSorting: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <Box
        component="form"
        mx="auto"
        p={"0px 10px"}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex
          justify={"space-between"}
          align={"center"}
          style={{ marginBottom: "10px" }}
        >
          <Flex w={"100%"} justify={"flex-end"} gap={10}>
            <Button
              color="red"
              onClick={() => handleReturnPage()}
              leftSection={<IconArrowBack size={18} />}
            >
              Thoát
            </Button>
            <Button
              color={"teal"}
              leftSection={<IconCheck size={18} />}
              onClick={() => handleCreate()}
              disabled={
                dataReceiveTicket === undefined || dataReceiveTicket === null
              }
            >
              Lưu
            </Button>
          </Flex>
        </Flex>
        <Grid>
          <Grid.Col span={5}>
            <ScrollArea h={height + 80} scrollbars="y">
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin khách hàng">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Tên khách hàng"
                          placeholder="Tên khách hàng"
                          value={dataReceiveTicket?.custMeeting}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số điện thoại"
                          placeholder="Số điện thoại"
                          value={dataReceiveTicket?.custPhone}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                        <TextInput
                          label="Địa chỉ"
                          placeholder="Địa chỉ"
                          value={dataReceiveTicket?.meetingAddress}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin phiếu yêu cầu xuất mượn">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số phiếu yêu cầu hàng mượn"
                          placeholder="Số phiếu yêu cầu hàng mượn"
                          readOnly
                          variant="filled"
                          {...form.getInputProps("borrowCode")}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <Menu
                          trigger="hover"
                          openDelay={100}
                          closeDelay={400}
                          width={500}
                        >
                          <Menu.Target>
                            <TextInput
                              label="Số phiếu tiếp nhận hàng lỗi"
                              placeholder="Nhập số phiếu tiếp nhận hàng lỗi"
                              defaultValue={keySearch}
                              onChange={(e) =>
                                setKeySearch(e.currentTarget.value)
                              }
                            />
                          </Menu.Target>
                          <Menu.Dropdown
                            w={800}
                            h={150}
                            style={{ height: "100px" }}
                          >
                            <BorrowedGoodList
                              keySearch={keySearch}
                              setKeySearch={setKeySearch}
                              setDataReceiveTicket={setDataReceiveTicket}
                              setDataForm={form.setValues}
                              setDataProduct={setDataProductSubmit}
                              setSerialSelect={setSerialSelect}
                            />
                          </Menu.Dropdown>
                        </Menu>
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Bộ phận / Phòng ban"
                          placeholder="Bộ phận / Phòng ban"
                          value={
                            JSON.parse(
                              localStorage.getItem("userLoginHeader") || "{}"
                            )?.departmentName || ""
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Người lập"
                          placeholder="Người lập"
                          value={
                            JSON.parse(
                              localStorage.getItem("userLoginHeader") || "{}"
                            )?.fullname || ""
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <DateTimePicker
                          label="Ngày tạo phiếu"
                          valueFormat="DD-MM-YYYY hh:mm A"
                          value={new Date()}
                          variant="filled"
                          readOnly
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Kho"
                          placeholder="Chọn PNHL để có thông tin kho"
                          value={dataReceiveTicket?.inventoryCode}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chi nhánh"
                          placeholder="Chi nhánh"
                          value={
                            JSON.parse(
                              localStorage.getItem("userLoginHeader") || "{}"
                            )?.brachName || ""
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chức danh"
                          placeholder="Chức danh"
                          value={
                            JSON.parse(
                              localStorage.getItem("userLoginHeader") || "{}"
                            )?.roleName || ""
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                        <Textarea
                          h={60}
                          label="Ghi chú"
                          placeholder="Nhập ghi chú"
                          {...form.getInputProps("note")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </ScrollArea>
          </Grid.Col>
          <Grid.Col span={7}>
            <Fieldset legend="Thông tin sản phẩm">
              <MantineReactTable table={table} />
            </Fieldset>
            <Flex justify={"start"} gap={"xs"} h={100} mt={5}>
              <Flex
                direction={"row"}
                align={"center"}
                justify={"center"}
                bd={"1px solid #DEE2E6"}
                style={{ borderRadius: "10px" }}
                gap={"md"}
                w={300}
              >
                <Text size="14px" fw={"bold"}>
                  Tổng số tiền cọc :
                </Text>
                <Text size="13px" fw={"bold"} c={"red"}>
                  {totalDeposit?.toLocaleString("vi-VN")} VND
                </Text>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default CreateExportBorrwedGood;
