import {
  Button,
  Flex,
  Grid,
  Group,
  Radio,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { sky_blue } from "../../const/variables";
type ModalDepartMentProps = {
  type: string;
  idDeparment?: number;
  fetchData?: () => void;
};
const ModalDepartMent = ({
  type,
  idDeparment,
  fetchData,
}: ModalDepartMentProps) => {
  const [dataBranchdSelect, setDataBranchdSelect] = useState<any[]>([]);
  const entity = {
    departmentId: 0,
    name: "", // tên phong ban
    email: null,
    description: "", // ghi chú
    creationDate: null,
    createdBy: null,
    lastUpdateDate: null,
    lastUpdatedBy: null,
    active: "true", // trạng thái
    deparmentCode: "", // mã phòng ban
    address: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    branchId: "", // id chi nhánh
  };
  const form = useForm({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      deparmentCode: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã phòng ban";
        }
      },
      name: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên phòng ban";
        }
      },

      branchId: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn chi nhánh";
        }
      },

      active: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn trạng thái";
        }
      },
    },
  });
  //   lấy chi nhánh
  const fetchDataBranch = async () => {
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblDmMasterOrg/get-all`
      );
      if (response && response.success) {
        const data = response.data;
        setDataBranchdSelect(data);
      } else {
        setDataBranchdSelect([]);
      }
    } catch (error) {
      setDataBranchdSelect([]);
      console.error(error);
      return;
    }
  };
  //   thêm mới hoặc chỉnh sửa phòng ban
  const handleDataCreate = async (dataSubmit: any) => {
    const processData = {
      ...dataSubmit,
      active: dataSubmit.active === "true",
      branchId: dataSubmit.branchId,
    };
    console.log("processData", processData);
    const endpoint =
      type === "create"
        ? "/api/v1/TblDepartment/create"
        : "/api/v1/TblDepartment/edit";
    const successMessage =
      type === "create"
        ? "Thêm mới phòng ban thành công"
        : "Chỉnh sửa phòng ban thành công";
    try {
      const response = await repositoryPos.post(endpoint, processData);

      if (response && response.success) {
        NotificationExtension.Success(successMessage);

        setTimeout(() => {
          fetchData?.(); // Gọi fetchData nếu tồn tại
          modals.closeAll();
        }, 1500);
      } else {
        NotificationExtension.Fails("Đã xảy ra lỗi. Vui lòng thử lại!");
      }
    } catch (error) {
      console.error(error);
      NotificationExtension.Fails("Đã xảy ra lỗi. Vui lòng thử lại!");
    }
  };
  //lấy dữ liệu edit
  const fetchDataUpdate = async (idDeparment: Number) => {
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDepartment/edit?id=${idDeparment}`
      );
      if (response && response.success) {
        const result = response.data;
        form.setValues({
          ...result,
          active: result.active ? "true" : "false",
          branchId:
            result.branchId?.toString()?.length === 1
              ? "0" + result.branchId?.toString()
              : result.branchId?.toString(),
        });
      } else {
        NotificationExtension.Fails("Đã xảy ra lỗi. Vui lòng thử lại!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (idDeparment) {
      fetchDataUpdate(idDeparment);
    }
    fetchDataBranch();
  }, [idDeparment]);
  return (
    <div style={{ marginTop: "15px" }}>
      <form
        onSubmit={form.onSubmit(async (values) => {
          await handleDataCreate(values);
        })}
      >
        <Grid>
          <Grid.Col span={{ base: 12, xs: 6 }}>
            <TextInput
              label="Mã phòng ban: "
              withAsterisk
              key={form.key("deparmentCode")}
              placeholder="Mã phòng ban..."
              {...form.getInputProps("deparmentCode")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 6 }}>
            <TextInput
              label="Tên phòng ban: "
              withAsterisk
              key={form.key("name")}
              placeholder="Nhập tên phòng ban..."
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 6 }}>
            <Select
              label="Chi nhánh:"
              placeholder="Chọn chi nhánh...."
              withAsterisk
              key={form.key("branchId")}
              value={form.getValues().branchId?.toString() ?? ""}
              data={dataBranchdSelect
                .filter((ele) => ele?.id && ele?.name)
                .map((ele) => ({
                  label: ele?.id?.toString() + " - " + ele?.name.toString(),
                  value: ele?.id.toString(),
                }))}
              {...form.getInputProps("branchId")}
              onChange={(e: any) => {
                form.setFieldValue("branchId", e);
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 6 }}>
            <Radio.Group
              label="Trạng thái: "
              key={form.key("active")}
              value={form.getValues().active?.toString() ?? ""}
              withAsterisk
              {...form.getInputProps("active")}
              onChange={(value) => {
                form.setFieldValue("active", value);
              }}
            >
              <Group mt="xs">
                <Flex direction={"row"} gap={"xs"} align={"center"}>
                  <Radio value="true" label="Hoạt động" />
                  <Radio value="false" label="Không hoạt động" />
                </Flex>
              </Group>
            </Radio.Group>
          </Grid.Col>

          <Grid.Col span={{ base: 12, xs: 12 }}>
            <Textarea
              //   withAsterisk
              size="sm"
              flex={1}
              rows={3}
              key={form.key("description")}
              label="Nhập ghi chú: "
              placeholder="Nhập ghi chú..."
              {...form.getInputProps("description")}
            ></Textarea>
          </Grid.Col>
        </Grid>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            leftSection={<IconWindow size={18} />}
            onClick={() => {
              modals.closeAll();
            }}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            leftSection={<IconCheck size={18} />}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </form>
    </div>
  );
};

export default ModalDepartMent;
