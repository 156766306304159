import {
  Badge,
  Box,
  Button,
  Group,
  Input,
  Menu,
  MultiSelect,
  MultiSelectProps,
  rem,
  Text,
} from "@mantine/core";
import {
  IconBarcode,
  IconBuildingFactory,
  IconBuildingWarehouse,
  IconCaretDown,
  IconFileExport,
  IconMapPin,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { SelectListItemModel } from "../../../model/SelectListItem";
import { exportDataToExcel } from "../../../common/ExportExcelToAPI/ExportExcelToAPI";
import { BASE_API_DELIVERY } from "../../../config";
import { green } from "../../../const/variables";
const InventoryReportLocation = () => {
  // Khởi tạo component InventoryReportList
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<InventoryItem[]>([]);
  const [dataInvCode, setDataInvCode] = useState<SelectListItemModel[]>([]);
  const [dataItemCode, setDataItemCode] = useState<SelectListItemModel[]>([]);
  const [dataPositionCode, setDataPositionCode] = useState<
    SelectListItemModel[]
  >([]);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState({
    keySearch: "",
    positionCode: [],
    invCode: [],
    itemCode: [],
  });

  type InventoryItem = {
    positionCode: string;
    branchName: string;
    countSoLuong: number;
    dvt: string | null;
    idBranch: number;
    idKho: number;
    maKho: string;
    maSp: string;
    soLuong: number | null;
    tenKho: string;
    tenSp: string;
  };

  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };
  const handleChangeSearch = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "branchCode",
        header: "Mã chi nhánh",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Button
              variant="light"
              p="4px 8px"
              radius="xl"
              size="xs"
              color="violet"
              fw="bold"
            >
              {renderedCellValue}
            </Button>
          </>
        ),
      },
      {
        accessorKey: "branchName",
        header: "Tên chi nhánh",
      },
      {
        accessorKey: "invCode",
        header: "Mã kho",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Button
              variant="light"
              p="4px 8px"
              radius="xl"
              size="xs"
              color="blue"
              fw="bold"
            >
              {renderedCellValue}
            </Button>
          </>
        ),
      },
      {
        accessorKey: "invName",
        header: "Tên kho",
        size: 200,
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue ?? "Không có mã sản phẩm"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        size: 700,
      },
      {
        accessorKey: "positionCode",
        header: "Mã vị trí",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Button
              variant="light"
              p="4px 8px"
              radius="xl"
              size="xs"
              color="blue"
              fw="bold"
            >
              {renderedCellValue}
            </Button>
          </>
        ),
      },
      {
        accessorKey: "positionName",
        header: "Tên vị trí",
        size: 250,
      },
      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "sumQuantity",
        header: "Tổng số lượng",
      },
    ],
    []
  );
  const exportDataExcel = async () => {
    let url = `?Skip=${pagination?.pageIndex}&Take=${pagination.pageSize}`;

    if (search.positionCode && search.positionCode.length > 0) {
      search.positionCode.forEach((positionCode) => {
        url += `&MaViTri=${positionCode}`;
      });
    }
    if (search.invCode && search.invCode.length > 0) {
      search.invCode.forEach((invCode) => {
        url += `&MaKho=${invCode}`;
      });
    }
    if (search.itemCode && search.itemCode.length > 0) {
      search.itemCode.forEach((itemCode) => {
        url += `&MaSP=${itemCode}`;
      });
    }
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    // const baseUrl = `/api/v1/VPositionOnhandQuantity/export-onhand-position${url}`;
    // const baseUrl = `https://apis-pos-dev.hacom.vn/api-shipping/api/v1/VPositionOnhandQuantity/export-onhand-position${url}`;
    // const fileName = "bao_cao_ton_kho_vi_tri.xlsx";

    // await exportDataToExcel(baseUrl, fileName);
    const baseUrl = `/api/v1/VPositionOnhandQuantity/export-onhand-position${url}`;

    const _url = BASE_API_DELIVERY + baseUrl;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "bao_cao_ton_kho_vi_tri.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  // Hàm lấy dữ liệu
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (search.positionCode && search.positionCode.length > 0) {
      search.positionCode.forEach((positionCode) => {
        url += `&MaViTri=${positionCode}`;
      });
    }
    if (search.invCode && search.invCode.length > 0) {
      search.invCode.forEach((invCode) => {
        url += `&MaKho=${invCode}`;
      });
    }
    if (search.itemCode && search.itemCode.length > 0) {
      search.itemCode.forEach((itemCode) => {
        url += `&MaSP=${itemCode}`;
      });
    }
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    try {
      const response = await repositoryDelivery.get(
        `/api/v1/VPositionOnhandQuantity/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const DataInvCode = async () => {
    // const user = JSON.parse(localStorage.getItem("userLogin") || "{}");
    // const Invcode = user?.branchId;
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmInventory/get-all?checkAuth=true`
      );
      if (response && response.httpStatusCode === 200) {
        const transformedData = response.data
          .filter((i: any) => i.value && i.text)
          .map((item: any) => {
            const match = item.text.match(/\[(.*?)\]/);
            const fullLabel = match ? match[1].trim() : item.text;
            return {
              value: item.value,
              label: item.text,
              fullLabel: fullLabel,
            };
          });

        setDataInvCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataPositionCode = async () => {
    try {
      if (search.invCode != null && search.invCode.length > 0) {
        let url = search.invCode
          .map((invCode) => `SubInvCode=${invCode}`)
          .join("&");
        if (url) {
          url = `?${url}`;
        }

        const response = await repositoryDelivery.get(
          `/api/v1/TblDmItemPosition/get-select${url}`
        );
        if (response && response.httpStatusCode === 200) {
          const transformedData = response.data
            .filter((i: any) => i.positionCode && i.positionName)
            .map((item: any) => ({
              value: item.positionCode,
              label: item.positionCode + " - " + item.positionName,
            }));
          setDataPositionCode(transformedData);
        }
      } else {
        const response = await repositoryDelivery.get(
          `/api/v1/TblDmItemPosition/get-all`
        );
        if (response && response.httpStatusCode === 200) {
          const transformedData = response.data
            .filter((i: any) => i.positionCode && i.positionName)
            .map((item: any) => ({
              value: item.positionCode,
              label: item.positionCode + " - " + item.positionName,
            }));
          const uniqueData = Array.from(
            new Set(transformedData.map((item: any) => item.value))
          ).map((value) =>
            transformedData.find((item: any) => item.value === value)
          );
          setDataPositionCode(uniqueData);
        }
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataItemCode = async (keySearch: string) => {
    try {
      let url = "";
      if (keySearch && keySearch.length > 0) {
        url += `&ItemCodes=${keySearch}`;
      }
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-list-item-by-inv?Skip=0&Take=50` +
          url
      );
      if (response && response.success) {
        const transformedData = response.data
          ?.filter((i: any) => i.maSp && i.tenSp)
          .map((branch: any) => ({
            value: branch.maSp,
            label: branch.maSp + " - " + branch.tenSp,
          }));
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.value))
        ).map((value) =>
          transformedData.find((item: any) => item.value === value)
        );
        setDataItemCode(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,

    rowCount,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,

    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "itemCode"],
        right: ["statusName"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập từ khóa"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearch(e.target.value ?? "", "keySearch");
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />

          <MultiSelect
            size="sm"
            placeholder="Mã kho"
            searchable
            clearable
            data={dataInvCode
              .map((x) => x.fullLabel)
              .filter((label): label is string => label !== undefined)}
            value={search.invCode}
            renderOption={renderMultiSelectOptionInvCode}
            leftSection={<IconBuildingWarehouse size={20} color="#15aabf" />}
            onClick={() => {
              if (dataInvCode.length === 0) {
                DataInvCode();
              }
            }}
            onChange={(value) => {
              handleChangeSearchValue(value, "invCode");
            }}
          />
          <MultiSelect
            size="sm"
            placeholder="Mã vị trí"
            searchable
            clearable
            data={dataPositionCode.map((x) => x.value)}
            value={search.positionCode}
            renderOption={renderMultiSelectOptionPositionCode}
            leftSection={<IconBuildingFactory size={20} color="#15aabf" />}
            onClick={() => {
              DataPositionCode();
            }}
            onChange={(value) => {
              handleChangeSearchValue(value, "positionCode");

              // const _key = value.target.value ?? ""; // Nếu value là mảng
              // setKeySearch(_key);
            }}
          />
          <MultiSelect
            size="sm"
            placeholder="Mã sản phẩm"
            clearable
            searchable
            data={dataItemCode.map((x) => x.value)}
            value={search.itemCode}
            renderOption={renderMultiSelectOption}
            leftSection={<IconBarcode size={20} color="#15aabf" />}
            onSearchChange={(value) => {
              if (value.length >= 3) {
                DataItemCode(value); // Gọi API với từ khóa tìm kiếm
              }
            }}
            onChange={(value) => {
              handleChangeSearchValue(value, "itemCode");
            }}
          />

          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Chức năng
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Đóng
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);
  useEffect(() => {
    fetchData();
  }, [pagination]);
  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataItemCode.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );
  const renderMultiSelectOptionInvCode: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>
        {dataInvCode.find((x: any) => x.fullLabel === option.value)?.label}
      </Text>
    </Group>
  );
  const renderMultiSelectOptionPositionCode: MultiSelectProps["renderOption"] =
    ({ option }) => (
      <Group gap="sm">
        <Text>
          {dataPositionCode.find((x) => x.value === option.value)?.label}
        </Text>
      </Group>
    );
  return (
    <Box>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default InventoryReportLocation;
