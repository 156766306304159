import {
  Box,
  Button,
  Grid,
  Group,
  TextInput,
  Text,
  Flex,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import {
  _timerDelayClick,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";

import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconBarcode,
  IconCheck,
  IconSearch,
  IconUser,
  IconUserCode,
  IconWindow,
} from "@tabler/icons-react";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { sky_blue, teal } from "../../../const/variables";
import { tblItem } from "../../../model/TblItem";
import { TblWarrantyItem } from "../../../model/TblWarrantyItem";
import { TblWarrantyServiceRequestForm } from "../../../model/TblWarrantyServiceRequestForm";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { MessageResponse } from "../../../model/MessageResponse";

const WarrantyItem = ({
  custId,
  selectWarrantyItem,
  setFormValues,
  type,
}: WarrantyItemProps) => {
  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<TblWarrantyItem>[]>(
    () => [
      {
        accessorKey: "serial",
        header: "Serial",
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={400}>
            <Tooltip label={row.original.itemName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "orderCode",
        header: "Mã hóa đơn",
        size: 30,
      },
      {
        accessorKey: "customerName",
        header: "Người mua",
        size: 30,
      },
      {
        accessorKey: "buyingDate",
        header: "Ngày mua",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
    ],
    []
  );

  const columnsBefore2025 = React.useMemo<MRT_ColumnDef<TblWarrantyItem>[]>(
    () => [
      {
        accessorKey: "maVach",
        header: "Serial",
      },
      {
        accessorKey: "maVt",
        header: "Mã sản phẩm",
        size: 30,
      },
      {
        accessorKey: "tenVtMoi",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Box w={400}>
            <Tooltip label={renderedCellValue}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {renderedCellValue}
              </Text>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "soCt",
        header: "Mã hóa đơn",
        size: 30,
      },
      {
        accessorKey: "tenKh",
        header: "Người mua",
        size: 30,
      },
      {
        accessorKey: "ngayCt",
        header: "Ngày mua",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
    ],
    []
  );

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarrantyItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [keySearch, setKeySearch] = useState<string>("");
  const [searchSerial, setSearchSerial] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState(paginationBase);
  const [selectItem, setSelectItem] = useState<tblItem>();
  const [isCheck, setIsCheck] = useState<boolean>(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [visible, { toggle, close, open }] = useDisclosure(false);

  //search
  const [search, setSearch] = useState({
    customerName: "",
    customerCode: "",
  });

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleSelectReceipt = async () => {
    const res = await repositoryMdm.post<MessageResponse<any>>(
      `/api/v1/TblWarrantyServiceRequestForm/check-serial?branchId=${
        JSON.parse(localStorage.getItem("userLoginHeader") || "{}")?.branchId
      }`,
      selectIds
    );

    if (res && res?.success) {
      const result = res?.data;
      if (type === "sau2025") {
        const listSerialDuplicate = result
          ?.filter((item: any) => item.status === 3)
          ?.map((item: any) => item.serial);

        if (data.length === 0 || selectIds.length === 0) {
          NotificationExtension.Warn("Vui lòng chọn đơn hàng !");
          return;
        }

        if (listSerialDuplicate.length > 0) {
          NotificationExtension.Info(
            `Serial ${listSerialDuplicate.join(
              ","
            )} đã được tiếp nhận yêu cầu ở chi nhánh này !`
          );
        }

        const selectedItems = data.filter(
          (e: TblWarrantyItem) =>
            selectIds.includes(e.serial?.toString() ?? "") &&
            !listSerialDuplicate.includes(e.serial?.toString() ?? "")
        );

        if (selectedItems.length > 0) {
          selectWarrantyItem((prev: TblWarrantyItem[]) => [
            ...prev,
            ...selectedItems.map((item) => {
              const today = new Date();
              const entryDate = new Date();
              entryDate.setDate(today.getDate() + 7);

              const monthsDifference =
                (new Date().getFullYear() -
                  new Date(item?.buyingDate ?? "").getFullYear()) *
                  12 +
                (new Date().getMonth() -
                  new Date(item?.buyingDate ?? "").getMonth());

              return {
                ...item,
                serviceType: null,
                entryDate: entryDate?.toISOString(),
                customerOrderId: item.customerId,
                customerName: item.customerName,
                attribute5: "7",
                attribute4: Math.max(
                  item?.warrantyTerm ?? 0 - monthsDifference + 1,
                  0
                ),
              };
            }),
          ]);

          setFormValues((prev: TblWarrantyServiceRequestForm) => ({
            ...prev,
            attribute6: "HANG_HACOM",
          }));

          modals.closeAll();
        }
      } else {
        const listSerialDuplicate = result
          ?.filter((item: any) => item.status === 3)
          ?.map((item: any) => item.serial);

        if (data.length === 0 || selectIds.length === 0) {
          NotificationExtension.Warn("Vui lòng chọn đơn hàng !");
          return;
        }

        if (listSerialDuplicate.length > 0) {
          NotificationExtension.Info(
            `Serial ${listSerialDuplicate.join(
              ","
            )} đã được tiếp nhận yêu cầu ở chi nhánh này !`
          );
        }

        const selectedItems = data.filter(
          (e: any) =>
            selectIds.includes(e.maVach?.toString() ?? "") &&
            !listSerialDuplicate.includes(e.maVach?.toString() ?? "")
        );

        if (selectedItems.length > 0) {
          selectWarrantyItem(() =>
            selectedItems.map((item: any) => {
              const today = new Date();
              const entryDate = new Date();
              entryDate.setDate(today.getDate() + 7);

              const monthsDifference =
                (new Date().getFullYear() -
                  new Date(item?.ngayCt ?? "").getFullYear()) *
                  12 +
                (new Date().getMonth() -
                  new Date(item?.ngayCt ?? "").getMonth());

              return {
                itemId: item.idVt,
                itemCode: item.maVt,
                itemName: item.tenVtMoi,
                orderNumber: item.soCt,
                serial: item.maVach,
                serviceType: null,
                entryDate: entryDate?.toISOString(),
                customerOrderId: null,
                customerName: item.tenKh,
                buyingDate: item.ngayCt,
                telephoneNumber: null,
                uom: item.maDvt,
                warrantyTerm: item.tgbhHnc,
                attribute5: "7",
                attribute4: Math.max(
                  Number(item?.tgbhHnc) ?? 0 - monthsDifference + 1,
                  0
                ),
              };
            })
          );

          setFormValues((prev: TblWarrantyServiceRequestForm) => ({
            ...prev,
            attribute6: "HANG_HACOM",
          }));

          modals.closeAll();
        }
      }
    } else {
      return;
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    if (searchSerial.length > 0) {
      url += `&Serial=${searchSerial}`;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblWarrantyItem/search-order${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const fetchDataBefore2025 = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    if (search.customerCode.length > 0) {
      url += `&CustomerCode=${search.customerCode}`;
    }

    if (search.customerName.length > 0) {
      url += `&CustomerName=${search.customerName}`;
    }

    if (searchSerial.length > 0) {
      url += `&Serial=${searchSerial}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyServiceRequestForm/get-list-serial-before-2025${url}`
      );
      if (response && response.success) {
        setIsCheck(true);
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    if (searchSerial.length === 0 && type === "truoc2025") {
      setIsCheck(false);
    }
  }, [searchSerial]);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (type === "sau2025") {
      fetchData();
    }
  }, [pagination, type]);

  useEffect(() => {
    if (searchSerial.length > 0 && type === "truoc2025") {
      fetchDataBefore2025();
    }
  }, [pagination]);

  const table = useMantineReactTable({
    columns: type === "sau2025" ? columns : columnsBefore2025,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập Serial"
              value={searchSerial}
              leftSection={
                <IconBarcode
                  size={20}
                  style={{ color: teal.base }}
                ></IconBarcode>
              }
              onChange={(e) => setSearchSerial(e.currentTarget.value)}
            />
          </Grid.Col>
          {type === "truoc2025" ? (
            <>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  placeholder="Mã khách hàng"
                  leftSection={<IconUserCode size={20} color="#15AABF" />}
                  onChange={(e) =>
                    handleChangeSearchValue(
                      e.currentTarget.value,
                      "customerCode"
                    )
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  placeholder="Tên khách hàng"
                  leftSection={<IconUser size={20} color="#15AABF" />}
                  onChange={(e) =>
                    handleChangeSearchValue(
                      e.currentTarget.value,
                      "customerName"
                    )
                  }
                />
              </Grid.Col>
            </>
          ) : (
            <></>
          )}
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa ..."
              value={keySearch}
              leftSection={
                <IconSearch size={20} style={{ color: teal.base }}></IconSearch>
              }
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Flex w={"100%"} h={"100%"} align={"center"}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                disabled={searchSerial.length < 3 && type === "truoc2025"}
                onClick={() => {
                  if (type === "sau2025") {
                    fetchData();
                  } else {
                    fetchDataBefore2025();
                  }
                }}
              >
                Tìm kiếm
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: () => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    // localization: _localization,
    localization: {
      noRecordsToDisplay:
        type === "truoc2025"
          ? searchSerial.length > 0 && isCheck
            ? "Không tìm thấy dữ liệu !"
            : "Vui lòng nhập Serial để tìm kiếm sản phẩm !"
          : "Không tìm thấy dữ liệu !",
    },
    enableRowSelection: true,
    getRowId: (row) =>
      type === "sau2025"
        ? row.serial?.toString() ?? ""
        : row.maVach?.toString() ?? "",
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: "375px", minHeight: "375px" },
    },
    enableStickyHeader: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    rowCount,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <Box>
      <MantineReactTable table={table} />
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
          onClick={handleSelectReceipt}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

type WarrantyItemProps = {
  custId?: string | number;
  selectWarrantyItem: any;
  setFormValues: any;
  type: any;
};
export default WarrantyItem;
