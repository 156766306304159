import { Badge, Box, Text } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect } from "react";
import { green } from "../../const/variables";
import { _localization } from "../../config/location";
import { repositoryDelivery } from "../../_base/_const/_constVar";

const TableDetail = ({
  idDetail,
  typeSourceType,
}: {
  idDetail: any;
  typeSourceType: any;
}) => {
  const [dataDetail, setDataDetail] = React.useState<any>([]);

  const fetchDataDetail = async () => {
    const url = `/api/v1/TblTestingTechniqueHeader/get-detail-v2?id=${idDetail}&IsItemsOnly=true`;
    try {
      const response = await repositoryDelivery.get(url);
      if (response?.success && response?.data) {
        setDataDetail(response?.data?.tblTestingTechniqueDetailModels);
      } else {
        setDataDetail([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataDetail([]);
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
      },

      {
        accessorKey: "serial",
        header: "Số serial",
      },
      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "quality",
        header: "Số lượng",
      },
      ...(typeSourceType == "DON_HANG"
        ? [
            {
              accessorKey: "lineTyle",
              header: "Loại mặt hàng", // Tiêu đề hiển thị
            },
          ]
        : []),
      // ...(typeSourceType == "NHAP_KHO_BH_NCC"
      //   ? [
      //       {
      //         accessorKey: "serialStatus",
      //         header: "Trạng thái Serial", // Tiêu đề hiển thị
      //       },
      //     ]
      //   : []),
      ...(typeSourceType == "CHUYEN_DOI_DU_LIEU"
        ? [
            {
              accessorKey: "invPositionName",
              header: "Vị trí trong kho", // Tiêu đề hiển thị
            },
          ]
        : []),
      {
        accessorKey: "testingStatus",
        header: "Trạng thái kiểm tra",
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue === "TEST_OK"
              ? "Test ok"
              : renderedCellValue === "TEST_LOI"
              ? "Test lỗi"
              : ""}
          </Text>
        ),
      },

      {
        accessorKey: "noteByEmployee",
        header: "Ghi chú",
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
      },
    ],
    []
  );
  const table = useMantineReactTable({
    columns,
    data: dataDetail ? dataDetail : [],
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableBottomToolbar: false,
    enablePagination: false,
    localization: _localization,
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: 255, minHeight: 100 },
    },
    enableStickyHeader: true,
    manualFiltering: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        // padding: "5px",
      },
    }),
    manualPagination: false,
    manualSorting: false,
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  useEffect(() => {
    fetchDataDetail();
  }, [idDetail]);
  return (
    <Box>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default TableDetail;
