import {
    Badge,
    Box,
    Breadcrumbs,
    Button,
    Flex,
    Grid,
    Input,
    Menu,
    NumberFormatter,
    rem,
    Select,
    Text,
    Tooltip,
} from "@mantine/core";
import {
    MantineReactTable,
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_RowSelectionState,
    MRT_SortingState,
    useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import * as xlsx from "xlsx";

import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
    IconBuildingWarehouse,
    IconCaretDown,
    IconFileExport,
    IconFileUpload,
    IconMapPin,
    IconSearch,
    IconTimeDuration15,
    IconUsers,
} from "@tabler/icons-react";
import {
    repositoryDelivery,
    repositoryMdm,
    repositoryPos,
} from "../../../../_base/_const/_constVar";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import {
    formatDateNotTimeZone,
    formatDateTransfer,
} from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { Transaction } from "../../../../model/TblVallTransaction";
import { toast } from "react-toastify";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { MessageResponse } from "../../../../model/MessageResponse";
import { useNavigate } from "react-router-dom";
import { green } from "../../../../const/variables";
import { AvatarUtils } from "../../../../common/ColorByName/AvatarUtils";
import { exportDataToExcel } from "../../../../common/ExportExcelToAPI/ExportExcelToAPI";
import { BASE_API_MDM } from "../../../../config";
import { addMonths } from "date-fns";
import { render } from "react-dom";
const ReportTransaction = () => {
    const navigate = useNavigate();
    const headerRef = React.useRef<HTMLDivElement>(null);
    const [data, setData] = useState<Transaction[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });
    const [rowCount, setRowCount] = useState(0);
    const [height, setHeight] = useState(0);
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        []
    );
    const [globalFilter, setGlobalFilter] = useState("");
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    //chi nhánh
    // const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
    //   []
    // );
    const userLogin = JSON.parse(localStorage.getItem("userLogin") || "{}");
    //Kho
    const [dataInventory, setDataInventory] = useState<SelectListItemModel[]>([]);
    //Bộ phận
    const [dataDepartment, setDataDepartment] = useState<SelectListItemModel[]>(
        []
    );
    //Khách hàng
    const [dataCustomer, setDataCustomer] = useState<SelectListItemModel[]>([]);
    const sizeGrid = { base: 6, sm: 3, md: 3, lg: 3, xl: 1.7 };
    const formSeachInput = useForm({
        mode: "uncontrolled",
        initialValues: {
            keySearch: "",
            StartTime: "",
            EndTime: "",
        },
    });

    const [search, setSearch] = useState({
        Cnids: [], //chi nhánh
        // KhoIds: [], //kho
        // Bpids: [], //bộ phận
        custId: [], //khách hàng
    });
    const handleChangeSearchValue = (value: string[] | null, key: string) => {
        setSearch((prevData) => ({
            ...prevData,
            [key]: value ? value : [],
        }));
    };

    const fetchData = async () => {
        setIsLoading(true);
        setIsRefetching(true);

        let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${pagination.pageSize
            }`;
        if (formSeachInput.getValues().keySearch) {
            url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
        }
        if (formSeachInput.getValues().StartTime) {
            url += `&StartTime=${formSeachInput.getValues().StartTime}`;
        }

        if (formSeachInput.getValues().EndTime) {
            url += `&EndTime=${formSeachInput.getValues().EndTime}`;
        }
        // if (userLogin) {
        //   url += `&Cnids=${userLogin.branchId}`;
        // }
        // if (search.KhoIds && search.KhoIds.length > 0) {
        //     search.KhoIds.forEach((KhoIds) => {
        //         url += `&KhoIds=${KhoIds}`;
        //     });
        // }
        // if (search.Bpids && search.Bpids.length > 0) {
        //     search.Bpids.forEach((Bpids) => {
        //         url += `&Bpids=${Bpids}`;
        //     });
        // }
        if (search.custId && search.custId.length > 0) {
            search.custId.forEach((custId) => {
                url += `&custId=${custId}`;
            });
        }
        try {
            const response = await repositoryMdm.get(
                `/api/v1/VAllTransaction/get-all${url}`
            );
            if (response && response.success) {
                let result = response.data;
                setData(result);
                setRowCount(response.totalCount);
            } else {
                setData([]);
                setRowCount(0);
                setIsLoading(false);
            }
        } catch (error) {
            setData([]);
        } finally {
            setIsLoading(false);
            setIsRefetching(false);
        }
    };
    const handleCheckView = async (row: any) => {
        if (row.original.tlHd == "3") {
            const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.maCt}`;
            const response = await repositoryPos.get<MessageResponse<any>>(url);

            if (response && response.success) {
                navigate("/sell/view-return-order", {
                    state: {
                        id: row.original.orderId,
                        orderNumber: row.original.maCt,
                        orderType: row.original.tlHd,
                        actionType: "view",
                    },
                });
            }
        } else {
            const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.maCt}`;
            const response = await repositoryPos.get<MessageResponse<any>>(url);

            if (response && response.success) {
                navigate("/sell/view-order", {
                    state: {
                        id: row.original.orderId,
                        orderNumber: row.original.maCt,
                        orderType: row.original.tlHd,
                        actionType: "view",
                    },
                });
            }
        }
    };
    const columns = React.useMemo<MRT_ColumnDef<Transaction>[]>(
        () => [
            {
                header: "STT",
                accessorKey: "STT",
                Cell: ({ row }) => <>{row?.index + 1}</>,
                size: 100,
            },
            {
                accessorKey: "soCt",
                header: "Số chứng từ",
                size: 170,

                Cell: ({ renderedCellValue, row }) => (
                    <>
                        <Tooltip label="Xem chi tiết">
                            <Badge
                                variant="dot"
                                radius="xs"
                                onDoubleClick={() => handleCheckView(row)}
                                style={{
                                    cursor: "pointer",
                                }}
                                size="lg"
                                color={renderedCellValue === null ? "red" : green.base}
                            >
                                {renderedCellValue}
                            </Badge>
                        </Tooltip>
                    </>
                ),
            },
            {
                accessorKey: "ngayCt",
                header: "Ngày chứng từ",
                Cell: ({ renderedCellValue }: any) => (
                    <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
                ),
                sortingFn: (a, b) => {
                    const dateA = new Date(a.original.ngayCt);
                    const dateB = new Date(b.original.ngayCt);
                    return dateA.getTime() - dateB.getTime();
                },
            },
            {
                accessorKey: "maCn",
                header: "Mã chi nhánh",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                accessorKey: "boPhan",
                header: "Bộ phận",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                accessorKey: "loaiCt",
                header: "Loại chứng từ",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            // {
            //     accessorKey: "tenCn",
            //     header: "Tên chi nhánh",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            {
                accessorKey: "maKh",
                header: "Mã khách hàng",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            // {
            //     accessorKey: "tenKh",
            //     header: "Tên khách hàng",
            //     Cell: ({ renderedCellValue, cell, row }) => {
            //         return (
            //             <>
            //                 <AvatarUtils value={row.original.tenKh?.split("-")[0]} />
            //             </>
            //         );
            //     },
            // },
            {
                accessorKey: "address",
                header: "Địa chỉ khách hàng",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                accessorKey: "telephoneNumber",
                header: "Số điện thoại",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            // {
            //     accessorKey: "phanHe",
            //     header: "Phân hệ giao dịch",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },

            // {
            //     accessorKey: "email",
            //     header: "Email",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            // {
            //     accessorKey: "maThueKh",
            //     header: "Mã thuế khách hàng",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            // {
            //     accessorKey: "maTinh",
            //     header: "Mã tỉnh",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            // {
            //     accessorKey: "tenTinh",
            //     header: "Tên tỉnh",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            // {
            //     accessorKey: "maQuan",
            //     header: "Mã quận",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            // {
            //     accessorKey: "tenQuan",
            //     header: "Tên quận",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            // {
            //     accessorKey: "maPhuong",
            //     header: "Mã phường",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            // {
            //     accessorKey: "tenPhuong",
            //     header: "Tên phường",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            {
                accessorKey: "ttXuatNhap",
                header: "Thông tin xuất nhập",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                accessorKey: "trangThai",
                header: "Trạng thái giao dịch",
                Cell: ({ renderedCellValue }) => (
                    <>
                        <Badge size="sm" color="green">
                            {renderedCellValue === "DON_HANG_HOAN_THANH"
                                ? "Đơn hàng hoàn thành"
                                : ""}
                        </Badge>
                    </>
                ),
            },
            // {
            //     accessorKey: "loaiHachToan",
            //     header: "Loại hạch toán",
            //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            // },
            {
                accessorKey: "soTien",
                header: "Tổng tiền",
                Cell: ({ renderedCellValue }: any) => (
                    <>{formatCurrencyVND(renderedCellValue)}</>
                ),
            },
            {
                accessorKey: "dienGiai",
                header: "Diễn giải",
                size: 200,
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ],
        []
    );

    const table = useMantineReactTable({
        columns,
        data: data || [],
        enableColumnResizing: true,
        enableTopToolbar: false,
        mantineTopToolbarProps: {
            style: {
                borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
                marginBottom: 5,
            },
        },
        localization: _localization,

        initialState: {
            showColumnFilters: false,
            columnPinning: {
                left: ["STT", "soCt"],
                // right: ["tTienDh", "action"],
            },
            columnVisibility: { id: true },
            density: "xs",
        },
        enableRowSelection: false,
        mantineTableContainerProps: {
            style: { maxHeight: height - 100, minHeight: height - 100 },
        },
        enableStickyHeader: true,
        onRowSelectionChange: setRowSelection,
        enableColumnFilters: true,
        enableSorting: true,
        enableColumnActions: true,
        manualFiltering: false,
        manualPagination: true,
        manualSorting: false,
        rowCount,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        onColumnFiltersChange: setColumnFilters,
        mantineTableBodyCellProps: ({ row }) => ({
            style: {
                fontWeight: "550",
                fontSize: "12.5px",
                padding: "5px 15px",
            },
        }),
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showProgressBars: isRefetching,
            showSkeletons: isLoading, //loading for the first time with no data
            sorting,
            rowSelection,
        },
        mantinePaginationProps: {
            showRowsPerPage: true,
            withEdges: true,
            radius: "xl",
            size: "lg",
            rowsPerPageOptions: ["20", "50", "100"],
        },
        paginationDisplayMode: "pages",
        enableColumnPinning: true,
        mantineTableProps: {
            striped: false,
        },
    });
    //xuất excel
    // const handleExport = () => {
    //     try {
    //         const worksheet = xlsx.utils.json_to_sheet(data);
    //         const workbook = xlsx.utils.book_new();
    //         xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
    //         xlsx.writeFile(workbook, "danh-sach-bao-cao-ban-hang-theo-ngay.xlsx");
    //         toast.success("Export excel thành công", { autoClose: 1500 });
    //     } catch (error) {
    //         toast.error("Export excel thất bại", { autoClose: 1500 });
    //     }
    // };

    // // Lấy danh sách mã chi nhánh
    // const DataBranchCode = async () => {
    //   try {
    //     const response = await repositoryDelivery.get(
    //       `api/v1/TblDmMasterOrg/get-select`
    //     );

    //     if (response && response.success) {
    //       const transformedData = response.data.map((item: any) => ({
    //         value: item.value,
    //         label: item.value + " - " + item.text,
    //       }));
    //       setDataBranchCode(transformedData);
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     return;
    //   }
    // };
    // Lấy danh sách kho
    // const fetchDataKho = async () => {
    //     try {
    // if (
    //   userLogin &&
    //   userLogin.branchId !== null &&
    //   userLogin.branchId !== undefined
    // ) {
    //   const response = await repositoryPos.get(
    //     `/api/v1/TblDmInventory/get-select-by-branch?branchId=${userLogin?.branchId}`
    //   );
    //   if (response && response.success) {
    //     const transformedData = response.data.map((item: any) => ({
    //       value: String(item.inventoryId),
    //       label: item.inventoryCode + " - " + item.inventoryName,
    //     }));
    //     setDataInventory(transformedData);
    //   } else {
    //     setDataInventory([]);
    //   }
    // } else {
    //   const response = await repositoryPos.get(
    //     `/api/v1/TblDmInventory/get-all?checkAuth=true`
    //   );
    //   if (response && response.success) {
    //     const transformedData = response.data.map((item: any) => ({
    //       value: item.value,
    //       label: item.text,
    //     }));
    //     setDataInventory(transformedData);
    //   } else {
    //     setDataInventory([]);
    //   }
    // }
    //         const response = await repositoryPos.get(
    //             `/api/v1/TblDmInventory/get-all?checkAuth=true`
    //         );
    //         if (response && response.success) {
    //             const transformedData = response.data.map((item: any) => ({
    //                 value: item.value,
    //                 label: item.text,
    //             }));
    //             setDataInventory(transformedData);
    //         } else {
    //             setDataInventory([]);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //         return;
    //     }
    // };
    // Lấy danh sách mã bộ phận
    // const fetchDataBp = async () => {
    //     try {
    // if (
    //   userLogin &&
    //   userLogin.branchId !== null &&
    //   userLogin.branchId !== undefined
    // ) {
    //   const response = await repositoryPos.get(
    //     `/api/v1/TblDepartment/get-all?branchId=${userLogin?.branchId}`
    //   );
    //   if (response && response.success) {
    //     const transformedData = response.data.map((item: any) => ({
    //       value: String(item.departmentId),
    //       label: item.deparmentCode + " - " + item.name,
    //     }));
    //     setDataDepartment(transformedData);
    //   } else {
    //     setDataDepartment([]);
    //   }
    // } else {
    //   const response = await repositoryPos.get(
    //     `/api/v1/TblDepartment/get-all`
    //   );
    //   if (response && response.success) {
    //     const transformedData = response.data.map((item: any) => ({
    //       value: String(item.departmentId),
    //       label: item.departmentId + " - " + item.name,
    //     }));
    //     setDataDepartment(transformedData);
    //   } else {
    //     setDataDepartment([]);
    //   }
    // }
    //         const response = await repositoryPos.get(`/api/v1/TblDepartment/get-all`);
    //         if (response && response.success) {
    //             const transformedData = response.data.map((item: any) => ({
    //                 value: String(item.departmentId),
    //                 label: item.deparmentCode + " - " + item.name,
    //             }));
    //             setDataDepartment(transformedData);
    //         } else {
    //             setDataDepartment([]);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //         return;
    //     }
    // };
    // Lấy danh sách mã khách hàng
    const fetchDataCustomer = async () => {
        try {
            const response = await repositoryPos.get(
                `/api/v1/TblDmCustomer/get-list?Skip=0&Take=200`
            );
            if (response && response.success) {
                const transformedData = response.data
                    .filter((item: any) => item.name !== null)
                    .map((item: any) => ({
                        value: String(item.id),
                        label: String(item.code) + " - " + item.name,
                    }))
                    .filter(
                        (item: any, index: number, self: any[]) =>
                            index === self.findIndex((t) => t.value === item.value)
                    );

                setDataCustomer(transformedData);
            } else {
                setDataCustomer([]);
            }
        } catch (error) {
            console.error(error);
            return;
        }
    };
    useEffect(() => {
        fetchData();
    }, [pagination]);

    const formatCurrencyVND = (value: any) => {
        if (value == null) {
            return "";
        }
        // Định dạng số theo chuẩn Việt Nam
        return value.toLocaleString("vi-VN", {
            minimumFractionDigits: 0, // Không hiển thị phần thập phân
            maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
        });
    };

    // const exportDataExcel = async () => {
    //     // let url = ``;
    //     // if (formSeachInput.getValues().keySearch) {
    //     //   url += `?KeySearch=${formSeachInput.getValues().keySearch}`;
    //     // }
    //     // if (formSeachInput.getValues().StartTime) {
    //     //   url += `&StartTime=${formSeachInput.getValues().StartTime}`;
    //     // }

    //     // if (formSeachInput.getValues().EndTime) {
    //     //   url += `&EndTime=${formSeachInput.getValues().EndTime}`;
    //     // }
    //     // // if (userLogin) {
    //     // //   url += `&Cnids=${userLogin.branchId}`;
    //     // // }
    //     // if (search.KhoIds && search.KhoIds.length > 0) {
    //     //   search.KhoIds.forEach((KhoIds) => {
    //     //     url += `&KhoIds=${KhoIds}`;
    //     //   });
    //     // }
    //     // if (search.Bpids && search.Bpids.length > 0) {
    //     //   search.Bpids.forEach((Bpids) => {
    //     //     url += `&Bpids=${Bpids}`;
    //     //   });
    //     // }
    //     // if (search.custId && search.custId.length > 0) {
    //     //   search.custId.forEach((custId) => {
    //     //     url += `&custId=${custId}`;
    //     //   });
    //     // }
    //     let params = new URLSearchParams();

    //     // Add the query parameters if they exist
    //     const keySearch = formSeachInput.getValues().keySearch;
    //     if (keySearch) {
    //         params.append("KeySearch", keySearch);
    //     }

    //     const startTime = formSeachInput.getValues().StartTime;
    //     if (startTime) {
    //         params.append("StartTime", startTime);
    //     }

    //     const endTime = formSeachInput.getValues().EndTime;
    //     if (endTime) {
    //         params.append("EndTime", endTime);
    //     }

    //     // Example of adding custom query params for arrays
    //     // if (search.KhoIds && search.KhoIds.length > 0) {
    //     //     params.append("KhoIds", search.KhoIds.join(","));
    //     // }

    //     // if (search.Bpids && search.Bpids.length > 0) {
    //     //     params.append("Bpids", search.Bpids.join(","));
    //     // }

    //     if (search.custId && search.custId.length > 0) {
    //         params.append("custId", search.custId.join(","));
    //     }

    //     const baseUrl = params.toString()
    //         ? `/api/v1/ExportExcel/export-item-sales-daily?${params.toString()}`
    //         : `/api/v1/ExportExcel/export-item-sales-daily`;

    //     const _url = BASE_API_MDM + baseUrl;

    //     const fullUrl = new URL(_url);
    //     const token = localStorage.getItem("token");
    //     fetch(fullUrl.toString(), {
    //         method: "GET", // Use the appropriate HTTP method
    //         headers: {
    //             Authorization: `Bearer ${token?.replace(/"/g, "")}`,
    //             "Content-Type": "application/vnd.ms-excel",
    //         },
    //     })
    //         .then((response) => {
    //             if (response.ok) {
    //                 return response.blob(); // Assuming the response is a file or blob
    //             } else {
    //                 throw new Error("Failed to fetch the resource");
    //             }
    //         })

    //         .then((blob) => {
    //             // Open the blob in a new tab
    //             const url = window.URL.createObjectURL(blob);
    //             let filename = "bao_cao_ban_hang_theo_ngay.xlsx"; // Tên mặc định nếu không có header
    //             const link = document.createElement("a");
    //             link.href = url;
    //             link.download = filename;
    //             document.body.appendChild(link);
    //             link.click();
    //             link.remove();
    //             window.URL.revokeObjectURL(url);
    //         })
    //         .catch((error) => {
    //             console.error("Error:", error);
    //         });
    // };
    useEffect(() => {
        const headerHeight = headerRef.current?.offsetHeight || 0;
        const handleResize = () => {
            // 190 là chiều cao của phần phân trang
            // headerHeight là chiều cao của phần header
            setHeight(window.innerHeight - (150 + headerHeight));
        };

        handleResize(); // Set initial height
        window.addEventListener("resize", handleResize); // Update height on window resize

        return () => {
            window.removeEventListener("resize", handleResize); // Clean up event listener
        };
    }, []);

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                style={{
                    border: "1px solid #dee2e6",
                    padding: "5px 10px",
                }}
            >
                <BreadcrumbLayout1></BreadcrumbLayout1>
                <Menu shadow="md" width={200}>
                    {/* <Menu.Target>
                        <Button
                            // size="xs"
                            rightSection={
                                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                            }
                        >
                            Chức năng
                        </Button>
                    </Menu.Target> */}

                    <Menu.Dropdown>
                        {/* <Menu.Label>Application</Menu.Label> */}
                        {/* <Menu.Item
                            leftSection={
                                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                            }
                            // onClick={handleExport}
                            onClick={() => exportDataExcel()}
                        >
                            Export Excel
                        </Menu.Item> */}
                        {/* <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip> */}
                    </Menu.Dropdown>
                </Menu>
            </Flex>
            <Box
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    flexWrap: "wrap",
                    // marginTop: "10px",
                }}
            >
                <Grid style={{ width: "100%" }} mt={10}>
                    <Grid.Col span={sizeGrid}>
                        <Input
                            size="sm"
                            placeholder="Từ khoá"
                            type="text"
                            leftSection={<IconSearch color="#15aabf" />}
                            key={formSeachInput.key("keySearch")}
                            {...formSeachInput.getInputProps("keySearch")}
                            onKeyDown={async (e) => {
                                if (e.key === "Enter") {
                                    fetchData();
                                }
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={sizeGrid}>
                        <DatePickerInput
                            size="sm"
                            placeholder="Chọn ngày bắt đầu"
                            locale="vi"
                            valueFormat="DD/MM/YYYY"
                            leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
                            onChange={(date) => {
                                formSeachInput.setFieldValue(
                                    "StartTime",
                                    formatDateNotTimeZone(date) || ""
                                );
                            }}
                            clearable
                        />
                    </Grid.Col>
                    <Grid.Col span={sizeGrid}>
                        <DatePickerInput
                            size="sm"
                            placeholder="Chọn ngày kết thúc"
                            locale="vi"
                            valueFormat="DD/MM/YYYY"
                            leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
                            // maxDate={new Date(formSeachInput.getValues().StartTime)}
                            maxDate={
                                formSeachInput.getValues().StartTime
                                    ? addMonths(new Date(formSeachInput.getValues().StartTime), 1)
                                    : undefined
                            }
                            onChange={(date) => {
                                formSeachInput.setFieldValue(
                                    "EndTime",
                                    formatDateNotTimeZone(date) || ""
                                );
                            }}
                            clearable
                        />
                    </Grid.Col>
                    {/* <Grid.Col span={sizeGrid}>
                        <Select
                            size="sm"
                            placeholder="Vui lòng chọn kho"
                            searchable
                            clearable
                            leftSection={<IconBuildingWarehouse size={20} color="#15aabf" />}
                            // disabled={search.Cnids.length === 0}
                            onClick={() => {
                                if (dataInventory.length === 0) {
                                    fetchDataKho();
                                }
                            }}
                            data={dataInventory}
                            onChange={(value) =>
                                handleChangeSearchValue(value ? [value] : null, "KhoIds")
                            } // Cập nhật KhoIds
                        />
                    </Grid.Col>
                    <Grid.Col span={sizeGrid}>
                        <Select
                            size="sm"
                            placeholder="Vui lòng chọn bộ phận"
                            searchable
                            clearable
                            leftSection={<IconUsers size={20} color="#15aabf" />}
                            data={dataDepartment}
                            onClick={() => {
                                if (dataDepartment.length === 0) {
                                    fetchDataBp();
                                }
                            }}
                            onChange={(value) =>
                                handleChangeSearchValue(value ? [value] : null, "Bpids")
                            } // Cập nhật Bpids
                        />
                    </Grid.Col> */}
                    {/* <Grid.Col span={sizeGrid}>
                        <Select
                            size="sm"
                            placeholder="Vui lòng chọn khách hàng"
                            searchable
                            clearable
                            leftSection={<IconUsers size={20} color="#15aabf" />}
                            data={dataCustomer}
                            onClick={() => {
                                if (dataCustomer.length === 0) {
                                    fetchDataCustomer();
                                }
                            }}
                            onChange={(value) =>
                                handleChangeSearchValue(value ? [value] : null, "custId")
                            } // Cập nhật custId
                        />
                    </Grid.Col> */}
                    <Grid.Col span={sizeGrid}>
                        <Button
                            type="submit"
                            leftSection={<IconSearch size={20} />}
                            color="blue"
                            variant="outline"
                            onClick={async () => {
                                await fetchData();
                            }}
                        >
                            Tìm kiếm
                        </Button>{" "}
                    </Grid.Col>
                </Grid>

                {/* <Select
            size="sm"
            placeholder="Chi nhánh"
            searchable
            clearable
            data={dataBranchCode}
            leftSection={<IconMapPin size={20} color="#15aabf" />}
            onClick={() => {
              if (dataBranchCode.length === 0) {
                DataBranchCode();
              }
            }}
            onChange={(value) =>
              handleChangeSearchValue(value ? [value] : null, "Cnids")
            } // Cập nhật Cnids
          /> */}
            </Box>

            <div className="mt-5" style={{ marginTop: "10px" }}>
                <MantineReactTable table={table} />
            </div>
        </>
    );
};

export default ReportTransaction;
