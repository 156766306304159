import {
  ActionIcon,
  Button,
  Checkbox,
  Combobox,
  FileButton,
  FileInput,
  Flex,
  Group,
  Input,
  Loader,
  LoadingOverlay,
  Menu,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
  UnstyledButton,
  rem,
  useCombobox,
} from "@mantine/core";
import { DateTimePicker, DateValue } from "@mantine/dates";
import { useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCaretDown,
  IconDeviceFloppy,
  IconFileDownload,
  IconFileExport,
  IconFileUpload,
  IconPlus,
  IconSelect,
  IconTrash,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import {
  isNullOrUndefined,
  isNullOrUndefinedArry,
} from "../../../_base/extension/StringExtension";
import { handleKeyDownNegativeNum } from "../../../_base/helper/FunctionHelper";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateNotTimeZone } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { tblItem } from "../../../model/TblItem";
import {
  tblPriceList,
  tblPriceListCommand,
  tblPriceListCommandList,
} from "../../../model/TblPriceList";
import ModelAddProduct from "./ModelAddProduct";
import { TblPriceListDetailCommand } from "../../../model/TblPriceListDetailCommand";
import ModelAddProductV2 from "./ModelAddProductV2";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";


import {
  AllCommunityModule,
  CellSelectionOptions,
  ClientSideRowModelModule,
  ColDef,
  ColGroupDef,
  DefaultMenuItem,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  INumberCellEditorParams,
  MenuItemDef,
  ModuleRegistry,
  ValidationModule,
  ValueFormatterParams,
  createGrid,
} from "ag-grid-community";
import { AG_GRID_LOCALE_VN } from "@ag-grid-community/locale";
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import {
  AdvancedFilterModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  GetRowIdParams,
  GridChartsModule,
  IServerSideDatasource,
  IServerSideGetRowsRequest,
  MasterDetailModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowClassParams,
  RowGroupingModule,
  RowStyle,
  ServerSideRowModelModule,
  SetFilterModule,
  SparklinesModule,
  StatusBarModule,
} from "ag-grid-enterprise";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  AllCommunityModule
]);
declare var XLSX: any;

const ListDetailsPrice = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const combobox = useCombobox();
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;
  const isDetailProp = location.state && location.state.isDetailProp;
  const isEditProp = location.state && location.state.isEditProp;
  const [dataBranch, setDataBranch] = useState<any[]>([]);

  //#region new

  const containerStyle = useMemo(
    () => ({ width: "100%", height: height, marginTop: "15px" }),
    [height]
  );
  function formatNumber(number: number) {
    return Math.floor(number).toLocaleString();
  }
  function currencyFormatter(params: ValueFormatterParams) {
    return formatNumber(params.value ?? 0) + " ₫";
    // return (
    //   <NumberFormatter
    //     value={params.value}
    //     thousandSeparator=","
    //     suffix=" ₫"
    //   />
    // );
  }
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<TblPriceListDetailCommand[]>([]);
  const columnDefs = useMemo<ColDef<TblPriceListDetailCommand>[]>(
    () => [
      {
        field: "itemCode",
        headerName: "Mã sản phẩm",
        editable: false,
        lockPinned: true,
        pinned: "left",
        filter: "agTextColumnFilter",
      },
      {
        field: "itemName",
        headerName: "Tên sản phẩm",
        editable: false,
        width: 350,
        filter: "agTextColumnFilter",
        cellRenderer: (
          params: CustomCellRendererProps<TblPriceListDetailCommand>
        ) => {
          return (
            <Tooltip label={params.data?.itemName ?? ""}>
              <div>{params.data?.itemName ?? ""}</div>
            </Tooltip>
          );
        },
      },
      {
        field: "listPrice",
        headerName: "Giá bán lẻ",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
      {
        field: "webPrice",
        headerName: "Giá niêm yết",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
      {
        field: "wholesalePrice1",
        headerName: "Giá Bán Buôn 1",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
      {
        field: "wholesalePrice2",
        headerName: "Giá Bán Buôn 2",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
      {
        field: "wholesalePrice3",
        headerName: "Giá Bán Buôn 3",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
    ],
    [rowData]
  );

  useEffect(() => {
    console.log(rowData);
  }, [rowData]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: true,
      filter: true,
      floatingFilter: true,
    };
  }, []);

  //#endregion

  const entity: tblPriceListCommand = {
    id: 0,
    priceListNum: null,
    description: null,
    status: 0,
    isDrap: 0,
    listIdBranch: null,
    activeDate: null,
    inactiveDate: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    attribute6: null,
    attribute7: null,
    attribute8: null,
    attribute9: null,
    attribute10: null,
    attribute11: null,
    attribute12: null,
    attribute13: null,
    attribute14: null,
    attribute15: null,
    approveBy: null,
    approveDate: null,
  };

  const [dataPriceList, setDataPriceList] = useState<tblPriceList>({
    tblPriceListCommand: entity,
    tblPriceListCommandList: [],
  });

  const [data, setData] = useState<tblPriceListCommandList[]>([]);
  const [dataAdd, setDataAdd] = useState<tblPriceListCommandList[]>([]);
  const [dataEdit, setDataEdit] = useState<tblPriceListCommandList[]>([]);
  const [dataDelete, setDataDelete] = useState<number[]>([]);

  const validateRequired = (value: string) =>
    value !== undefined && value !== null && value.length > 0;

  function validateUser(user: any) {
    return {
      firstName: !validateRequired(user.itemName)
        ? "First Name is Required"
        : "",
      lastName: !validateRequired(user.itemCode) ? "Last Name is Required" : "",
    };
  }

  const handleSaveUser: MRT_TableOptions<any>["onEditingRowSave"] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    const dataEdit = {
      ...dataPriceList,
      tblPriceListCommandList: dataPriceList?.tblPriceListCommandList?.map(
        (item: any) => {
          if (item.itemCode === values.itemCode) {
            return {
              ...item,
              ...values,
            };
          }
          return item;
        }
      ),
    };
    setDataPriceList(dataEdit);
    setData((prevData) =>
      prevData?.map((item) => {
        if (item.itemCode === values.itemCode) {
          setDataEdit((prevData) => {
            const dataPrev = prevData?.filter((item) => item?.type !== "ADD");
            const existingItemIndex = dataPrev.findIndex(
              (data) => data.itemId === values.itemId
            );

            if (existingItemIndex >= 0) {
              dataPrev[existingItemIndex] = {
                ...dataPrev[existingItemIndex],
                ...values,
              };
            } else {
              dataPrev.push({
                ...item,
                ...values,
              });
            }

            return dataPrev;
          });
          return {
            ...item,
            ...values,
          };
        }
        return item;
      })
    );
    table.setEditingRow(null);
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(
        dataPriceList?.tblPriceListModelList || []
      );
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-san-pham-bang-gia-POS.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleSavePriceList = async () => {
    let dataNewPriceList = {
      TblPriceListAddCommandList: rowData,
      //  tblPriceListUpdateCommandList: dataEdit,
      // idsDelete: dataDelete,
      tblPriceListCommand: {
        ...dataPriceList.tblPriceListCommand,
        activeDate:
          typeof dataPriceList.tblPriceListCommand?.activeDate === "number"
            ? new Date(
                dataPriceList.tblPriceListCommand?.activeDate
              ).toISOString()
            : dataPriceList.tblPriceListCommand?.activeDate,
        inactiveDate:
          typeof dataPriceList.tblPriceListCommand?.inactiveDate === "number"
            ? new Date(
                dataPriceList.tblPriceListCommand?.inactiveDate
              ).toISOString()
            : dataPriceList.tblPriceListCommand?.inactiveDate,
      },
    };

    try {
      const { priceListNum, activeDate, inactiveDate, listIdBranch, isDrap } =
        dataNewPriceList.tblPriceListCommand;

      if (!priceListNum) {
        NotificationExtension.Fails("Vui lòng nhập số giao dịch !");
        return;
      }

      if (!activeDate) {
        NotificationExtension.Fails("Vui lòng nhập ngày hoạt động !");
        return;
      }

      if (!inactiveDate) {
        NotificationExtension.Fails("Vui lòng nhập ngày dừng hoạt !");
        return;
      }

      if (!listIdBranch || listIdBranch.length === 0) {
        NotificationExtension.Fails("Vui lòng nhập trung tâm !");
        return;
      }

      // if (isNullOrUndefined(isDrap)) {
      //   NotificationExtension.Fails("Vui lòng chọn trạng thái bảng giá !");
      //   return;
      // }

      const response = await repositoryPos.post(
        "/api/v1/TblPriceList/create",
        dataNewPriceList
      );

      if (response && response.success) {
        NotificationExtension.Success("Lưu bảng giá thành công !");
        setTimeout(() => navigate("/config-system/config-price"), 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBranchSelect = (val: string) => {
    setDataPriceList((prevData) => {
      let listIdBranch = prevData?.tblPriceListCommand?.listIdBranch;
      let branchArray = listIdBranch ? listIdBranch.split(",") : [];

      if (branchArray.includes(val)) {
        // Remove the value
        branchArray = branchArray.filter((branch) => branch !== val);
      } else {
        // Add the value
        branchArray.push(val);
      }

      const updatedListIdBranch = branchArray?.join(",");

      return {
        ...prevData,
        tblPriceListCommand: {
          ...prevData.tblPriceListCommand,
          listIdBranch: updatedListIdBranch,
        },
      };
    });
  };

  const handleCreateUser: MRT_TableOptions<any>["onCreatingRowSave"] = async ({
    values,
    exitCreatingMode,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    exitCreatingMode();
  };

  const handleChangeDataPriceListCommand = (
    value: string | number | null,
    key: string
  ) => {
    setDataPriceList((prevData) => {
      return {
        ...prevData,
        tblPriceListCommand: {
          ...prevData?.tblPriceListCommand,
          [key]: value ? value : null,
        },
      };
    });
  };

  const handleChangeDataItem = (
    itemCode: string,
    newValue: number | string | null,
    key: string,
    type?: string
  ) => {
    setData((prevItems) =>
      prevItems.map((item) =>
        item.itemCode === itemCode
          ? { ...item, [key]: newValue ? newValue : null }
          : item
      )
    );
    if (type === "ADD") {
      setDataAdd((prevItems) =>
        prevItems.map((item) =>
          item.itemCode === itemCode
            ? { ...item, [key]: newValue ? newValue : null }
            : item
        )
      );
    }
  };

  const handleAddProductToData = (
    dataSelect: tblItem[],
    productId?: string
  ) => {
    if (productId) {
      if (dataSelect.length > 0) {
        const productToUpdate = dataSelect[0];

        const dataEdit = {
          ...dataPriceList,
          tblPriceListCommandList: dataPriceList?.tblPriceListCommandList?.map(
            (item: any) => {
              if (item.itemCode === productId) {
                return {
                  ...item,
                  itemCode: productToUpdate.itemCode,
                  itemName: productToUpdate.itemName,
                };
              }
              return item;
            }
          ),
        };

        setDataPriceList(dataEdit);
        setData((prevData) =>
          prevData?.map((item) => {
            if (item.itemCode === productId) {
              return {
                ...item,
                itemCode: productToUpdate.itemCode,
                itemName: productToUpdate.itemName,
              };
            }
            return item;
          })
        );
      }
    } else {
      const dataAddProduct = dataSelect?.map((product) => ({
        id: 0,
        idPriceLis: 0,
        itemId: product.itemId, // Set the correct itemId
        itemCode: product.itemCode,
        itemName: product.itemName,
        priceNoVat: 0,
        priceIncludeVat: 0,
        discountPercent: 0,
        discountAmount: 0,
        vatRate: 0,
        vatAmount: 0,
        wholesalePrice1: 0,
        wholesalePrice2: 0,
        wholesalePrice3: 0,
        listPrice: 0,
        webPrice: 0,
        demoPrice: 0,
        bonusRate: 0,
        bunus: 0, // Make sure this matches your interface spelling
        lastPoPrice: 0,
        onhandAvgPrice: 0, // Correct the spelling to match your interface
        priceMin: 0,
        priceMax: 0,
        approveStatus: null, // Ensure this matches the interface type
        approveBy: null, // Ensure this matches the interface type
        approveDate: null, // Ensure this matches the interface type
        attribute1: null,
        attribute2: null,
        attribute3: null,
        attribute4: null,
        attribute5: null,
        attribute6: null,
        attribute7: null,
        attribute8: null,
        attribute9: null,
        attribute10: null,
        attribute11: null,
        attribute12: null,
        attribute13: null,
        attribute14: null,
        attribute15: null,
        createBy: null,
        createDate: null,
        lastUpdateBy: null,
        lastUpdateDate: null,
        type: "ADD",
      }));

      setDataPriceList((prevData) => ({
        ...prevData,
        tblPriceListCommandList: [
          ...(prevData?.tblPriceListCommandList ?? []),
          ...dataAddProduct,
        ],
      }));
      setData((prevData) => [...dataAddProduct, ...prevData]);
      setDataAdd((prev) => [...prev, ...dataAddProduct]);
    }
    modals.closeAll();
  };

  const convertListId = (listId: string) => {
    const arrId = listId.split(",");
    const listBranch = arrId?.map(
      (item) =>
        dataBranch.find(
          (branch) => branch.value?.toString() === item?.toString()
        )?.text
    );
    return listBranch?.join(", ");
  };

  const handleRowDelete = (row: MRT_Row<any>) => {
    setData((prevData) => prevData.filter((_, index) => index !== row.index));
    setDataDelete((prev) => {
      if (row.original.type === "ADD") {
        setDataAdd((prevData) =>
          prevData.filter((item) => item.itemCode !== row.original.itemCode)
        );
        return [...prev];
      }
      return [...prev, row.original?.id];
    });

    setDataPriceList((prevData) => ({
      ...prevData,
      tblPriceListCommandList: (prevData?.tblPriceListCommandList ?? []).filter(
        (_, index) => index !== row.index
      ),
    }));
  };

  const handleAddProduct = (
    editTable: boolean,
    productId?: string,
    dataList?: any[]
  ) => {
    // modals.closeAll();
    modals.openConfirmModal({
      title: "Thêm sản phẩm",
      centered: true,
      size: "100%",
      fullScreen: false,
      children: (
        <ModelAddProductV2
          handleAddProduct={setRowData}
          listIdItem={rowData?.map((item) => item.itemId) ?? []}
        ></ModelAddProductV2>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  useHotkeys([
    [
      "F11",
      () => {
        handleAddProduct(false);
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setDataPriceList({
      tblPriceListCommand: entity,
      tblPriceListCommandList: [],
    });
    setRowCount(0);

    let url = `&Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    try {
      let callapi = await repositoryPos.get(
        `/api/v1/TblPriceList/detail?id=${id}${url}`
      );
      if (isNullOrUndefined(callapi) || isNullOrUndefinedArry(callapi?.data)) {
        setRowCount(0);
        setDataPriceList({
          tblPriceListCommand: entity,
          tblPriceListCommandList: [],
        });
      } else {
        setDataPriceList(
          callapi?.data
            ? {
                tblPriceListCommand: {
                  ...callapi?.data?.tblPriceListModel,
                  activeDate: callapi?.data?.tblPriceListModel?.activeDate
                    ? new Date(
                        callapi?.data?.tblPriceListModel?.activeDate
                      ).setHours(
                        new Date(
                          callapi?.data?.tblPriceListModel?.activeDate
                        ).getHours()
                      )
                    : null,
                  inactiveDate: callapi?.data?.tblPriceListModel?.inactiveDate
                    ? new Date(
                        callapi?.data?.tblPriceListModel?.inactiveDate
                      ).setHours(
                        new Date(
                          callapi?.data?.tblPriceListModel?.inactiveDate
                        ).getHours()
                      )
                    : null,
                },
                tblPriceListCommandList: callapi?.data?.tblPriceListModelList,
              }
            : {
                tblPriceListCommand: entity,
                tblPriceListCommandList: [],
              }
        );
        setData(callapi?.data?.tblPriceListModelList);
        setRowCount(callapi?.totalCount ?? 0);
        //  table.resetRowSelection();
      }
      return callapi?.dataPriceList;
    } catch (error: any) {
      setIsError(true);
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const fetchDataCreate = async () => {
    try {
      const callApi = await repositoryPos.get(
        "/api/v1/TblPriceList/create?prefix=BG"
      );
      if (isNullOrUndefined(callApi) || isNullOrUndefined(callApi?.data)) {
        setDataPriceList({
          tblPriceListCommand: entity,
          tblPriceListCommandList: [],
        });
      } else {
        setDataPriceList((prev) => ({
          ...prev,
          tblPriceListCommand: callApi?.data,
          tblPriceListCommandList: prev.tblPriceListCommandList,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    } else {
      fetchDataCreate();
    }
  }, [id, pagination]);

  useEffect(() => {
    const fetchDataBranch = async () => {
      const response = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmInventory/get-select-branch"
      );

      setDataBranch(response?.data);
    };

    fetchDataBranch();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const gridRef = useRef<AgGridReact<TblPriceListDetailCommand>>(null);

  const getContextMenuItems = useCallback(
    (
      params: GetContextMenuItemsParams<TblPriceListDetailCommand>
    ): (DefaultMenuItem | MenuItemDef)[] => {
      const _number = params?.node?.data?.itemCode || "";
      var result: (DefaultMenuItem | MenuItemDef)[] = [
        {
          name: "Thêm vật tư",
          action: () => {
            handleAddProduct(false);
          },
          cssClasses: ["red", "bold"],
        },
        // {
        //   // custom item
        //   name: "Sửa vật tư: " + _number,
        //   action: () => {
        //     const index = params?.node?.rowIndex;
        //     if (!index) {
        //       NotificationExtension.Fails("Vui lòng chọn vật tư !");
        //       return;
        //     }
        //     gridRef.current!.api.setFocusedCell(1, "make");
        //     gridRef.current!.api.startEditingCell({
        //       rowIndex: 1,
        //       colKey: "make",
        //     });
        //   },
        //   cssClasses: ["red", "bold"],
        // },
        {
          // custom item
          name: "Xóa vật tư: " + _number,
          action: () => {
            setRowData(rowData?.filter((item) => item.itemCode !== _number));
          },
          cssClasses: ["red", "bold"],
        },

        // {
        //   // custom item
        //   name: "Hủy đơn: " + _number,
        //   action: () => {
        //     window.alert("Alerting about " + params.value);
        //   },
        //   cssClasses: ["red", "bold"],
        // },
        // "cut",
        "copy",
        // "copyWithHeaders",
        // "copyWithGroupHeaders",
        // "paste",
        // "separator",
        // "chartRange",

        // "export",
      ];
      return result;
    },
    [window, rowData]
  );

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    console.log(file);
    if (file) {
      if (
        file &&
        (file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel")
      )
        uploadFile(file);
      else NotificationExtension.Fails("File không đúng định đạng Excel !");
    }
  }, [file]);

  const uploadFile = async (e: File) => {
    if (!e) {
      NotificationExtension.Fails("File rỗng hoặc chưa chọn file !");
      return;
    }
    open();

    const formData = new FormData();
    formData.append("FormFile", e);

    var resSaveFile = await repositoryDelivery.post<MessageResponse<boolean>>(
      "/api/v1/ImportExelPriceList/import-excel-price-list",
      formData
    );
    if (resSaveFile && resSaveFile.httpStatusCode === 200) {
      if (resSaveFile.success) {
        setFile(null);
        NotificationExtension.Success("Import file thành công!");

        setTimeout(() => {
          navigate("/config-system/config-price");
        }, 2000);
      } else NotificationExtension.Fails("Import file thất bại !");
    }
    setFile(null);
    close();
  };

  // read the raw data and convert it to a XLSX workbook
  function convertDataToWorkbook(dataRows: ArrayBuffer) {
    /* convert data to binary string */
    var data = new Uint8Array(dataRows);
    var arr = [];

    for (var i = 0; i !== data.length; ++i) {
      arr[i] = String.fromCharCode(data[i]);
    }

    var bstr = arr.join("");

    return XLSX.read(bstr, { type: "binary" });
  }

  // pull out the values we're after, converting it into an array of rowData

  function populateGrid(workbook: any) {
    // our data is in the first sheet
    var firstSheetName = workbook.SheetNames[0];
    var worksheet = workbook.Sheets[firstSheetName];

    // we expect the following columns to be present
    var columns: Record<string, string> = {
      A: "Mã sản phẩm",
      B: "Tên sản phẩm",
      C: "Giá bán lẻ",
      D: "Giá niêm yết",
      E: "Giá bán buôn 1",
      F: "Giá bán buôn 2",
      G: "Giá bán buôn 3",
    };

    var rowData = [];

    // start at the 2nd row - the first row are the headers
    var rowIndex = 8;

    // iterate over the worksheet pulling out the columns we're expecting
    while (worksheet["A" + rowIndex]) {
      var row: any = {};
      Object.keys(columns).forEach((column) => {
        row[columns[column]] = worksheet[column + rowIndex].w;
      });

      rowData.push(row);
      console.log(row);
      rowIndex++;
    }

    // // finally, set the imported rowData into the grid
    // gridApi!.setGridOption("rowData", rowData);
  }

  const [visible, { toggle, open, close }] = useDisclosure(false);

  return (
    <>
      <_breadcrumb></_breadcrumb>
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 5 }}
        loaderProps={{
          children: (
            <Flex direction={"column"} align="center" gap="xs">
              <Loader type="dots" size={35}></Loader>
              <Title size={15}> Đang upload tài liệu...</Title>
            </Flex>
          ),
        }}
      />

      <PanelGroup direction="horizontal" id="group">
        <Panel
          defaultSize={20}
          minSize={15}
          maxSize={85}
          style={{ marginTop: "55px" }}
        >
          <Table striped highlightOnHover withTableBorder withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>Thông tin bảng giá</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Th>Mã bảng giá</Table.Th>
                <Table.Td>
                  <Text fw={"500"}>
                    {dataPriceList?.tblPriceListCommand?.priceListNum}
                  </Text>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Người lập</Table.Th>
                <Table.Td>
                  <TextInput
                    value={localStorage.getItem("fullName")?.toString() || ""}
                    readOnly
                    variant="filled"
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Ngày hiệu lực</Table.Th>
                <Table.Td>
                  <DateTimePicker
                    placeholder="Ngày hiệu lực"
                    clearable
                    locale="vi"
                    value={
                      dataPriceList?.tblPriceListCommand?.activeDate
                        ? new Date(dataPriceList.tblPriceListCommand.activeDate)
                        : null
                    }
                    valueFormat="DD/MM/YYYY hh:mm A"
                    minDate={new Date()}
                    onChange={(value: DateValue) => {
                      handleChangeDataPriceListCommand(
                        formatDateNotTimeZone(value) || null,
                        "activeDate"
                      );
                      handleChangeDataPriceListCommand("", "inactiveDate");
                    }}
                    readOnly={isDetailProp}
                    variant={isDetailProp ? "filled" : undefined}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Ngày hết hiệu lực</Table.Th>
                <Table.Td>
                  <DateTimePicker
                    placeholder="Ngày hết hiệu lực"
                    clearable
                    locale="vi"
                    minDate={
                      dataPriceList?.tblPriceListCommand?.activeDate
                        ? dayjs(dataPriceList.tblPriceListCommand.activeDate)
                            .add(1, "hour")
                            .toDate()
                        : dayjs().add(1, "hour").toDate()
                    }
                    value={
                      dataPriceList?.tblPriceListCommand?.inactiveDate
                        ? new Date(
                            dataPriceList.tblPriceListCommand.inactiveDate
                          )
                        : null
                    }
                    valueFormat="DD/MM/YYYY hh:mm A"
                    onChange={(value) => {
                      handleChangeDataPriceListCommand(
                        formatDateNotTimeZone(value) || null,
                        "inactiveDate"
                      );
                    }}
                    disabled={!dataPriceList?.tblPriceListCommand?.activeDate}
                    readOnly={isDetailProp}
                    variant={isDetailProp ? "filled" : undefined}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Chi nhánh áp dụng</Table.Th>
                <Table.Td>
                  <Combobox
                    store={combobox}
                    onOptionSubmit={handleBranchSelect}
                  >
                    <Combobox.Target>
                      <Textarea
                        placeholder="Chi nhánh áp dụng"
                        onClick={() => {
                          if (!isDetailProp) combobox.openDropdown();
                        }}
                        value={convertListId(
                          dataPriceList.tblPriceListCommand?.listIdBranch ?? ""
                        )?.split(",")}
                        onChange={(event) => {
                          setDataPriceList((prevData) => ({
                            ...prevData,
                            tblPriceListCommand: {
                              ...prevData.tblPriceListCommand,
                              listIdBranch: event.currentTarget.value,
                            },
                          }));
                          combobox.updateSelectedOptionIndex();
                        }}
                        readOnly={isDetailProp}
                        variant={isDetailProp ? "filled" : undefined}
                        rows={4}
                      />
                    </Combobox.Target>

                    <Combobox.Dropdown>
                      <Combobox.Options>
                        <ScrollArea.Autosize type="scroll" mah={300}>
                          {dataBranch?.map((branch: any) => (
                            <Combobox.Option
                              value={branch.value}
                              key={branch.value}
                              active={
                                dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                  branch.value
                                ) ||
                                dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                  "-1"
                                )
                              }
                              onMouseOver={() => combobox.resetSelectedOption()}
                              disabled={
                                (dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                  "-1"
                                ) &&
                                  branch.value !== "-1") ||
                                (!dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                  "-1"
                                ) &&
                                  !!dataPriceList?.tblPriceListCommand
                                    ?.listIdBranch &&
                                  branch.value === "-1" &&
                                  dataPriceList?.tblPriceListCommand
                                    ?.listIdBranch?.length > 0)
                              }
                            >
                              <Group gap="sm">
                                <Checkbox
                                  label={branch.text}
                                  checked={dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                    branch.value
                                  )}
                                  disabled={
                                    dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                      "-1"
                                    ) && branch.value !== "-1"
                                  }
                                  onChange={() => {}}
                                  aria-hidden
                                  tabIndex={-1}
                                  style={{ pointerEvents: undefined }}
                                />
                              </Group>
                            </Combobox.Option>
                          ))}
                        </ScrollArea.Autosize>
                      </Combobox.Options>
                    </Combobox.Dropdown>
                  </Combobox>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Trạng thái bảng giá</Table.Th>
                <Table.Td>
                  <Select
                    placeholder="Thông tin phê duyệt"
                    clearable
                    data={[
                      {
                        value: "0",
                        label: "Chưa duyệt",
                      },
                      {
                        value: "1",
                        label: "Đã duyệt",
                      },
                    ]}
                    defaultValue={"1"}
                    value={dataPriceList?.tblPriceListCommand?.status?.toString()}
                    onChange={(e) =>
                      handleChangeDataPriceListCommand(e, "status")
                    }
                    readOnly={isDetailProp}
                    variant={isDetailProp ? "filled" : undefined}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Mô tả</Table.Th>
                <Table.Td>
                  <Textarea
                    placeholder={!isDetailProp ? "Nhập mô tả..." : undefined}
                    value={
                      dataPriceList?.tblPriceListCommand?.description || ""
                    }
                    onChange={(e) => {
                      handleChangeDataPriceListCommand(
                        e.currentTarget.value,
                        "description"
                      );
                    }}
                    rows={16}
                    readOnly={isDetailProp}
                    variant={isDetailProp ? "filled" : undefined}
                  />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Panel>
        <PanelResizeHandle id="resize-handle" style={{ width: "5px" }} />
        <Panel defaultSize={70} minSize={25} maxSize={75}>
          {/* <MantineReactTable table={table} /> */}

          <div>
            <div ref={headerRef} style={{ width: "100%" }}>
              <Flex
                gap="md"
                justify={{ sm: "flex-start", lg: "flex-end" }}
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  leftSection={<IconPlus size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={() => handleAddProduct(false)}
                  disabled={isDetailProp}
                >
                  Thêm mới (F11)
                </Button>
                <Button
                  leftSection={<IconArrowLeft size={14} />}
                  color="red"
                  variant="outline"
                  onClick={() => navigate("/config-system/config-price")}
                >
                  Quay lại
                </Button>
                <Button
                  leftSection={<IconDeviceFloppy size={14} />}
                  color="green"
                  variant="outline"
                  onClick={handleSavePriceList}
                  disabled={isDetailProp}
                >
                  Lưu bảng giá
                </Button>
                <FileButton
                  onChange={setFile}
                  //    accept="image/png,image/jpeg"
                >
                  {(props) => (
                    <Button
                      leftSection={
                        <IconFileDownload
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                      {...props}
                    >
                      Import Excel
                    </Button>
                  )}
                </FileButton>
              </Flex>
            </div>
          </div>

          <div style={containerStyle}>
            <div style={gridStyle} className={"ag-theme-quartz"}>
              <AgGridReact<TblPriceListDetailCommand>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                suppressColumnVirtualisation={true}
                suppressRowVirtualisation={true}
                pagination={true}
                localeText={AG_GRID_LOCALE_VN}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                cacheBlockSize={paginationPageSize}
                maxBlocksInCache={3}
                getContextMenuItems={getContextMenuItems}
                editType="fullRow"
              />
            </div>
          </div>
        </Panel>
      </PanelGroup>
    </>
  );
};

export default ListDetailsPrice;
