import { Badge, Box, Button, Input, Select } from "@mantine/core";
import {
  IconPackage,
  IconPhone,
  IconSearch,
  IconUser,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { green } from "../../../const/variables";
import { SelectListItemModel } from "../../../model/SelectListItem";
const InformationWarranty = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataItemCode, setDataItemCode] = useState<SelectListItemModel[]>([]);
  const [data, setData] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState({
    keySearch: "",
    TenKh: "",
    Tel: "",
    MaSp: "",
  });

  type InventoryItem = {
    branchCode: string;
    branchName: string;
    countSoLuong: number;
    dvt: string | null;
    idBranch: number;
    idKho: number;
    maKho: string;
    maSp: string;
    soLuong: number | null;
    tenKho: string;
    tenSp: string;
  };
  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  const handleChangeSearch = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "transactionCode",
        header: "Mã giao dịch",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue ?? null}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "errorNumber",
        header: "Mã phiếu",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge bd="1px solid blue" variant="light" color="blue">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "requestNum",
        header: "Số phiếu yêu cầu",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge bd="1px solid blue" variant="light" color="blue">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "dateReceive",
        header: "Ngày nhận",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "custMeeting",
        header: "Tên khách hàng",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.custMeeting?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "custPhone",
        header: "Số điện thoại",
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ",
      },
      {
        accessorKey: "channelName",
        header: "Kênh",
      },
      {
        accessorKey: "customerGroup",
        header: "Nhóm khách hàng",
      },
      {
        accessorKey: "nameCustomerGroup",
        header: "Tên nhóm khách hàng",
      },
      {
        accessorKey: "inventoryCode",
        header: "Mã kho",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
      },
      {
        accessorKey: "branchAddress",
        header: "Địa chỉ chi nhánh",
      },
      {
        accessorKey: "createByName",
        header: "Người tạo",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.createByName?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "rolename",
        header: "Vai trò",
      },
      {
        accessorKey: "depname",
        header: "Phòng ban",
      },
      {
        accessorKey: "processingMethod",
        header: "Phương thức xử lý",
      },
      {
        accessorKey: "processingMethodName",
        header: "Tên phương thức xử lý",
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
      },

      {
        accessorKey: "att1",
        header: "Số lượng nhập kho",
      },
      {
        accessorKey: "attribute10",
        header: ":HANG_NGOAI/HANG_HACOM",
      },
      {
        accessorKey: "attribute2",
        header: "Số lượng đã trả",
      },
      {
        accessorKey: "attribute16",
        header: "Trang thiếu phiếu",
      },
    ],
    []
  );
  const columnsTicketDetailModel = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "orderCode",
        header: "Mã hóa đơn",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue ?? null}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
      },
      {
        accessorKey: "parentSerial",
        header: "Mã vạch cha",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge bd="1px solid blue" variant="light" color="blue">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "serial",
        header: "Mã vạch",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge bd="1px solid blue" variant="light" color="blue">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "newSerial",
        header: "Mã vạch mới",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge bd="1px solid blue" variant="light" color="blue">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "acessory",
        header: "Phụ kiện đi kềm",
      },
      {
        accessorKey: "serviceType",
        header: "Loại dịch vụ",
      },

      {
        accessorKey: "warrantyTerm",
        header: "Thời hạn bảo hành",
      },
      {
        accessorKey: "daysReturn",
        header: "Số ngày hẹn trả",
      },
      {
        accessorKey: "scheduleTime",
        header: "Ngày hẹn trả",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "itemCodeBak",
        header: "Mã sản phẩm cũ",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "itemNameBak",
        header: "Tên sản phẩm cũ",
      },
      {
        accessorKey: "dvtBak",
        header: "Đơn vị tính cũ",
      },
      {
        accessorKey: "attribute1",
        header: "Check hàng hacom/ngoại",
      },
      {
        accessorKey: "attribute6",
        header: "Tình trạng",
      },
      {
        accessorKey: "attribute7",
        header: "Tình trạng nợ",
      },
      {
        accessorKey: "latestPrice",
        header: "Giá mới nhất",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "buyingDate",
        header: "Ngày mua",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "priceOnPriceList",
        header: "Giá trên bảng giá",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "itemStatus",
        header: "Trạng thái sản phẩm",
      },

      {
        accessorKey: "serialStatus",
        header: "Trạng thái mã vạch",
      },
      {
        accessorKey: "amount",
        header: "Số lượng",
      },
      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "conclusionError",
        header: "Kết luận lỗi",
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
      },
    ],
    []
  );
  // Hàm lấy dữ liệu
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    if (search.TenKh) {
      url += `&TenKh=${search.TenKh.trim().toLowerCase()}`;
    }
    if (search.Tel) {
      url += `&Tel=${search.Tel.trim().toLowerCase()}`;
    }
    if (search.MaSp) {
      url += `&MaSp=${search.MaSp}`;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/WarrantyInfo/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data.lists;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const ticketModel = data.map((item: any) => item.ticketModel);
  const ticketDetailModel = data.map((item: any) => item.ticketDetailModel);
  const table = useMantineReactTable({
    columns,
    data: ticketModel,
    enableColumnResizing: true,
    rowCount,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,
    enableBottomToolbar: false,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["transactionCode"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box
          style={{
            display: "grid",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <Input
              style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
              placeholder="Nhập từ khóa"
              type="text"
              leftSection={<IconSearch size={"20"} color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearch(e.target.value ?? "", "keySearch");
              }}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  await fetchData();
                }
              }}
            />
            <Input
              style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
              placeholder="Nhập sô điên thoại"
              type="text"
              leftSection={<IconPhone size={"20"} color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearch(e.target.value ?? "", "Tel");
              }}
            />
            <Input
              style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
              placeholder="Nhập tên khách hàng"
              type="text"
              leftSection={<IconUser size={"20"} color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearch(e.target.value ?? "", "TenKh");
              }}
            />
            <Input
              style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
              placeholder="Nhập mã sản phẩm"
              type="text"
              leftSection={<IconPackage size={"20"} color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearch(e.target.value ?? "", "MaSp");
              }}
            />
            <Button
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Box>
          <Box style={{ fontSize: 20, fontWeight: 600, marginTop: 10 }}>
            Thông tin đơn hàng
          </Box>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    mantinePaginationProps: {
      showRowsPerPage: false,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  const tableTicketDetailModel = useMantineReactTable({
    columns: columnsTicketDetailModel,
    data: ticketDetailModel,
    enableColumnResizing: true,
    rowCount,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,

    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["orderCode"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Box style={{ fontSize: 20, fontWeight: 600, marginTop: 10 }}>
          Thông tin Bảo Hành
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (250 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);
  useEffect(() => {
    fetchData();
  }, [pagination]);
  return (
    <Box>
      <MantineReactTable table={table} />
      <MantineReactTable table={tableTicketDetailModel} />
    </Box>
  );
};

export default InformationWarranty;
