import {
  Box,
  Button,
  Fieldset,
  Grid,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import React from "react";
import { sky_blue } from "../../../const/variables";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { DateInput, DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  ProductWarrantyHistoryModel,
  PurchaseHistoryModel,
} from "../../../model/PurchaseHistory";
import { formatDateNotTimeZone } from "../../../common/FormatDate/FormatDate";
import { useDisclosure } from "@mantine/hooks";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
type Props = {
  fetchData?: () => void;
};
const CreateProductWarrantyHistory = ({ fetchData }: Props) => {
  const [visible, { close, open }] = useDisclosure(false);

  const entity = {
    id: 0,
    branchCode: "", // Mã chi nhánh
    name: "", // Tên chi nhánh
    phone: "", // Số điện thoại
    itemCode: "", // Mã sản phẩm
    nameItem: "", // Tên sản phẩm
    serial: "", // Serial sản phẩm
    replaceItemCode: "", // Mã sản phẩm thay thế
    replaceItemName: "", // Tên sản phẩm thay thế
    replaceSerial: "", // Serial sản phẩm thay thế
    warrantyDate: "", // Ngày bảo hành
    vendorDate: "", // Ngày nhập từ nhà cung cấp
    vendorCode: "", // Mã nhà cung cấp
    vendorName: "", // Tên nhà cung cấp
    warrantyReturnDate: "", // Ngày trả bảo hành
    warrantyAddDate: "", // Ngày thêm bảo hành
  };

  const form = useForm<ProductWarrantyHistoryModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      branchCode: (value) => (value ? null : "Vui lòng điền mã đơn vị"),
      name: (value) => (value ? null : "Vui lòng điền tên đơn vị"),
      phone: (value) => {
        if (value && !/^\d{8,11}$/.test(value)) {
          return "Số điện thoại phải có từ 8 đến 11 chữ số!";
        }
        if (!value) {
          return "Vui lòng nhập số điện thoại!";
        }
        return null;
      },
      itemCode: (value) => (value ? null : "Vui lòng điền mã vật tư"),
      nameItem: (value) => (value ? null : "Vui lòng điền tên vật tư"),
      serial: (value) => (value ? null : "Vui lòng điền serial"),
      replaceItemCode: (value) =>
        value ? null : "Vui lòng điền mã vật tư thay thế",
      replaceItemName: (value) =>
        value ? null : "Vui lòng điền tên vật tư thay thế",
      replaceSerial: (value) =>
        value ? null : "Vui lòng điền serial thay thế",
      warrantyDate: (value) =>
        value ? null : "Vui lòng điền thời hạn bảo hành",
      vendorDate: (value) =>
        value ? null : "Vui lòng điền ngày nhập nhà cung cấp",
      vendorCode: (value) => (value ? null : "Vui lòng điền mã nhà cung cấp"),
      vendorName: (value) => (value ? null : "Vui lòng điền tên nhà cung cấp"),
      warrantyReturnDate: (value) =>
        value ? null : "Vui lòng điền ngày trả bảo hành",
      warrantyAddDate: (value) =>
        value ? null : "Vui lòng điền ngày thêm bảo hành",
    },
  });

  const handleCreatePurchaseHistory = async (value: any) => {
    open();
    try {
      const response = await repositoryMdm.post(
        "/api/v1/TblDmWarrantyHistory/create",
        value
      );
      if (response && response.success) {
        NotificationExtension.Success(
          "Tạo mới lịch sử sản phẩm bảo hành thành công"
        );
        setTimeout(() => {
          modals.closeAll();
          if (fetchData) {
            fetchData();
          }
        }, 1000);
      }
    } catch (error) {
      NotificationExtension.Fails("Tạo mới lịch sử sản phẩm bảo hành thất bại");
      setTimeout(() => {
        modals.closeAll();
      }, 1000);
      console.log("error", error);
    } finally {
      close();
    }
  };

  return (
    <div>
      <Box
        component="form"
        mx="auto"
        w={{
          base: "300px",
          xs: "400px",
          sm: "500px",
          md: "650px",
          lg: "800px",
        }}
        onSubmit={form.onSubmit((e: any) => {
          handleCreatePurchaseHistory(e);
        })}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Mã đơn vị"}
              placeholder={"Nhập mã đơn vị"}
              {...form.getInputProps("branchCode")}
              withAsterisk
            ></TextInput>
            <Fieldset legend="Khách hàng" mt={10}>
              <Grid gutter={"sm"}>
                <Grid.Col span={12}>
                  <TextInput
                    label={"Tên khách hàng"}
                    placeholder={"Nhập tên khách hàng"}
                    {...form.getInputProps("name")}
                    withAsterisk
                  ></TextInput>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    label={"SĐT"}
                    type="number"
                    placeholder={"Nhập SĐT"}
                    {...form.getInputProps("phone")}
                    withAsterisk
                  ></TextInput>
                </Grid.Col>
              </Grid>
            </Fieldset>
            <Fieldset legend="Nhà cung cấp nhập" mt={10}>
              <Grid gutter={"sm"}>
                <Grid.Col span={12}>
                  <TextInput
                    label={"Mã nhà cung cấp"}
                    placeholder={"Nhập mã nhà cung cấp"}
                    withAsterisk
                    {...form.getInputProps("vendorCode")}
                  ></TextInput>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    label={"Tên nhà cung cấp"}
                    placeholder={"Nhập tên nhà cung cấp"}
                    {...form.getInputProps("vendorName")}
                    withAsterisk
                  ></TextInput>
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Grid gutter={"md"}>
              <Grid.Col span={12}>
                <TextInput
                  label={"Mã vật tư"}
                  placeholder={"Nhập mã vật tư"}
                  {...form.getInputProps("itemCode")}
                  withAsterisk
                ></TextInput>
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  label={"Tên vật tư"}
                  placeholder={"Nhập tên vật tư"}
                  {...form.getInputProps("nameItem")}
                  withAsterisk
                ></TextInput>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      label={"Serial"}
                      placeholder={"Nhập serial"}
                      {...form.getInputProps("serial")}
                      withAsterisk
                    ></TextInput>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label={"Serial thay thế"}
                      placeholder={"Nhập serial thay thế"}
                      {...form.getInputProps("replaceSerial")}
                      withAsterisk
                    ></TextInput>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      label={"Mã vật tư thay thế"}
                      placeholder={"Nhập mã vật tư thay thế"}
                      {...form.getInputProps("replaceItemCode")}
                      withAsterisk
                    ></TextInput>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label={"Tên vật tư thay thế"}
                      placeholder={"Nhập tên vật tư thay thế"}
                      {...form.getInputProps("replaceItemName")}
                      withAsterisk
                    ></TextInput>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid>
                  <Grid.Col span={6}>
                    <DateInput
                      size="sm"
                      label="Ngày nhận bảo hành"
                      placeholder="Chọn ngày nhận bảo hành"
                      locale="vi"
                      valueFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      {...form.getInputProps("warrantyDate")}
                      onChange={(date) => {
                        form.setFieldValue(
                          "warrantyDate",
                          formatDateNotTimeZone(date) || ""
                        );
                      }}
                      clearable
                      withAsterisk
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <DateInput
                      size="sm"
                      label="Ngày nhập từ NCC"
                      placeholder="Chọn ngày nhập từ NCC"
                      locale="vi"
                      valueFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      {...form.getInputProps("vendorDate")}
                      onChange={(date) => {
                        form.setFieldValue(
                          "vendorDate",
                          formatDateNotTimeZone(date) || ""
                        );
                      }}
                      clearable
                      withAsterisk
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid>
                  <Grid.Col span={6}>
                    <DateInput
                      size="sm"
                      label="Ngày trả bảo hành"
                      placeholder="Chọn ngày trả bảo hành"
                      locale="vi"
                      valueFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      {...form.getInputProps("warrantyReturnDate")}
                      onChange={(date) => {
                        form.setFieldValue(
                          "warrantyReturnDate",
                          formatDateNotTimeZone(date) || ""
                        );
                      }}
                      clearable
                      withAsterisk
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <DateInput
                      size="sm"
                      label="Ngày cộng bảo hành"
                      placeholder="Chọn ngày cộng bảo hành"
                      locale="vi"
                      valueFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      {...form.getInputProps("warrantyAddDate")}
                      onChange={(date) => {
                        form.setFieldValue(
                          "warrantyAddDate",
                          formatDateNotTimeZone(date) || ""
                        );
                      }}
                      clearable
                      withAsterisk
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            // loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
          >
            Quay lại
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            // loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
        </Group>
      </Box>
    </div>
  );
};

export default CreateProductWarrantyHistory;
