import { Checkbox, NumberFormatter, ScrollArea, Table } from "@mantine/core";
import React from "react";
import {
  itemAddOnPromotionModel,
  TblItemPromotionModel,
} from "../../../../../model/TblItem";

interface AddOnTableProps {
  setListSelectedAddOn: React.Dispatch<
    React.SetStateAction<itemAddOnPromotionModel[]>
  >;
  listSelectedAddOn: itemAddOnPromotionModel[];

  selectedPromotion: TblItemPromotionModel;
}

const AddOnTable: React.FC<AddOnTableProps> = ({
  selectedPromotion,
  setListSelectedAddOn,
  listSelectedAddOn,
}) => {
  const handleCheckboxChange = (promotionItem: itemAddOnPromotionModel) => {
    setListSelectedAddOn((prevList) => {
      const isAlreadySelected = prevList.some(
        (item) => item.idItemAddOn === promotionItem.idItemAddOn
      );
      if (isAlreadySelected) {
        // Loại bỏ nếu đã được chọn
        return prevList.filter(
          (item) => item.idItemAddOn !== promotionItem.idItemAddOn
        );
      } else {
        // Thêm vào nếu chưa được chọn
        return [...prevList, promotionItem];
      }
    });
  };

  return (
    <ScrollArea h={500}>
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Mã kho</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
            <Table.Th>% Chiết khấu</Table.Th>
            <Table.Th>Tiền chiết khấu</Table.Th>
            <Table.Th>Tồn kho</Table.Th>
            <Table.Th>SL khả dụng</Table.Th>
            <Table.Th>Chọn</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {selectedPromotion?.tblPromotionItemAddOn
            .filter((promotionItem) => promotionItem?.itemForSellModel) // Kiểm tra tồn tại
            .map((promotionItem) => (
              <Table.Tr key={promotionItem?.idItemAddOn}>
                <Table.Td>
                  {promotionItem?.itemForSellModel?.productCode}
                </Table.Td>
                <Table.Td>
                  {promotionItem?.itemForSellModel?.wareHouseCode}
                </Table.Td>
                <Table.Td>
                  {promotionItem?.itemForSellModel?.productName}
                </Table.Td>
                <Table.Td>{promotionItem?.itemForSellModel?.unit}</Table.Td>
                <Table.Td>{promotionItem?.discountPercent}%</Table.Td>
                <Table.Td>
                  <NumberFormatter
                    value={promotionItem.discountAmount || 0}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="₫"
                  />
                </Table.Td>
                <Table.Td>{promotionItem?.itemForSellModel?.tons}</Table.Td>
                <Table.Td>
                  {promotionItem?.itemForSellModel?.tons -
                    promotionItem?.itemForSellModel?.tonao}
                </Table.Td>
                <Table.Td>
                  <Checkbox
                    checked={listSelectedAddOn.some(
                      (item) => item.idItemAddOn === promotionItem.idItemAddOn
                    )}
                    onChange={() => handleCheckboxChange(promotionItem)}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default AddOnTable;
