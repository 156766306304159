import { ComboboxItem, Select, Text, TextInput, Tooltip } from "@mantine/core";
import React, { useEffect } from "react";
import { repositoryDelivery } from "../../../../_base/_const/_constVar";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { MessageResponse } from "../../../../model/MessageResponse";
import { tblInventoryTransaction } from "../../../../model/TblOutWard";
import RenderTable from "./RenderTable";
import classesButton from "../../../../../src/Styles/StyleButton.module.css";

interface InventoryPosition {
  active: boolean;
  createBy: string | null;
  createDate: string | null;
  id: number;
  invCode: string;
  invId: number;
  lastUpdateBy: string | null;
  lastUpdateDate: string | null;
  positionCode: string;
  positionName: string;
  subInvCode: string;
  subInvId: number;
}

const RequestTransferOutWard = ({
  dataSearchSourceCodeOption,
  dataOutWard,
  sourceCode,
  setSourceCode,
  handleKeyDownSourceCode,
  dataOrderDetail,
  handleChangeValueInput,
  isSave,
  setSearchSourceCode,
  isEdit,
  isLocked,
  haveStatus,
  haveSourceCode,
  action,
  updatedDataInventoryTransactionType,
}: RequestTransferOutWardProps) => {
  const [dataDmItemPosition, setDataDmItemPosition] = React.useState<
    InventoryPosition[]
  >([]);

  const isDisabled =
    dataOutWard.status === "LOCK" ||
    sourceCode ||
    isSave ||
    ["DETAIL", "EDIT"].includes(action);

  const headers = [
    { label: "", style: { width: "22%" } },
    { label: "Bên xuất", style: { width: "38%" } },
    { label: "Bên nhận", style: { width: "38%" } },
  ];

  const getDeliveryText = (delivery: string) => {
    switch (delivery) {
      case "KHONG":
        return "Nhận tại cửa hàng";
      case "NOI_BO":
      case "HA_COM":
        return "Nội bộ";
      default:
        return delivery;
    }
  };

  const getTblDmItemPosition = async () => {
    const response = await repositoryDelivery.get<
      MessageResponse<InventoryPosition[]>
    >(
      `/api/v1/TblDmItemPosition/get-select?SubInvCode=${dataOrderDetail.toInvCode}`
    );

    if (response && response.success) {
      let result = response.data;
      setDataDmItemPosition(result);
    }
  };

  const getdata =
    dataDmItemPosition.filter((item) => item?.positionCode?.startsWith("LR")) ??
    [];

  useEffect(() => {
    getTblDmItemPosition();
  }, [dataOrderDetail.toInvCode]);

  useEffect(() => {
    handleChangeValueInput(getdata[0]?.id?.toString(), "atribute10");
  }, [getdata[0]?.id?.toString()]);

  const rows = [
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian tạo
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {formatDateTransfer(dataOrderDetail?.createDate ?? "")}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian hoàn thành
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {formatDateTransfer(dataOutWard?.finishDate)}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu xuất kho
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOutWard?.transactionCode}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu yêu cầu xuất kho
          </Text>
        ),
      },
      {
        content:
          !isEdit && !haveSourceCode ? (
            <Tooltip
              label={
                dataOutWard?.sourceCode
                  ? dataOutWard?.sourceCode
                  : "Vui lòng chọn phiếu"
              }
            >
              <Select
                w={"160px"}
                placeholder="Mã phiếu yêu cầu xuất kho"
                data={dataSearchSourceCodeOption}
                size="sm"
                disabled={
                  !dataOutWard.sourceType ||
                  dataOutWard.status === "LOCK" ||
                  sourceCode ||
                  isSave
                }
                className={classesButton.text_input}
                searchable
                clearable
                onSearchChange={(e) => {
                  if (setSearchSourceCode) {
                    setSearchSourceCode(e);
                  }
                }}
                onKeyDown={handleKeyDownSourceCode}
                value={sourceCode || dataOutWard.sourceCode}
                onChange={(value) => {
                  if (handleChangeValueInput) {
                    handleChangeValueInput(value || "", "sourceCode");
                  }
                  if (setSourceCode) {
                    setSourceCode(value);
                  }
                }}
                nothingFoundMessage={"Không tìm thấy dữ liệu !"}
              />
            </Tooltip>
          ) : (
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOutWard.sourceCode}{" "}
            </Text>
          ),
      },
      { content: null },
    ],
    // [
    //   {
    //     content: (
    //       <Text
    //         fw={700}
    //         w={"100px"}
    //         size="sm"
    //         style={{ whiteSpace: "normal" }}
    //         lineClamp={2}
    //         truncate
    //       >
    //         Lý do
    //       </Text>
    //     ),
    //   },
    //   {
    //     content: !isDisabled ? (
    //       <Select
    //         searchable
    //         clearable
    //         w={160}
    //         className={classesButton.text_input}
    //         placeholder="Chọn lý do"
    //         disabled={
    //           dataOutWard.status === "LOCK" ||
    //           sourceCode ||
    //           isSave ||
    //           ["DETAIL", "EDIT"].includes(action)
    //         }
    //         data={updatedDataInventoryTransactionType}
    //         value={dataOutWard.sourceType?.toString() || ""}
    //         onChange={(value) => {
    //           handleChangeValueInput(value || "", "sourceType");
    //         }}
    //       />
    //     ) : (
    //       <Text
    //         fw={700}
    //         w={"100px"}
    //         size="sm"
    //         style={{ whiteSpace: "normal" }}
    //         lineClamp={2}
    //         truncate
    //       >
    //         {
    //           updatedDataInventoryTransactionType?.find(
    //             (t) => t.value === dataOutWard.sourceType?.toString()
    //           )?.label
    //         }
    //       </Text>
    //     ),
    //   },
    //   {
    //     content: null,
    //   },
    // ],
    haveStatus
      ? [
          {
            content: (
              <Text
                fw={700}
                w={"100px"}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              >
                Tình trạng
              </Text>
            ),
          },
          { content: null },
          { content: null },
        ]
      : [],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chi nhánh
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.fromBranchName}
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.toBranchName}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Địa điểm làm việc
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.fromBranchAddress}
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.toBranchAddress}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Kho
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.fromInvCode
              ? `${dataOrderDetail.fromInvCode} - ${
                  dataOrderDetail.fromInvName || ""
                }`
              : dataOrderDetail.fromInvName || ""}
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.toInvCode
              ? `${dataOrderDetail.toInvCode} - ${
                  dataOrderDetail.toInvName || ""
                }`
              : dataOrderDetail.toInvName || ""}
          </Text>
        ),
      },
    ],
    (!isLocked && dataOutWard.sourceType === "XUAT_DOI_LINH_KIEN") ||
    dataOutWard.sourceType === "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT"
      ? [
          {
            content: (
              <Text
                fw={700}
                w={"100px"}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              >
                Vị trí
              </Text>
            ),
          },
          {
            content: (
              <Text
                w={"150px"}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              ></Text>
            ),
          },
          {
            content: (
              <Text
                w={"150px"}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              >
                {getdata[0]?.positionCode &&
                  getdata[0]?.positionCode + " - " + getdata[0]?.positionName}
              </Text>
            ),
          },
        ]
      : [],

    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Họ & Tên
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail?.createName
              ? dataOrderDetail?.createName +
                " - " +
                dataOrderDetail?.createCode
              : ""}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chức danh
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail?.createRoleName || ""}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Giao hàng
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {getDeliveryText(dataOrderDetail?.delivery || "")}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Ghi chú
          </Text>
        ),
      },
      {
        content: (
          <TextInput
            error={
              dataOutWard?.description && dataOutWard.description.length > 300
                ? "Ghi chú không được vượt quá 300 kí tự!"
                : null
            }
            className={classesButton.text_input}
            value={dataOutWard?.description ?? ""}
            onChange={(e) => {
              handleChangeValueInput(e.target.value, "description");
            }}
            disabled={isLocked}
            placeholder={isLocked ? "" : "Vui lòng nhập"}
          />
        ),
        colSpan: 2,
      },
    ],
  ];

  return (
    <RenderTable
      headers={headers}
      rows={rows.filter((item) => item.length !== 0)}
    />
  );
};

type RequestTransferOutWardProps = {
  dataSearchSourceCodeOption?: any[];
  dataPosition?: ComboboxItem[];
  dataOutWard: tblInventoryTransaction;
  dataOrderDetail: any;
  handleChangeValueInput: (value: string | boolean | null, key: string) => void;
  setSourceCode?: (value: any) => void;
  handleKeyDownSourceCode?: (e: any) => void;
  sourceCode?: any;
  isSave?: boolean;
  setSearchSourceCode?: (newValue: React.SetStateAction<string>) => void;
  isEdit?: boolean;
  isLocked?: boolean;
  haveStatus?: boolean;
  haveSourceCode?: boolean;
  action: string;
  updatedDataInventoryTransactionType?: ComboboxItem[];
};

export default RequestTransferOutWard;
