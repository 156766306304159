import {
  Badge,
  Box,
  Button,
  Input,
  Menu,
  MultiSelect,
  rem,
  Select,
  Text,
} from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import {
  IconBuildingSkyscraper,
  IconCaretDown,
  IconFileExport,
  IconMapPin,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { BASE_API_MDM } from "../../../../config";
import { _localization } from "../../../../config/location";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { SelectListItemBase } from "../../../../_base/model/_base/SelectListItemBase";
import { MessageResponse } from "../../../../model/MessageResponse";

type ReportPhoneItem = {
  organizationId: number;
  maCn: string;
  tenCn: string;
  soLuongGd: number;
  tGiaVon: number;
  tDoanhThu: number;
  tongSauCk: number;
  tLai: number;
  fx: number;
};
const ReportBrach = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<ReportPhoneItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [dataSaleChannel, setDataBranchCode] = useState<SelectListItemBase[]>(
    []
  );
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
    },
  });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const exportDataExcel = async () => {
    const url = `/api/v1/ExportExcel/export-bao-cao-ban-hang-theo-chi-nhanh`;

    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "bao_cao_ban_hang_theo_chi_nhanh" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  const [search, setSearch] = useState({
    branchCode: [],
    MaCn: [],
  });
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };
  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (search.branchCode && search.branchCode.length > 0) {
      search.branchCode.forEach((branchCode) => {
        url += `&OrganizationId=${branchCode}`;
      });
    }
    if (search.MaCn && search.MaCn.length > 0) {
      search.MaCn.forEach((MaCn) => {
        url += `&MaCn=${MaCn}`;
      });
    }

    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/report-sales-branch${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<ReportPhoneItem>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="violet">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tenCn",
        header: "Tên chi nhánh",
      },
      {
        accessorKey: "soLuongGd",
        header: "Số lượng giao dịch",
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.soLuongGd || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng số lượng GG
              </Text>
              <Text ta="right" c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tGiaVon",
        header: "Giá vốn",
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tGiaVon || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng giá vốn
              </Text>
              <Text ta="right" c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tDoanhThu",
        header: "Doanh thu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tDoanhThu || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng doanh thu
              </Text>
              <Text ta="right" c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tongSauCk",
        header: "Sau chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tongSauCk || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng sau Chiết khấu
              </Text>
              <Text ta="right" c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tLai",
        header: "Lãi",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tLai || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng tiền lãi
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "fx",
        header: "Tỷ lệ",
        Cell: ({ row }: any) => {
          const value = row.original.fx;
          if (value != null) {
            const formattedValue = (value * 100).toFixed(2) + "%";
            return (
              <Text
                size="12.5px"
                style={{
                  fontWeight: 550,
                }}
              >
                {formattedValue}
              </Text>
            );
          }
          return null;
        },
      },
    ],
    []
  );
  const DataBranchCode = async () => {
    try {
      const response = await repositoryPos.get<
        MessageResponse<SelectListItemBase[]>
      >(`api/v1/TblDmInventory/get-select-branch?auth=true`);
      if (response && response.success) {
        const data = response.data;
        setDataBranchCode(data);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableColumnResizing: true,

    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableStickyFooter: true,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT"],
        // right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 550,
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  
  useEffect(() => {
    DataBranchCode();
  }, []);
  
  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (160 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: "10px",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
            marginTop: "10px",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Từ khoá"
            type="text"
            leftSection={<IconSearch color="#15aabf" />}
            key={formSeachInput.key("keySearch")}
            {...formSeachInput.getInputProps("keySearch")}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                fetchData();
              }
            }}
          />
          <MultiSelect
            placeholder="Chọn chi nhánh"
            clearable
            searchable
            data={dataSaleChannel
              .filter((ele) => ele.att2 && ele.text)
              .map((ele) => ({
                label: ele.att2 + " - " + ele.text,
                value: ele.att2,
              }))}
            leftSection={<IconBuildingSkyscraper size={20} color="#15aabf" />}
         
            onChange={(value) => {
              handleChangeSearchValue(value ? value : null, "MaCn");
            }}
          />
          <Button
            type="submit"
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Chức năng
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Đóng
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ReportBrach;
