import {
  Box,
  Button,
  Group,
  Input,
  Menu,
  MultiSelect,
  MultiSelectProps,
  rem,
  Text,
} from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import {
  IconBarcode,
  IconBuildingSkyscraper,
  IconCaretDown,
  IconFileExport,
  IconSearch,
} from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { _localization } from "../../../../config/location";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { BASE_API_MDM } from "../../../../config";

const MfgWipReport = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<any[]>([]);
  const [dataItemCode, setDataItemCode] = useState<SelectListItemModel[]>([]);
  const [dataSaleChannel, setDataBranchCode] = useState<SelectListItemModel[]>(
    []
  );

  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState({
    keySearch: "",
    branchCode: [],
    itemCode: [],
  });

  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };

  const handleChangeSearch = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "codeCn",
        header: "Mã chi nhánh",
      },
      {
        accessorKey: "nameCn",
        header: "Tên chi nhánh",
      },
      // {
      //   header: "Nhân viên lắp máy",
      //   id: "Nhanvienlapmay",
      //   mantineTableHeadCellProps: () => {
      //     return {
      //       style: {
      //         backgroundColor: "#f1f3f5",
      //       },
      //     };
      //   },
      //   columns: [
      //     {
      //       accessorKey: "codeNx",
      //       enableColumnActions: false,
      //       header: "Mã nhân viên lắp máy",
      //     },
      //     {
      //       accessorKey: "tenNx",
      //       enableColumnActions: false,
      //       header: "Tên nhân viên lắp máy",
      //     },
      //   ],
      // },
      //   {
      //     header: "Điểm lắp test",
      //     id: "Diemlaptest",
      //     mantineTableHeadCellProps: () => {
      //       return {
      //         style: {
      //           backgroundColor: "#f1f3f5",
      //         },
      //       };
      //     },
      //     columns: [
      //       {
      //         accessorKey: "createdDate2",
      //         enableColumnActions: false,
      //         header: "Độ khó (Để mặc định: bình thường)",
      //       },
      //       {
      //         accessorKey: "completiontime3",
      //         enableColumnActions: false,
      //         header: "Điểm (Có thể sửa, nhập số âm)",
      //       },
      //     ],
      //   },
      {
        accessorKey: "soCt",
        header: "Mã lệnh sản xuất",
      },
      {
        accessorKey: "maTp",
        header: "Mã thành phẩm",
      },
      {
        accessorKey: "tenTp",
        header: "Tên thành phẩm",
      },
      {
        accessorKey: "serialTp",
        header: "Serial thành phẩm",
      },
      {
        accessorKey: "tenNx",
        header: "Tên nhân viên xuất linh kiện SX",
      },
      {
        accessorKey: "tenNhapTp",
        header: "Tên nhân viên nhập thành phẩm",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,

    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        // left: ["STT", "maSp"],
        // right: ["statusName"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <div>
          <BreadcrumbLayout1></BreadcrumbLayout1>
        </div>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập tìm kiếm"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearch(e.target.value ?? "", "keySearch");
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />
          <MultiSelect
            placeholder="Chi nhánh"
            clearable
            searchable
            value={search.branchCode}
            data={dataSaleChannel.map((x) => x.value)}
            leftSection={<IconBuildingSkyscraper size={20} color="#15aabf" />}
            onChange={(value) => {
              handleChangeSearchValue(value ? value : null, "branchCode");
            }}
            renderOption={renderMultiSelectOptionBrachCide}
            onClick={() => {
              if (dataSaleChannel.length === 0) {
                DataBrachCode();
              }
            }}
          />

          <MultiSelect
            size="sm"
            placeholder="Mã sản phẩm"
            clearable
            searchable
            data={dataItemCode.map((x) => x.value)} // Hiển thị kết quả từ API
            value={search.itemCode}
            leftSection={<IconBarcode size={20} color="#15aabf" />}
            renderOption={renderMultiSelectOption}
            onSearchChange={(value) => {
              if (value.length >= 3) {
                DataItemCode(value); // Gọi API với từ khóa tìm kiếm
              }
            }}
            onChange={(value) => {
              handleChangeSearchValue(value, "itemCode");
            }}
          />

          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Chức năng
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Đóng
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //chi nhánh
  const DataBrachCode = async () => {
    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmInventory/get-select-branch?auth=true`
      );
      if (response && response.success) {
        const transformedData = response.data
          .filter((i: any) => i.att2 && i.text)
          .map((item: any) => {
            return {
              value: item.att2,
              label: item.att2 + " - " + item.text,
            };
          });

        setDataBranchCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  //mã sản phẩm
  const DataItemCode = async (keySearch: string) => {
    try {
      let url = "";
      if (keySearch && keySearch.length > 0) {
        url += `&ItemCodes=${keySearch}`;
      }
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-list-item-by-inv?Skip=0&Take=50` +
          url
      );
      if (response && response.success) {
        const transformedData = response.data
          ?.filter((i: any) => i.maSp && i.tenSp)
          .map((branch: any) => ({
            value: branch.maSp,
            label: branch.maSp + " - " + branch.tenSp,
          }));
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.value))
        ).map((value) =>
          transformedData.find((item: any) => item.value === value)
        );
        setDataItemCode(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  //lấy dữ liệu
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.branchCode && search.branchCode.length > 0) {
      search.branchCode.forEach((branchCode) => {
        url += `&MaCns=${branchCode}`;
      });
    }
    if (search.itemCode && search.itemCode.length > 0) {
      search.itemCode.forEach((itemCode) => {
        url += `&MaThanhPhams=${itemCode}`;
      });
    }
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/EmployeeWipPayroll/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  //export excel

  const exportDataExcel = async () => {
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.branchCode && search.branchCode.length > 0) {
      search.branchCode.forEach((branchCode) => {
        url += `&MaCns=${branchCode}`;
      });
    }
    if (search.itemCode && search.itemCode.length > 0) {
      search.itemCode.forEach((itemCode) => {
        url += `&MaThanhPhams=${itemCode}`;
      });
    }
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    const baseUrl = `/api/v1/ExportExcel/export-employee-wip-payroll${url}`;

    const _url = BASE_API_MDM + baseUrl;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    let filename = "bao_cao_lap_rap_may.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  useEffect(() => {
    fetchData();
  }, [pagination]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);
  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataItemCode.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );
  const renderMultiSelectOptionBrachCide: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>
        {dataSaleChannel.find((x) => x.value === option.value)?.label}
      </Text>
    </Group>
  );

  return (
    <Box>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default MfgWipReport;
