import { Checkbox, ScrollArea, Table } from "@mantine/core";
import React from "react";
import {
  itemGiftPromotionModel,
  TblItemPromotionModel,
} from "../../../../../model/TblItem";

interface GiftTableProps {
  selectedPromotion: TblItemPromotionModel;
  setListSelectedGift: React.Dispatch<
    React.SetStateAction<itemGiftPromotionModel[]>
  >;
  listSelectedGift: itemGiftPromotionModel[];
}

const GiftTable: React.FC<GiftTableProps> = ({
  selectedPromotion,
  listSelectedGift,
  setListSelectedGift,
}) => {
  const handleCheckboxChange = (promotionItem: itemGiftPromotionModel) => {
    setListSelectedGift((prevList) => {
      const isAlreadySelected = prevList.some(
        (item) => item.idItemGift === promotionItem.idItemGift
      );
      if (isAlreadySelected) {
        // Nếu đã chọn, loại bỏ khỏi danh sách
        return prevList.filter(
          (item) => item.idItemGift !== promotionItem.idItemGift
        );
      } else {
        // Nếu chưa chọn, thêm vào danh sách
        return [...prevList, promotionItem];
      }
    });
  };

  return (
    <ScrollArea h={500}>
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Mã kho</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
            <Table.Th>Tồn kho</Table.Th>
            <Table.Th>SL khả dụng</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Chọn</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {selectedPromotion?.tblPromotionItemGift?.map((promotionItem) => (
            <Table.Tr key={promotionItem?.idItemGift}>
              <Table.Td>
                {promotionItem?.itemForSellModel?.productCode}
              </Table.Td>
              <Table.Td>
                {promotionItem?.itemForSellModel?.wareHouseCode}
              </Table.Td>
              <Table.Td>
                {promotionItem?.itemForSellModel?.productName}
              </Table.Td>
              <Table.Td>{promotionItem?.itemForSellModel?.unit}</Table.Td>
              <Table.Td>{promotionItem?.itemForSellModel?.tons}</Table.Td>
              <Table.Td>
                {promotionItem?.itemForSellModel?.tons -
                  promotionItem?.itemForSellModel?.tonao}
              </Table.Td>
              <Table.Td>{promotionItem?.itemForSellModel?.warehouse}</Table.Td>
              <Table.Td>
                <Checkbox
                  checked={listSelectedGift.some(
                    (item) => item.idItemGift === promotionItem.idItemGift
                  )}
                  onChange={() => handleCheckboxChange(promotionItem)}
                />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default GiftTable;
