import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Menu,
  NumberFormatter,
  rem,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileText,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconUsers,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { modals } from "@mantine/modals";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { _timerDelayClick, repositoryPos } from "../../_base/_const/_constVar";
import { paginationBase } from "../../_base/model/_base/BaseTable";
import { _localization } from "../../config/location";
import { cobalt_blue, green } from "../../const/variables";
import { TblDebtGroup } from "../../model/TblDebtGroup";
import CreateDataView from "./CreateDataView";
import DetailDataView from "./DetailDataView";
import EditDataView from "./EditDataView";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import DropZoneFile from "./DropZoneFile";
import { IconPhone } from "@tabler/icons-react";

const DebtGroupListCus = ({ type }: DebtGroupListCusProps) => {
  //#region State
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  // @ts-ignore
  const [data, setData] = useState<TblDebtGroup[]>([]);
  const [dataTemp, setDataTemp] = useState<TblDebtGroup[]>([]);
  const [dataReview, setDataReview] = useState<TblDebtGroup[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [search, setSearch] = useState({
    keySearch: "",
    Phone: "",
    TaxCode: "",
    Code: "",
  });

  // Grid span
  const gridSpan = { base: 12, sm: 4, md: 4, lg: 4, xl: 4 };

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<TblDebtGroup>[]>(
    () => [
      {
        accessorKey: "groupCode",
        header: "Mã nhóm công nợ",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailItem(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 250,
      },
      {
        accessorKey: "groupName",
        header: "Tên nhóm",
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px" fw={"bold"}>
            {renderedCellValue}
          </Text>
        ),
        size: 550,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "taxCode",
        header: "Mã số thuế",
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px">
            {renderedCellValue === null ? "Không tồn tại" : renderedCellValue}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "address",
        header: "Địa chỉ",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "phone",
        header: "Điện thoại",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "contactPerson",
        header: "Người giao dịch",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "classification",
        header: "Phân loại",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "classificationName",
        header: "Tên phân loại",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "debtTerm",
        header: "Thời hạn nợ",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "debtLimit",
        header: "Giới hạn nợ",
        Cell: ({ renderedCellValue }: any) => (
          <NumberFormatter
            thousandSeparator={","}
            value={renderedCellValue}
          ></NumberFormatter>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableResizing: false,
        size: 85,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      if (type === 1) {
        xlsx.writeFile(workbook, "danh-sach-nhom-cong-no-khach-hang.xlsx");
      } else {
        xlsx.writeFile(workbook, "danh-sach-nhom-cong-no-nha-cung-cap.xlsx");
      }
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleImportExcel = async (file: any) => {
    if (!file) {
      NotificationExtension.Warn("Vui lòng chọn lại tệp !");
      return;
    } else {
      modals.closeAll();
      NotificationExtension.Success("Import excel thành công !");
    }

    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const data = e.target?.result;
      if (data) {
        const workbook = xlsx.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        worksheet = xlsx.utils.sheet_add_aoa(
          worksheet,
          [
            [
              "groupCode",
              "groupName",
              "taxCode",
              "address",
              "phone",
              "contactPerson",
              "classification",
              "classificationName",
              "debtTerm",
              "debtLimit",
            ],
          ],
          { origin: "A1" }
        );
        const jsonData = xlsx.utils.sheet_to_json(worksheet);
        const dataSubmit = jsonData?.map((item: any, index) => ({
          id: "0",
          groupCode: item.groupCode,
          groupName: item.groupName,
          taxCode: item.taxCode?.toString(),
          address: item.address,
          phone: item.phone?.toString(),
          contactPerson: item.contactPerson,
          classification: item.classification,
          classificationName: item.classificationName,
          debtTerm: Number(item.debtTerm?.toString().replace(/\s+/g, "")) || 0,
          debtLimit:
            Number(item.debtLimit?.toString().replace(/\s+/g, "")) || 0,
          type: type,
        }));
        setDataReview(dataSubmit);
        setTimeout(() => {
          reviewDataImport();
        }, 1000);
      }
    };
    fileReader.readAsBinaryString(file);
  };

  const handleOpenFileDrop = () => {
    try {
      modals.openConfirmModal({
        title: null,
        yOffset: "5%",
        withCloseButton: false,
        children: <DropZoneFile onImport={handleImportExcel}></DropZoneFile>,
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    } catch (e) {
      NotificationExtension.Fails("Import excel thất bại");
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const createListDebtGroup = async () => {
    try {
      if (dataReview.length > 0) {
        const response = await repositoryPos.post(
          "/api/v1/TblDebtGroup/create-list",
          dataReview
        );
        if (response?.success) {
          NotificationExtension.Success("Tạo mới thành công!");
          modals.closeAll();
        }
      } else {
        NotificationExtension.Warn("Vui lòng thêm nhóm công nợ!");
      }
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Type=${type}&Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.Code) {
      url += `&Code=${search.Code}`;
    }
    if (search.TaxCode) {
      url += `&TaxCode=${search.TaxCode}`;
    }
    if (search.Phone) {
      url += `&Phone=${search.Phone}`;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDebtGroup/get-list${url}`
      );
      if (response && response.success) {
        let result = response?.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response?.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  function createItem() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Tạo mới nhóm công nợ!</Title>
        </>
      ),
      size: "auto",
      children: <CreateDataView type={type} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function editItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Sửa nhóm công nợ!</Title>
        </>
      ),
      size: "auto",
      children: (
        <EditDataView id={id} type={type} onClose={setDeleteViewStatus} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function detailItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xem chi tiết nhóm công nợ!</Title>
        </>
      ),
      size: "auto",
      children: <DetailDataView id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function reviewDataImport() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xem lại danh sách nhóm công nợ!</Title>
        </>
      ),
      size: "auto",
      children: <MantineReactTable table={tableReview} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (pagination.pageIndex === 0 && data.length < pagination.pageSize) {
      fetchData();
    } else {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (210 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const tableReview = useMantineReactTable({
    columns,
    data: dataReview,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    renderBottomToolbar: (
      <>
        <Flex w={"100%"} my={"10px"} pr={"20px"} justify={"flex-end"}>
          <Button
            onClick={() => {
              createListDebtGroup();
            }}
          >
            Tạo mới
          </Button>
        </Flex>
      </>
    ),
    localization: _localization,
    enableRowSelection: true,
    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "groupCode"],
      },
      showColumnFilters: false,
      columnVisibility: { id: false, action: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    onPaginationChange: setPagination,
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: false,
      withEdges: true,
    },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: true,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableColumnResizing: true,
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },

    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "groupCode"],
        right: ["action"],
      },
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height + 50, minHeight: height + 50 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: f,
    // },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: true,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <>
      <>
        <div
          ref={headerRef}
          style={{
            width: "100%",
            border: "1px solid #dee2e6",
            padding: "10px",
          }}
        >
          <Grid>
            <Grid.Col span={{ base: 12, sm: 8, md: 8, lg: 8, xl: 8 }}>
              <Grid align="center" gutter={"xs"}>
                <Grid.Col span={gridSpan}>
                  <Input
                    flex={1}
                    placeholder="Nhập từ khóa tìm kiếm"
                    type="text"
                    w={"100%"}
                    leftSection={
                      <IconSearch
                        size={"20"}
                        color={cobalt_blue.base}
                      ></IconSearch>
                    }
                    onChange={(e) => {
                      handleChangeSearchValue(
                        e.target.value ?? "",
                        "keySearch"
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={gridSpan}>
                  <Input
                    flex={1}
                    placeholder="Nhập mã nhóm công nợ"
                    type="text"
                    w={"100%"}
                    leftSection={
                      <IconUsers
                        size={"20"}
                        color={cobalt_blue.base}
                      ></IconUsers>
                    }
                    onChange={(e) => {
                      handleChangeSearchValue(e.target.value ?? "", "Code");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={gridSpan}>
                  <Input
                    flex={1}
                    placeholder="Nhập mã số thuế"
                    type="text"
                    w={"100%"}
                    leftSection={
                      <IconFileText
                        size={"20"}
                        color={cobalt_blue.base}
                      ></IconFileText>
                    }
                    onChange={(e) => {
                      handleChangeSearchValue(e.target.value ?? "", "TaxCode");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={gridSpan}>
                  <Input
                    flex={1}
                    placeholder="Nhập số điện thoại"
                    type="text"
                    w={"100%"}
                    leftSection={
                      <IconPhone
                        size={"20"}
                        color={cobalt_blue.base}
                      ></IconPhone>
                    }
                    onChange={(e) => {
                      handleChangeSearchValue(e.target.value ?? "", "Phone");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={gridSpan}>
                  <Button
                    leftSection={<IconSearch size={14} />}
                    color="blue"
                    variant="outline"
                    onClick={async () => {
                      await fetchData();
                    }}
                  >
                    Tìm kiếm
                  </Button>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 4, md: 4, lg: 4, xl: 4 }}>
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  leftSection={<IconPlus size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    createItem();
                  }}
                >
                  Thêm mới
                </Button>
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button
                      // size="xs"
                      rightSection={
                        <IconCaretDown
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                    >
                      Chức năng
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    {/* <Menu.Item
                      leftSection={
                        <IconFileExport
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                      onClick={handleExport}
                    >
                      Export Excel
                    </Menu.Item> */}
                    <Menu.Item
                      leftSection={
                        <IconFileUpload
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                      onClick={() => handleOpenFileDrop()}
                    >
                      Import Excel
                    </Menu.Item>
                    <Menu.Item
                      leftSection={
                        <IconX style={{ width: rem(14), height: rem(14) }} />
                      }
                    >
                      Đóng
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Grid.Col>
          </Grid>
        </div>
        <div>
          <MantineReactTable table={table} />
        </div>
      </>
    </>
  );
};

type DebtGroupListCusProps = {
  type: number;
};

export default DebtGroupListCus;
