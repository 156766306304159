"use strict";

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import { createRoot } from "react-dom/client";

import {
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { randomId } from "@mantine/hooks";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { ReportInventorySerialOnhandModel } from "../../model/Report/ReportInventorySerialOnhandModel";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import {
  AllCommunityModule,
  CellSelectionOptions,
  ClientSideRowModelModule,
  ColDef,
  ColGroupDef,
  DefaultMenuItem,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  ModuleRegistry,
  ValidationModule,
  createGrid,
} from "ag-grid-community";
import { AG_GRID_LOCALE_VN } from "@ag-grid-community/locale";
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import {
  AdvancedFilterModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  GetRowIdParams,
  GridChartsModule,
  IServerSideDatasource,
  IServerSideGetRowsRequest,
  MasterDetailModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowClassParams,
  RowGroupingModule,
  RowStyle,
  ServerSideRowModelModule,
  SetFilterModule,
  SparklinesModule,
  StatusBarModule,
} from "ag-grid-enterprise";
ModuleRegistry.registerModules([
  ColumnsToolPanelModule,
  MenuModule,
  ServerSideRowModelModule,
  RichSelectModule,
  SetFilterModule,
  AdvancedFilterModule,
  FiltersToolPanelModule,
  MultiFilterModule,
  MasterDetailModule,
  ClientSideRowModelModule,
  ExcelExportModule,
  GridChartsModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  SparklinesModule,
  ClipboardModule,
]);

export const ReportInventorySerialOnhandView = () => {
  const [loading, setLoading] = useState(true);
  const gridRef = useRef<AgGridReact<ReportInventorySerialOnhandModel>>(null);
  const [height, setHeight] = useState(0);
  const headerRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (104 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: height }),
    [height]
  );
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%" }),
    [height]
  );

  const fetchData = async (request: IServerSideGetRowsRequest) => {
    try {
      const queryParams = {
        Request: request,
      };

      return await repositoryPos.post<
        MessageResponse<ReportInventorySerialOnhandModel[]>
      >("/api/v1/report/inventory-serial-onhand", queryParams);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getServerSideDatasource: () => IServerSideDatasource = () => {
    return {
      getRows: async (params) => {
        var response = await fetchData(params.request);
        if (response && response.success) {
          params.success({
            rowData: response.data,
            rowCount: response.totalCount,
          });
          setLoading(false);
        } else {
          params.fail();
          setLoading(false);
        }
      },
    };
  };
  const getRowId = useCallback((params: GetRowIdParams) => {
    if (params.data.id != null) {
      return "leaf-" + randomId();
    }
    const rowGroupCols = params.api.getRowGroupColumns();
    const rowGroupColIds = rowGroupCols.map((col) => col.getId()).join("-");
    const thisGroupCol = rowGroupCols[params.level];
    return (
      "group-" +
      rowGroupColIds +
      "-" +
      (params.parentKeys || []).join("-") +
      params.data[thisGroupCol.getColDef().field!]
    );
  }, []);

  const filterParamsDefault = {
    buttons: ["clear", "reset", "apply"],
    debounceMs: 1000,
    maxNumConditions: 5,
  };

  const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      field: "subInventoryName",
      colId: "subInventoryName",
      headerName: "Kho",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      filter: "agTextColumnFilter",
      width: 500,
      cellRenderer: (params: CustomCellRendererProps) => {
        return (
          <>
            {params.data.subInventoryCode
              ? "[" + params.data.subInventoryCode + "]"
              : ""}{" "}
            {params.data.subInventoryName}
          </>
        );
      },
    },
    {
      field: "itemCode",
      headerName: "Mã hàng",
      filter: "agTextColumnFilter",
    },
    {
      field: "serialNumber",
      headerName: "Serial",
      filter: "agTextColumnFilter",
    },
    {
      field: "itemName",
      headerName: "Tên sản phẩm",
      filter: "agTextColumnFilter",
      width: 900,
    },
    {
      headerName: "Thời gian",
      children: [
        {
          field: "entryDate",
          headerName: "Nhập kho",
          filter: "agTextColumnFilter",
          cellRenderer: (params: CustomCellRendererProps) => {
            if (
              typeof params.value === "string" ||
              params.value instanceof Date
            ) {
              return <> {params.value && formatDateTransfer(params.value)}</>;
            }
            return <></>;
          },
        },
        {
          field: "entryDate",
          headerName: "Tuổi tồn",
          //  filter: "agDateColumnFilter",
          cellStyle: () => {
            return { textAlign: "right" };
          },
          cellRenderer: (params: CustomCellRendererProps) => {
            if (
              typeof params.value === "string" ||
              params.value instanceof Date
            ) {
              return (
                <>
                  {params.value &&
                    (() => {
                      const valueDate = new Date(params.value);
                      if (valueDate.getFullYear() < 2000) {
                        return null;
                      }

                      const currentDate = new Date();
                      const differenceInTime =
                        currentDate.getTime() - valueDate.getTime();
                      const differenceInDays = Math.floor(
                        differenceInTime / (1000 * 3600 * 24)
                      );
                      return differenceInDays;
                    })()}
                </>
              );
            }
            return <></>;
          },
        },
        {
          field: "",
          headerName: "Lưu kho CN LK",
          filter: "agTextColumnFilter",
          cellRenderer: (params: CustomCellRendererProps) => {
            if (
              typeof params.value === "string" ||
              params.value instanceof Date
            ) {
              return <> {params.value && formatDateTransfer(params.value)}</>;
            }
            return <></>;
          },
        },
        {
          field: "lastTransferDate",
          headerName: "Lưu kho CN",
          filter: "agTextColumnFilter",
          cellRenderer: (params: CustomCellRendererProps) => {
            if (
              typeof params.value === "string" ||
              params.value instanceof Date
            ) {
              return (
                <>
                  {params.value &&
                    (() => {
                      const currentDate = new Date();
                      const valueDate = new Date(params.value);
                      const differenceInTime =
                        currentDate.getTime() - valueDate.getTime();
                      const differenceInDays = Math.floor(
                        differenceInTime / (1000 * 3600 * 24)
                      );
                      return differenceInDays;
                    })()}
                </>
              );
            }
            return <></>;
          },
        },
        {
          field: "",
          headerName: "Sản Xuất",
          filter: "agTextColumnFilter",
          cellRenderer: (params: CustomCellRendererProps) => {
            if (
              typeof params.value === "string" ||
              params.value instanceof Date
            ) {
              return <> {params.value && formatDateTransfer(params.value)}</>;
            }
            return <></>;
          },
        },
      ],
    },
    {
      headerName: "Ngành hàng",
      children: [
        {
          field: "industryCode",
          headerName: "Mã",
          filter: "agTextColumnFilter",
          width: 150,
        },
        {
          field: "industryName",
          headerName: "Tên",
          filter: "agTextColumnFilter",
          width: 250,
        },
      ],
    },
    {
      headerName: "Nhóm hàng",
      children: [
        {
          field: "groupCode",
          headerName: "Mã",
          filter: "agTextColumnFilter",
          width: 150,
        },
        {
          field: "groupName",
          headerName: "Tên",
          filter: "agTextColumnFilter",
          width: 350,
        },
      ],
    },
    {
      headerName: "Chủng loại",
      children: [
        {
          field: "commonCode",
          headerName: "Mã",
          filter: "agTextColumnFilter",
          width: 150,
        },
        {
          field: "commonName",
          headerName: "Tên",
          filter: "agTextColumnFilter",
          width: 250,
        },
      ],
    },

    {
      field: "",
      headerName: "Khối lượng (gr)",
      filter: "agTextColumnFilter",
    },
    {
      field: "",
      headerName: "Kích thước Dài x Rộng x Cao (cm)",
      filter: "agTextColumnFilter",
    },
    {
      field: "unitOfMeasureCode",
      headerName: "Đơn vị tính 1",
      filter: "agTextColumnFilter",
    },
    {
      field: "quantity",
      colId: "quantity",
      headerName: "Số lượng 1",
      cellStyle: () => {
        return { textAlign: "right" };
      },
      filter: "agTextColumnFilter",
      // suppressSpanHeaderHeight: true,
    },
    {
      field: "purchaseOrderQuantity",
      headerName: "Giá nhập",
      filter: "agTextColumnFilter",
      cellStyle: () => {
        return { textAlign: "right" };
      },
    },
    {
      field: "",
      headerName: "Thành tiền",
      // filter: "agTextColumnFilter",
      cellRenderer: (params: CustomCellRendererProps) => {
        return <>{params.data.quantity * params.data.purchaseOrderQuantity}</>;
      },
      cellStyle: () => {
        return { textAlign: "right" };
      },
    },
    {
      headerName: "Quy đổi",
      children: [
        {
          field: "subUnitOfMeasureCode",
          headerName: "Đơn vị tính 2",
          filter: "agTextColumnFilter",
        },
        {
          field: "subQuantity",
          headerName: "Số lượng 2",
          filter: "agTextColumnFilter",
          cellStyle: () => {
            return { textAlign: "right" };
          },
        },
      ],
    },
    {
      field: "serialStatus",
      headerName: "Tình trạng",
      filter: "agTextColumnFilter",
    },
    {
      field: "",
      headerName: "Vị trí",
      //filter: "agTextColumnFilter",
    },
    {
      field: "quantity",
      headerName: "Khả dụng",
      filter: "agTextColumnFilter",
      cellStyle: () => {
        return { textAlign: "right" };
      },
    },
    {
      field: "warranty",
      headerName: "Thời gian bảo hành (tháng)",
      filter: "agTextColumnFilter",
      cellStyle: () => {
        return { textAlign: "right" };
      },
    },
    {
      field: "",
      headerName: "Thời hạn bảo hành",
      cellStyle: () => {
        return { textAlign: "right" };
      },
    },
    {
      field: "supplierNum",
      headerName: "Mã NCC",
      filter: "agTextColumnFilter",
    },
    {
      field: "supplierName",
      headerName: "Tên NCC",
      filter: "agTextColumnFilter",
    },
    {
      field: "receiveNumber",
      headerName: "Mã phiếu nhập hàng",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Ghi chú",
      children: [
        {
          field: "serialNote",
          headerName: "SP",
          filter: "agTextColumnFilter",
        },
        {
          field: "receiveNote",
          headerName: "Phiếu Nhập",
          filter: "agTextColumnFilter",
        },
      ],
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      autoHeaderHeight: true,
      wrapHeaderText: true,
      allowedAggFuncs: ["sum", "min", "max", "avg"],
      filter: true,
      enableRowGroup: true,
      enableValue: true,
      floatingFilter: true,
      floatingFilterComponentParams: {
        showDisabledCheckboxes: true,
      },
      filterParams: filterParamsDefault,
      autoHeight: true,
    };
  }, []);
  const defaultColGroupDef = useMemo<Partial<ColGroupDef>>(() => {
    return {
      headerName: "",
    };
  }, []);

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    var datasource = getServerSideDatasource();
    // register the datasource with the grid
    params.api!.setGridOption("serverSideDatasource", datasource);
  }, []);
  const statusBar = useMemo(
    () => ({
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent" },
        { statusPanel: "agTotalRowCountComponent" },
        { statusPanel: "agFilteredRowCountComponent" },
        //  { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    }),
    []
  );
  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (DefaultMenuItem | MenuItemDef)[] => {
      const _number = params.node?.data.orderNumber;
      var result: (DefaultMenuItem | MenuItemDef)[] = [
        {
          // custom item
          name: "Thanh toán đơn: " + _number,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          cssClasses: ["red", "bold"],
        },
        {
          // custom item
          name: "Chỉnh sửa đơn: " + _number,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          //  icon: iconElement,
          cssClasses: ["red", "bold"],
        },
        {
          // custom item
          name: "Hủy đơn: " + _number,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          cssClasses: ["red", "bold"],
        },
        "cut",
        "copy",
        "copyWithHeaders",
        "copyWithGroupHeaders",
        "paste",
        "separator",
        "chartRange",

        "export",
      ];
      return result;
    },
    [window]
  );
  const pinnedTopRowData = useMemo<any[]>(() => {
    return [
      {
        id: "1",
        quantity: 0,
      },
    ];
  }, []);
  const pinnedBottomRowData = useMemo<any[]>(() => {
    return [
      {
        id: "1",
        quantity: 0,
      },
    ];
  }, []);
  return (
    <div style={containerStyle}>
      <_breadcrumb></_breadcrumb>
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact<ReportInventorySerialOnhandModel>
          ref={gridRef}
          loading={loading}
          suppressNoRowsOverlay
          columnDefs={columnDefs}
          defaultColGroupDef={defaultColGroupDef}
          defaultColDef={defaultColDef}
          rowModelType={"serverSide"}
          pagination={true}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          cacheBlockSize={paginationPageSize}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
          localeText={AG_GRID_LOCALE_VN}
          sideBar={true}
          enableAdvancedFilter={false}
          //  masterDetail={true}
          //  detailCellRendererParams={detailCellRendererParams}
          rowSelection={"multiple"}
          suppressAggFuncInHeader={true}
          groupDefaultExpanded={-1}
          getRowId={getRowId}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          blockLoadDebounceMillis={200}
          suppressServerSideFullWidthLoadingRow={false}
          maxConcurrentDatasourceRequests={1}
          animateRows={true}
          rowBuffer={paginationPageSize}
          purgeClosedRowNodes={true}
          pivotPanelShow={"always"}
          rowGroupPanelShow={"always"}
          statusBar={statusBar}
          enableRangeSelection
          enableCharts
          allowContextMenuWithControlKey={true}
          getContextMenuItems={getContextMenuItems}
          suppressColumnVirtualisation
          paginateChildRows={true}
          paginationAutoPageSize={true}
          maintainColumnOrder={true}
          //  pinnedTopRowData={pinnedTopRowData}
          // pinnedBottomRowData={pinnedBottomRowData}
          // pinnedBottomRowData={pinnedBottomRowData}
          // autoSizeStrategy={{
          //   type: "fitCellContents",
          // }}
        />
      </div>
    </div>
  );
};
