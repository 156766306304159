import {
  Button,
  ComboboxItem,
  Flex,
  Menu,
  Select,
  TextInput,
  Title,
  Tooltip,
  rem,
  Text,
  Grid,
  Badge,
  SelectProps,
  Group,
  Box,
  ActionIcon,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { modals } from "@mantine/modals";
import {
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconMapPinDown,
  IconMapPinUp,
  IconSearch,
  IconStatusChange,
  IconUserCode,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryDelivery,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { TblDeliveryAssignment } from "../../../model/TblDeliveryAssignment";
import {
  getBranchSelectMdm,
  getDeliveryStatusSelectMdm,
  getTblDmEmployeeSelect,
} from "../../../service/getSelectApi";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import DetailPerformHomeWarranty from "./DetailPerformHomeWarranty";
import DetailDataView from "./DetailDataView";
import { green } from "../../../const/variables";

const PerformHomeWarranty = () => {
  // Reference data from another table
  const [dataTblBranchSelect, setDataTblBranchSelect] = useState<
    ComboboxItem[]
  >([]);

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDeliveryAssignment[]>([]);
  const [dataTemp, setDataTemp] = useState<TblDeliveryAssignment[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [search, setSearch] = useState({
    code: "",
    startDate: "",
    endDate: "",
    employeeId: "",
    fromLocationId: "",
    toLocationId: "",
    statusId: "",
    partnerId: "",
    keySearch: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Số vận đơn",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
        size: 225,
      },
      {
        accessorKey: "distance",
        header: "Khoảng cách",
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {row.original.distance / 1000} Km
          </Text>
        ),
      },
      {
        accessorKey: "estimatedTime",
        header: "Thời gian đi dự kiến",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">{`${(
            row.original.estimatedTime / 60
          ).toFixed(0)} Phút`}</Text>
        ),
        size: 200,
      },
      {
        accessorKey: "distance",
        header: "KC thực hiện",
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {row.original.distance / 1000} Km
          </Text>
        ),
      },
      {
        accessorKey: "assignmentDate",
        header: "Ngày phân công",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "toExpectedDate",
        header: "Ngày đơn giao hàng",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "fromExpectedDate",
        header: "Dự kiến giao từ",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "description",
        header: "Ghi chú",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableResizing: false,
        size: 70,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailView(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataTblBranchSelect]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-thuc-hien-xu-ly-tai-nha.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const detailView = async (id: string | number) => {
    try {
      modals.openConfirmModal({
        title: (
          <>
            <Title order={5}>Chi tiết phân công!</Title>
          </>
        ),
        size: "auto",
        children: <DetailDataView id={id} onClose={setDeleteViewStatus} />,
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.employeeId) {
      url += `&EmployeeId=${search.employeeId}`;
    }

    if (search.fromLocationId) {
      url += `&FromLocationId=${search.fromLocationId}`;
    }

    if (search.toLocationId) {
      url += `&ToLocationId=${search.toLocationId}`;
    }

    if (search.statusId) {
      url += `&StatusId=${search.statusId}`;
    }

    if (search.partnerId) {
      url += `&PartnerId=${search.partnerId}`;
    }

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblDeliveryAssignmentForWarrantyService/get-list?${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.length);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (170 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length !== 0) fetchData();
  }, [pagination, deleteViewStatus]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={{ sm: "flex-end", lg: "flex-end" }}
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Flex gap={"md"} align={"center"} wrap={"wrap"} mt={10}>
          <Grid gutter={"xs"} w={"100%"}>
            <Grid.Col span={{ base: 12, md: 12, lg: 2.25 }}>
              <DatePickerInput
                type="range"
                size="sm"
                placeholder="Từ ngày - Đến ngày"
                locale="vi"
                valueFormat="DD/MM/YYYY"
                onChange={(e) => {
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[0]) ?? "",
                    "startDate"
                  );
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[1]) ?? "",
                    "endDate"
                  );
                }}
                leftSection={<IconCalendar color="#15aabf" />}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <TextInput
                placeholder="Nhập từ khóa"
                onChange={(e) => {
                  handleChangeSearchValue(e.currentTarget.value, "keySearch");
                }}
                leftSection={<IconSearch color="#15aabf" />}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await fetchData();
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
                w={"100%"}
              >
                Tìm kiếm
              </Button>
            </Grid.Col>
          </Grid>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    renderDetailPanel: ({ row }) => (
      <>
        <DetailPerformHomeWarranty
          dataDetail={row.original.warrantyServiceRequestFormModels}
        />
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      sorting: sorting,
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
  });

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default PerformHomeWarranty;
