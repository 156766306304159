import React from "react";
import {
  ScrollArea,
  Flex,
  Box,
  Text,
  Radio,
  NumberFormatter,
} from "@mantine/core";
import { IconDevicesPc } from "@tabler/icons-react";

interface VoucherListProps {
  dataVoucher: any[]; // Kiểu dữ liệu của danh sách voucher
  selectedPromotion: any | null;
  handleOptionChange: (voucher: any) => void;
  peach: { base: string };
  orange: { vivid: string };
}

const VoucherList: React.FC<VoucherListProps> = ({
  dataVoucher,
  selectedPromotion,
  handleOptionChange,
  peach,
  orange,
}) => {
  return (
    <ScrollArea h={530}>
      <Text size="md" fw={700} mb="xs">
        Chọn chính sách khuyến mãi:
      </Text>

      <Flex gap={10} direction={"column"} pb={15}>
        {dataVoucher?.map((voucher) => (
          <Flex
            align={"center"}
            onClick={() => handleOptionChange(voucher)}
            className="voucher-card"
            key={voucher.tblPromotion.id} // Đảm bảo key là duy nhất
          >
            <Flex
              justify={"center"}
              align={"center"}
              w={"30%"}
              bg={peach.base}
              direction={"column"}
              py={10}
            >
              <Box
                style={{
                  borderRadius: "50%",
                  backgroundColor: orange.vivid,
                  padding: 5,
                  color: "white",
                }}
              >
                <IconDevicesPc size={50} />
              </Box>
              <Text c={"white"}> Điện tử</Text>
            </Flex>

            <Box w={"60%"} p={10}>
              <Text fw={700} style={{ fontSize: 15 }}>
                {voucher.tblPromotion.code}
              </Text>
              <Text c={"#AAAAAA"}>{voucher.tblPromotion.note}</Text>
              <Text>
                Giảm{" "}
                {voucher.tblPromotionItemPrimary[0]?.discountPercent ? (
                  `${voucher.tblPromotionItemPrimary[0]?.discountPercent}%`
                ) : (
                  <NumberFormatter
                    value={
                      voucher.tblPromotionItemPrimary[0]?.discountAmount || 0
                    }
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="₫"
                  />
                )}
              </Text>
              {/* Thêm giá trị max và min */}
              <Text c={"#555555"} size="sm">
                Áp dụng với số lượng từ{" "}
                <NumberFormatter
                  value={voucher.tblPromotionItemPrimary[0]?.min || 0}
                />{" "}
                đến{" "}
                {voucher.tblPromotionItemPrimary[0]?.max === 0 ? (
                  "Không giới hạn"
                ) : (
                  <NumberFormatter
                    value={voucher.tblPromotionItemPrimary[0]?.max || 0}
                  />
                )}
              </Text>
            </Box>

            <Box w={"10%"}>
              <Radio checked={selectedPromotion === voucher} />
            </Box>
          </Flex>
        ))}

        {dataVoucher.length < 1 && <Text>Không có chính sách khuyến mãi</Text>}
      </Flex>
    </ScrollArea>
  );
};

export default VoucherList;
