import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  ScrollArea,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { sky_blue } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDeliveryAssignment } from "../../../model/TblDeliveryAssignment";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { SocketExtension } from "../../../_base/socket/socket";
import { getValueById } from "../../../_base/helper/FunctionHelper";

const DeliveryAssignment = ({
  selectIds,
  dataDeliverySelect,
  onClose,
  department,
}: {
  selectIds: string[] | number[];
  dataDeliverySelect: any[];
  onClose: any;
  department: string;
}) => {
  const entity = {
    id: "0",
    code: null,
    assignmentDate: new Date().toDateString(),
    fromExpectedDate: null,
    toExpectedDate: null,
    deliveryWorkId: null,
    employee1: null,
    employee2: null,
    mainResponsibility1: false,
    mainResponsibility2: false,
    payroll1: false,
    payroll2: false,
    vehicleCode: "XE_MAY",
    driverId: null,
    driverName: null,
    driverMobileNumber: null,
    fromLocationId: null,
    toLocationId: null,
    distance: null,
    distanceLevel: null,
    distanceTotal: null,
    partnerId: null,
    partnerEmployeeName: null,
    description: null,
    deliveryIds: selectIds,
    estimatedTime: null,
    tblDeliveryModels: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataDriverSelect, setDataDriverSelect] = useState<ComboboxItem[]>([]);
  const [dataWorkSelect, setDataWorkSelect] = useState<ComboboxItem[]>([]);
  const [dataCalculateDistance, setDataCalculateDistance] = useState<any[]>([]);
  const dataFromAddress = dataDeliverySelect?.map((item) => item.fromAddress);
  const dataToAddress = dataDeliverySelect?.map((item) => item.toAddress);
  const processedData = dataToAddress.map((item) => {
    const parts = item?.split("-");
    if (parts.length >= 4) {
      parts.shift();
      return parts
        .join("-")
        .trim()
        ?.replace(/^(undefined|null) - /, "");
    }
    return item;
  });

  const [scrolled, setScrolled] = useState(false);

  const form = useForm<TblDeliveryAssignment>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      employee1: Number(values.employee1),
      employee2: Number(values.employee2),
    }),

    validate: {
      driverId: (value: number | null | undefined) => {
        if (!value) {
          return "Vui lòng chọn người lái xe !";
        }
      },
      driverMobileNumber: (value: string | null | undefined) => {
        if (value) {
          return hasLength(
            { max: 10, min: 10 },
            "Số điện thoại phải đủ 10 ký tự !"
          )(value as string);
        }
      },
      employee1: (value: number | null | undefined) => {
        if (!value) {
          return "Vui lòng nhập tên nhân viên giao hàng !";
        }
      },
    },
  });

  const customHeightTable = () => {
    if (dataDeliverySelect.length === 1) {
      return 110;
    } else if (dataDeliverySelect.length === 2) {
      return 150;
    } else if (dataDeliverySelect.length === 3) {
      return 190;
    } else {
      return 230;
    }
  };

  const handleCreateTblDeliveryAssignment = async (
    dataSubmit: TblDeliveryAssignment
  ) => {
    open();
    const dataApi = await repositoryDelivery.post<
      MessageResponse<TblDeliveryAssignment>
    >("/api/v1/TblDeliveryAssignment/create", dataSubmit);
    if (dataApi?.success) {
      NotificationExtension.Success("Phân đơn giao hàng thành công !");
      setTimeout(() => {
        onClose((prev: any) => !prev);
        modals.closeAll();
      }, 300);
    }
    close();
  };

  const getCreateDeliveryAssignment = async () => {
    const dataApi = await repositoryDelivery.get<
      MessageResponse<TblDeliveryAssignment>
    >("/api/v1/TblDeliveryAssignment/create?prefix=VD-");
    if (dataApi !== null) {
      const data = dataApi?.data;
      if (data) {
        form.setValues((prev) => ({ ...prev, code: data.code }));
      }
    }
  };

  const dataEmployeeSelect = async () => {
    if (department === "DV") {
      const res = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/get-list?BranchId=488&Take=200`
      );

      if (res && res?.success) {
        setDataEmployeedSelect(
          res.data
            .filter(
              (item: any) =>
                item.fullname != null &&
                item.id != null &&
                (item?.attribute1?.toString() === "true" ||
                  item?.roleName?.toLowerCase()?.includes("giao vận"))
            )
            .map((item: any) => ({
              value: item.id?.toString(),
              label: `${item.fullname} - ${item.code}`,
              phoneNumber: item.phoneNumber,
            }))
        );
      }
    } else {
      const res = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/get-list?BranchId=${dataDeliverySelect[0]?.branchId}&Take=200`
      );

      if (res && res?.success) {
        setDataEmployeedSelect(
          res.data
            ?.filter(
              (item: any) =>
                item.id != null &&
                item.fullname != null &&
                (item.roleName.toLowerCase().includes("kỹ thuật") ||
                  item?.attribute2?.toString() === "true")
            )
            ?.map((item: any) => ({
              value: item.id?.toString(),
              label: `${item.fullname} - ${item.code}`,
              phoneNumber: item.phoneNumber,
            }))
        );
      }
    }
  };

  const dataDeliveryWorkSelect = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblDmDeliveryWork/get-all"
    );

    if (res && res?.success) {
      const result = res?.data;
      if (department?.toString() === "KT") {
        setDataWorkSelect(
          result
            .filter((item: any) => item.name != null && item.note === "KTKT")
            .map((item: any) => ({
              value: item.id?.toString(),
              label: item.name,
            }))
        );
      } else {
        setDataWorkSelect(
          result
            .filter((item: any) => item.name != null && item.note === "GV")
            .map((item: any) => ({
              value: item.id?.toString(),
              label: item.name,
            }))
        );
      }
    }
  };

  const handleCalculateDistance = async () => {
    const res = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Location/matric",
      {
        origins: dataFromAddress,
        destinations: processedData,
      }
    );

    if (res && res?.success) {
      const dataApi = res.data.rows;
      const filteredElements = dataFromAddress.map((fromAddress, index) => {
        const toAddress = processedData[index];

        return dataApi
          .flatMap((row: any) => row.elements)
          .find(
            (element: any) =>
              element.origin === fromAddress &&
              element.destination === toAddress
          );
      });

      setDataCalculateDistance(filteredElements);
    }
  };

  useEffect(() => {
    Promise.all([
      dataEmployeeSelect(),
      dataDeliveryWorkSelect(),
      getCreateDeliveryAssignment(),
    ]);
  }, []);

  useEffect(() => {
    if (dataDeliverySelect.length === 1) {
      form.setValues((prev) => ({
        ...prev,
        fromExpectedDate: dataDeliverySelect[0]?.fromDeliveryDate,
        toExpectedDate: dataDeliverySelect[0]?.deliveryAppointmentTime,
      }));
    }
  }, [dataDeliverySelect]);

  useEffect(() => {
    form.setValues((prev) => ({
      ...prev,
      distance: dataCalculateDistance.reduce(
        (sum: any, item: any) => sum + item?.distance?.value,
        0
      ),
      distanceTotal: dataCalculateDistance.reduce(
        (sum: any, item: any) => sum + item?.distance?.value,
        0
      ),
    }));
  }, [dataCalculateDistance]);

  useEffect(() => {
    if (dataFromAddress && processedData) {
      handleCalculateDistance();
    } else {
      setDataCalculateDistance([]);
    }
  }, []);

  return (
    <Box
      component="form"
      m={"5px 0px"}
      w={"70vw"}
      maw={1000}
      onSubmit={form.onSubmit((e: TblDeliveryAssignment) => {
        handleCreateTblDeliveryAssignment(e);
        SocketExtension.SendMessageToEmp<any>(
          Number(form.getValues().employee1),
          {
            message: "Có đơn hàng",
            quantity: dataDeliverySelect.length,
          }
        );
      })}
      style={{ position: "relative" }}
    >
      <Grid>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <Fieldset legend="Thông tin giao nhận" p={"0px 15px 7.5px 15px"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <TextInput
                  label="Mã phân công giao vận"
                  placeholder="Nhập mã phân công giao vận"
                  value={form.getValues().code ?? ""}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <DateTimePicker
                  label="Ngày phân công"
                  placeholder="Nhập ngày phân công"
                  value={new Date()}
                  valueFormat="DD/MM/YYYY HH:mm A"
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <Select
                  label="Phương tiện giao hàng"
                  placeholder="Chọn phương tiện giao hàng"
                  clearable
                  data={[
                    { label: "Xe máy", value: "XE_MAY" },
                    { label: "Ô tô", value: "O_TO" },
                  ]}
                  value={
                    form.getValues().vehicleCode
                      ? form.getValues().vehicleCode?.toString()
                      : null
                  }
                  {...form.getInputProps("vehicleCode")}
                  onChange={(e) =>
                    form.setValues((prev) => ({
                      ...prev,
                      vehicleCode: e ? e : "XE_MAY",
                    }))
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <Select
                  label="Công việc"
                  placeholder="Chọn loại công việc"
                  clearable
                  data={dataWorkSelect}
                  onChange={(value) =>
                    form.setFieldValue(
                      "deliveryWorkId",
                      value !== null ? Number(value) : null
                    )
                  }
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="Ghi chú"
                  placeholder="Ghi chú"
                  {...form.getInputProps("description")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <Fieldset legend="Nhân viên phân công" p={"0px 15px 7.5px 15px"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
                <Select
                  label="Lái xe"
                  placeholder="Nhập tên nhân viên lái xe"
                  value={
                    form.getValues().driverId
                      ? form.getValues().driverId?.toString()
                      : null
                  }
                  data={dataDriverSelect?.map((item: any) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  searchable
                  nothingFoundMessage={
                    dataDriverSelect.length > 0
                      ? "Không tìm thấy dữ liệu !"
                      : "Vui lòng chọn nhân viên giao hàng hoặc hỗ trợ !"
                  }
                  {...form.getInputProps("driverId")}
                  onChange={(e) =>
                    form.setValues((prev) => ({
                      ...prev,
                      driverId: e ? Number(e) : null,
                      driverMobileNumber: getValueById(
                        e?.toString() ?? "",
                        dataEmployeedSelect,
                        "phoneNumber"
                      ),
                    }))
                  }
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
                <TextInput
                  label="Số điện thoại người lái"
                  placeholder="Nhập số điện thoại người lái"
                  {...form.getInputProps("driverMobileNumber")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
                <Select
                  label="Nhân viên giao hàng"
                  placeholder="Nhập tên nhân viên giao hàng"
                  data={dataEmployeedSelect
                    ?.filter(
                      (item: any) =>
                        item.value !== form.getValues().employee2?.toString()
                    )
                    ?.map((item: any) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                  value={
                    form.getValues().employee1
                      ? form.getValues().employee1?.toString()
                      : null
                  }
                  {...form.getInputProps("employee1")}
                  onChange={(e) => {
                    const selectedEmployee = dataEmployeedSelect.find(
                      (item: any) => item.value === e
                    );

                    form.setValues((prev) => ({
                      ...prev,
                      employee1: e ? Number(e) : null,
                      payroll1: e ? true : false,
                      driverId: e ? Number(e) : null,
                      driverMobileNumber: getValueById(
                        e?.toString() ?? "",
                        dataEmployeedSelect,
                        "phoneNumber"
                      ),
                    }));

                    if (selectedEmployee) {
                      setDataDriverSelect((prev) => {
                        const updatedPrev = [...prev];
                        updatedPrev[0] = selectedEmployee;
                        return updatedPrev;
                      });
                    }
                  }}
                  searchable
                  clearable
                  nothingFoundMessage="Không tìm thấy dữ liệu !"
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
                <Checkbox
                  mt={30}
                  label="Tính lương"
                  checked={form.getValues().payroll1}
                  readOnly
                  {...form.getInputProps("payroll1")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
                <Select
                  label="Nhân viên hỗ trợ"
                  placeholder="Nhập tên nhân viên hỗ trợ"
                  data={dataEmployeedSelect
                    ?.filter(
                      (item: any) =>
                        item.value !== form.getValues().employee1?.toString()
                    )
                    ?.map((item: any) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                  value={
                    form.getValues().employee2
                      ? form.getValues().employee2?.toString()
                      : null
                  }
                  searchable
                  clearable
                  nothingFoundMessage="Không tìm thấy dữ liệu !"
                  disabled={!form.getValues().employee1}
                  {...form.getInputProps("employee2")}
                  onChange={(e) => {
                    const selectedEmployee = dataEmployeedSelect.find(
                      (item: any) => item.value === e
                    );

                    form.setValues((prev) => ({
                      ...prev,
                      employee2: e ? Number(e) : null,
                      payroll2: e ? true : false,
                    }));

                    if (selectedEmployee) {
                      setDataDriverSelect((prev) => {
                        const updatedPrev = [...prev];
                        updatedPrev[1] = selectedEmployee;
                        return updatedPrev;
                      });
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
                <Checkbox
                  mt={30}
                  label="Tính lương"
                  checked={form.getValues().payroll2}
                  readOnly
                  {...form.getInputProps("payroll2")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
      </Grid>
      <ScrollArea
        h={customHeightTable()}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
          mt={10}
        >
          <Table.Thead
            className={cx(classes.header, {
              [classes.scrolled]: scrolled,
            })}
          >
            <Table.Tr>
              <Table.Th>
                {dataDeliverySelect.length > 1
                  ? "Danh sách điểm đi"
                  : "Điểm đi"}
              </Table.Th>
              <Table.Th>
                {dataDeliverySelect.length > 1
                  ? "Danh sách điểm đến"
                  : "Điểm đến"}
              </Table.Th>
              <Table.Th>Quãng đường</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataDeliverySelect &&
              dataDeliverySelect.length > 0 &&
              dataDeliverySelect?.map((item: any, index: any) => (
                <Table.Tr>
                  <Table.Td>{item?.fromAddress}</Table.Td>
                  <Table.Td>
                    {item?.toAddress?.replace(/^(undefined|null) - /, "")}
                  </Table.Td>
                  <Table.Td>
                    {dataCalculateDistance[index]?.distance?.value
                      ? dataCalculateDistance[index].distance.value / 1000 +
                        " Km"
                      : "0 Km"}
                  </Table.Td>
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
      <Flex justify={"end"} gap={"md"}>
        <TextInput
          label="Tổng khoảng cách"
          placeholder="Bấm nút tính khoảng cách để hiển thị"
          value={`${
            dataCalculateDistance
              ?.filter((item: any) => item !== undefined)
              ?.reduce(
                (sum: any, item: any) => sum + item?.distance?.value,
                0
              ) / 1000
          } Km`}
          variant="filled"
          readOnly
          size="xs"
        />
        <TextInput
          label="Thời gian giao ước tính"
          placeholder="Bấm nút tính khoảng cách để hiển thị"
          value={`${Math.floor(
            dataCalculateDistance
              ?.filter((item: any) => item !== undefined)
              ?.reduce(
                (sum: any, item: any) => sum + item?.duration?.value,
                0
              ) / 3600
          )} giờ - ${Math.floor(
            (dataCalculateDistance.reduce(
              (sum: any, item: any) => sum + item?.duration?.value,
              0
            ) %
              3600) /
              60
          )} phút`}
          variant="filled"
          readOnly
          size="xs"
        />
      </Flex>
      <Group
        justify="end"
        mt="md"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

export default DeliveryAssignment;
