import {
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Input,
  NumberFormatter,
  Select,
  Text,
  Title,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconBrandComedyCentral,
  IconMapPin,
  IconNumbers,
  IconPlus,
  IconSearch,
  IconUser,
  IconUsers,
  IconUsersGroup,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { cobalt_blue } from "../../../../const/variables";
import { TblDMCustomerNew } from "../../../../model/TblDmCustomer";
import {
  getProvinceSelect,
  getTblDmCustomerGroupSelect,
} from "../../../../service/getSelectApi";
import CreateDataView from "../../../CustomerManagement/CustomerList/CreateDataView";

const CustomerInformation = ({
  handleChooseCustomer,
  navigate,
  isChooseCongNo,
  disible,
}: {
  handleChooseCustomer: Function;
  navigate?: any;
  isChooseCongNo: boolean;
  disible?: boolean;
}) => {
  const [data, setData] = useState<TblDMCustomerNew[]>([]);
  const [dataTemp, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [customerInfo, setCustomerInfo] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [keySearch, setKeySearch] = useState({
    keySearch: "",
    type: "",
    provinceId: "",
    groupId: "",
    taxCode: "",
    cusName: "",
    cusCode: "",
  });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );

  const [dataTblCustomerGroup, setDataTblCustomerGroup] = useState<any[]>([]);

  const columns = React.useMemo<MRT_ColumnDef<TblDMCustomerNew>[]>(
    () =>
      [
        {
          accessorKey: "index",
          header: "STT",
          Cell: ({ row }) => (
            <Text size="12.5px">{row.index === -1 ? "" : row.index + 1}</Text>
          ),
          size: 50,
        },
        {
          accessorKey: "buyerName",
          header: "Người mua hàng",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "name",
          header: "Tên khách hàng",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "code",
          header: "MSKH",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "attribute1",
          header: "Mã đối tượng công nợ",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 150,
          Cell: ({ row }) => (
            <Text size="12.5px">{row?.original?.attribute1}</Text>
          ),
        },
        {
          accessorKey: "debtTerm",
          header: "Thời hạn công nợ (ngày)",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 150,
          Cell: ({ row }) => (
            <NumberFormatter
              value={row.original.debtTerm ?? 0}
              thousandSeparator="."
              decimalSeparator=","
            />
          ),
        },
        {
          accessorKey: "debtLimit",
          header: "Hạn mức công nợ",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 150,
          Cell: ({ row }) => (
            <NumberFormatter
              value={row.original.debtLimit ?? 0}
              thousandSeparator="."
              decimalSeparator=","
            />
          ),
        },
        // {
        //   accessorKey: "thoiGianDangKy",
        //   header: "Thời gian đăng ký",
        //   enableColumnFilter: false,
        //   enableColumnActions: false,
        //   Cell: ({ renderedCellValue }: any) => (
        //     <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        //   ),
        //   size: 50,
        // },
        // {
        //   accessorKey: "ketNoiLauDai",
        //   header: "Kết nối lâu dài",
        //   enableColumnFilter: false,
        //   enableColumnActions: false,
        //   size: 50,
        // },
        {
          accessorKey: "type",
          header: "Loại khách hàng",
          enableColumnFilter: false,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Text size="12.5px">
              {renderTypeName(Number(row.original.type))}
            </Text>
          ),
          size: 50,
        },
        {
          accessorKey: "tenDN",
          header: "Tên Doanh nghiệp",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "taxCode",
          header: "Mã số thuế",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "sex",
          header: "Giới tính",
          enableColumnFilter: false,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Text>
              {row.original.sex !== null
                ? row.original.sex === 1
                  ? `${"Nam"}`
                  : `${"Nữ"}`
                : null}
            </Text>
          ),
          size: 50,
        },
        {
          accessorKey: "birthOfDate",
          header: "Ngày sinh",
          enableColumnFilter: false,
          enableColumnActions: false,
          Cell: ({ renderedCellValue }: any) => (
            <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
          ),
          size: 50,
        },
        {
          accessorKey: "telephoneNumber",
          header: "Phone",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "email",
          header: "Email",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 150,
        },
        {
          accessorKey: "provinceName",
          header: "Tỉnh/Thành phố",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "districtName",
          header: "Quận/Huyện",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "communeName",
          header: "Phường/Xã",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "address",
          header: "Địa chỉ",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 115,
        },
        {
          accessorKey: "cuaHangKhachMuaLanGanNhat",
          header: "Cửa hàng mua lần gần nhất",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 240,
        },
        {
          accessorKey: "slHoaDonDaMua",
          header: "SL đơn đã mua",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 190,
        },
        {
          accessorKey: "slSanPhamDaMua",
          header: "SL SP đã mua",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 200,
        },
        {
          accessorKey: "giaTriHangHoaDaMua",
          header: "Tổng tiền đã mua",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 180,
        },
        {
          accessorKey: "bomHang",
          header: "BOM hàng",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "xepHang",
          header: "Xếp hạng",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        {
          accessorKey: "trangThaiTaiKhoan",
          header: "Trạng thái tk",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 160,
        },
        {
          accessorKey: "description",
          header: "Ghi chú",
          enableColumnFilter: false,
          enableColumnActions: false,
          size: 50,
        },
        // {
        //   accessorKey: "action",
        //   header: "Thao tác",
        //   enableSorting: false,
        //   enableColumnActions: false,
        //   enableColumnFilter: false,
        //   Cell: ({ row }) => (
        //     <Tooltip label="Chọn">
        //       <ActionIcon
        //         variant="light"
        //         aria-label="Settings"
        //         color="teal"
        //         onClick={() => {
        //           handleChooseCustomer(row.original);
        //           modals.closeAll();
        //         }}
        //       >
        //         <IconCheckbox size={20} stroke={1.5} />
        //       </ActionIcon>
        //     </Tooltip>
        //   ),
        //   size: 50,
        // },
      ] as MRT_ColumnDef<(typeof data)[0]>[],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const renderTypeName = (value: number) => {
    switch (value) {
      case 1:
        return "Cá nhân";
      case 2:
        return "Doanh nghiệp";
      case 3:
        return "Bán buôn";
      case null:
        return "";
    }
  };

  const dataCustomerGroupSelect = async () => {
    try {
      const getData = await getTblDmCustomerGroupSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
        type: item?.att1,
      }));
      setDataTblCustomerGroup(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const fetchData = async () => {
    // setIsLoading(true);
    // setIsRefetching(true);
    // setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.keySearch) {
      url += `&KeySearch=${keySearch.keySearch.trim()}`;
    }

    if (keySearch.type) {
      url += `&Type=${keySearch.type}`;
    }

    if (keySearch.provinceId) {
      url += `&ProvinceId=${keySearch.provinceId}`;
    }

    if (keySearch.groupId) {
      url += `&GroupId=${keySearch.groupId}`;
    }

    if (keySearch.taxCode) {
      url += `&TaxCode=${keySearch.taxCode}`;
    }

    if (keySearch.cusName) {
      url += `&CusName=${keySearch.cusName}`;
    }

    if (keySearch.cusCode) {
      url += `&CusCode=${keySearch.cusCode}`;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmCustomer/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    // startCountdown();
  };

  const getDataProvinceId = async () => {
    const res = await getProvinceSelect();
    setDataProvinceSelect(
      res
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  function createCustomer() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Tạo mới khách hàng!</Title>
        </>
      ),
      size: "auto",
      children: (
        <CreateDataView
          isCreateBySeller
          onClose={setDeleteViewStatus}
          handleChooseCustomer={handleChooseCustomer}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["index", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: { pageSize: 20, pageIndex: 0 },
    },
    mantineTableContainerProps: {
      style: { maxHeight: "62.5vh", minHeight: "300px" },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: (e) => {
      console.log("sdsdsds", e);
      setPagination(e);
    },
    // manualFiltering: true,
    manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // mantineTableBodyRowProps: ({ row }) => ({
    //   onClick: row.getToggleSelectedHandler(),
    //   sx: { cursor: "pointer" },
    // }),
    mantineTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        handleChooseCustomer(row.original, isChooseCongNo);
        modals.closeAll();
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
  });

  return (
    <>
      <div
        style={{
          width: "100%",
          border: "1px solid #dee2e6",
          padding: "10px",
        }}
      >
        <Grid>
          <Grid.Col span={{ base: 12, sm: 10.5, md: 10.5, lg: 10.5, xl: 10.5 }}>
            <Grid align="center" gutter={"xs"}>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <Select
                  size="sm"
                  placeholder="Loại khách hàng"
                  searchable
                  clearable
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={[
                    {
                      value: "1",
                      label: "Cá nhân",
                    },
                    {
                      value: "2",
                      label: "Doanh nghiệp",
                    },
                    {
                      value: "3",
                      label: "Doanh nghiệp có hạn mức công nợ",
                    },
                  ]}
                  leftSection={
                    <IconUsers size={"20"} color={cobalt_blue.base}></IconUsers>
                  }
                  w={"100%"}
                  onChange={(e) => {
                    handleChangeSearchValue(e?.toString() ?? "", "type");
                  }}
                />
              </Grid.Col>

              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <Select
                  flex={1}
                  placeholder="Nhóm khách hàng"
                  searchable
                  clearable
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  w={"100%"}
                  data={
                    keySearch?.type
                      ? dataTblCustomerGroup?.filter(
                          (item) => item?.type === keySearch?.type
                        )
                      : dataTblCustomerGroup
                  }
                  leftSection={
                    <IconUsersGroup
                      size={"20"}
                      color={cobalt_blue.base}
                    ></IconUsersGroup>
                  }
                  onClick={() => {
                    if (dataTblCustomerGroup.length === 0) {
                      dataCustomerGroupSelect();
                    }
                  }}
                  onChange={(e) => {
                    handleChangeSearchValue(e?.toString() ?? "", "groupId");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <Select
                  flex={1}
                  placeholder="Chọn Tỉnh/Thành"
                  searchable
                  clearable
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  w={"100%"}
                  data={dataProvinceSelect}
                  leftSection={
                    <IconMapPin
                      size={"20"}
                      color={cobalt_blue.base}
                    ></IconMapPin>
                  }
                  onClick={() => {
                    if (dataProvinceSelect.length === 0) {
                      getDataProvinceId();
                    }
                  }}
                  onChange={(e) => {
                    handleChangeSearchValue(e?.toString() ?? "", "provinceId");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <Input
                  flex={1}
                  placeholder="Nhập mã số thuế"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconNumbers
                      size={"20"}
                      color={cobalt_blue.base}
                    ></IconNumbers>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "taxCode");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <Input
                  flex={1}
                  placeholder="Nhập tên khách hàng"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconUser size={"20"} color={cobalt_blue.base}></IconUser>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "cusName");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <Input
                  flex={1}
                  placeholder="Nhập mã khách hàng"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconBrandComedyCentral
                      size={"20"}
                      color={cobalt_blue.base}
                    ></IconBrandComedyCentral>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "cusCode");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <Input
                  flex={1}
                  placeholder="Nhập SĐT , từ khóa"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconSearch
                      size={"20"}
                      color={cobalt_blue.base}
                    ></IconSearch>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col>

              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          {disible ? null : (
            <Grid.Col span={{ base: 12, sm: 1.5, md: 1.5, lg: 1.5, xl: 1.5 }}>
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  variant="outline"
                  leftSection={<IconPlus size={14} />}
                  onClick={async () => {
                    // if (navigate) {
                    //   navigate("/customer-management/customer-list");
                    //   modals.closeAll();
                    // }
                    createCustomer();
                  }}
                >
                  Thêm mới khách hàng
                </Button>
              </Flex>
            </Grid.Col>
          )}
        </Grid>
      </div>
      <div style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default CustomerInformation;
