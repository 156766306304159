import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
  Box,
  Flex,
  Loader,
} from "@mantine/core";
import classes from "./AuthenticationTitle.module.css";
import {
  randomId,
  readLocalStorageValue,
  useDisclosure,
  useLocalStorage,
  useOs,
} from "@mantine/hooks";
import { hasLength, isEmail, useForm } from "@mantine/form";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoginModel } from "../../../model/LoginModel";

import { isNullOrEmpty } from "../../extension/StringExtension";
import { AuthProvider } from "../../helper/IAuthProvider";
import {
  IconArrowNarrowRight,
  IconHttpPost,
  IconSend,
} from "@tabler/icons-react";
import style from "./AuthenticationTitle.module.css";
import { NotificationExtension } from "../../extension/NotificationExtension";
import { SocketExtension } from "../../socket/socket";
import { usePostHog } from "posthog-js/react";
import { DelayTask } from "../../helper/FunctionHelper";
import { repositoryMaster } from "../../_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
type Props = {
  setIsLogin: (value: boolean) => void;
  dataUserSign: { name: string; pass: string };
};
export function AuthenticationTitle({ setIsLogin, dataUserSign }: Props) {
  //#region  state
  const entity: LoginModel = {
    username: "",
    password: "",
    remember: false,
    os: "",
    location: "",
    code: "",
  };
  const form = useForm<LoginModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      username: (value) =>
        value.length < 5 || value.length > 25
          ? "Tên đăng nhập phải từ 5-25 kí tự!"
          : null,
      password: hasLength(
        { min: 6, max: 19 },
        "Mật khẩu phải lớn hớn 5 và nhỏ hơn 20 kí tự !"
      ),
    },
  });
  const navigate = useNavigate();
  const [showOTP, setShowOTP] = useState(false);
  const [visible, { toggle, open, close }] = useDisclosure(false);
  const [visible1, setvisible1] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const callbackParam = queryParams.get("callback");
  const [value, setValue] = useLocalStorage({
    key: "user-login",
    defaultValue: false,
  });
  const posthog = usePostHog();
  const [locationVC, setLocation] = useState("");

  //#endregion
  function success(position: { coords: { latitude: any; longitude: any } }) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation("latitude: " + latitude + "-" + " longitude: " + longitude);
  }

  function error1() {
    console.log("Unable to retrieve your location");
  }
  //#region use
  //#region  auth
  const isAuthenticated = AuthProvider.isAuthenticated();
  const os = useOs();
  const handleLogin = async (dataSubmit: LoginModel) => {
    open();
    setvisible1(true);
    dataSubmit.os = os;
    dataSubmit.location = locationVC;

    const resLogin = await AuthProvider.signin(dataSubmit);
    if (
      resLogin !== undefined &&
      !isNullOrEmpty(resLogin.data?.jwt) &&
      resLogin.success
    ) {
      NotificationExtension.Success(
        resLogin?.message ?? "Đăng nhập thành công !"
      );
      posthog?.identify(resLogin.data.userName, {
        userName: resLogin.data.userName,
        empId: resLogin.data.empId,
        clientId: resLogin.data.clientId,
      });
      posthog?.group("hacom-login", "Hacom");

      // window.location.href = callbackParam ?? "/";
      //  await DelayTask(500);
      navigate("/");
      setValue(true);
      // này là push trực tiếp với điều kiện là app không tắt
      const registrationReady = await navigator.serviceWorker.ready;
      setShowOTP(false);
      if (registrationReady && registrationReady.active)
        if (AuthProvider.isAuthenticated()) {
          const getUser = resLogin.data.userName;
          const empId = resLogin.data.empId;
          if (getUser && empId) {
            const postData = {
              userName: getUser,
              empId: empId,
              type: "listen",
              typeHandler: "login",
            };
            registrationReady.active?.postMessage(postData);
          }
        }
      //  navigate(callbackParam ?? "/");
    } else if (resLogin?.httpStatusCode === 200) {
      if (resLogin?.message.includes("OTP")) setShowOTP(true);
      NotificationExtension.Fails(resLogin?.message ?? "Đăng nhập thất bại !");
    }
    setvisible1(false);
    close();
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      console.log("isAuthenticated");
      navigate("/");
    }
  });
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error1);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);
  useEffect(() => {
    if (dataUserSign && dataUserSign.name !== "" && dataUserSign.pass !== "") {
      form.values.username = dataUserSign.name;
      form.values.password = dataUserSign.pass;
    }
  }, [dataUserSign]);

  const [loadingSend, setLoadingSend] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const countdownRef = useRef(0);

  const handleSendOTP = async () => {
    const userName = form.getValues().username; // Replace with your form instance
    if (isNullOrEmpty(userName)) {
      NotificationExtension.Fails("Vui lòng nhập tên người dùng!");
      return;
    }
    const res = await repositoryMaster.get<MessageResponse<boolean>>(
      `/api/v1/Auth/code-v2?userName=${userName}`
    );
    if (res && res.httpStatusCode === 200) {
      if (res.success) {
        NotificationExtension.Success(res?.message ?? "Thành công !");
        setLoadingSend(true);
        setTimeout(() => {
          setLoadingSend(false);
          countdownRef.current = 60; // Bắt đầu đếm ngược từ 60 giây
          setCountdown(60); // Cập nhật giá trị hiển thị
        }, 1000); // Replace with actual API call duration
      } else {
        NotificationExtension.Fails(res?.message ?? "Thất bại !");
      }
    }

    try {
    } catch (error) {
      console.error("Error sending OTP:", error);
      NotificationExtension.Fails("Thất bại !");
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        countdownRef.current -= 1;
        setCountdown(countdownRef.current); // Chỉ cập nhật khi cần hiển thị
        if (countdownRef.current <= 0) {
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer); // Clear timer khi component unmount
    }
  }, [countdown]);
  const [environment, setEnvironment] = useState<string | undefined>("");

  useEffect(() => {
    console.log(window.location.hostname);
    const envvv = window.location.hostname.includes("pos.hacom.vn")
      ? "production"
      : "development";
    setEnvironment(envvv);
  }, []);

  return (
    <>
      <Box
        component="form"
        m={0}
        p={0}
        className={style.form}
        onSubmit={form.onSubmit(async (e: LoginModel) => {
          posthog?.capture("clicked_log_in");
          await handleLogin(e);
        })}
      >
        {environment === "production" && (
          <Flex mb={40} direction="column">
            <Title c="#342e79" fw={500} order={3}>
              Chào mừng trở lại!
            </Title>
            <Text c="#8e8e8e" fw="400" size="lg">
              Xin hãy đăng nhập vào tài khoản của bạn
            </Text>
          </Flex>
        )}
        <LoadingOverlay
          visible={visible1}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Paper pb={50} bg={"transparent"}>
          <TextInput
            label={
              <Text c="#342e79" mb={5} size="sm" fw="bold">
                Tên người dùng
              </Text>
            }
            placeholder="Tên người dùng"
            mt="md"
            {...form.getInputProps("username")}
          />
          <PasswordInput
            label={
              <Text c="#342e79" mb={5} size="sm" fw="bold">
                Mật khẩu
              </Text>
            }
            placeholder="Mật khẩu của bạn"
            mt="md"
            {...form.getInputProps("password")}
          />
          {showOTP && (
            <TextInput
              label={
                <Text c="#342e79" mb={5} size="sm" fw="bold">
                  OTP (bấm icon lá thư bên phải để nhận mã)
                </Text>
              }
              placeholder="Nhập OTP..."
              mt="md"
              rightSection={
                loadingSend ? (
                  <Loader color="blue" />
                ) : countdown > 0 ? (
                  <Text size="sm" c="dimmed">
                    {countdown}s
                  </Text>
                ) : (
                  <IconSend
                    size={24}
                    onClick={handleSendOTP}
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              {...form.getInputProps("code")}
            />
          )}
          {/* <Group justify="space-between" mt="lg">
            <Checkbox
              label={
                <Text c="#8e8e8e" size="sm" fw="400">
                  Ghi nhớ
                </Text>
              }
              radius="xl"
              size="md"
              color={"#342e79"}
              onChange={(e) => {
                form.values.remember = e.target.checked;
              }}
            />
            <Anchor component="button" size="sm">
                Quên mật khẩu?
              </Anchor>
          </Group> */}

          <Button
            rightSection={<IconArrowNarrowRight color="#fff" size={24} />}
            type="submit"
            fullWidth
            bg="#342e79"
            mt="xl"
            className={style.button_submit}
          >
            <Text c={"#fff"} fw={500} size="lg" tt="capitalize">
              Đăng nhập
            </Text>
          </Button>
          <Group justify="end" mt="lg">
            <Anchor size="md" onClick={() => setIsLogin(true)}>
              Đăng ký tài khoản?
            </Anchor>
          </Group>
        </Paper>
        <Group justify="end">
          <Flex gap={4}>
            Copyright © 2024{" "}
            <Text fw={600} c="#000">
              Hacom.
            </Text>{" "}
            All rights reserved.
          </Flex>
        </Group>
      </Box>
    </>
  );
}
