import {
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Select,
  TextInput,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { custMeeting } from "../../../model/CustMeeting";
import { handleKeyDown } from "../../../_base/helper/FunctionHelper";
import { IconCheck } from "@tabler/icons-react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { useEffect, useState } from "react";
import {
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
  getStreetSelect,
} from "../../../service/getSelectApi";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";

const EditCustMeeting = ({
  id,
  setDeleteViewStatus,
}: {
  id: any;
  setDeleteViewStatus: any;
}) => {
  const entity = {
    id: id,
    name: null,
    phone: null,
    province: null,
    provinceName: null,
    commune: null,
    communeName: null,
    district: null,
    districtName: null,
    street: null,
    streetName: null,
    additionalAddress: null,
  };

  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataDistrictSelect, setDataDistrictSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataCommuneSelect, setDataCommuneSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataStreetSelect, setDataStreetSelect] = useState<ComboboxItem[]>([]);

  const form = useForm<custMeeting>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      name: (value: string | null | undefined) => {
        if (!value) {
          return "Vui lòng nhập tên khách hàng !";
        }
      },
      phone: (value: string | null | undefined) => {
        if (value) {
          return hasLength(
            { max: 10, min: 8 },
            "Số điện thoại phải từ 8 đến 10 ký tự !"
          )(value as string);
        }
      },
      province: (value: number | null | undefined) => {
        if (!value) {
          return "Vui lòng chọn tỉnh/thành phố !";
        }
      },
      district: (value: number | null | undefined) => {
        if (!value) {
          return "Vui lòng chọn quận/huyện !";
        }
      },
      commune: (value: number | null | undefined) => {
        if (!value) {
          return "Vui lòng chọn phường/xã !";
        }
      },
    },
  });

  const getUpdate = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblWarrantyServiceRequestForm/update-meeting-customer?id=${id}`
    );

    if (res && res?.success) {
      const result = res?.data;
      form.setValues(result);
      await Promise.all([
        getDataDistrictSelect(result?.province),
        getDataCommuneSelect(result?.district),
        getDataStreetSelect(result?.commune),
      ]);
    }
  };

  const updateCustMeeting = async (dataSubmit: custMeeting) => {
    const res = await repositoryMdm.post<MessageResponse<any>>(
      "/api/v1/TblWarrantyServiceRequestForm/update-meeting-customer",
      dataSubmit
    );

    if (res && res?.success) {
      NotificationExtension.Success(
        "Cập nhật thông tin khách hàng hẹn gặp thành công !"
      );
      setTimeout(() => {
        modals.closeAll();
        setDeleteViewStatus((prev: any) => !prev);
      }, 1000);
    }
  };

  const getDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataProvinceSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataDistrictSelect = async (province: any) => {
    const getData = await getDistrictSelect(province);
    setDataDistrictSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataCommuneSelect = async (district: any) => {
    const getData = await getCommuneSelect(district);
    setDataCommuneSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataStreetSelect = async (commune: any) => {
    const getData = await getStreetSelect(commune);
    setDataStreetSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (form.getValues()?.province !== null) {
      getDataDistrictSelect(form.getValues()?.province);
    }
  }, [form.getValues().province]);

  useEffect(() => {
    if (form.getValues()?.district !== null) {
      getDataCommuneSelect(form.getValues()?.district);
    }
  }, [form.getValues().district]);

  useEffect(() => {
    if (form.getValues()?.commune !== null) {
      getDataStreetSelect(form.getValues()?.commune);
    }
  }, [form.getValues().commune]);

  useEffect(() => {
    if (id) {
      getUpdate();
      getDataProvinceSelect();
    }
  }, [id]);

  return (
    <Box
      component="form"
      m={"5px 0px"}
      w={"30vw"}
      maw={500}
      onSubmit={form.onSubmit((e: custMeeting) => {
        updateCustMeeting(e);
      })}
    >
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label="Tên khách hàng"
            placeholder="Nhập tên khách hàng"
            key={form.key("name")}
            {...form.getInputProps("name")}
            withAsterisk
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label="Số điện thoại"
            placeholder="Nhập số điện thoại"
            type="number"
            onKeyDown={handleKeyDown}
            key={form.key("phone")}
            {...form.getInputProps("phone")}
            withAsterisk
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Select
            label="Tỉnh/Thành phố"
            placeholder="Chọn tỉnh/thành phố"
            data={dataProvinceSelect}
            value={
              form.getValues().province
                ? form.getValues()?.province?.toString()
                : null
            }
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            {...form.getInputProps("province")}
            onChange={(e) =>
              form.setValues((prev) => ({
                ...prev,
                province: e ? Number(e) : null,
                district: null,
                commune: null,
                street: null,
                additionalAddress: "",
              }))
            }
            withAsterisk
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Select
            label="Quận/Huyện"
            placeholder="Chọn quận/Huyện"
            data={dataDistrictSelect}
            searchable
            clearable
            disabled={form.getValues().province === null}
            value={
              form.getValues().district
                ? form.getValues()?.district?.toString()
                : null
            }
            {...form.getInputProps("district")}
            onChange={(e) =>
              form.setValues((prev) => ({
                ...prev,
                district: e ? Number(e) : null,
                commune: null,
                street: null,
                additionalAddress: "",
              }))
            }
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Select
            label="Phường/Xã"
            placeholder="Chọn phường/xã"
            data={dataCommuneSelect}
            searchable
            clearable
            disabled={form.getValues().district === null}
            value={
              form.getValues()?.commune
                ? form.getValues()?.commune?.toString()
                : null
            }
            {...form.getInputProps("commune")}
            onChange={(e) =>
              form.setValues((prev) => ({
                ...prev,
                commune: e ? Number(e) : null,
                street: null,
                additionalAddress: "",
              }))
            }
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Select
            label="Đường"
            placeholder="Chọn đường"
            data={dataStreetSelect}
            searchable
            clearable
            disabled={form.getValues().commune === null}
            value={
              form.getValues()?.street
                ? form.getValues()?.street?.toString()
                : null
            }
            {...form.getInputProps("street")}
            onChange={(e) =>
              form.setValues((prev) => ({
                ...prev,
                street: e ? Number(e) : null,
                additionalAddress: "",
              }))
            }
            nothingFoundMessage="Không tìm thấy dữ liệu !"
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={12}>
          <TextInput
            label="Địa chỉ cụ thể"
            placeholder="Nhập địa chỉ cụ thể"
            disabled={form.getValues().street === null}
            key={form.key("additionalAddress")}
            {...form.getInputProps("additionalAddress")}
          />
        </Grid.Col>
      </Grid>
      <Flex
        justify={"end"}
        gap={"md"}
        mt={10}
      >
        <Button
          type="submit"
          color="green"
          leftSection={<IconCheck size={14} />}
        >
          Lưu
        </Button>
      </Flex>
    </Box>
  );
};

export default EditCustMeeting;
