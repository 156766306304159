import { Badge, Box, Button, Input, Menu, rem, Text } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import {
  IconCaretDown,
  IconFileExport,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { BASE_API_MDM } from "../../../../config";
import { _localization } from "../../../../config/location";

type ReportPhoneItem = {
  maKh: string;
  tenKh: string;
  diaChi: string;
  sdt: string;
  email: string | null;
  soLuongHd: number;
  soLuongGd: number;
  tDoanhThu: number;
  tCk: number;
  tSauCk: number | null;
  tSauCkGg: number;
  tVon: number;
  tLai: number;
};
const ReportCustomer = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<ReportPhoneItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
    },
  });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const exportDataExcel = async () => {
    // const baseUrl = `https://apis-pos-dev.hacom.vn/api-mdm/api/v1/ExportExcel/export-bao-cao-theo-thong-tin-khach-hang-mua`;
    // const fileName = "bao_cao_theo_thong_tin_khach_hang_mua.xlsx";
    // exportDataToExcel(baseUrl, fileName);
    const url = `/api/v1/ExportExcel/export-bao-cao-theo-thong-tin-khach-hang-mua`;
    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "bao_cao_theo_thong_tin_khach_hang_mua.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/report-sale-info-customer${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  const columns = React.useMemo<MRT_ColumnDef<ReportPhoneItem>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
      },
      {
        accessorKey: "diaChi",
        header: "Địa chỉ",
        size: 300,
      },
      {
        accessorKey: "sdt",
        header: "Số điện thoại",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "soLuongHd",
        header: "Số lượng hóa đơn",
      },
      {
        accessorKey: "soLuongGd",
        header: "Số lượng giao dịch",
        // footer: "Tổng doanh thu:",
        // Footer(props) {
        //   return (
        //     <div>
        //       <Text c="red" fw={800}>
        //         {props.column.columnDef.footer}
        //       </Text>
        //     </div>
        //   );
        // },
      },
      {
        accessorKey: "tDoanhThu",
        header: "Doanh thu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tDoanhThu || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng doanh thu
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tCk",
        header: "Chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tCk || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng chiết khấu
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tSauCk",
        header: "Sau chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tSauCkGg",
        header: "Sau chiết khấu giảm giá",
        size: 200,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tVon",
        header: "Vốn",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tLai",
        header: "Lãi",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableColumnResizing: true,

    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "itemCode"],
        // right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableStickyFooter: true,
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 550,
        fontSize: "12.5px",
        padding: "10px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (160 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: "10px",
          justifyContent: "space-between",
        }}
      >
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Từ khoá"
            type="text"
            leftSection={<IconSearch color="#15aabf" />}
            key={formSeachInput.key("keySearch")}
            {...formSeachInput.getInputProps("keySearch")}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                fetchData();
              }
            }}
          />

          <Button
            type="submit"
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Chức năng
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Đóng
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ReportCustomer;
