import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Flex,
  Grid,
  Input,
  Menu,
  NumberFormatter,
  rem,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import * as xlsx from "xlsx";

import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  IconBuildingWarehouse,
  IconCaretDown,
  IconFileExport,
  IconFileUpload,
  IconMapPin,
  IconSearch,
  IconTimeDuration15,
  IconUsers,
} from "@tabler/icons-react";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { reportItemSales } from "../../../../model/TblOrderHeader";
import { toast } from "react-toastify";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { MessageResponse } from "../../../../model/MessageResponse";
import { useNavigate } from "react-router-dom";
import { green } from "../../../../const/variables";
import { AvatarUtils } from "../../../../common/ColorByName/AvatarUtils";
import { exportDataToExcel } from "../../../../common/ExportExcelToAPI/ExportExcelToAPI";
import { BASE_API_MDM } from "../../../../config";
import { addMonths } from "date-fns";
const GeneralSalesStatement = () => {
  const navigate = useNavigate();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<reportItemSales[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //chi nhánh
  // const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
  //   []
  // );
  const userLogin = JSON.parse(localStorage.getItem("userLogin") || "{}");
  //Kho
  const [dataInventory, setDataInventory] = useState<SelectListItemModel[]>([]);
  //Bộ phận
  const [dataDepartment, setDataDepartment] = useState<SelectListItemModel[]>(
    []
  );
  //Khách hàng
  const [dataCustomer, setDataCustomer] = useState<SelectListItemModel[]>([]);
  const sizeGrid = { base: 6, sm: 3, md: 3, lg: 3, xl: 1.7 };
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      StartTime: "",
      EndTime: "",
    },
  });

  const [search, setSearch] = useState({
    Cnids: [], //chi nhánh
    KhoIds: [], //kho
    Bpids: [], //bộ phận
    KHids: [], //khách hàng
  });
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }
    if (formSeachInput.getValues().StartTime) {
      url += `&StartTime=${formSeachInput.getValues().StartTime}`;
    }

    if (formSeachInput.getValues().EndTime) {
      url += `&EndTime=${formSeachInput.getValues().EndTime}`;
    }
    // if (userLogin) {
    //   url += `&Cnids=${userLogin.branchId}`;
    // }
    if (search.KhoIds && search.KhoIds.length > 0) {
      search.KhoIds.forEach((KhoIds) => {
        url += `&KhoIds=${KhoIds}`;
      });
    }
    if (search.Bpids && search.Bpids.length > 0) {
      search.Bpids.forEach((Bpids) => {
        url += `&Bpids=${Bpids}`;
      });
    }
    if (search.KHids && search.KHids.length > 0) {
      search.KHids.forEach((KHids) => {
        url += `&KHids=${KHids}`;
      });
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/report-item-sales${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  const handleCheckView = async (row: any) => {
    if (row.original.tlHd == "3") {
      const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.maCt}`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        navigate("/sell/view-return-order", {
          state: {
            id: row.original.orderId,
            orderNumber: row.original.maCt,
            orderType: row.original.tlHd,
            actionType: "view",
          },
        });
      }
    } else {
      const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.maCt}`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        navigate("/sell/view-order", {
          state: {
            id: row.original.orderId,
            orderNumber: row.original.maCt,
            orderType: row.original.tlHd,
            actionType: "view",
          },
        });
      }
    }
  };
  const columns = React.useMemo<MRT_ColumnDef<reportItemSales>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "maCt",
        header: "Mã chứng từ",
        size: 170,

        Cell: ({ renderedCellValue, row }) => (
          <>
            <Tooltip label="Xem chi tiết">
              <Badge
                variant="dot"
                radius="xs"
                onDoubleClick={() => handleCheckView(row)}
                style={{
                  cursor: "pointer",
                }}
                size="lg"
                color={renderedCellValue === null ? "red" : green.base}
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          </>
        ),
      },
      {
        accessorKey: "attribute6",
        header: "Trạng thái chung",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "ngayCt",
        header: "Ngày chứng từ",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        sortingFn: (a, b) => {
          const dateA = new Date(a.original.ngayCt);
          const dateB = new Date(b.original.ngayCt);
          return dateA.getTime() - dateB.getTime();
        },
      },
      //   {
      //     accessorKey: "ttHd",
      //     header: "Trạng thái hóa đơn",
      //     size: 30,
      //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      //   },
      {
        accessorKey: "maBp",
        header: "Mã bộ phận",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenCn",
        header: "Tên chi nhánh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maKho",
        header: "Mã kho",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tenKho",
        header: "Tên kho",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maNvkd",
        header: "Mã nhân viên",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenNvkd",
        header: "Tên nhân viên kinh doanh",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.tenNvkd?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "maQl",
        header: "Mã quản lý",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenQl",
        header: "Tên quản lý",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.tenQl?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "maKt",
        header: "Mã kế toán",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKeToan",
        header: "Tên kế toán",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.tenKeToan?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "maKenh",
        header: "Mã kênh bán",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKenh",
        header: "Tên kênh bán",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "attribute4",
        header: "Mã đối tượng công nợ",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.tenKh?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "diaChiKh",
        header: "Địa chỉ khách hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "sdt",
        header: "Số điện thoại",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "email",
        header: "Email",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maThueKh",
        header: "Mã thuế khách hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maTinh",
        header: "Mã tỉnh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenTinh",
        header: "Tên tỉnh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maQuan",
        header: "Mã quận",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenQuan",
        header: "Tên quận",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maPhuong",
        header: "Mã phường",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenPhuong",
        header: "Tên phường",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "attribute1",
        header: "Loại hóa đơn",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "ttHd",
        header: "Trạng thái hóa đơn",
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge size="sm" color="green">
              {renderedCellValue === "DON_HANG_HOAN_THANH"
                ? "Đơn hàng hoàn thành"
                : ""}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "shippingCode",
        header: "Mã vận đơn",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "shipngType",
        header: "Hình thức giao hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "shippingStatus",
        header: "Trạng thái giao hàng",
        size: 200,
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge size="sm" color="cyan">
              {renderedCellValue === null ? "KHONG_YEU_CAU" : renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "shippingPriority",
        header: "Ưu tiên giao vận",
        size: 200,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "shipingVendor",
        header: "Đơn vị giao vận",
        size: 200,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "shippingDate",
        header: "Ngày giao hàng",
        size: 225,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "shippingFinishDate",
        header: "Ngày hoàn thành giao hàng",
        size: 225,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "shippingNote",
        header: "Ghi chú GV",
        size: 200,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tTienDh",
        header: "Tổng tiền hóa đơn",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tCk",
        header: "Tổng tiền chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "dtSauCk",
        header: "Doanh thu sau chiết khấu",
        size: 225,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "tGg",
        header: "Tổng giảm giá",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "tTienVoucher",
        header: "Tiền voucher",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "tTienTra",
        header: "Tổng tiền trả",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "tTienCon",
        header: "Tổng tiền còn phải trả",
        size: 200,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "ngayDuyet",
        header: "Ngày duyệt giảm giá",
        size: 200,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "nguoiDuyetGg",
        header: "Người duyệt giảm giá",
        size: 190,
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {/* <AvatarUtils
              value={row.original.nguoiDuyetGg?.split("-")[0] || ""}
            /> */}
            {renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "maItem",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "uom",
        header: "Đơn vị tính",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "priceOnPriceList",
        header: "Đơn giá bán",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "giaVon",
        header: "Giá vốn",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },

      {
        accessorKey: "tienGiaVon",
        header: "Tiền giá vốn",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "attribute3",
        header: "Tổng tiền lãi",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600} size="sm">
            {renderedCellValue !== undefined &&
            !isNaN(Number(renderedCellValue)) ? (
              <NumberFormatter
                value={Number(renderedCellValue)}
                thousandSeparator=","
              />
            ) : (
              <></>
            )}
          </Text>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.attribute3 || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng tiền lãi
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tienCk",
        header: "Chiết khấu trên sản phẩm",
        size: 225,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "tienGg",
        header: "Xin giảm giá trên sản phẩm ",
        size: 225,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "totalLineAmount",
        header: "Tổng tiền theo sản phẩm",
        size: 225,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) =>
                sum + (row.original.totalLineAmount || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng tiền theo sản phẩm
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "attribute8",
        header: "Tổng tiền theo sản phẩm sau chuyển khoản và giảm giá",
        size: 225,
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={600} size="sm">
            {renderedCellValue !== undefined &&
            !isNaN(Number(renderedCellValue)) ? (
              <NumberFormatter
                value={Number(renderedCellValue)}
                thousandSeparator=","
              />
            ) : (
              <></>
            )}
          </Text>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.attribute8 || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                Tổng tiền theo SP sau CK và GG
              </Text>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },

      {
        accessorKey: "tgNguoiSua",
        header: "Thời gian người sửa",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "maNguoiSua",
        header: "Mã Người sửa",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "attribute5",
        header: "Xuất hóa đơn",
        Cell: ({ renderedCellValue, row }) => (
          <>
            {renderedCellValue === null
              ? null
              : renderedCellValue === "Có"
              ? `Có xuất hóa đơn-${row.original?.maCt}`
              : "Không xuất hóa đơn"}
          </>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableColumnResizing: true,
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "maCt"],
        // right: ["tTienDh", "action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableStickyFooter: true,
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //xuất excel
  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-bao-cao-ban-hang-theo-ngay.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  // // Lấy danh sách mã chi nhánh
  // const DataBranchCode = async () => {
  //   try {
  //     const response = await repositoryDelivery.get(
  //       `api/v1/TblDmMasterOrg/get-select`
  //     );

  //     if (response && response.success) {
  //       const transformedData = response.data.map((item: any) => ({
  //         value: item.value,
  //         label: item.value + " - " + item.text,
  //       }));
  //       setDataBranchCode(transformedData);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return;
  //   }
  // };
  // Lấy danh sách kho
  const fetchDataKho = async () => {
    try {
      // if (
      //   userLogin &&
      //   userLogin.branchId !== null &&
      //   userLogin.branchId !== undefined
      // ) {
      //   const response = await repositoryPos.get(
      //     `/api/v1/TblDmInventory/get-select-by-branch?branchId=${userLogin?.branchId}`
      //   );
      //   if (response && response.success) {
      //     const transformedData = response.data.map((item: any) => ({
      //       value: String(item.inventoryId),
      //       label: item.inventoryCode + " - " + item.inventoryName,
      //     }));
      //     setDataInventory(transformedData);
      //   } else {
      //     setDataInventory([]);
      //   }
      // } else {
      //   const response = await repositoryPos.get(
      //     `/api/v1/TblDmInventory/get-all?checkAuth=true`
      //   );
      //   if (response && response.success) {
      //     const transformedData = response.data.map((item: any) => ({
      //       value: item.value,
      //       label: item.text,
      //     }));
      //     setDataInventory(transformedData);
      //   } else {
      //     setDataInventory([]);
      //   }
      // }
      const response = await repositoryPos.get(
        `/api/v1/TblDmInventory/get-all?checkAuth=true`
      );
      if (response && response.success) {
        const transformedData = response.data
          .filter((i: any) => i.value !== null && i.text !== null)
          .map((item: any) => ({
            value: item.value,
            label: item.text,
          }));
        setDataInventory(transformedData);
      } else {
        setDataInventory([]);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  // Lấy danh sách mã bộ phận
  const fetchDataBp = async () => {
    try {
      // if (
      //   userLogin &&
      //   userLogin.branchId !== null &&
      //   userLogin.branchId !== undefined
      // ) {
      //   const response = await repositoryPos.get(
      //     `/api/v1/TblDepartment/get-all?branchId=${userLogin?.branchId}`
      //   );
      //   if (response && response.success) {
      //     const transformedData = response.data.map((item: any) => ({
      //       value: String(item.departmentId),
      //       label: item.deparmentCode + " - " + item.name,
      //     }));
      //     setDataDepartment(transformedData);
      //   } else {
      //     setDataDepartment([]);
      //   }
      // } else {
      //   const response = await repositoryPos.get(
      //     `/api/v1/TblDepartment/get-all`
      //   );
      //   if (response && response.success) {
      //     const transformedData = response.data.map((item: any) => ({
      //       value: String(item.departmentId),
      //       label: item.departmentId + " - " + item.name,
      //     }));
      //     setDataDepartment(transformedData);
      //   } else {
      //     setDataDepartment([]);
      //   }
      // }
      const response = await repositoryPos.get(`/api/v1/TblDepartment/get-all`);
      if (response && response.success) {
        const transformedData = response.data
          .filter((i: any) => i.departmentId !== null && i.name !== null)
          .map((item: any) => ({
            value: String(item.departmentId),
            label: item.deparmentCode + " - " + item.name,
          }));
        setDataDepartment(transformedData);
      } else {
        setDataDepartment([]);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  // Lấy danh sách mã khách hàng
  const fetchDataCustomer = async () => {
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmCustomer/get-list?Skip=0&Take=200`
      );
      if (response && response.success) {
        const transformedData = response.data
          .filter((item: any) => item.name !== null && item.id !== null)
          .map((item: any) => ({
            value: String(item.id),
            label: String(item.code) + " - " + item.name,
          }))
          .filter(
            (item: any, index: number, self: any[]) =>
              index === self.findIndex((t) => t.value === item.value)
          );

        setDataCustomer(transformedData);
      } else {
        setDataCustomer([]);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  useEffect(() => {
    fetchData();
  }, [pagination]);

  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };

  const exportDataExcel = async () => {
    // let url = ``;
    // if (formSeachInput.getValues().keySearch) {
    //   url += `?KeySearch=${formSeachInput.getValues().keySearch}`;
    // }
    // if (formSeachInput.getValues().StartTime) {
    //   url += `&StartTime=${formSeachInput.getValues().StartTime}`;
    // }

    // if (formSeachInput.getValues().EndTime) {
    //   url += `&EndTime=${formSeachInput.getValues().EndTime}`;
    // }
    // // if (userLogin) {
    // //   url += `&Cnids=${userLogin.branchId}`;
    // // }
    // if (search.KhoIds && search.KhoIds.length > 0) {
    //   search.KhoIds.forEach((KhoIds) => {
    //     url += `&KhoIds=${KhoIds}`;
    //   });
    // }
    // if (search.Bpids && search.Bpids.length > 0) {
    //   search.Bpids.forEach((Bpids) => {
    //     url += `&Bpids=${Bpids}`;
    //   });
    // }
    // if (search.KHids && search.KHids.length > 0) {
    //   search.KHids.forEach((KHids) => {
    //     url += `&KHids=${KHids}`;
    //   });
    // }
    let params = new URLSearchParams();

    // Add the query parameters if they exist
    const keySearch = formSeachInput.getValues().keySearch;
    if (keySearch) {
      params.append("KeySearch", keySearch);
    }

    const startTime = formSeachInput.getValues().StartTime;
    if (startTime) {
      params.append("StartTime", startTime);
    }

    const endTime = formSeachInput.getValues().EndTime;
    if (endTime) {
      params.append("EndTime", endTime);
    }

    // Example of adding custom query params for arrays
    if (search.KhoIds && search.KhoIds.length > 0) {
      params.append("KhoIds", search.KhoIds.join(","));
    }

    if (search.Bpids && search.Bpids.length > 0) {
      params.append("Bpids", search.Bpids.join(","));
    }

    if (search.KHids && search.KHids.length > 0) {
      params.append("KHids", search.KHids.join(","));
    }

    const baseUrl = params.toString()
      ? `/api/v1/ExportExcel/export-item-sales-daily?${params.toString()}`
      : `/api/v1/ExportExcel/export-item-sales-daily`;

    const _url = BASE_API_MDM + baseUrl;

    const fullUrl = new URL(_url);
    const token = localStorage.getItem("token");
    fetch(fullUrl.toString(), {
      method: "GET", // Use the appropriate HTTP method
      headers: {
        Authorization: `Bearer ${token?.replace(/"/g, "")}`,
        "Content-Type": "application/vnd.ms-excel",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob(); // Assuming the response is a file or blob
        } else {
          throw new Error("Failed to fetch the resource");
        }
      })

      .then((blob) => {
        // Open the blob in a new tab
        const url = window.URL.createObjectURL(blob);
        let filename = "bao_cao_ban_hang_theo_ngay.xlsx"; // Tên mặc định nếu không có header
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        style={{
          border: "1px solid #dee2e6",
          padding: "5px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Chức năng
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              // onClick={handleExport}
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
            {/* <Tooltip label={"Chức năng đang update"}>
              <Menu.Item
                leftSection={
                  <IconFileUpload style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Import Excel
              </Menu.Item>
            </Tooltip> */}
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          // marginTop: "10px",
        }}
      >
        <Grid style={{ width: "100%" }} mt={10}>
          <Grid.Col span={sizeGrid}>
            <Input
              size="sm"
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              key={formSeachInput.key("keySearch")}
              {...formSeachInput.getInputProps("keySearch")}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  fetchData();
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày bắt đầu"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
              onChange={(date) => {
                formSeachInput.setFieldValue(
                  "StartTime",
                  formatDateNotTimeZone(date) || ""
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày kết thúc"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
              // maxDate={new Date(formSeachInput.getValues().StartTime)}
              maxDate={
                formSeachInput.getValues().StartTime
                  ? addMonths(new Date(formSeachInput.getValues().StartTime), 1)
                  : undefined
              }
              onChange={(date) => {
                formSeachInput.setFieldValue(
                  "EndTime",
                  formatDateNotTimeZone(date) || ""
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              size="sm"
              placeholder="Vui lòng chọn kho"
              searchable
              clearable
              leftSection={<IconBuildingWarehouse size={20} color="#15aabf" />}
              // disabled={search.Cnids.length === 0}
              onClick={() => {
                if (dataInventory.length === 0) {
                  fetchDataKho();
                }
              }}
              data={dataInventory}
              onChange={(value) =>
                handleChangeSearchValue(value ? [value] : null, "KhoIds")
              } // Cập nhật KhoIds
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              size="sm"
              placeholder="Vui lòng chọn bộ phận"
              searchable
              clearable
              leftSection={<IconUsers size={20} color="#15aabf" />}
              data={dataDepartment}
              onClick={() => {
                if (dataDepartment.length === 0) {
                  fetchDataBp();
                }
              }}
              onChange={(value) =>
                handleChangeSearchValue(value ? [value] : null, "Bpids")
              } // Cập nhật Bpids
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              size="sm"
              placeholder="Vui lòng chọn khách hàng"
              searchable
              clearable
              leftSection={<IconUsers size={20} color="#15aabf" />}
              data={dataCustomer}
              onClick={() => {
                if (dataCustomer.length === 0) {
                  fetchDataCustomer();
                }
              }}
              onChange={(value) =>
                handleChangeSearchValue(value ? [value] : null, "KHids")
              } // Cập nhật KHids
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Button
              type="submit"
              leftSection={<IconSearch size={20} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>{" "}
          </Grid.Col>
        </Grid>

        {/* <Select
          size="sm"
          placeholder="Chi nhánh"
          searchable
          clearable
          data={dataBranchCode}
          leftSection={<IconMapPin size={20} color="#15aabf" />}
          onClick={() => {
            if (dataBranchCode.length === 0) {
              DataBranchCode();
            }
          }}
          onChange={(value) =>
            handleChangeSearchValue(value ? [value] : null, "Cnids")
          } // Cập nhật Cnids
        /> */}
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default GeneralSalesStatement;
