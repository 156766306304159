import {
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Paper,
  Select,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import { useState } from "react";
import {
  repositoryPayment,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import MBB666 from "../../../../assets/images/MBB-666-QR.png";
import MBB973 from "../../../../assets/images/MBB-973-QR.png";
import TCB999 from "../../../../assets/images/TCB-999-QR.png";
import VCB762 from "../../../../assets/images/VCB-762-QR.png";
import VCB813 from "../../../../assets/images/VCB-813-QR.png";
import VCB930 from "../../../../assets/images/VCB-930-QR.png";
import MBB913 from "../../../../assets/images/MBB-913-QR.png";
import MBB091 from "../../../../assets/images/MBB-091-QR.png";
import VCB968 from "../../../../assets/images/VCB-968-QR.png";
import MBB001 from "../../../../assets/images/MBB-001-QR.png";
import TPB001 from "../../../../assets/images/TPB-001-QR.png";
import TPB604 from "../../../../assets/images/TPB-604-QR.png";
import TPB001NTQ from "../../../../assets/images/TPB-001-NTQ.png";
import MBB099 from "../../../../assets/images/MBB-099-QR.png";
import MBB868 from "../../../../assets/images/MBB-868-QR.png";
import VCB008 from "../../../../assets/images/VCB-008-QR.png";
import TPB601 from "../../../../assets/images/TPB-601.QR.png";
import TCB091 from "../../../../assets/images/TCB-091-QR.png";
import TPB002 from "../../../../assets/images/TPB-002-QR.png";
import MBB008 from "../../../../assets/images/MBB-008-QR.png";

import { MessageResponse } from "../../../../model/MessageResponse";
import { customerCheck } from "../Model/modelRetailOrder";

export const PaymentMethodMb = ({
  price,
  productCode,
  setPaymentDone,
  paymentDone,
  customerCheck,
  orderNumber,
  setSelectedBankReserve,
  setTransactionCodeReserve,
  selectedBankReserve,
  transactionCodeReserve,
  setTransactionCodeMb,
  setIsPaymentMbSuccess,
  isPaymentMbSuccess,
}: {
  setPaymentDone: any;
  paymentDone: any;
  price: number;
  productCode: string;
  orderNumber: string;
  customerCheck: customerCheck;
  setSelectedBankReserve: any;
  setTransactionCodeReserve: any;
  selectedBankReserve: any;
  transactionCodeReserve: any;
  setTransactionCodeMb: any;
  setIsPaymentMbSuccess: any;
  isPaymentMbSuccess: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState<string | null>(null); // QR động
  const [sectionId, setSectionId] = useState<string>(""); // QR động
  const [genQrError, setGenQrError] = useState(false); // QR động
  // do state isPaymentMbSuccess ở component cha không thay đổi ngay khi setIsPaymentMbSuccess nên cần tạo state này
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

  const [transactionCodeTemp, setTransactionCodeTemp] = useState("");
  const [selectedBankImage, setSelectedBankImage] = useState<string | null>(
    null
  );
  const [selectedBankReserveChildren, setSelectedBankReserveChildren] =
    useState("");
  const [expireTime, setExpireTime] = useState<string | null>(null); // Thêm state để lưu expire_time

  const bankOptions = [
    { value: "TCB-999", label: "TCB-999-HACOM", imageQr: TCB999 },
    { value: "VCB HN-968", label: "VCB HN-968-HACOM", imageQr: VCB968 },
    { value: "VCB-930", label: "VCB TAY HO-930-HACOM", imageQr: VCB930 },
    { value: "MBB-001", label: "MBB-001-HACOM", imageQr: MBB001 },
    { value: "TPB-001", label: "TPB-001-HACOM", imageQr: TPB001 },
    { value: "MBB-973", label: "MBB QR-973-HACOM", imageQr: MBB973 },
    { value: "TPB-604", label: "TPB-604-HACOM", imageQr: TPB604 },
    { value: "TPB-001-NQT", label: "TPB-001-NQT", imageQr: TPB001NTQ },
    { value: "VCB-813", label: "VCB-813-HACOM Q3", imageQr: VCB813 },
    { value: "MBB-099", label: "MBB-099-HACOM Q3", imageQr: MBB099 },
    { value: "VCB-762", label: "VCB-762-HACOM TU SON", imageQr: VCB762 },
    { value: "MBB-666", label: "MBB-666-HACOM THANH TRI", imageQr: MBB666 },
    { value: "MBB-868", label: "MBB-868-FAS", imageQr: MBB868 },
    { value: "VCB-008", label: "VCB-008-FAS", imageQr: VCB008 },
    { value: "TPB-601", label: "TPB-601-FAS", imageQr: TPB601 },
    { value: "TCB-091", label: "TCB-091-HACOM DVT", imageQr: TCB091 },
    { value: "MBB-091", label: "MBB-091-HACOM DVT", imageQr: MBB091 },
    { value: "TPB-002", label: "TPB-002-HACOM-USD", imageQr: TPB002 },
    { value: "MBB008", label: "MBB-008-HACOM-USD", imageQr: MBB008 },
    { value: "MBB-913", label: "MBB-913-FAS-USD", imageQr: MBB913 },
  ];

  const handleCreateQrCode = async () => {
    try {
      setIsLoading(true);
      const response = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/Public/ip`
      );
      const ipAddress = response?.data || "";
      const callapi = await repositoryPayment.post<MessageResponse<any>>(
        `/api/TblMBQRcode/gen-qr-mb-v2`,
        {
          amount: price,
          ipAddress: ipAddress,
          orderInfo: orderNumber,
          orderNumber: orderNumber,
        }
      );

      if (callapi?.success) {
        console.log("callapi", callapi?.data?.session_id);
        setQrCode(callapi?.data?.qr_url);
        setGenQrError(false);
        setSectionId(callapi?.data?.session_id);
        setTransactionCodeMb(callapi?.data?.session_id);
        setExpireTime(callapi?.data?.expire_time); // Gán expire_time vào state mới

        // Gọi hàm kiểm tra thanh toán mỗi 3 giây
        const intervalId = setInterval(async () => {
          const paymentSuccess = await handleCheckPaymentOneSecond(
            callapi?.data?.session_id
          );

          // Dừng gọi hàm nếu thanh toán thành công hoặc thất bại
          if (paymentSuccess) {
            clearInterval(intervalId);
            setPaymentDone(true); // Cập nhật trạng thái thanh toán
          }
        }, 3000); // Gọi lại mỗi 3 giây
      } else {
        setGenQrError(true);
      }
    } catch (error) {
      console.log("error", error);
      setGenQrError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckPaymentOneSecond = async (
    sectionId: string
  ): Promise<boolean> => {
    try {
      const response = await repositoryPayment.get<MessageResponse<any>>(
        `/api/TblMBQRcode/check-qr-mb?sessionId=${sectionId}`
      );
      console.log("res", response);

      // Kiểm tra trạng thái thanh toán dựa trên response?.data?.status
      const status = response?.data?.status;

      if (response?.success) {
        if (status === 1) {
          NotificationExtension?.Success(
            `Thanh toán đơn hàng ${response?.data?.orderinfo} thành công!`
          );
          setIsPaymentMbSuccess(true); // Cập nhật trạng thái thành công
          setIsPaymentSuccess(true);
          return true; // Dừng kiểm tra nếu thanh toán thành công
        } else if (status === 2) {
          NotificationExtension?.Fails(
            "Thanh toán thất bại, vui lòng thử lại!"
          );
          return true; // Dừng kiểm tra nếu thanh toán thất bại
        }
      }
    } catch (error) {
      console.log("Error checking payment:", error);
    }
    return false; // Tiếp tục kiểm tra nếu chưa có kết quả hoặc lỗi xảy ra
  };

  return (
    <Box component="form" mx="auto" style={{ position: "relative" }} mih={480}>
      <LoadingOverlay visible={isLoading} zIndex={1000} />

      <Tabs defaultValue="backupQR">
        <Tabs.List
          grow
          style={{
            borderBottom: "1px solid #e0e0e0",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Tabs.Tab
            value="dynamicQR"
            // disabled
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
              padding: "10px 0",
            }}
          >
            QR động
          </Tabs.Tab>
          <Tabs.Tab
            value="backupQR"
            disabled={isPaymentMbSuccess || isPaymentSuccess} // Logic disable
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
              padding: "10px 0",
              color:
                isPaymentMbSuccess || isPaymentSuccess ? "#ccc" : "inherit", // Đổi màu khi bị disable
              cursor:
                isPaymentMbSuccess || isPaymentSuccess
                  ? "not-allowed"
                  : "pointer", // Thay đổi con trỏ chuột
            }}
          >
            QR tĩnh
          </Tabs.Tab>
        </Tabs.List>

        {/* Tab 1: QR động */}
        <Tabs.Panel value="dynamicQR" pt="xs" mt={46}>
          {genQrError ? (
            <>
              <Text style={{ alignItems: "center" }}>
                Tạo QR code lỗi, vui lòng đóng form và thử lại với số tiền lớn
                hơn 50.000!
              </Text>
            </>
          ) : qrCode && !(isPaymentMbSuccess || isPaymentSuccess) ? (
            <Group
              align="center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Paper
                withBorder
                shadow="md"
                p="lg"
                radius="md"
                style={{
                  backgroundColor: "white",
                  width: "90%",
                  textAlign: "center",
                }}
              >
                <Text mb={5}>Quý khách vui lòng quét mã để thanh toán</Text>
                <Text>
                  {`Số tiền thanh toán: `}
                  <Text component="span" color="red" fw={"600"}>
                    {Number(price).toLocaleString("vi-VN")} VND
                  </Text>
                </Text>

                <Text fw={"500"}>{`Nội dung: ${orderNumber}`}</Text>
                <Text fw={"500"}>Ngân hàng: MB Bank</Text>
                {expireTime && (
                  <Text fw={"500"}>{`Hạn sử dụng QR: ${expireTime}`}</Text>
                )}
                <img src={qrCode} alt="QR Code" width={300} height={300} />
              </Paper>
            </Group>
          ) : (
            <Flex
              direction="column"
              align="center"
              justify="center"
              style={{
                minHeight: "200px",
                backgroundColor: "#f0fff4", // Màu nền nhạt
                borderRadius: "12px",
                padding: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Hiệu ứng đổ bóng
              }}
            >
              {isPaymentMbSuccess || isPaymentSuccess ? (
                // Hiển thị thông báo thành công
                <>
                  <Text
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "#2f855a", // Màu chữ nổi bật
                    }}
                  >
                    🎉 Bạn đã hoàn tất thanh toán! 🎉
                  </Text>
                </>
              ) : (
                // Hiển thị nút tạo QR nếu chưa thành công
                <>
                  <Button
                    size="lg"
                    variant="gradient"
                    gradient={{ from: "teal", to: "blue", deg: 60 }}
                    onClick={handleCreateQrCode}
                    style={{
                      padding: "10px 20px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      borderRadius: "8px",
                    }}
                  >
                    Tạo QR Code
                  </Button>
                  <Text
                    style={{
                      marginTop: "10px",
                      color: "#666",
                      fontSize: "14px",
                    }}
                  >
                    Nhấn để tạo mã QR động cho thanh toán.
                  </Text>
                </>
              )}
            </Flex>
          )}
        </Tabs.Panel>

        {/* Tab 2: QR dự phòng */}
        <Tabs.Panel value="backupQR" pt="xs">
          <Group
            align="center"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              gap: "10px",
            }}
          >
            <Select
              size="sm"
              placeholder="Chọn ngân hàng"
              data={bankOptions}
              value={selectedBankReserveChildren || selectedBankReserve}
              onChange={(bank) => {
                setSelectedBankReserve(bank);
                setSelectedBankReserveChildren(bank || "");
                const selectedBankDetails = bankOptions.find(
                  (b) => b.value === bank
                );
                setSelectedBankImage(selectedBankDetails?.imageQr || null);
              }}
              style={{ width: "200px" }}
            />
            <Flex align="center" gap="xs">
              <TextInput
                placeholder="Nhập mã giao dịch"
                size="sm"
                value={transactionCodeTemp || transactionCodeReserve}
                onChange={(e) => setTransactionCodeTemp(e.target.value)}
                style={{ width: "200px" }}
              />
              <Button
                size="sm"
                onClick={() => {
                  if (!transactionCodeTemp?.trim()) {
                    NotificationExtension?.Fails(
                      "Vui lòng nhập mã giao dịch trước khi lưu."
                    );
                    return;
                  }
                  setTransactionCodeReserve(transactionCodeTemp);
                  NotificationExtension?.Success("Lưu mã giao dịch thành công");
                }}
              >
                Lưu
              </Button>
            </Flex>
          </Group>

          {selectedBankImage && (
            <Group
              align="center"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Paper
                withBorder
                shadow="md"
                p="lg"
                radius="md"
                style={{
                  backgroundColor: "white",
                  width: "90%",
                  textAlign: "center",
                }}
              >
                <Text mb={5}>Quý khách vui lòng quét mã để thanh toán</Text>
                <Text>
                  {`Số tiền thanh toán: `}
                  <Text component="span" color="red" fw={"600"}>
                    {Number(price).toLocaleString("vi-VN")} VND
                  </Text>
                </Text>

                <Text fw={"500"}>{`Nội dung:${orderNumber}`}</Text>
                <img
                  src={selectedBankImage}
                  alt="QR Code Backup"
                  style={{ width: 300, height: 350 }}
                />
              </Paper>
            </Group>
          )}
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};
