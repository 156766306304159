import {
  Button,
  Checkbox,
  Fieldset,
  Flex,
  Grid,
  Input,
  LoadingOverlay,
  Overlay,
  Pagination,
  ScrollArea,
  Table,
  TextInput,
  Tooltip,
  Text,
  Box,
  ActionIcon,
  Menu,
  Tabs,
  rem,
  NumberInput,
} from "@mantine/core";
import { useDebouncedCallback, useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCheck,
  IconMessageCircle,
  IconPhoto,
  IconSearch,
  IconSettings,
  IconWindow,
} from "@tabler/icons-react";
import cx from "clsx";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { SelectListItemBase } from "../../../../../_base/model/_base/SelectListItemBase";
import { _localization } from "../../../../../config/location";
import { sky_blue } from "../../../../../const/variables";
import { MessageResponse } from "../../../../../model/MessageResponse";
import { tblItemByCat } from "../../../../../model/TblItem";
import { tblPromotionItemGiftCommand } from "../../../../../model/TblPromotion";
import TableSegment from "../Components/TableItemPromotion/TableSegment";
import classes from "../Styles/ScrollTable.module.css";
import TableItems from "../Components/TableItemPromotion/TableItems";
import { useForm } from "@mantine/form";
import { set } from "date-fns";
interface TableItemsModelProps {
  itemCode: string;
  itemName: string;
}
interface SelectSegment {
  code: string;
  type: number;
  checked: boolean;
}

export interface ItemData {
  brandId: number | null; // Có thể null
  isOnhand: boolean | null; // Có thể null
  itemCode: string; // Mã sản phẩm, chuỗi
  itemId: number; // ID sản phẩm, số nguyên
  itemName: string; // Tên sản phẩm, chuỗi
  itemShortName: string | null; // Tên rút gọn, có thể null
  segment1: string | null; // Có thể null
  segment2: string | null; // Có thể null
  segment3: string | null; // Có thể null
  segment4: string | null; // Có thể null
  segment5: string | null; // Có thể null
  segment6: string | null; // Có thể null
}

const ModalSearchItems = ({
  handleAddItem,
  handleAddSearchItem,
  dataDelete,
  typeAdd,
}: {
  handleAddItem: (
    selectedCatSegment: { code: string; type: number }[],
    selectedManuFacTurer: string[],
    selectCat: string | null
  ) => void;
  handleAddSearchItem: (selectItem: tblItemByCat[]) => void;
  dataDelete?: tblPromotionItemGiftCommand[];
  typeAdd?: number;
}) => {
  const [visibleCat, { close: closeCat, open: openCat }] = useDisclosure(false);
  const [visibleItem, { close: closeItem, open: openItem }] =
    useDisclosure(false);
  const [selectCat, setSelectedCat] = useState<string | null>("1a");
  const [scrolledv1, setScrolledv1] = useState(false);
  const [selectedManuFacTurer, setSelectedManuFacTurer] = useState<string[]>(
    []
  );
  const [dataManuFacTurer, setDataManuFacTurer] = useState<any[]>([]);
  const [dataManuFacTurerTemp, setDataManuFacTurerTemp] = useState<any[]>([]);
  const [keySearch, setKeySearch] = useState<string>("");
  const [keySearchItem, setKeySearchItem] = useState<string>("");
  const [selectedCatSegment, setSelectedCatSegment] = useState<SelectSegment[]>(
    []
  );
  const [dataItem, setDataItem] = useState<tblItemByCat[]>([]);
  const [dataItemTemp, setDataItemTemp] = useState<tblItemByCat[]>([]);
  const [selectItem, setSelectItem] = useState<tblItemByCat[]>([]);
  const [activePage, setPage] = useState(1);
  const [activePageGroup, setPageGroup] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountGroup, setTotalCountGroup] = useState(0);
  const [isGetByCat, setIsGetByCat] = useState(false);
  const [search, setSearch] = useState({
    type: "",
    keySearch: "",
  });

  // Table delete
  const [data, setData] = useState<any[]>(dataDelete ?? []);
  const [dataTemp, setDataTemp] = useState<any[]>(dataDelete ?? []);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [rowCount, setRowCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [selectIds, setSelectIds] = useState<string[]>([]);

  const entity: tblItemByCat = {
    brandId: null, // ID thương hiệu (có thể null)
    isOnhand: null, // Trạng thái có sẵn hàng
    itemCode: null, // Mã mặt hàng
    itemId: 0, // ID mặt hàng
    itemName: null, // Tên mặt hàng
    itemShortName: null, // Tên ngắn của mặt hàng (có thể null)
    segmentId: null, // ID phân khúc
    segmentName: null,
    quantityMin: null,
    quantityMax: null,
    quantityApply: null,
    discountPercent: null,
    discountAmount: null,
    priceMin: null,
    priceMax: null
  };

  const columnsDelete = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemPrimaryCode",
        header: "Mã",
      },

      {
        accessorKey: "itemPrimaryName",
        header: "Tên",
      },
    ],
    [data]
  );

  const handleCheckboxChange = (
    code: string | null,
    type: number,
    checked: boolean
  ) => {
    setSelectedCatSegment((prevSelectedCatSegment) => {
      let updatedSelectedRows;

      if (checked && code) {
        updatedSelectedRows = [
          ...prevSelectedCatSegment.filter((row) => row.code !== code),
          { code, type, checked },
        ];
      } else {
        updatedSelectedRows = prevSelectedCatSegment.filter(
          (row) => row.code !== code
        );
      }

      return updatedSelectedRows;
    });
  };
  const handleItemCheckboxChange = (item: tblItemByCat[]) => {
    setSelectItem(item);
  };

  const handleConfirmAddItem = () => {
    const setting = formAdd.getValues();
    if (dataDelete && dataDelete.length > 0) {
      if (tableDelete.getSelectedRowModel().rows.length > 0) {
        const rowSelected = tableDelete
          .getSelectedRowModel()
          .rows?.map((row) => ({
            itemId: Number(row.original?.idItemPrimary),
            itemCode: row.original?.itemPrimaryCode?.toString() ?? "",
            itemName: row.original?.itemPrimaryName?.toString() ?? "",
            brandId: row.original?.brandId ?? 0,
            isOnhand: row.original?.isOnhand ?? false,
            itemShortName: row.original?.itemShortName?.toString() ?? "",
            segmentId: row.original?.segmentId ?? 0,
            segmentName: row.original?.segmentName?.toString() ?? "",
            quantityMin: setting.quantityMin,
            quantityMax: setting.quantityMax,
            quantityApply: setting.quantityApply,
            discountPercent: setting.discountPercent,
            discountAmount: setting.discountAmount,
            priceMin: setting.quantityMin,
            priceMax: setting.quantityMax,
          }));
        handleAddSearchItem(rowSelected);
      }
    }
    const newItem: Array<tblItemByCat> = selectItem?.map((row) => ({
      itemId: Number(row.itemId),
      itemCode: row.itemCode ?? "",
      itemName: row.itemName ?? "",
      brandId: row.brandId ?? 0,
      isOnhand: row.isOnhand,
      itemShortName: row?.itemShortName?.toString() ?? "",
      segmentId: row.segmentId ?? 0,
      segmentName: row.segmentName?.toString() ?? "",
      quantityMin: setting.quantityMin,
      quantityMax: setting.quantityMax,
      quantityApply: setting.quantityApply,
      discountPercent: setting.discountPercent,
      discountAmount: setting.discountAmount,
      priceMin: setting.quantityMin,
      priceMax: setting.quantityMax,
    }));
    handleAddSearchItem(newItem);
  };

  // const handleKeyDown = (e: any) => {
  //   if (e.key === "Enter") {
  //     activePage === 1 ? fetchDataSearchItem() : setPage(1);
  //   }
  // };

  const handleOnChange = (e: any) => {
    const value = e.target.value.toLowerCase() ?? "";
    setKeySearch(value);
    if (value) {
      const searchResult = dataTemp.filter(
        (product) =>
          product.itemPrimaryCode?.toLowerCase().includes(value) ||
          product.itemPrimaryName?.toLowerCase().includes(value)
      );
      setRowCount(searchResult.length);
      setSelectIds([]);
      tableDelete.resetRowSelection();
      setData(searchResult);
    } else {
      setRowCount(dataTemp.length);
      setSelectIds([]);
      tableDelete.resetRowSelection();
      setData(dataTemp);
    }
  };

  // const fetchDataSearchItem = async () => {
  //   openItem();
  //   let url = `/api/v1/TblItem/get-list-by-cat?Skip=${
  //     50 * (activePage - 1)
  //   }&Take=50`;

  //   if (keySearchItem) {
  //     url += `&KeySearch=${keySearchItem}`;
  //   }
  //   const dataApi = await repositoryPos.get<MessageResponse<tblItemByCat[]>>(
  //     url
  //   );

  //   if (dataApi?.success && dataApi.data) {
  //     setDataItem(dataApi.data);
  //     setDataItemTemp(dataApi.data);
  //     setTotalCount(Math.ceil(dataApi.totalCount / 50));
  //   } else {
  //     setDataItem([]);
  //     setDataItemTemp([]);
  //     setSelectItem([]);
  //     setTotalCount(0);
  //   }
  //   closeItem();
  // };

  const mapSegments = (array: { code: string; type: number }[]) => {
    const segments: { [key: string]: string[] } = {
      segment1: [],
      segment2: [],
      segment3: [],
      segment4: [],
      segment5: [],
      segment6: [],
    };

    array?.forEach((item) => {
      const segmentKey = `segment${item?.type}`;

      if (segments[segmentKey]) {
        segments[segmentKey].push(item?.code);
      }
    });

    return segments;
  };

  const handleFetchItemFormCat = async (_dataSegment: SelectSegment[]) => {
    if (!_dataSegment || _dataSegment.length === 0) {
      setIsGetByCat(true);
      setDataItem([]);
      setDataTableItems([]);
      setDataItemTemp([]);
      setTotalCount(0);
      setRowSelectionTableItems({});
      return;
    }

    let url = `/api/v1/TblPromotion/get-list-item`;

    const dataSegment = _dataSegment.filter(
      (item) => item !== null && item !== undefined
    );

    const dataSubmit = {
      keySearch: "",
      sort: "",
      isOrder: true,
      active: true,
      skip: 50 * (activePage - 1),
      take: 50,
      ...mapSegments(dataSegment),
    };

    const dataApi = await repositoryPos.post<MessageResponse<any[]>>(
      url,
      dataSubmit
    );
    if (dataApi?.success && dataApi.data) {
      if (dataApi?.data?.length < 1) {
        NotificationExtension.Warn("Không có sản phẩm tồn kho !");
        return;
      }
      NotificationExtension.Success("Thêm sản phẩm thành công !");

      const newData: tblItemByCat[] = dataApi?.data.map((item) => ({
        ...entity,
        itemId: item.itemId,
        itemCode: item.itemCode,
        itemName: item.itemName,
      }));
      setIsGetByCat(true);
      setDataItem(newData);
      setDataTableItems(newData);
      setDataItemTemp(newData);
      setTotalCount(Math.ceil(dataApi.totalCount / 50));
      if (newData?.length > 0) {
        const res: MRT_RowSelectionState = {};
        for (let index = 0; index < newData.length; index++) {
          const element = newData[index];
          res[element.itemId.toString()] = true;
        }
        setRowSelectionTableItems(res);
      }
    } else {
      setDataItem([]);
      setDataItemTemp([]);
      setSelectItem([]);
      setTotalCount(0);
      setIsGetByCat(false);
    }
  };

  const tableDelete = useMantineReactTable({
    columns: columnsDelete,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input placeholder="Nhập từ khóa ..." onChange={handleOnChange} />
        </Flex>
      </div>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row: any) => row?.original?.idItemPrimary?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: true, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 400, minHeight: 400 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  // useEffect(() => {
  //   if (!dataDelete && !isGetByCat) {
  //     fetchDataSearchItem();
  //   }
  // }, [activePage]);

  //#region  menu phải

  const columnsTableItems: MRT_ColumnDef<tblItemByCat>[] = React.useMemo(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã",
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên",
        enableColumnFilter: false,
        enableColumnActions: false,
      },
    ],
    []
  );

  const [dataTableItems, setDataTableItems] = useState<tblItemByCat[]>([]);
  // const [rowCount, setRowCount] = useState(0);
  const [paginationTableItems, setPaginationTableItems] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const [rowSelectionTableItems, setRowSelectionTableItems] =
    useState<MRT_RowSelectionState>({});

  const [keySearchTableItems, setKeySearchTableItems] = useState<string>("");
  const [checkDuplicateCodeTableItems, setCheckDuplicateCodeTableItems] =
    useState<string[]>([]);
  const [dataItemSearchTableItems, setDataItemSearchTableItems] = useState<
    ItemData[]
  >([]);

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} maw={550} h={200} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItemSearchTableItems?.map((item) => {
            return (
              <Table.Tr
                key={item.itemCode}
                bg={
                  checkDuplicateCodeTableItems.some(
                    (select) => select === item.itemCode
                  )
                    ? "var(--mantine-color-blue-light)"
                    : undefined
                }
              >
                <Table.Td>
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      handleAddItemTableItems(item);
                    }}
                    disabled={checkDuplicateCodeTableItems.includes(
                      item.itemCode
                    )}
                  >
                    {checkDuplicateCodeTableItems.includes(item.itemCode ?? "")
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                </Table.Td>
                <Table.Td>{item.itemCode}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.itemName}>
                    <Text size="sm" w={250} lineClamp={2} truncate="end">
                      {item.itemName}
                    </Text>
                  </Tooltip>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
        {dataItemSearchTableItems.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  const deepCompare = (obj1: any, obj2: any) => {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return obj1 === obj2;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const fetchDataItem = async () => {
    if (keySearchTableItems) {
      try {
        const response = await repositoryPos.get<MessageResponse<ItemData[]>>(
          `/api/v1/TblItem/get-list-by-cat?Skip=0&Take=25&KeySearch=${keySearchTableItems}`
        );

        setDataItemSearchTableItems(response?.data ?? []);
      } catch (error) {
        setDataItemSearchTableItems([]);
      }
    }
  };

  const handleAddItemTableItems = (dataAdd: ItemData) => {
    const _add: tblItemByCat = {
      brandId: dataAdd.brandId,
      itemShortName: dataAdd.itemShortName,
      itemId: dataAdd.itemId,
      itemCode: dataAdd.itemCode,
      itemName: dataAdd.itemName,
      isOnhand: null,
      segmentName: null,
      segmentId: null,
      quantityMin: null,
      quantityMax: null,
      quantityApply: null,
      discountPercent: null,
      discountAmount: null,
      priceMin: null,
      priceMax: null
    };
    setDataTableItems((prevData) => [...prevData, { ..._add }]);
    setCheckDuplicateCodeTableItems((prev) => [...prev, _add.itemCode ?? ""]);
    if (_add) {
      const res: MRT_RowSelectionState = {};
      res[dataAdd.itemId.toString()] = true;
      setRowSelectionTableItems((x) => ({ ...x, ...res }));
    }
  };

  const fetchDataTableItems = async (data?: tblItemByCat[]) => {
    open();
    try {
      if (data && data.length > 0) {
        setDataTableItems(data);
      } else if (dataItem && dataItem.length > 0) {
        setDataTableItems((prev) => [...prev, ...dataItem]);
        //  setRowCount([...data, ...result].length);
        // setSelectIds([]);
        // table.resetRowSelection();
      }
    } catch (error) {
      setDataTableItems([]);
      console.error(error);
      return;
    } finally {
      close();
    }
  };

  useEffect(() => {
    const selectedRows = tableTableItems.getSelectedRowModel().rows;
    const selectedOriginalData = selectedRows.map((row) => row.original);
    handleItemCheckboxChange(selectedOriginalData);
  }, [rowSelectionTableItems]);

  // useEffect(() => {
  //   const updatedRowSelection = dataTableItems?.reduce(
  //     (acc: { [key: string]: boolean }, _, index) => {
  //       acc[index] = true;
  //       return acc;
  //     },
  //     {}
  //   );
  //   tableTableItems.setRowSelection(updatedRowSelection);
  // }, [dataTableItems, dataItem]);

  // useEffect(() => {
  //   fetchDataTableItems();
  // }, [dataItem]);

  useEffect(() => {
    if (keySearchTableItems && keySearchTableItems.length > 2) {
      fetchDataItem();
    } else {
      setDataItemSearchTableItems([]);
    }
  }, [keySearchTableItems]);

  const tableTableItems = useMantineReactTable<tblItemByCat>({
    columns: columnsTableItems,
    data: dataTableItems,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    enableRowVirtualization: true,
    enableBatchRowSelection: true,
    enablePagination: true,
    rowVirtualizerOptions: { overscan: 8 },
    selectAllMode: "all",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: {
      ..._localization,
      selectedCountOfRowCountRowsSelected: "",
      clearSelection: "",
    },
    manualPagination: false,
    enableRowSelection: true,
    // enableTopToolbar: false,
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 380, minHeight: 380 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelectionTableItems,
    onPaginationChange: setPaginationTableItems,
    //  rowCount,
    state: {
      rowSelection: rowSelectionTableItems,
      pagination: paginationTableItems,
    },
    mantinePaginationProps: {
      showRowsPerPage: false,
      withEdges: true,
      // rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "default",
    enableTopToolbar: false,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion

  const handleSearch = useDebouncedCallback(async (query: string) => {
    setKeySearchTableItems(query);
  }, 500);

  const iconStyle = { width: rem(12), height: rem(12) };
  const formAdd = useForm({
    validateInputOnChange: true, // Kích hoạt kiểm tra đầu vào khi thay đổi
    initialValues: {
      discountPercent: null, // Giá trị mặc định cho % chiết khấu
      discountAmount: null, // Giá trị mặc định cho số tiền chiết khấu
      quantityMin: null, // Giá trị mặc định cho số lượng tối thiểu
      quantityMax: null, // Giá trị mặc định cho số lượng tối đa
      quantityApply: null, // Giá trị mặc định cho số lượng áp dụng
    },
    validate: {
      discountPercent: (value, values) => {
        if (value) {
          if (value < 0 || value > 100) {
            return "% chiết khấu phải từ 0 đến 100";
          }
          if (values.discountAmount) {
            return "Không được nhập cả % chiết khấu và số tiền chiết khấu";
          }
        }
        return null;
      },
      discountAmount: (value, values) => {
        if (value) {
          if (value <= 0) {
            return "Số tiền chiết khấu phải lớn hơn 0";
          }
          if (values.discountPercent) {
            return "Không được nhập cả % chiết khấu và số tiền chiết khấu";
          }
        }
        return null;
      },
      quantityMin: (value) =>
        value && value <= 0 ? "Số lượng tối thiểu phải lớn hơn 0" : null,
      quantityMax: (value, values) => {
        if (value) {
          if (value <= 0) {
            return "Số lượng tối đa phải lớn hơn 0";
          }
          if (values.quantityMin && value < values.quantityMin) {
            return "Số lượng tối đa không được nhỏ hơn số lượng tối thiểu";
          }
        }
        return null;
      },
      quantityApply: (value) =>
        value && value <= 0 ? "Số lượng áp dụng phải lớn hơn 0" : null,
    },
  });

  return (
    <>
      {dataDelete && dataDelete?.length > 0 ? (
        <MantineReactTable table={tableDelete} />
      ) : (
        <Grid>
          <Grid.Col span={{ base: 12, md: 5, xs: 5 }}>
            <Fieldset
              legend="Tìm nhóm hàng"
              mt={10}
              h={"98%"}
              style={{ position: "relative" }}
            >
              {/* {selectItem.length > 0 && (
                <Overlay color="#000" backgroundOpacity={0.55} />
              )} */}
              <TableSegment
                // handleCheckboxChange={handleCheckboxChange}
                handleFetchItemFormCat={handleFetchItemFormCat}
              ></TableSegment>
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 7, xs: 7 }}>
            <Fieldset
              legend="Tìm mã hàng"
              mt={10}
              style={{ position: "relative" }}
            >
              {/* {dataItem?.length === 0 && (
                <Overlay color="#000" backgroundOpacity={0.5} />
              )} */}
              {/* <TableItems
                dataItem={dataItem}
                handleCheckboxChange={handleItemCheckboxChange}
                isGetByCat={isGetByCat}
              ></TableItems> */}

              <Tabs defaultValue="gallery">
                <Tabs.List>
                  <Tabs.Tab
                    value="gallery"
                    leftSection={<IconPhoto style={iconStyle} />}
                  >
                    Danh sách
                  </Tabs.Tab>
                  {typeAdd === 1 && (
                    <Tabs.Tab
                      value="messages"
                      leftSection={<IconSettings style={iconStyle} />}
                    >
                      Cấu hình
                    </Tabs.Tab>
                  )}
                </Tabs.List>

                <Tabs.Panel value="gallery">
                  <Box mt={10} w={"95%"}>
                    <LoadingOverlay
                      visible={visible}
                      zIndex={1000}
                      overlayProps={{ radius: "sm", blur: 2 }}
                    />
                    <Grid align="center">
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                        <MRT_GlobalFilterTextInput
                          disabled={dataTableItems.length === 0}
                          table={tableTableItems}
                          placeholder="Nhập tên sản phẩm"
                          rightSection={
                            <ActionIcon variant="light">
                              <IconSearch size={14} />
                            </ActionIcon>
                          }
                          leftSection={<></>}
                          styles={{
                            input: {
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              paddingLeft: "10px",
                            },
                          }}
                          size="xs"
                          w={"100%"}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                        <Menu
                          trapFocus={false}
                          trigger="hover"
                          shadow="md"
                          width={"800"}
                          position="bottom-start"
                        >
                          <Menu.Target>
                            <TextInput
                              placeholder="Tìm kiếm để thêm sản phẩm"
                              size="xs"
                              onChange={async (e) => {
                                const _key = e.target.value ?? "";
                                handleSearch(_key);
                              }}
                            />
                          </Menu.Target>
                          <Menu.Dropdown maw={550}>
                            <TableSelect />
                          </Menu.Dropdown>
                        </Menu>
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}></Grid.Col>
                    </Grid>

                    <Box p={"10px 0px"}>
                      <MantineReactTable table={tableTableItems} />
                    </Box>
                  </Box>
                </Tabs.Panel>

                <Tabs.Panel value="messages">
                  {" "}
                  <Box
                    mt={10}
                    w={"95%"}
                    component="form"
                    onSubmit={formAdd.onSubmit((e) => {
                      console.log(e);
                    })}
                  >
                    {/* <LoadingOverlay
                      visible={visible}
                      zIndex={1000}
                      overlayProps={{ radius: "sm", blur: 2 }}
                    /> */}

                    <Grid align="center">
                      <Grid.Col span={6}>
                        <TextInput
                          label={"% chiết khấu"}
                          placeholder={"Nhập %"}
                          type="number"
                          key={formAdd.key("discountPercent")}
                          {...formAdd.getInputProps("discountPercent")}
                        />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <NumberInput
                          label={"Số tiền chiết khấu"}
                          placeholder={"Nhập số tiền"}
                          hideControls
                          thousandSeparator="."
                          decimalSeparator=","
                          key={formAdd.key("discountAmount")}
                          {...formAdd.getInputProps("discountAmount")}
                        />
                      </Grid.Col>

                      <Grid.Col span={6}>
                        <NumberInput
                          label={"Số Lượng tối thiểu"}
                          placeholder={"Nhập số lượng"}
                          hideControls
                          key={formAdd.key("quantityMin")}
                          {...formAdd.getInputProps("quantityMin")}
                        />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <NumberInput
                          label={"Số Lượng tối đa"}
                          placeholder={"Nhập số lượng"}
                          hideControls
                          key={formAdd.key("quantityMax")}
                          {...formAdd.getInputProps("quantityMax")}
                        />
                      </Grid.Col>

                      <Grid.Col span={6}>
                        <TextInput
                          label={"Số Lượng áp dụng"}
                          placeholder={"Nhập số lượng"}
                          type="number"
                          key={formAdd.key("quantityApply")}
                          {...formAdd.getInputProps("quantityApply")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Box>
                </Tabs.Panel>
              </Tabs>
            </Fieldset>
          </Grid.Col>
        </Grid>
      )}

      <Flex align={"center"} justify={"end"} gap={10} mt={10}>
        <Button
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={<IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          leftSection={<IconCheck size={18} />}
          onClick={() => {
            handleConfirmAddItem();
          }}
        >
          Xác nhận
        </Button>
      </Flex>
    </>
  );
};

export default ModalSearchItems;
