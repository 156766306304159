import {
  Box,
  Button,
  Flex,
  NumberInput,
  ScrollArea,
  Table,
} from "@mantine/core";
import { useEffect, useState } from "react";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { IconCheck } from "@tabler/icons-react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";

const UpdateWarrantyTerm = ({
  dataSerial,
  setDeleteViewStatus,
}: {
  dataSerial: any;
  setDeleteViewStatus: any;
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [dataSubmit, setDataSubmit] = useState<any[]>([]);

  const handleChange = (serial: string, updates: Record<string, any>) => {
    const updatedData = dataSubmit.map((item: any) =>
      item.serial?.toString() === serial?.toString()
        ? {
            ...item,
            ...updates,
          }
        : item
    );
    setDataSubmit(updatedData);
  };

  const updateWarrantyTerm = async () => {
    const res = await repositoryDelivery.post<MessageResponse<any>>(
      "/api/v1/TblWarrantyItem/create",
      dataSubmit?.filter(
        (item) => item.warrantyTerm !== null && item.warrantyTerm !== 0
      )
    );

    if (res && res?.success) {
      NotificationExtension.Success("Cập nhật hạn BH hoàn tất !");
      setTimeout(() => {
        modals.closeAll();
        setDeleteViewStatus((prev: any) => !prev);
      }, 1000);
    }
  };

  useEffect(() => {
    const updateDataSubmit = dataSerial?.map((item: any) => ({
      id: item.id,
      itemId: item.itemId,
      orderId: 0,
      serial: item?.soSerial,
      parentSerial: null,
      itemCode: item?.soItemCode,
      itemName: item?.soItemName,
      buyingDate: null,
      orderNumber: null,
      warrantyTerm: 0,
      custId: 0,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
    }));
    setDataSubmit(updateDataSubmit);
  }, [dataSerial]);

  return (
    <Box
      mx="auto"
      w={"82.5vw"}
      maw={1250}
    >
      <ScrollArea
        h={500}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
          mt={10}
        >
          <Table.Thead
            className={cx(classes.header, {
              [classes.scrolled]: scrolled,
            })}
          >
            <Table.Tr>
              <Table.Th>STT</Table.Th>
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Serial</Table.Th>
              <Table.Th>Tên sản phẩm</Table.Th>
              <Table.Th>Đơn vị tính</Table.Th>
              <Table.Th>Hạn BH</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataSerial?.map((item: any, index: any) => (
              <Table.Tr key={index}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td>{item?.soItemCode}</Table.Td>
                <Table.Td>{item?.soSerial}</Table.Td>
                <Table.Td>{item?.soItemName}</Table.Td>
                <Table.Td>{item?.uomName}</Table.Td>
                <Table.Td>
                  <NumberInput
                    placeholder="Nhập hạn BH mới của sản phẩm"
                    allowNegative
                    hideControls
                    min={0}
                    suffix=" tháng"
                    size="xs"
                    onChange={(e) =>
                      handleChange(dataSubmit[index]?.serial, {
                        warrantyTerm: e ? Number(e) : null,
                      })
                    }
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
      <Flex
        justify={"end"}
        mt={10}
        onClick={() => updateWarrantyTerm()}
      >
        <Button leftSection={<IconCheck size={14} />}>Lưu</Button>
      </Flex>
    </Box>
  );
};

export default UpdateWarrantyTerm;
