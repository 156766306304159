import { Box, Button, Flex, Grid, TextInput } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { IconCheck } from "@tabler/icons-react";
import { useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { modals } from "@mantine/modals";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

const ModalVAT = ({
  orderNumber,
  onClose,
}: {
  orderNumber: any;
  onClose: any;
}) => {
  const [dataSubmit, setDataSubmit] = useState<any>({
    orderNumber: orderNumber,
    writeDateNumberOrder: new Date()?.toISOString(),
    writeNumberOrder: null,
  });

  const handleCreateVat = async () => {
    const res = await repositoryMdm.post<MessageResponse<any>>(
      "/api/v1/TblOrderHeaderVat/create-Vat",
      [dataSubmit]
    );

    if (res && res?.success) {
      setTimeout(() => {
        modals.closeAll();
        onClose((prev: any) => !prev);
      }, 1000);
      NotificationExtension.Success("Viết hóa đơn VAT hoàn tất !");
    }
  };

  return (
    <Box>
      <Grid>
        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
          <TextInput
            label="Mã hóa đơn"
            value={orderNumber}
            variant="filled"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
          <DateTimePicker
            label="Ngày viết đơn"
            minDate={new Date()}
            valueFormat="DD-MM-YYYY hh:mm A"
            defaultValue={
              dataSubmit?.writeDateNumberOrder
                ? new Date(dataSubmit?.writeDateNumberOrder ?? "")
                : null
            }
            onChange={(e) =>
              setDataSubmit((prev: any) => ({
                ...prev,
                writeDateNumberOrder: e
                  ? new Date(e ?? "").toISOString()
                  : null,
              }))
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
          <TextInput
            label={"Số hóa đơn"}
            value={dataSubmit?.writeNumberOrder}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setDataSubmit((prev: any) => ({
                ...prev,
                writeNumberOrder: value,
              }));
            }}
          />
        </Grid.Col>
      </Grid>
      <Flex justify={"end"} w={"100%"}>
        <Button
          onClick={() => handleCreateVat()}
          variant="outline"
          leftSection={<IconCheck size={14} />}
          disabled={
            dataSubmit.writeNumberOrder === null ||
            dataSubmit.writeNumberOrder === ""
          }
        >
          Xác nhận
        </Button>
      </Flex>
    </Box>
  );
};

export default ModalVAT;
