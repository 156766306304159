import {
  Badge,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Input,
  NumberFormatter,
  Select,
  Text,
  Title,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconBrandComedyCentral,
  IconFileText,
  IconMapPin,
  IconNumbers,
  IconPhone,
  IconPlus,
  IconSearch,
  IconUser,
  IconUsers,
  IconUsersGroup,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { cobalt_blue, green } from "../../../../const/variables";
import { TblDMCustomerNew } from "../../../../model/TblDmCustomer";
import {
  getProvinceSelect,
  getTblDmCustomerGroupSelect,
} from "../../../../service/getSelectApi";
import CreateDataView from "../../../CustomerManagement/CustomerList/CreateDataView";

const DebtObjectCodeFund = ({
  handleChooseCustomer,
  navigate,
  isChooseCongNo,
  openFormCustomer,
  disible,
}: {
  handleChooseCustomer: Function;
  navigate?: any;
  isChooseCongNo: boolean;
  openFormCustomer?: any;
  disible?: boolean;
}) => {
  const [data, setData] = useState<TblDMCustomerNew[]>([]);
  const [dataTemp, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [customerInfo, setCustomerInfo] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [search, setSearch] = useState({
    keySearch: "",
    Phone: "",
    TaxCode: "",
    Code: "",
  });

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );
  const headerRef = React.useRef<HTMLDivElement>(null);

  const [dataTblCustomerGroup, setDataTblCustomerGroup] = useState<any[]>([]);
  const gridSpan = { base: 12, sm: 1.5, md: 1.5, lg: 1.5, xl: 1.5 };
  const columns = React.useMemo<MRT_ColumnDef<TblDMCustomerNew>[]>(
    () =>
      [
        {
          accessorKey: "index",
          header: "STT",
          enableSorting: false,
          enableColumnActions: false,
          enableColumnFilter: false,
          Cell: ({ row }) => (
            <Text size="12.5px">{row.index === -1 ? "" : row.index + 1}</Text>
          ),
          size: 50,
        },
        {
          accessorKey: "groupCode",
          header: "Mã đối tượng công nợ",
          Cell: ({ renderedCellValue, row }) => (
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          ),
          enableSorting: false,
          enableColumnActions: false,
          enableColumnFilter: false,
          size: 250,
        },
        {
          accessorKey: "groupName",
          header: "Tên đối tượng công nợ",
          Cell: ({ renderedCellValue }: any) => (
            <Text size="12.5px" fw={"bold"}>
              {renderedCellValue}
            </Text>
          ),
          size: 550,
          enableSorting: false,
          enableColumnActions: false,
          enableColumnFilter: false,
        },
        {
          accessorKey: "debtTerm",
          header: "Thời hạn",
          enableSorting: false,
          enableColumnActions: false,
          enableColumnFilter: false,
          Cell: ({ renderedCellValue }: any) => (
            <Text size="12.5px" fw={600}>
              {renderedCellValue}
            </Text>
          ),
        },
        {
          accessorKey: "debtLimit",
          header: "Hạn mức",
          Cell: ({ renderedCellValue }: any) => (
            <Text fw={600}>
              <NumberFormatter
                thousandSeparator={","}
                value={renderedCellValue}
              ></NumberFormatter>
            </Text>
          ),
          enableSorting: false,
          enableColumnActions: false,
          enableColumnFilter: false,
        },
        // {
        //   accessorKey: "action",
        //   header: "Thao tác",
        //   enableSorting: false,
        //   enableColumnActions: false,
        //   enableColumnFilter: false,
        //   Cell: ({ row }) => (
        //     <Tooltip label="Chọn">
        //       <ActionIcon
        //         variant="light"
        //         aria-label="Settings"
        //         color="teal"
        //         onClick={() => {
        //           handleChooseCustomer(row.original);
        //           modals.closeAll();
        //         }}
        //       >
        //         <IconCheckbox size={20} stroke={1.5} />
        //       </ActionIcon>
        //     </Tooltip>
        //   ),
        //   size: 50,
        // },
      ] as MRT_ColumnDef<(typeof data)[0]>[],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const renderTypeName = (value: number) => {
    switch (value) {
      case 1:
        return "Cá nhân";
      case 2:
        return "Doanh nghiệp";
      case 3:
        return "Bán buôn";
      case null:
        return "";
    }
  };

  const dataCustomerGroupSelect = async () => {
    try {
      const getData = await getTblDmCustomerGroupSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
        type: item?.att1,
      }));
      setDataTblCustomerGroup(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Type=${3}&Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.Code) {
      url += `&Code=${search.Code}`;
    }
    if (search.TaxCode) {
      url += `&TaxCode=${search.TaxCode}`;
    }
    if (search.Phone) {
      url += `&Phone=${search.Phone}`;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDebtGroup/get-list${url}`
      );
      if (response && response.success) {
        let result = response?.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response?.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const getDataProvinceId = async () => {
    const res = await getProvinceSelect();
    setDataProvinceSelect(
      res
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  function createCustomer() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Tạo mới khách hàng!</Title>
        </>
      ),
      size: "auto",
      children: (
        <CreateDataView
          onClose={setDeleteViewStatus}
          handleChooseCustomer={handleChooseCustomer}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["index", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: { pageSize: 50, pageIndex: 0 },
    },
    mantineTableContainerProps: {
      style: { maxHeight: "62.5vh", minHeight: "300px" },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: (e) => {
      console.log("sdsdsds", e);
      setPagination(e);
    },
    // manualFiltering: true,
    manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // mantineTableBodyRowProps: ({ row }) => ({
    //   onClick: row.getToggleSelectedHandler(),
    //   sx: { cursor: "pointer" },
    // }),
    mantineTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        handleChooseCustomer(row.original, isChooseCongNo, openFormCustomer);
        modals.closeAll();
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
  });

  return (
    <>
      <div
        ref={headerRef}
        style={{
          width: "100%",
          border: "1px solid #dee2e6",
          padding: "10px",
        }}
      >
        <Grid>
          <Grid.Col span={{ base: 12 }}>
            <Grid align="center" gutter={"xs"}>
              <Grid.Col span={gridSpan}>
                <Input
                  flex={1}
                  placeholder="Nhập từ khóa tìm kiếm"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconSearch
                      size={"20"}
                      color={cobalt_blue.base}
                    ></IconSearch>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={gridSpan}>
                <Input
                  flex={1}
                  placeholder="Nhập mã công nợ"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconUsers size={"20"} color={cobalt_blue.base}></IconUsers>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "Code");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col>
              {/* <Grid.Col span={gridSpan}>
                <Input
                  flex={1}
                  placeholder="Nhập mã số thuế"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconFileText
                      size={"20"}
                      color={cobalt_blue.base}
                    ></IconFileText>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "TaxCode");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={gridSpan}>
                <Input
                  flex={1}
                  placeholder="Nhập số điện thoại"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconPhone size={"20"} color={cobalt_blue.base}></IconPhone>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "Phone");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col> */}
              <Grid.Col span={gridSpan}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
      <div style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DebtObjectCodeFund;
