import { Box, Button, Flex, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";

const ContinueCreate = ({
  changeValue,
  dataSerial,
}: {
  changeValue: any;
  dataSerial: any;
}) => {
  return (
    <Box pt={10}>
      {dataSerial && dataSerial.length === 1 ? (
        <>
          <Text
            fw={500}
            size="18px"
          >
            Serial {dataSerial[0]?.serial} đã tồn tại ở chi nhánh{" "}
            {dataSerial[0]?.branchName}! Bạn có muốn tiếp tục tiếp nhận yêu cầu
            ?
          </Text>
          <Flex
            justify={"end"}
            gap={"md"}
            mt={15}
          >
            <Button
              color="red"
              leftSection={<IconX size={14} />}
              onClick={() => modals.closeAll()}
            >
              Hủy
            </Button>
            <Button
              color="green"
              leftSection={<IconCheck size={14} />}
              onClick={() => {
                modals.closeAll();
                changeValue();
              }}
            >
              Tiếp tục
            </Button>
          </Flex>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ContinueCreate;
