import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Kbd,
  Menu,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  Switch,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCheck,
  IconGripVertical,
  IconMaximize,
  IconMaximizeOff,
  IconMinus,
  IconPlus,
  IconRowInsertTop,
  IconSearch,
  IconTrash,
  IconX,
} from "@tabler/icons-react";

import { notifications } from "@mantine/notifications";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { TblDMCustomer } from "../../../../model/TblDMCustomerSell";
import { SelectListItemBase } from "../../../../_base/model/_base/SelectListItemBase";
import { EmployeeModel } from "../../../../model/Employee";
import { TblDebtGroup } from "../../../../model/TblDebtGroup";
import { modals } from "@mantine/modals";
import AddFormDebt from "../../AddFormDebt/AddFormDebt";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMPaymentMethod } from "../../../../model/TblDMPaymentMethod";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import EditableTextWithOptions from "../../../../common/selectValue";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import {
  useDebouncedState,
  useDebouncedValue,
  useDisclosure,
  useHotkeys,
  useMediaQuery,
} from "@mantine/hooks";

import BorrowedReceipt from "../FormBill/BorrowedReceipt/BorrowedReceipt";
import {
  AccountantAccount,
  CreateReceiptParentRequest,
  DebtData,
  DepartmentCustomerOrder,
  DepartmentUser,
  FundDetails,
  Permission,
  Program,
  RowErrors,
  User,
  UserData,
} from "../CreditAdvice/model/model";
import ReturnOrderChildren from "../FormBill/ReturnOrderChildren/ReturnOrderChildren";
import classesButton from "../buttonDisible.module.css";
import { IconArrowLeft } from "@tabler/icons-react";
import CustomerInformation from "../../RetailOrder/ModalComponent/ModalCustomerInformation";
import DebtObjectCodeFund from "../DebtObject/DebtObject";

const dataExchangeRate = [
  {
    calculation: "Tiền mặt VND tại CN",
    currencyType: "VND",
    value: 1,
    id: 111101,
  },
  {
    calculation: "Tiền mặt ngoại tệ tại Cty",
    currencyType: "USD",
    value: 24200,
    id: 111210,
  },
  {
    calculation: "Vàng bạc, kim khí quý, đá quý",
    currencyType: "GOLD",
    value: 7800000,
    id: 111301,
  },
];

const DebtNoteBranchs = () => {
  const navigate = useNavigate();
  const userName = localStorage.getItem("userName") || "";
  const [userData, setUserData] = useState<UserData | null>(null);
  const [listdata, setListData] = useState<FundDetails>();
  const [isRunning, setIsRunning] = useState<boolean>(true);
  const [newDate, setNewDate] = useState<Date | null>(new Date());
  const [height, setHeight] = useState(0);
  const [branchDeparment, setBranchDeparment] = useState<DepartmentUser>();
  const [branchAll, setBranchAll] = useState<DepartmentUser[]>([]);

  const [statusReponse, setStatusReponse] = useState<any>();
  const [changeFielselect, setChangeFielselect] = useState<any>();
  const [data, setData] = useState<TblDMCustomer[]>([]);
  const [customerOrders, setCustomerOrders] = useState<
    DepartmentCustomerOrder[]
  >([]);
  const [selectedCalculation, setSelectedCalculation] = useState<string>("VND");
  const [dataGetDepartments, setDataGetDepartments] = useState<
    SelectListItemBase[]
  >([]);
  const [handleFullName, setHandleFullName] = useState<String | null>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [stoppedTime, setStoppedTime] = useState<Date | null>();
  const [valueDataLocation, setValueDataLocation] = useState<string>("");
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [listEmployee, setListEmployee] = useState<EmployeeModel[]>([]);
  const [listTblDmEmployee, setListTblDmEmployee] = useState<User>();
  const [listTblBranch, setListTblBranch] = useState<User>();
  const [handelAttrible, setHandelAttrible] = useState<boolean>(false);
  const [handlePayertype, setHandlePayertype] = useState<boolean>(false);
  const [handleOther, setHandleOther] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<Date | null>();
  const [completeTime, setCompleteTime] = useState<Date | null>(null);
  const [inputValues, setInputValues] = useState<{ [key: number]: any }>({});
  const [handelDateCurrent, setHandelDateCurrent] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(1);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);
  const [dataDepartments, setDataDepartments] = useState<SelectListItemBase[]>(
    []
  );
    const [valueSeachKHNCC, setValueSeachKHNCC] = useDebouncedState("", 1000);
    const [dataGetReceiptContract, setDataGetReceiptContract] = useState<
      Program[]
    >([]);
    const [debtData, setDebtData] = useState<DebtData[]>([]);
    const [dataGetReceiptFee, setDataReceiptFee] = useState<Permission[]>([]);
    const [dataAcount, setDataAcount] = useState<AccountantAccount[]>([]);
    const [isCollapse, { toggle: toggleCollapse }] = useDisclosure(false);
    const [occurringCredits, setOccurringCredits] = useState<number>(0);
    const [dataBranch, setDataBranch] = useState<SelectListItemBase[]>([]);
    const [filteredData, setFilteredData] = useState(dataCodeFund);
    const [dataTblRole, setDataTblRole] = useState<any[]>([]);
    const [fundepartmentId, setFundepartmentId] = useState<number | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [departmentsByBranch, setDepartmentsByBranch] = useState<
      Record<number, EmployeeModel>
    >({});
    const userString = localStorage.getItem("userLogin");
    const [inputValueDept, setInputValueDept] = useState("");

    const isSmallScreen = useMediaQuery("(max-width: 900px)", true, {
      getInitialValueInEffect: false,
    });
    const TotalAmountConverted = (occurringCredits ?? 0) * (selectedValue ?? 0);
    const [errors, setErrors] = useState<{ [index: number]: RowErrors }>({});

    const [formErrors, setFormErrors] = useState({
      payerfullname: "",
      accountfund: 1,
      codefund: "",
      createby: 0,
      createdDate: "",
      creatorbranch: "",
      creatordepartment: "",
      creatorfullname: "",
      creatorsalesbranch: "",
      creatorworkplace: "",
      fundbranch: "",
      attribute2: "",
      attribute3: "",
      attribute6: "",
      attribute8: "",
      funddepartment: "",
      fundsalesbranch: "",
      fundworkplace: "",
      funfullname: "",
      id: 0,
      payerdescription: "",
      payernote: "",
      codeCustomer: "",
      anotherName: "",
      phoneNumber: "",
      payertype: "",
      receiptNumber: "",
      totalAmount: "",
      type: 0,
    });

    const [formData, setFormData] = useState({
      accountfund: 111101,
      codefund: "",
      createby: 0,
      createdDate: moment(stoppedTime, "DD-MM-YYYY HH:mm:ss")
        .add(7, "hours")
        .toISOString(),
      completiontime: moment(currentTime, "DD-MM-YYYY HH:mm:ss")
        .add(7, "hours")
        .toISOString(),
      creatorbranch: userData?.branchId,
      creatordepartment: userData?.depName,
      creatorsalesbranch: null,
      creatorworkplace: "129 Lê Thanh Nghị",
      fundbranch: "",
      funddepartment: "",
      fundsalesbranch: "",
      occurringCredit: null,
      fundworkplace: valueDataLocation ?? "",
      funfullname: "",
      payerdescription: "",
      payerfullname: "",
      payernote: "",
      payertype: "Nội bộ",
      anotherName: "",
      phoneNumber: "",
      codeCustomer: "",
      receiptNumber: listdata?.receiptNumber,
      totalAmount: 0,
      type: 3,
      attribute2: "",
      attribute3: "",
      attribute4: "",
      attribute6: "",
      attribute7: "",
      attribute8: "",
      attribute10: "01",
      typeReceipt: listdata?.typeReceipt,
    });

    const getDataFromLocalStorage = (key: string): UserData | null => {
      const data = localStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      }
      return null;
    };

    const formatFullName = (value: string) => {
      return value
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const handleChangeRate = (value: any) => {
      setSelectedCalculation(value);
    };

    const handleReturnPage = () => {
      navigate(-1);
    };

    const handleChange = (
      field: string,
      value: any,
      valueDataLocation?: string | null
    ) => {
      const errorMessages: Record<string, string> = {
        attribute3: "Vui lòng chọn chức danh!",
        attribute2: "Vui lòng chọn chức danh!",
        fundworkplace: "Vui lòng chọn địa điểm làm việc!",
        fundbranch: "Vui lòng chọn chi nhánh!",
        // attribute8: "Vui lòng nhập mã giao dịch!",
        funfullname: "Vui lòng chọn họ và tên!",
        codefund: "Vui lòng chọn mã phí!",
        funddepartment: "Vui lòng chọn phòng ban!",
        totalAmount: "Vui lòng nhập số tiền!",
        payerdescription: "Vui lòng nhập thông tin!",
        codeCustomer: "Vui lòng chọn mã KH/NCC!",
        anotherName: "Vui lòng nhập tên!",
        description: "Vui lòng nhập nội dung",
      };

      if (
        field in errorMessages &&
        formErrors[field as keyof typeof formErrors]
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [field]: value ? "" : errorMessages[field],
        }));
      }

      if (value) {
        setChangeFielselect(value);
      }

      if (field === "payertype") {
        if (value !== "Nội bộ") {
          setHandlePayertype(true);
        } else {
          setHandlePayertype(false);
        }
      }

      if (field === "payertype") {
        if (value !== "Khác") {
          setHandleOther(false);
        } else {
          setHandleOther(true);
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        [field]: value instanceof Date ? value.toISOString() : value,
      }));
    };

    const handleChooseOrderReturn = (value: any, id: number, type: string) => {
      setDebtData((prevDebtData: any) => {
        const updatedDebtData = prevDebtData.map((data: DebtData) => {
          if (data.id === id) {
            let originalDocumentNumber = "";
            let occurringCredit = 0;
            let debtObjectCode = "";
            let debtObjectName = "";

            if (type === "DT") {
              originalDocumentNumber = value?.orderNumber ?? "";
              occurringCredit = Math.abs(value?.amount) ?? 0;
              debtObjectCode = value?.attribute16;
              debtObjectName = "";
            } else if (type === "BH") {
              originalDocumentNumber = value?.borrowCode ?? "";
              occurringCredit = value?.totalDeposit ?? 0;
            }

            return {
              ...data,
              originalDocumentNumber: originalDocumentNumber,
              occurringCredit: occurringCredit,
              debtObjectCode: debtObjectCode,
              debtObjectName: debtObjectName,
            };
          }
          handleInputChange(value?.debtObjectName, id, "debtObjectName");
          handleInputChange(value?.orderNumber, id, "originalDocumentNumber");
          handleInputChange(value?.amount, id, "occurringCredit");
          handleInputChange(value?.debtObjectCode, id, "debtObjectCode");
          return data;
        });
        return updatedDebtData;
      });
    };

    function openFormBorrwedReceipt(id: number) {
      modals.openConfirmModal({
        title: <Title order={5}>Danh sách trả lại hàng mượn</Title>,
        size: "90vw",
        children: (
          <BorrowedReceipt
            handleChooseOrderReturn={(value: any) =>
              handleChooseOrderReturn(value, id, "BH")
            }
            fieldName={debtData}
            typeBill={"CH"}
          />
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    }

    const handleChooseCustomer = (
      value: any,
      isChooseCustomer: any,
      openFormCustomer: any
    ) => {
      if (isChooseCustomer) {
        if (!openFormCustomer?.original?.debtObjectCode) {
          setFormData((prevData) => ({
            ...prevData,
            attribute2: value?.groupCode ?? "",
            attribute7: value?.groupName ?? "",
          }));
          setDebtData((prevDebtData) => {
            const updatedDebtData = prevDebtData?.map((data) => ({
              ...data,
              debtObjectCode: value?.groupCode,
              debtObjectName: value?.groupName,
            }));
            return updatedDebtData;
          });
        } else {
          setDebtData((prevDebtData) => {
            const updatedDebtData = prevDebtData.map((data) => {
              if (data.id === openFormCustomer?.original.id) {
                return {
                  ...data,
                  debtObjectCode: value?.groupCode ?? "",
                  debtObjectName: value?.groupName ?? "",
                };
              }
              return data;
            });
            return updatedDebtData;
          });
        }
      }
    };

    function openFormCustomer(isChooseCongNo: boolean, openFormCustomer?: any) {
      modals.openConfirmModal({
        title: (
          <>
            <Title order={5}>Danh sách đối tượng công nợ</Title>
          </>
        ),
        size: "90vw",
        children: (
          <DebtObjectCodeFund
            isChooseCongNo={isChooseCongNo}
            openFormCustomer={openFormCustomer}
            handleChooseCustomer={handleChooseCustomer}
            navigate={navigate}
            disible
          />
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    }
    const formInternal = () => (
      <>
        <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
          <Flex align="center">
            <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
              Chi nhánh <span style={{ color: "red" }}>*</span>
            </Text>
            <Select
              searchable
              flex={1}
              className={classesButton.text_input}
              clearable
              error={
                !handlePayertype && formErrors.fundbranch
                  ? formErrors.fundbranch
                  : undefined
              }
              disabled={
                statusReponse
                  ? statusReponse
                  : !handlePayertype && changeFielselect !== "-1"
                  ? false
                  : true
              }
              value={
                dataBranch?.find(
                  (item: SelectListItemBase) =>
                    item.value === formData.fundbranch
                )?.value || null
              }
              data={dataBranch
                ?.sort((a: any, b: any) => a.att2 - b.att2)
                .map((ele, index) => {
                  return {
                    label: ele.text,
                    value: ele.value,
                  };
                })
                .slice(1)}
              placeholder="Vui lòng chọn chi nhánh"
              onChange={(selectedDescription) => {
                if (!selectedDescription) {
                  handleChange("fundbranch", null);
                } else {
                  const selectedOption = dataBranch?.find(
                    (item: SelectListItemBase) =>
                      item.value === selectedDescription
                  );
                  if (selectedOption) {
                    handleChange("fundbranch", selectedOption.value);
                    handleChange("fundworkplace", selectedOption.att1);
                    handleChange("funddepartment", "");
                    handleChange("attribute3", "");
                    handleChange("funfullname", null);
                    setFundepartmentId(Number(selectedOption?.value));
                  }
                }
              }}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
          <Flex align="center">
            <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
              Họ và tên <span style={{ color: "red" }}>*</span>
            </Text>
            <Select
              searchable
              clearable
              className={classesButton.text_input}
              flex={1}
              error={
                !handlePayertype && formErrors.funfullname
                  ? formErrors.funfullname
                  : undefined
              }
              disabled={
                statusReponse || handlePayertype || !formData?.fundbranch
              }
              placeholder="Vui lòng chọn họ và tên"
              comboboxProps={{
                transitionProps: {
                  transition: "pop",
                  duration: 200,
                },
              }}
              limit={50}
              value={
                listEmployee
                  ?.find(
                    (item: any) =>
                      String(item.id) === String(formData.funfullname)
                  )
                  ?.id.toString() || null
              }
              data={listEmployee.map((item) => {
                const formatUnder = formatFullName(item.fullname);
                return {
                  label: formatUnder + " - " + item.code,
                  value: String(item.id ?? null),
                };
              })}
              onChange={(selectedValue) => {
                setHandleFullName(selectedValue);
                if (!selectedValue) {
                  handleChange("attribute3", "");
                  handleChange("funfullname", null);
                  handleChange("funddepartment", "");
                } else {
                  const selectedOption = listEmployee.find(
                    (item) => String(item.id) === selectedValue
                  );
                  if (selectedOption) {
                    handleChange("funfullname", selectedOption?.id);
                    handleChange("attribute3", listTblDmEmployee?.roleName);
                    handleChange("funddepartment", listTblBranch?.departmentId);
                  }
                }
              }}
            />
          </Flex>
        </Grid.Col>

        <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
          <Flex align="center">
            <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
              ĐĐ Làm việc <span style={{ color: "red" }}>*</span>
            </Text>
            <Tooltip label={formData.fundworkplace ?? ""}>
              <TextInput
                flex={1}
                className={classesButton.text_input}
                error={
                  !handlePayertype && formErrors.fundworkplace
                    ? formErrors.fundworkplace
                    : undefined
                }
                disabled={true}
                value={formData.fundworkplace ?? ""}
                placeholder="Vui lòng chọn ĐĐ làm việc"
              />
            </Tooltip>
          </Flex>
        </Grid.Col>
        <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
          <Flex align="center">
            <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
              Chức danh <span style={{ color: "red" }}>*</span>
            </Text>
            <TextInput
              flex={1}
              disabled={true}
              className={classesButton.text_input}
              error={
                !handlePayertype && formErrors.attribute3
                  ? formErrors.attribute3
                  : undefined
              }
              placeholder="Vui lòng chọn chức danh"
              value={formData.attribute3 ?? ""}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
          <Flex align="center">
            <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
              Phòng ban <span style={{ color: "red" }}>*</span>
            </Text>
            <TextInput
              flex={1}
              disabled={true}
              className={classesButton.text_input}
              error={
                !handlePayertype && formErrors.funddepartment
                  ? formErrors.funddepartment
                  : undefined
              }
              value={
                formData.funddepartment
                  ? listTblBranch?.departmentCode +
                    " - " +
                    listTblBranch?.departmentName
                  : ""
              }
              placeholder="Vui lòng chọn phòng ban"
            />
          </Flex>
        </Grid.Col>
      </>
    );

    const formOther = () => (
      <>
        <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
          <Flex align="center">
            <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
              Mã KH/NCC <span style={{ color: "red" }}>*</span>
            </Text>
            <Select
              key={formData.codeCustomer}
              searchable
              className={classesButton.text_input}
              clearable
              flex={1}
              placeholder="Vui lòng chọn Mã KH/NCC"
              error={
                handlePayertype && !handleOther && formErrors.codeCustomer
                  ? formErrors.codeCustomer
                  : undefined
              }
              disabled={statusReponse || !handlePayertype || handleOther}
              onSearchChange={(value) => setValueSeachKHNCC(value)}
              defaultValue={
                customerOrders?.find(
                  (item: any) => item.id === formData.codeCustomer
                )?.code || null
              }
              data={
                customerOrders
                  ?.map((e: any) => e.code)
                  .filter((name: string): name is string => !!name)
                  .filter(
                    (name, index, self) => self.indexOf(name) === index
                  ) ?? []
              }
              onDropdownOpen={fetchData}
              onChange={(selectedDescription) => {
                if (!selectedDescription) {
                  handleChange("codeCustomer", null);
                  handleChange("anotherName", null);
                  handleChange("phoneNumber", null);
                  handleChange("attribute7", null);
                  handleChange("attribute2", null);
                } else {
                  const selectedOption = customerOrders?.find(
                    (item) => item.code === selectedDescription
                  );
                  if (selectedOption) {
                    handleChange("codeCustomer", selectedOption.id);
                    handleChange(
                      "anotherName",
                      selectedOption.details.fullName
                    );
                    handleChange("attribute2", selectedOption.congno ?? "");
                    handleChange("attribute7", selectedOption.tencongno ?? "");
                    handleChange(
                      "phoneNumber",
                      selectedOption.details.phoneNumber
                    );
                  }
                }
              }}
              renderOption={({ option, checked }) => {
                const selectedOrder = customerOrders?.find(
                  (ele) => String(ele.code) === String(option.value)
                );
                return (
                  <Flex flex="1" gap="xs">
                    <Text>
                      {selectedOrder?.code ?? ""}
                      {" - "}
                      {selectedOrder?.details.fullName ?? ""}
                    </Text>
                    {checked && (
                      <IconCheck
                        fontWeight={600}
                        size={20}
                        color="green"
                        style={{ marginInlineStart: "auto" }}
                      />
                    )}
                  </Flex>
                );
              }}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
          <Flex align="center">
            <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
              Tên KH/NCC <span style={{ color: "red" }}>*</span>
            </Text>
            <TextInput
              flex={1}
              className={classesButton.text_input}
              disabled={
                statusReponse ? statusReponse : !handlePayertype || !handleOther
              }
              error={
                handlePayertype && formErrors.anotherName
                  ? formErrors.anotherName
                  : undefined
              }
              value={formData.anotherName || ""}
              onChange={(e) => {
                const input = e.target.value;
                if (/\d/.test(input)) {
                } else if (!input) {
                  handleChange("anotherName", null);
                } else {
                  handleChange("anotherName", input);
                }
              }}
              w={185}
              placeholder="Nhập tên KH/NCC"
            />
          </Flex>
        </Grid.Col>
        <Grid.Col
          offset={{ base: 0, md: 0, lg: 6 }}
          pt={0}
          span={{ base: 12, md: 12, lg: 6 }}
        >
          <Flex align="center">
            <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
              Số điện thoại <span style={{ color: "red" }}>*</span>
            </Text>
            <TextInput
              className={classesButton.text_input}
              flex={1}
              w={185}
              disabled={
                statusReponse ? statusReponse : !handlePayertype || !handleOther
              }
              value={formData.phoneNumber || ""}
              placeholder="Nhấp số điện thoại"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 11) {
                  handleChange("phoneNumber", value);
                }
                if (!e) {
                  handleChange("phoneNumber", null);
                }
              }}
            />
          </Flex>
        </Grid.Col>
      </>
    );

  function openFormReturnOrder(id: number) {
    modals.openConfirmModal({
      title: <Title order={5}>Danh sách đơn đổi trả</Title>,
      size: "90vw",
      children: (
        <ReturnOrderChildren
          handleChooseOrderReturn={(value: any) =>
            handleChooseOrderReturn(value, id, "DT")
          }
          fieldName={debtData}
          inventory={
            formData?.fundbranch ??
            (userString && String(JSON.parse(userString).branchId))
          }
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }
  const fetchCreateReceiptParent = async (handleSave?: boolean) => {
    let isValid = true;
    const newErrors: { [index: number]: RowErrors } = {};
    const errors = {
      payerfullname: "",
      accountfund: 0,
      codefund: "",
      createby: 0,
      createdDate: "",
      creatorbranch: "",
      creatordepartment: "",
      creatorfullname: "",
      creatorsalesbranch: "",
      creatorworkplace: "",
      occurringCredit: "",
      fundbranch: "",
      attribute3: "",
      attribute2: "",
      attribute4: "",
      attribute6: "",
      attribute8: "",
      funddepartment: "",
      fundsalesbranch: "",
      fundworkplace: "",
      funfullname: "",
      id: 0,
      payerdescription: "",
      codeCustomer: "",
      anotherName: "",
      phoneNumber: "",
      payernote: "",
      payertype: "",
      receiptNumber: "",
      totalAmount: "",
      type: 0,
    };

    const handelWarning = (item: string) => {
      notifications.show({
        title: <Title order={5}>Thông báo</Title>,
        autoClose: 4000,
        message: `${item}!`,
        color: "red",
        icon: <IconX />,
      });
    };

    const validateField = (
      condition: boolean,
      field: string,
      errorMessage: string
    ) => {
      if (!condition) {
        (errors as any)[field] = errorMessage;
        isValid = false;
        handelWarning(errorMessage);
      }
    };

    // validateField(
    //   !!formData.attribute8,
    //   "attribute8",
    //   "Vui lòng chọn mã giao dịch!"
    // );
    validateField(
      !!formData.codefund,
      "codefund",
      "Vui lòng chọn tài khoản nợ!"
    );

    if (!handlePayertype) {
      validateField(
        !!formData.attribute3,
        "attribute3",
        "Vui lòng chọn chức danh!"
      );
      validateField(
        !!formData.funfullname,
        "funfullname",
        "Vui lòng chọn họ và tên!"
      );

      validateField(
        !!formData.fundbranch,
        "fundbranch",
        "Vui lòng chọn chi nhánh!"
      );
      validateField(
        !!formData.fundworkplace,
        "fundworkplace",
        "Vui lòng chọn địa điểm làm việc!"
      );
      validateField(
        !!formData.totalAmount,
        "totalAmount",
        "Tổng tiền chưa được hiển thị!"
      );
      validateField(
        !!formData.funddepartment,
        "funddepartment",
        "Vui lòng chọn phòng ban!"
      );
      validateField(
        !!formData.attribute2,
        "attribute2",
        "Vui lòng chọn mã đối tượng công nợ!"
      );
    } else {
      if (!handleOther) {
        validateField(
          !!formData.codeCustomer,
          "codeCustomer",
          "Vui lòng chọn mã KH/NCC!"
        );
        validateField(
          !!formData.attribute2,
          "attribute2",
          "Vui lòng chọn mã đối tượng công nợ!"
        );
        validateField(
          !!formData.totalAmount,
          "totalAmount",
          "Tổng tiền chưa được hiển thị!"
        );
      }

      if (formData.payertype !== "NCC" && formData.payertype !== "Khách hàng") {
        validateField(
          !!formData.anotherName,
          "anotherName",
          "Vui lòng nhập tên!"
        );
        validateField(
          !!formData.totalAmount,
          "totalAmount",
          "Tổng tiền chưa được hiển thị!"
        );
        validateField(
          !!formData.attribute2,
          "attribute2",
          "Vui lòng chọn mã đối tượng công nợ!"
        );
      }
    }

    debtData.forEach((row: any, index: number) => {
      if (row.originalDocuments && !row.occurringCredit) {
        newErrors[index] = {
          ...newErrors[index],
          occurringCredit: "Vui lòng nhập phí phát sinh!",
        };
        handelWarning("Vui lòng nhập phí phát sinh!");
      }
    });

    debtData.forEach((row: any, index: number) => {
      if (!row.originalDocuments) {
        newErrors[index] = {
          ...newErrors[index],
          originalDocuments: "Vui lòng chọn tên chứng từ gốc!",
        };
        handelWarning("Vui lòng chọn tên chứng từ gốc!");
      }
    });

    debtData.forEach((row: any, index: number) => {
      if (!row.originalDocumentNumber) {
        if (row.originalDocuments === "Đơn hàng") {
          newErrors[index] = {
            ...newErrors[index],
            originalDocumentNumber: "Vui lòng chọn số chứng từ gốc!",
          };
          handelWarning("Vui lòng chọn số chứng từ gốc!");
        }
      }
    });

    // debtData.forEach((row: any, index: number) => {
    //   if (row.originalDocuments && !row.branchesFund) {
    //     newErrors[index] = {
    //       ...newErrors[index],
    //       branchesFund: "Vui lòng chọn chi nhánh!",
    //     };
    //     handelWarning("Vui lòng chọn chi nhánh!");
    //   }
    // });

    debtData.forEach((row: any, index: number) => {
      if (!row.description) {
        newErrors[index] = {
          ...newErrors[index],
          description: "Vui lòng nhập nội dung!",
        };
        handelWarning("Vui lòng nhập nội dung!");
      }
    });

    debtData.forEach((row: any, index: number) => {
      if (row.originalDocuments && !row.codeForFees) {
        newErrors[index] = {
          ...newErrors[index],
          codeForFees: "Vui lòng chọn mã phí!",
        };
        handelWarning("Vui lòng chọn mã phí!");
      }
    });

    setErrors(newErrors);
    setFormErrors(errors);

    if (!isValid || Object.keys(newErrors).length > 0) {
      return;
    }

    setIsRunning(false);

    const requestData: CreateReceiptParentRequest = {
      paymentReceiptDetailCommand: {
        id: 0,
        accountfund:
          dataExchangeRate.find((x) => x.id === formData.accountfund)
            ?.currencyType ?? "",
        codefund: formData.codefund, //Mã tài khoản nợ
        createby: formData.createby,
        createdDate: formData.createdDate,
        completiontime: moment(new Date(), "DD-MM-YYYY HH:mm:ss")
          .add(7, "hours")
          .toISOString(),
        createrolename: String(userData?.roleId),
        creatorbranch: listdata?.creatorbranch,
        creatordepartment: listdata?.creatordepartment,
        creatorfullname: listdata?.creatorfullname,
        creatorsalesbranch: "",
        creatorworkplace: String(branchDeparment?.address),
        fundbranch: formData.fundbranch,
        funddepartment: formData.funddepartment,
        fundsalesbranch: formData.fundbranch,
        fundworkplace: formData.fundworkplace,
        funfullname: formData.funfullname,
        payerdescription: formData.payerdescription,
        payerfullname: formData.payerfullname,
        payernote: formData.payernote,
        anotherName: formData.anotherName,
        phoneNumber: formData.phoneNumber,
        codeCustomer: formData.codeCustomer,
        payertype: formData.payertype,
        receiptNumber: listdata?.receiptNumber.toString() ?? "",
        totalAmount: occurringCredits,
        type: formData.type,
        attribute2: formData.attribute2 ?? "", // Mã nhóm công nợ
        attribute3: formData.attribute3 ?? "", //Bộ phận
        attribute4: formData.attribute4,
        attribute5: JSON.stringify(debtData) ?? "", //Mã phí
        attribute6: formData.attribute6 ?? "", //Tên tài khoản nợ
        attribute7: formData.attribute7,
        attribute8: formData.attribute8 ?? "", //Tên Mã nhóm công nợ
        attribute10: formData.attribute10,
        attribute11: String(TotalAmountConverted) ?? "", // Số tiền quy đổi
        attribute12: String(selectedValue) ?? "", // Tỷ giá
        attribute13:
          String(
            dataExchangeRate.find((x) => x.id === formData.accountfund)?.id
          ) ?? "",
        typeReceipt: 2,
      },
      save: handleSave,
    };

    try {
      const response = await repositoryPos.post<
        MessageResponse<CreateReceiptParentRequest>
      >("/api/v1/CreateSellItem/create-receipt-paid-parent", requestData);

      if (response && response.success) {
        if (handleSave) {
          notifications.show({
            title: "Thành công",
            message: "Phiếu báo nợ đã được hoàn thành!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        } else {
          notifications.show({
            title: "Thành công",
            message: "Phiếu thu đã được lưu thành công!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        }
        setStatusReponse(response.success);
      } else {
        notifications.show({
          title: "Thất bại",
          message: "Lưu phiếu báo nợ không thành công. Vui lòng thử lại!",
          autoClose: 3000,
          color: "red",
          icon: <IconX />,
        });
      }
    } catch (error) {
      console.error("Error creating receipt:", error);
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 90,
        maxSize: 90,
        minSize: 90,
        enableEditing: false,
        enableSorting: false,
        enableResizing: false,
      },
      {
        accessorKey: "originalDocuments",
        header: "Tên chứng từ gốc",
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <Select
              key={row.original.id}
              searchable
              disabled={statusReponse}
              flex={1}
              w={200}
              className={classesButton.text_input}
              fw={600}
              clearable
              data={[
                "Đơn hàng",
                "Chi khác",
                "Mua dịch vụ",
                "Bảo hành",
                "Mua hàng NCC",
              ]}
              value={debtData[row.index].originalDocuments}
              placeholder="Vui lòng nhập"
              onChange={(e) => {
                handleOriginalDocumentsChange(e, row.index);
                handleInputChange(e, row.index, "originalDocuments");
                handleChangeCodeFund(e, row, "originalDocuments");
              }}
              error={errors[row.index]?.originalDocuments}
            />
          );
        },
      },
      {
        accessorKey: "originalDocumentNumber",
        header: "Số chứng từ gốc",
        enableSorting: false,
        Cell: ({ cell, row }) => {
          const [value, setValue] = useState(
            row.original.originalDocumentNumber || ""
          );
          const [debounced] = useDebouncedValue(value, 300);

          const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.currentTarget.value;
            setValue(inputValue);

            if (!inputValue) {
              handleInputChange("", row.index, "originalDocumentNumber");
              handleChangeCodeFund("", row, "originalDocumentNumber");
            }
          };

          const handleClick = () => {
            if (row.original.originalDocuments === "Đơn hàng") {
              openFormReturnOrder(row.original.id);
            } else if (row.original.originalDocuments === "Bảo hành") {
              openFormBorrwedReceipt(row.original.id);
            }
          };

          useEffect(() => {
            if (debounced) {
              handleInputChange(debounced, row.index, "originalDocumentNumber");
              handleChangeCodeFund(debounced, row, "originalDocumentNumber");
            }
          }, [debounced]);

          useEffect(() => {
            setValue(row.original.originalDocumentNumber || "");
          }, [row.original.originalDocumentNumber]);

          return row.original.originalDocuments === "Đơn hàng" ||
            row.original.originalDocuments === "Bảo hành" ? (
            <TextInput
              readOnly={
                row.original.originalDocuments === "Đơn hàng" ||
                row.original.originalDocuments === "Bảo hành"
              }
              disabled={!row.original.originalDocuments || statusReponse}
              flex={1}
              fw={600}
              className={classesButton.text_input}
              placeholder="Vui lòng nhập"
              value={debtData[row.index].originalDocumentNumber?.toString()}
              onClick={handleClick}
              onChange={handleInput}
              error={errors[row.index]?.originalDocumentNumber}
            />
          ) : (
            <TextInput
              disabled={!row.original.originalDocuments || statusReponse}
              flex={1}
              className={classesButton.text_input}
              fw={600}
              placeholder="Vui lòng nhập"
              value={value}
              onChange={handleInput}
              error={errors[row.index]?.originalDocumentNumber}
            />
          );
        },
      },
      {
        accessorKey: "codeForFees",
        header: "Mã Phí",
        enableEditing: isCreating,
        enableSorting: false,
        size: 250,
        Cell: ({ cell, row, table, column }) => {
          return (
            <Select
              key={row.original.code || ""}
              searchable
              className={classesButton.text_input}
              disabled={!row.original.originalDocuments || statusReponse}
              flex={1}
              clearable
              data={filteredData.map((ele) => ({
                label: ele.att1 + " - " + ele.text,
                value: ele.value,
              }))}
              placeholder="Vui lòng nhập"
              value={
                filteredData.find((ele) => ele.att1 === row.original.code)
                  ?.value ?? ""
              }
              onChange={(e) => {
                const data = filteredData.find((ele) => ele.value === e);
                handleChangeCodeFund(data?.text, row, "codeForFees");
                handleChangeCodeFund(data?.att1, row, "code");
                handleInputChange(data?.text, row.index, "codeForFees");
              }}
              error={errors[row.index]?.codeForFees}
            />
          );
        },
      },
      {
        accessorKey: "occurringCredit",
        header: "Phát Sinh nợ",
        enableSorting: false,
        Cell: ({ cell, row, table, column, renderedCellValue }) => {
          const [value, setValue] = useState<any>(undefined);
          const [debounced] = useDebouncedValue(value, 200);

          useEffect(() => {
            if (debounced !== undefined) {
              handleInputChange(debounced, row.index, "occurringCredit");
              handleChangeCodeFund(debounced, row, "occurringCredit");
            }
          }, [debounced]);

          return (
            <NumberInput
              disabled={!row.original.originalDocuments || statusReponse}
              readOnly={
                row.original.originalDocuments === "Đơn hàng" ||
                row.original.originalDocuments === "Bảo hành"
              }
              className={classesButton.text_input}
              placeholder="Vui lòng nhập"
              allowNegative={false}
              thousandSeparator=","
              flex={1}
              value={row.original.occurringCredit ?? ""}
              hideControls
              style={{ whiteSpace: "normal" }}
              rightSection={<IconMinus size={12} />}
              onChange={(e) => {
                setValue(e.toString());
              }}
              error={!renderedCellValue && errors[row.index]?.occurringCredit}
            />
          );
        },
      },
      {
        accessorKey: "debtObjectCode",
        header: "Mã Đối Tượng Công Nợ",
        enableSorting: false,
        Cell: ({ cell, row, table, column, renderedCellValue }) => {
          const check =
            formData.payertype === "Khách hàng" || formData.payertype === "NCC";
          return (
            <TextInput
              placeholder="Vui lòng nhập"
              flex={1}
              className={classesButton.text_input}
              value={
                debtData[row.index].debtObjectCode?.toString() ??
                renderedCellValue?.toString() ??
                ""
              }
              onClick={() => check && openFormCustomer(true, row)}
              disabled={!row.original.originalDocuments || statusReponse}
            />
          );
        },
      },
      {
        accessorKey: "debtObjectName",
        header: "Tên Đối Tượng Công Nợ",
        enableSorting: false,
        Cell: ({ row, renderedCellValue }) => {
          return (
            <TextInput
              flex={1}
              disabled
              className={classesButton.text_input}
              value={
                debtData[row.index].debtObjectName?.toString() ??
                renderedCellValue?.toString() ??
                ""
              }
              placeholder="Vui lòng nhập"
              style={{ whiteSpace: "normal" }}
              onBlur={(e) => {
                handleChangeCodeFund(e.target.value, row, "debtObjectName");
              }}
            />
          );
        },
      },
      {
        accessorKey: "description",
        header: "Nội Dung",
        size: 300,
        enableSorting: false,
        Cell: ({ cell, row, table, column, renderedCellValue }) => {
          const [value, setValue] = useState<any>(renderedCellValue);
          const [debounced] = useDebouncedValue(value, 500);

          useEffect(() => {
            handleChangeCodeFund(debounced, row, "description");
          }, [debounced]);

          useEffect(() => {
            setValue(renderedCellValue);
          }, [renderedCellValue]);
          return (
            <TextInput
              flex={1}
              className={classesButton.text_input}
              disabled={!row.original.originalDocuments || statusReponse}
              placeholder="Vui lòng nhập"
              value={value}
              style={{ whiteSpace: "normal" }}
              onChange={(e) => setValue(e.target.value)}
              error={!renderedCellValue && errors[row.index]?.description}
            />
          );
        },
      },
      {
        accessorKey: "branchesFund",
        header: "Chi nhánh",
        size: 250,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <Select
              key={row.original.branchesFund || ""}
              searchable
              disabled={!row.original.originalDocuments || statusReponse}
              flex={1}
              clearable
              className={classesButton.text_input}
              data={dataBranch
                ?.slice(1)
                ?.sort((a: any, b: any) => a.att2 - b.att2)
                .map((ele) => ({
                  label: ele.text,
                  value: ele.att2,
                }))}
              placeholder="Vui lòng nhập"
              value={
                dataBranch.find((ele) => ele.att2 === row.original.branchesFund)
                  ?.att2 ?? row.original.branchesFund
              }
              onChange={(e) => {
                handleChangeCodeFund(e, row, "branchesFund");
                handleInputChange(e, row.index, "branchesFund");

                setDepartmentsByBranch((prevData) => {
                  const newData = { ...prevData };
                  delete newData[row.index];
                  return newData;
                });
              }}
              // error={errors[row.index]?.branchesFund}
            />
          );
        },
      },
      {
        accessorKey: "employeeFund",
        header: "Mã NV",
        enableSorting: false,
        size: 250,
        Cell: ({ cell, row }) => {
          const [employeesByBranch, setEmployeesByBranch] = useState<
            EmployeeModel[]
          >([]);
          // Only call this when the row's branchesFund changes
          const loadEmployeeByBranch = async () => {
            if (!row.original.branchesFund) return;

            const selectedBranch = dataBranch.find(
              (item) => String(item.att2) === String(row.original.branchesFund)
            );

            const response = await repositoryPos.get<
              MessageResponse<EmployeeModel[]>
            >(
              `/api/v1/TblDmEmployee/get-list?BranchId=${selectedBranch?.value}&Skip=0&Take=9999`
            );

            if (response && response.success) {
              setEmployeesByBranch(response.data);
            }
          };

          return (
            <Select
              key={row.original.branchesFund || ""}
              searchable
              disabled={
                !row.original.branchesFund ||
                statusReponse ||
                !row.original.originalDocuments
              }
              flex={1}
              className={classesButton.text_input}
              onClick={() => loadEmployeeByBranch()}
              w={250}
              clearable
              data={
                employeesByBranch?.map((item) => ({
                  value: item.code, // Mã sẽ là giá trị duy nhất
                  label: `${item.code}`, // Hiển thị tên + mã
                })) || []
              }
              value={row.original.employeeFund}
              placeholder="Vui lòng nhập"
              onChange={(e) => {
                handleInputChange(e, row.index, "employeeFund");
                handleChangeCodeFund(e, row, "employeeFund");

                // setSelectedDepartments((prev) => ({
                //   ...prev,
                //   [row.index]: "",
                // }));

                setDepartmentsByBranch((prevData) => {
                  const newData = { ...prevData };
                  delete newData[row.index];
                  return newData;
                });

                const data =
                  employeesByBranch
                    ?.find((item) => String(item.code) === String(e))
                    ?.id.toString() ?? "";

                if (e) loadDepartmentsByBranch(e, row.index, data);
              }}
              renderOption={({ option, checked }) => (
                <Flex flex="1" gap="xs">
                  <Text>
                    {option.label} {" - "}
                    {employeesByBranch?.find(
                      (item) => String(item.code) === String(option.label)
                    )?.fullname ?? ""}
                  </Text>
                  {checked && (
                    <IconCheck
                      fontWeight={600}
                      color="green"
                      style={{ marginInlineStart: "auto" }}
                    />
                  )}
                </Flex>
              )}
            />
          );
        },
      },
      {
        accessorKey: "fee",
        header: "CTKM",
        enableSorting: false,
        Cell: ({ cell, row, table, column }) => {
          return (
            <Select
              searchable
              flex={1}
              clearable
              value={
                dataGetReceiptFee
                  .find((ele) => String(ele.code) === String(row?.original.fee))
                  ?.code.toString() ?? null
              }
              // value={
              //   dataGetDepartments?.find(
              //     (item: any) => item.name === formData.funddepartment
              //   )?.name || null
              // }
              data={dataGetReceiptFee.map((ele) => {
                return {
                  label: ele.code,
                  value: ele.code.toString(),
                };
              })}
              disabled={!row.original.originalDocuments || statusReponse}
              placeholder="Vui lòng nhập"
              className={classesButton.text_input}
              onClick={async () => await fetchDataReceiptFee()}
              onChange={(e) => {
                // handleInputChange(e, row.index, "fee");
                handleChangeCodeFund(e, row, "fee");
              }}
              // error={errors[row.index]?.fee}
              renderOption={({ option, checked }) => (
                <Flex flex="1" gap="xs">
                  <Text>
                    {option.label} {" - "}
                    {dataGetReceiptFee.find(
                      (ele) => String(ele.code) === String(option.value)
                    )?.name ?? ""}
                  </Text>
                  {checked && (
                    <IconCheck
                      fontWeight={600}
                      size={20}
                      color="green"
                      style={{ marginInlineStart: "auto" }}
                    />
                  )}
                </Flex>
              )}
            />
          );
        },
      },
      {
        accessorKey: "contract",
        header: "Hợp Đồng",
        enableSorting: false,
        Cell: ({ cell, row, table, column }) => {
          return (
            <Select
              searchable
              flex={1}
              clearable
              className={classesButton.text_input}
              value={
                dataGetReceiptContract.find(
                  (ele) => String(ele.code) === String(row?.original.contract)
                )?.code ?? null
              }
              // value={
              //   dataGetDepartments?.find(
              //     (item: any) => item.name === formData.funddepartment
              //   )?.name || null
              // }
              data={dataGetReceiptContract.map((ele) => {
                return {
                  label: ele.code,
                  value: ele.code,
                };
              })}
              disabled={!row.original.originalDocuments || statusReponse}
              placeholder="Vui lòng nhập"
              onClick={async () => await fetchDataReceiptContract()}
              onChange={(e) => {
                // handleInputChange(e, row.index, "contract");
                handleChangeCodeFund(e, row, "contract");
              }}
              renderOption={({ option, checked }) => (
                <Flex flex="1" gap="xs">
                  <Text>
                    {option.label} {" - "}
                    {dataGetReceiptContract.find(
                      (ele) => String(ele.code) === String(option.value)
                    )?.name ?? ""}
                  </Text>
                  {checked && (
                    <IconCheck
                      fontWeight={600}
                      size={20}
                      color="green"
                      style={{ marginInlineStart: "auto" }}
                    />
                  )}
                </Flex>
              )}
              // error={errors[row.index]?.contract}
            />
          );
        },
      },
    ],
    [
      inputValues,
      statusReponse,
      customerOrders,
      debtData,
      errors,
      branchAll,
      // selectedBranches,
      filteredData,
      isCreating,
      filteredData,
      selectedItems,
      dataGetReceiptContract,
      dataGetReceiptFee,
    ]
  );

  const fetchDataDepart = async () => {
    try {
      const response = await repositoryPos.post<
        MessageResponse<DepartmentUser>
      >(`/api/v1/TblDepartment/detail?id=${listdata?.creatordepartment}`);

      if (response && response.success) {
        const result = response.data;
        setBranchDeparment(result);
      } else {
        console.error(
          "Failed to fetch departmentFund details",
          response?.message
        );
      }
    } catch (error) {
      console.error("Error fetching departmentFund details", error);
    }
  };

  const handleOriginalDocumentsChange = (value: any, rowIndex: number) => {
    setDebtData((prevData) =>
      prevData.map((item, i) =>
        i === rowIndex
          ? {
              ...item,
              originalDocuments: "",
              originalDocumentNumber: "",
              code: "",
              codeForFees: null,
              occurringCredit: null,
              // branchesFund: null,
              employeeFund: null,
              departmentFund: null,
              description: null,
              fee: null,
              contract: null,
            }
          : item
      )
    );
  };

  const fetchDataGetAll = async () => {
    try {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentUser[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        const result = response.data;
        setBranchAll(result);
      } else {
        console.error("Failed to fetch departmentFund details");
      }
    } catch (error) {
      console.error("Error fetching departmentFund details", error);
    }
  };

  useEffect(() => {
    if (listdata?.creatordepartment) {
      fetchDataDepart();
    }
  }, [listdata?.creatordepartment]);

  const fetchData = async (keySearch?: string) => {
    let url = `?Skip=0&Take=25`;
    if (keySearch) {
      url = `?Skip=0&Take=25&KeySearch=${keySearch}`;
    }

    try {
      const repository =
        formData.payertype === "NCC" ? repositoryMdm : repositoryPos;
      const response = await repository.get(
        formData.payertype === "NCC"
          ? "/api/v1/TblVendor/get-all"
          : `/api/v1/TblDmCustomer/get-list${url}`
      );
      if (response && response.success) {
        const result = response.data;

        const mappedData: DepartmentCustomerOrder[] = result.map(
          formData.payertype === "NCC"
            ? (customer: any) => ({
                id: customer.id,
                code: customer.vendorCode,
                name: customer.vendorName,
                congno: customer.vendorCode,
                tencongno: customer.vendorName ?? "",
                details: {
                  fullName: customer.vendorName,
                  phoneNumber: customer.phone ?? "",
                },
              })
            : (customer: any) => ({
                id: customer.id,
                code: customer.code,
                name: customer.name,
                congno: customer.attribute1,
                tencongno: customer.attribute4 ?? "",
                details: {
                  fullName: customer.name,
                  phoneNumber: customer.telephoneNumber,
                },
              })
        );

        setCustomerOrders(mappedData);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const handleGroupNameChange = (e: any) => {
    const newValue = e.currentTarget.value;
    setInputValueDept(newValue);
  };

  const fetchDataDepartments = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >(`/api/v1/TblDepartment/get-select?branchId=${fundepartmentId}`);

    if (response && response.success) {
      let result = response.data;

      // const newDepartment = {
      //   att1: null,
      //   att2: null,
      //   att3: 0,
      //   att4: 0,
      //   att5: null,
      //   disabled: false,
      //   group: null,
      //   selected: false,
      //   text: "Khác",
      //   value: "-1",
      // };

      // const updatedDepartments = [...result, newDepartment];
      setDataGetDepartments(result);
    }
  };

  const fetchDataReceiptParent = async () => {
    const response = await repositoryPos.get<MessageResponse<FundDetails>>(
      "/api/v1/CreateSellItem/create-receipt-paid-parent?typeReceipt=BN"
    );

    if (response && response.success) {
      let result = response.data;
      setListData(result);
      // setDataGetReceiptParent(result);
    }
  };

  const fetchDataReceiptContract = async () => {
    const response = await repositoryPos.get<MessageResponse<Program[]>>(
      "/api/v1/TblDmContract/get-list?Skip=0&Take=99999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptContract(result);
    }
  };

  const fetchDataReceiptFee = async () => {
    const response = await repositoryPos.get<MessageResponse<Permission[]>>(
      "/api/v1/TblDmFee/get-list?Skip=0&Take=9999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataReceiptFee(result);
    }
  };

  const fetchDataReceiptAcount = async () => {
    const response = await repositoryPos.get<
      MessageResponse<AccountantAccount[]>
    >("/api/v1/TblDmAccount/get-list?Type=2&Skip=0&Take=999");

    if (response && response.success) {
      let result = response.data;
      setDataAcount(result);
    }
  };

  const fetchDataBranch = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmInventory/get-select-branch?auth=false");

    if (response && response.success) {
      let result = response.data;
      setDataBranch(result);
    }
  };

  const loadDepartmentsByBranch = async (
    branchId: string,
    rowIndex: number,
    id: string
  ) => {
    const response = await repositoryPos.get<MessageResponse<EmployeeModel>>(
      `/api/v1/TblDmEmployee/detail-seft?empId=${id ?? ""}`
    );

    if (response && response.success) {
      const result = response.data;
      setDepartmentsByBranch((prevData) => ({
        ...prevData,
        [rowIndex]: result,
      }));
    }
  };

  const handleAddRow = () => {
    const newId =
      debtData.length > 0
        ? Math.max(
            ...debtData
              .map((row) => row.id)
              .filter((id): id is number => id !== undefined)
          ) + 1
        : 0;

    setSelectedItems((prev) => {
      const updatedSelected = [...prev, newId.toString()];
      const x = listdata?.creatorbranch;
      const y = dataBranch.find((ele) => String(ele.value) === String(x));
      setDebtData((prevDebtData: any) => {
        const firstRow = prevDebtData[0] || {};
        const filteredDebtData = prevDebtData.filter((data: DebtData) =>
          updatedSelected.includes(data.codeForFees ?? "")
        );
        const newDebtItems = updatedSelected.map((_, index) => {
          const existingDebt = prevDebtData.find(
            (ele: any) => ele.id === index
          );

          let dataDebtObjectCode = "";
          let dataebtObjectName = "";

          if (formData.payertype === "Khác") {
            dataDebtObjectCode = formData.attribute2;
            dataebtObjectName = formData.attribute7;
          } else if (formData.payertype === "Nội bộ") {
            dataDebtObjectCode = formData.attribute2;
            dataebtObjectName = formData.attribute7;
          } else {
            dataDebtObjectCode =
              existingDebt?.debtObjectCode ?? formData.attribute2 ?? "";
            dataebtObjectName =
              existingDebt?.debtObjectName ?? formData.attribute7 ?? "";
          }

          return {
            id: index,
            code: existingDebt?.code ?? firstRow?.code ?? "",
            originalDocuments:
              existingDebt?.originalDocuments ??
              firstRow.originalDocuments ??
              "",
            originalDocumentNumber:
              firstRow.originalDocuments === "Đơn hàng" ||
              firstRow.originalDocuments === "Bảo hành"
                ? existingDebt?.originalDocumentNumber
                : existingDebt?.originalDocumentNumber ??
                  firstRow?.originalDocumentNumber ??
                  "",
            codeForFees:
              existingDebt?.codeForFees ?? firstRow?.codeForFees ?? "",
            occurringCredit: existingDebt?.occurringCredit,
            debtObjectCode: existingDebt?.debtObjectCode ?? dataDebtObjectCode,
            debtObjectName: existingDebt?.debtObjectName ?? dataebtObjectName,
            branchesFund: existingDebt?.branchesFund ?? y?.att2 ?? "",
            employeeFund: existingDebt?.employeeFund ?? "",
            description:
              existingDebt?.description ?? firstRow?.description ?? "",
            departmentFund: existingDebt?.departmentFund ?? "",
            fee: existingDebt?.fee ?? firstRow?.fee ?? "",
            contract: existingDebt?.contract ?? firstRow.contract ?? "",
          };
        });

        return [...filteredDebtData, ...newDebtItems];
      });
      return updatedSelected;
    });
  };

  useEffect(() => {
    if (searchValue.trim().length > 0) {
      const filtered = dataCodeFund.filter((item) =>
        item.text.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(dataCodeFund);
    }
  }, [searchValue]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      codefund: formData.codefund,
      attribute6:
        dataAcount.find(
          (x) => String(x.accountantAccount) === String(formData.codefund)
        )?.accountantAccountName ?? "12121",
    }));
  }, [dataAcount]);

  useEffect(() => {
    if (formData?.attribute2) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        attribute2:
          formData?.attribute2 ?? ""
            ? ""
            : "Vui lòng chọn mã đối tượng công nợ!",
      }));
    }
  }, [formData?.attribute2]);

  const getTblDmExpense = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmExpense/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataCodeFunch(result);
      setFilteredData(result);
    }
  };

  const getSelectTblDepartment = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >(`/api/v1/TblDepartment/get-select?branchId=${userData?.branchId}`);

    if (response && response.success) {
      let result = response.data;
      setDataDepartments(result);
    }
  };

  const fetchDataEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<EmployeeModel[]>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${fundepartmentId}&Skip=0&Take=9999`
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployee(result);
    }
  };

  const fetchDataTblRole = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblRole/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setDataTblRole(result);
    }
  };

  const fetchDataTblDmEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/detail-seft?empId=${handleFullName}`
    );

    if (response && response.success) {
      let result = response.data;
      setListTblDmEmployee(result);
      setListTblBranch(result);

      // setListEmployee(result);
    }
  };
  //#region  Start codefund
  const handleInputChange = (
    value: any,
    rowIndex: number,
    field: keyof RowErrors
  ) => {
    const newErrors = { ...errors };
    if (!value) {
      newErrors[rowIndex] = {
        ...newErrors[rowIndex],
        [field]: "Vui lòng nhập",
      };
    } else if (newErrors[rowIndex]) {
      delete newErrors[rowIndex][field];
      if (Object.keys(newErrors[rowIndex]).length === 0) {
        delete newErrors[rowIndex];
      }
    }
    //#region  Xóa dữ liệu khi có chi nhánh hoặc nhân viên bị xóa
    setDebtData((prevData) =>
      prevData.map((item, i) =>
        i === rowIndex
          ? {
              ...item,
              [field]: value || "",
              ...(field === "branchesFund" && {
                employeeFund: "",
                departmentFund: "",
              }),
              ...(field === "employeeFund" && { departmentFund: "" }),
            }
          : item
      )
    );

    setErrors(newErrors);
  };

  //Thêm thông tin
  const handleChangeCodeFund = (value: any, row: any, field: string) => {
    if (row !== null) {
      setDebtData((prevData) => {
        const updatedData = prevData.map((item, i) =>
          i === row.index
            ? {
                ...item,
                [field]: value || "",
              }
            : item
        );

        const totalOccurringCredit = updatedData.reduce((total, item) => {
          const occurringCreditValue = Number(item.occurringCredit) || 0;
          return total + occurringCreditValue;
        }, 0);

        setOccurringCredits(totalOccurringCredit);

        return updatedData;
      });
    } else {
      setDebtData((prevData) => {
        const updatedData = prevData.map((item) => ({
          ...item,
          [field]: value || "",
        }));
        return updatedData;
      });
    }
  };

  //Xóa mã phí
  const deleteRow = (id: any, rowCodeForFees?: any) => {
    setInputValues((prevValues) => {
      const updatedValues = { ...prevValues };
      delete updatedValues[id];
      return updatedValues;
    });

    setDebtData((prevData) => {
      const updatedData = prevData
        .filter((item) => item.id !== id)
        .map((item, index) => ({
          ...item,
          id: index,
        }));

      const newTotalOccurringCredit = updatedData.reduce((total, item) => {
        const occurringCreditValue = Number(item.occurringCredit) || 0;
        return total + occurringCreditValue;
      }, 0);
      setOccurringCredits(newTotalOccurringCredit);

      return updatedData;
    });

    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((selected, index) => index !== id)
    );
  };

  const DebtObject = () => (
    <Flex align="center">
      <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
        ĐTCN
        <span style={{ color: "red" }}>*</span>
      </Text>
      {formData.payertype === "Nội bộ" || formData.payertype === "Khác" ? (
        <TextInput
          placeholder="Chọn mã đối tượng công nợ"
          w={300}
          flex={1}
          disabled={statusReponse}
          className={classesButton.text_input}
          error={formErrors.attribute2 ? formErrors.attribute2 : undefined}
          value={formData.attribute2 ?? ""}
          onClick={() => openFormCustomer(true)}
          rightSection={
            statusReponse ? null : inputValueDept ? (
              <IconX
                onClick={() => {
                  setInputValueDept("");
                  setHandelAttrible(true);
                }}
                size={16}
              />
            ) : null
          }
        />
      ) : (
        <TextInput
          placeholder="Chọn mã đối tượng công nợ"
          flex={1}
          w={300}
          disabled
          className={classesButton.text_input}
          error={
            (!handlePayertype ||
              !handleOther ||
              (formData.payertype !== "NCC" &&
                formData.payertype !== "Khách hàng")) &&
            formErrors.attribute2
              ? formErrors.attribute2
              : undefined
          }
          value={formData.attribute2 ?? ""}
          rightSection={
            statusReponse ? null : inputValueDept ? (
              <IconX
                onClick={() => {
                  setInputValueDept("");
                  setHandelAttrible(true);
                }}
                size={16}
              />
            ) : null
          }
        />
      )}
    </Flex>
  );

  useEffect(() => {
    getTblDmExpense();
    fetchDataReceiptParent();
    fetchDataReceiptAcount();
    fetchDataBranch();
  }, []);

  useEffect(() => {
    setCurrentTime(new Date());
  }, []);

  useEffect(() => {
    if (formData.payertype === "Khác") {
      handleChange("attribute2", "");
    } else if (formData.payertype === "Nội bộ") {
      handleChange("attribute2", "");
    }
  }, [formData.payertype]);

  const handleComplete = () => {
    setCompleteTime(new Date());
  };

  useEffect(() => {
    const _s = JSON.stringify(debtData);
    const parsedData = JSON.parse(_s);
  }, [formData]);

  useEffect(() => {
    if (fundepartmentId) {
      fetchDataEmployee();
      fetchDataDepartments();
    }
  }, [fundepartmentId]);

  useEffect(() => {
    if (formData?.attribute2 || formData?.attribute7) {
      setDebtData((prevDebtData) =>
        prevDebtData.map((data: DebtData) => ({
          ...data,
          debtObjectCode: formData?.attribute2 ?? "",
          debtObjectName: formData?.attribute7 ?? "",
        }))
      );
    }
  }, [formData?.attribute2, formData?.attribute7]);

  useEffect(() => {
    if (!handlePayertype && fundepartmentId) {
      getSelectTblDepartment();
    } else {
      fetchDataGetAll();
    }
  }, [handlePayertype, fundepartmentId]);

  useEffect(() => {
    if (listTblBranch?.departmentId) {
      setFormData((prev) => ({
        ...prev,
        funddepartment: listTblBranch?.departmentId?.toString() ?? "",
      }));

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        funddepartment: listTblBranch?.departmentId
          ? ""
          : "Vui lòng chọn phòng ban!",
      }));
    }
  }, [listTblBranch?.departmentId]);

  useEffect(() => {
    const defaultItem = dataExchangeRate.find(
      (item) => item.calculation === selectedCalculation
    );
    setSelectedValue(defaultItem?.value ?? 1);
  }, [selectedCalculation]);

  useEffect(() => {
    if (handleFullName) {
      fetchDataTblDmEmployee();
    }
  }, [handleFullName]);

  useEffect(() => {
    handleChange("fundworkplace", valueDataLocation);
  }, [valueDataLocation]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (listTblDmEmployee?.roleName) {
      setFormData((prev) => ({
        ...prev,
        attribute3: listTblDmEmployee?.roleName ?? "",
      }));

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        attribute3:
          listTblDmEmployee?.roleName ?? "" ? "" : "Vui lòng chọn chức danh!",
      }));
    }
  }, [listTblDmEmployee?.roleName]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      createdDate: moment(new Date(), "DD-MM-YYYY HH:mm:ss")
        .add(7, "hours")
        .toISOString(),
    }));
  }, []);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      totalAmount: occurringCredits ?? "",
    }));
  }, [occurringCredits]);

  useEffect(() => {
    Object.keys(departmentsByBranch).forEach((rowIndex) => {
      const index = parseInt(rowIndex, 10);
      setDebtData((prevData) =>
        prevData.map((item, i) =>
          i === index
            ? {
                ...item,
                departmentFund:
                  departmentsByBranch[index]?.departmentCode ?? "",
              }
            : item
        )
      );
    });
  }, [departmentsByBranch]);

  useEffect(() => {
    if (dataCodeFund.length > 0) {
      setFilteredData(dataCodeFund);
    }
  }, [dataCodeFund]);

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue.trim().length > 0) {
        setFilteredData(
          dataCodeFund.filter((item) =>
            item.text.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      } else {
        setFilteredData(dataCodeFund);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, dataCodeFund]);

  useEffect(() => {
    if (userData?.branchId) {
      // Tìm chi nhánh mặc định từ dataBranch dựa trên branchId từ tài khopanr ngươi đăng nhập
      const defaultBranch = dataBranch?.find(
        (item: SelectListItemBase) =>
          String(item.value) === String(userData.branchId)
      );

      if (defaultBranch) {
        handleChange("fundbranch", defaultBranch.value);
        handleChange("fundworkplace", defaultBranch.att1);
        handleChange("funddepartment", "");
        handleChange("attribute3", "");
        handleChange("funfullname", null);
        setFundepartmentId(Number(defaultBranch.value));
      }
    }
  }, [userData, dataBranch]);

   useEffect(() => {
      fetchData(valueSeachKHNCC);
    }, [valueSeachKHNCC]);

  useHotkeys([
    ["ctrl+D", () => formData.attribute2 && !statusReponse && handleAddRow()],
  ]);
  useHotkeys([["ctrl+shift+Z", () => toggleCollapse()]]);

  const table = useMantineReactTable({
    columns,
    data: debtData,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Thao tác",
        size: 100,
        maxSize: 100,
        minSize: 100,
      },
    },
    defaultColumn: {
      maxSize: 400,
      minSize: 150,
      size: 200,
    },
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    enableEditing: true,
    createDisplayMode: "row",
    enableColumnOrdering: true,
    enableColumnDragging: true,
    // enableColumnResizing: true,
    //
    enableBatchRowSelection: true,
    editDisplayMode: "modal",
    enableToolbarInternalActions: false,
    enableRowActions: true,
    positionActionsColumn: "last",
    enableExpandAll: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    renderRowActions: ({ row }) => (
      <Flex gap={10}>
        <Tooltip label="Xóa">
          <ActionIcon
            disabled={statusReponse}
            onClick={() => deleteRow(row.original.id)}
            variant="light"
            color="rgba(255, 0, 0, 1)"
          >
            <IconTrash size={14} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <Group gap={30} align="center" justify="center">
          {isCollapse && DebtObject()}
          <Tooltip label="Thêm dòng (Ctrl + D)">
            <Button
              leftSection={<IconRowInsertTop size={16} />}
              disabled={statusReponse}
              className={classesButton.button}
              onClick={handleAddRow}
              variant="light"
              color="blue"
            >
              Thêm dòng
            </Button>
          </Tooltip>
        </Group>
      );
    },
    mantineBottomToolbarProps: {
      style: {
        paddingBottom: "20px",
      },
    },
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["mrt-row-actions", "deviated"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: isCollapse ? height * 0.75 : height * 0.6,
        minHeight: isCollapse ? height * 0.75 : height * 0.6,
        alignItems: "center",
      },
    },
    mantineTableProps: {
      striped: true,
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    localization: {
      noRecordsToDisplay: "Chưa có thông tin để hiển thị.",
    },

    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),

    enableColumnPinning: true,

    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  console.log(formData?.attribute8, "formData.attribute8");
  return (
    <>
      <Box>
        <Group justify="space-between" pl={20} pr={0} bg="#fff" ref={headerRef}>
          <header>
            <BreadcrumbLayout1 />
            <Flex align="center" gap={20}>
              <Button
                radius="lg"
                color="blue"
                size="sm"
                w={100}
                leftSection={<IconPlus size={14} />}
                variant="filled"
                onClick={() => {
                  navigate("/bill/credit-advice");
                  window.location.reload();
                }}
              >
                Tạo mới
              </Button>
              <Flex align="center" gap={10}>
                <Switch
                  size="lg"
                  color={isCollapse ? "red" : "green"}
                  checked={!isCollapse}
                  onLabel="Bật"
                  offLabel="Tắt"
                  onChange={toggleCollapse}
                  thumbIcon={
                    !isCollapse ? (
                      <IconMaximize
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    ) : (
                      <IconMaximizeOff
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    )
                  }
                />
                <Text size="md">
                  <Kbd>Ctrl</Kbd> + <Kbd>Shift</Kbd> + <Kbd>Z</Kbd>
                </Text>
              </Flex>
            </Flex>
          </header>
          <Button
            leftSection={<IconArrowLeft size={14} />}
            color="red"
            radius="xl"
            onClick={() => handleReturnPage()}
          >
            Quay lại
          </Button>
        </Group>
        <Divider my="md" mb={0} />
        <Box p={15} bg={"#f8f8f8"}>
          <PanelGroup
            style={{ height: !isSmallScreen ? "100%" : `${height * 2}px` }}
            autoSaveId="conditional"
            direction={isSmallScreen ? "vertical" : "horizontal"}
          >
            <Panel
              defaultSize={isSmallScreen ? 55 : 35}
              minSize={isSmallScreen ? 55 : isCollapse ? 0 : 35}
              maxSize={isSmallScreen ? 55 : 50}
              collapsible={!isSmallScreen}
              style={{
                width: isSmallScreen ? "100%" : "auto",
                transform: isCollapse ? "translateX(-10%)" : "translateX(0)",
                display: isCollapse ? "none" : "block",
              }}
            >
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="lg"
                pr={0}
                pt={15}
                style={{
                  boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                  maxHeight: height + 55,
                  minHeight: height + 55,
                }}
              >
                <ScrollArea w="100%" pr={5} h={height + 600} offsetScrollbars>
                  <Grid>
                    <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Mã báo nợ
                        </Text>
                        <Text fw={600}>{listdata?.receiptNumber || ""}</Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Thời gian tạo
                        </Text>
                        <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                          <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                            <>
                              {moment(currentTime).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </>
                          </Text>
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 12, lg: 6 }} pb={0}>
                      <Flex align="center">
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Mã giao dịch
                        </Text>
                        <TextInput
                          flex={1}
                          className={classesButton.text_input}
                          disabled={statusReponse}
                          // error={
                          //   formErrors.attribute8
                          //     ? formErrors.attribute8
                          //     : undefined
                          // }
                          placeholder="Nhập mã giao dịch"
                          onKeyPress={(e) => {
                            if (e.key === " ") {
                              e.preventDefault(); // Ngăn không cho nhập khoảng trắng
                            }
                          }}
                          onChange={(e) =>
                            handleChange("attribute8", e.target.value)
                          }
                        />
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 12, lg: 6 }} pb={0}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 150px", whiteSpace: "nowrap" }}
                        >
                          Thời gian hoàn thành
                        </Text>
                        <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                          {handelDateCurrent && statusReponse ? (
                            <>
                              {moment(completeTime).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </>
                          ) : null}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Người lập báo nợ
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Chi nhánh
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {dataBranch.find(
                            (item) =>
                              String(item.value) ===
                              String(listdata?.creatorbranch)
                          )?.text ?? ""}
                        </Text>
                      </Flex>
                    </Grid.Col>

                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Họ và tên
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {`${userData?.fullName ?? ""}`}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <GridCol pt={0} pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          ĐĐ làm việc
                        </Text>
                        <Text fw="600">{branchDeparment?.address ?? ""}</Text>
                      </Flex>
                    </GridCol>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Chức danh
                        </Text>
                        <Text fw="600">{userData?.roleName}</Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Phòng ban
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {branchAll?.find(
                            (item) =>
                              String(item.departmentId) ===
                              String(listdata?.creatordepartment)
                          )?.name ?? ""}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Người nhận tiền
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={6}>
                      <EditableTextWithOptions
                        readOnly={statusReponse ? true : false}
                        required
                        label="Loại"
                        value={formData.payertype}
                        options={["Nội bộ", "Khách hàng", "NCC", "Khác"]}
                        onChange={(value) => {
                          setDebtData([]);
                          setSelectedItems([]);
                          setInputValues([]);
                          table.setCreatingRow(null);
                          setOccurringCredits(0);
                          handleChange("payertype", value);
                          handleChange("funfullname", null);
                          handleChange("funddepartment", null);
                          handleChange("attribute2", null);
                          handleChange("attribute3", null);
                          handleChange("attribute7", null);
                          handleChange("fundbranch", null);
                          handleChange("fundworkplace", null);
                          handleChange("codeCustomer", null);
                          handleChange("anotherName", "");
                          handleChange("phoneNumber", "");
                          setFormErrors((prevErrors) => ({
                            ...prevErrors,
                            anotherName: "",
                            attribute2: "",
                            attribute3: "",
                            fundbranch: "",
                            fundworkplace: "",
                            funfullname: "",
                            funddepartment: "",
                            phoneNumber: "",
                            codeCustomer: "",
                          }));
                        }}
                        width="100px"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 150px", whiteSpace: "nowrap" }}
                        >
                          Đơn vị chuyển khoản
                        </Text>
                        <Text fw={600}>
                          {(() => {
                            const x = dataBranch?.find(
                              (item: SelectListItemBase) =>
                                item.att2 === formData.attribute10
                            );
                            return x ? `${x.att2} - ${x.text}` : "";
                          })()}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Nội bộ
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    {formInternal()}
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Khác
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    {formOther()}
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Đối tượng công nợ
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      {DebtObject()}
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Tỷ giá
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Flex ml={10} mr={10} align="center">
                      <Table layout="fixed" withTableBorder withColumnBorders>
                        <Table.Tbody>
                          <Table.Tr>
                            <Table.Td w={250} align="left">
                              <Text fw={600}>
                                Đơn vị tính
                                <span style={{ color: "red" }}>*</span>
                              </Text>
                            </Table.Td>
                            <Table.Td align="center">
                              <Text fw={600}>Tỉ giá</Text>
                            </Table.Td>
                            <Table.Td align="center">
                              <Text fw={600}>Thành tiền</Text>
                            </Table.Td>
                          </Table.Tr>
                          <Table.Tr>
                            <Table.Td>
                              <EditableTextWithOptions
                                label=""
                                isCod={true}
                                readOnly={statusReponse ? true : false}
                                value={
                                  dataExchangeRate?.find(
                                    (item: any) =>
                                      item.id === formData.accountfund
                                  )?.calculation || "Tiền mặt VND tại Công ty"
                                }
                                options={
                                  dataExchangeRate
                                    ?.map((e: any) => e.calculation)
                                    .filter(
                                      (calculation): calculation is string =>
                                        !!calculation
                                    ) ?? []
                                }
                                onChange={(selectedDescription) => {
                                  const selectedOption = dataExchangeRate?.find(
                                    (item: any) =>
                                      item.calculation === selectedDescription
                                  );
                                  if (selectedOption) {
                                    handleChange(
                                      "accountfund",
                                      selectedOption.id
                                    );
                                    // handleChange(
                                    //   "attribute6",
                                    //   selectedOption.calculation
                                    // );
                                    handleChangeRate(
                                      selectedOption.calculation
                                    );
                                  }
                                }}
                              />
                            </Table.Td>
                            <Table.Td align="right">
                              <NumberFormatter
                                style={{ fontWeight: "bold" }}
                                value={selectedValue}
                                thousandSeparator
                              ></NumberFormatter>
                            </Table.Td>
                            <Table.Td align="right">
                              <NumberFormatter
                                value={
                                  (Math.abs(occurringCredits) ?? 0) *
                                  (selectedValue ?? 0)
                                }
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  color: "red",
                                }}
                                thousandSeparator
                              />
                            </Table.Td>
                          </Table.Tr>
                        </Table.Tbody>
                      </Table>
                    </Flex>
                  </Grid>
                </ScrollArea>
              </Card>
            </Panel>
            {!isCollapse && !isSmallScreen && (
              <PanelResizeHandle
                style={{ position: "relative" }}
                id="resize-handle"
              >
                <Box
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: "100",
                  }}
                >
                  <ActionIcon
                    size="sm"
                    radius="md"
                    variant="default"
                    aria-label="Settings"
                  >
                    <IconGripVertical size={16} />
                  </ActionIcon>
                </Box>
              </PanelResizeHandle>
            )}
            <Panel
              defaultSize={isSmallScreen ? 45 : isCollapse ? 100 : 55}
              minSize={isSmallScreen ? 45 : 35}
              maxSize={isSmallScreen ? 45 : isCollapse ? 100 : 65}
              style={{
                width: isSmallScreen ? "100%" : "auto",
              }}
            >
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="md"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                  maxHeight: height + 55,
                  minHeight: height + 55,
                }}
              >
                <Box>
                  {isCollapse ? null : (
                    <>
                      <Flex mb={15} align="center">
                        <Text
                          style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}
                          fw={600}
                        >
                          Tài khoản có <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Table layout="fixed" withTableBorder withColumnBorders>
                          <Table.Tbody>
                            <Table.Tr>
                              <Table.Td w={250}>
                                <Select
                                  searchable
                                  limit={50}
                                  className={classesButton.text_input}
                                  value={dataAcount
                                    .find(
                                      (x) =>
                                        String(x.accountantAccount) ===
                                        String(formData.codefund ?? 0)
                                    )
                                    ?.id.toString()}
                                  data={dataAcount.map((ele) => {
                                    return {
                                      label: ele.accountantAccount ?? "",
                                      value: ele.id.toString(),
                                      description:
                                        ele.accountantAccountName ?? "",
                                    };
                                  })}
                                  disabled={statusReponse}
                                  placeholder="Vui lòng nhập"
                                  onClick={async () =>
                                    await fetchDataReceiptFee()
                                  }
                                  onChange={(selectedDescription) => {
                                    const selectedOption = dataAcount?.find(
                                      (item: any) =>
                                        String(item.id) ===
                                        String(selectedDescription)
                                    );
                                    // handleInputChange(e, row.index, "fee");
                                    if (selectedOption) {
                                      handleChange(
                                        "codefund",
                                        selectedOption.accountantAccount
                                      );
                                      handleChange(
                                        "attribute6",
                                        selectedOption.accountantAccountName ??
                                          ""
                                      );
                                    }
                                  }}
                                  renderOption={({ option, checked }) => (
                                    <Group flex="1" gap="xs">
                                      {option.label} {" - "}
                                      {dataAcount.find(
                                        (x) =>
                                          String(x.id) === String(option.value)
                                      )?.accountantAccountName ?? ""}
                                      {checked && (
                                        <IconCheck
                                          fontWeight={600}
                                          size={20}
                                          color="green"
                                          style={{ marginInlineStart: "auto" }}
                                        />
                                      )}
                                    </Group>
                                  )}
                                  error={
                                    formErrors.codefund
                                      ? formErrors.codefund
                                      : undefined
                                  }
                                />
                              </Table.Td>
                              <Table.Td align="left">
                                <Text fw={600}>
                                  {dataAcount.find(
                                    (x) =>
                                      String(x.accountantAccountName) ===
                                      String(formData.attribute6)
                                  )?.accountantAccountName ??
                                    "Vui lòng chọn tài khoản có"}
                                </Text>
                              </Table.Td>
                            </Table.Tr>
                          </Table.Tbody>
                        </Table>
                      </Flex>
                    </>
                  )}
                  <Group>
                    <MantineReactTable table={table} />
                  </Group>
                  <Group justify="space-between" mt={20}>
                    <Flex w="60%" align="center" gap={20}>
                      <Text
                        style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}
                        fw={600}
                        c="red"
                      >
                        Tổng tiền:
                      </Text>
                      <Text fw={700} c="red">
                        <NumberFormatter
                          thousandSeparator=","
                          value={Math.abs(occurringCredits)}
                        />
                      </Text>
                    </Flex>
                    <Button
                      variant="filled"
                      disabled={statusReponse}
                      className={classesButton.button}
                      onClick={() => {
                        fetchCreateReceiptParent(true);
                        setHandelDateCurrent(true);
                        handleComplete();
                      }}
                      leftSection={<IconCheck size={14} />}
                      color="#36a94b"
                    >
                      Hoàn thành
                    </Button>
                  </Group>
                </Box>
              </Card>
            </Panel>
          </PanelGroup>
        </Box>
      </Box>
    </>
  );
};

export default DebtNoteBranchs;
