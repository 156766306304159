import { Grid, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import DetailProduct from "./DetailProduct";

const DeliveryDetailByReturnCustomer = ({
  id,
  setParkingFee,
}: {
  id: any;
  setParkingFee: any;
}) => {
  const [dataDetail, setDataDetail] = useState<any>();

  useEffect(() => {
    setParkingFee(dataDetail?.parkingFee);
  }, [dataDetail]);

  return (
    <Grid>
      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
        <TextInput
          label="Số phiếu xuất trả khách"
          value={dataDetail?.sourceCode}
          variant="filled"
          readOnly
          size="xs"
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <DetailProduct
          id={id}
          sourceCode={null}
          sourceType={5}
          isDetail={true}
          setDataOrder={setDataDetail}
        />
      </Grid.Col>
    </Grid>
  );
};

export default DeliveryDetailByReturnCustomer;
