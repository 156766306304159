import React, { createContext, useContext, useEffect, useState } from "react";

import {
  Link,
  NavigateFunction,
  useLocation,
  useMatches,
  useNavigate,
} from "react-router-dom";
import { isNullOrEmpty } from "../../extension/StringExtension";
import { SelectListItem } from "../../../model/SelectListItem";
import { IBreadCrumbs } from "../../model/_share/breadCrumbs";
import {
  Breadcrumbs,
  Anchor,
  Button,
  Flex,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowRight,
  IconChevronLeft,
  IconLetterF,
} from "@tabler/icons-react";
import { IconArrowLeft } from "@tabler/icons-react";
import { IconChevronRight } from "@tabler/icons-react";
const BreadcrumbLayout1 = () => {
  const navigate = useNavigate();
  //
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadCrumbs[]>();
  let matches = useMatches();
  //
  useEffect(() => {
    const data = BreadcrumbLayout(matches);
    setBreadcrumbs(data);
  }, [location.pathname]);

  function BreadcrumbLayout(matches: any) {
    const breadcrumbsData: IBreadCrumbs[] = [];
    breadcrumbsData.push({
      title: "Trang chủ",
      iconCss: "e-icons e-home",
      link: "/",
      onClick: () => {
        navigate(url);
      },
    });
    let crumbs = matches
      .filter((match: any) => Boolean(match.handle?.crumb))
      .map((match: any) => match.handle.crumb(match.data));
    let url = "";
    for (let index = 0; index < crumbs.length; index++) {
      const element: SelectListItem = crumbs[index];
      if (element && !isNullOrEmpty(element.text) && element.value !== "/") {
        url = url + element.value;
        if (index !== crumbs.length - 1) {
          if (!element.disabled)
            breadcrumbsData.push({
              title: element.text,
              onClick: () => {
                //  navigate(url);
              },
            });
          else
            breadcrumbsData.push({
              title: element.text,
              onClick: () => {
                //  navigate(url);
              },
            });
        } else
          breadcrumbsData.push({
            title: element.text,
            onClick: () => {
              //  navigate(url);
            },
          });
      }
    }
    return breadcrumbsData;
  }

  const items = breadcrumbs?.map((item, index) =>
    index === 0 ? (
      <Anchor href={item.link} key={index}>
        {item.title}
      </Anchor>
    ) : (
      <p key={index}>{item.title}</p>
    )
  );

  const _render = () => {
    if (
      breadcrumbs !== undefined &&
      breadcrumbs !== null &&
      breadcrumbs.length > 0
    ) {
      return (
        <>
          <Flex align={"center"}>
            <Breadcrumbs key={"Breadcrumbs"}>{items}</Breadcrumbs>
            <Flex ml={20} align="center" justify="center" gap={10}>
              <ActionIcon
                onClick={() => navigate(-1)}
                variant="light"
                radius="xl"
                aria-label="Settings"
              >
                <Tooltip label="Trang trước">
                  <IconArrowLeft stroke={1.5} />
                </Tooltip>
              </ActionIcon>
              <ActionIcon
                onClick={() => navigate(1)}
                radius="xl"
                variant="light"
                aria-label="Settings"
              >
                <Tooltip label="Trang sau">
                  <IconArrowRight stroke={1.5} />
                </Tooltip>
              </ActionIcon>
            </Flex>
          </Flex>
        </>
      );
    }
  };
  const renderComponent = () => {
    if (!(localStorage.getItem("checkedTabs") === "true")) {
      return <>{_render()}</>; // Hiển thị Breadcrumbs nếu checkedTabs là true
    } else {
      return (
        <>
          <div style={{ marginBottom: "1px" }}></div>
        </>
      );
    }
  };

  //  return <>{_render()}</>;
  return renderComponent();
};

export default BreadcrumbLayout1;
