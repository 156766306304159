import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMExpense } from "../../../../model/TblDMExpense";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { useEffect, useState } from "react";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";

const CreateDataView = ({ onClose }: CreateDataViewProps) => {
  type AccountData = {
    accountLevel: number;
    accountantAccount: string;
    accountantAccountName: string;
    createBy: string | null;
    createDate: string | null;
    id: number;
    lastUpdateBy: string | null;
    lastUpdateDate: string | null;
    value: string;
    label: string;
  };
  const [dataAccount, setDataAccount] = useState<AccountData[]>([]);
  const entity = {
    id: "0",
    code: "",
    name: "",
    attribute1: "",
    attribute2: "",
    note: null,
    active: false,
    createBy: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMExpense>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      name: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên chi phí!";
        }
        return hasLength(
          { min: 5, max: 150 },
          "Tên phải từ 5-150 kí tự!"
        )(value as string);
      },
      code: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã chi phí!";
        }
        return hasLength(
          { max: 10 },
          "Mã phải nhỏ hơn 10 kí tự!"
        )(value as string);
      },
      note: (value: string | null) => {
        if (value) {
          return hasLength(
            { max: 250 },
            "Ghi chú phải nhỏ hơn 250 kí tự!"
          )(value as string);
        }
      },
    },
  });

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<MessageResponse<TblDMExpense>>(
      "/api/v1/TblDMExpense/create"
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Bạn không có quyền tạo!!!");
      modals.closeAll();
    }
  };
  const GetdataAccount = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmAccount/get-all?Level=3"
    );
    if (res && res?.success) {
      const dataApi = res.data;
      setDataAccount(
        dataApi
          .filter(
            (item: any) =>
              item.accountantAccount != null &&
              item.accountantAccountName != null
          )
          .map((item: any) => ({
            value: item.accountantAccount,
            label: item.accountantAccountName,
          }))
      );
    }
  };
  const handleCreateTblDMExpense = async (dataSubmit: TblDMExpense) => {
    open();
    const dataApi = await repositoryMdm.post<MessageResponse<TblDMExpense>>(
      "/api/v1/TblDmExpense/create",
      dataSubmit
    );
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  useEffect(() => {
    callApiGetData();
  }, []);
  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "280px", md: "336px", lg: "448px" }}
        onSubmit={form.onSubmit((e: TblDMExpense) => {
          handleCreateTblDMExpense(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={4}>
            <TextInput
              label={"Mã chi phí"}
              placeholder={"Nhập mã chi phí"}
              withAsterisk
              onKeyDown={handleKeyDown}
              {...form.getInputProps("code")}
            />
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              label={"Tên chi phí"}
              placeholder={"Nhập tên chi phí"}
              withAsterisk
              w={"100%"}
              {...form.getInputProps("name")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <Select
              label={"Tên tài khoản kế toán"}
              placeholder={"Nhập tên tài khoản kế toán"}
              withAsterisk
              data={dataAccount}
              w={"100%"}
              searchable
              {...form.getInputProps("attribute1")}
              onClick={() => {
                if (dataAccount.length === 0) {
                  GetdataAccount();
                }
              }}
              onChange={(value) => {
                form.setFieldValue("attribute1", value);
                const selectedAccount = dataAccount.find(
                  (item) => item.value === value
                );
                if (selectedAccount) {
                  form.setFieldValue("attribute2", selectedAccount.label);
                }
              }}
            />
          </Grid.Col>
        </Grid>
        <Grid align="center">
          <Grid.Col span={12}>
            <Textarea
              label={"Ghi chú"}
              placeholder="Nhập ghi chú"
              w={"100%"}
              {...form.getInputProps("note")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox label={"Sử dụng"} {...form.getInputProps("active")} />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
};
