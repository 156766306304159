import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  List,
  NumberFormatter,
  Select,
  SelectProps,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconBuildingSkyscraper,
  IconCheck,
  IconCoinPound,
  IconDownload,
  IconEdit,
  IconMoneybag,
  IconPencil,
  IconSearch,
  IconTicket,
  IconTimeDuration15,
  IconUser,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DatePickerInput } from "@mantine/dates";
import { useDebouncedCallback } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import * as xlsx from "xlsx";
import { repositoryPos, repositoryMdm } from "../../_base/_const/_constVar";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { SelectListItemBase } from "../../_base/model/_base/SelectListItemBase";
import { AvatarUtils } from "../../common/ColorByName/AvatarUtils";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import useWindowSize from "../../common/WindownSize/WindownSize";
import { BASE_API_POS } from "../../config";
import { _localization } from "../../config/location";
import { green, orange, purple } from "../../const/variables";
import { MessageResponse } from "../../model/MessageResponse";
import { SelectListItem } from "../../model/SelectListItem";
import {
  PaymentReceiptDetail,
  PaymentReceiptSummary,
  ReportPaymentReceiptDetail,
} from "../../model/Report/ReportPaymentReceiptDetail";

interface DataList {
  lists: any[];
  count: number;
}

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

interface DepartmentUser {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  departmentCode: string;
  creationDate: string;
  createdBy: string;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
}

interface Program {
  id: number;
  code: string;
  name: string;
  createBy: string | null;
  createDate: string;
  lastUpdateDate: string | null;
  lastUpdateBy: string | null;
}

interface Permission {
  id: number;
  code: string;
  name: string;
  createBy: string | null;
  createDate: string;
  lastUpdateDate: string | null;
  lastUpdateBy: string | null;
}

interface AddressInfo {
  active: boolean;
  address: string;
  birthOfDate: string;
  code: string;
  buyerName: string;
  commune: number;
  companyName: string | null;
  contact: string | null;
  country: string | null;
  debtLimit: number | null;
  description: string | null;
  district: number;
  email: string;
  fax: string | null;
  groupId: number;
  groupName: string;
  id: number;
  name: string;
  province: number;
  sex: number;
  taxCode: string | null;
  tblDmCustomerGroupModel: any | null;
  telephoneNumber: string;
  type: number;
}

interface Customer {
  addr: string;
  createdBy: number;
  creationDate: string;
  creditLimit: number;
  email: string;
  employeeId: number;
  enabledFlag: boolean | null;
  endDateActive: string;
  id: number;
  orgId: number;
  phone: string;
  startDateActive: string;
  vatRegistrationNum: string;
  vendorCode: string;
  vendorName: string;
  vendorTypeLookupCode: string;
}
interface Customer {
  addr: string;
  createdBy: number;
  creationDate: string;
  creditLimit: number;
  email: string;
  employeeId: number;
  enabledFlag: boolean | null;
  endDateActive: string;
  id: number;
  orgId: number;
  phone: string;
  startDateActive: string;
  vatRegistrationNum: string;
  vendorCode: string;
  vendorName: string;
  vendorTypeLookupCode: string;
}

interface User {
  id: number;
  code: string;
  fullname: string;
  dateOfBirth: string;
  gender: number;
  departmentId: number | null;
  departmentName: string | null;
  roleId: number;
  roleName: string;
  managerName: string | null;
  address: string | null;
  phoneNumber: string;
  active: boolean;
  email: string;
  description: string | null;
  accountingCenterId: number | null;
  managerId: number | null;
  isCrm: boolean | null;
  currentCardId: number;
  partCode: string | null;
  createDate: string;
  listBranch: string | null;
  listInventory: string | null;
  listGroup: string;
  branchId: number;
  branchName: string;
  workAddress: string | null;
}

const ReportPaymentReceiptViewV2 = () => {
  const navigate = useNavigate();
  //#region  table
  //data and fetching state
  const [data, setData] = useState<PaymentReceiptDetail[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [dataFetch, setDataFetch] = useState<SelectListItem[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<SelectListItemBase[]>([]);
  // const [handleType, setHandeValueType] = useState<string>("");
  const [dataListCustomer, setDataListCustomer] = useState<SelectListItem[]>(
    []
  );
  const [addressInfoDetail, setAddressInfo] = useState<AddressInfo[]>([]);
  const [customerOrders, setCustomerOrders] = useState<
    DepartmentCustomerOrder[]
  >([]);
  const [branchAll, setBranchAll] = useState<DepartmentUser[]>([]);

  const [dataGetReceiptContract, setDataGetReceiptContract] = useState<
    Program[]
  >([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [typeHande, setTyleHandle] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(1);
  const [take, setTake] = useState<number>(30);
  const [searchTerm, setSearchTerm] = useState<string>("");
  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  // const [typeKey, settypeKey] = useState<number | null>(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  //
  const [dataGetReceiptFee, setDataReceiptFee] = useState<Permission[]>([]);
  const [customerDetail, setCustomerDetail] = useState<Customer[]>([]);
  const debounceTimeoutRef = React.useRef<number | null>(null);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);
  const [dataBranchs, setDataBranchs] = useState<SelectListItemBase[]>([]);
  const [sumData, setSumData] = useState<PaymentReceiptSummary>({
    duDau: 0,
    duCuoi: 0,
    sumChi: 0,
    sumThu: 0,
  });
  const size = useWindowSize();

  const [search, setSearch] = useState({
    keySearch: "",
    customerId: "",
    startDate: "",
    endDate: "",
    Codefund: "111101",
    accountfund: "",
    deartment: "",
    branch: "488",
    Type: "",
    workplace: "",
    creator: "",
    typeReceipt: "",
    BranchSale: "",
  });

  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const sizeGrid = { base: 6, sm: 2, md: 2, lg: 2, xl: 2 };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        CustomerId: search.customerId,
        StartDate: search.startDate,
        EndDate: search.endDate,
        KeySearch: (search.keySearch || keySearch).toLowerCase(),
        Codefund: search.Codefund,
        Accountfund: search.accountfund,
        Creator: search.creator,
        Workplace: search.workplace,
        Branch: search.branch,
        Type: search.Type || search.Type,
        TypeReceipt: search.typeReceipt,
        BranchSale: search.BranchSale,
      };

      let sortingQuery = "";
      if (sorting && sorting.length > 0) {
        const { id, desc } = sorting[0] || {};
        if (id) {
          sortingQuery = `&sort=${encodeURIComponent(id)}&isOrder=${desc}`;
        }
      }

      const url =
        Object.entries(queryParams)
          .reduce((acc, [key, value]) => {
            if (value !== undefined && value !== "") {
              return `${acc}&${key}=${encodeURIComponent(value)}`;
            }
            return acc;
          }, "/api/v1/Report/payment-receipt-v2?")
          .replace("?&", "?") + sortingQuery;

      const response = await repositoryPos.get<
        MessageResponse<ReportPaymentReceiptDetail>
      >(url);

      if (response && response.success) {
        const dataList = response?.data;
        setData(dataList.paymentReceiptDetail);
        setRowCount(dataList?.count);
        setSumData(dataList.paymentReceiptSummary);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  // Hàm gọi API phòng ban
  const fetchDataSelect = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDepartment/get-select"
    );

    if (dataApi && dataApi.success) {
      setDataFetch(dataApi.data);
    }
  };
  // Hàm gọi API chi nhánh
  const fetchDataBranch = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<SelectListItem[]>>(
      "/api/v1/TblDmInventory/get-select-branch"
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.slice(1);
      setDataBranch(filteredData);
    }
  };

  const fetchDataWarehouse = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-select"
    );

    if (dataApi && dataApi.success) {
      setDataWarehouse(dataApi.data);
    }
  };

  const getTblDmExpense = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmExpense/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataCodeFunch(result);
    }
  };

  const fetchDataReceiptFee = async () => {
    const response = await repositoryPos.get<MessageResponse<Permission[]>>(
      "/api/v1/TblDmFee/get-list?Skip=0&Take=9999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataReceiptFee(result);
    }
  };

  const fetchDataGetAll = async () => {
    try {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentUser[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        const result = response.data;
        setBranchAll(result);
      } else {
        console.error("Failed to fetch departmentFund details");
      }
    } catch (error) {
      console.error("Error fetching departmentFund details", error);
    }
  };

  const fetchDataReceiptContract = async () => {
    const response = await repositoryPos.get<MessageResponse<Program[]>>(
      "/api/v1/TblDmContract/get-list?Skip=0&Take=99999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptContract(result);
    }
  };

  useEffect(() => {
    getTblDmExpense();
    fetchDataGetAll();
    fetchDataReceiptFee();
    fetchDataWarehouse();
    fetchDataReceiptContract();
    fetchDataBranch();
    fetchDataCustomer("");
    fetchDataBranchs();
    fetchDataTblVendor();
    fetchDataCustomerDetail();
    fetchDataTblDmCustomer();
  }, []);

  const handleChangeSearchValue = useDebouncedCallback(
    (value: string, key: string) => {
      // if (key === "customerId") {
      //   setSelectedCustomer(value);
      // }
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    },
    50
  );

  const handleRangeChange = (dates: [Date | null, Date | null]) => {
    console.log(dates);
    setValue(dates);
    const [startDate, endDate] = dates;
    const startDateISO = startDate ? startDate.toLocaleDateString() : null;
    const endDateISO = endDate ? endDate.toLocaleDateString() : null;

    handleChangeSearchValue(startDateISO ?? "", "startDate");

    handleChangeSearchValue(endDateISO ?? "", "endDate");
  };

  const parseAttribute5Data = (row: any) => {
    try {
      const parsedData = row.attribute5 ? JSON.parse(row.attribute5) : [];
      return Array.isArray(parsedData) ? parsedData : [];
    } catch {
      return [];
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<PaymentReceiptDetail>[]>(
    () => [
      {
        accessorKey: "createdDate",
        enableColumnActions: false,
        header: "Ngày lập chứng từ",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "completionTime",
        enableColumnActions: false,
        header: "Ngày ghi sổ",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "receiptNumber",
        enableColumnActions: false,
        header: "Số chứng từ",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge bd="1px solid blue" variant="light" color="blue">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },

      {
        accessorKey: "creatorBranch",
        enableColumnActions: false,
        header: "Chi nhánh tạo",
        footer: "Tổng phát sinh:",
        Header(props) {
          return (
            <div>
              <Text c="red" fw={800}>
                {props.column.columnDef.header}
              </Text>
              <br></br>
              <Text fw={600}>{"Dư đầu"}</Text>
            </div>
          );
        },
        Footer(props) {
          return (
            <div>
              <Text c="red" fw={800}>
                {props.column.columnDef.footer}
              </Text>
              <br></br>
              <Text c="red" fw={800}>
                {"Dư cuối:"}
              </Text>
            </div>
          );
        },
      },

      {
        accessorKey: "creatorsalesbranch",
        enableColumnActions: false,
        header: "Chi nhánh bán",
        // Cell: ({ renderedCellValue, cell }) => (
        //   <Text fw={600}>
        //     {renderedCellValue === null ? null : renderedCellValue}
        //   </Text>
        // ),
      },

      {
        accessorKey: "thu",
        enableColumnActions: false,
        header: "Thu",
        Footer(props) {
          return (
            <Text fw={800} c="red">
              <NumberFormatter
                value={sumData.sumThu}
                suffix=" ₫"
                thousandSeparator=","
              />
            </Text>
          );
        },
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix=" ₫"
            thousandSeparator=","
          />
        ),
      },

      {
        accessorKey: "chi",
        enableColumnActions: false,
        header: "Chi",
        Footer(props) {
          return (
            <Text fw={800} c="red">
              <NumberFormatter
                value={sumData.sumChi}
                suffix=" ₫"
                thousandSeparator=","
              />
            </Text>
          );
        },
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix=" ₫"
            thousandSeparator=","
          />
        ),
      },

      {
        accessorKey: "ton",
        enableColumnActions: false,
        header: "Tồn",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix=" ₫"
            thousandSeparator=","
          />
        ),
        Header(props) {
          return (
            <div>
              <Text c="red" fw={800}>
                {props.column.columnDef.header}
              </Text>
              <br></br>
              <NumberFormatter
                value={sumData.duDau}
                suffix=" ₫"
                thousandSeparator=","
              />{" "}
            </div>
          );
        },
        Footer(props) {
          return (
            <Text fw={800} c="red">
              <Text fw={800}>{0}</Text>
              <br></br>
              <NumberFormatter
                value={sumData.duCuoi}
                suffix=" ₫"
                thousandSeparator=","
              />
            </Text>
          );
        },
      },
      {
        accessorKey: "codefund",
        enableColumnActions: false,
        header: "Tài khoản",
      },
      {
        accessorKey: "payerDescription",
        enableColumnActions: false,
        header: "Nội dung",
        // Cell: ({ renderedCellValue, cell }) => (
        //   <Text fw={600}>
        //     {renderedCellValue === null ? null : renderedCellValue}
        //   </Text>
        // ),
      },
      {
        accessorKey: "codeCustomer",
        enableColumnActions: false,
        header: "Mã khách hàng",

        // Cell: ({ renderedCellValue, cell }) => (
        //   <Text fw={600}>
        //     {renderedCellValue === null ? null : renderedCellValue}
        //   </Text>
        // ),
      },
      {
        accessorKey: "attributE2",
        enableColumnActions: false,
        header: "Nhóm công nợ",
        // Cell: ({ renderedCellValue, cell }) => (
        //   <Text fw={600}>
        //     {renderedCellValue === null ? null : renderedCellValue}
        //   </Text>
        // ),
      },
    ],
    [sumData]
  );

  const table = useMantineReactTable<PaymentReceiptDetail>({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    enableStickyHeader: true,
    enableStickyFooter: true,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => <></>,
    // mantineTableHeadCellProps: {
    //   align: "center",
    // },
    //  enableStickyHeader: true,
    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false,
    // getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["receiptNumber", "mrt-row-select"],
        right: ["totalAmount", "actions"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    mantineTableProps: {
      striped: false,
    },
    enableSorting: false,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,

    //  onColumnFiltersChange: setColumnFilters,
    //  onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    //   onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: 550, //conditional styling
        fontSize: "12.5px",
        padding: "10px 15px",
      },
    }),
    state: {
      //  columnFilters,
      //  globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //  sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
  });

  const transformToComboboxItem = (item: SelectListItemBase): ComboboxItem => {
    return {
      label: item.text,
      value: item.value,
    };
  };

  const fetchDataCustomer = async (searchTerm: string) => {
    setLoading(true);
    const query = searchTerm ? `KeySearch=${searchTerm}&` : "";
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-select?${query}Skip=${1}&Take=${30}`
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.filter(
        (item: any) => item.text !== null
      );
      setDataListCustomer(filteredData);
      setSkip((prevSkip) => prevSkip + 1);
    }
    setLoading(false);
  };

  const debouncedFetchDataCustomer = React.useCallback((value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      fetchDataCustomer(value);
    }, 300);
  }, []);

  const fetchDataCustomerDetail = async () => {
    let url = `?Skip=0&Take=9999`;

    try {
      // Fetch NCC data
      const nccResponse = await repositoryMdm.get(`/api/v1/TblVendor/get-all`);
      const nccData = nccResponse?.success ? nccResponse.data : [];

      // Map NCC data
      const nccMappedData: DepartmentCustomerOrder[] = nccData.map(
        (customer: any) => ({
          id: customer.id,
          name: customer.vendorCode,
          details: {
            fullName: customer.vendorName,
            phoneNumber: customer.phone ?? "",
          },
        })
      );

      // Fetch Khách hàng data
      const customerResponse = await repositoryPos.get(
        `/api/v1/TblDmCustomer/get-list${url}`
      );
      const customerData = customerResponse?.success
        ? customerResponse.data
        : [];

      // Map Khách hàng data
      const customerMappedData: DepartmentCustomerOrder[] = customerData.map(
        (customer: any) => ({
          id: customer.id,
          name: customer.code,
          details: {
            fullName: customer.name,
            phoneNumber: customer.telephoneNumber,
          },
        })
      );

      // Combine both NCC and Khách hàng data
      const mappedData = [...nccMappedData, ...customerMappedData];
      setCustomerOrders(mappedData);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const fetchDataBranchs = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmInventory/get-select-branch?auth=false");

    if (response && response.success) {
      let result = response.data;
      setDataBranchs(result);
    }
  };

  const fetchDataTblDmCustomer = async () => {
    const response = await repositoryPos.get<MessageResponse<AddressInfo[]>>(
      "/api/v1/TblDmCustomer/get-list?Skip=0&Take=50"
    );

    if (response && response.success) {
      let result = response.data;
      setAddressInfo(result);
    }
  };

  const fetchDataTblVendor = async () => {
    const response = await repositoryMdm.get<MessageResponse<Customer[]>>(
      "/api/v1/TblVendor/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setCustomerDetail(result);
    }
  };

  useEffect(() => {
    debouncedFetchDataCustomer(searchTerm);
  }, [searchTerm, debouncedFetchDataCustomer]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (pagination.pageIndex === 0 && data.length < pagination.pageSize) {
      fetchData();
    } else {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  // Icon color mappings based on value
  const optionColors: Record<
    "Tiền dư" | "Khác" | "Thanh lý" | "Hoàn ứng công tác" | "Hoàn ứng thu mua",
    string
  > = {
    "Tiền dư": "cyan",
    Khác: "green",
    "Thanh lý": "blue",
    "Hoàn ứng công tác": orange.medium,
    "Hoàn ứng thu mua": purple.medium,
  };

  // Render function to customize the option appearance
  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColors[
            option.label as
              | "Tiền dư"
              | "Khác"
              | "Thanh lý"
              | "Hoàn ứng công tác"
              | "Hoàn ứng thu mua"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  // account fund
  const optionColorsAccount: Record<
    "VND" | "USD" | "Vàng SJC 9999" | "Vàng PNJ 9999",
    string
  > = {
    VND: "cyan",
    USD: "green",
    "Vàng SJC 9999": "blue",
    "Vàng PNJ 9999": orange.medium,
  };

  const dataAccountFund = [
    { value: "1", label: "VND" },
    { value: "2", label: "USD" },
    { value: "3", label: "Vàng SJC 9999" },
    { value: "4", label: "Vàng PNJ 9999" },
  ];
  // Render function to customize the option appearance
  const renderSelectOptionAccount: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsAccount[
            option.label as "VND" | "USD" | "Vàng SJC 9999" | "Vàng PNJ 9999"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const TypeTicket: SelectProps["renderOption"] = ({ option, checked }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={option.value === "1" ? "red" : "yellow"}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const renderSelectOptionType: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={option.value === "1" ? "blue" : "green"}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const handleExport = async () => {
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        CustomerId: search.customerId,
        StartDate: search.startDate,
        EndDate: search.endDate,
        KeySearch: (search.keySearch || keySearch).toLowerCase(),
        Codefund: search.Codefund,
        Accountfund: search.accountfund,
        Creator: search.creator,
        Workplace: search.workplace,
        Branch: search.branch,
        Type: search.Type || search.Type,
        TypeReceipt: search.typeReceipt,
        BranchSale: search.BranchSale,
      };

      let sortingQuery = "";
      if (sorting && sorting.length > 0) {
        const { id, desc } = sorting[0] || {};
        if (id) {
          sortingQuery = `&sort=${encodeURIComponent(id)}&isOrder=${desc}`;
        }
      }

      const url =
        Object.entries(queryParams)
          .reduce((acc, [key, value]) => {
            if (value !== undefined && value !== "") {
              return `${acc}&${key}=${encodeURIComponent(value)}`;
            }
            return acc;
          }, "/api/v1/CreateSellItem/export-payment-receipt?")
          .replace("?&", "?") + sortingQuery;

      const _url = BASE_API_POS + url;

      const fullUrl = new URL(_url);

      const token = localStorage.getItem("token");

      fetch(fullUrl.toString(), {
        method: "GET", // Use the appropriate HTTP method
        headers: {
          Authorization: `Bearer ${token?.replace(/"/g, "")}`,
          "Content-Type": "application/vnd.ms-excel",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.blob(); // Assuming the response is a file or blob
          } else {
            throw new Error("Failed to fetch the resource");
          }
        })
        .then((blob) => {
          // Open the blob in a new tab
          const url = window.URL.createObjectURL(blob);
          const _name = new Date().toISOString().slice(0, 10);
          let filename = "bao_cao_so_quy" + "-" + _name + ".xlsx"; // Tên mặc định nếu không có header
          const link = document.createElement("a");
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Group mb={5} justify="space-between">
          <_breadcrumb></_breadcrumb>
          <Button
            leftSection={<IconDownload size={14} />}
            color="violet"
            variant="outline"
            onClick={handleExport}
          >
            Export Excel
          </Button>
        </Group>
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
            <Grid>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Từ khóa"
                  type="text"
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  leftSection={<IconSearch color="#15aabf" />}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      await fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Tài khoản"
                  key={"Codefund"}
                  type="text"
                  defaultValue={search.Codefund}
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "Codefund");
                  }}
                  leftSection={<IconMoneybag color="#15aabf" />}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      await fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Chi nhánh"
                  key={"branch"}
                  limit={20}
                  leftSection={<IconBrandBootstrap color="#15aabf" />}
                  defaultValue={search.branch}
                  value={search.branch}
                  data={dataBranch?.map((item) => {
                    return {
                      label: item.text,
                      value: item.value,
                    };
                  })}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "branch");
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={sizeGrid}
                // style={{ flexBasis: "100%", maxWidth: "320px", flex: "1" }}
              >
                <DatePickerInput
                  type="default"
                  leftSection={<IconTimeDuration15 color="#15aabf" />}
                  size="sm"
                  placeholder="Từ ngày"
                  locale="vi"
                  //   value={value}
                  valueFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    const startDateISO = e
                      ? e.toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : null;
                    handleChangeSearchValue(startDateISO ?? "", "startDate");
                  }}
                  clearable
                />
              </Grid.Col>
              <Grid.Col
                span={sizeGrid}
                // style={{ flexBasis: "100%", maxWidth: "320px", flex: "1" }}
              >
                <DatePickerInput
                  leftSection={<IconTimeDuration15 color="#15aabf" />}
                  size="sm"
                  placeholder="Đến ngày"
                  locale="vi"
                  //  value={value}
                  valueFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    const startDateISO = e
                      ? e.toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : null;
                    handleChangeSearchValue(startDateISO ?? "", "endDate");
                  }}
                  clearable
                />
              </Grid.Col>
              {/* 
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Phòng ban"
                  limit={20}
                  leftSection={<IconBuildingSkyscraper color="#15aabf" />}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataFetch
                    .filter((item) => item.text !== "Tất cả")
                    .map((item) => {
                      return {
                        label: item.text ?? "",
                        value: String(item.value ?? ""),
                      };
                    })}
                  onClick={async () => {
                    if (!dataFetch || dataFetch.length < 1) {
                      await fetchDataSelect();
                    }
                    return;
                  }}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "department");
                  }}
                />
              </Grid.Col> */}

              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Nhân viên"
                  leftSection={<IconUser color="#15aabf" />}
                  // comboboxProps={{
                  //   transitionProps: { transition: "pop", duration: 200 },
                  // }}
                  limit={50}
                  data={dataWarehouse.map((item) => {
                    return {
                      label: item.text ?? "",
                      value: String(item.value ?? ""),
                    };
                  })}
                  // onClick={async () => {
                  //   if (!dataWarehouse || dataWarehouse.length < 1) {
                  //     await fetchDataWarehouse();
                  //   }
                  //   return;
                  // }}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "creator");
                  }}
                />
              </Grid.Col>
              {/* <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Mã phí"
                  leftSection={<IconReceipt color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={transformedData}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "codefund");
                  }}
                  // renderOption={renderSelectOption}
                />
              </Grid.Col> */}
              {/* <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Tài khoản quỹ"
                  leftSection={<IconCoinPound color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataAccountFund}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "accountfund");
                  }}
                  renderOption={renderSelectOptionAccount}
                />
              </Grid.Col> */}
              {/* <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Chọn danh sách phiếu"
                  leftSection={<IconTicket color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={[
                    { value: "1", label: "Danh sách phiếu thu / chi" },
                    { value: "2", label: "Danh sách phiếu chi" },
                  ]}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "Type");
                    handelType(Number(value));
                  }}
                  renderOption={TypeTicket}
                />
              </Grid.Col> */}

              {/* <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Loại phiếu"
                  leftSection={<IconTicket color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={[
                    { value: "1", label: "Thu" },
                    { value: "2", label: "Chi" },
                    // { value: "1", label: "Phiếu chi" },
                    // { value: "2", label: "Phiếu báo nợ" },
                  ]}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "typeReceipt");
                  }}
                  renderOption={renderSelectOptionType}
                />
              </Grid.Col> */}

              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Kiểu phiếu"
                  leftSection={<IconTicket color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={[
                    { value: "1", label: "Đơn hàng" },
                    { value: "2", label: "Nội bộ" },
                    { value: "3", label: "Báo nợ" },
                    { value: "4", label: "Báo có" },
                    { value: "5", label: "Khác" },
                  ]}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "Type");
                  }}
                  //     renderOption={renderSelectOptionType}
                />
              </Grid.Col>

              <Grid.Col span={sizeGrid}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default ReportPaymentReceiptViewV2;
