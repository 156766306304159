import { Paper, Tabs } from "@mantine/core";
import React, { useState } from "react";
import SyncCheckingExist from "./SyncCheckingExist";
import GetDataCheckingExit from "./GetDataCheckingExit";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import ImportSyncChecking from "./ImportSyncChecking";

const TabSyncCheckingExit = () => {
  const [activeTab, setActiveTab] = useState<string | null>("1");

  return (
    <>
      <div>
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Paper shadow="md" radius="lg">
        <Tabs p={"xs"} value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="1">
              Đối chiếu dữ liệu đồng bộ từ POS lên ASIA
            </Tabs.Tab>
            <Tabs.Tab value="2">
              Đối chiếu dữ liệu đồng bộ từ ASIA về POS
            </Tabs.Tab>
            <Tabs.Tab value="3">Lấy dữ liệu từ ASIA về DB POS</Tabs.Tab>
            <Tabs.Tab value="4">Import dữ liệu đồng bộ</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="1">
            {activeTab === "1" ? <SyncCheckingExist type={1} /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="2">
            {activeTab === "2" ? <SyncCheckingExist type={2} /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="3">
            {activeTab === "3" ? <GetDataCheckingExit /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="4">
            {activeTab === "4" ? <ImportSyncChecking /> : <></>}
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </>
  );
};

export default TabSyncCheckingExit;
