import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Menu,
  Select,
  Text,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useHotkeys } from "@mantine/hooks";
import {
  IconArrowRight,
  IconCaretDown,
  IconEyeSearch,
  IconFileExport,
  IconFileTypography,
  IconFileUpload,
  IconSearch,
  IconTimeDuration15,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";

import { useNavigate } from "react-router";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { _localization } from "../../../../config/location";
import { MessageResponse } from "../../../../model/MessageResponse";
import { getTblInventoryTransactionTypeOutWard } from "../../../../service/getSelectApi";

import { modals } from "@mantine/modals";
import { useSearchParams } from "react-router-dom";
import { SelectListItemBase } from "../../../../_base/model/_base/SelectListItemBase";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../../common/FormatDate/FormatDate";
import InputSearch from "../../../../common/InputSearch/InputSearch";
import { green, teal } from "../../../../const/variables";
import { TblRequestInventoryTransactionList } from "../../../../model/TblRequestInventoryTransaction";
import SuggestItemWardModal from "../components/SuggestItemWardModal";
import { AvatarUtils } from "../../../../common/ColorByName/AvatarUtils";
import { getValueById } from "../../../../_base/helper/FunctionHelper";
import { DepartmentUser } from "../../../Sell/PaymentReceipt/model/modelPaymentReceipt";

const RequestOutWardTransaction = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("Type");
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  // Reference data from another table
  const [branchAll, setBranchAll] = useState<SelectListItemBase[]>([]);
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblRequestInventoryTransactionList[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const sizeGrid = { base: 6, sm: 3, md: 3, lg: 3 };
  //count

  const [search, setSearch] = useState({
    keySearch: "",
    fromDate: "",
    toDate: "",
    sourceType: "",
  });

  const fetchDataGetAll = async () => {
    try {
      const response = await repositoryPos.get<
        MessageResponse<SelectListItemBase[]>
      >(`/api/v1/TblDmInventory/get-select-branch?auth=false`);

      if (response && response.success) {
        const result = response.data;
        setBranchAll(result);
      } else {
        console.error("Failed to fetch departmentFund details");
      }
    } catch (error) {
      console.error("Error fetching departmentFund details", error);
    }
  };

  useEffect(() => {
    fetchDataGetAll();
  }, []);
  const breadcrumbs = React.useMemo(
    () => [
      {
        link: "/",
        title: "Trang chủ",
      },
      {
        title: "Cấu hình kho hàng",
      },
      {
        title: "Danh sách đơn cần xuất",
      },
    ],
    []
  );

  const items = React.useMemo(
    () =>
      breadcrumbs?.map((item, index) =>
        index === 0 ? (
          <Anchor href={item.link} key={index}>
            {item.title}
          </Anchor>
        ) : (
          <p key={index}>{item.title}</p>
        )
      ),
    [breadcrumbs]
  );

  const columns = React.useMemo<
    MRT_ColumnDef<TblRequestInventoryTransactionList>[]
  >(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        size: 30,
        enableSorting: false,
        enableResizing: false,
        Cell: ({ row: { index } }: any) => index + 1,
      },
      {
        accessorKey: "sourceTranCode",
        header: "Mã yêu cầu xuất kho",
        Cell: ({ renderedCellValue, row }: any) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() =>
                handleDetailRequest(
                  row.original.sourceType,
                  row.original.sourceTranCode
                )
              }
            >
              {renderedCellValue === null ? "Không tồn tại" : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Thời gian tạo",
        size: 150,
        Cell: ({ renderedCellValue }: any) => (
          <Text size="sm">
            {renderedCellValue && formatDateTransfer(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "finishDate",
        header: "Thời gian hoàn thành",
        size: 170,
        Cell: ({ renderedCellValue }: any) => (
          <Text size="sm">
            {renderedCellValue && formatDateTransfer(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "sourceTypeName",
        header: "Lý do",
        Cell: ({ row, renderedCellValue }) => (
          <Badge
            color={getColorType(row.original.sourceType ?? null)}
            variant="light"
            bd={`1px solid ${getColorType(row.original.sourceType ?? null)}`}
          >
            {renderedCellValue}
          </Badge>
        ),
        size: 250,
      },
      {
        accessorKey: "branchId",
        header: "Chi nhánh",
        Cell: ({ row, renderedCellValue }) =>
          branchAll.find((x) => String(x.value) === String(renderedCellValue))
            ?.text ?? "Không xác định",
        size: 250,
      },
      {
        accessorKey: "depName",
        header: "Phòng ban",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
        size: 250,
      },
      {
        accessorKey: "workAddress",
        header: "Địa điểm làm việc",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
        size: 275,
      },
      {
        accessorKey: "roleName",
        header: "Chức danh",
        Cell: ({ renderedCellValue }) => (
          <Text size="11.5px">{renderedCellValue}</Text>
        ),
        size: 250,
      },
      {
        accessorKey: "empName",
        header: "Họ & Tên",
        size: 250,
        Cell: ({ renderedCellValue, cell, row }: any) =>
          row.original.empName ? (
            <Flex align="center">
              <AvatarUtils value={row.original.empName?.split("-")[1]} /> -
              <Text fw={600}>{row.original.empName?.split("-")[0]}</Text>
            </Flex>
          ) : (
            <Text size="sm">Không xác định</Text>
          ),
      },

      {
        accessorKey: "note",
        header: "Ghi chú",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text size="sm" maw={300}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "a",
        header: "GHNN",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text size="sm" maw={300}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableResizing: false,
        size: 100,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Tạo phiếu">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="blue"
                onClick={async () => {
                  createData(
                    row.original.sourceTranCode,
                    row.original.sourceType
                  );
                }}
              >
                <IconArrowRight size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Sản phẩm đề xuất">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="violet"
                onClick={async () => {
                  openSuggestModal(
                    row?.original?.sourceTranCode ?? "",
                    row?.original?.sourceType ?? ""
                  );
                }}
              >
                <IconEyeSearch size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [branchAll]
  );

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-xuat-kho.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const createData = (
    sourceCode?: string | null,
    sourceType?: string | null
  ) => {
    if (sourceCode) {
      navigate("/warehouse-config/create-warehouse-out-ward", {
        state: { sourceCode: sourceCode, sourceType: sourceType },
      });
    } else {
      navigate("/warehouse-config/create-warehouse-out-ward");
    }
  };

  //   const editData = (id: string | number) => {
  //     navigate("/warehouse-config/edit-warehouse-out-ward", {
  //       state: { id: id },
  //     });
  //   };

  useHotkeys([
    [
      "F11",
      () => {
        createData();
      },
    ],
  ]);

  const handleDetailRequest = (sourceType: string, sourceCode: string) => {
    switch (sourceType) {
      case "XUAT_BAN":
        detailOrder(sourceCode);
        break;
      default:
        detailRequestTransfer(sourceCode);
        break;
    }
  };

  const getColorType = (type: string | null) => {
    switch (type) {
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return "#f06418";
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return "#ffab09";
      case "XUAT_HUY":
        return "#d9d02f";
      case "XUAT_DIEU_CHINH":
        return "#6bd731";
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return "#09b8ff";
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return "#1f32c4";
      case "XUAT_TRA_NCC":
        return "#2bdd66";
      case "XUAT_BAN":
        return teal.light;
      case null:
        return "rgba(0, 0, 0, 0)";
      case "NOI_BO":
        return "#656a7e";
    }
  };

  const openSuggestModal = (sourceCode: string, sourceType: string) => {
    try {
      modals.openConfirmModal({
        title: (
          <>
            <Title order={5}>Danh sách sản phẩm!</Title>
          </>
        ),
        radius: "md",
        size: "auto",
        children: (
          <SuggestItemWardModal
            sourceType={sourceType}
            sourceCode={sourceCode}
          />
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    } catch (error) {
      console.error(error);
      return;
    }
  };

  // Detail:
  const detailOrder = async (sourceCode: string) => {
    const url = `/api/v1/CreateSellItem/details?orderNumber=${sourceCode}`;
    const response = await repositoryPos.get<MessageResponse<any>>(url);
    const data = response?.data;

    if (response && response.success) {
      const isReturnOrder = sourceCode.includes("DT");
      navigate(isReturnOrder ? "/sell/return-order" : "/sell/view-order", {
        state: {
          id: data?.orderHeader.id,
          orderNumber: data?.orderHeader.orderNumber,
          orderType: data?.orderHeader.orderTypeId.toString(),
          actionType: "view",
          ...(isReturnOrder && { action: "tra" }),
        },
      });
    }
  };

  const detailRequestTransfer = (sourceCode: string) => {
    navigate("/warehouse-config/detail-request-transfer", {
      state: { sourceCode: sourceCode },
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }&Type=${2}`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }

    if (search.fromDate) {
      url += `&FromDate=${search.fromDate}`;
    }

    if (search.toDate) {
      url += `&ToDate=${search.toDate}`;
    }

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
      url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    }

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblRequestInventoryTransactionList[]>
      >(
        `/api/v1/RequestInventoryTransaction/get-list-request-transaction-out-ward${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      } else {
        setData([]);
        setRowCount(0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
      setIsLoading(false);
    }
  };

  const fetchdataInventoryTransactionType = async () => {
    const response: SelectListItemBase[] =
      await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      response.map((type) => ({
        value: type.value.toString(),
        label: type.text,
      }))
    );
  };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [sorting, pagination]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,

    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    // getRowId: (row) => row.transactionId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["index", "sourceTranCode", "mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    enableColumnActions: false,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <Breadcrumbs key={"Breadcrumbs"}>{items}</Breadcrumbs>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <Grid mt={"10px"}>
          <GridCol span={sizeGrid}>
            {/* <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            /> */}
            <InputSearch
              placeholder="Từ khoá"
              onSearch={fetchData}
              onChange={(value) => {
                handleChangeSearchValue(value, "keySearch");
              }}
            />
          </GridCol>

          <GridCol span={sizeGrid}>
            <Select
              placeholder={"Loại chứng từ gốc"}
              searchable
              clearable
              leftSection={<IconFileTypography color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataInventoryTransactionType}
              nothingFoundMessage={"Không có dữ liệu"}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "sourceType")
              }
              onClick={() => {
                if (dataInventoryTransactionType.length === 0) {
                  fetchdataInventoryTransactionType();
                }
              }}
            />
          </GridCol>
          <GridCol span={sizeGrid}>
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày bắt đầu"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "fromDate"
                );
              }}
              clearable
            />
          </GridCol>
          <GridCol span={sizeGrid}>
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày kết thúc"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "toDate"
                );
              }}
              clearable
            />
          </GridCol>
          <GridCol span={sizeGrid}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default RequestOutWardTransaction;
