import { Box, ScrollArea, Table } from "@mantine/core";
import { useState } from "react";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";

const ReviewDataImport = ({ type }: ReviewDataImportProps) => {
  const [scrolled1, setScrolled1] = useState(false);

  return (
    <Box w={"82.5vw"} maw={1250}>
      <ScrollArea
        mt={10}
        h={135}
        onScrollPositionChange={({ y }) => setScrolled1(y !== 0)}
      >
        <Table striped highlightOnHover withTableBorder withColumnBorders>
          <Table.Thead
            className={cx(classes.header, {
              [classes.scrolled]: scrolled1,
            })}
          >
            <Table.Tr>
              <Table.Th>STT</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
        </Table>
      </ScrollArea>
    </Box>
  );
};

export default ReviewDataImport;

type ReviewDataImportProps = {
  type: any;
};
