import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Menu,
  Select,
  TextInput,
  Title,
  Tooltip,
  rem,
  Grid,
  Badge,
  SelectProps,
  Group,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedState, useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconBrandBootstrap,
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconClipboardX,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconMapPinCog,
  IconPackageExport,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTrash,
  IconTruck,
  IconUserX,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { TblDelivery } from "../../../model/TblDelivery";
import DeleteView from "./DeleteView";
import DetailDataView from "./DetailDataView";
import EditDataView from "./EditDataView";
import {
  getBranchSelectMdm,
  getDeliveryStatusSelectMdm,
} from "../../../service/getSelectApi";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { beige, green, navy_blue, sky_blue } from "../../../const/variables";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import CreateDataView from "./CreateDataView";
import DeliveryAssignment from "./DeliveryAssignment";
import DetailProduct from "./DetailProduct";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import ChangeLocation from "./ChangeLocation";
import { MessageResponse } from "../../../model/MessageResponse";
import { BASE_API_DELIVERY } from "../../../config";
import { useNavigate } from "react-router-dom";

const Delivery4 = () => {
  // Reference data from another table
  const navigate = useNavigate();
  const [dataTblDeliveryStatusSelect, setDataTblDeliveryStatusSelect] =
    useState<ComboboxItem[]>([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataDeliverySelect, setDataDeliverySelect] = useState<any[]>([]);

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDelivery[]>([]);
  const [dataTemp, setDataTemp] = useState<TblDelivery[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [height, setHeight] = useState(0);
  const [toPageDetail, setToPageDetail] = useState(false);
  const [toPageEdit, setToPageEdit] = useState(false);
  const [toPageCreate, setToPageCreate] = useState(false);
  const [detailViewId, setDetailViewId] = useState<string | number | null>(
    null
  );
  const [editViewId, setEditViewId] = useState<string | number | null>(null);
  //table state
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [productDetails, setProductDetails] = useState({
    id: "",
    sourceType: 0,
    sourceCode: "",
  });
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  const [search, setSearch] = useState({
    startDate: new Date(
      new Date(new Date().setDate(new Date().getDate() - 2)).getTime() +
        7 * 60 * 60 * 1000
    ).toISOString(),
    endDate: new Date(new Date().getTime() + 7 * 60 * 60 * 1000).toISOString(),
    deliveryDepartmentId: "",
    statusId: "",
    branchId: "",
    customerId: "",
    customerProvinceId: "",
    customerDistrictId: "",
    customerCommuneId: "",
    type: "",
    empid: "",
    sourceType: "",
    sourceCode: "",
    transactionNumber: "",
  });

  const [keySearch, setKeySearch] = useDebouncedState("", 500);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "deliveryAppointmentTime",
        header: "Ngày hẹn giao",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
        size: 150,
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
        enableColumnFilter: false,
      },
      {
        accessorKey: "toAddress",
        header: "Địa chỉ giao hàng",
        Cell: ({ row }) => (
          <Text fw={500} size="11.5px">
            {row.original.toAddress?.replace(/^(undefined|null) - /, "")}
          </Text>
        ),
        enableColumnFilter: false,
        size: 550,
      },
      {
        accessorKey: "createByName",
        header: "Nhân viên tạo đơn hàng",
        Cell: ({ renderedCellValue }) => (
          <AvatarUtils
            value={renderedCellValue?.toString() ?? ""}
            sizeXS={true}
          />
        ),
        enableColumnFilter: false,
        size: 225,
      },
      {
        accessorKey: "description",
        header: "Ghi chú đơn hàng",
        enableColumnFilter: false,
      },
      {
        accessorKey: "transactionStatus",
        header: "Trạng thái chứng từ gốc",
        size: 210,
        enableColumnFilter: false,
      },
      {
        accessorKey: "customerName",
        header: "Khách hàng",
        size: 140,
        enableColumnFilter: false,
      },
      {
        accessorKey: "telephoneNumber",
        header: "Số điện thoại",
        enableColumnFilter: false,
        size: 140,
      },
      {
        accessorKey: "sourceCode",
        header: "Số chứng từ gốc",
        Cell: ({ renderedCellValue, row }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Badge
              radius="sm"
              variant="dot"
              size="xs"
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => {
                if (renderedCellValue?.toString()?.includes("DH")) {
                  handleCheckView(row);
                } else {
                  return;
                }
              }}
            >
              {renderedCellValue}
            </Badge>
          ) : (
            <></>
          ),
        enableColumnFilter: false,
        size: 160,
      },
      {
        accessorKey: "attribute8",
        header: "Tiền phải thu",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text size="11.5px" fw={500}>
            {renderedCellValue && renderedCellValue !== "0"
              ? `${Number(renderedCellValue)?.toLocaleString()} đ`
              : "Không thu tiền"}
          </Text>
        ),
      },
      {
        accessorKey: "debt",
        header: "Công nợ",
        Cell: ({ row }) => (
          <Text fw={500} size="11.5px">
            {row.original.sourceType === 1 && row.original.debt === null
              ? "Thu tiền ngay"
              : row.original.debt}
          </Text>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "deliveryStatusName",
        header: "Trạng thái đơn giao hàng",
        enableClickToCopy: true,
        Cell: ({ renderedCellValue }) => (
          <Badge
            color={getColorStatus(renderedCellValue?.toString() ?? "")}
            radius={"sm"}
            size="xs"
          >
            {renderedCellValue}
          </Badge>
        ),
        size: 210,
        enableColumnFilter: false,
      },
      {
        accessorKey: "sourceType",
        header: "Nguồn hàng",
        Cell: ({ row }) => (
          <Badge
            color={getColorSourceType(row.original.sourceType)}
            radius={"sm"}
            size="xs"
          >
            {soureTypeText(row.original.sourceType)}
          </Badge>
        ),
        enableColumnFilter: false,
        size: 140,
      },
      {
        accessorKey: "subInvName",
        header: "Kho",
        enableColumnFilter: false,
        size: 200,
      },
      {
        accessorKey: "responsibleEmployeeName",
        header: "Nhân viên giao hàng",
        Cell: ({ row }) => (
          <AvatarUtils
            value={row.original.responsibleEmployeeName}
            sizeXS={true}
          />
        ),
        enableColumnFilter: false,
        size: 200,
      },
      {
        accessorKey: "transactionNumber",
        header: "Số giao dịch",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="xs"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailItem(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        enableColumnFilter: false,
        size: 140,
      },
      {
        accessorKey: "attribute11",
        header: "Công việc",
        enableColumnFilter: false,
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
        size: 140,
      },
      {
        accessorKey: "invoiceDate",
        header: "Ngày duyệt đơn",
        enableColumnFilter: false,
      },
      {
        accessorKey: "transactionFinishDate",
        header: "Ngày xuất kho",
        enableColumnFilter: false,
      },
      {
        accessorKey: "receptionTime",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "deliveryTime",
        header: "Ngày giao hàng",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "arrivalTime",
        header: "Ngày đến nơi",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "completionTime",
        header: "Ngày hoàn thành",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "transactionContent",
        header: "Nội dung giao",
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute2",
        header: "Ghi chú nhân viên giao hàng",
        enableColumnFilter: false,
        size: 250,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        enableResizing: false,
        enableClickToCopy: false,
        size: 150,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa vị trí">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="blue"
                size={"xs"}
                onClick={() =>
                  changeLocation(
                    row.original.id,
                    row.original.customerProvinceId,
                    row.original.customerDistrictId,
                    row.original.customerCommuneId,
                    row.original.streetId,
                    row.original.customerAddress
                  )
                }
                disabled={
                  row.original.deliveryStatusName !== "Lập đơn giao hàng" &&
                  row.original.deliveryStatusName !== "Hủy phân công" &&
                  row.original.deliveryStatusName !== "Hủy đơn" &&
                  row.original.deliveryStatusName !==
                    "Đơn hàng giao chưa thành công do Khách hàng" &&
                  row.original.deliveryStatusName !==
                    "Đơn hàng giao chưa thành công do Hacom"
                }
              >
                <IconMapPinCog size={"xs"} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                size={"xs"}
                onClick={async () => {
                  editItem(row.original.id);
                }}
                disabled={
                  (row.original.statusId !== 1 &&
                    row.original.statusId !== 8) ||
                  row.original.sourceType !== 3
                }
              >
                <IconEdit size={"xs"} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                size={"xs"}
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={"xs"} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Tooltip label="Hủy">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="red"
                    size={"xs"}
                  >
                    <IconX size={"xs"} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <IconUserX style={{ width: rem(14), height: rem(14) }} />
                  }
                  onClick={() =>
                    modalCheckCancel([row.original.id], "HUY_PHAN_CONG")
                  }
                  disabled={
                    row.original.deliveryStatusName === "Lập đơn giao hàng"
                  }
                >
                  Hủy phân công
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconClipboardX
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                    onClick={() =>
                      modalCheckCancel([row.original.id], "HUY_DON")
                    }
                  >
                    Hủy đơn hàng
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                size={"xs"}
                onClick={async () => {
                  await deleteData([row.original.id]);
                  close();
                }}
                disabled={
                  (row.original.statusId !== 1 &&
                    row.original.statusId !== 8) ||
                  row.original.sourceType !== 3
                }
              >
                <IconTrash size={"xs"} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  function getColorSourceType(value: number) {
    switch (value) {
      case 1:
        return beige.base;
      case 2:
        return sky_blue.light_alt;
      case 3:
        return navy_blue.base;
      case 4:
        return "#7b2eda";
      case 5:
        return "#c91a52";
    }
  }

  function getColorStatus(value: string) {
    switch (value) {
      case "Đã phân công":
        return "lime";
      case "Lập đơn giao hàng":
        return "green";
      case "Đang giao hàng":
        return "teal";
      case "Đơn hàng giao chưa thành công do Khách hàng":
        return "blue";
      case "Đơn hàng giao chưa thành công do Hacom":
        return "indigo";
      case "Hàng đã giao":
        return "violet";
      case "Hủy phân công":
        return "red";
      case "Đã đến nơi":
        return "#2bdd66";
      case "Hủy đơn":
        return "#c91a25";
      case null:
        return "rgba(0, 0, 0, 0)";
    }
  }

  const soureTypeText = (numberST: number) => {
    switch (numberST) {
      case 1:
        return "Đơn hàng";
      case 2:
        return "Điều chuyển";
      case 3:
        return "Khác";
      case 4:
        return "Xuất bảo hành";
      case 5:
        return "Xuất trả khách";
    }
  };

  const detailProduct = (
    id: string,
    sourceType: number,
    sourceCode: string
  ) => {
    if (!toPageCreate && !toPageDetail && !toPageEdit) {
      setProductDetails({ id, sourceType, sourceCode });
      open();
    } else {
      return;
    }
  };

  function modalDeliveryAssignment() {
    modals.openConfirmModal({
      title: (
        <>
          {selectIds.length > 1 ? (
            <Title order={5}>Phân công giao nhận (nhiều đơn)</Title>
          ) : (
            <Title order={5}>Phân công giao nhận</Title>
          )}
        </>
      ),
      size: "auto",
      children: (
        <DeliveryAssignment
          selectIds={selectIds}
          dataDeliverySelect={dataDeliverySelect}
          onClose={setDeleteViewStatus}
          department={"DA"}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const handleCreate = () => {
    setToPageCreate(!toPageCreate);
    close();
    setDeleteViewStatus((prev) => !prev);
  };

  const handleDetail = () => {
    setToPageDetail(!toPageDetail);
    close();
    setDeleteViewStatus((prev) => !prev);
  };

  const handleEdit = () => {
    setToPageEdit(!toPageEdit);
    close();
    setDeleteViewStatus((prev) => !prev);
  };

  function detailItem(id: string | number) {
    setDetailViewId(id);
    handleDetail();
  }

  function editItem(id: string | number) {
    setEditViewId(id);
    handleEdit();
  }

  const handleCheckView = async (row: any) => {
    const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.sourceCode}`;
    const response = await repositoryPos.get<MessageResponse<any>>(url);

    if (response && response.success) {
      // Kiểm tra nếu orderNumber chứa "DT"
      if (row.original.sourceCode.includes("DT")) {
        navigate("/sell/view-order-return", {
          state: {
            id: row.original.orderId,
            orderNumber: row.original.sourceCode,
            orderType: row.original.orderType,
            actionType: "view",
          },
        });
      } else {
        navigate("/sell/view-order", {
          state: {
            id: row.original.orderId,
            orderNumber: row.original.sourceCode,
            orderType: row.original.orderType,
            actionType: "view",
          },
        });
      }
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const changeLocation = (
    id: any,
    province: number,
    district: number,
    commune: number,
    street: number,
    addr: string
  ) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa điểm đến</Title>
        </>
      ),
      size: "auto",
      children: (
        <ChangeLocation
          id={id}
          province={province}
          district={district}
          commune={commune}
          street={street}
          addr={addr}
          deleteViewStatus={setDeleteViewStatus}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const modalCheckCancel = (id: any, type: string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>
            {type === "HUY_PHAN_CONG" ? "Hủy phân công" : "Hủy đơn"}
          </Title>
        </>
      ),
      size: "auto",
      children: (
        <Box>
          <Text fw={500} mt={10}>
            Bạn có chắc muốn{" "}
            {type === "HUY_PHAN_CONG"
              ? "hủy phân công đơn hàng"
              : "hủy đơn hàng"}{" "}
            này ?
          </Text>
          <Flex justify={"end"} gap={"md"} mt={10}>
            <Button
              color="green"
              variant="outline"
              leftSection={<IconCheck size={14} />}
              onClick={() => handleCancelDelivery(id, type)}
            >
              Xác nhận
            </Button>
          </Flex>
        </Box>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const optionColorsAccount: Record<
    | "Đã phân công"
    | "Lập đơn giao hàng"
    | "Đang giao hàng"
    | "Đã đến nơi"
    | "Đơn hàng giao chưa thành công do Khách hàng"
    | "Đơn hàng giao chưa thành công do Hacom"
    | "Hàng đã giao"
    | "Hàng đã giao - nối chuyến"
    | "Hủy phân công"
    | "Hủy đơn",
    string
  > = {
    "Đã phân công": "lime",
    "Lập đơn giao hàng": "green",
    "Đang giao hàng": "teal",
    "Đã đến nơi": "cyan",
    "Đơn hàng giao chưa thành công do Khách hàng": "blue",
    "Đơn hàng giao chưa thành công do Hacom": "indigo",
    "Hàng đã giao": "violet",
    "Hàng đã giao - nối chuyến": "grape",
    "Hủy phân công": "red",
    "Hủy đơn": "#c91a25",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsAccount[
            option.label as
              | "Đã phân công"
              | "Lập đơn giao hàng"
              | "Đang giao hàng"
              | "Đã đến nơi"
              | "Đơn hàng giao chưa thành công do Khách hàng"
              | "Đơn hàng giao chưa thành công do Hacom"
              | "Hàng đã giao"
              | "Hàng đã giao - nối chuyến"
              | "Hủy phân công"
              | "Hủy đơn"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const optionColorSourceType: Record<
    "Đơn hàng" | "Điều chuyển" | "Khác" | "Xuất bảo hành" | "Xuất trả khách",
    string
  > = {
    "Đơn hàng": "#E2E2B6",
    "Điều chuyển": "#6EACDA",
    Khác: "#03346E",
    "Xuất bảo hành": "#7b2eda",
    "Xuất trả khách": "#C91A52",
  };

  const renderSelectOptionSourceType: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorSourceType[
            option.label as
              | "Đơn hàng"
              | "Điều chuyển"
              | "Khác"
              | "Xuất bảo hành"
              | "Xuất trả khách"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const deleteData = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa đơn hàng!</Title>
            </>
          ),
          size: "550px",
          children: <DeleteView idItems={ids} onClose={setDeleteViewStatus} />,
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const dataDeliveryStatusSelect = async () => {
    try {
      const getData = await getDeliveryStatusSelectMdm();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDeliveryStatusSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const fetchDataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataBranchSelect(
      getData
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useHotkeys([
    [
      "F11",
      () => {
        handleCreate();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    setData([]);
    setRowCount(0);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }&DeliveryDepartment=Dự án`;

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.statusId) {
      url += `&StatusId=${search.statusId}`;
    }

    if (search.branchId) {
      url += `&BranchId=${search.branchId}`;
    }

    if (search.customerId) {
      url += `&CustomerId=${search.customerId}`;
    }

    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }

    if (search.sourceCode) {
      url += `&SourceCode=${search.sourceCode}`;
    }

    if (search.transactionNumber) {
      url += `&TransactionNumber=${search.transactionNumber}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblDelivery/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      if (dataTblDeliveryStatusSelect) setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const handleExportData = async () => {
    let url = `/api/v1/TblDelivery/export-deliveries?Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}&DeliveryDepartment=Dự án`;

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.statusId) {
      url += `&StatusId=${search.statusId}`;
    }

    if (search.branchId) {
      url += `&BranchId=${search.branchId}`;
    }

    if (search.customerId) {
      url += `&CustomerId=${search.customerId}`;
    }

    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }

    if (search.sourceCode) {
      url += `&SourceCode=${search.sourceCode}`;
    }

    if (search.transactionNumber) {
      url += `&TransactionNumber=${search.transactionNumber}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    const _url = BASE_API_DELIVERY + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "danh_muc_don_giao_hang_ky_thuat" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleCancelDelivery = async (id: any, type: string) => {
    const res = await repositoryDelivery.post<MessageResponse<any>>(
      "/api/v1/TblDelivery/cancel-deliveries",
      {
        ids: id,
        type: type,
      }
    );

    if (res && res?.success) {
      if (type === "HUY_PHAN_CONG") {
        NotificationExtension.Success("Hoàn tất hủy phân công !");
      } else {
        NotificationExtension.Success("Hoàn tất hủy đơn hàng !");
      }
      setTimeout(() => {
        modals.closeAll();
        setDeleteViewStatus(!deleteViewStatus);
      }, 1000);
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (330 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  useEffect(() => {
    fetchData();
  }, [pagination, deleteViewStatus]);

  useEffect(() => {
    const numericIds = selectIds.map((id) => Number(id));

    const filtered = data.filter((item) =>
      numericIds.includes(Number(item.id))
    );

    setDataDeliverySelect(filtered);
  }, [selectIds, data]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableClickToCopy: true,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={{ sm: "flex-end", lg: "flex-end" }}
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                handleCreate();
              }}
            >
              Tạo mới (F11)
            </Button>
            <Button
              leftSection={
                <IconTruck style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => modalDeliveryAssignment()}
              disabled={selectIds.length < 1}
              color="purple"
            >
              Phân công giao hàng
            </Button>
            {/* <Menu shadow="md" width={200}>
                <Menu.Target>
                  <Button
                    // size="xs"
                    rightSection={
                      <IconCaretDown
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                    color="red"
                    disabled={selectIds.length < 1}
                  >
                    Hủy
                  </Button>
                </Menu.Target>
  
                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={
                      <IconUserX style={{ width: rem(14), height: rem(14) }} />
                    }
                    onClick={() =>
                      handleCancelDelivery(selectIds, "HUY_PHAN_CONG")
                    }
                  >
                    Hủy phân công
                  </Menu.Item>
                  <Tooltip label={"Chức năng đang update"}>
                    <Menu.Item
                      leftSection={
                        <IconClipboardX
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                      onClick={() => handleCancelDelivery(selectIds, "HUY_DON")}
                    >
                      Hủy đơn hàng
                    </Menu.Item>
                  </Tooltip>
                </Menu.Dropdown>
              </Menu> */}
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={() => handleExportData()}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Flex gap={"md"} align={"center"} wrap={"wrap"} mt={10}>
          <Grid gutter={"xs"} w={"100%"}>
            <Grid.Col span={{ base: 12, md: 12, lg: 1.8 }}>
              <DatePickerInput
                size="sm"
                placeholder="Từ ngày"
                locale="vi"
                valueFormat="DD/MM/YYYY"
                value={
                  search.startDate.length > 0
                    ? new Date(search.startDate ?? "")
                    : null
                }
                onChange={(e) => {
                  handleChangeSearchValue(
                    e ? formatDateNotTimeZone(e ?? "") : "",
                    "startDate"
                  );
                }}
                leftSection={<IconCalendar color="#15aabf" />}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 1.8 }}>
              <DatePickerInput
                size="sm"
                placeholder="Đến ngày"
                locale="vi"
                valueFormat="DD/MM/YYYY"
                value={
                  search.endDate.length > 0
                    ? new Date(search.endDate ?? "")
                    : null
                }
                onChange={(e) => {
                  handleChangeSearchValue(
                    e ? formatDateNotTimeZone(e ?? "") : "",
                    "endDate"
                  );
                }}
                leftSection={<IconCalendar color="#15aabf" />}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
              <Select
                placeholder="Chi nhánh - Địa điểm nhận hàng"
                data={dataBranchSelect}
                leftSection={<IconBrandBootstrap color="#15aabf" />}
                nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                onChange={(e) => {
                  handleChangeSearchValue(e?.toString() ?? "", "branchId");
                }}
                onClick={() => {
                  if (dataBranchSelect.length === 0) {
                    fetchDataBranchSelect();
                  }
                }}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
              <Select
                placeholder="Nguồn hàng"
                data={[
                  { label: "Đơn hàng", value: "1" },
                  { label: "Điều chuyển", value: "2" },
                  { label: "Khác", value: "3" },
                  { label: "Xuất bảo hành", value: "4" },
                  { label: "Xuất trả khách", value: "5" },
                ]}
                leftSection={<IconPackageExport color="#15aabf" />}
                onChange={(e) =>
                  handleChangeSearchValue(e?.toString() ?? "", "sourceType")
                }
                clearable
                renderOption={renderSelectOptionSourceType}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
              <Select
                placeholder="Trạng thái"
                clearable
                data={dataTblDeliveryStatusSelect}
                leftSection={<IconStatusChange color="#15aabf" />}
                onChange={(e) => {
                  handleChangeSearchValue(e?.toString() ?? "", "statusId");
                }}
                onClick={() => {
                  if (dataTblDeliveryStatusSelect.length === 0) {
                    dataDeliveryStatusSelect();
                  }
                }}
                renderOption={renderSelectOptionStatus}
                comboboxProps={{ width: 400 }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
              <TextInput
                placeholder="Nhập từ khóa"
                defaultValue={keySearch}
                leftSection={<IconSearch color="#15aabf" />}
                onChange={(e) => {
                  setKeySearch(e.currentTarget.value);
                }}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await fetchData();
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.2 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={() => {
                  fetchData();
                }}
                w={"100%"}
              >
                Tìm kiếm
              </Button>
            </Grid.Col>
          </Grid>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    enableExpandAll: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: (row) =>
      row.original.deliveryStatusName === "Lập đơn giao hàng" ||
      row.original.deliveryStatusName === "Hủy phân công" ||
      row.original.deliveryStatusName ===
        "Đơn hàng giao chưa thành công do Khách hàng" ||
      row.original.deliveryStatusName ===
        "Đơn hàng giao chưa thành công do Hacom",
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "sourceCode"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "11.5px",
        padding: "3px 10px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        detailProduct(
          row.original.id,
          row.original.sourceType,
          row.original.sourceCode
        );
      },
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {toPageDetail ? (
        <DetailDataView id={detailViewId} handleDetail={handleDetail} />
      ) : toPageEdit && editViewId ? (
        <EditDataView id={editViewId} handleEdit={handleEdit} />
      ) : toPageCreate ? (
        <CreateDataView
          handleCreate={handleCreate}
          setSearch={setSearch}
          department={"Kỹ thuật"}
        />
      ) : (
        <>
          <MantineReactTable table={table} />
          <Box mt={5}>
            <DetailProduct
              id={productDetails.id}
              sourceType={productDetails.sourceType}
              sourceCode={productDetails.sourceCode}
              isDetail={false}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default Delivery4;
