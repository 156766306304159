import { Box, Grid } from "@mantine/core";
import { Text } from "@mantine/core";
import { MessageResponse } from "../../model/MessageResponse";
import { repositoryLog, repositoryPos } from "../../_base/_const/_constVar";
import { useEffect, useState } from "react";
import { ChartModelPosModel } from "../../model/ChartModelPosModel";

import { AgCharts, AgGauge } from "ag-charts-react";
import { AgChartOptions, AgGaugeOptions, AgRadialGaugeOptions } from "ag-charts-enterprise";
import "ag-charts-enterprise";

interface RevenueData {
  CITY: string;
  TOTAL_REVENUE: number;
  TOTAL_REMAINING: number;
}

export interface Topology {
  type: string;
  features: Feature[];
}

export interface Feature {
  type: string;
  properties: Properties;
  geometry: Geometry;
}

export interface Properties {
  // [x: string]: any;
  gid: number;
  code: string;
  name: string;
  Ten_Tinh?: string;
  f_code?: string;
  Dan_So?: string;
  Nam_TK?: string;
}

interface RequestStatistics {
  totalRequestsThisMonth: number;
  todayRequests: number;
  requestsPerDayThisMonth: DailyRequest[];
  requestsPerSecondToday: number;
  requestsPerMonthThisYear: MonthlyRequest[];
}

interface DailyRequest {
  date: string; // Ngày dưới dạng chuỗi, ví dụ: "01", "25"
  count: number; // Số lượng yêu cầu trong ngày
}

interface MonthlyRequest {
  month: string; // Tháng dưới dạng chuỗi, ví dụ: "11", "12"
  count: number; // Số lượng yêu cầu trong tháng
}

export interface Geometry {
  type: string;
  coordinates: any;
}
export type Coordinate = [number, number];

const HomeView = () => {
  const [dataSale, setDataSale] = useState<ChartModelPosModel>();
  const [dataReq, setDataReq] = useState<RequestStatistics | null>();
  const getDataSaleOrder = async () => {
    try {
      const res = await repositoryPos.get<MessageResponse<ChartModelPosModel>>(
        "/api/v1/CreateSellItem/get-chart-sale"
      );
      if (res && res.success && res.data) {
        setDataSale(res.data);
        if (res.data.saleOrder) {
          setOptions7({
            title: {
              text: "",
            },
            subtitle: {
              text: "",
            },
            data: res.data.saleOrder,
            series: [
              {
                type: "bar",
                xKey: "month",
                yKey: "onlineOrders",
                yName: "Đơn hàng bán buôn",
                tooltip: {
                  renderer: ({ datum }) => ({
                    title: datum.name,
                    content: `Số lượng: ${datum.onlineOrders}`,
                  }),
                },
              },
              {
                type: "bar",
                xKey: "month",
                yKey: "officeOrders",
                yName: "Đơn hàng bán lẻ",
                tooltip: {
                  renderer: ({ datum }) => ({
                    title: datum.name,
                    content: `Số lượng: ${datum.officeOrders}`,
                  }),
                },
              },
              {
                type: "bar",
                xKey: "month",
                yKey: "returnOrders",
                yName: "Đơn hàng trả lại",
                tooltip: {
                  renderer: ({ datum }) => ({
                    title: datum.name,
                    content: `Số lượng: ${datum.returnOrders}`,
                  }),
                },
              },
            ],
          });
        }

        if (res.data.saleOrderAmount) {
          setOptions8({
            title: {
              text: "",
            },
            subtitle: {
              text: "",
            },
            data: res.data.saleOrderAmount,
            series: [
              {
                type: "bar",
                xKey: "month",
                yKey: "amountTotal",
                yName: "Tổng tiền thanh toán",
                tooltip: {
                  renderer: ({ datum }) => ({
                    title: datum.name,
                    content: `Tổng tiền: ${numberFormatter.format(
                      datum.amountTotal
                    )}`,
                  }),
                },
              },
              {
                type: "bar",
                xKey: "month",
                yKey: "amountPaid",
                yName: "Tổng tiền đã trả",
                tooltip: {
                  renderer: ({ datum }) => ({
                    title: datum.name,
                    content: `Tổng tiền: ${numberFormatter.format(
                      datum.amountPaid
                    )}`,
                  }),
                },
              },
              {
                type: "bar",
                xKey: "month",
                yKey: "amountRemaining",
                yName: "Tổng tiền khách nợ",
                tooltip: {
                  renderer: ({ datum }) => ({
                    title: datum.name,
                    content: `Tổng tiền: ${numberFormatter.format(
                      datum.amountRemaining
                    )}`,
                  }),
                },
              },
              {
                type: "bar",
                xKey: "month",
                yKey: "amountDiscount",
                yName: "Tổng tiền chiết khấu",
                tooltip: {
                  renderer: ({ datum }) => ({
                    title: datum.name,
                    content: `Tổng tiền: ${numberFormatter.format(
                      datum.amountDiscount
                    )}`,
                  }),
                },
              },
            ],
          });
        }

        if (res.data.employeeList) {
          setOptions9({
            data: res.data.employeeList,
            title: {
              text: "",
            },
            series: [
              {
                type: "donut",
                calloutLabelKey: "employeeName",
                angleKey: "totalRevenue",
                innerRadiusRatio: 0.7,
                tooltip: {
                  renderer: ({ datum }) => ({
                    title: datum.name,
                    content: `Doanh thu: ${numberFormatter.format(
                      datum.totalRevenue
                    )}`,
                  }),
                },
                innerLabels: [
                  {
                    text: "Tổng doanh thu",
                    fontWeight: "bold",
                    fontSize: 12,
                  },
                  {
                    text: `${numberFormatter.format(
                      res.data.employeeList.reduce(
                        (total, item) => total + item.totalRevenue,
                        0
                      )
                    )}`,
                    spacing: 3,
                    fontSize: 11,
                    //   color: 'green',
                  },
                ],
                calloutLabel: {
                  enabled: false,
                },
              },
            ],
            legend: {
              enabled: true,
              position: "right",
              pagination: {},
            },
          });
        }

        if (res.data.inventoryList) {
          setOptions10({
            data: res.data.inventoryList,
            title: {
              text: "",
            },
            series: [
              {
                type: "donut",
                calloutLabelKey: "warehouseName",
                angleKey: "totalOrders",
                innerRadiusRatio: 0.7,
                tooltip: {
                  renderer: ({ datum }) => ({
                    title: datum.name,
                    content: `Doanh thu: ${numberFormatter.format(
                      datum.totalOrders
                    )}`,
                  }),
                },
                innerLabels: [
                  {
                    text: "Tổng doanh thu",
                    fontWeight: "bold",
                    fontSize: 12,
                  },
                  {
                    text: `${numberFormatter.format(
                      res.data.inventoryList.reduce(
                        (total, item) => total + item.totalOrders,
                        0
                      )
                    )}`,
                    spacing: 2,
                    fontSize: 11,
                    //   color: 'green',
                  },
                ],
                calloutLabel: {
                  enabled: false,
                },
                // innerCircle: {
                //     fill: '#c9fdc9',
                // },
              },
            ],
            legend: {
              enabled: true,
              position: "right",
            },
          });
        }
        const {
          quantityOrderSuccess,
          quantityOrderReturn,
          quantityOrderFails,
        } = res.data.gaugeOrder;
        if (res.data.gaugeOrder) {
          setOptions11({
            type: "radial-gauge",
            label: {
              formatter({ value }: { value: number }) {
                return `${value.toFixed(0)}%`;
              },
              fontSize: 20,
            },
            secondaryLabel: {
              text: "Đơn hoàn thành",
              fontSize: 12.5,
            },
            cornerRadius: 99,
            cornerMode: "container",
            value:
              (res.data.gaugeOrder.quantityOrderSuccess /
                res.data.gaugeOrder.totalOrders) *
              100,
            scale: {
              min: 0,
              max: 100,
              label: {
                enabled: false,
              },
            },
            segmentation: {
              enabled: true,
              interval: {
                count: 4,
              },
              spacing: 2,
            },
            bar: {
              fills: [
                { color: "#ea3943" },
                { color: "#ea8c00" },
                { color: "#f3d42f" },
                { color: "#a0de20" },
                { color: "#16c784" },
              ],
              fillMode: "discrete",
            },
          } as AgRadialGaugeOptions);

          setOptions12({
            type: "radial-gauge",
            label: {
              formatter({ value }: { value: number }) {
                return `${value.toFixed(0)}%`;
              },
              fontSize: 20,
            },
            secondaryLabel: {
              text: "Đơn trả lại",
              fontSize: 12.5,
            },
            cornerRadius: 99,
            cornerMode: "container",
            value:
              (res.data.gaugeOrder.quantityOrderReturn /
                res.data.gaugeOrder.totalOrders) *
              100,
            scale: {
              min: 0,
              max: 100,
              label: {
                enabled: false,
              },
            },
            segmentation: {
              enabled: true,
              interval: {
                count: 4,
              },
              spacing: 2,
            },
            bar: {
              fills: [
                { color: "#16c784" },
                { color: "#a0de20" },
                { color: "#f3d42f" },
                { color: "#ea8c00" },
                { color: "#ea3943" },
              ],
              fillMode: "discrete",
            },
          } as AgRadialGaugeOptions);

          setOptions13({
            type: "radial-gauge",
            label: {
              formatter({ value }: { value: number }) {
                return `${value.toFixed(0)}%`;
              },
              fontSize: 20,
            },
            cornerRadius: 99,
            cornerMode: "container",
            secondaryLabel: {
              text: "Đơn hủy",
              fontSize: 12.5,
            },
            value:
              (res.data.gaugeOrder.quantityOrderFails /
                res.data.gaugeOrder.totalOrders) *
              100,
            scale: {
              min: 0,
              max: 100,
              label: {
                enabled: false,
              },
            },
            segmentation: {
              enabled: true,
              interval: {
                count: 4,
              },
              spacing: 2,
            },
            bar: {
              fills: [
                { color: "#16c784" },
                { color: "#a0de20" },
                { color: "#f3d42f" },
                { color: "#ea8c00" },
                { color: "#ea3943" },
              ],
              fillMode: "discrete",
            },
          } as AgRadialGaugeOptions);

          setOptions14({
            type: "radial-gauge",
            label: {
              formatter({ value }: { value: number }) {
                return `${value.toFixed(0)}%`;
              },
              fontSize: 20,
            },
            cornerRadius: 99,
            cornerMode: "container",
            secondaryLabel: {
              text: "Độ hiệu quả",
              fontSize: 12.5,
            },
            value:
              quantityOrderSuccess + quantityOrderFails + quantityOrderReturn >
              0
                ? (quantityOrderSuccess /
                    (quantityOrderSuccess +
                      quantityOrderFails +
                      quantityOrderReturn)) *
                  100
                : 0,
            scale: {
              min: 0,
              max: 100,
              label: {
                enabled: false,
              },
            },
            segmentation: {
              enabled: true,
              interval: {
                count: 4,
              },
              spacing: 2,
            },
            bar: {
              fills: [
                { color: "#ea3943" },
                { color: "#ea8c00" },
                { color: "#f3d42f" },
                { color: "#a0de20" },
                { color: "#16c784" },
              ],
              fillMode: "discrete",
            },
          } as AgRadialGaugeOptions);
        }
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const getReq = async () => {
    try {
      //
      const res = await repositoryLog.get<RequestStatistics>(
        "/api/v1/Logging/count-req"
      );
      setDataReq(res);
      if (res?.requestsPerDayThisMonth)
        setOptions15({
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          data: res?.requestsPerDayThisMonth,
          series: [
            {
              type: "bar",
              xKey: "date",
              yKey: "count",
              yName: "Lưu lượng truy cập",
              tooltip: {
                renderer: ({ datum }) => ({
                  title: datum.name,
                  content: datum.count,
                }),
              },
            },
          ],
        });

      if (res?.requestsPerDayThisMonth)
        setOptions16({
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          data: res?.requestsPerMonthThisYear,
          series: [
            {
              type: "bar",
              xKey: "month",
              yKey: "count",
              yName: "Lưu lượng truy cập",
              tooltip: {
                renderer: ({ datum }) => ({
                  title: datum.name,
                  content: datum.count,
                }),
              },
            },
          ],
        });
      return res;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      // await initMap();
      await getDataSaleOrder();
      await getDataProvice();
      //  await getReq();
    };

    initialize();
  }, []);
  const [dataGdp, setDataGdp] = useState<RevenueData[]>([]);
  const [topology, settopology] = useState<Topology>();
  const [quanhuyen, setQuanHuyen] = useState<Topology>();

  async function initMap() {
    const _top = await fetch("/diaphantinh.geojson");
    const _to = await _top.json();
    if (_to) settopology(_to);

    const _quanhuyen = await fetch("/diaphanhuyen.geojson");
    const _quanhuyens = await _quanhuyen.json();
    if (_quanhuyens) setQuanHuyen(_quanhuyens);
  }

  async function getDataProvice() {
    try {
      const res = await repositoryPos.get<RevenueData[]>(
        "/api/v1/home/test-gdp"
      );
      setDataGdp(res ?? []);
      return res;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  // useEffect(() => {
  //   if (dataGdp && topology && quanhuyen) {
  //     // const _leg: AgMapShapeSeriesOptions<any>[] = dataGdp.map((d) => ({
  //     //   type: "map-shape",
  //     //   idKey: "name",
  //     //   colorKey: "gdp",
  //     //   title: d.CITY,
  //     //   tooltip: {
  //     //     renderer: ({ datum }) => ({
  //     //       title: datum.name,
  //     //       content: `Doanh thu: ${numberFormatter.format(datum.gdp)}`,
  //     //     }),
  //     //   },
  //     // }));
  //     setOptions5({
  //       title: {
  //         text: "Doanh thu theo tỉnh thành Việt Nam",
  //       },
  //       data: topology.features.map((d) => ({
  //         name: d.properties.name,
  //         code: d.properties.code,
  //         gdp:
  //           dataGdp?.find(
  //             (xxx) =>
  //               xxx.CITY?.toUpperCase() === d.properties.name?.toUpperCase()
  //           )?.TOTAL_REVENUE ?? Math.random() * 4e9,
  //       })),
  //       topology,
  //       series: [
  //         {
  //           type: "map-shape",
  //           //   legendItemName: "name",
  //           idKey: "name",
  //           colorKey: "gdp",
  //           //  title: "Pacific",
  //           //  labelKey: "name",
  //           tooltip: {
  //             renderer: ({ datum }) => ({
  //               title: datum.name,
  //               content: `Doanh thu: ${numberFormatter.format(datum.gdp)}`,
  //             }),
  //           },
  //         },
  //       ],
  //       gradientLegend: {
  //         enabled: true,
  //         position: "right",
  //         gradient: {
  //           preferredLength: 200,
  //           thickness: 20,
  //         },
  //         scale: {
  //           // interval: {
  //           //   minSpacing: 10,
  //           //   values: [0, 1e6, 2e6, 3e6, 4e6, 5e6],
  //           // },
  //           label: {
  //             fontSize: 10,
  //             formatter: ({ value }) =>
  //               new Intl.NumberFormat("vi-VN", {
  //                 style: "currency",
  //                 currency: "VND",
  //               }).format(value),
  //           },
  //         },
  //       },
  //       listeners: {
  //         // click: (e) => {
  //         //   console.log(e);
  //         // },
  //         seriesNodeClick(event) {
  //           const tinh = event.datum.name;
  //           const _dataClick = quanhuyen?.features.filter(
  //             (d) => d.properties.Ten_Tinh === tinh
  //           );

  //           if (tinh) {
  //             const _dataaa: Topology = {
  //               type: "FeatureCollection",
  //               features: _dataClick ?? [],
  //             };
  //             console.log(_dataClick);

  //             setOptions6({
  //               title: {
  //                 text: "Doanh thu theo quận huyện " + tinh + "",
  //               },
  //               data: _dataaa?.features.map((d) => ({
  //                 name: d.properties.name,
  //                 code: d.properties.code,
  //                 gdp: Math.random() * 4e6,
  //               })),
  //               topology: _dataaa,
  //               series: [
  //                 {
  //                   type: "map-shape",
  //                   idKey: "name",
  //                   colorKey: "gdp",
  //                   //  labelKey: "name",
  //                   tooltip: {
  //                     renderer: ({ datum }) => ({
  //                       title: datum.name,
  //                       content: `Doanh thu: ${numberFormatter.format(
  //                         datum.gdp
  //                       )}`,
  //                     }),
  //                   },
  //                 },
  //               ],
  //               gradientLegend: {
  //                 enabled: true,
  //                 position: "right",
  //                 gradient: {
  //                   preferredLength: 200,
  //                   thickness: 20,
  //                 },
  //                 scale: {
  //                   interval: {
  //                     minSpacing: 10,
  //                     values: [0, 1e6, 2e6, 3e6, 4e6, 5e6],
  //                   },
  //                   label: {
  //                     fontSize: 10,
  //                     formatter: ({ value }) => `${value}Đ`,
  //                   },
  //                 },
  //               },
  //               listeners: {
  //                 // click: (e) => {
  //                 //   console.log(e);
  //                 // },
  //                 seriesNodeClick(event) {
  //                   console.log(event);
  //                 },
  //               },
  //             });
  //           }
  //         },
  //       },
  //       contextMenu: {
  //         enabled: true,
  //       },
  //       zoom: {
  //         enabled: true,
  //         enableAxisDragging: true,
  //       },
  //       legend: {
  //         enabled: false,
  //         // item: {
  //         //   label: {
  //         //     fontSize: 14,
  //         //     fontFamily: "Papyrus",
  //         //     color: "red",
  //         //     maxLength: 12,
  //         //     formatter: ({ value }) =>
  //         //       value == "Coal" ? value + " *" : value,
  //         //   },
  //         // },
  //       },
  //       locale: {
  //         localeText: AG_CHARTS_LOCALE_VI_VN,
  //       },
  //     });

  //     setOptions6({
  //       title: {
  //         text: "Doanh thu theo quận huyện Hà Nội ",
  //       },
  //       data: {
  //         type: "FeatureCollection",
  //         features:
  //           quanhuyen?.features.filter(
  //             (d) => d.properties.Ten_Tinh === "Hà Nội"
  //           ) ?? [],
  //       }?.features.map((d) => ({
  //         name: d.properties.name,
  //         code: d.properties.code,
  //         gdp: Math.random() * 4e6,
  //       })),
  //       topology: {
  //         type: "FeatureCollection",
  //         features:
  //           quanhuyen?.features.filter(
  //             (d) => d.properties.Ten_Tinh === "Hà Nội"
  //           ) ?? [],
  //       },
  //       series: [
  //         {
  //           type: "map-shape",
  //           idKey: "name",
  //           colorKey: "gdp",
  //           //  labelKey: "name",
  //           tooltip: {
  //             renderer: ({ datum }) => ({
  //               title: datum.name,
  //               content: `Doanh thu: ${numberFormatter.format(datum.gdp)}`,
  //             }),
  //           },
  //         },
  //       ],
  //       gradientLegend: {
  //         enabled: true,
  //         position: "right",
  //         gradient: {
  //           preferredLength: 200,
  //           thickness: 20,
  //         },
  //         scale: {
  //           // interval: {
  //           //   minSpacing: 10,
  //           //   values: [0, 1e6, 2e6, 3e6, 4e6, 5e6],
  //           // },
  //           label: {
  //             fontSize: 10,
  //             formatter: ({ value }) =>
  //               new Intl.NumberFormat("vi-VN", {
  //                 style: "currency",
  //                 currency: "VND",
  //               }).format(value),
  //           },
  //         },
  //       },
  //       listeners: {
  //         seriesNodeClick(event) {
  //           console.log(event);
  //         },
  //       },
  //     });
  //   }
  // }, [dataGdp]);

  const numberFormatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
    useGrouping: true,
  });

  const [options5, setOptions5] = useState<AgChartOptions>({
    data: [],
  });

  const [dataDep, setDataDep] = useState<any>([]);

  const [options6, setOptions6] = useState<AgChartOptions>({
    data: [],
  });

  const [options7, setOptions7] = useState<AgChartOptions>({
    data: [],
  });
  const [options8, setOptions8] = useState<AgChartOptions>({
    data: [],
  });
  const [options15, setOptions15] = useState<AgChartOptions>({
    data: [],
  });
  const [options16, setOptions16] = useState<AgChartOptions>({
    data: [],
  });
  const [options9, setOptions9] = useState<AgChartOptions>({
    data: [],
  });
  const [options10, setOptions10] = useState<AgChartOptions>({
    data: [],
  });

  const [options11, setOptions11] = useState<AgGaugeOptions>({
    type: "radial-gauge",
    value: 80,
  });

  const [options12, setOptions12] = useState<AgGaugeOptions>({
    type: "radial-gauge",
    value: 80,
  });

  const [options13, setOptions13] = useState<AgGaugeOptions>({
    type: "radial-gauge",
    value: 80,
  });
  const [options14, setOptions14] = useState<AgGaugeOptions>({
    type: "radial-gauge",
    value: 80,
  });
  const _styleIndex1 = { height: "150px", width: "100%" };
  const _span1 = { base: 12, sm: 6, md: 3, lg: 3 };
  const _styleIndex2 = { base: 12, sm: 6, md: 6 };
  const _styleIndex3 = { base: 12 };

  const [height, setHeight] = useState(400);
  useEffect(() => {
    const onChange = (e: any) => {
      if (
        e.data.event === "posthog:dimensions" &&
        e.data.name === "MyPostHogIframe"
      ) {
        setHeight(e.data.height);
      }
    };
    window.addEventListener("message", onChange);
    return () => window.removeEventListener("message", onChange);
  }, []);

  return (
    <>
      <Box>
        <Grid>
          <Grid.Col span={_span1}>
            <AgGauge style={_styleIndex1} options={options11} />
          </Grid.Col>
          <Grid.Col span={_span1}>
            <AgGauge style={_styleIndex1} options={options12} />
          </Grid.Col>
          <Grid.Col span={_span1}>
            <AgGauge style={_styleIndex1} options={options13} />
          </Grid.Col>
          <Grid.Col span={_span1}>
            <AgGauge style={_styleIndex1} options={options14} />
          </Grid.Col>
          {/* <Grid.Col span={_span1}>
            <Paper style={_styleIndex1} withBorder shadow="xs" p="lg">
              <Group justify="space-around">
                <Grid>
                  <Grid.Col span={{ base: 6, lg: 6 }}>
                    <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
                      REQ/tháng:
                    </Text>
                    <Text fw={700} fz="xl">
                      {dataReq?.totalRequestsThisMonth}{" "}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={{ base: 6, lg: 6 }}>
                    <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
                      REQ/ngày:
                    </Text>
                    <Text fw={700} fz="xl">
                      {dataReq?.todayRequests}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
                      Req/phút:
                    </Text>
                    <Text fw={700} fz="xl">
                      {dataReq?.requestsPerSecondToday.toPrecision(3)}{" "}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Group>
            </Paper>{" "}
          </Grid.Col> */}
        </Grid>

        <Grid>
          {/* <Grid.Col span={{ base: 12, md: 6 }}>
          {dataGdp.length > 0 && (
            <AgCharts
              style={{ height: "850px", width: "100%" }}
              options={options5}
            />
          )}
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <AgCharts
            style={{ height: "850px", width: "100%" }}
            options={options6}
          />
        </Grid.Col> */}
          <Grid.Col span={_styleIndex2}>
            <Text fw={"bold"}>Danh sách nhân viên xuất sắc</Text>
            <AgCharts
              style={{ height: "350px", width: "100%" }}
              options={options9 as any}
            />
          </Grid.Col>
          <Grid.Col span={_styleIndex2}>
            <Text fw={"bold"}>Danh sách kho bán nhiều nhất</Text>
            <AgCharts
              style={{ height: "350px", width: "100%" }}
              options={options10 as any}
            />
          </Grid.Col>

          <Grid.Col span={_styleIndex3}>
            <Text fw={"bold"}>Danh sách đơn hàng theo tháng</Text>
            <AgCharts
              style={{ height: "450px", width: "100%" }}
              options={options7 as any}
            />
          </Grid.Col>
          <Grid.Col span={_styleIndex3}>
            <Text fw={"bold"}>Danh sách tổng tiền</Text>
            <AgCharts
              style={{ height: "450px", width: "100%" }}
              options={options8 as any}
            />
          </Grid.Col>

          {/* <Grid.Col span={12}>
            <Text fw={"bold"}>Biểu đồ lưu lương truy cập trong tháng</Text>
            <AgCharts
              style={{ height: "450px", width: "100%" }}
              options={options15 as any}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text fw={"bold"}>Biểu đồ lưu lương truy cập trong năm</Text>
            <AgCharts
              style={{ height: "450px", width: "100%" }}
              options={options16 as any}
            />
          </Grid.Col> */}
        </Grid>
      </Box>

      <div>
        {/* <iframe
          name="MyPostHogIframe"
          width="100%"
          height={height}
          src="https://app.posthog.com/embedded/PQ_VjRR-9F49pRJfmLaoflADhoKTsw"
        ></iframe> */}
        {/* <iframe
          width="100%"
          height={1000}
          src="https://us.posthog.com/embedded/-FcDntRDIyReNuC0dOEj84CY57stqA"
        ></iframe> */}
      </div>
    </>
  );
};

export default HomeView;
