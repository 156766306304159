export const exchangeReasons = [
  { value: "Đ1", label: "[Đ1]CS đổi mới 100%" },
  { value: "Đ2", label: "[Đ2]Lỗi do nhân viên" },
  { value: "Đ3", label: "[Đ3]Lỗi vận chuyển" },
  { value: "Đ4", label: "[Đ4]Lỗi sản phẩm" },
  { value: "Đ5", label: "[Đ5]Lý do khác" },
];

export const returnReasons = [
  { value: "T1", label: "[T1]Trả hàng tính phí" },
  { value: "T2", label: "[T2]Bom hàng" },
  { value: "T3", label: "[T3]Lỗi do nhân viên" },
  { value: "T4", label: "[T4]Lỗi do đối tác vận chuyển" },
  { value: "T5", label: "[T5]Thao tác nhầm(xử lý tại chỗ)" },
  { value: "T6", label: "[T6]KH đổi ý(xử lý tại cửa hàng)" },
  { value: "T7", label: "[T7]Sản phẩm lỗi(xử lý tại chỗ)" },
  { value: "T8", label: "[T8]Lý do khác" },
];
