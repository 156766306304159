import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Group,
  Menu,
  Select,
  SelectProps,
  Text,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedState, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCalendarSearch,
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileInvoice,
  IconFilePlus,
  IconFileUpload,
  IconFileX,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTransferIn,
  IconTransferOut,
  IconTrash,
  IconTruckDelivery,
  IconUserCode,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import DeleteView from "./DeleteView";
import { useNavigate } from "react-router";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { getValueById } from "../../../../_base/helper/FunctionHelper";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { tblRequestTransfer } from "../../../../model/TblRequestTransfer";
import {
  getBranchSelect,
  getTblDmEmployeeSelect,
  getTblDMInventory,
} from "../../../../service/getSelectApi";
import { green } from "../../../../const/variables";
import { AvatarUtils } from "../../../../common/ColorByName/AvatarUtils";
import InputSearch from "../../../../common/InputSearch/InputSearch";
import { MessageResponse } from "../../../../model/MessageResponse";
import { BASE_API_MDM } from "../../../../config";

function getColorStatus(text: any) {
  switch (text) {
    case "XUAT_KHO":
      return "#87de57";
    case "NHAP_KHO":
      return "teal";
    case "PHIEU_TAO_MOI":
      return "blue";
    case "KHOA":
      return "red";
    case "HOAN_THANH":
      return "green";
    case "DONG_YEU_CAU":
      return "gray";
    case "QUAN_LY_TU_CHOI":
    case "DPV_TU_CHOI":
      return "red";
    case null:
      return "rgba(0, 0, 0, 0)";
    default:
      return "gray";
  }
}

const getColorType = (type: string | null) => {
  switch (type) {
    case "DIEU_CHUYEN_CHI_NHANH":
      return "#f06418";
    case "DIEU_CHUYEN_CHI_NHANH_SERIAL":
      return "#ffab09";
    case "XUAT_HUY":
      return "#d9d02f";
    case "XUAT_DIEU_CHINH":
      return "#6bd731";
    case "DIEU_CHUYEN_NOI_BO":
      return "#09b8ff";
    case "DIEU_CHUYEN_NOI_BO_SERIAL":
      return "#1f32c4";
    case "DOI_LINH_KIEN":
      return "#c92a2a";
    case "XUAT_TRA_NCC":
      return "#2bdd66";
    case "DOI_LINH_KIEN":
      return "#f01879";
    case "XUAT_CONG_CU_DUNG_CU":
      return "#4c5897";
    case null:
      return "rgba(0, 0, 0, 0)";
    case "NOI_BO":
      return "#656a7e";
  }
};

const RequestWarehouseTransfer = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  // Reference data from another table
  const [dataTblLocation, setDataTblLocation] = useState<ComboboxItem[]>([]);
  const [dataTblInventoryTransactionType, setDataTblInventoryTransactionType] =
    useState<ComboboxItem[]>([
      {
        label: "Điều chuyển chi nhánh",
        value: "DIEU_CHUYEN_CHI_NHANH",
      },
      {
        label: "Điều chuyển chi nhánh serial",
        value: "DIEU_CHUYEN_CHI_NHANH_SERIAL",
      },
      {
        label: "Xuất kho hủy",
        value: "XUAT_HUY",
      },
      {
        label: "Xuất kho điều chỉnh",
        value: "XUAT_DIEU_CHINH",
      },
      {
        label: "Điều chuyển nội bộ",
        value: "DIEU_CHUYEN_NOI_BO",
      },
      {
        label: "Điều chuyển nội bộ serial",
        value: "DIEU_CHUYEN_NOI_BO_SERIAL",
      },
      {
        label: "Xuất trả NCC",
        value: "XUAT_TRA_NCC",
      },
      {
        label: "Đổi linh kiện",
        value: "DOI_LINH_KIEN",
      },
      {
        label: "Xuất công cụ/dụng cụ",
        value: "XUAT_CONG_CU_DUNG_CU",
      },
    ]);
  const [dataRequestTransferStatusOption, setDataRequestTransferStatusOption] =
    useState<ComboboxItem[]>([
      {
        value: "PHIEU_TAO_MOI",
        label: "Phiếu tạo mới",
      },
      {
        value: "XUAT_KHO",
        label: "Xuất kho",
      },
      // {
      //   value: "GIAO_VAN",
      //   label: "Giao vận",
      // },
      {
        value: "NHAP_KHO",
        label: "Nhập kho",
      },
      {
        value: "KHOA",
        label: "Khóa",
      },
      {
        value: "HOAN_THANH",
        label: "Hoàn thành",
      },
      {
        value: "DONG_YEU_CAU",
        label: "Đóng yêu cầu",
      },
    ]);

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<tblRequestTransfer[]>([]);
  const [dataEmployee, setDataEmployee] = useState<ComboboxItem[]>([]);
  // const [dataDeliveryDepartment, setDataDeliveryDepartment] = useState<
  //   TblDMDeliveryDepartment[]
  // >([]);
  const [dataInvSelect, setDataInvSelect] = useState<ComboboxItem[]>([]);
  const [dataAttribute1Select] = useState<ComboboxItem[]>([
    { label: "Không", value: "KHONG" },
    { label: "Nội bộ", value: "NOI_BO" },
    { label: "GHTK", value: "GHTK" },
    { label: "Nhất tín", value: "NHAT_TIN" },
    { label: "SCA", value: "SCA" },
    { label: "Tín thành", value: "TIN_THANH" },
    { label: "Viettel", value: "VIETTEL" },
    { label: "VN Post", value: "VN_POST" },
  ]);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataBranch, setDataBranch] = useState<ComboboxItem[]>([]);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [search, setSearch] = useDebouncedState(
    {
      keySearch: "",
      startTime: "",
      endTime: "",
      fromInvId: "",
      toInvId: "",
      statusCode: "",
      branchId: "",
      deliveryDepartmentId: "",
      createById: "",
      type: "",
      deliveryCode: "",
      fromBranch: JSON.parse(localStorage.getItem("userLoginHeader") ?? "")
        ?.branchId,
      toBranch: "",
    },
    300
  );

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "stt",
        header: "STT",
        Cell: ({ row }) => (
          <Text
            fw={500}
            size="12.5px"
          >
            {row.index === -1 ? "" : row.index + 1}
          </Text>
        ),
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableResizing: false,
      },
      {
        accessorKey: "code",
        header: "Số phiếu",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailItem(row.original.id)}
            >
              {renderedCellValue === null
                ? "Không xác định"
                : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "statusCode",
        header: "Trạng thái",
        Cell: ({ row }) => (
          <>
            <Badge color={getColorStatus(row.original.statusCode)}>
              {getStatus(row.original.statusCode)}
            </Badge>
          </>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "type",
        header: "Loại phiếu",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 250,
        Cell: ({ row }) => (
          <Badge
            color={getColorType(row.original.type ?? null)}
            variant="light"
            bd={`1px solid ${getColorType(row.original.type ?? null)}`}
          >
            {getValueById(
              row.original.type?.toString() ?? null,
              dataTblInventoryTransactionType,
              "label"
            )}
          </Badge>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "fromBranchName",
        header: "Chi nhánh xuất",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "fromInvName",
        header: "Kho xuất",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => (
          <Text
            size="12.5px"
            fw={500}
          >
            {row.original.fromInvCode} - {renderedCellValue}
          </Text>
        ),
        size: 225,
      },
      {
        accessorKey: "toBranchName",
        header: "Chi nhánh nhận",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "toInvName",
        header: "Kho nhận",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => (
          <Text
            size="12.5px"
            fw={500}
          >
            {row.original.toInvCode} - {renderedCellValue}
          </Text>
        ),
        size: 225,
      },
      {
        accessorKey: "attribute1",
        header: "Hình thức giao hàng",
        Cell: ({ row }) => (
          <Text
            fw={500}
            size="12.5px"
          >
            {getValueById(
              row.original.attribute1?.toString(),
              dataAttribute1Select,
              "label"
            )}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "createByName",
        header: "Người tạo",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ row }) => <AvatarUtils value={row.original.createByName} />,
        size: 225,
      },
      {
        accessorKey: "lastUpdateByName",
        header: "Người sửa cuối",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ row }) => (
          <AvatarUtils value={row.original.lastUpdateByName} />
        ),
        size: 225,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ row }) => (
          <Box w={450}>
            {row.original.note &&
            row.original.note !== null &&
            row.original.note.length > 0 ? (
              <Tooltip
                label={row.original.note}
                w={450}
                multiline
              >
                <Text
                  size="12.5px"
                  fw={500}
                  truncate="end"
                  h={15}
                >
                  {row.original.note}
                </Text>
              </Tooltip>
            ) : (
              <Text
                size="12.5px"
                fw={500}
                truncate="end"
                h={15}
              >
                {row.original.note}
              </Text>
            )}
          </Box>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        enableResizing: false,
        size: 190,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <Tooltip label="Điều phối duyệt">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="teal"
                onClick={async () => {
                  handleApproveRequestTranfer([row.id]);
                }}
              >
                <IconFileLike size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip> */}

            {/* <Tooltip label="Quản lý duyệt">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="teal"
                disabled={
                  row.original.statusid !== 201 && row.original.statusid !== 224
                }
                onClick={async () => {
                  handleApproveRequestTranfer(
                    row.id,
                    row.original.statusid || 0
                  );
                }}
              >
                <IconFileLike size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip> */}
            {row.original.statusCode !== "PHIEU_TAO_MOI" ? (
              <></>
            ) : (
              <Tooltip label="Chỉnh sửa">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="orange"
                  onClick={async () => {
                    editData(row.original.id, row.original.type);
                  }}
                >
                  <IconEdit
                    size={20}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Tooltip>
            )}
            {row.original.statusCode !== "KHOA" ||
            (row.original.attribute10 !== null &&
              row.original.attribute10 !== "DA_YEU_CAU") ? (
              <></>
            ) : (
              <Tooltip label="Xuất kho">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="blue"
                  onClick={async () => {
                    createDataOutWard(
                      row.original.code,
                      handleText(row.original.type)
                    );
                  }}
                >
                  <IconFilePlus
                    size={20}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye
                  size={20}
                  stroke={1.5}
                />
              </ActionIcon>
            </Tooltip>
            {row.original.statusCode !== "PHIEU_TAO_MOI" &&
            row.original.statusCode !== "KHOA" ? (
              <></>
            ) : (
              <Tooltip label="Đóng yêu cầu">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="red"
                  onClick={() => handleCloseRequest(row.original.id)}
                >
                  <IconFileX
                    size={20}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Tooltip>
            )}
            {row.original.statusCode !== "PHIEU_TAO_MOI" ? (
              <></>
            ) : (
              <Tooltip label="Xóa">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="red"
                  onClick={async () => {
                    await deleteData([row.original.id]);
                  }}
                >
                  <IconTrash
                    size={20}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Tooltip>
            )}
          </Box>
        ),
      },
    ],
    [
      dataTblLocation,
      dataRequestTransferStatusOption,
      dataTblInventoryTransactionType,
      dataAttribute1Select,
    ]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  function getStatus(id: string | null) {
    const status = dataRequestTransferStatusOption?.find(
      (status) => status.value === id?.toString()
    );

    return status ? status.label : "";
  }

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const createData = () => {
    navigate("/warehouse-config/create-request-transfer");
  };

  const createDataOutWard = (
    sourceCode?: string | null,
    sourceType?: string | null
  ) => {
    if (sourceCode) {
      navigate("/warehouse-config/create-warehouse-out-ward", {
        state: { sourceCode: sourceCode, sourceType: sourceType },
      });
    } else {
      navigate("/warehouse-config/create-warehouse-out-ward");
    }
  };

  const editData = (id: string | number, type: string) => {
    navigate("/warehouse-config/edit-request-transfer", {
      state: { id: id, type: type },
    });
  };

  const handleCloseRequest = (id: any) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xác nhận đóng yêu cầu !</Title>
        </>
      ),
      size: "550px",
      children: (
        <Box pt={10}>
          <Text
            fw={500}
            size="18px"
          >
            Bạn có chắc muốn đóng yêu cầu điều chuyển này ?
          </Text>
          <Flex
            justify={"end"}
            gap={"md"}
            mt={20}
          >
            <Button
              color="red"
              leftSection={<IconX size={14} />}
              onClick={() => modals.closeAll()}
            >
              Hủy
            </Button>
            <Button
              color="green"
              leftSection={<IconCheck size={14} />}
              onClick={() => handleChangeStatus(id)}
            >
              Xác nhận
            </Button>
          </Flex>
        </Box>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const detailItem = (id: string | number) => {
    navigate("/warehouse-config/detail-request-transfer", {
      state: { id: id },
    });
  };

  const deleteData = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa phiếu điều chuyển!</Title>
            </>
          ),
          size: "550px",
          children: (
            <DeleteView
              idItems={ids}
              fetchData={fetchData}
            />
          ),
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const optionColorType: Record<
    | "DIEU_CHUYEN_CHI_NHANH"
    | "DIEU_CHUYEN_CHI_NHANH_SERIAL"
    | "XUAT_HUY"
    | "XUAT_DIEU_CHINH"
    | "DIEU_CHUYEN_NOI_BO"
    | "DIEU_CHUYEN_NOI_BO_SERIAL"
    | "XUAT_TRA_NCC"
    | "DOI_LINH_KIEN"
    | "XUAT_CONG_CU_DUNG_CU",
    string
  > = {
    DIEU_CHUYEN_CHI_NHANH: "#f06418",
    DIEU_CHUYEN_CHI_NHANH_SERIAL: "#ffab09",
    XUAT_HUY: "#d9d02f",
    XUAT_DIEU_CHINH: "#6bd731",
    DIEU_CHUYEN_NOI_BO: "#09b8ff",
    DIEU_CHUYEN_NOI_BO_SERIAL: "#1f32c4",
    XUAT_TRA_NCC: "#2bdd66",
    DOI_LINH_KIEN: "#f01879",
    XUAT_CONG_CU_DUNG_CU: "#4c5897",
  };

  const renderSelectOptionType: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group
      flex="1"
      gap="xs"
    >
      <Badge
        color={
          optionColorType[
            option.value as
              | "DIEU_CHUYEN_CHI_NHANH"
              | "DIEU_CHUYEN_CHI_NHANH_SERIAL"
              | "XUAT_HUY"
              | "XUAT_DIEU_CHINH"
              | "DIEU_CHUYEN_NOI_BO"
              | "DIEU_CHUYEN_NOI_BO_SERIAL"
              | "XUAT_TRA_NCC"
              | "DOI_LINH_KIEN"
              | "XUAT_CONG_CU_DUNG_CU"
          ]
        }
        bd={`1px solid ${
          optionColorType[
            option.value as
              | "DIEU_CHUYEN_CHI_NHANH"
              | "DIEU_CHUYEN_CHI_NHANH_SERIAL"
              | "XUAT_HUY"
              | "XUAT_DIEU_CHINH"
              | "DIEU_CHUYEN_NOI_BO"
              | "DIEU_CHUYEN_NOI_BO_SERIAL"
              | "XUAT_TRA_NCC"
              | "DOI_LINH_KIEN"
              | "XUAT_CONG_CU_DUNG_CU"
          ]
        }`}
        radius="sm"
        variant="light"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck
          size={"14px"}
          style={{ marginInlineStart: "auto" }}
        />
      )}
    </Group>
  );

  const optionColorStatusCode: Record<
    | "PHIEU_TAO_MOI"
    | "NHAP_KHO"
    | "XUAT_KHO"
    | "KHOA"
    | "HOAN_THANH"
    | "DONG_YEU_CAU",
    string
  > = {
    PHIEU_TAO_MOI: "#228BE6",
    NHAP_KHO: "#12B886",
    XUAT_KHO: "#87DE57",
    KHOA: "red",
    HOAN_THANH: "green",
    DONG_YEU_CAU: "gray",
  };

  const renderSelectOptionStatusCode: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group
      flex="1"
      gap="xs"
    >
      <Badge
        color={
          optionColorStatusCode[
            option.value as
              | "PHIEU_TAO_MOI"
              | "NHAP_KHO"
              | "XUAT_KHO"
              | "KHOA"
              | "HOAN_THANH"
              | "DONG_YEU_CAU"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck
          size={"14px"}
          style={{ marginInlineStart: "auto" }}
        />
      )}
    </Group>
  );

  function handleText(text: string): string {
    if (text.includes("XUAT_")) {
      return text;
    } else {
      return `XUAT_${text}`;
    }
  }

  const getDataInvSelect = async () => {
    const res = await getTblDMInventory();
    setDataInvSelect(
      res
        ?.filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  // const findDeliveryDepartmentById = (id: string): string | null => {
  //   const delivery = dataDeliveryDepartment.find(
  //     (emp) => emp.id.toString() === id
  //   );
  //   return delivery ? delivery.ddName : null;
  // };

  useHotkeys([
    [
      "F11",
      () => {
        createData();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    if (search.startTime) {
      url += `&FromCreateDate=${search.startTime}`;
    }

    if (search.endTime) {
      url += `&ToCreateDate=${search.endTime}`;
    }
    if (search.fromInvId) {
      url += `&FromInvId=${search.fromInvId}`;
    }
    if (search.toInvId) {
      url += `&ToInvId=${search.toInvId}`;
    }
    if (search.statusCode) {
      url += `&StatusCode=${search.statusCode}`;
    }
    if (search.fromBranch) {
      url += `&FromBranchId=${search.fromBranch}`;
    }
    if (search.toBranch) {
      url += `&ToBranchId=${search.toBranch}`;
    }
    if (search.deliveryDepartmentId) {
      url += `&DeliveryDepartmentId=${search.deliveryDepartmentId}`;
    }
    if (search.createById) {
      url += `&CreateById=${search.createById}`;
    }
    if (search.type) {
      url += `&Type=${search.type}`;
    }
    if (search.deliveryCode) {
      url += `&DeliveryCode=${search.deliveryCode}`;
    }

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
      url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblRequestTransfer/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        setRowCount(response.totalCount);
        setSelectIds([]);
        await Promise.all([
          // dataInventoryTransactionType(),
          getDataInvSelect(),
          fetchDataBranch(),
        ]);
        table.resetRowSelection();
      } else {
        setData([]);
        setIsError(true);
        setIsLoading(false);
        return;
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
    }
    startCountdown();
  };

  const handleExport = async () => {
    let url = `/api/v1/ExportExcel/export-request-transfers?Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.startTime) {
      url += `&FromCreateDate=${search.startTime}`;
    }
    if (search.endTime) {
      url += `&ToCreateDate=${search.endTime}`;
    }
    if (search.fromInvId) {
      url += `&FromInvId=${search.fromInvId}`;
    }
    if (search.toInvId) {
      url += `&ToInvId=${search.toInvId}`;
    }
    if (search.statusCode) {
      url += `&StatusCode=${search.statusCode}`;
    }
    if (search.fromBranch) {
      url += `&FromBranchId=${search.fromBranch}`;
    }
    if (search.toBranch) {
      url += `&ToBranchId=${search.toBranch}`;
    }
    if (search.deliveryDepartmentId) {
      url += `&DeliveryDepartmentId=${search.deliveryDepartmentId}`;
    }
    if (search.createById) {
      url += `&CreateById=${search.createById}`;
    }
    if (search.type) {
      url += `&Type=${search.type}`;
    }
    if (search.deliveryCode) {
      url += `&DeliveryCode=${search.deliveryCode}`;
    }

    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "danh_sach_phieu_yeu_cau_dieu_chuyen" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleChangeStatus = async (id: any) => {
    const res = await repositoryMdm.post<MessageResponse<any>>(
      `/api/v1/TblRequestTransfer/update-status?StatusCode=DONG_YEU_CAU&Id=${id}`
    );

    if (res && res?.success) {
      NotificationExtension.Success("Đã đóng yêu cầu !");
      setTimeout(() => {
        fetchData();
        modals.closeAll();
      }, 1000);
    }
  };

  const fetchDataBranch = async () => {
    const getDataBranch = await getBranchSelect();

    const mappedData = getDataBranch
      .filter(
        (item) => item.value != null && item.text != null && item.value != "-1"
      )
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataBranch(mappedData);
  };

  const getDataEmpSelect = async () => {
    const res = await getTblDmEmployeeSelect();
    setDataEmployee(
      res
        ?.filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: `${item.text} - ${item.value}`,
        }))
    );
  };

  // const fetchDeliveryDepartment = async () => {
  //   const url = `/api/v1/TblDmDeliveryDepartment/get-all`;
  //   try {
  //     const searchResult = await repositoryMdm.get<
  //       MessageResponse<TblDMDeliveryDepartment[]>
  //     >(url);
  //     if (searchResult?.data) {
  //       setDataDeliveryDepartment(searchResult.data);
  //     } else {
  //       setDataDeliveryDepartment([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setDataDeliveryDepartment([]);
  //   }
  // };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "stt", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onSortingChange: setSorting,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "12.5px",
        padding: "4px 12px",
        fontWeight: 500,
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                createData();
              }}
            >
              Tạo mới (F11)
            </Button>

            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                await deleteData(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>

            <Menu
              shadow="md"
              width={200}
            >
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={() => handleExport()}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <Grid mt={10}>
          <GridCol span={{ base: 12, md: 6, lg: 2 }}>
            {/* <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              defaultValue={search.keySearch}
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            /> */}
            <InputSearch
              width="100%"
              placeholder="Từ khoá"
              onSearch={fetchData}
              defaultValue={search.keySearch}
              onChange={(value) => {
                handleChangeSearchValue(value, "keySearch");
              }}
            />
          </GridCol>
          <GridCol span={{ base: 12, md: 12, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Từ ngày"
              leftSection={<IconCalendarSearch color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                const startTime = e
                  ? new Date(e.setHours(e.getHours() + 7)).toISOString()
                  : "";
                handleChangeSearchValue(startTime, "startTime");
              }}
              clearable
            />
          </GridCol>

          <GridCol span={{ base: 12, md: 12, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Đến ngày"
              leftSection={<IconCalendarSearch color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                const endTime = e
                  ? new Date(e.setHours(e.getHours() + 7)).toISOString()
                  : "";
                handleChangeSearchValue(endTime, "endTime");
              }}
              clearable
            />
          </GridCol>

          {/*<Grid.Col span={{ base: 6, sm: 4, md: 1.5 }}>
            <Select
              flex={1}
              searchable
              clearable
              placeholder="Chi nhánh"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              nothingFoundMessage={"Loading..."}
              data={dataBranch}
              onChange={(value) => {
                handleChangeSearchValue(value ?? "", "branchId");
              }}
              onClick={() => {
                if (dataBranch.length === 0) {
                  fetchDataBranch();
                }
              }}
            />
          </Grid.Col> */}

          {/* <Grid.Col span={{ base: 6, sm: 4, md: 1.5 }}>
            <Select
              flex={1}
              searchable
              clearable
              placeholder="Bộ phận giao hàng"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              leftSection={<IconTruckDelivery color="#15aabf" />}
              nothingFoundMessage={"Loading..."}
              data={dataDeliveryDepartment.map((delivery) => ({
                label: delivery.ddName ?? "",
                value: String(delivery.id ?? ""),
              }))}
              onChange={(value) => {
                handleChangeSearchValue(value ?? "", "deliveryDepartmentId");
              }}
              onClick={() => {
                if (dataDeliveryDepartment.length === 0) {
                  fetchDeliveryDepartment();
                }
              }}
            />
          </Grid.Col> */}

          <GridCol span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder={"Trạng thái phiếu"}
              clearable
              leftSection={<IconStatusChange color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Loading..."}
              data={dataRequestTransferStatusOption}
              renderOption={renderSelectOptionStatusCode}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "statusCode")
              }
            />
          </GridCol>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Loại phiếu"
              clearable
              leftSection={<IconFileInvoice color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
                width: 325,
              }}
              nothingFoundMessage={"Loading..."}
              data={dataTblInventoryTransactionType}
              renderOption={renderSelectOptionType}
              onChange={(value) => handleChangeSearchValue(value || "", "type")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Chi nhánh xuất"
              searchable
              clearable
              data={dataBranch}
              value={search.fromBranch?.toString()}
              leftSection={<IconTransferOut color="#15aabf" />}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "fromBranch")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Chi nhánh nhập"
              searchable
              clearable
              data={dataBranch}
              leftSection={<IconTransferIn color="#15aabf" />}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "toBranch")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Chọn phương thức giao hàng"
              searchable
              clearable
              data={dataAttribute1Select}
              leftSection={<IconTruckDelivery color="#15aabf" />}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "deliveryCode")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder={"Chọn nhân viên"}
              searchable
              clearable
              leftSection={<IconUserCode color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataEmployee}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "createById")
              }
              onClick={() => {
                if (dataEmployee.length === 0) {
                  getDataEmpSelect();
                }
              }}
            />
          </Grid.Col>
          <GridCol span={{ base: 12, md: 12, lg: 2 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div
        className="mt-5"
        style={{ marginTop: "10px" }}
      >
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default RequestWarehouseTransfer;
