import {
  Badge,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Menu,
  rem,
  Select,
  SelectProps,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { TblDMExpense } from "../../../model/TblDMExpense";
import {
  IconBrandBootstrap,
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconFileExport,
  IconFileUpload,
  IconLocationFilled,
  IconSearch,
  IconStarFilled,
  IconUsers,
} from "@tabler/icons-react";
import { useDebouncedState } from "@mantine/hooks";
import { green, teal } from "../../../const/variables";
import { BASE_API_MDM } from "../../../config";
import { DatePickerInput } from "@mantine/dates";
import {
  getBranchSelectMdm,
  getTblDmProcessingMethodSelect,
} from "../../../service/getSelectApi";
import { MessageResponse } from "../../../model/MessageResponse";

const RequestFormReport = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMExpense[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //select
  const [dataTblBranch, setDataTblBranch] = useState<ComboboxItem[]>([]);
  const [dataTblDmRequestStatus] = useState<any>([
    { label: "Chờ phân công", value: "Chờ phân công" },
    { label: "Đã phân công", value: "Đã phân công" },
    { label: "Hủy phân công", value: "Hủy phân công" },
    { label: "Đang xử lý", value: "Đang xử lý" },
    { label: "Đã xử lý", value: "Đã xử lý" },
    { label: "Hãng xử lý", value: "Hãng xử lý" },
    { label: "Hãng đang xử lý", value: "Hãng đang xử lý" },
    { label: "Hãng đã xử lý", value: "Hãng đã xử lý" },
    { label: "Xử lý lại", value: "Xử lý lại" },
    { label: "Kỹ thuật đã xử lý", value: "Kỹ thuật đã xử lý" },
    { label: "Chờ phiếu BN", value: "Chờ phiếu BN" },
    { label: "Nhập kho BH", value: "Nhập kho BH" },
    { label: "Trả khách", value: "Trả khách" },
  ]);
  const [dataTblProcessingMethod, setDataTblProcessingMethod] = useState<
    ComboboxItem[]
  >([]);
  const [dataWarrantyCustomerGroup, setDataWarrantyCustomerGroup] = useState<
    ComboboxItem[]
  >([]);
  //search
  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    status: "",
    processingMethod: "",
    customerGroup: "",
    branch: "",
  });

  const [keySearch, setKeySearch] = useDebouncedState<string>("", 300);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.index === -1 ? "" : row.index + 1}
          </Text>
        ),
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        enableResizing: false,
      },
      {
        accessorKey: "branchCode",
        header: "Mã chi nhánh",
        size: 175,
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
      },
      {
        accessorKey: "requestNum",
        header: "Phiếu yêu cầu xử lý",
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
        size: 200,
      },
      {
        accessorKey: "errorNumber",
        header: "Phiếu tiếp nhận hàng lỗi",
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Chưa tiếp nhận" : renderedCellValue}
          </Badge>
        ),
        size: 225,
      },
      {
        accessorKey: "createDate",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px" fw={"500"}>
            {renderedCellValue && formatDateTransfer(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "meetingDate",
        header: "Ngày hẹn xử lý",
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px" fw={"500"}>
            {renderedCellValue && formatDateTransfer(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "handleDate",
        header: "Ngày xử lý",
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px" fw={"500"}>
            {renderedCellValue && formatDateTransfer(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "attribute2",
        header: "Khách hẹn xử lý",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue} ngày
          </Text>
        ),
      },
      {
        accessorKey: "entryDate",
        header: "Ngày hẹn trả",
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px" fw={"500"}>
            {renderedCellValue && formatDateTransfer(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "amountReturnDate",
        header: "Số ngày hẹn trả",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue} ngày
          </Text>
        ),
      },
      {
        accessorKey: "attribute1",
        header: "Số ngày tiếp nhận",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue} ngày
          </Text>
        ),
        size: 200,
      },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng hẹn gặp",
        size: 200,
      },
      {
        accessorKey: "custPhone",
        header: "Số điện thoại",
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ hẹn gặp",
        Cell: ({ renderedCellValue }) => {
          if (renderedCellValue?.toString()?.trim()?.startsWith("-")) {
            return (
              <Text fw={500} size="12.5px">
                {renderedCellValue?.toString()?.trim()?.slice(1)}
              </Text>
            );
          }
          return (
            <Text fw={500} size="12.5px">
              {renderedCellValue?.toString()}
            </Text>
          );
        },
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm BH",
        Cell: ({ renderedCellValue, row }: any) =>
          renderedCellValue === null ? null : (
            <Badge variant="light">{renderedCellValue}</Badge>
          ),
      },
      {
        accessorKey: "itemName",
        header: "Sản phẩm BH",
      },
      {
        accessorKey: "serial",
        header: "Serial",
      },
      {
        accessorKey: "buyingDate",
        header: "Ngày mua",
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px" fw={"500"}>
            {renderedCellValue && formatDateTransfer(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "listErrorCode",
        header: "Ghi nhận lỗi ban đầu",
        size: 225,
      },
      {
        accessorKey: "conclusionError",
        header: "Diễn giải",
      },
      {
        accessorKey: "resolution",
        header: "Cách khác phục",
      },
      {
        accessorKey: "itemStatus",
        header: "Trạng thái xử lý",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue === "NHAP_KHO" ? "Nhập kho" : "Trả khách"}
          </Text>
        ),
      },
      {
        accessorKey: "processingMethodName",
        header: "Hình thức xử lý",
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            color={getColorProcessingMethod(row.original?.processingMethodName)}
          >
            {renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "soSerialStatus",
        header: "Trạng thái serial",
      },
      {
        accessorKey: "debtStatus",
        header: "Trạng thái trả khách",
        size: 225,
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  function getColorProcessingMethod(value: string) {
    switch (value) {
      case "Xử lý tại nhà":
        return "lime";
      case "Hãng xử lý":
        return "green";
      case "Xử ý trực tiếp":
        return "teal";
      case "Xử lý tại CH/CN":
        return "cyan";
      case "Xử lý từ xa":
        return "orange";
    }
  }

  const optionColorsAccount: Record<
    | "Xử lý tại nhà"
    | "Hãng xử lý"
    | "Xử lý tại chỗ"
    | "Xử lý tại CH/CN"
    | "Xử lý từ xa",
    string
  > = {
    "Xử lý tại nhà": "lime",
    "Hãng xử lý": "green",
    "Xử lý tại chỗ": "teal",
    "Xử lý tại CH/CN": "cyan",
    "Xử lý từ xa": "orange",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsAccount[
            option.label as
              | "Xử lý tại nhà"
              | "Hãng xử lý"
              | "Xử lý tại chỗ"
              | "Xử lý tại CH/CN"
              | "Xử lý từ xa"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate.length > 0) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate.length > 0) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.processingMethod.length > 0) {
      url += `&ProcessingMethod=${search.processingMethod}`;
    }

    if (search.customerGroup.length > 0) {
      url += `&CustomerGroup=${search.customerGroup}`;
    }

    if (search.branch.length > 0) {
      url += `&BranchId=${search.branch}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyServiceRequestForm/get-list-report${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const handleExportData = async () => {
    let url = `/api/v1/TblWarrantyServiceRequestForm/export-handle-warranty?Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.startDate.length > 0) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate.length > 0) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.processingMethod.length > 0) {
      url += `&ProcessingMethod=${search.processingMethod}`;
    }

    if (search.customerGroup.length > 0) {
      url += `&CustomerGroup=${search.customerGroup}`;
    }

    if (search.branch.length > 0) {
      url += `&BranchId=${search.branch}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "bao_cao_thuc_hien_xu_ly_yeu_cau" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const dataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataTblBranch(
      getData
        ?.filter(
          (item) =>
            item.value != null && item.text != null && item.value != "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataProcessingMethodSelect = async () => {
    try {
      const getData = await getTblDmProcessingMethodSelect();
      const filteredData = getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          label: item.text,
          value: item.value?.toString(),
        }));
      setDataTblProcessingMethod(filteredData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const getDataWarrantyCustomerGroup = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblWarrantyCustomerGroup/get-all"
    );

    if (res && res?.success) {
      const getData = res.data;
      const filteredData = getData?.filter(
        (item: any) => item.text != null && item.value != null
      );
      const mappedData = filteredData?.map((item: any) => ({
        value: item.value?.toString(),
        label: item.text,
      }));
      setDataWarrantyCustomerGroup(mappedData);
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (140 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <_breadcrumb></_breadcrumb>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() => handleExportData()}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>
            </Menu.Dropdown>
          </Menu>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
            <DatePickerInput
              size="sm"
              placeholder="Từ ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "startDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
            <DatePickerInput
              size="sm"
              placeholder="Đến ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
            <Select
              placeholder="Chi nhánh"
              searchable
              clearable
              data={dataTblBranch}
              onClick={() => {
                if (dataTblBranch.length === 0) {
                  dataBranchSelect();
                }
              }}
              leftSection={
                <IconBrandBootstrap size={20} style={{ color: teal.base }} />
              }
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "branch")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
            <Select
              placeholder="Hình thức xử lý"
              type="text"
              w={"100%"}
              clearable
              searchable
              leftSection={
                <IconLocationFilled
                  size={20}
                  style={{ color: teal.base }}
                ></IconLocationFilled>
              }
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataTblProcessingMethod}
              onClick={() => {
                if (dataTblProcessingMethod.length === 0) {
                  dataProcessingMethodSelect();
                }
              }}
              onChange={(e) => {
                handleChangeSearchValue(
                  e?.toString() ?? "",
                  "processingMethod"
                );
              }}
              renderOption={renderSelectOptionStatus}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
            <Select
              placeholder="Nhóm khách hàng"
              data={dataWarrantyCustomerGroup}
              searchable
              clearable
              leftSection={<IconUsers size={20} style={{ color: teal.base }} />}
              onClick={() => {
                if (dataWarrantyCustomerGroup.length === 0) {
                  getDataWarrantyCustomerGroup();
                }
              }}
              onChange={(e) => {
                handleChangeSearchValue(e?.toString() ?? "", "customerGroup");
              }}
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              defaultValue={keySearch}
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default RequestFormReport;
