import {
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Select,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import {
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
  getStreetSelect,
} from "../../../service/getSelectApi";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";
import { IconArrowBackUp, IconCheck } from "@tabler/icons-react";

const ChangeLocation = ({
  id,
  province,
  district,
  commune,
  street,
  addr,
  deleteViewStatus,
}: {
  id: any;
  province: any;
  district: any;
  commune: any;
  street: any;
  addr: any;
  deleteViewStatus: any;
}) => {
  const [provinceId, setProvinceId] = useState<any>(province);
  const [districtId, setDistrictId] = useState<any>(district);
  const [communeId, setCommuneId] = useState<any>(commune);
  const [streetId, setStreetId] = useState<any>(street);
  const [address, setAddress] = useState<any>(addr);
  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataDistrictSelect, setDataDistrictSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataCommuneSelect, setDataCommuneSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataStreetSelect, setDataStreetSelect] = useState<ComboboxItem[]>([]);
  const [toAddressNew, setToAddress] = useState<any>();

  const changeLocation = async () => {
    const res = await repositoryDelivery.post(
      "/api/v1/TblDelivery/update-to-address-v2",
      {
        id: id,
        provinceId: provinceId,
        districtId: districtId,
        communeId: communeId,
        streetId: streetId,
        addressDetail: address,
        toAddress: toAddressNew,
      }
    );

    if (res && res?.success) {
      NotificationExtension.Success("Đã thay đổi điểm đến !");
      setTimeout(() => {
        modals.closeAll();
        deleteViewStatus((prev: any) => !prev);
      }, 1000);
    }
  };

  const getDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataProvinceSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataDistrictSelect = async () => {
    if (provinceId !== null) {
      const getData = await getDistrictSelect(provinceId ?? "");
      setDataDistrictSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataCommuneSelect = async () => {
    if (districtId !== null) {
      const getData = await getCommuneSelect(districtId ?? "");
      setDataCommuneSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataStreetSelect = async () => {
    if (communeId !== null) {
      const getData = await getStreetSelect(communeId ?? "");
      setDataStreetSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  useEffect(() => {
    getDataProvinceSelect();
  }, []);

  useEffect(() => {
    setToAddress(
      `${address?.toString()?.length > 0 ? `${address} - ` : ""} ${
        streetId
          ? `${getValueById(
              streetId?.toString(),
              dataStreetSelect,
              "label"
            )} - `
          : ""
      } ${
        communeId
          ? `${getValueById(
              communeId?.toString(),
              dataCommuneSelect,
              "label"
            )} -`
          : ""
      } ${
        districtId
          ? `${getValueById(
              districtId?.toString(),
              dataDistrictSelect,
              "label"
            )} - `
          : ""
      } ${
        provinceId
          ? `${getValueById(
              provinceId?.toString(),
              dataProvinceSelect,
              "label"
            )}`
          : ""
      }`
    );
  }, [
    provinceId,
    districtId,
    communeId,
    streetId,
    address,
    province,
    district,
    commune,
    street,
    addr,
    dataProvinceSelect,
    dataCommuneSelect,
    dataDistrictSelect,
    dataStreetSelect,
  ]);

  useEffect(() => {
    if (provinceId !== null) {
      getDataDistrictSelect();
    }
  }, [provinceId]);

  useEffect(() => {
    if (districtId !== null) {
      getDataCommuneSelect();
    }
  }, [districtId]);

  useEffect(() => {
    if (communeId !== null) {
      getDataStreetSelect();
    }
  }, [communeId]);

  return (
    <Box>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
          <Select
            label="Tỉnh/TP"
            placeholder="Nhập tỉnh/thành phố"
            data={dataProvinceSelect}
            value={provinceId ? provinceId?.toString() : null}
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
            onChange={(e) => {
              setProvinceId(e);
              setDistrictId(null);
              setCommuneId(null);
              setStreetId(null);
              setAddress(null);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
          <Select
            label="Quận/Huyện"
            placeholder="Nhập quận/huyện"
            data={dataDistrictSelect}
            value={districtId ? districtId?.toString() : null}
            searchable
            clearable
            disabled={provinceId === null}
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
            onChange={(e) => {
              setDistrictId(e);
              setCommuneId(null);
              setStreetId(null);
              setAddress("");
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
          <Select
            label="Phường/Xã"
            placeholder="Nhập phường/xã"
            data={dataCommuneSelect}
            value={communeId ? communeId?.toString() : null}
            searchable
            clearable
            disabled={districtId === null || provinceId === null}
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
            onChange={(e) => {
              setCommuneId(e);
              setStreetId(null);
              setAddress("");
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
          <Select
            label="Tên đường"
            placeholder="Nhập tên đường"
            data={dataStreetSelect}
            value={streetId ? streetId?.toString() : null}
            disabled={
              communeId === null || districtId === null || provinceId === null
            }
            onChange={(e) => {
              setStreetId(e);
              setAddress("");
            }}
            nothingFoundMessage="Không tìm thấy dữ liệu !"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
          <TextInput
            label="Địa chỉ cụ thể"
            placeholder="Nhập địa chỉ cụ thể"
            value={address}
            disabled={
              communeId === null || districtId === null || provinceId === null
            }
            onChange={(e) => setAddress(e.currentTarget.value)}
          />
        </Grid.Col>
      </Grid>
      <Flex justify={"end"} gap={"md"}>
        <Button
          variant="outline"
          color="red"
          leftSection={<IconArrowBackUp size={14} />}
          onClick={() => modals.closeAll()}
        >
          Quay lại
        </Button>
        <Button
          variant="outline"
          color="green"
          leftSection={<IconCheck size={14} />}
          onClick={() => changeLocation()}
        >
          Xác nhận
        </Button>
      </Flex>
    </Box>
  );
};

export default ChangeLocation;
