import {
  Badge,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Menu,
  rem,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { _timerDelayClick, repositoryMdm } from "../../_base/_const/_constVar";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../_base/model/_base/BaseTable";
import { _localization } from "../../config/location";
import { TblDMExpense } from "../../model/TblDMExpense";
import {
  IconBrandBootstrap,
  IconCalendar,
  IconCaretDown,
  IconFileExport,
  IconFileUpload,
  IconSearch,
} from "@tabler/icons-react";
import { useDebouncedState } from "@mantine/hooks";
import { green, orange, sky_blue, teal } from "../../const/variables";
import { BASE_API_MDM } from "../../config";
import { AvatarUtils } from "../../common/ColorByName/AvatarUtils";
import { getValueById } from "../../_base/helper/FunctionHelper";
import { DatePickerInput } from "@mantine/dates";
import { formatDateNotTimeZone } from "../../common/FormatDate/FormatDate";
import { getBranchSelectMdm } from "../../service/getSelectApi";

const PayrollReport = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMExpense[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //select
  const dataStatusOption = [
    { value: "TAO_MOI", label: "Tạo mới" },
    { value: "PHAN_CONG", label: "Phân công" },
    { value: "DANG_KIEM_TRA", label: "Đang kiểm tra" },
    { value: "TEST_MOT_PHAN", label: "Test một phần" },
    { value: "TEST_LOI", label: "Test lỗi" },
    { value: "TEST_OK", label: "Test ok" },
    { value: "HUY_PHAN_CONG", label: "Hủy phân công" },
  ];
  const [dataTblBranch, setDataTblBranch] = useState<ComboboxItem[]>([]);

  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    branchCode: "",
  });
  const [keySearch, setKeySearch] = useDebouncedState<string>("", 300);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.index === -1 ? "" : row.index + 1}
          </Text>
        ),
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        enableResizing: false,
      },
      {
        accessorKey: "branchCode",
        header: "Mã chi nhánh",
      },
      {
        accessorKey: "branchName",
        header: "Tên chi nhánh",
      },
      {
        accessorKey: "empCode",
        header: "Mã nhân viên",
      },
      {
        accessorKey: "empName",
        header: "Nhân viên kiểm tra",
        size: 200,
        Cell: ({ renderedCellValue }) =>
          renderedCellValue !== null ? (
            <AvatarUtils
              value={renderedCellValue?.toString() ?? ""}
              sizeXS={true}
            />
          ) : (
            <Text fw={500} size="12.5px">
              Chưa tiếp nhận
            </Text>
          ),
      },
      {
        accessorKey: "sourceCode",
        header: "Số chứng từ gốc",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "sourceType",
        header: "Nguồn chứng từ",
        size: 225,
        Cell: ({ renderedCellValue }) => (
          <Badge
            color={getColorSourceType(renderedCellValue?.toString() ?? "")}
            radius={"sm"}
          >
            {getDataSourceType(renderedCellValue?.toString() ?? "")}
          </Badge>
        ),
      },
      {
        accessorKey: "testDifficulty",
        header: "Mức độ test",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue !== null ? (
            <Badge
              color={getColorTestDifficulty(
                renderedCellValue?.toString() ?? ""
              )}
              radius={"sm"}
            >
              {getDataTestDifficulty(renderedCellValue?.toString() ?? "")}
            </Badge>
          ) : (
            <></>
          ),
      },
      {
        accessorKey: "testPoint",
        header: "Điểm",
        size: 125,
      },
      {
        accessorKey: "testCode",
        header: "Mã kiểm tra",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "testingStatus",
        header: "Trạng thái kiểm tra",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue !== null ? (
            <>
              <Badge
                color={getColorStatus(renderedCellValue?.toString())}
                radius={"sm"}
              >
                {getValueById(
                  renderedCellValue?.toString() ?? "",
                  dataStatusOption,
                  "label"
                )}
              </Badge>
            </>
          ) : (
            <></>
          ),
      },
      {
        accessorKey: "customerName",
        header: "Tên khách hàng",
      },
      {
        accessorKey: "customerTelephone",
        header: "SĐT",
      },
      {
        accessorKey: "note",
        header: "Ghi chú kiểm tra",
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  function getColorStatus(text: any) {
    switch (text) {
      case "TAO_MOI":
        return sky_blue.base;
      case "PHAN_CONG":
        return orange.base;
      // case "TIEP_NHAN":
      //   return "#ffab09";
      case "DANG_KIEM_TRA":
        return "#d9d02f";
      // case "DA_KIEM_TRA":
      //   return "green";
      case "TEST_MOT_PHAN":
        return "#ffcc00";
      case "TEST_LOI":
        return "red";
      case "TEST_OK":
        return "#17a2b8";
      case "HUY_PHAN_CONG":
        return "red";
      case "":
        return "green";
      default:
        return sky_blue.base;
    }
  }

  const getColorTestDifficulty = (value: string | null) => {
    switch (value) {
      case "KHONG_TEST":
        return "#D3D3D3";
      case "DE":
        return "#32CD32";
      case "DON_GIAN":
        return "#90EE90";
      case "BINH_THUONG":
        return "#FFD700";
      case "KHO":
        return "#FFA500";
      case "PHUC_TAP":
        return "#FF4500";
    }
  };

  const getDataTestDifficulty = (value: string) => {
    switch (value) {
      case "KHONG_TEST":
        return "Không test";
      case "DE":
        return "Dễ";
      case "DON_GIAN":
        return "Đơn giản";
      case "BINH_THUONG":
        return "Bình thường";
      case "KHO":
        return "Khó";
      case "PHUC_TAP":
        return "Phức tạp";
    }
  };

  const getColorSourceType = (value: string) => {
    switch (value) {
      case "NHAP_KHO_BH":
        return "orange";
      case "DON_HANG":
        return "cyan";
      case "NHAP_KHO_BH_NCC":
        return "teal";
      case "Xử lý yêu cầu":
        return "blue";
    }
  };

  const getDataSourceType = (value: string) => {
    switch (value) {
      case "NHAP_KHO_BH":
        return "Nhập kho bảo hành";
      case "DON_HANG":
        return "Đơn hàng";
      case "NHAP_KHO_BH_NCC":
        return "Nhập kho bảo hành NCC";
      case "Xử lý yêu cầu":
        return "Xử lý yêu cầu";
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate.length > 0) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate.length > 0) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.branchCode.length > 0) {
      url += `&BranchCode=${search.branchCode}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/VReportDelivery/get-list-testing${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const handleExportData = async () => {
    let url = `/api/v1/VReportDelivery/export-bao-cao-ki-thuat-kiem-tra?Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.startDate.length > 0) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate.length > 0) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.branchCode.length > 0) {
      url += `&BranchCode=${search.branchCode}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "bao_cao_tinh_luong_ky_thuat_kiem_tra" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const dataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataTblBranch(
      getData
        ?.filter(
          (item) => item.att2 != null && item.text != null && item.value != "-1"
        )
        ?.map((item) => ({
          value: item.att2,
          label: `${item.att2} - ${item.text}`,
        }))
    );
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (140 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <_breadcrumb></_breadcrumb>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() => handleExportData()}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>
            </Menu.Dropdown>
          </Menu>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Từ ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "startDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Đến ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Chi nhánh"
              searchable
              clearable
              data={dataTblBranch}
              onClick={() => {
                if (dataTblBranch.length === 0) {
                  dataBranchSelect();
                }
              }}
              leftSection={
                <IconBrandBootstrap size={20} style={{ color: teal.base }} />
              }
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "branchCode")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              defaultValue={keySearch}
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
export default PayrollReport;
