import {
  Box,
  Button,
  Collapse,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  Text,
  TextInput,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowBack, IconChevronUp } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
const DetailSellOrderInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [height, setHeight] = useState(0);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const data = location.state || {};
  const [opened, { toggle }] = useDisclosure(false);
  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const handleBackClick = () => {
    navigate("/sell/order-information");
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "stt",
        header: "STT",
      },
      {
        accessorKey: "maVt",
        header: "Mã vật tư",
      },
      {
        accessorKey: "tenVt",
        header: "Tên vật tư",
      },
      {
        accessorKey: "maKho",
        header: "Mã kho",
      },
      {
        accessorKey: "tenKho",
        header: "Tên kho",
      },
      {
        accessorKey: "maVitri",
        header: "Mã vị trí",
      },
      {
        accessorKey: "tenVitri",
        header: "Tên vị trí",
      },
      {
        accessorKey: "maCty",
        header: "Mã công ty",
      },
      {
        accessorKey: "sttPdn",
        header: "Số thứ tự phiếu đề nghị",
      },
      {
        accessorKey: "soPdn",
        header: "Số phiếu đề nghị",
      },
      {
        accessorKey: "maDv",
        header: "Mã đơn vị",
      },
      {
        accessorKey: "tenDv",
        header: "Tên đơn vị",
      },
      {
        accessorKey: "tkVt",
        header: "Tài khoản vật tư",
      },
      {
        accessorKey: "tkThue",
        header: "Tài khoản thuế",
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng",
      },
      {
        accessorKey: "soLuongQd",
        header: "Số lượng quy đổi",
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "giaNt0",
        header: "Giá gốc ban đầu(ngoại tệ)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "gia0",
        header: "Giá gốc chưa chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienNt0",
        header: "Tổng tiền gốc chưa chiết khấu(ngoại tệ)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tien0",
        header: "Tổng tiền gốc chưa chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tlCk",
        header: "Tỷ lệ chiết khấu",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}%</>,
      },
      {
        accessorKey: "tienCkDh",
        header: "Số tiền chiết khấu đơn hàng",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienCkNt",
        header: "Tiền chiết khấu(ngoại tệ)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienCk",
        header: "Tiền chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "giaNt",
        header: "Giá(ngoại tệ)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "gia",
        header: "Giá",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienNt",
        header: "Tổng tiền(ngoại tệ)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tien",
        header: "Tổng tiền",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tsGtgt",
        header: "Tỷ suất thuế GTGT",
      },
      {
        accessorKey: "thueGtgtNt",
        header: "Thuế GTGT(ngoại tệ)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "ttNt",
        header: "Tổng thanh toán(ngoại tệ)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tt",
        header: "Tổng thanh toán",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "maCtkm",
        header: "Mã chương trình khuyến mãi",
      },
      {
        accessorKey: "giaMuaLast",
        header: "Giá mua gần nhất",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "slBanLast",
        header: " Số lượng bán gần nhất",
      },
      {
        accessorKey: "dienGiaiCt",
        header: "Diễn giải chi tiết",
      },
      {
        accessorKey: "maLo",
        header: "Mã lô hàng",
      },
      {
        accessorKey: "maHd",
        header: "Mã hợp đồng",
      },
      {
        accessorKey: "maBp",
        header: "Mã bộ phận",
      },
      {
        accessorKey: "maPhi",
        header: "Mã phí",
      },
      {
        accessorKey: "maSpct",
        header: "Mã sản phẩm chi tiết",
      },
      {
        accessorKey: "slNhap",
        header: "Số lượng nhập",
      },
      {
        accessorKey: "slNhapQd",
        header: "Số lượng nhập quy đổi",
      },
      {
        accessorKey: "slHd",
        header: "Số lượng đã xuất hóa đơn",
      },
      {
        accessorKey: "slHdQd",
        header: "Số lượng xuất hóa đơn quy đổi",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data.data.listChiTietVatTu,
    enableColumnResizing: true,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["stt", "maVt"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => <></>,
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    getRowId: (row) => row.departmentId,
    enableStickyHeader: true,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "12px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    mantinePaginationProps: {
      style: { display: "none" },
      showRowsPerPage: false,
      withEdges: false,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  return (
    <Box component="form" mx="auto" style={{ position: "relative" }}>
      <LoadingOverlay zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      <Flex justify={"space-between"} align={"center"}>
        <_breadcrumb></_breadcrumb>
        <Flex w={"100%"} justify={"flex-end"} gap={10}>
          <Button
            type="button"
            color="red"
            leftSection={<IconArrowBack />}
            onClick={handleBackClick}
          >
            Quay lại
          </Button>
        </Flex>
      </Flex>
      <Grid>
        <Grid.Col span={12}>
          <Fieldset legend="Thông tin " h={"100%"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Số chứng từ"
                  placeholder="Số chứng từ"
                  variant="filled"
                  value={data.data.soCt}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <DateTimePicker
                  label="Ngày chứng từ"
                  valueFormat="DD-MM-YYYY hh:mm A"
                  placeholder="Ngày chứng từ"
                  value={data.data.ngayCt ? new Date(data.data.ngayCt) : null}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <DateTimePicker
                  label="Ngày lập chứng từ"
                  placeholder="Ngày lập chứng từ"
                  valueFormat="DD-MM-YYYY hh:mm A"
                  value={data.data.ngayLct ? new Date(data.data.ngayLct) : null}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Mã công ty"
                  placeholder="Mã công ty"
                  variant="filled"
                  value={data.data.maCty}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Mã chứng từ"
                  placeholder="Mã chứng từ"
                  variant="filled"
                  value={data.data.maCt}
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Mã khách hàng"
                  placeholder="Mã khách hàng"
                  variant="filled"
                  value={data.data.maKh}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Tên khách hàng"
                  placeholder="Tên khách hàng"
                  variant="filled"
                  value={data.data.tenKh}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Mã hình thức thanh toán"
                  placeholder="Mã hình thức thanh toán"
                  variant="filled"
                  value={data.data.maHttt}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Tên hình thức thanh toán"
                  placeholder="Tên hình thức thanh toán"
                  variant="filled"
                  value={data.data.tenHttt}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Hình thức thanh toán"
                  placeholder="Hình thức thanh toán"
                  variant="filled"
                  value={data.data.httt}
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Người giao dịch"
                  placeholder="Người giao dịch"
                  variant="filled"
                  value={data.data.nguoiGd}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Mã bộ phận"
                  placeholder="Mã bộ phận"
                  variant="filled"
                  value={data.data.maBp}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Tên bộ phận"
                  placeholder="Tên bộ phận"
                  variant="filled"
                  value={data.data.tenBp}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Diễn giải"
                  placeholder="Diễn giải"
                  variant="filled"
                  value={data.data.dienGiai}
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Mã thanh toán PO"
                  placeholder="Mã thanh toán PO"
                  variant="filled"
                  value={data.data.maTtPo}
                  readOnly
                />
              </Grid.Col>
            </Grid>
            {!opened && (
              <Text
                style={{
                  width: "80px",
                  marginTop: 15,
                  cursor: "pointer",
                  color: "#15aabf",
                  transition: "color 0.3s",
                }}
                onClick={toggle}
                onMouseEnter={(e) => (e.currentTarget.style.color = "darkblue")}
                onMouseLeave={(e) => (e.currentTarget.style.color = "blue")}
              >
                Xem Thêm...
              </Text>
            )}
            <Collapse in={opened}>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tên thanh toán PO"
                    placeholder="Tên thanh toán PO"
                    variant="filled"
                    value={data.data.tenTtPo}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Hạn chiết khấu"
                    placeholder="Hạn chiết khấu"
                    variant="filled"
                    value={data.data.hanCk}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tỷ lệ chiết khấu"
                    placeholder="Tỷ lệ chiết khấu"
                    variant="filled"
                    value={data.data.tlCk}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tài khoản chiết khấu"
                    placeholder="Tài khoản chiết khấu"
                    variant="filled"
                    value={data.data.tkCk}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Hạn thanh toán"
                    placeholder="Hạn thanh toán"
                    variant="filled"
                    value={
                      data.data.hanTt
                        ? formatCurrencyVND(data.data.hanTt)
                        : data.data.hanTt
                    }
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Ty gia"
                    placeholder="Ty gia"
                    variant="filled"
                    value={data.data.tyGia}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tổng tiền gốc"
                    placeholder="Tổng tiền gốc"
                    variant="filled"
                    value={
                      data.data.tTien0
                        ? formatCurrencyVND(data.data.tTien0)
                        : data.data.tTien0
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tổng tiền chiết khấu ngoại tệ"
                    placeholder="Tổng tiền chiết khấu ngoại tệ"
                    variant="filled"
                    value={
                      data.data.tTienCkNt
                        ? formatCurrencyVND(data.data.tTienCkNt)
                        : data.data.tTienCkNt
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tổng tiền chiết khấu"
                    placeholder="Tổng tiền chiết khấu"
                    variant="filled"
                    value={
                      data.data.tTienCk
                        ? formatCurrencyVND(data.data.tTienCk)
                        : data.data.tTienCk
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tổng thuế"
                    placeholder="Tổng thuế"
                    variant="filled"
                    value={
                      data.data.tThue
                        ? formatCurrencyVND(data.data.tThue)
                        : data.data.tThue
                    }
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tổng tiền chiết khấu đơn hàng"
                    placeholder="Tổng tiền chiết khấu đơn hàng"
                    variant="filled"
                    value={
                      data.data.tTienCkDh
                        ? formatCurrencyVND(data.data.tTienCkDh)
                        : data.data.tTienCkDh
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tổng giảm giá"
                    placeholder="Tổng giảm giá"
                    variant="filled"
                    value={
                      data.data.tGg
                        ? formatCurrencyVND(data.data.tGg)
                        : data.data.tGg
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tổng thanh toán"
                    placeholder="Tổng thanh toán"
                    variant="filled"
                    value={
                      data.data.tTt
                        ? formatCurrencyVND(data.data.tTt)
                        : data.data.tTt
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tổng số lượng"
                    placeholder="Tổng số lượng"
                    variant="filled"
                    value={data.data.tSoLuong}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tạo từ phiếu đề nghị"
                    placeholder="Tạo từ phiếu đề nghị"
                    variant="filled"
                    value={
                      data.data.taoTuPdn === false
                        ? "Không"
                        : data.data.taoTuPdn === true
                        ? "Có"
                        : data.data.taoTuPdn
                    }
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Sửa tiền"
                    placeholder="Sửa tiền"
                    variant="filled"
                    value={
                      data.data.suaTien === false
                        ? "Không"
                        : data.data.suaTien === true
                        ? "Có"
                        : data.data.suaTien
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Sửa trạng thái"
                    placeholder="Sửa trạng thái"
                    variant="filled"
                    value={
                      data.data.suaTt === false
                        ? "Không"
                        : data.data.suaTt === true
                        ? "Có"
                        : data.data.suaTt
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Trạng thái"
                    placeholder="Trạng thái"
                    variant="filled"
                    value={data.data.tenTrangThai}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Duyệt"
                    placeholder="Duyệt"
                    variant="filled"
                    value={
                      data.data.duyet === false
                        ? "Không"
                        : data.data.duyet === "True"
                        ? "Có"
                        : data.data.duyet
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Người duyệt"
                    placeholder="Người duyệt"
                    variant="filled"
                    value={data.data.nguoiDuyet}
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tên người duyệt"
                    placeholder="Tên người duyệt"
                    variant="filled"
                    value={data.data.tenNguoiDuyet}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <DateTimePicker
                    label="Ngày duyệt"
                    valueFormat="DD-MM-YYYY hh:mm A"
                    placeholder="Ngày duyệt"
                    value={
                      data.data.ngayDuyet ? new Date(data.data.ngayDuyet) : null
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Người hủy duyệt"
                    placeholder="Người hủy duyệt"
                    variant="filled"
                    value={data.data.nguoiHuyDuyet}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Tên người hủy duyệt"
                    placeholder="Tên người hủy duyệt"
                    variant="filled"
                    value={data.data.tenNguoiHuyDuyet}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <DateTimePicker
                    label="Ngày hủy duyệt"
                    valueFormat="DD-MM-YYYY hh:mm A"
                    placeholder="Ngày hủy duyệt"
                    value={
                      data.data.ngayHuyDuyet
                        ? new Date(data.data.ngayHuyDuyet)
                        : null
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
            {opened && (
              <Text
                style={{
                  width: "150px",
                  marginTop: 15,
                  cursor: "pointer",
                  color: "#15aabf",
                  transition: "color 0.3s",
                }}
                onClick={toggle}
                onMouseEnter={(e) => (e.currentTarget.style.color = "darkblue")}
                onMouseLeave={(e) => (e.currentTarget.style.color = "blue")}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  Thu gọn
                  <span style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <IconChevronUp size={12} />
                  </span>
                </span>
              </Text>
            )}
          </Fieldset>
        </Grid.Col>
        <Grid.Col span={12}>
          <Fieldset legend="Thông tin sản phẩm">
            <MantineReactTable table={table} />
          </Fieldset>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default DetailSellOrderInformation;
