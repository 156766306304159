import { Box, Flex, NumberFormatter, Table, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { repositoryPayment } from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { PaymentStatus } from "./orderStatus";

interface QueryParams {
  [key: string]: string;
}

const PaymentForm = () => {
  const navigate = useNavigate();
  const [notification, setNotification] = useState<string | null>();
  const [queryParams, setQueryParams] = useState<QueryParams>({});
  const [notificationAleart, setNotificationAleart] = useState<boolean>(false);
  const [dataPayment, setDataPayment] = useState({
    status: "",
    vnpAmount: "",
    vnpBankcode: "",
    vnpCardtype: "",
    vnpOrderinfo: "",
    vnpPayDate: "",
    vnpResponsecode: "",
    vnpTmncode: "",
    vnpBanktranno: "",
    vnpTxnref: "",
    vnpSecureHashType: "",
    vnpSecureHash: "",
  });

  const [paymentStatus, setPaymentStatus] = useState<string>("unknown");

  const toQueryString = (params: Record<string, string>): string => {
    return (
      "?" +
      Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&")
    );
  };

  const fetchData = async (queryString: string) => {
    try {
      const response = await repositoryPayment.get(
        `api/VNPAY/payment-update${queryString}`
      );
      if (response && response.success) {
        NotificationExtension.Success("Thanh toán thành công!");
        setNotificationAleart(true);
        setDataPayment(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const entries = params.entries();
    const queryParamsObj: QueryParams = {};
    for (let [key, value] of entries) {
      queryParamsObj[key] = value;
    }
    setQueryParams(queryParamsObj);
    const queryString = toQueryString(queryParamsObj);
    fetchData(queryString);
  }, []);

  useEffect(() => {
    if (dataPayment.status === "DONE") {
      setPaymentStatus("DONE");
    } else {
      setPaymentStatus("unknown");
    }
  }, [dataPayment]);

  return (
    <Box mx="auto" style={{ maxWidth: 500 }}>
      <Title order={2} style={{ textAlign: "center" }} mb="lg">
        Thông tin thanh toán
      </Title>
      <Table highlightOnHover withTableBorder withColumnBorders striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Thông tin</Table.Th>
            <Table.Th>Chi tiết</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td fw="bold">Trạng thái:</Table.Td>
            <Table.Td>
              <Flex>
                <PaymentStatus status={paymentStatus} />
              </Flex>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td fw="bold">Số tiền đã thanh toán:</Table.Td>
            <Table.Td>
              <NumberFormatter
                suffix=" ₫"
                thousandSeparator=","
                value={dataPayment.vnpAmount}
              ></NumberFormatter>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td fw="bold">Mã ngân hàng:</Table.Td>
            <Table.Td>{dataPayment.vnpBankcode}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td fw="bold">Số giao dịch ngân hàng:</Table.Td>
            <Table.Td>{dataPayment.vnpBanktranno}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td fw="bold">Thông tin đơn hàng:</Table.Td>
            <Table.Td>{dataPayment.vnpOrderinfo}</Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
      {/* {notificationAleart && (
        <Flex align="end" justify="flex-end">
          <Button
            mt={15}
            variant="filled"
            size="xs"
            color="indigo"
            onClick={() => comeBackOrder()}
          >
            Trở về trang thanh toán
          </Button>
        </Flex>
      )} */}
    </Box>
  );
};

export default PaymentForm;
