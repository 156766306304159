import { Flex, Image } from "@mantine/core";
import { IconMail, IconPhone, IconWorldWww } from "@tabler/icons-react";
import styles from "./PrintReceipts.module.css";
import { DetailsOrderHeader } from "../../../PaymentReceipt/model/modelPaymentReceipt";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { SelectListItemBase } from "../../../../../_base/model/_base/SelectListItemBase";
import { MessageResponse } from "../../../../../model/MessageResponse";
import { useEffect, useState } from "react";
// Interface cho DebtData
interface DebtData {
  branchesFund?: string | undefined;
  codeForFees?: string | undefined;
  contract?: string | undefined;
  debtObjectCode?: string | undefined;
  debtObjectName?: string | undefined;
  department?: string | undefined;
  departmentFund?: string | undefined;
  description?: string | undefined;
  employeeFund?: string | undefined;
  fee?: number | undefined;
  id?: string | undefined;
  incurDebt?: number | undefined;
  occurringCredit?: number | undefined;
  originalDocumentNumber?: string | undefined;
  originalDocuments?: string | undefined;
}

type Props = {
  componentRef: any;
  currentDate: any;
  dataPrint: any;
};

const PrintReceipts = ({ componentRef, currentDate, dataPrint }: Props) => {
  let dataTransaction = {} as any;
  const [dataBranch, setDataBranch] = useState<SelectListItemBase[]>([]);
  let userInfo = {} as any;

  // hàm chuyển đổi số thành chữ
  const numberToWords = (num: number): string => {
    if (num === 0) return "Không đồng";

    const units = ["", "nghìn", "triệu", "tỷ"];
    const numbers = [
      "không",
      "một",
      "hai",
      "ba",
      "bốn",
      "năm",
      "sáu",
      "bảy",
      "tám",
      "chín",
    ];

    const convertBlock = (block: number): string => {
      const hundred = Math.floor(block / 100);
      const ten = Math.floor((block % 100) / 10);
      const unit = block % 10;

      let result = "";
      if (hundred) result += `${numbers[hundred]} trăm `;
      if (ten) result += `${ten === 1 ? "mười" : numbers[ten] + " mươi"} `;
      if (unit) result += ten === 1 && unit === 5 ? "lăm" : numbers[unit];

      return result.trim();
    };

    const parts: string[] = [];
    let index = 0;

    while (num > 0) {
      const block = num % 1000;
      if (block) {
        parts.unshift(`${convertBlock(block)} ${units[index]}`);
      }
      num = Math.floor(num / 1000);
      index++;
    }

    // Chỉnh chữ cái đầu viết hoa
    const result = parts.join(" ") + " đồng";
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  const getCityFromAddress = (address: string) => {
    if (!address) return "";
    const parts = address.split(/ - |, /);
    return parts[parts.length - 1];
  };
  //tiền bằng chữ
  //
  const formatCurrency = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };

  const fetchDataBranch = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmInventory/get-select-branch?auth=false");

    if (response && response.success) {
      let result = response.data;
      setDataBranch(result);
    }
  };

  useEffect(() => {
    fetchDataBranch();
  }, []);
  return (
    <div
      ref={componentRef}
      className={styles.containerPrint}
      style={{
        height: "100%",
        margin: "0 auto",
        marginLeft: "40px",
        marginRight: "30px",
        // marginTop: "10px",
        fontFamily: `Times New Roman, Times, serif`,
      }}
    >
      <div
        className={styles.contentMain}
        style={{
          // paddingTop: "10px",
          width: "100%",
          position: "relative",
          margin: "0 auto",
          marginRight: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1.5fr 2fr",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h6 style={{ margin: 0, fontSize: "10px", fontWeight: 600 }}>
              Công Ty Cổ Phần Đầu Tư Công Nghệ HACOM
            </h6>
            <p style={{ margin: 0, fontSize: "9px" }}>
              {/* CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà Trưng, TP. Hà Nội */}
              CN: {dataPrint?.creatorworkplace}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                alignItems: "top",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "3px",
                }}
              >
                <IconPhone
                  stroke={2}
                  style={{ width: "10px", paddingBottom: "13px" }}
                />
                <p
                  style={{ fontSize: "10px", margin: 0 }}
                  className={styles.text}
                >
                  19001903{" |"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3px",
                  margin: 0,
                }}
                className={styles.text}
              >
                <IconMail
                  stroke={2}
                  style={{ width: "10px", paddingBottom: "13px" }}
                />
                <p style={{ margin: 0, fontSize: "10px" }}>
                  dichvukhachhang@hacom.vn{" |"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3px",
                  margin: 0,
                }}
                className={styles.text}
              >
                <IconWorldWww
                  stroke={2}
                  style={{ width: "10px", paddingBottom: "13px" }}
                />
                <p style={{ margin: 0, fontSize: "10px" }}>Hacom.vn</p>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "10px" }}>
            <h1
              className={styles.text}
              style={{
                margin: 0,
                fontWeight: "700",
                lineHeight: "1",
                fontSize: "18px",
                textAlign: "center",
                alignItems: "center",
                width: "110%",
              }}
            >
              {dataPrint?.typeReceipt === 1 &&
                dataPrint?.type === 1 &&
                "PHIẾU THU ĐƠN HÀNG"}
              {dataPrint?.typeReceipt === 1 &&
                dataPrint?.type === 5 &&
                "PHIẾU THU KHÁC"}
              {dataPrint?.typeReceipt === 2 && "PHIẾU CHI"}
              {dataPrint?.typeReceipt === 3 && "PHIẾU ĐIỀU CHỈNH"}
            </h1>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "14px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <p style={{ marginRight: "2px", marginTop: "-2px" }}>
              Mã số: {dataPrint?.receiptNumber}
            </p>{" "}
            <p></p>
          </div>
        </div>
        {/* bảng thông tin bên xuất, bên nhận */}
        <div
          className={styles.customerInformation}
          style={{
            width: "100%",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid black",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={2}
                  style={{ width: "50%", border: "1px solid black" }}
                >
                  {dataPrint?.typeReceipt === 1
                    ? "NGƯỜI LẬP PHIẾU THU"
                    : dataPrint?.typeReceipt === 2
                    ? "NGƯỜI LẬP PHIẾU CHI"
                    : ""}
                </th>
                <th
                  colSpan={2}
                  style={{ width: "50%", border: "1px solid black" }}
                >
                  {dataPrint?.typeReceipt === 1
                    ? "NGƯỜI NỘP TIỀN"
                    : dataPrint?.typeReceipt === 2
                    ? "NGƯỜI NHẬN TIỀN"
                    : "NGƯỜI NHẬN TIỀN"}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  Phòng ban
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {dataPrint?.creatorbranch}
                </td>

                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {/* {dataPrint?.typeReceipt === 1
                    ? "Mã đối tượng công nợ"
                    : dataPrint?.typeReceipt === 2
                    ? "Mã nhóm công nợ"
                    : ""} */}
                  {dataPrint?.typeReceipt === 1
                    ? dataPrint?.type === 1
                      ? "Họ & Tên"
                      : "Mã đối tượng công nợ"
                    : dataPrint?.typeReceipt === 2
                    ? "Mã nhóm công nợ"
                    : ""}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {dataPrint?.typeReceipt === 1 && dataPrint?.type === 1
                    ? dataPrint?.anothername || dataPrint?.funfullname
                    : dataPrint?.attribute2}
                  {/* {dataPrint?.attribute2} */}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  Địa điểm làm việc
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {dataPrint?.creatorworkplace}
                </td>

                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {/* Mã Nhà Cung Cấp */}
                  {dataPrint?.typeReceipt === 1
                    ? dataPrint?.type === 1
                      ? "Mã số khách hàng"
                      : "Mã Nhà Cung Cấp"
                    : dataPrint?.typeReceipt === 2
                    ? "Mã Nhà Cung Cấp "
                    : ""}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {/* {dataPrint?.codecustomer} */}
                  {dataPrint?.typeReceipt === 1 && dataPrint?.type === 1
                    ? ""
                    : dataPrint?.codecustomer}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  Bộ phận
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {dataPrint?.creatordepartment}
                </td>

                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {/* Tên */}
                  {dataPrint?.typeReceipt === 1
                    ? dataPrint?.type === 1
                      ? "Số điện thoại"
                      : "Tên"
                    : dataPrint?.typeReceipt === 2
                    ? "Tên"
                    : ""}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {/* {dataPrint?.anothername} */}
                  {dataPrint?.typeReceipt === 1 && dataPrint?.type === 1
                    ? dataPrint?.phonenumber
                    : dataPrint?.anothername || dataPrint?.funfullname}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  Chức danh
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {dataPrint?.createrolename}
                </td>

                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {/* Số điện thoại */}
                  {dataPrint?.typeReceipt === 1
                    ? dataPrint?.type === 1
                      ? ""
                      : "Số điện thoại"
                    : dataPrint?.typeReceipt === 2
                    ? "Số điện thoại"
                    : ""}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {/* {dataPrint?.phonenumber} */}
                  {dataPrint?.typeReceipt === 1 && dataPrint?.type === 1
                    ? ""
                    : dataPrint?.phonenumber}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  Họ & tên
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {dataPrint?.creatorfullname}
                </td>

                <td
                  style={{
                    width: "16%",
                    maxWidth: "30%",
                    whiteSpace: "nowrap",
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {""}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    paddingLeft: "4px",
                  }}
                >
                  {""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Nội dung */}
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "baseline",
            gap: "5px",
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            Nội dung:
          </p>
          <p
            style={{
              flex: 1,
              borderBottom: "1px dotted black",
              margin: 0,
            }}
          >
            {dataPrint?.payerdescription ?? ""}
          </p>
        </div>

        {/* table sản phẩm */}
        {/* Nếu nó là phiếu thu ĐƠN HÀNG thì sẽ vào bảng 1 còn còn sẽ vào bảng 2 */}
        {dataPrint?.typeReceipt === 1 && dataPrint?.type === 1 ? (
          <>
            <div
              className={styles.ProductMain}
              style={{ marginTop: "10px", width: "100%" }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                      rowSpan={2}
                    >
                      STT
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                      rowSpan={2}
                    >
                      Số đơn hàng
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                      colSpan={2}
                    >
                      Đối tượng công nợ
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                      rowSpan={2}
                    >
                      Tổng tiền hóa đơn
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                      rowSpan={2}
                    >
                      Đã thanh toán
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                      rowSpan={2}
                    >
                      Số tiền còn phải TT
                    </th>
                    <th
                      style={{
                        minWidth: "90px",
                        maxWidth: "90px",
                        border: "1px solid black",
                      }}
                      rowSpan={2}
                    >
                      Số tiền nộp
                    </th>

                    <th
                      style={{
                        minWidth: "80px",
                        maxWidth: "80px",
                        border: "1px solid black",
                      }}
                      rowSpan={2}
                    >
                      Diễn giải
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        minWidth: "60px",
                        maxWidth: "60px",
                        border: "1px solid black",
                      }}
                    >
                      Mã
                    </th>
                    <th
                      style={{
                        minWidth: "60px",
                        maxWidth: "60px",
                        border: "1px solid black",
                      }}
                    >
                      Tên
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      border: "none",
                    }}
                  >
                    <td
                      style={{
                        padding: "0 2px",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "0 2px",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "0 2px",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "0 2px",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "0 2px",
                        textAlign: "right",
                      }}
                    >
                      {formatCurrency(
                        Number(
                          dataPrint?.detailHeader?.reduce(
                            (total: any, item: DetailsOrderHeader) =>
                              total + (item.amountTotal || 0),
                            0
                          )
                        )
                      )}
                    </td>
                    <td
                      style={{
                        padding: "0 2px",
                        textAlign: "right",
                      }}
                    >
                      {formatCurrency(
                        Number(
                          dataPrint?.detailHeader?.reduce(
                            (total: any, item: DetailsOrderHeader) =>
                              total + (item.amountPaid || 0),
                            0
                          )
                        )
                      )}
                    </td>
                    <td
                      style={{
                        padding: "0 2px",
                        textAlign: "right",
                      }}
                    >
                      {formatCurrency(
                        Number(
                          dataPrint?.detailHeader?.reduce(
                            (total: any, item: DetailsOrderHeader) =>
                              total + (item.amountRemaining || 0),
                            0
                          )
                        )
                      )}
                    </td>
                    <td
                      style={{
                        padding: "0 2px",
                        textAlign: "right",
                      }}
                    >
                      {formatCurrency(
                        Number(
                          dataPrint?.detailHeader?.reduce(
                            (total: any, item: DetailsOrderHeader) =>
                              total + (item.allocatedAmount || 0),
                            0
                          )
                        )
                      )}
                    </td>
                    <td
                      style={{
                        padding: "0 2px",
                        textAlign: "right",
                      }}
                    ></td>
                  </tr>
                  {dataPrint?.detailHeader?.map(
                    (item: DetailsOrderHeader, index: number) => (
                      <tr key={index}>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "0 2px",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "0 2px",
                          }}
                        >
                          {item.orderNumber}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "0 2px",
                          }}
                        >
                          {item.attribute7}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "0 2px",
                          }}
                        >
                          {item.attribute2}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "0 2px",
                            textAlign: "right",
                            // display: "flex",
                            // justifyContent: "end",
                          }}
                        >
                          {formatCurrency(Number(item.amountTotal ?? 0))}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "0 2px",
                            textAlign: "right",
                          }}
                        >
                          {formatCurrency(Number(item.amountPaid ?? 0))}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "0 2px",
                            textAlign: "right",
                          }}
                        >
                          {formatCurrency(Number(item.amountRemaining ?? 0))}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "0 2px",
                            textAlign: "right",
                          }}
                        >
                          {formatCurrency(Number(item.allocatedAmount ?? 0))}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "0 2px",
                          }}
                        >
                          {item.note ?? ""}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div
              className={styles.ProductMain}
              style={{ marginTop: "10px", width: "100%" }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                      colSpan={2}
                    >
                      Chứng từ gốc
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                      rowSpan={2}
                    >
                      Mã phí
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        minWidth: "90px",
                        maxWidth: "90px",
                        border: "1px solid black",
                      }}
                    >
                      Phát sinh nợ
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                      }}
                      colSpan={2}
                    >
                      Đối tượng công nợ
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        minWidth: "80px",
                        maxWidth: "80px",
                        border: "1px solid black",
                      }}
                    >
                      Nội dung
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        minWidth: "80px",
                        maxWidth: "80px",
                        border: "1px solid black",
                      }}
                    >
                      Chi nhánh
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        minWidth: "70px",
                        maxWidth: "70px",
                        border: "1px solid black",
                      }}
                    >
                      Tên
                    </th>
                    <th
                      style={{
                        minWidth: "70px",
                        maxWidth: "70px",
                        border: "1px solid black",
                      }}
                    >
                      Số
                    </th>
                    <th
                      style={{
                        minWidth: "60px",
                        maxWidth: "60px",
                        border: "1px solid black",
                      }}
                    >
                      Mã
                    </th>
                    <th
                      style={{
                        minWidth: "60px",
                        maxWidth: "60px",
                        border: "1px solid black",
                      }}
                    >
                      Tên
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataPrint?.debtData?.map((item: DebtData, index: number) => (
                    <tr key={index}>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "0 2px",
                        }}
                      >
                        {item?.originalDocuments}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                          padding: "0 2px",
                        }}
                      >
                        {item?.originalDocumentNumber}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "0 2px",
                        }}
                      >
                        {item?.codeForFees}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          border: "1px solid black",
                          padding: "0 2px",
                        }}
                      >
                        {formatCurrency(Number(item?.occurringCredit))}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "0 2px",
                        }}
                      >
                        {item.debtObjectCode}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "0 2px",
                        }}
                      >
                        {item?.debtObjectName}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "0 2px",
                        }}
                      >
                        {item?.description}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "0 2px",
                        }}
                      >
                        {dataBranch.find(
                          (e) => String(e.att2) === String(item?.branchesFund)
                        )?.text ?? ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {/* Tổng tiền*/}
        <div style={{ margin: "5px 0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p style={{ margin: 0, marginRight: "3px" }}>Tổng tiền:</p>
            <p style={{ margin: 0, fontWeight: 700, marginRight: "3px" }}>
              {formatCurrency(Number(dataPrint?.totalAmount))} VND
            </p>
            <p
              style={{
                margin: 0,
                fontStyle: "italic",
              }}
            >
              ( Bằng chữ: {numberToWords(Number(dataPrint?.totalAmount))})
            </p>
          </div>
        </div>
        {/* Ngày giờ */}
        <div style={{ marginTop: "5px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "14px",
            }}
          >
            <div>
              {getCityFromAddress(dataPrint?.creatorworkplace)} ngày{" "}
              <strong style={{ fontStyle: "italic" }}>{currentDate}</strong>{" "}
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p style={{ fontWeight: "700" }}>Tổng Giám Đốc</p>
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {userInfo?.fullName}
                </p>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p style={{ fontWeight: "700" }}>Kế Toán Trưởng</p>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p style={{ fontWeight: "700" }}>
                {dataPrint?.typeReceipt === 1
                  ? "Người Nộp Tiền"
                  : "Người Nhận Tiền"}
              </p>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p style={{ fontWeight: "700" }}>Người Lập Phiếu</p>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p style={{ fontWeight: "700" }}>Thủ Quỹ</p>
            </div>
          </div>
        </div>
        <div
          className={styles.backgroudHacom}
          style={{
            position: "absolute",
            width: "100%",
            zIndex: -1,
            opacity: 0.1,
            top: "40%",
          }}
        >
          <Image
            radius="md"
            src="https://hanoicomputercdn.com/media/lib/17-10-2024/logo-hacom-since-2001_new.png"
            className={styles.logo}
            style={{
              width: "90%",
              margin: "0 auto",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PrintReceipts;
