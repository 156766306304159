import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  Radio,
  Select,
  TagsInput,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";

import { DateInput } from "@mantine/dates";
import { repositoryPos } from "../../_base/_const/_constVar";
import { sky_blue } from "../../const/variables";
import { EmployeeModel } from "../../model/Employee";
import { MessageResponse } from "../../model/MessageResponse";
import {
  getBranchSelect,
  getTblDepartmentSelect,
  getTblDmEmployeeSelect,
  getTblRoleSelect,
} from "../../service/getSelectApi";

const EmployeeCreate = function ({ onLoad }: { onLoad: () => Promise<void> }) {
  const entity: EmployeeModel = {
    id: 0,
    fullname: "",
    code: "",
    dateOfBirth: "",
    gender: 0,
    departmentId: 0,
    roleId: 0,
    departmentName: "",
    roleName: "",
    address: "",
    phoneNumber: "",
    active: false,
    email: "",
    description: null,
    accountingCenterId: 0,
    managerId: 0,
    isCrm: false,
    currentCardId: null,
    partCode: null,
    createDate: new Date(),
    createBy: 0,
    lastUpdateDate: new Date(),
    lastUpdateBy: "",
    listBranch: null,
    isAccountant: null,
    isInventory: null,
    attribute1: null,
    attribute2: null,
  };
  const form = useForm<EmployeeModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    //   validate: _validateUserMasterModelValidator,
  });

  // console.log(form.getValues());

  const [visible, { close, open }] = useDisclosure(false);

  const [dataBranchdSelect, setDataBranchdSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataTblDepartmentSelect, setDataTblDepartmentSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblRoleSelect, setDataTblRoleSelect] = useState<ComboboxItem[]>(
    []
  );

  const [dataEmployeeSelect, setDataDmEmployeeSelect] = useState<
    ComboboxItem[]
  >([]);

  const [dataAllDepartment, setDataAllDepartment] = useState([]);

  const callApiGetData = async () => {
    open();
    let urlCreate = `/api/v1/TblDmEmployee/create`;
    const callapi = await repositoryPos.get<MessageResponse<EmployeeModel>>(
      urlCreate
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  const apiCreate = async (data: EmployeeModel) => {
    try {
      const selectedBranches = [];
      if (valueInventory.length === 1 && valueInventory[0] === "Tất cả") {
        selectedBranches.push(
          ...dataBranchdSelect
            .filter((branch) => branch.value?.toString() !== "-1")
            .map((branch) => branch.value?.toString())
        );
      } else {
        valueInventory.forEach((inventoryItem) => {
          const matchingBranch = dataBranchdSelect.find(
            (branch) => branch.label === inventoryItem
          );
          if (matchingBranch) {
            selectedBranches.push(matchingBranch.value?.toString());
          }
        });
      }

      data.listBranch = selectedBranches.join(",");

      const urlCreate = `/api/v1/TblDmEmployee/create`;
      const response = await repositoryPos.post(urlCreate, {
        ...data,
        active: true,
      });

      if (response?.success) {
        await onLoad();
        NotificationExtension.Success("Thao tác thành công !");
        modals.closeAll();
      } else {
        NotificationExtension.Fails("Thao tác thất bại !");
      }
    } catch (error) {
      console.error("Error during API create:", error);
      NotificationExtension.Fails("Đã xảy ra lỗi, vui lòng thử lại sau.");
    } finally {
      close();
    }
  };

  const dataDepartmentSelect = async () => {
    // const get = await getTblDepartmentSelect();
    // console.log("getTblDepartmentSelect", get);
    // setDataTblDepartmentSelect(
    //   get
    //     .filter((item) => item.value != null && item.text != null)
    //     .map((item) => ({
    //       value: item.value,
    //       label: item.text,
    //     }))
    // );
    try {
      const response = await repositoryPos.get("/api/v1/TblDepartment/get-all");
      if (response && response.success) {
        setDataAllDepartment(response.data);
        const result = response.data
          .filter(
            (item: any) => item?.name !== null && item?.departmentId !== null
          )
          .map((i: any) => ({
            value: i?.departmentId?.toString(),
            label: i?.deparmentCode + " - " + i?.name,
          }));
        setDataTblDepartmentSelect(result);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const dataRoleSelect = async () => {
    const get = await getTblRoleSelect();
    setDataTblRoleSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataBranchSelect = async () => {
    const get = await getBranchSelect();
    setDataBranchdSelect(
      get
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };
  const dataDmEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataDmEmployeeSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value?.toString(),
          label: `${item.att1?.toString()} - ${item.text?.toString()}`,
        }))
    );
  };
  useEffect(() => {
    var br = form.getValues().listBranch;
    if (br) {
      const valuesArray = br.split(",").length > 0 ? br.split(",") : [br];
      const labels = valuesArray
        .map(
          (value) =>
            dataBranchdSelect.find((item) => item.value === value)?.label
        ) // Tìm `label` dựa trên `value`
        .filter((label): label is string => label !== undefined); // Loại bỏ `undefined`
      setValueInventory(labels);
      // if (dataApi.listBranch.split(",").length > 0) {
      //   dataApi.listBranch.split(",");
      // } else setValueInventory([dataApi.listBranch]);
    }
  }, [dataBranchdSelect, form.getValues().listBranch]);
  useEffect(() => {
    Promise.all([callApiGetData(), dataBranchSelect()]);
  }, []);

  const [valueInventory, setValueInventory] = useState<string[]>([]);

  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit(async (e: EmployeeModel) => {
          await apiCreate(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Box className="flex-none" component="form" mx="auto">
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                key={form.key("code")}
                label="Mã nhân viên: "
                withAsterisk
                placeholder="Nhập mã nhân viên..."
                {...form.getInputProps("code")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                key={form.key("fullname")}
                label="Tên đầy đủ: "
                placeholder="Nhập họ tên..."
                withAsterisk
                {...form.getInputProps("fullname")}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <DateInput
                locale="vi"
                key={form.key("dateOfBirth")}
                label="Ngày sinh: "
                placeholder="Nhập ngày sinh..."
                valueFormat="DD-MM-YYYY"
                withAsterisk
                dateParser={(input) => {
                  const [day, month, year] = input.split("-").map(Number);
                  return new Date(year, month - 1, day);
                }}
                {...form.getInputProps("dateOfBirth")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <Radio.Group
                key={form.key("gender")}
                name="favoriteFramework"
                label="Giới tính: "
                withAsterisk
                value={form.getValues()?.gender?.toString()}
                {...form.getInputProps("gender")}
              >
                <Group mt="xs">
                  <Radio value="0" label="Nam" />
                  <Radio value="1" label="Nữ" />
                  <Radio value="2" label="Không xác định" />
                </Group>
              </Radio.Group>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <Select
                id="departmentId"
                key={form.key("departmentId")}
                label="Phòng ban: "
                placeholder="Nhập..."
                data={dataTblDepartmentSelect}
                value={form.getValues().departmentId?.toString()}
                clearable
                searchable
                {...form.getInputProps("departmentId")}
                onClick={() => {
                  if (dataTblDepartmentSelect.length === 0) {
                    dataDepartmentSelect();
                  }
                }}
                onChange={(e) => {
                  form.setValues((prev) => ({
                    ...prev,
                    departmentId: Number.parseInt(e ?? "0"),
                  }));
                  const dataInfo = dataAllDepartment?.filter(
                    (item: any) => item?.departmentId == e
                  );
                  const result = dataBranchdSelect?.filter(
                    (item: any) => item?.value == (dataInfo[0] as any)?.branchId
                  );
                  if (result.length > 0 && result[0]?.label) {
                    setValueInventory([result[0].label]);
                  } else {
                    setValueInventory([]); // Giá trị mặc định
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <Select
                id="roleId"
                key={form.key("roleId")}
                label="Chức danh: "
                placeholder="Nhập..."
                nothingFoundMessage="Không có dữ liệu"
                data={dataTblRoleSelect}
                value={form.getValues().roleId?.toString()}
                searchable
                clearable
                {...form.getInputProps("roleId")}
                onClick={() => {
                  if (dataTblRoleSelect.length === 0) {
                    dataRoleSelect();
                  }
                }}
                onChange={(e) => {
                  form.setValues((prev) => ({
                    ...prev,
                    roleId: Number.parseInt(e ?? "0"),
                  }));
                }}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 4 }}>
              <TextInput
                key={form.key("phoneNumber")}
                label="Điện thoại: "
                placeholder="Nhập..."
                {...form.getInputProps("phoneNumber")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 4 }}>
              {" "}
              <TextInput
                key={form.key("email")}
                label="Email:  "
                placeholder="Nhập..."
                {...form.getInputProps("email")}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, xs: 4 }}>
              <Select
                id="managerId"
                key={form.key("managerId")}
                label="QL trực tiếp: "
                placeholder="Nhập..."
                nothingFoundMessage="Không có dữ liệu"
                data={dataEmployeeSelect}
                value={form.getValues().managerId?.toString()}
                searchable
                clearable
                {...form.getInputProps("managerId")}
                onClick={() => {
                  if (dataEmployeeSelect.length === 0) {
                    dataDmEmployeeSelect();
                  }
                }}
                onChange={(e) => {
                  form.setValues((prev) => ({
                    ...prev,
                    managerId: Number.parseInt(e ?? "0"),
                  }));
                }}
              />
            </Grid.Col>
          </Grid>
          <TagsInput
            label="Chọn chi nhánh làm việc: "
            placeholder="Nhập..."
            data={dataBranchdSelect}
            style={{ maxHeight: "250px", overflow: "auto" }}
            value={valueInventory}
            onClick={() => {
              if (dataBranchdSelect.length === 0) {
                dataBranchSelect();
              }
            }}
            onChange={(e) => {
              if (e.includes("-1")) {
                setValueInventory(["-1"]);
              } else {
                setValueInventory(e);
              }
            }}
          />

          <Grid>
            <Grid.Col span={{ base: 12, xs: 12 }}>
              {" "}
              <Textarea
                key={form.key("address")}
                label="Địa chỉ: "
                placeholder="Nhập..."
                {...form.getInputProps("address")}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Checkbox
                key={form.key("isAccountant")}
                label="Kế toán"
                defaultValue={form.getValues().isAccountant ?? false}
                defaultChecked={form.getValues().isAccountant ?? false}
                {...form.getInputProps("isAccountant")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Checkbox
                key={form.key("isInventory")}
                label="Thủ kho"
                defaultValue={form.getValues().isInventory ?? false}
                defaultChecked={form.getValues().isInventory ?? false}
                {...form.getInputProps("isInventory")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Checkbox
                label="Giao vận"
                onClick={() =>
                  form.setValues((prev) => ({
                    ...prev,
                    attribute1:
                      prev.attribute1 === "false" || prev.attribute1 === null
                        ? "true"
                        : "false",
                  }))
                }
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Checkbox
                label="Kỹ thuật bảo hành"
                onClick={() =>
                  form.setValues((prev) => ({
                    ...prev,
                    attribute2:
                      prev.attribute2 === "false" || prev.attribute2 === null
                        ? "true"
                        : "false",
                  }))
                }
              />
            </Grid.Col>
          </Grid>

          <Checkbox
            mt={"xs"}
            key={form.key("active")}
            label="Trạng thái"
            defaultValue={form.getValues().active ?? true}
            defaultChecked={form.getValues().active ?? true}
            {...form.getInputProps("active")}
          />
        </Box>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default EmployeeCreate;
