import {
  Badge,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Menu,
  rem,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryDelivery,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { TblDMExpense } from "../../../model/TblDMExpense";
import {
  IconBrandBootstrap,
  IconBuildingSkyscraper,
  IconBuildingWarehouse,
  IconCalendar,
  IconCaretDown,
  IconClipboardData,
  IconFileExport,
  IconFileUpload,
  IconSearch,
  IconTagStarred,
} from "@tabler/icons-react";
import { useDebouncedState } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { green } from "../../../const/variables";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { DatePickerInput } from "@mantine/dates";
import { getBranchSelectMdm } from "../../../service/getSelectApi";
import { MessageResponse } from "../../../model/MessageResponse";
import { BASE_API_DELIVERY } from "../../../config";

const ExportOrReExport = () => {
  //data and fetching state
  const navigate = useNavigate();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMExpense[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //select
  const [trangThaiDhSelect] = useState<ComboboxItem[]>([
    { label: "Đơn hàng hoàn thành", value: "DON_HANG_HOAN_THANH" },
    { label: "Kho xuất hàng", value: "KHO_XUAT_HANG" },
    { label: "Hủy phân công KTKT", value: "HUY_PHAN_CONG_KTKT" },
    { label: "Thành công", value: "THANH_CONG" },
    { label: "Test lỗi", value: "TEST_LOI" },
    { label: "Test OK", value: "TEST_OK" },
    { label: "Đã phân công giao vận", value: "DA_PHAN_CONG_GV" },
    { label: "KTKT đang kiểm tra", value: "KTKT_DANG_KIEM_TRA" },
    { label: "Giao vận đến nơi", value: "GV_DEN_NOI" },
    { label: "Đã phân công KTKT", value: "DA_PHAN_CONG_KTKT" },
  ]);
  const [loaiGdKho] = useState<ComboboxItem[]>([
    { label: "Nhập đổi trả", value: "NHAP_DOI_TRA" },
    { label: "Xuất bán", value: "XUAT_BAN" },
    { label: "Xuất đổi trả", value: "XUAT_DOI_TRA" },
  ]);
  const [warrantyTerm] = useState<ComboboxItem[]>([
    { label: "0 tháng", value: "0" },
    { label: "3 tháng", value: "3" },
    { label: "6 tháng", value: "6" },
    { label: "12 tháng", value: "12" },
    { label: "18 tháng", value: "18" },
    { label: "36 tháng", value: "36" },
    { label: "60 tháng", value: "60" },
  ]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataVendorSelect, setDataVendorSelect] = useState<ComboboxItem[]>([]);

  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    idCn: "",
    trangThaiDh: "",
    loaiGdKho: "",
    idNcc: "",
    fromWarrantyDue: "0",
    toWarrantyDue: "60",
  });

  const [keySearch, setKeySearch] = useDebouncedState<string>("", 300);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.index === -1 ? "" : row.index + 1}
          </Text>
        ),
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        enableResizing: false,
      },
      {
        accessorKey: "tenCn",
        header: "Chi nhánh",
      },
      {
        accessorKey: "maSp",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }: any) =>
          renderedCellValue === null ? null : (
            <Badge variant="light">{renderedCellValue}</Badge>
          ),
      },
      {
        accessorKey: "serial",
        header: "Serial",
      },
      {
        accessorKey: "tenSp",
        header: "Tên sản phẩm",
        size: 425,
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị tính",
        size: 150,
      },
      {
        accessorKey: "thoiGianBh",
        header: "Thời hạn bảo hành",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue} tháng
          </Text>
        ),
        size: 200,
      },
      {
        accessorKey: "tenNcc",
        header: "NCC sản phẩm",
        size: 400,
      },
      {
        accessorKey: "ctDonHang",
        header: "Chứng từ đơn hàng",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue}
          </Badge>
        ),
        size: 200,
      },
      {
        accessorKey: "trangThaiDh",
        header: "Trạng thái đơn hàng",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              renderedCellValue?.toString() ?? "",
              trangThaiDhSelect,
              "label"
            )}
          </Text>
        ),
        size: 200,
      },
      {
        accessorKey: "ngayGd",
        header: "Ngày giao dịch",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "ctKho",
        header: "Chứng từ kho",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "loaiGdKho",
        header: "Loại giao dịch kho",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              renderedCellValue?.toString() ?? "",
              loaiGdKho,
              "label"
            )}
          </Text>
        ),
        size: 200,
      },
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
        Cell: ({ renderedCellValue }: any) =>
          renderedCellValue === null ? null : (
            <Badge variant="light">{renderedCellValue}</Badge>
          ),
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
      },
      {
        accessorKey: "sdt",
        header: "Số điện thoại",
      },
      {
        accessorKey: "dcKh",
        header: "Địa chỉ khách hàng",
        size: 400,
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate.length > 0) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate.length > 0) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.idCn.length > 0) {
      url += `&IdCn=${search.idCn}`;
    }

    if (search.trangThaiDh.length > 0) {
      url += `&TrangThaiDh=${search.trangThaiDh}`;
    }

    if (search.loaiGdKho.length > 0) {
      url += `&LoaiGdKho=${search.loaiGdKho}`;
    }

    if (search.idNcc.length > 0) {
      url += `&IdNcc=${search.idNcc}`;
    }

    if (search.fromWarrantyDue.length > 0) {
      url += `&FromWarrantyDue=${search.fromWarrantyDue}`;
    }

    if (search.toWarrantyDue.length > 0) {
      url += `&ToWarrantyDue=${search.toWarrantyDue}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryDelivery.get(
        `/api/v1/SaleSerialReport/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const handleExportData = async () => {
    let url = `/api/v1/SaleSerialReport/export-sale-serial?Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.startDate.length > 0) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate.length > 0) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.idCn.length > 0) {
      url += `&IdCn=${search.idCn}`;
    }

    if (search.trangThaiDh.length > 0) {
      url += `&TrangThaiDh=${search.trangThaiDh}`;
    }

    if (search.loaiGdKho.length > 0) {
      url += `&LoaiGdKho=${search.loaiGdKho}`;
    }

    if (search.idNcc.length > 0) {
      url += `&IdNcc=${search.idNcc}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    const _url = BASE_API_DELIVERY + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "bao_cao_serial_xuat_ban_hoac_nhap_lai" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const fetchDataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataBranchSelect(
      getData
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataVendorSelect = async () => {
    const getData = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblVendor/get-all"
    );

    if (getData && getData?.success) {
      const result = getData.data;
      setDataVendorSelect(
        result
          ?.filter(
            (item: any) => item.vendorName != null && item.vendorCode != null
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: `${item.vendorCode} - ${item.vendorName}`,
          }))
      );
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (140 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <_breadcrumb></_breadcrumb>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() => handleExportData()}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>
            </Menu.Dropdown>
          </Menu>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Từ ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              value={
                search.startDate.length > 0
                  ? new Date(search.startDate ?? "")
                  : null
              }
              onChange={(e) => {
                handleChangeSearchValue(
                  e ? formatDateNotTimeZone(e ?? "") : "",
                  "startDate"
                );
              }}
              leftSection={<IconCalendar color="#15aabf" />}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Đến ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              value={
                search.endDate.length > 0
                  ? new Date(search.endDate ?? "")
                  : null
              }
              onChange={(e) => {
                handleChangeSearchValue(
                  e ? formatDateNotTimeZone(e ?? "") : "",
                  "endDate"
                );
              }}
              leftSection={<IconCalendar color="#15aabf" />}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Chi nhánh - Địa điểm nhận hàng"
              data={dataBranchSelect}
              searchable
              clearable
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              nothingFoundMessage={"Không tìm thấy dữ liệu !"}
              onChange={(e) => {
                handleChangeSearchValue(e?.toString() ?? "", "idCn");
              }}
              onClick={() => {
                if (dataBranchSelect.length === 0) {
                  fetchDataBranchSelect();
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Trạng thái đơn hàng"
              data={trangThaiDhSelect}
              searchable
              clearable
              leftSection={<IconClipboardData color="#15aabf" />}
              nothingFoundMessage={"Không tìm thấy dữ liệu !"}
              onChange={(e) => {
                handleChangeSearchValue(e?.toString() ?? "", "trangThaiDh");
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Loại giao dịch kho"
              data={loaiGdKho}
              searchable
              clearable
              leftSection={<IconBuildingWarehouse color="#15aabf" />}
              nothingFoundMessage={"Không tìm thấy dữ liệu !"}
              onChange={(e) => {
                handleChangeSearchValue(e?.toString() ?? "", "loaiGdKho");
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Nhà cung cấp"
              data={dataVendorSelect}
              leftSection={<IconBuildingSkyscraper color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              comboboxProps={{ width: 425 }}
              onChange={(e) => handleChangeSearchValue(e ?? "", "idNcc")}
              onClick={() => {
                if (dataBranchSelect.length === 0) {
                  getDataVendorSelect();
                }
              }}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Thời hạn bảo hành"
              data={warrantyTerm?.map((item: any) => ({
                label: `Trên ${item.label}`,
                value: item.value,
              }))}
              leftSection={<IconTagStarred color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(e ?? "", "fromWarrantyDue")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Thời hạn bảo hành"
              data={warrantyTerm?.map((item: any) => ({
                label: `Dưới ${item.label}`,
                value: item.value,
              }))}
              leftSection={<IconTagStarred color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(e ?? "", "toWarrantyDue")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              defaultValue={keySearch}
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
export default ExportOrReExport;
