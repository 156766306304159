import "./tab.component.css";

import { Paper, Tabs } from "@mantine/core";
import { useState } from "react";
import DMBranch from "./DMBranch/DMBranch";
import DMInventory from "./DMInventory/DMInventory";
import DMReasonReturn from "./DMReasonReturn/DMReasonReturn";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import { DMInventoryGroup } from "./DMInventoryGroup/DMInventoryGroup";
import WarehouseLocationList from "../../Warehouse/WarehouseLocationList/WarehouseLocationList";

const GeneralData = () => {
  const [activeTab, setActiveTab] = useState<string | null>("branch");

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Paper shadow="md" radius="lg">
        <Tabs p={"xs"} value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="branch">Danh mục trung tâm</Tabs.Tab>
            {/* <Tabs.Tab value="inventory">Danh mục kho</Tabs.Tab>
            <Tabs.Tab value="warehouse-location-list">
              Danh mục vị trí kho
            </Tabs.Tab> */}
            <Tabs.Tab value="inventory-groups">Phân loại kho</Tabs.Tab>
            <Tabs.Tab value="reasonreturn">Lý do trả hàng</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="branch">
            {activeTab === "branch" ? <DMBranch /> : <></>}
          </Tabs.Panel>

          {/* <Tabs.Panel value="inventory">
            {activeTab === "inventory" ? <DMInventory /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="warehouse-location-list">
            {activeTab === "warehouse-location-list" ? (
              <WarehouseLocationList />
            ) : (
              <></>
            )}
          </Tabs.Panel> */}

          <Tabs.Panel value="reasonreturn">
            {activeTab === "reasonreturn" ? <DMReasonReturn /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="inventory-groups">
            {activeTab === "inventory-groups" && <DMInventoryGroup />}
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </>
  );
};

export default GeneralData;
