import {
  Badge,
  Box,
  Button,
  Group,
  Input,
  Menu,
  MultiSelect,
  MultiSelectProps,
  Text,
  Select,
  rem,
  Tooltip,
} from "@mantine/core";
import {
  IconBuildingWarehouse,
  IconCaretDown,
  IconFileExport,
  IconSearch,
  IconSwitch,
  IconBuildingFactory,
  IconX,
  IconBarcode,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { BASE_API_MDM } from "../../../config";
import { _localization } from "../../../config/location";
import { SelectListItemModel } from "../../../model/SelectListItem";
import { DatePickerInput } from "@mantine/dates";
import { IconTimeDuration15 } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { MessageResponse } from "../../../model/MessageResponse";
const InventoryTransactionsReport = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<Department[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranchCode, setDataBranchCode] = useState<any>([]);
  const [dataInvCode, setDataInvCode] = useState<SelectListItemModel[]>([]);
  const [dataItemCode, setDataItemCode] = useState<SelectListItemModel[]>([]);
  const [dataPositionCode, setDataPositionCode] = useState<
    SelectListItemModel[]
  >([]);
  const [search, setSearch] = useState({
    keySearch: "",
    LoaiGdNhapXuat: "",
    maViTriList: [],
    maKhoList: [],
    maCn: [],
    itemCode: [],
    StartTime: "",
    EndTime: "",
  });
  const handleChangeSearchValue = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };
  const handleChangeValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
    // setOnchange(value);
  };
  const navigate = useNavigate();
  const exportDataExcel = async () => {
    // const baseUrl = `https://apis-pos-dev.hacom.vn/api-mdm/api/v1/ExportExcel/export-bao-cao-bang-ke-phieu-nhap-phieu-xuat`;
    // const fileName = "bang_ke_phieu_nhap_phieu_xuat.xlsx";
    // await exportDataToExcel(baseUrl, fileName);
    // const url = `/api/v1/ExportExcel/export-bao-cao-bang-ke-phieu-nhap-phieu-xuat`;
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (search.maViTriList && search.maViTriList.length > 0) {
      search.maViTriList.forEach((maViTriList) => {
        url += `&MaViTriList=${maViTriList}`;
      });
    }
    if (search.StartTime) {
      url += `&startDate=${search.StartTime}`;
    }
    if (search.EndTime) {
      url += `&endDate=${search.EndTime}`;
    }
    if (search.itemCode && search.itemCode.length > 0) {
      search.itemCode.forEach((itemCode) => {
        url += `&MaVatTu=${itemCode}`;
      });
    }
    if (search.maKhoList && search.maKhoList.length > 0) {
      search.maKhoList.forEach((maKho) => {
        url += `&MaKhos=${maKho}`;
      });
    }
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    if (search.LoaiGdNhapXuat) {
      url += `&LoaiGdNhapXuat=${search.LoaiGdNhapXuat}`;
    }
    const baseUrl = `/api/v1/ExportExcel/export-bao-cao-bang-ke-phieu-nhap-phieu-xuat${url}`;
    const _url = BASE_API_MDM + baseUrl;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "bang_ke_phieu_nhap_phieu_xuat.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  const formatNumber = (number: number) => {
    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };
  const detailData = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean,
    category: number,
    chungTuGoc: string
  ) => {
    // window.open("/warehouse-config/detail-warehouse-out-ward", "_blank");
    if (category === 2) {
      navigate("/warehouse-config/detail-warehouse-out-ward", {
        state: { id: id, type: type, isLocked: isLocked },
      });
    } else {
      navigate(`/warehouse-config/detail-warehouse-in-ward`, {
        state: {
          id: id,
          sourceType: type,
          isLocked: isLocked,
          sourceCode: chungTuGoc,
        },
      });
    }
  };

  const handleCheckView = async (row: any) => {
    console.log("row", row?.original);
    const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.chungTuGoc}`;
    const response = await repositoryPos.get<MessageResponse<any>>(url);

    if (response && response.success) {
      // Kiểm tra nếu orderNumber chứa "DT"
      if (row.original.chungTuGoc.includes("DT")) {
        navigate("/sell/view-order-return", {
          state: {
            id: row.original.id,
            orderNumber: row.original.chungTuGoc,
            orderType: 3,
            actionType: "view",
            // isReadOnly:
            //   location?.pathname === "/sell/sales-order-list-read-only"
            //     ? true
            //     : false,
          },
        });
      } else {
        navigate("/sell/view-order", {
          state: {
            id: row.original.id,
            orderNumber: row.original.chungTuGoc,
            orderType: 2,
            actionType: "view",
            // isReadOnly:
            //   location?.pathname === "/sell/sales-order-list-read-only"
            //     ? true
            //     : false,
          },
        });
      }
    }
  };

  console.log(data, "àuiashfdiuashfd");
  
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "chungTuGoc",
        header: "Chứng từ gốc",
        size: 200,
        Cell: ({ renderedCellValue, cell, row }) => (
          <Badge
            radius="sm"
            variant="dot"
            style={{ cursor: "pointer" }}
            size="lg"
            onDoubleClick={() => handleCheckView(row)}
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "maGd",
        header: "Mã giao dịch",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Tooltip label="Xem chi tiết">
                <Badge
                  style={{ cursor: "pointer" }}
                  onDoubleClick={() =>
                    detailData(
                      row.original.transactionHeaderId,
                      row.original.sourceType,
                      true,
                      row.original.loaiGdNhapXuat,
                      row.original.chungTuGoc
                    )
                  }
                  bd="1px solid blue"
                  variant="light"
                  color="blue"
                >
                  {renderedCellValue}
                </Badge>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        accessorKey: "ngayGd",
        header: "Ngày giao dịch",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "loaiGd",
        header: "Loại giao dịch",
        size: 250,
      },
      {
        accessorKey: "dienGiai",
        header: "Diễn giải",
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge variant="light" color="violet">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "tenCn",
        header: "Tên chi nhánh",
      },
      {
        accessorKey: "maKho",
        header: "Mã kho",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge variant="light" color="blue">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "maViTri",
        header: "Mã vị trí",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge variant="light" color="blue">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge variant="light" color="blue">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        size: 700,
      },
      {
        accessorKey: "serialNumber",
        header: "Serial",
        Cell: ({ renderedCellValue, cell, row }) => (
          <Box
            style={{
              borderRadius: "30px",
              backgroundColor: "#E8F3FC",
              padding: "4px",
            }}
          >
            <Text variant="light" p="4px 8px" size="xs" color="blue" fw="bold">
              {renderedCellValue ?? ""}
            </Text>
          </Box>
        ),
      },
      {
        accessorKey: "serialStatus",
        header: "Trạng thái serial",
      },
      {
        accessorKey: "soLuongNhap",
        header: "Số lượng nhập",
        // Cell: ({ renderedCellValue, row }) => (
        //   <>{row.original.soLuongNhap === 1 ? "Đã Nhập" : "Chưa Nhập"}</>
        // ),
      },
      {
        accessorKey: "soLuongXuat",
        header: "Số lượng xuất",
        // Cell: ({ renderedCellValue, row }) => (
        //   <>{row.original.soLuongXuat === 1 ? "Đã Xuất" : "Chưa Xuất"}</>
        // ),
      },
      {
        accessorKey: "donGia",
        header: "Đơn giá",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatNumber(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "maNv",
        header: "Mã nhân viên",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            {renderedCellValue === null ? null : (
              <Badge variant="light" color="pink">
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "tenNv",
        header: "Tên nhân viên",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <AvatarUtils value={row.original.tenNv?.toString().split("-")[0]} />
          </>
        ),
        size: 200,
      },
      {
        accessorKey: "phongBanGd",
        header: "Phòng ban giao dịch",
      },
      // {
      //   accessorKey: "tenCnGd",
      //   header: "Tên chi nhánh giao dịch",
      //   size: 200,
      // },
    ],
    []
  );

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.maViTriList && search.maViTriList.length > 0) {
      search.maViTriList.forEach((maViTriList) => {
        url += `&MaViTriList=${maViTriList}`;
      });
    }
    if (search.StartTime) {
      url += `&startDate=${search.StartTime}`;
    }
    if (search.EndTime) {
      url += `&endDate=${search.EndTime}`;
    }
    if (search.itemCode && search.itemCode.length > 0) {
      search.itemCode.forEach((itemCode) => {
        url += `&MaVatTu=${itemCode}`;
      });
    }
    if (search.maKhoList && search.maKhoList.length > 0) {
      search.maKhoList.forEach((maKho) => {
        url += `&MaKho=${maKho}`;
      });
    }
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    if (search.LoaiGdNhapXuat) {
      url += `&LoaiGdNhapXuat=${search.LoaiGdNhapXuat}`;
    }
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblItemTransaction/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const DataInvCode = async () => {
    // const user = JSON.parse(localStorage.getItem("userLogin") || "{}");
    // const Invcode = user?.branchId;
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmInventory/get-all?checkAuth=true`
      );
      if (response && response.httpStatusCode === 200) {
        const transformedData = response.data
          .filter((i: any) => i.value && i.text)
          .map((item: any) => {
            const match = item.text.match(/\[(.*?)\]/);
            const fullLabel = match ? match[1].trim() : item.text;
            return {
              value: item.value,
              label: item.text,
              fullLabel: fullLabel,
            };
          });

        setDataInvCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const DataPositionCode = async () => {
    try {
      if (search.maKhoList != null && search.maKhoList.length > 0) {
        let url = search.maKhoList
          .map((maKhoList) => `SubInvCode=${maKhoList}`)
          .join("&");
        if (url) {
          url = `?${url}`;
        }

        const response = await repositoryDelivery.get(
          `/api/v1/TblDmItemPosition/get-select${url}`
        );
        if (response && response.httpStatusCode === 200) {
          const transformedData = response.data
            .filter((i: any) => i.positionCode && i.positionName)
            .map((item: any) => ({
              value: item.positionCode,
              label: item.positionCode + " - " + item.positionName,
            }));
          setDataPositionCode(transformedData);
        }
      } else {
        const response = await repositoryDelivery.get(
          `/api/v1/TblDmItemPosition/get-all`
        );
        if (response && response.httpStatusCode === 200) {
          const transformedData = response.data.map((item: any) => ({
            value: item.positionCode,
            label: item.positionCode + " - " + item.positionName,
          }));
          const uniqueData = Array.from(
            new Set(transformedData.map((item: any) => item.value))
          ).map((value) =>
            transformedData.find((item: any) => item.value === value)
          );
          setDataPositionCode(uniqueData);
        }
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataItemCode = async (keySearch: string) => {
    try {
      let url = "";
      if (keySearch && keySearch.length > 0) {
        url += `&ItemCodes=${keySearch}`;
      }
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-list-item-by-inv?Skip=0&Take=50` +
          url
      );
      if (response && response.success) {
        const transformedData = response.data
          ?.filter((i: any) => i.maSp && i.tenSp)
          .map((branch: any) => ({
            value: branch.maSp,
            label: branch.maSp + " - " + branch.tenSp,
          }));
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.value))
        ).map((value) =>
          transformedData.find((item: any) => item.value === value)
        );
        setDataItemCode(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  console.log(data, "ạiodfhsdiuogfh");
  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,

    enableColumnFilters: false,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "transactionNumber", "chungTuGoc"],
        right: ["statusName"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập từ khóa"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "keySearch");
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />

          <Select
            size="sm"
            placeholder="Trạng thái"
            searchable
            clearable
            data={[
              { value: "1", label: "Nhập" },
              { value: "2", label: "Xuất" },
            ]}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconSwitch size={20} color="#15aabf" />}
            onChange={(value) =>
              handleChangeSearchValue(value ?? "", "LoaiGdNhapXuat")
            }
          />
          <MultiSelect
            size="sm"
            placeholder="Mã kho"
            searchable
            clearable
            data={dataInvCode
              .map((x) => x.fullLabel)
              .filter((label): label is string => label !== undefined)}
            value={search.maKhoList}
            leftSection={<IconBuildingWarehouse size={20} color="#15aabf" />}
            onClick={() => {
              //   if (change.maCn) {
              DataInvCode();
              //   }
            }}
            renderOption={renderMultiSelectOptionMaKho}
            onChange={(value) => handleChangeValue(value, "maKhoList")}
          />
          <MultiSelect
            size="sm"
            placeholder="Mã vị trí"
            searchable
            clearable
            data={dataPositionCode.map((x) => x.value)}
            value={search.maViTriList}
            renderOption={renderMultiSelectOptionPositionCode}
            leftSection={<IconBuildingFactory size={20} color="#15aabf" />}
            onClick={() => {
              DataPositionCode();
            }}
            onChange={(value) => {
              handleChangeValue(value, "maViTriList");
            }}
          />
          <MultiSelect
            size="sm"
            placeholder="Mã sản phẩm"
            clearable
            searchable
            data={dataItemCode.map((x) => x.value)}
            value={search.itemCode}
            renderOption={renderMultiSelectOption}
            leftSection={<IconBarcode size={20} color="#15aabf" />}
            onSearchChange={(value) => {
              if (value.length >= 3) {
                DataItemCode(value);
              }
            }}
            onChange={(value) => {
              handleChangeValue(value, "itemCode");
            }}
          />
          <DatePickerInput
            size="sm"
            placeholder="Chọn ngày bắt đầu"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(formatDateNotTimeZone(e), "StartTime");
            }}
            w={200}
            clearable
          />
          <DatePickerInput
            size="sm"
            placeholder="Chọn ngày kết thúc"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(formatDateNotTimeZone(e), "EndTime");
            }}
            w={200}
            clearable
          />
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Chức năng
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Đóng
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 550,
        fontSize: "12.5px",
        padding: "8px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);
  useEffect(() => {
    fetchData();
  }, [pagination]);
  const renderMultiSelectOptionMaKho: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>
        {dataInvCode.find((x) => x.fullLabel === option.value)?.label}
      </Text>
    </Group>
  );
  const renderMultiSelectOptionPositionCode: MultiSelectProps["renderOption"] =
    ({ option }) => (
      <Group gap="sm">
        <Text>
          {dataPositionCode.find((x) => x.value === option.value)?.label}
        </Text>
      </Group>
    );
  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataItemCode.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );
  return (
    <Box>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default InventoryTransactionsReport;
type Department = {
  loaiGdNhapXuat: number;
  maGd: string;
  ngayGd: string;
  loaiGd: string;
  chungTuGoc: string;
  dienGiai: string;
  idChiNhanh: number;
  maCn: string;
  tenCn: string;
  idKho: string;
  maKho: string;
  locatorId: number | null;
  maViTri: string | null;
  transactionId: number;
  itemId: number;
  itemCode: string;
  itemName: string;
  serialNumber: string;
  serialStatus: string;
  dvt: string | null;
  soLuong: number;
  soLuongNhap: number;
  soLuongXuat: number;
  donGia: number;
  maNv: string;
  tenNv: string;
  phongBanGd: string;
  idCnGd: number;
  tenCnGd: string;
  maNhomSp: string;
};
