import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { _localization } from "../../../config/location";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { useDebouncedState } from "@mantine/hooks";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  Menu,
  rem,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCaretDown,
  IconEdit,
  IconFileExport,
  IconFileUpload,
  IconSearch,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import EditCustMeeting from "./EditCustMeeting";

const CustMeeting = ({
  setDataMeetingAddress,
  infoCust,
  setProvinceId,
  setDistrictId,
  setCommuneId,
  view,
}: {
  setDataMeetingAddress?: any;
  infoCust?: any;
  setProvinceId?: any;
  setDistrictId?: any;
  setCommuneId?: any;
  view: any;
}) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState<boolean>(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [keySearch, setKeySearch] = useDebouncedState<string>("", 300);

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Họ và tên",
        enableColumnActions: false,
      },
      {
        accessorKey: "phone",
        header: "Số điện thoại",
        enableColumnActions: false,
        size: 150,
      },
      {
        accessorKey: "additionalAddress",
        header: "Địa chỉ cụ thể",
        enableColumnActions: false,
        size: 150,
      },
      {
        accessorKey: "streetName",
        header: "Đường",
        enableColumnActions: false,
      },
      {
        accessorKey: "communeName",
        header: "Phường/Xã",
        enableColumnActions: false,
      },
      {
        accessorKey: "districtName",
        header: "Quận/Huyện",
        enableColumnActions: false,
      },
      {
        accessorKey: "provinceName",
        header: "Tỉnh/Thành Phố",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableResizing: false,
        size: 100,
        Cell: ({ row }) => (
          <Flex justify={"center"}>
            <Button
              variant="filled"
              color="green"
              onClick={() => {
                modals.closeAll();
                setDataMeetingAddress({
                  province: row.original.province,
                  district: row.original.district,
                  commune: row.original.commune,
                  street: row.original.street ?? null,
                });
                setProvinceId(row.original.province);
                setDistrictId(row.original.district);
                setCommuneId(row.original.commune);
                infoCust((prev: any) => ({
                  ...prev,
                  custMeeting: row.original.name,
                  custPhone: row.original.phone,
                  additionalAddress: row.original.additionalAddress ?? null,
                  attribute1: row.original.id,
                  meetingAddress: `${
                    row.original.additionalAddress !== null
                      ? `${row.original.additionalAddress} -`
                      : ""
                  } ${
                    row.original.street !== null
                      ? `${row.original.street} -`
                      : ""
                  } ${row.original.commune} - ${row.original.district} - ${
                    row.original.province
                  }`,
                }));
              }}
            >
              Chọn
            </Button>
          </Flex>
        ),
      },
      {
        accessorKey: "edit",
        header: "Thao tác",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableResizing: false,
        size: 60,
        Cell: ({ row }) => (
          <Flex justify={"center"}>
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                color="orange"
                onClick={() => editModals(row.original.id)}
              >
                <IconEdit
                  size={20}
                  stroke={1.5}
                />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ),
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const getColumnVisibility = () => {
    if (view === "VIEW") {
      return {
        action: false,
        edit: true,
      };
    } else if (view !== "VIEW") {
      return {
        edit: false,
        action: true,
      };
    }
  };

  const editModals = (id: any) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa thông tin khách hàng bảo hành</Title>
        </>
      ),
      size: "auto",
      children: (
        <EditCustMeeting
          id={id}
          setDeleteViewStatus={setDeleteViewStatus}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const getDataCustMeeting = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyServiceRequestForm/get-customer${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    getDataCustMeeting();
  }, [pagination, deleteViewStatus]);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList?.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (195 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: () => (
      <div
        ref={headerRef}
        style={{ width: "100%" }}
      >
        {view === "VIEW" ? (
          <Flex
            justify={"space-between"}
            wrap={"wrap"}
            w={"100%"}
            align={"center"}
          >
            <_breadcrumb></_breadcrumb>
            <Menu
              shadow="md"
              width={200}
            >
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        ) : (
          <></>
        )}
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa ..."
              leftSection={<IconSearch color="#15aabf" />}
              defaultValue={keySearch}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                getDataCustMeeting();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: () => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["action"],
        right: ["edit"],
      },
      columnVisibility: getColumnVisibility(),
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: view === "VIEW" ? height : 450,
        minHeight: view === "VIEW" ? height : 450,
      },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    onPaginationChange: setPagination,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 500,
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  // React.useEffect(() => {
  //   const newColumnVisibility = getColumnVisibility();
  //   if (table) {
  //     table.setColumnVisibility(newColumnVisibility);
  //   }
  // }, [table]);

  return (
    <Box pt={10}>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default CustMeeting;
