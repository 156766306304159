import {
  Anchor,
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Select,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { ComboboxItem } from "../../../model/ComboboxItem";
import { RegisterModel } from "../../../model/RegisterModel";
import { _validateUserRisterModelValidator } from "../../../validate/UserCreateModelValidator";
import { repositoryMaster } from "../../_const/_constVar";
import { NotificationExtension } from "../../extension/NotificationExtension";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { truncate } from "fs/promises";
type Props = {
  setIsLogin: (value: boolean) => void;
  setDataUserSign: (data: { name: string; pass: string }) => void;
};

interface Item {
  id: number; // hoặc string tùy thuộc vào kiểu của id trong item
  fullname: string;
  codeList: string; // hoặc bất kỳ kiểu nào phù hợp với dữ liệu trong codeList
}

const Register = ({ setIsLogin, setDataUserSign }: Props) => {
  // form create user
  const entity = {
    id: 0,
    userName: "",
    password: "",
    inActive: true,
    // phone: null,
    fullName: "",
    // center: "", //bỏ
    confirmPassword: "",
    // group: "", //bỏ
    // createBy: null,
    empid: 0, //empId là mã nhân viên
    menuId: "", //menuid là mã menu, chức vụ
    email: "", //email
  };
  const form = useForm<RegisterModel>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: _validateUserRisterModelValidator,
  });

  const [data, setData] = useState<any>({});
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataListMenu, setDataListMenu] = useState<ComboboxItem[]>([]);
  const [dataEmployeeSelect, setDataDmEmployeeSelect] = useState<any[]>([]); //data Chức vụ
  const [dataCodeEmp, setDataCodeEmp] = useState<any>(null); //data mã nhân viên
  //getApiCreateUser\
  const CallApiGetData = async () => {
    open();
    try {
      const response = await repositoryMaster.get("/api/v1/Auth/create-auth");
      if (response && response.httpStatusCode === 200) {
        setData(response.data);
        //chức vụ
        setDataListMenu(
          response.data.listMenu
            .filter((item: any) => item.value != null && item.text != null)
            .map((item: any) => ({
              value: String(item.value),
              label: item.text,
            }))
        );
        //nhân viên
        setDataDmEmployeeSelect(
          response.data.tblDmEmployees
            .filter((item: any) => item.code != null && item.fullname != null)
            .map((item: any) => ({
              value: item.id.toString(),
              label: item.fullname,
              codeList: item.code,
            }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      close();
    }
  };

  const CreateApi = async (data: RegisterModel) => {
    // data.empid = Number(data.empid);
    open();
    try {
      const response = await repositoryMaster.post(
        "/api/v1/Auth/create-auth",
        data
      );
      if (response && response.success === true) {
        NotificationExtension.Success("Đăng ký thành công");
        setDataUserSign({ name: data.userName, pass: data.password });
        setTimeout(() => {
          setIsLogin(false);
        }, 1000);
      } else {
        NotificationExtension.Fails(response.message);
      }
    } catch (error) {
      NotificationExtension.Fails("Đăng ký thất bại");
    } finally {
      close();
    }
  };

  useEffect(() => {
    CallApiGetData();
  }, []);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data.tblDmEmployees) &&
      data.tblDmEmployees.length > 0
    ) {
      const result = data?.tblDmEmployees?.find(
        (item: any) => item.id == form.getValues().empid
      );
      setDataCodeEmp(result?.code);

      // const inputString =
      //   dataEmployeeSelect.find(
      //     (item: any) => item.value == form.getValues().empid
      //   )?.label ?? "";

      // const normalizedString = inputString
      //   .normalize("NFD")
      //   .replace(/[\u0300-\u036f]/g, "")
      //   .replace(/Đ/g, "D")
      //   .replace(/đ/g, "d");

      // let formattedString = normalizedString.toLowerCase();

      // const nameParts = formattedString.split(" ");
      // if (formattedString.replace(/\s/g, "").length > 15) {
      //   formattedString =
      //     nameParts.length > 1
      //       ? nameParts[0] + nameParts[nameParts.length - 1]
      //       : formattedString;
      // }

      // const stringWithoutSpaces =
      //   formattedString.replace(/\s/g, "") + "-" + result?.code;

      const inputString =
        dataEmployeeSelect.find(
          (item: any) => item.value === form.getValues().empid
        )?.label ?? "";

      const normalizedString = inputString
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/Đ/g, "D")
        .replace(/đ/g, "d");

      let formattedString = normalizedString.toLowerCase();

      const nameParts = formattedString.split(" ");

      const lastName = nameParts[nameParts.length - 1] || "";

      if (formattedString.replace(/\s/g, "").length > 15) {
        formattedString =
          nameParts.length > 1 ? nameParts[0] + lastName : formattedString;
      }

      const stringWithoutSpaces = `${lastName}-${result?.code}`;

      if (result) {
        const phoneNumber = result?.phoneNumber?.replace(/\s+/g, "") || "";
        const formattedPhoneNumber =
          phoneNumber && !phoneNumber.startsWith("0")
            ? "0" + phoneNumber
            : phoneNumber;

        form.setValues({
          email: result?.email || "",
          phone: formattedPhoneNumber ? formattedPhoneNumber : null,
          fullName: result?.fullname || "",
          userName: stringWithoutSpaces,
        });
      }
    }
  }, [form.getValues().empid]);

  useEffect(() => {
    if (form.getValues().userName.length > 20) {
      form.setErrors({
        userName: "Tên đăng nhập phải từ 5-20 kí tự!",
      });
    }
  }, [form.getValues().userName]);

  return (
    <>
      <Box
        component="form"
        m={0}
        p={0}
        style={{
          width: "100%",
        }}
        onSubmit={form.onSubmit(async (e: RegisterModel) => {
          await CreateApi(e);
        })}
      >
        <Flex mb={20} direction="column">
          <Title c="#342e79" fw={500} order={3}>
            Đăng ký để bắt đầu!
          </Title>
          <Text c="#8e8e8e" fw="400" size="lg">
            Vui lòng điền đầy đủ thông tin để đăng ký tài khoản
          </Text>
        </Flex>
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Paper pb={50} bg={"transparent"}>
          <Flex
            direction={"row"}
            justify={"space-between"}
            align={"center"}
            gap={"md"}
          >
            <Select
              id="empid"
              key={form.key("empid")}
              w={"50%"}
              label="Tên nhân viên"
              labelProps={{
                style: {
                  color: "#342e79",
                  marginBottom: 5,
                  fontSize: "0.750rem",
                  fontWeight: "bold",
                },
              }}
              searchable
              clearable
              withAsterisk
              c="#342e79"
              placeholder="Chọn tên nhân viên"
              data={dataEmployeeSelect}
              value={(form.getValues().empid ?? "").toString()}
              {...form.getInputProps("empid")}
              renderOption={({ option, checked }) => (
                <Flex flex="1" gap="xs">
                  <Text>
                    {option.label} {" - "}
                    {dataEmployeeSelect.find(
                      (ele) => String(ele.value) === String(option.value)
                    )?.codeList ?? ""}
                  </Text>
                  {checked && (
                    <IconCheck
                      fontWeight={600}
                      size={20}
                      color="green"
                      style={{ marginInlineStart: "auto" }}
                    />
                  )}
                </Flex>
              )}
              // onChange={(e) => {
              //   console.log("e", e);
              //   form.setValues({ empid: e });
              // }}
            />
            <TextInput
              w={"50%"}
              withAsterisk
              label="Mã nhân viên"
              labelProps={{
                style: {
                  color: "#342e79",
                  marginBottom: 5,
                  fontSize: "0.750rem",
                  fontWeight: "bold",
                },
              }}
              placeholder="Mã nhân viên"
              readOnly
              value={dataCodeEmp ? dataCodeEmp : ""}
            />
          </Flex>
          <Flex
            direction={"row"}
            justify={"space-between"}
            align={"center"}
            gap={"md"}
          >
            <TextInput
              w={"50%"}
              withAsterisk
              label="Tên tài khoản"
              labelProps={{
                style: {
                  color: "#342e79",
                  marginBottom: 5,
                  fontSize: "0.750rem",
                  fontWeight: "bold",
                },
              }}
              value={form.getValues().userName}
              placeholder="Tên tài khoản"
              onKeyDown={(event) => {
                if (event.key === " ") {
                  event.preventDefault();
                }
              }}
              {...form.getInputProps("userName")}
            />
            <Select
              w={"50%"}
              withAsterisk
              searchable
              label="Chức vụ"
              labelProps={{
                style: {
                  color: "#342e79",
                  marginBottom: 5,
                  fontSize: "0.750rem",
                  fontWeight: "bold",
                },
              }}
              c="#342e79"
              data={dataListMenu}
              placeholder="Chức vụ"
              {...form.getInputProps("menuId")}
            />
          </Flex>

          <Flex
            direction={"row"}
            justify={"space-between"}
            align={"center"}
            gap={"md"}
          >
            <PasswordInput
              w={"50%"}
              withAsterisk
              label="Mật khẩu"
              labelProps={{
                style: {
                  color: "#342e79",
                  marginBottom: 5,
                  fontSize: "0.750rem",
                  fontWeight: "bold",
                },
              }}
              placeholder="Mật khẩu"
              {...form.getInputProps("password")}
            />
            <PasswordInput
              w={"50%"}
              withAsterisk
              label="Nhập lại mật khẩu"
              labelProps={{
                style: {
                  color: "#342e79",
                  marginBottom: 5,
                  fontSize: "0.750rem",
                  fontWeight: "bold",
                },
              }}
              placeholder="Nhập lại mật khẩu"
              {...form.getInputProps("confirmPassword")}
            />
          </Flex>
          <Flex
            direction={"row"}
            justify={"space-between"}
            align={"center"}
            gap={"md"}
          >
            <TextInput
              withAsterisk
              w={"50%"}
              label="Email"
              readOnly
              labelProps={{
                style: {
                  color: "#342e79",
                  marginBottom: 5,
                  fontSize: "0.750rem",
                  fontWeight: "bold",
                },
              }}
              placeholder="Email"
              {...form.getInputProps("email")}
            />

            <TextInput
              withAsterisk
              w={"50%"}
              label="Số điện thoại"
              labelProps={{
                style: {
                  color: "#342e79",
                  marginBottom: 5,
                  fontSize: "0.750rem",
                  fontWeight: "bold",
                },
              }}
              readOnly
              placeholder="Số điện thoại"
              {...form.getInputProps("phone")}
              onChange={(e) => {
                const phoneValue = e.target.value.replace(/\D/g, "");
                form.setValues({
                  phone: phoneValue ? Number(phoneValue) : null,
                });
              }}
            />
          </Flex>

          <Button
            type="submit"
            fullWidth
            bg="#342e79"
            mt="xl"
            // className={style.button_submit}
            size="lg"
          >
            <Text c={"#fff"} fw={500} size="lg" tt="capitalize">
              Đăng ký
            </Text>
          </Button>
          <Group align="center" justify="end" mt="lg">
            <IconArrowLeft color="#228be6" size={16} />
            <Anchor size="md" onClick={() => setIsLogin(false)}>
              Trở lại đăng nhập
            </Anchor>
          </Group>
        </Paper>
        <Group justify="end">
          <Flex gap={4}>
            Copyright © 2024{" "}
            <Text fw={600} c="#000">
              Hacom.
            </Text>{" "}
            All rights reserved.
          </Flex>
        </Group>
      </Box>
    </>
  );
};

export default Register;
