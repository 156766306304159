import { Badge, Button, Flex, Grid, Menu, rem, TextInput } from "@mantine/core";
import {
  IconCaretDown,
  IconFileExport,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { useDebouncedState } from "@mantine/hooks";
import { exportDataToExcel } from "../../../common/ExportExcelToAPI/ExportExcelToAPI";
import { BASE_API_DELIVERY } from "../../../config";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
type TypeCategoryCombination = {
  itemId: number;
  itemCode: string;
  itemName: string;
  maHang: string;
  tenHang: string;
  maLinhVuc: string;
  tenLinhVuc: string;
  maNganh: string;
  tenNganh: string;
  maLoai: string;
  tenLoai: string;
  maChung: string;
  tenChung: string;
  maNhom: string;
  tenNhom: string;
  maModel: string;
  tenModel: string | null;
  maHangSx: string | null;
  tenHangSx: string | null;
};

const CategoryCombination = ({ breadCrumb }: { breadCrumb?: any }) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TypeCategoryCombination[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [search, setSearch] = useDebouncedState(
    {
      maHang: "",
      maLinhVuc: "",
      maNganh: "",
      maLoai: "",
      maChung: "",
      maNhom: "",
      maModel: "",
      maHangSx: "",
      keySearch: "",
    },
    300
  );

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex}&Take=${pagination.pageSize}`;

    if (search.maHang.length > 0) {
      url += `&MaHang=${search.maHang}`;
    }

    if (search.maLinhVuc.length > 0) {
      url += `&MaLinhVuc=${search.maLinhVuc}`;
    }

    if (search.maNganh.length > 0) {
      url += `&MaNganh=${search.maNganh}`;
    }

    if (search.maLoai.length > 0) {
      url += `&MaLoai${search.maLoai}`;
    }

    if (search.maChung.length > 0) {
      url += `&MaChung=${search.maChung}`;
    }

    if (search.maNhom.length > 0) {
      url += `&MaNhom=${search.maNhom}`;
    }

    if (search.maModel.length > 0) {
      url += `&MaModel=${search.maModel}`;
    }

    if (search.maHangSx.length > 0) {
      url += `&MaHangSx=${search.maHangSx}`;
    }

    if (search.keySearch.length > 0) {
      url += `&KeySearch=${search.keySearch}`;
    }

    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblDmItemCategoryCombination/get-list${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setIsLoading(false);
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
    }
  };

  const exportDataExcel = async () => {
    let url = `?Skip=${pagination?.pageIndex}&Take=${pagination.pageSize}`;

    if (search.maHang.length > 0) {
      url += `&MaHang=${search.maHang}`;
    }

    if (search.maLinhVuc.length > 0) {
      url += `&MaLinhVuc=${search.maLinhVuc}`;
    }

    if (search.maNganh.length > 0) {
      url += `&MaNganh=${search.maNganh}`;
    }

    if (search.maLoai.length > 0) {
      url += `&MaLoai${search.maLoai}`;
    }

    if (search.maChung.length > 0) {
      url += `&MaChung=${search.maChung}`;
    }

    if (search.maNhom.length > 0) {
      url += `&MaNhom=${search.maNhom}`;
    }

    if (search.maModel.length > 0) {
      url += `&MaModel=${search.maModel}`;
    }

    if (search.maHangSx.length > 0) {
      url += `&MaHangSx=${search.maHangSx}`;
    }

    if (search.keySearch.length > 0) {
      url += `&KeySearch=${search.keySearch}`;
    }

    // const baseUrl = `https://apis-pos-dev.hacom.vn/api-shipping/api/v1/ExportExcel/export-item-category${url}`;
    // const fileName = "danh_muc_vat_tu.xlsx";

    // await exportDataToExcel(baseUrl, fileName);
    const baseUrl = `/api/v1/ExportExcel/export-item-category${url}`;

    const _url = BASE_API_DELIVERY + baseUrl;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "danh_muc_vat_tu.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<TypeCategoryCombination>[]>(
    () => [
      {
        header: "STT",
        size: 30,
        Cell: ({ row }) => (
          <>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {row.index + 1}
            </span>
          </>
        ),
        enableResizing: false,
      },
      {
        accessorKey: "itemCode",
        header: "Mã Sản Phẩm",
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge variant="dot" color="green" radius="xs">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên Sản Phẩm",
        size: 750,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maHang",
        header: "Mã Hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenHang",
        header: "Tên Hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maLinhVuc",
        header: "Mã Lĩnh Vực",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenLinhVuc",
        header: "Tên Lĩnh Vực",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maNganh",
        header: "Mã Ngành",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenNganh",
        header: "Tên Ngành",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maLoai",
        header: "Mã Loại",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenLoai",
        header: "Tên Loại",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maChung",
        header: "Mã Chung",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenChung",
        header: "Tên Chung",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maNhom",
        header: "Mã Nhóm",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenNhom",
        header: "Tên Nhóm",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maModel",
        header: "Mã Model",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenModel",
        header: "Tên Model",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maHangSx",
        header: "Mã Hãng Sản Xuất",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenHangSx",
        header: "Tên Hãng Sản Xuất",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
    ],
    [pagination?.pageSize, pagination?.pageIndex]
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    positionToolbarAlertBanner: "bottom",
    enableColumnResizing: true,

    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "itemCode"],
        // right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "12.5px",
        padding: "5px 12px",
        fontWeight: 500,
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  useEffect(() => {
    fetchData();
  }, [pagination]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (210 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          {breadCrumb ? (
            <>
              <_breadcrumb></_breadcrumb>
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <Button
                    // size="xs"
                    rightSection={
                      <IconCaretDown
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Chức năng
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label>Application</Menu.Label>
                  <Menu.Item
                    leftSection={
                      <IconFileExport
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                    onClick={() => exportDataExcel()}
                  >
                    Export Excel
                  </Menu.Item>
                  <Menu.Item
                    leftSection={
                      <IconX style={{ width: rem(14), height: rem(14) }} />
                    }
                  >
                    Đóng
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </>
          ) : (
            <></>
          )}
        </Flex>
        <Flex wrap={"wrap"} justify={"space-between"} mt={10}>
          <Grid w={"70%"}>
            {/* <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              placeholder="Mã hàng"
              defaultValue={search.maHang}
              onChange={(e) =>
                handleChangeSearchValue(e.currentTarget.value, "maHang")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              placeholder="Mã lĩnh vực"
              defaultValue={search.maLinhVuc}
              onChange={(e) =>
                handleChangeSearchValue(e.currentTarget.value, "maLinhVuc")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              placeholder="Mã ngành"
              defaultValue={search.maNganh}
              onChange={(e) =>
                handleChangeSearchValue(e.currentTarget.value, "maNganh")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              placeholder="Mã loại"
              defaultValue={search.maLoai}
              onChange={(e) =>
                handleChangeSearchValue(e.currentTarget.value, "maLoai")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              placeholder="Mã chủng"
              defaultValue={search.maChung}
              onChange={(e) =>
                handleChangeSearchValue(e.currentTarget.value, "maChung")
              }
            />
          </Grid.Col> */}
            <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
              <TextInput
                w={"100%"}
                placeholder="Từ khoá"
                type="text"
                leftSection={<IconSearch color="#15aabf" />}
                defaultValue={search.keySearch}
                onChange={(e) =>
                  handleChangeSearchValue(e.currentTarget.value, "keySearch")
                }
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
              <Button
                onClick={() => fetchData()}
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
              >
                Tìm kiếm
              </Button>
            </Grid.Col>
          </Grid>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() => exportDataExcel()}
              >
                Export Excel
              </Menu.Item>
              <Menu.Item
                leftSection={
                  <IconX style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Đóng
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default CategoryCombination;
