import { useEffect, useState } from "react";
import { Grid, TextInput } from "@mantine/core";
import DetailProduct from "./DetailProduct";

const DeliveryDetailByWarranty = ({
  id,
  setParkingFee,
}: {
  id: any;
  setParkingFee: any;
}) => {
  const [dataDetail, setDataDetail] = useState<any>();

  useEffect(() => {
    setParkingFee(dataDetail?.parkingFee);
  }, [dataDetail]);

  return (
    <>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label="Số phiếu xuất kho BH NCC"
            value={dataDetail?.sourceCode}
            variant="filled"
            readOnly
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label="Số phiếu nhà cung cấp"
            value={dataDetail?.supplierTicketNumber}
            variant="filled"
            readOnly
            size="xs"
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={12}>
          <DetailProduct
            id={id}
            sourceCode={null}
            sourceType={4}
            isDetail={true}
            setDataOrder={setDataDetail}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default DeliveryDetailByWarranty;
