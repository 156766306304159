import {
  Box,
  Flex,
  LoadingOverlay,
  Button,
  Stack,
  Grid,
  Title,
  InputLabel,
  NumberInput,
  Checkbox,
  TagsInput,
  Text,
  TextInput,
  PasswordInput,
  Input,
  Loader,
  JsonInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useInterval } from "@mantine/hooks";
import { IconEdit, IconSend, IconSparkles } from "@tabler/icons-react";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import {
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryMaster,
} from "../../_base/_const/_constVar";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../model/MessageResponse";
import { TblDMConfigSystem } from "../../model/TblDMConfigSystem";
import { validateChangPassWordModel } from "../../validate/UserCreateModelValidator";
import { ChangPassWordModel } from "../../model/ChangPassWordModel";
import { Delay, DelayTask } from "../../_base/helper/FunctionHelper";
import { ILogging } from "../../_base/IndexedDB/table/logging";
import { DbExtension } from "../../_base/IndexedDB/dbLogging";
import { Tables } from "../../_base/IndexedDB/table";
import { modals } from "@mantine/modals";

import {
  AllCommunityModule,
  CellSelectionOptions,
  ClientSideRowModelModule,
  ColDef,
  ColGroupDef,
  DefaultMenuItem,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  ModuleRegistry,
  ValidationModule,
  createGrid,
} from "ag-grid-community";
import { AG_GRID_LOCALE_VN } from "@ag-grid-community/locale";
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import {
  AdvancedFilterModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  GetRowIdParams,
  GridChartsModule,
  IServerSideDatasource,
  IServerSideGetRowsRequest,
  MasterDetailModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowClassParams,
  RowGroupingModule,
  RowStyle,
  ServerSideRowModelModule,
  SetFilterModule,
  SparklinesModule,
  StatusBarModule,
} from "ag-grid-enterprise";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  AllCommunityModule
]);

const UserPageView = () => {
  const userName = localStorage.getItem("userName");

  const [ipWhiteList, setIpWhiteList] = useState<string[]>([]);
  const entityPass: ChangPassWordModel = {
    Code: "",
    PassOld: "",
    PassNew: "",
    RePassNew: "",
  };
  const form = useForm<ChangPassWordModel>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entityPass,
    },
    validate: validateChangPassWordModel,
  });
  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [seconds, setSeconds] = useState(1);
  const interval = useInterval(() => setSeconds((s) => s + 1), 1000);

  //   useEffect(() => {
  //     interval.start();
  //     return interval.stop;
  //   }, []);
  useEffect(() => {
    if (seconds > 60) {
      interval.stop();
      setSeconds(0);
    }
  }, [seconds]);
  const sendCode = async () => {
    setStateSendCode(true);
    try {
      await DelayTask(1000);
      const response = await repositoryMaster.get<MessageResponse<any>>(
        "/api/v1/Auth/code?userName=" + userName
      );
      if (response && response.httpStatusCode === 200) {
        if (response.success) {
          NotificationExtension.Success(response?.message);
          setSeconds(1);
          interval.start();
        } else {
          NotificationExtension.Fails("Xin vui lòng thử lại !");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setStateSendCode(false);
      close();
    }
  };
  const handleEdit = async (dataSubmit: ChangPassWordModel) => {
    try {
      open();
      const dataApi = await repositoryMaster.post<
        MessageResponse<TblDMConfigSystem>
      >("/api/v1/Auth/change-pass", dataSubmit);
      if (dataApi?.httpStatusCode === 200)
        if (dataApi?.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          form.resetDirty();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
    } catch (error) {}
    close();
  };

  //state

  const [stateSendCode, setStateSendCode] = useState<boolean>(false);
  //logging
  const gridRef = useRef<AgGridReact<ILogging>>(null);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "650px",
      marginTop: "15px",
      flex: " 1 1 auto",
    }),
    []
  );

  const [hiddenGrid, setHiddenGrid] = useState<boolean>(true);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<ILogging[]>([]);
  const columnDefs = useMemo<ColDef<ILogging>[]>(
    () => [
      {
        field: "id",
        headerName: "Mã",
        filter: "agTextColumnFilter",
      },
      {
        field: "createdate",
        headerName: "Ngày thao tác",
        filter: "agDateColumnFilter",
      },
      {
        field: "message",
        headerName: "Nội dung",
        filter: "agTextColumnFilter",
      },
      {
        field: "router",
        headerName: "Đường dẫn",
        filter: "agTextColumnFilter",
        width: 500,
        cellRenderer: (params: CustomCellRendererProps<ILogging>) => {
          return (
            <Button
              size="xs"
              onClick={() => {
                modals.openConfirmModal({
                  title: "Chi tiết ",
                  children: (
                    <Box mx="auto">
                      <Textarea>{params.data?.router}</Textarea>
                    </Box>
                  ),
                  size: "80%",
                  confirmProps: { display: "none" },
                  cancelProps: { display: "none" },
                });
              }}
            >
              {params.data?.router}
            </Button>
          );
        },
      },
      {
        field: "data",
        headerName: "Dữ liệu",
        filter: "agTextColumnFilter",
        width: 500,
        cellRenderer: (params: CustomCellRendererProps<ILogging>) => {
          return (
            <Button
              size="xs"
              disabled={!params.data?.data}
              onClick={() => {
                modals.openConfirmModal({
                  title: "Chi tiết ",
                  children: (
                    <Box mx="auto">
                      <JsonInput
                        disabled
                        defaultValue={params.data?.data ?? ""}
                        label="Disabled"
                        placeholder="Disabled"
                        autosize
                      />
                    </Box>
                  ),
                  size: "80%",
                  confirmProps: { display: "none" },
                  cancelProps: { display: "none" },
                });
              }}
            >
              {params.data?.data}
            </Button>
          );
        },
      },
      {
        field: "error",
        headerName: "Lỗi chung",
        filter: "agTextColumnFilter",
        width: 500,
        cellRenderer: (params: CustomCellRendererProps<ILogging>) => {
          return (
            <Button
              size="xs"
              disabled={!params.data?.error}
              onClick={() => {
                modals.openConfirmModal({
                  title: "Chi tiết ",
                  children: (
                    <Box mx="auto">
                      <JsonInput
                        disabled
                        defaultValue={params.data?.error ?? ""}
                        label="Disabled"
                        placeholder="Disabled"
                        autosize
                      />{" "}
                    </Box>
                  ),
                  size: "80%",
                  confirmProps: { display: "none" },
                  cancelProps: { display: "none" },
                });
              }}
            >
              {params.data?.error}
            </Button>
          );
        },
      },
      {
        field: "errorReponse",
        headerName: "Lỗi từ server",
        filter: "agTextColumnFilter",
        width: 500,
        cellRenderer: (params: CustomCellRendererProps<ILogging>) => {
          return (
            <Button
              size="xs"
              disabled={!params.data?.errorReponse}
              onClick={() => {
                modals.openConfirmModal({
                  title: "Chi tiết ",
                  children: (
                    <Box mx="auto">
                      <JsonInput
                        disabled
                        defaultValue={params.data?.errorReponse ?? ""}
                        label="Disabled"
                        placeholder="Disabled"
                        autosize
                      />{" "}
                    </Box>
                  ),
                  size: "80%",
                  confirmProps: { display: "none" },
                  cancelProps: { display: "none" },
                });
              }}
            >
              {params.data?.errorReponse}
            </Button>
          );
        },
      },
    ],
    [rowData]
  );
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      filter: true,
      floatingFilter: true,
      resizable: false,
    };
  }, []);
  return (
    <>
      <_breadcrumb></_breadcrumb>
      <Box
        component="form"
        w={"100%"}
        onSubmit={form.onSubmit((e: ChangPassWordModel) => {
          handleEdit(e);
        })}
      >
        <Flex
          justify={"end"}
          w={"100%"}
          style={{
            borderBottom: "2px solid #E6E7EA",
          }}
        >
          <LoadingOverlay
            visible={visible}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
        </Flex>
        <Stack px={8} gap={16}>
          <Grid
            py={16}
            style={{
              borderBottom: "2px solid #E6E7EA",
            }}
          >
            <Grid.Col className="setting-header" span={{ sm: 12, md: 3 }}>
              <Flex gap={8} className="setting-header-title">
                <Title
                  order={4}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Đổi mật khẩu
                </Title>
                <IconSparkles color="blue" />
              </Flex>
            </Grid.Col>
            <Grid.Col className="setting-main" span={{ sm: 12, md: 8 }}>
              <Grid>
                <Grid.Col span={{ base: 3 }}>
                  <TextInput
                    disabled
                    label="Tài khoản"
                    placeholder="Nhập..."
                    value={userName ?? ""}
                    radius="md"
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 3 }}>
                  <PasswordInput
                    label="Mật khẩu cũ"
                    placeholder="Nhập..."
                    //  value={form.values.password}
                    withAsterisk
                    {...form.getInputProps("PassOld")}
                    radius="md"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 3 }}>
                  <PasswordInput
                    label="Mật khẩu mới"
                    placeholder="..."
                    withAsterisk
                    {...form.getInputProps("PassNew")}
                    radius="md"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 3 }}>
                  <PasswordInput
                    label="Nhập lại mật khẩu mới"
                    placeholder="..."
                    withAsterisk
                    {...form.getInputProps("RePassNew")}
                    radius="md"
                  />
                </Grid.Col>

                {/* //code */}
                <Grid.Col span={{ base: 3 }}>
                  <TextInput
                    label="Mã xác nhận"
                    placeholder="ABC..."
                    withAsterisk
                    {...form.getInputProps("Code")}
                    radius="md"
                  />
                </Grid.Col>

                <Grid.Col span={{ base: 3 }}>
                  <Button
                    loading={stateSendCode}
                    disabled={interval.active}
                    leftSection={
                      !interval.active ? (
                        <IconSend />
                      ) : (
                        <Loader size={15} color="blue" />
                      )
                    }
                    mt={"20px"}
                    onClick={async () => {
                      await sendCode();
                    }}
                  >
                    {interval.active ? seconds : "Gửi mã"}
                  </Button>

                  {/* <PasswordInput
                    required
                    label="Nhập lại mật khẩu mới"
                    placeholder="Your password"
                    //  value={form.values.password}
                    onChange={(event) =>
                      form.setFieldValue("password", event.currentTarget.value)
                    }
                    error={
                      form.errors.password &&
                      "Password should include at least 6 characters"
                    }
                    radius="md"
                  /> */}
                </Grid.Col>

                {/* <Grid.Col span={{ base: 3 }}>
                  <TextInput
                    label="l"
                    value={
                      interval.active
                        ? "Gửi lại mã sau " + seconds + " giây"
                        : ""
                    }
                    radius="md"
                  />
                </Grid.Col> */}
              </Grid>

              <Grid>
                <Grid.Col span={{ base: 6 }}>
                  <Button
                    variant="filled"
                    mt={20}
                    type="submit"
                    leftSection={<IconEdit />}
                  >
                    Cập nhật
                  </Button>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Stack>
      </Box>

      <Box>
        <Flex gap={8} className="setting-header-title">
          <Title
            order={4}
            style={{
              fontWeight: 500,
              marginTop: "10px",
            }}
          >
            Nhật ký hoạt động (theo trình duyệt)
          </Title>
          <IconSparkles color="blue" />
        </Flex>
        <PasswordInput
          label="Nhập..."
          onChange={(e) => {
            if (e && e.target.value === "hopnk@abc") {
              setHiddenGrid(false);
              DbExtension.getLoggingData(Tables.Logging)
                .then((res) => {
                  const loggingData = res as ILogging[]; // Ép kiểu dữ liệu trả về
                  setRowData(loggingData);
                })
                .catch((error) => {
                  console.error("Error fetching logging data:", error);
                });
            } else setHiddenGrid(true);
          }}
        />
        <div style={containerStyle}>
          <div style={gridStyle} className={"ag-theme-quartz"}>
            {!hiddenGrid && (
              <AgGridReact<ILogging>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                suppressColumnVirtualisation={true}
                suppressRowVirtualisation={true}
                pagination={true}
                localeText={AG_GRID_LOCALE_VN}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                cacheBlockSize={paginationPageSize}
              />
            )}
          </div>
        </div>
      </Box>
      {/* <Box
        component="form"
        w={"100%"}
        onSubmit={form.onSubmit((e: ChangPassWordModel) => {
          //  handleEdit(e);
        })}
      >
        <Flex
          justify={"end"}
          w={"100%"}
          style={{
            borderBottom: "2px solid #E6E7EA",
          }}
        >
          <LoadingOverlay
            visible={visible}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <Button
            variant="filled"
            m={8}
            type="submit"
            leftSection={<IconEdit />}
          >
            Cập nhật
          </Button>
        </Flex>
        <Stack px={8} gap={16}>
          <Grid
            py={16}
            style={{
              borderBottom: "2px solid #E6E7EA",
            }}
          >
            <Grid.Col className="setting-header" span={{ sm: 12, md: 3 }}>
              <Flex gap={8} className="setting-header-title">
                <Title
                  order={4}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Đăng nhập
                </Title>
                <IconSparkles color="blue" />
              </Flex>
            </Grid.Col>
            <Grid.Col className="setting-main" span={{ sm: 12, md: 8 }}>
              <Stack gap={16}>
                <Flex gap={8}>
                  <Title order={6} fw={500}>
                    Phiên đăng nhập
                  </Title>
                  <IconSparkles color="blue" size={18} />
                </Flex>
                <Grid>
                  <Grid.Col span={{ base: 6 }}>
                    <InputLabel htmlFor="session_life_time">
                      Thời gian tồn tại của một phiên
                    </InputLabel>
                    <Flex gap={16} align={"center"}>
                      <NumberInput
                        size="sm"
                        name="session_life_time"
                        w={100}
                        {...form.getInputProps("timesession")}
                      />
                      <Text size="sm" color="gray">
                        phút(s)
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={{ base: 6 }}>
                    <InputLabel htmlFor="max_current_device_logon">
                      Số lượng thiết bị đăng nhập đồng thời
                    </InputLabel>
                    <NumberInput
                      size="sm"
                      name="max_current_device_logon"
                      {...form.getInputProps("numberdevice")}
                      w={100}
                    />
                  </Grid.Col>
                </Grid>
                <Flex gap={8}>
                  <Checkbox
                    name="approve_on_first_time_login"
                    //   checked={form.values.loginapprovefrist}
                    {...form.getInputProps("loginapprovefrist")}
                  />
                  <InputLabel htmlFor="approve_on_first_time_login">
                    Phê duyệt ở mọi lần đăng nhập
                  </InputLabel>
                </Flex>
                <Flex gap={8}>
                  <Title order={6} fw={500}>
                    Địa chỉ IP
                  </Title>
                  <IconSparkles color="blue" size={18} />
                </Flex>
                <TagsInput
                  value={ipWhiteList}
                  onChange={(e) => {
                    setIpWhiteList(e);
                  }}
                  // {...form.getInputProps("listipallow")}
                />
              </Stack>
            </Grid.Col>
          </Grid>
        </Stack>
      </Box> */}
    </>
  );
};

export default UserPageView;
