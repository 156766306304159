import axios from "axios";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";

export const exportDataToExcel = async (
  baseUrl: string,
  fileName: string,
  isToken?: boolean,
  repository?: any
) => {
  try {
    let response;

    if (!isToken && repository === undefined) {
      response = await axios.get(baseUrl, {
        responseType: "blob",
      });
    } else if (!isToken && repository) {
      response = await repository.get(baseUrl, {
        responseType: "blob",
      });
    }

    if (response) {
      const blob = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);

      NotificationExtension.Success("Export data thành công!");
    }
  } catch (error) {
    console.error(error);
    NotificationExtension.Fails("Export data thất bại!");
  }
};
