import {
  Badge,
  Box,
  Button,
  Group,
  Input,
  Menu,
  MultiSelect,
  MultiSelectProps,
  rem,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconBarcode,
  IconBuildingWarehouse,
  IconCalendar,
  IconCaretDown,
  IconFileExport,
  IconMapPin,
  IconSearch,
  IconSwitch,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { SelectListItemModel } from "../../../model/SelectListItem";
import { exportDataToExcel } from "../../../common/ExportExcelToAPI/ExportExcelToAPI";
import { green } from "../../../const/variables";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { useNavigate } from "react-router-dom";
import { MessageResponse } from "../../../model/MessageResponse";
import { useForm } from "@mantine/form";
import { search } from "../../Warehouse/WarehouseInwardNew/constants";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";
const SellOrderIinformation = () => {
  const navigate = useNavigate();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<InventoryItem[]>([]);
  const [rowCount, setRowCount] = useState(0);

  type InventoryItem = {
    id: string;
    soCt: string;
    ngayCt: string;
    ngayLct: string;
    maCty: string;
    maCt: string;
    maKh: string;
    tenKh: string;
    maHttt: string;
    tenHttt: string;
    httt: string;
    nguoiGd: string;
    maBp: string;
    tenBp: string;
    dienGiai: string | null;
    maTtPo: string;
    tenTtPo: string;
    hanCk: number;
    tlCk: number;
    tkCk: string;
    hanTt: number;
    tyGia: number;
    tTien0: number;
    tTienCkNt: number;
    tTienCk: number;
    tThue: number;
    tTienCkDh: number;
    tGg: number;
    tTt: number;
    tSoLuong: number;
    taoTuPdn: boolean;
    suaTien: boolean;
    suaTt: boolean;
    trangThai: string;
    tenTrangThai: string;
    duyet: string;
    nguoiDuyet: string | null;
    tenNguoiDuyet: string | null;
    ngayDuyet: string;
    nguoiHuyDuyet: string | null;
    tenNguoiHuyDuyet: string | null;
    ngayHuyDuyet: string;
  };
  const [search, setSearch] = useState({
    keySearch: "",
    SoCt: "",
    TrangThai: "",
    StartTime: "",
    EndTime: "",
  });
  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };
  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const handleCheckView = async (row: any) => {
    const url = `/api/v1/TblDonMuaHang/detail?SoCt=${row.original.soCt}`;
    const response = await repositoryMdm.get<MessageResponse<any>>(url);
    if (response && response.success) {
      navigate("/report/detail-order-information", {
        state: {
          data: response.data,
        },
      });
    }
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "soCt",
        header: "Số chứng từ",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Tooltip label="Xem chi tiết">
              <Badge
                variant="dot"
                radius="xs"
                onDoubleClick={() => handleCheckView(row)}
                style={{
                  cursor: "pointer",
                }}
                size="lg"
                color={renderedCellValue === null ? "red" : green.base}
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          </>
        ),
      },
      {
        accessorKey: "ngayCt",
        header: "Ngày chứng từ",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "ngayLct",
        header: "Ngày lập chứng từ",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {renderedCellValue &&
              moment(renderedCellValue).format("DD-MM-YYYY HH:mm:ss")}
          </>
        ),
      },
      {
        accessorKey: "maCty",
        header: "Mã công ty",
      },
      {
        accessorKey: "maCt",
        header: "Mã chứng từ",
      },
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.tenKh?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "maHttt",
        header: "Mã hình thức thanh toán",
      },
      {
        accessorKey: "tenHttt",
        header: "Tên hình thức thanh toán",
      },
      {
        accessorKey: "httt",
        header: "Hình thức thanh toán",
      },
      {
        accessorKey: "nguoiGd",
        header: "Người giao dịch",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.nguoiGd?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "maBp",
        header: "Mã bộ phận",
      },
      {
        accessorKey: "tenBp",
        header: "Tên bộ phận",
      },
      {
        accessorKey: "dienGiai",
        header: "Diễn giải",
      },
      {
        accessorKey: "maTtPo",
        header: "Mã thanh toán PO",
      },
      {
        accessorKey: "tenTtPo",
        header: "Tên thanh toán PO",
      },
      {
        accessorKey: "hanCk",
        header: "Hạn chiết khấu",
      },
      {
        accessorKey: "tlCk",
        header: "Tỷ lệ chiết khấu",
      },
      {
        accessorKey: "tkCk",
        header: "Tài khoản chiết khấu",
      },
      {
        accessorKey: "hanTt",
        header: "Hạn thanh toán",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tyGia",
        header: "Tỷ giá",
      },
      {
        accessorKey: "tTien0",
        header: "Tổng tiền gốc",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tTienCkNt",
        header: "Tổng tiền chiết khấu ngoại tệ",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tTienCk",
        header: "Tổng tiền chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tThue",
        header: "Tổng thuế",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tTienCkDh",
        header: "Tổng tiền chiết khấu đơn hàng",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tGg",
        header: "Tổng giảm giá",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tTt",
        header: "Tổng thanh toán",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tSoLuong",
        header: "Tổng số lượng",
      },
      {
        accessorKey: "taoTuPdn",
        header: "Tạo từ phiếu đề nghị",
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge
              size="sm"
              color={renderedCellValue === false ? "red" : "cyan"}
            >
              {renderedCellValue === false ? "KHôNG" : "CÓ"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "suaTien",
        header: "Sửa tiền",
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge
              size="sm"
              color={renderedCellValue === false ? "red" : "cyan"}
            >
              {renderedCellValue === false ? "KHôNG" : "CÓ"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "suaTt",
        header: "Sửa trạng thái",
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge
              size="sm"
              color={renderedCellValue === false ? "red" : "cyan"}
            >
              {renderedCellValue === false ? "KHôNG" : "CÓ"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tenTrangThai",
        header: "Trạng thái",
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge
              size="sm"
              color={renderedCellValue === "Hoàn thành" ? "green" : "yellow"}
            >
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "cuser",
        header: "Người tạo",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.cuser?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "cdate",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {renderedCellValue &&
              moment(renderedCellValue).format("DD-MM-YYYY HH:mm:ss")}
          </>
        ),
      },
      {
        accessorKey: "duyet",
        header: "Duyệt",
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge
              size="sm"
              color={renderedCellValue === "False" ? "red" : "cyan"}
            >
              {renderedCellValue === "False" ? "KHôNG" : "CÓ"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "nguoiDuyet",
        header: "Người duyệt",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.nguoiDuyet?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "tenNguoiDuyet",
        header: "Tên người duyệt",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.tenNguoiDuyet?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "ngayDuyet",
        header: "Ngày duyệt",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {renderedCellValue &&
              moment(renderedCellValue).format("DD-MM-YYYY HH:mm:ss")}
          </>
        ),
      },
      {
        accessorKey: "nguoiHuyDuyet",
        header: "Người hủy duyệt",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.nguoiHuyDuyet?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "tenNguoiHuyDuyet",
        header: "Tên người hủy duyệt",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils value={row.original.tenNguoiHuyDuyet?.split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "ngayHuyDuyet",
        header: "Ngày hủy duyệt",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {renderedCellValue &&
              moment(renderedCellValue).format("DD-MM-YYYY HH:mm:ss")}
          </>
        ),
      },
    ],
    [data]
  );
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    if (search.TrangThai) {
      url += `&TrangThai=${search.TrangThai.trim().toLowerCase()}`;
    }
    if (search.SoCt) {
      url += `&SoCt=${search.SoCt.trim().toLowerCase()}`;
    }
    if (search.StartTime) {
      url += `&StartTime=${search.StartTime}`;
    }
    if (search.EndTime) {
      url += `&EndTime=${search.EndTime}`;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblDonMuaHang/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      } else {
        setData([]);
        setRowCount(0);
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    rowCount,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,

    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["soCt"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập từ khóa"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) =>
              handleChangeSearchValue(e.target.value ?? "", "keySearch")
            }
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập số chứng từ "
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) =>
              handleChangeSearchValue(e.target.value ?? "", "SoCt")
            }
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />
          <Select
            size="sm"
            placeholder="Trạng thái"
            searchable
            clearable
            data={[
              { value: "2", label: "Hoàn thành" },
              { value: "1", label: "Thực hiện" },
            ]}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconSwitch size={20} color="#15aabf" />}
            // key={formSeachInput.key("TrangThai")}
            // {...formSeachInput.getInputProps("TrangThai")}
            onChange={(value) =>
              handleChangeSearchValue(value ?? "", "TrangThai")
            }
          />
          <DatePickerInput
            type="range"
            size="sm"
            placeholder="Từ ngày - Đến ngày"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            onChange={(e) => {
              handleChangeSearchValue(
                formatDateNotTimeZone(e[0]) ?? "",
                "StartTime"
              );
              handleChangeSearchValue(
                formatDateNotTimeZone(e[1]) ?? "",
                "EndTime"
              );
            }}
            leftSection={<IconCalendar color="#15aabf" />}
            w={250}
            clearable
          />
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);
  useEffect(() => {
    fetchData();
  }, [pagination]);
  return (
    <Box>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default SellOrderIinformation;
