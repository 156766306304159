import {
  Badge,
  Box,
  Button,
  Input,
  Menu,
  rem,
  Select,
  Text,
} from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import {
  IconCaretDown,
  IconFileExport,
  IconMapPin,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { BASE_API_MDM } from "../../../../config";
import { _localization } from "../../../../config/location";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { ItemDataList } from "../../../../model/TblOrderHeader";

// hàm này để cho chọn ngày đúng , chứ không khi chọn sẽ bị lùi 1 ngày so với ngày chọn
const addOneDay = (isoDate: string | null) => {
  if (!isoDate) return "";
  // Tạo đối tượng Date từ chuỗi ISO
  const date = new Date(isoDate);
  // Thêm 1 ngày vào đối tượng Date
  date.setDate(date.getDate() + 1);
  // Trả về chuỗi ngày giờ theo chuẩn ISO
  return date.toISOString().slice(0, -3);
};
const ProductPLReport = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<ItemDataList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //chi nhánh
  const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
    []
  );
  //Kho
  const [dataInventory, setDataInventory] = useState<SelectListItemModel[]>([]);
  //Bộ phận
  const [dataDepartment, setDataDepartment] = useState<SelectListItemModel[]>(
    []
  );
  //Khách hàng
  const [dataCustomer, setDataCustomer] = useState<SelectListItemModel[]>([]);
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
    },
  });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [search, setSearch] = useState({
    branchCode: [], //chi nhánh
  });
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };
  const DataBranchCode = async () => {
    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmInventory/get-select-branch?auth=true`
      );
      if (response && response.success && response.data) {
        const transformedData = response.data
          .filter(
            (branch: any) =>
              branch.att2 !== null &&
              branch.text !== "Tất cả" &&
              branch.value !== null
          )
          .map((branch: any) => ({
            value: branch.value,
            label: branch.att2 + " - " + branch.text,
          }));

        setDataBranchCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }

    if (search.branchCode && search.branchCode.length > 0) {
      search.branchCode.forEach((branchCode) => {
        url += `&OrgId=${branchCode}`;
      });
    }
    // if (search.KhoIds && search.KhoIds.length > 0) {
    //   search.KhoIds.forEach((KhoIds) => {
    //     url += `&MaNhomVt=${KhoIds}`;
    //   });
    // }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/report-profit-loss-by-item-and-branch${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  const exportDataExcel = async () => {
    const url = `/api/v1/ExportExcel/export-bao-cao-ban-hang-theo-san-pham-va-chi-nhanh`;

    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename =
      "bao_cao_lai_lo_theo_san_pham_va_chi_nhanh" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  const columns = React.useMemo<MRT_ColumnDef<ItemDataList>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "maVt",
        header: "Mã vật tư",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tenVt",
        header: "Tên vật tư",
        size: 400,
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="violet">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "maBp",
        header: "Mã bộ phận",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tenBp",
        header: "Tên bộ phận",
        size: 400,
      },
      {
        accessorKey: "maNhomVt",
        header: "Mã nhóm vật tư",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tenNhomVt",
        header: "Tên nhóm vật tư",
        size: 400,
      },
      {
        accessorKey: "soLuongHd",
        header: "Số lượng",
        footer: "Tổng doanh thu:",
        Footer(props) {
          return (
            <div>
              <Text c="red" fw={800}>
                {props.column.columnDef.footer}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tongDoanhThu",
        header: "Tổng doanh thu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        Footer: ({ table }: any) => {
          const total = table
            .getRowModel()
            .rows.reduce(
              (sum: number, row: any) => sum + (row.original.tongDoanhThu || 0),
              0
            );
          return (
            <div>
              <Text c="red" fw={800}>
                {formatCurrencyVND(total)}
              </Text>
            </div>
          );
        },
      },
      {
        accessorKey: "tongCk",
        header: "Tổng chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tongSauCk",
        header: "Tổng sau chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "giaVon",
        header: "Giá vốn",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },

      // {
      //   accessorKey: "fx",
      //   header: "Tỷ lệ",
      //   Cell: ({ row }: any) => {
      //     const value = row.original.fx;
      //     if (value != null) {
      //       const formattedValue = (value * 100).toFixed(2) + "%";
      //       return <Text>{formattedValue}</Text>;
      //     }
      //     return null;
      //   },
      // },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableColumnResizing: true,

    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableStickyFooter: true,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "itemCode"],
        // right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 550,
        fontSize: "12.5px",
        padding: "10px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  // Lấy danh sách mã chi nhánh
  // Lấy danh sách mã bộ phận
  //   const fetchDataBp = async (Cnids: string[]) => {
  //     try {
  //       if (Array.isArray(Cnids) && Cnids.length > 0) {
  //         const response = await repositoryPos.get(
  //           `/api/v1/TblDepartment/get-all?branchId=${Cnids}`
  //         );
  //         if (response && response.httpStatusCode === 200) {
  //           const transformedData = response.data.map((item: any) => ({
  //             value: String(item.departmentId),
  //             label: item.departmentId + " - " + item.name,
  //           }));
  //           setDataDepartment(transformedData);
  //         }
  //       } else {
  //         const response = await repositoryPos.get(
  //           `/api/v1/TblDepartment/get-all`
  //         );
  //         if (response && response.httpStatusCode === 200) {
  //           const transformedData = response.data.map((item: any) => ({
  //             value: String(item.departmentId),
  //             label: item.departmentId + " - " + item.name,
  //           }));
  //           setDataDepartment(transformedData);
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       return;
  //     }
  //   };
  // Lấy danh sách mã khách hàng

  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (160 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: "10px",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
            marginTop: "10px",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Từ khoá"
            type="text"
            leftSection={<IconSearch color="#15aabf" />}
            key={formSeachInput.key("keySearch")}
            {...formSeachInput.getInputProps("keySearch")}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                fetchData();
              }
            }}
          />
          <Select
            size="sm"
            placeholder="Lọc theo chi nhánh"
            searchable
            clearable
            data={dataBranchCode}
            leftSection={<IconMapPin size={20} color="#15aabf" />}
            onClick={() => {
              if (dataBranchCode.length === 0) {
                DataBranchCode();
              }
            }}
            onChange={(value) =>
              handleChangeSearchValue(value ? [value] : null, "branchCode")
            }
          />
          <Button
            type="submit"
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Chức năng
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Đóng
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ProductPLReport;
