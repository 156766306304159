import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Select,
  SelectProps,
  Title,
  Tooltip,
  rem,
  Text,
  ComboboxItem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  IconBrandBootstrap,
  IconCalendar,
  IconCheck,
  IconEdit,
  IconEye,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconTransferIn,
  IconTransferOut,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { green } from "../../../../const/variables";
import { WarrantyBorrowTicket } from "../../../../model/TblWarrantyItemBorrowTicket";
import { MessageResponse } from "../../../../model/MessageResponse";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";
import { AvatarUtils } from "../../../../common/ColorByName/AvatarUtils";
import ModalCheckSerial from "./ModalCheckSerial";
import { getBranchSelectMdm } from "../../../../service/getSelectApi";

function getColorStatus(text: any) {
  switch (text) {
    case "Yêu cầu xuất mượn":
      return "blue";
    case "Đã thu tiền":
      return "teal";
    case "Đã xuất kho":
      return "orange";
    case "Đã nhập về":
      return "green";
    case "Đã chi":
      return "yellow";
    default:
      return "gray";
  }
}

const dataStatusOption = [
  { value: "YEU_CAU_XUAT_MUON", label: "Yêu cầu xuất mượn" },
  { value: "DA_THU", label: "Đã thu tiền" },
  { value: "DA_XUAT", label: "Đã xuất kho" },
  { value: "DA_NHAP_VE", label: "Đã nhập về" },
  { value: "DA_CHI", label: "Đã chi" },
];
const renderSelectOptionStatus: SelectProps["renderOption"] = ({
  option,
  checked,
}) => (
  <Group flex="1" gap="xs">
    <Badge color={getColorStatus(option.label)} radius="sm" variant="filled">
      {option.label}
    </Badge>
    {checked && (
      <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
    )}
  </Group>
);
// hàm này để cho chọn ngày đúng , chứ không khi chọn sẽ bị lùi 1 ngày so với ngày chọn
const addOneDay = (isoDate: string | null) => {
  if (!isoDate) return "";
  // Tạo đối tượng Date từ chuỗi ISO
  const date = new Date(isoDate);
  // Thêm 1 ngày vào đối tượng Date
  date.setDate(date.getDate() + 1);
  // Trả về chuỗi ngày giờ theo chuẩn ISO
  return date.toISOString().slice(0, -3);
};
const DMBorrwedGoods = () => {
  const navigate = useNavigate();
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<WarrantyBorrowTicket[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [status, setStatus] = useState<number | null>(null); // Lưu trữ status
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);

  const [itemTransactionHeaderCommand, setItemTransactionHeaderCommand] =
    useState<any>({
      transactionId: null,
      transactionCode: null,
      transactionDate: new Date().toISOString(),
      subSourceId: null,
      sourceCode: null,
      sourceType: null,
      branchId: null,
      subInvId: null,
      departmentId: null,
      status: null,
      description: null,
      parentSerial: null,
      atribute1: null,
      atribute2: null,
      atribute3: null,
      atribute4: null,
      atribute5: null,
      atribute6: null,
      atribute7: null,
      atribute8: null,
      atribute9: null,
      atribute10: null,
      createBy: null,
      createDate: new Date().toISOString(),
      lastUpdateDate: new Date().toISOString(),
      lastUpdateBy: null,
      type: null,
      finishDate: new Date().toISOString(),
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      attribute16: null,
      attribute17: null,
      attribute18: null,
      attribute19: null,
      attribute20: null,
    });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        size: 30,
        Cell: ({ row }) => (
          <>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {row.index + 1}
            </span>
          </>
        ),
        enableResizing: false,
      },
      {
        accessorKey: "borrowCode",
        header: "Số phiếu yêu cầu hàng mượn",
        size: 200,
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            <Tooltip label="Xem chi tiết">
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                style={{ cursor: "pointer" }}
                color={renderedCellValue === null ? "red" : green.base}
                onDoubleClick={() =>
                  detailItem(row.original.id!, row.original.status)
                }
              >
                {renderedCellValue === null
                  ? "Không xác định"
                  : renderedCellValue}
              </Badge>
            </Tooltip>
          </>
        ),
      },
      {
        accessorKey: "receiveTicketCode",
        header: "Số phiếu tiếp nhận hàng lỗi",
        Cell: ({ renderedCellValue }: any) => (
          <>
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue === null
                ? "Không xác định"
                : renderedCellValue}
            </Badge>
          </>
        ),
        size: 200,
      },
      {
        accessorKey: "exTransactionCode",
        header: "Số phiếu xuất hàng mượn",
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue === null
                ? "Chưa lập phiếu"
                : renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "imTransactionCode",
        header: "Số phiếu nhập hàng mượn",
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue === null
                ? "Chưa lập phiếu"
                : renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {dataStatusOption.map(
              (item: any, index: number) =>
                item.value == renderedCellValue && (
                  <Badge
                    key={index}
                    color={getColorStatus(item.label)}
                    radius="sm"
                    variant="filled"
                  >
                    {item.label}
                  </Badge>
                )
            )}
          </>
        ),
      },

      {
        accessorKey: "totalDeposit",
        header: "Tiền cọc",
        Cell: ({ renderedCellValue, row }: any) => (
          <Text size="12.5px" fw={500}>
            {renderedCellValue?.toLocaleString("vi-VN")} VNĐ
          </Text>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue, row }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "cusName",
        header: "Tên khách hàng",
        Cell: ({ row }) => <AvatarUtils value={row.original.cusName} />,
        size: 225,
      },
      {
        accessorKey: "cusPhoneNumber",
        header: "Số điện thoại",
      },
      {
        accessorKey: "cusAddress",
        header: "Địa chỉ",
        size: 550,
      },
      {
        accessorKey: "inventoryName",
        header: "Kho",
      },
      {
        accessorKey: "createByName",
        header: "Người lập",
        Cell: ({ row }) => <AvatarUtils value={row.original.createByName} />,
        size: 225,
      },

      {
        accessorKey: "note",
        header: "Ghi chú",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableResizing: false,
        size: 130,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id, row.original.status ?? "");
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            {row.original.status === "YEU_CAU_XUAT_MUON" ? (
              <>
                <Tooltip label="Sửa phiếu">
                  <ActionIcon
                    variant="light"
                    color="yellow"
                    onClick={async () => {
                      editItem(row.original.id);
                    }}
                  >
                    <IconEdit size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            ) : (
              ""
            )}

            {(row.original.status === "DA_THU" ||
              row.original.totalDeposit === 0) &&
            row.original.status !== "DA_XUAT" &&
            row.original.status !== "DA_NHAP_VE" &&
            row.original.status !== "DA_CHI" ? (
              <>
                <Tooltip label="Xuất kho">
                  <ActionIcon
                    variant="light"
                    color="orange"
                    onClick={() => {
                      modalOutWard(
                        row.index,
                        row.original.borrowCode,
                        row.original.branchId,
                        row.original.inventoryId,
                        row.original
                          ?.tblWarrantyExportItemBorrowTicketDetailModels
                      );
                    }}
                  >
                    <IconTransferIn size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            ) : (
              <></>
            )}

            {row.original.status === "DA_XUAT" && (
              <>
                <Tooltip label="Nhập hàng">
                  <ActionIcon
                    variant="light"
                    color="violet"
                    onClick={() =>
                      modalInWard(
                        row.index,
                        row.original.borrowCode,
                        row.original.branchId,
                        row.original.inventoryId,
                        row.original
                          ?.tblWarrantyExportItemBorrowTicketDetailModels
                      )
                    }
                  >
                    <IconTransferOut size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
          </Box>
        ),
      },
    ],
    [data]
  );

  // form search
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      startDate: "",
      endDate: "",
      status: "",
      branch: "",
    },
  });
  // navigate to detail
  const detailItem = (id: string | number, status: string) => {
    navigate("/warranty/detail-borrowed-goods", {
      state: { id, status },
    });
  };

  const editItem = (id: string | number) => {
    navigate("/warranty/edit-borrowed-goods", {
      state: {
        idPXM: id,
      },
    });
  };

  const onhandleCreate = () => {
    navigate("/warranty/create-borrowed-goods");
  };

  const modalOutWard = (
    index: number,
    sourceCode: string,
    branchId: number,
    subInvId: number,
    listSerial: any[]
  ) => {
    modals.openConfirmModal({
      title: <Title order={5}>Xuất kho hàng mượn bảo hành</Title>,
      size: "auto",
      children: (
        <Box mt={10}>
          <Text size="16px" fw={500}>
            Bạn chắc chắn muốn xuất kho những sản phẩm này ?
          </Text>
          <Flex w={"100%"} justify={"end"} mt={20}>
            <Button
              color="teal"
              leftSection={<IconCheck size={"14px"} />}
              onClick={() => {
                handleOutWard(
                  index,
                  sourceCode,
                  branchId,
                  subInvId,
                  listSerial
                );
              }}
            >
              Xác nhận
            </Button>
          </Flex>
        </Box>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const modalInWard = (
    index: number,
    sourceCode: string,
    branchId: number,
    subInvId: number,
    listSerial: any[]
  ) => {
    modals.openConfirmModal({
      title: <Title order={5}>Nhập kho hàng mượn bảo hành</Title>,
      size: "auto",
      children: (
        <ModalCheckSerial
          index={index}
          sourceCode={sourceCode}
          branchId={branchId}
          subInvId={subInvId}
          listSerial={listSerial}
          handleInWard={handleInWard}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }
    if (formSeachInput.getValues().startDate) {
      url += `&StartDate=${formSeachInput.getValues().startDate}`;
    }

    if (formSeachInput.getValues().endDate) {
      url += `&EndDate=${formSeachInput.getValues().endDate}`;
    }
    if (formSeachInput.getValues().status) {
      url += `&Status=${formSeachInput.getValues().status}`;
    }
    if (formSeachInput.getValues().branch) {
      url += `&BranchId=${formSeachInput.getValues().branch}`;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyExportItemBorrowTicket/get-list${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setIsLoading(false);
        setRowCount(result.length);
        setData(result);
        Promise.all([getDataBranchSelect()]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
      setIsLoading(false);
    }
  };

  const getDataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataBranchSelect(
      getData
        ?.filter(
          (item) =>
            item.value != null && item.text != null && item.value != "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const handleOutWard = async (
    index: number,
    sourceCode: string,
    branchId: number,
    subInvId: number,
    listSerial: any[]
  ) => {
    const res = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Outward/create-warranty-borrow-outward",
      {
        // sourceId: null,
        tblItemTransactionHeaderCommand: {
          ...itemTransactionHeaderCommand,
          sourceType: "XUAT_MUON_BAO_HANH",
          sourceCode: sourceCode,
          branchId: branchId,
          subInvId: subInvId,
        },
        serials: listSerial
          ?.filter((item) => item.type === false)
          ?.map((item) => ({
            primaryQuantity: 1,
            subQuantity: 1,
            serial: item.serial,
            invId: subInvId,
            locatorId: item.positionid,
          })),
        isLock: true,
        isAuto: true,
      }
    );

    if (res && res?.success) {
      NotificationExtension.Success("Xuất kho hàng mượn thành công !");
      setTimeout(() => {
        setDeleteViewStatus(!deleteViewStatus);
        modals.closeAll();
      }, 500);
    }
  };

  const handleInWard = async (
    index: number,
    sourceCode: string,
    branchId: number,
    subInvId: number,
    listSerial: any[]
  ) => {
    const res = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Inward/create-in-ward-warranty-borrow",
      {
        sourceId: null,
        tblItemTransactionHeaderCommand: {
          ...itemTransactionHeaderCommand,
          sourceType: "NHAP_MUON_BAO_HANH",
          sourceCode: sourceCode,
          branchId: branchId,
          subInvId: subInvId,
        },
        serials: listSerial
          ?.filter((item) => item.type === false)
          ?.map((item) => ({
            primaryQuantity: 1,
            subQuantity: 1,
            serial: item.serial,
            invId: subInvId,
            locatorId: item.positionid,
          })),
        isLock: true,
      }
    );

    if (res && res?.success) {
      NotificationExtension.Success("Nhập kho hàng mượn thành công !");
      setTimeout(() => {
        setDeleteViewStatus(!deleteViewStatus);
        modals.closeAll();
      }, 500);
    }
  };

  const table = useMantineReactTable({
    columns,
    data: data ? data : [],
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id?.toString(),
    renderTopToolbarCustomActions: ({ row }: any) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} w={"100%"} wrap={"wrap"}>
          <BreadcrumbLayout1></BreadcrumbLayout1>
          <Button
            leftSection={
              <IconPlus style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => onhandleCreate()}
          >
            Thêm mới
          </Button>
        </Flex>
        <form
          onSubmit={formSeachInput.onSubmit(async () => await fetchData())}
          style={{ flex: 1 }}
        >
          <Grid mt={10}>
            <GridCol span={{ base: 12, md: 6, lg: 2 }}>
              <Input
                w={"100%"}
                placeholder="Từ khoá"
                type="text"
                leftSection={<IconSearch color="#15aabf" />}
                key={formSeachInput.key("keySearch")}
                {...formSeachInput.getInputProps("keySearch")}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await fetchData();
                  }
                }}
              />
            </GridCol>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <DatePickerInput
                size="sm"
                placeholder="Từ ngày"
                leftSection={<IconCalendar color="#15aabf" />}
                locale="vi"
                valueFormat="DD/MM/YYYY"
                onChange={(e) => {
                  formSeachInput.setValues((prev) => ({
                    ...prev,
                    startDate: formatDateNotTimeZone(e) ?? "",
                  }));
                }}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <DatePickerInput
                size="sm"
                placeholder="Đến ngày"
                leftSection={<IconCalendar color="#15aabf" />}
                locale="vi"
                valueFormat="DD/MM/YYYY"
                onChange={(e) => {
                  formSeachInput.setValues((prev) => ({
                    ...prev,
                    endDate: formatDateNotTimeZone(e) ?? "",
                  }));
                }}
                clearable
              />
            </Grid.Col>
            <GridCol span={{ base: 12, md: 6, lg: 2 }}>
              <Select
                placeholder={"Trạng thái"}
                searchable
                clearable
                data={dataStatusOption}
                leftSection={<IconStatusChange color="#15aabf" />}
                comboboxProps={{
                  transitionProps: { transition: "pop", duration: 200 },
                }}
                renderOption={renderSelectOptionStatus}
                nothingFoundMessage={"Không có dữ liệu"}
                key={formSeachInput.key("status")}
                {...formSeachInput.getInputProps("status")}
              />
            </GridCol>
            <GridCol span={{ base: 12, md: 6, lg: 2 }}>
              <Select
                placeholder="Chi nhánh"
                searchable
                clearable
                leftSection={<IconBrandBootstrap color="#15aabf" />}
                data={dataBranchSelect}
                key={formSeachInput.key("branch")}
                {...formSeachInput.getInputProps("branch")}
              />
            </GridCol>
            <GridCol span={{ base: 12, md: 6, lg: 2 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                type="submit"
              >
                Tìm kiếm
              </Button>
            </GridCol>
          </Grid>
        </form>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "borrowCode"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 500,
        fontSize: "12.5px",
        padding: "5px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    fetchData();
  }, [pagination?.pageIndex, pagination?.pageSize, status, deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (195 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  return (
    <>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DMBorrwedGoods;
