import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Menu,
  Modal,
  rem,
  Select,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedState } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconBrandBootstrap,
  IconBrandGrammarly,
  IconBuildingSkyscraper,
  IconBuildingWarehouse,
  IconCalendar,
  IconCaretDown,
  IconChartCandle,
  IconCubeSend,
  IconDots,
  IconFileExport,
  IconFileUpload,
  IconSearch,
  IconTagStarred,
  IconTransferIn,
  IconTransferOut,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  _timerDelayClick,
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import {
  formatDateNotTimeZone,
  formatDateTime,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { green } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { DefectiveItem } from "../../../model/TblFaultyItemList";
import "../../../Styles/tab.component.css";
import InWardWarrantyVendor from "./InWardWarrantyVendor";
import OutWardWarrantyVendor from "./OutWardWarrantyVendor";
import ReturnCustomers from "./ReturnCustomers";
import {
  getBranchSelectMdm,
  getTblDMInventory,
} from "../../../service/getSelectApi";
import { BASE_API_DELIVERY } from "../../../config";
import UpdateWarrantyTerm from "./UpdateWarrantyTerm";

const DefectiveGoodsListV2 = () => {
  const navigate = useNavigate();
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [dataTemp, setDataTemp] = useState<DefectiveItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataVendorSelect, setDataVendorSelect] = useState<ComboboxItem[]>([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataInventorySelect, setDataInventorySelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataItemStatusSelect] = useState<any[]>([
    { label: "Test lỗi", value: "Test lỗi" },
    { label: "Đang gửi NCC", value: "Đang gửi nhà cung cấp" },
    { label: "Chờ test", value: "Chờ test" },
    { label: "Đổi mã", value: "Đổi mã" },
    { label: "Test OK", value: "Test OK" },
    // { label: "Gửi lại NCC", value: "Gửi lại nhà cung cấp" },
    { label: "Trả khách", value: "Trả khách" },
  ]);
  const [dataDebtStatusSelect] = useState<any[]>([
    { label: "Nợ khách", value: "Nợ khách" },
    { label: "Đã trả khách", value: "Đã trả khách" },
    { label: "Nợ hàng kho", value: "Nợ hàng kho" },
    { label: "Đã trả hàng kho", value: "Đã trả hàng kho" },
    { label: "NCC nợ", value: "NCC nợ" },
    { label: "Đã nhập từ NCC", value: "Đã nhập từ NCC" },
  ]);
  const [selectStatusSerial, setSelectStatusSerial] = useState<string | null>(
    null
  );
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [isReturnedBorrowGoods, setIsReturnedBorrowGoods] = useState<any>(null);
  const [listErrorNum, setListErrorNum] = useState<any[]>([]);
  const [sourceCodeExport, setSourceCodeExport] = useState();
  const [dataRowSelect, setDataRowSelect] = useState<any[]>([]);
  const [dataRowProps, setDataRowProps] = useState<any[]>([]);
  const [tblItemTransactionHeaderCommand, setTblItemTransactionHeaderCommand] =
    useState({
      transactionId: null,
      transactionCode: null,
      transactionDate: new Date().toISOString(),
      subSourceId: null,
      // sourceCode: `XBHN${new Date().getDate()}.${new Date().getMonth()}.${new Date()
      //   .getFullYear()
      //   .toString()
      //   .slice(-2)}.${Math.floor(100000 + Math.random() * 900000)}`,
      sourceCode: null,
      sourceType: null,
      branchId: null,
      subInvId: null,
      departmentId: null,
      status: null,
      description: null,
      parentSerial: null,
      atribute1: null,
      atribute2: null,
      atribute3: null,
      atribute4: null,
      atribute5: null,
      atribute6: null,
      atribute7: null,
      atribute8: null,
      atribute9: null,
      atribute10: null,
      createBy: null,
      createDate: new Date().toISOString(),
      lastUpdateDate: new Date().toISOString(),
      lastUpdateBy: null,
      type: null,
      finishDate: new Date().toISOString(),
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      attribute16: null,
      attribute17: null,
      attribute18: null,
      attribute19: null,
      attribute20: null,
    });
  const [navigateInWard, setNavigateInWard] = useState(false);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    vendorName: "",
    itemStatus: "",
    debtStatus: "",
    serialStatus: "",
    branch: "",
    inv: "",
  });

  const detailDataOut = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean
  ) => {
    navigate("/warehouse-config/detail-warehouse-out-ward", {
      state: { transactionCode: id, type: type, isLocked: isLocked },
    });
  };

  const detailDataIn = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean
  ) => {
    navigate("/warehouse-config/detail-warehouse-in-ward", {
      state: { transactionCode: id, sourceType: type, isLocked: isLocked },
    });
  };

  const [keySearch, setKeySearch] = useDebouncedState("", 500);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "scheduleTime",
        header: "Ngày hẹn trả",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 125,
        Cell: ({ renderedCellValue, row }: any) => (
          <Badge
            radius={"sm"}
            color={
              row.original.soSerialStatus !== "Trả khách"
                ? renderedCellValue &&
                  !isNaN(new Date(renderedCellValue).getTime()) &&
                  (new Date(renderedCellValue).getTime() -
                    new Date().getTime()) /
                    (1000 * 60 * 60 * 24) <
                    3 &&
                  (new Date(renderedCellValue).getTime() -
                    new Date().getTime()) /
                    (1000 * 60 * 60 * 24) >
                    0
                  ? "yellow"
                  : (new Date(renderedCellValue).getTime() -
                      new Date().getTime()) /
                      (1000 * 60 * 60 * 24) <
                      0 && renderedCellValue !== null
                  ? "red"
                  : renderedCellValue === null
                  ? "rgba(0, 0, 0, 0)"
                  : "blue"
                : "green"
            }
          >
            {renderedCellValue && formatDateTime(renderedCellValue)}
          </Badge>
        ),
      },
      {
        accessorKey: "soItemCode",
        header: "Mã sản phẩm",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }: any) =>
          renderedCellValue === null ? null : (
            <Badge variant="light">{renderedCellValue}</Badge>
          ),
        size: 140,
      },
      {
        accessorKey: "soSerial",
        header: "Mã serial",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "newSerial",
        header: "Serial đổi mới",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "soItemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {row.original.itemName}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 425,
      },
      {
        accessorKey: "soQuantity",
        header: "Số lượng",
        size: 100,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "soSerialStatus",
        header: "Trạng thái serial",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 150,
      },
      {
        accessorKey: "soPositionCode",
        header: "Vị trí kho",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "subInvName",
        header: "Kho",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.original.subInvCode} - {row.original.subInvName}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 250,
      },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 175,
      },
      {
        accessorKey: "custPhone",
        header: "Số điện thoại",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 125,
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ khách hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 475,
      },
      {
        accessorKey: "iwCode",
        header: "Mã phiếu nhập kho BH",
        Cell: ({ cell, renderedCellValue }) => {
          const [opened, setOpened] = useState(false);
          const [searchTerm, setSearchTerm] = useState("");
          const values = renderedCellValue
            ? renderedCellValue
                .toString()
                .trim()
                .replace(/^,/, "")
                .split(",")
                .map((value) => value.trim())
            : [];

          const filteredValues = values.filter((value) =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
          );

          return (
            <>
              <Box display="flex">
                <Flex align="center" variant="filled" gap={5}>
                  {values.slice(0, 1).map((value, index) => (
                    <Badge
                      variant="dot"
                      color={value === null ? "red" : green.base}
                      key={index}
                      size="lg"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataIn(
                          value,
                          "NHAP_KHO_BAO_HANH",
                          true // status === "LOCK"
                        );
                      }}
                      fw={600}
                    >
                      {value === null ? "Chưa nhập kho" : value}
                    </Badge>
                  ))}
                  {values.length > 1 && (
                    <ActionIcon
                      onClick={() => setOpened(true)}
                      color="blue"
                      radius="xl"
                      variant="light"
                      aria-label="Settings"
                    >
                      <IconDots size="1.2rem" />
                    </ActionIcon>
                  )}
                </Flex>
              </Box>
              <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="550"
                title={<Title order={5}>Danh sách mã phiếu</Title>}
              >
                <TextInput
                  placeholder="Tìm kiếm mã phiếu..."
                  value={searchTerm}
                  radius="xl"
                  mt={10}
                  leftSection={<IconSearch size="1rem" />}
                  onChange={(event) => setSearchTerm(event.currentTarget.value)}
                  mb={10}
                  w={170}
                />
                <Flex gap={10} mt={20} wrap="wrap">
                  {filteredValues.map((value, index) => (
                    <Badge
                      size="lg"
                      variant="dot"
                      color={value === null ? "red" : green.base}
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataIn(value, "NHAP_KHO_BAO_HANH", true);
                        setOpened(false);
                      }}
                      fw={600}
                    >
                      {value === null ? "Chưa nhập kho" : value}
                    </Badge>
                  ))}
                  {filteredValues.length === 0 && (
                    <Text>Không tìm thấy kết quả</Text>
                  )}
                </Flex>
              </Modal>
            </>
          );
        },
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "owReturnCode",
        header: "Mã phiếu xuất kho BH",
        Cell: ({ cell, renderedCellValue }) => {
          const [opened, setOpened] = useState(false);
          const [searchTerm, setSearchTerm] = useState("");
          const values = renderedCellValue
            ? renderedCellValue
                .toString()
                .trim()
                .replace(/^,/, "")
                .split(",")
                .map((value) => value.trim())
            : [];

          const filteredValues = values.filter((value) =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
          );

          return (
            <>
              <Box display="flex">
                <Flex align="center" variant="filled" gap={5}>
                  {values.slice(0, 1).map((value, index) => (
                    <Badge
                      variant="dot"
                      color={value === null ? "red" : green.base}
                      key={index}
                      size="lg"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataOut(
                          value,
                          "XUAT_KHO_BAO_HANH",
                          true // status === "LOCK"
                        );
                      }}
                      fw={600}
                    >
                      {value === null ? "Chưa nhập kho" : value}
                    </Badge>
                  ))}
                  {values.length > 1 && (
                    <ActionIcon
                      onClick={() => setOpened(true)}
                      color="blue"
                      radius="xl"
                      variant="light"
                      aria-label="Settings"
                    >
                      <IconDots size="1.2rem" />
                    </ActionIcon>
                  )}
                </Flex>
              </Box>
              <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="550"
                title={<Title order={5}>Danh sách mã phiếu</Title>}
              >
                <TextInput
                  placeholder="Tìm kiếm mã phiếu..."
                  value={searchTerm}
                  radius="xl"
                  mt={10}
                  leftSection={<IconSearch size="1rem" />}
                  onChange={(event) => setSearchTerm(event.currentTarget.value)}
                  mb={10}
                  w={170}
                />
                <Flex gap={10} mt={20} wrap="wrap">
                  {filteredValues.map((value, index) => (
                    <Badge
                      size="lg"
                      variant="dot"
                      color={value === null ? "red" : green.base}
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataOut(value, "XUAT_KHO_BAO_HANH", true);
                        setOpened(false);
                      }}
                      fw={600}
                    >
                      {value === null ? "Chưa nhập kho" : value}
                    </Badge>
                  ))}
                  {filteredValues.length === 0 && (
                    <Text>Không tìm thấy kết quả</Text>
                  )}
                </Flex>
              </Modal>
            </>
          );
        },
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "receiveTicketCode",
        header: "Mã phiếu tiếp nhận hàng lỗi",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Tooltip label="Xem chi tiết">
              <Badge
                radius="xl"
                variant="dot"
                size="lg"
                fw={500}
                color={renderedCellValue === null ? "red" : green.base}
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          ) : (
            <></>
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 230,
      },
      {
        accessorKey: "owWarrantyCode",
        header: "Mã phiếu xuất kho BH NCC",
        Cell: ({ cell, renderedCellValue }) => {
          const [opened, setOpened] = useState(false);
          const [searchTerm, setSearchTerm] = useState("");
          const values = renderedCellValue
            ? renderedCellValue
                .toString()
                .split(",")
                .map((value) => value.trim())
            : [];

          const filteredValues = values.filter((value) =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
          );

          return (
            <>
              <Box display="flex">
                <Flex align="center" variant="filled" gap={5}>
                  {renderedCellValue ? (
                    <>
                      {values.slice(0, 1).map((value, index) => (
                        <Badge
                          key={index}
                          size="lg"
                          variant="dot"
                          color={value === null ? "red" : green.base}
                          style={{ cursor: "pointer" }}
                          onClick={async () => {
                            detailDataOut(
                              value,
                              "XUAT_KHO_BAO_HANH_NCC",
                              true // status === "LOCK"
                            );
                          }}
                          fw={600}
                        >
                          {value === null ? "Chưa xuất kho" : value}
                        </Badge>
                      ))}
                      {values.length > 1 && (
                        <ActionIcon
                          onClick={() => setOpened(true)}
                          color="blue"
                          radius="xl"
                          variant="light"
                          aria-label="Settings"
                        >
                          <IconDots size="1.2rem" />
                        </ActionIcon>
                      )}
                    </>
                  ) : (
                    <Badge variant="dot" color="red">
                      Chưa xuất kho
                    </Badge>
                  )}
                </Flex>
              </Box>
              <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="550"
                title={<Title order={5}>Danh sách mã phiếu </Title>}
              >
                <TextInput
                  placeholder="Tìm kiếm mã phiếu..."
                  value={searchTerm}
                  radius="xl"
                  mt={10}
                  leftSection={<IconSearch size="1rem" />}
                  onChange={(event) => setSearchTerm(event.currentTarget.value)}
                  mb={10}
                  w={170}
                />
                <Flex gap={10} mt={20} wrap="wrap">
                  {filteredValues.map((value, index) => (
                    <Badge
                      size="lg"
                      key={index}
                      variant="dot"
                      color={value === null ? "red" : green.base}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataOut(value, "XUAT_KHO_BAO_HANH_NCC", true);
                        setOpened(false);
                      }}
                      fw={600}
                    >
                      {value === null ? "Chưa xuất kho" : value}
                    </Badge>
                  ))}
                  {filteredValues.length === 0 && (
                    <Text>Không tìm thấy kết quả</Text>
                  )}
                </Flex>
              </Modal>
            </>
          );
        },
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 200,
      },
      {
        accessorKey: "attribute21",
        header: "Mã phiếu nhập từ NCC",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue === null
                ? "Chưa xuất tới NCC"
                : renderedCellValue}
            </Badge>
          ) : (
            <></>
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 200,
      },
      {
        accessorKey: "iwWarrantyCode",
        header: "Mã phiếu nhập kho BH NCC",
        Cell: ({ cell, renderedCellValue }) => {
          const [opened, setOpened] = useState(false);
          const [searchTerm, setSearchTerm] = useState("");
          const values = renderedCellValue
            ? renderedCellValue
                .toString()
                .trim()
                .replace(/^,/, "")
                .split(",")
                .map((value) => value.trim())
            : [];

          const filteredValues = values.filter((value) =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
          );

          return (
            <>
              <Box display="flex">
                <Flex align="center" variant="filled" gap={5}>
                  {renderedCellValue ? (
                    <>
                      {values.slice(0, 1).map((value, index) => (
                        <Badge
                          variant="dot"
                          color={value === null ? "red" : green.base}
                          key={index}
                          size="lg"
                          style={{ cursor: "pointer" }}
                          onClick={async () => {
                            detailDataIn(
                              value,
                              "NHAP_KHO_BAO_HANH_NCC",
                              true // status === "LOCK"
                            );
                          }}
                          fw={600}
                        >
                          {value === null ? "Chưa nhập kho" : value}
                        </Badge>
                      ))}
                      {values.length > 1 && (
                        <ActionIcon
                          onClick={() => setOpened(true)}
                          color="blue"
                          radius="xl"
                          variant="light"
                          aria-label="Settings"
                        >
                          <IconDots size="1.2rem" />
                        </ActionIcon>
                      )}
                    </>
                  ) : (
                    <Badge color="red" variant="dot">
                      Chưa nhập kho
                    </Badge>
                  )}
                </Flex>
              </Box>
              <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="550"
                title={<Title order={5}>Danh sách mã phiếu</Title>}
              >
                <TextInput
                  placeholder="Tìm kiếm mã phiếu..."
                  value={searchTerm}
                  radius="xl"
                  mt={10}
                  leftSection={<IconSearch size="1rem" />}
                  onChange={(event) => setSearchTerm(event.currentTarget.value)}
                  mb={10}
                  w={170}
                />
                <Flex gap={10} mt={20} wrap="wrap">
                  {filteredValues.map((value, index) => (
                    <Badge
                      size="lg"
                      variant="dot"
                      color={value === null ? "red" : green.base}
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataIn(value, "NHAP_KHO_BAO_HANH_NCC", true);
                        setOpened(false);
                      }}
                      fw={600}
                    >
                      {value === null ? "Chưa nhập kho" : value}
                    </Badge>
                  ))}
                  {filteredValues.length === 0 && (
                    <Text>Không tìm thấy kết quả</Text>
                  )}
                </Flex>
              </Modal>
            </>
          );
        },
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 200,
      },
      {
        accessorKey: "isReturnedBorrowGoods",
        header: "Hàng mượn trả",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.original.isReturnedBorrowGoods === 1.0
              ? "Đã trả hàng mượn"
              : row.original.isReturnedBorrowGoods === 2.0
              ? "Không xuất mượn"
              : "Chưa trả hàng mượn"}
          </Text>
        ),
      },
      {
        accessorKey: "vendorName",
        header: "Nhà cung cấp",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "itemType",
        header: "Loại hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "transactionType",
        header: "Loại giao dịch gần nhất",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 200,
      },
      {
        accessorKey: "conclusionError",
        header: "Mô tả lỗi",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "debtStatus",
        header: "Trạng thái nợ",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo phiếu",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "serialNote",
        header: "Ghi chú",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 70,
        Cell: ({ row }) => (
          <Flex justify={"center"} gap={"md"} align={"center"}>
            <Tooltip label="Cập nhật hạn BH">
              <ActionIcon
                color="blue"
                variant="light"
                disabled={row.original.soSerialStatus !== "Test OK"}
                onClick={() => updateWarrantyTerm([row.original])}
              >
                <IconTagStarred size={20} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableResizing: false,
      },
    ],
    [dataItemStatusSelect, dataDebtStatusSelect]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const handleChange = (serial: string, field: string, value: any) => {
    const updatedData = dataRowProps.map((item: any) =>
      item.serial?.toString() === serial?.toString()
        ? {
            ...item,
            [field]: value,
          }
        : item
    );
    setDataRowProps(updatedData);
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }&ItemType=1`;

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.vendorName) {
      url += `&VendorCode=${search.vendorName}`;
    }

    if (search.serialStatus) {
      url += `&SerialStatus=${search.serialStatus}`;
    }

    if (search.debtStatus) {
      url += `&DebtStatus=${search.debtStatus}`;
    }

    if (search.branch) {
      url += `&BranchId=${search.branch}`;
    }

    if (search.inv) {
      url += `&InventoryId=${search.inv}`;
    }

    try {
      const response = await repositoryDelivery.get<
        MessageResponse<DefectiveItem[]>
      >(`/api/v1/DefectiveItemsList/get-list${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(response.totalCount);
        setSelectIds([]);
        Promise.all([
          getDataVendorSelect(),
          getDataBranchSelect(),
          getDataInventorySelect(),
        ]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const handleExportData = async () => {
    let url = `/api/v1/ExportExcel/export-defective-items?Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}&ItemType=1`;

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.vendorName) {
      url += `&VendorCode=${search.vendorName}`;
    }

    if (search.serialStatus) {
      url += `&SerialStatus=${search.serialStatus}`;
    }

    if (search.debtStatus) {
      url += `&DebtStatus=${search.debtStatus}`;
    }

    if (search.branch) {
      url += `&BranchId=${search.branch}`;
    }

    const _url = BASE_API_DELIVERY + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "danh_muc_bang_ke_hang_loi_hang_khach" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleShippingOutward = async (id: string | number) => {
    await repositoryDelivery.post<MessageResponse<any>>(
      "/api/v1/TblDelivery/create-delivery-from-transaction",
      { transactionId: id }
    );
  };

  const handleVendorWarrantyOutward = async (
    itemTransaction: any,
    dep: boolean
  ) => {
    const req = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Outward/create-vendor-warranty-outward",
      {
        sourceId: 0,
        tblItemTransactionHeaderCommand: {
          ...tblItemTransactionHeaderCommand,
          sourceType: "XUAT_KHO_BAO_HANH_NCC",
          sourceCode: sourceCodeExport,
          attribute20: JSON.stringify(itemTransaction),
        },
        serials: dataRowSelect,
        isLock: true,
        isAuto: true,
      }
    );

    if (req && req?.success) {
      const id = req?.data;
      if (dep) {
        handleShippingOutward(id?.data);
      }
      NotificationExtension.Success("Xuất bảo hành nhà cung cấp thành công !");
      setTimeout(() => {
        setDeleteViewStatus(!deleteViewStatus);
        modals.closeAll();
      }, 1000);
    }
  };

  const handleWarrantyOutward = async (dep: boolean, itemTransaction: any) => {
    const req = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Outward/create-warranty-outward",
      {
        sourceId: 0,
        tblItemTransactionHeaderCommand: {
          ...tblItemTransactionHeaderCommand,
          sourceType: "XUAT_KHO_BAO_HANH",
          attribute20: JSON.stringify(itemTransaction),
        },
        serials: dataRowSelect?.map((item: any) => ({
          ...item,
          serialStatus: "Trả khách",
          transactionSourceTypeCode: "XUAT_KHO_BAO_HANH",
        })),
        isLock: true,
        isAuto: true,
      }
    );

    if (req && req?.success) {
      const id = req?.data;
      if (dep) {
        handleShippingOutward(id?.data);
      }
      NotificationExtension.Success("Xuất trả khách thành công !");
      modals.closeAll();
      setTimeout(() => {
        setDeleteViewStatus(!deleteViewStatus);
      }, 1000);
    }
  };

  const handleWarrantyInward = async () => {
    const req = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Inward/create-in-ward-vendor-warranty",
      {
        sourceId: 0,
        tblItemTransactionHeaderCommand: {
          ...tblItemTransactionHeaderCommand,
          sourceType: "NHAP_KHO_BAO_HANH_NCC",
        },
        serials: dataRowSelect?.map((item: any) => ({
          ...item,
          serialStatus: "Chờ test",
          transactionSourceTypeCode: "NHAP_KHO_BAO_HANH_NCC",
        })),
        isLock: true,
      }
    );

    if (req && req?.success) {
      NotificationExtension.Success("Nhập kho bảo hành thành công !");
      setTimeout(() => {
        setDeleteViewStatus(!deleteViewStatus);
        setNavigateInWard(false);
      }, 1000);
    }
  };

  const getDataVendorSelect = async () => {
    const getData = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblVendor/get-all"
    );

    if (getData && getData?.success) {
      const result = getData.data;
      setDataVendorSelect(
        result
          ?.filter(
            (item: any) => item.vendorName != null && item.vendorCode != null
          )
          ?.map((item: any) => ({
            value: item.vendorCode,
            label: `${item.vendorCode} - ${item.vendorName}`,
          }))
      );
    }
  };

  const getDataInventorySelect = async () => {
    const getData = await getTblDMInventory();

    setDataInventorySelect(
      getData
        ?.filter(
          (item) =>
            item.value != null &&
            item.text != null &&
            (item.text?.includes("BH") ||
              item.text?.includes("BK") ||
              item.text?.includes("DV"))
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataBranchSelect(
      getData
        ?.filter(
          (item) =>
            item.value != null && item.text != null && item.value != "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const updateWarrantyTerm = (listSerial: any) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Cập nhật hạn BH sản phẩm</Title>
        </>
      ),
      size: "auto",
      children: (
        <UpdateWarrantyTerm
          dataSerial={listSerial}
          setDeleteViewStatus={setDeleteViewStatus}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  function outWardWarrantyVendor() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xuất bảo hành nhà cung cấp</Title>
        </>
      ),
      size: "auto",
      children: (
        <OutWardWarrantyVendor
          handleVendorWarrantyOutward={handleVendorWarrantyOutward}
          invId={dataRowSelect[0].invId}
          dataRowProps={dataRowProps}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const modalsReturnCustomer = (dataItem: any) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xuất trả khách</Title>
        </>
      ),
      size: "auto",
      children: (
        <ReturnCustomers
          dataRowProps={dataItem}
          handleChange={handleChange}
          handleWarrantyOutward={handleWarrantyOutward}
          id={dataItem[0]?.requestFormId}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus, pagination]);

  useEffect(() => {
    const numericIds = selectIds.map((item) => item);

    const filtered = data.filter((item) =>
      numericIds.includes(item.id?.toString())
    );

    const filtered2 = filtered?.map((item: any) => ({
      primaryQuantity: 1,
      subQuantity: item.subQuantity ? item.subQuantity : 0,
      serial: item?.newSerial !== null ? item?.newSerial : item.soSerial,
      invId: item.subInvId,
      serialStatus: "Đang gửi nhà cung cấp",
      serialNote: item.serialNote,
      itemCode: item.soItemCode,
      itemName: item.soItemName,
      transactionId: 0,
      organizationId: item?.invOrgId,
      inventoryItemId: item.itemId,
      serialAge: null,
      subinventoryCode: item.subInvCode,
      locatorId: item.positionid,
      transactionStatus: null,
      note: null,
      transactionTypeId: null,
      transactionSourceId: null,
      trxSourceLineId: null,
      transactionReference: null,
      transactionQuantity: 1,
      transactionUom: null,
      transactionDate: null,
      periodId: null,
      actualCost: null,
      transactionCost: null,
      priorCost: null,
      newCost: null,
      departmentId: null,
      operationSeqNum: null,
      transferTransactionId: null,
      transferOrganizationId: null,
      transferSubinventory: null,
      transferLocatorId: null,
      rcvTransactionId: null,
      shipmentNumber: null,
      freightCode: null,
      expCode: null,
      numberOfContainers: null,
      valueChange: null,
      percentageChange: null,
      errorCode: null,
      errorExplanation: null,
      costedFlag: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      formChange: "KHONG_DOI",
      supplierSlip: null,
      createdBy: null,
      creationDate: null,
      clentId: null,
      requestBy: null,
      requestDate: null,
      approveBy: null,
      approveDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      primaryUom: item.uomCode,
      itemTransactionHeaderCode: item.owWarrantyCode,
      transactionSourceTypeCode: "XUAT_KHO_BAO_HANH_NCC",
      headerId: null,
      isDraf: true,
      soPrice: null,
      defectiveId: item.id,
    }));

    if (selectIds.length > 0) {
      setSelectStatusSerial(filtered[0]?.soSerialStatus);
      setIsReturnedBorrowGoods(filtered[0]?.isReturnedBorrowGoods);
      setListErrorNum(filtered?.map((item: any) => item.errorNumber));
      setDataRowProps(filtered);
    } else {
      setSelectStatusSerial(null);
      setIsReturnedBorrowGoods(null);
    }
    setSourceCodeExport(filtered[0]?.attribute2);
    setDataRowSelect(filtered2);
    setTblItemTransactionHeaderCommand((prev) => ({
      ...prev,
      subInvId: filtered[0]?.subInvId,
      branchId: filtered[0]?.invOrgId,
    }));
  }, [selectIds]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (180 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    enableRowSelection: (row) => {
      if (row.original.soSerialStatus === "Trả khách") {
        return false;
      }
      if (selectStatusSerial === null || selectStatusSerial === "") {
        return true;
      } else {
        return row.original.soSerialStatus === selectStatusSerial;
      }
    },
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ row }: any) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex gap={"sm"} align={"center"} wrap={"wrap"}>
            <Badge color="red" />
            <Text fw={500} size="12.5px">
              Quá hạn
            </Text>
            <Badge color="blue" />
            <Text fw={500} size="12.5px">
              Chưa đến hạn
            </Text>
            <Badge color="yellow" />
            <Text fw={500} size="12.5px">
              Sắp đến hạn
            </Text>
            <Badge color="green" />
            <Text fw={500} size="12.5px">
              Đã trả khách
            </Text>
          </Flex>
          <Flex
            gap="md"
            justify="end"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Menu>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Nhập/Xuất/Cập nhật Serial
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  leftSection={<IconTransferOut size={14} />}
                  disabled={
                    selectIds.length === 0 ||
                    (selectStatusSerial !== "Test lỗi" &&
                      selectStatusSerial !== "Bán bình thường")
                  }
                  // onClick={() => handleVendorWarrantyOutward()}
                  onClick={() => outWardWarrantyVendor()}
                >
                  Xuất BH NCC
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconTransferIn size={14} />}
                  onClick={() => setNavigateInWard(true)}
                  disabled={
                    selectIds.length === 0 ||
                    selectStatusSerial !== "Đang gửi nhà cung cấp"
                  }
                >
                  Nhập BH NCC
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconCubeSend size={14} />}
                  onClick={() => {
                    modalsReturnCustomer(dataRowProps);
                  }}
                  disabled={
                    selectIds.length === 0 ||
                    selectStatusSerial !== "Test OK" ||
                    isReturnedBorrowGoods === 0.0 ||
                    listErrorNum.every((item) => item === listErrorNum[0]) ===
                      false ||
                    listErrorNum.every((item) => item !== null) === false
                  }
                >
                  Xuất trả khách
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconTagStarred size={14} />}
                  disabled={
                    selectIds.length === 0 || selectStatusSerial !== "Test OK"
                  }
                  onClick={() => updateWarrantyTerm(dataRowProps)}
                >
                  Cập nhật hạn BH
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <Menu shadow="md">
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Export/Import Excel
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={() => handleExportData()}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Từ ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "startDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Đến ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Nhà cung cấp"
              data={dataVendorSelect}
              leftSection={<IconBuildingSkyscraper color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              comboboxProps={{ width: 425 }}
              onChange={(e) => handleChangeSearchValue(e ?? "", "vendorName")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Trạng thái sản phẩm"
              data={dataItemStatusSelect}
              leftSection={<IconChartCandle color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              onChange={(e) => handleChangeSearchValue(e ?? "", "serialStatus")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Chi nhánh"
              searchable
              clearable
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              data={dataBranchSelect}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "branch")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Kho"
              data={dataInventorySelect}
              searchable
              clearable
              leftSection={<IconBuildingWarehouse color="#15aabf" />}
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              onChange={(e) => handleChangeSearchValue(e ?? "", "inv")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Trạng thái nợ"
              data={dataDebtStatusSelect}
              leftSection={<IconBrandGrammarly color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              onChange={(e) => handleChangeSearchValue(e ?? "", "debtStatus")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              leftSection={<IconSearch color="#15aabf" />}
              defaultValue={keySearch}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  await fetchData();
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.25 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={fetchData}
              w={"100%"}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
        // "iwCode", "owReturnCode"
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {navigateInWard ? (
        <InWardWarrantyVendor
          back={setNavigateInWard}
          dataRowProps={dataRowSelect}
          setDataRowProps={setDataRowSelect}
          handleWarrantyInward={handleWarrantyInward}
        />
      ) : (
        <MantineReactTable table={table} />
      )}
    </>
  );
};

export default DefectiveGoodsListV2;
