import { ComboboxItem, Select, Text, TextInput, Tooltip } from "@mantine/core";
import React, { useEffect } from "react";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { tblInventoryTransaction } from "../../../../model/TblOutWard";
import RenderTable from "./RenderTable";
import classesButton from "../../../../../src/Styles/StyleButton.module.css";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { SelectListItemBase } from "../../../../_base/model/_base/SelectListItemBase";

interface OrderDetail {
  cod: number;
  shippingVendor: string;
  customerId: number;
  customerName: string;
  telephoneNumber: string;
  customerAddress: string;
  customerProvinceId: string;
  customerDistrictId: string;
  customerCommuneId: string;
  deliveryAppointmentTime: string; // ISO 8601 format
  typeShip: string;
  timeDelivery: string;
  timeDeliveryTime: number;
  sourceType: number;
  status: boolean;
  width: number;
  length: number;
  height: number;
  branchId: number;
}
//Interface gọi tỉnh thành phố
export interface Province {
  id: number;
  nameprovince: string;
  note: string | null;
  codeprovince: string;
  active: boolean;
  codeprovinceweb: string;
  createdate: string | null;
  lastupdatedate: string | null;
  createby: string | null;
  lastupdateby: string | null;
}

const SaleOrderOutward = ({
  dataSearchSourceCodeOption,
  dataOutWard,
  sourceCode,
  setSourceCode,
  handleKeyDownSourceCode,
  dataOrderDetail,
  handleChangeValueInput,
  isSave,
  setSearchSourceCode,
  isEdit,
  isLocked,
  haveSourceCode,
  action,
  updatedDataInventoryTransactionType,
}: SaleOrderOutwardProps) => {
  const [dataProvince, setDataProvince] = React.useState<Province[]>([]);
  const [dataDistrict, setDataDistrict] = React.useState<SelectListItemBase[]>(
    []
  );
  const [dataCommune, setDataCommune] = React.useState<SelectListItemBase[]>(
    []
  );

  const isDisabled =
    dataOutWard.status === "LOCK" ||
    sourceCode ||
    isSave ||
    ["DETAIL", "EDIT"].includes(action);
  const headers = [
    { label: "", style: { width: "25%" } },
    { label: "Bên xuất", style: { width: "25%" } },
    { label: "", style: { width: "25%" } },
    { label: "Bên nhận", style: { width: "25%" } },
  ];

  const getDeliveryText = (delivery: string) => {
    switch (delivery) {
      case "KHONG":
        return "Nhận tại cửa hàng";
      case "NOI_BO":
      case "HA_COM":
        return "Nội bộ";
      default:
        return delivery;
    }
  };

  const attribute3 = dataOrderDetail?.attribute3;
  const checkDvtn = dataOrderDetail?.dvtn;

  let parsedData: OrderDetail | null = null;

  try {
    parsedData = attribute3 ? JSON.parse(attribute3) : null;
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  //Api gọi Tỉnh thanh phố
  const fetchDataReceiptProvince = async () => {
    const response = await repositoryMdm.get<MessageResponse<Province[]>>(
      "/api/v1/TblDmProvince/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setDataProvince(result);
    }
  };
  //api gọi quận huyện
  const fetchDataDistrict = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >(`/api/v1/TblDmDistrict/get-select?id=${parsedData?.customerProvinceId}`);

    if (response && response.success) {
      let result = response.data;
      setDataDistrict(result);
    }
  };
  //api gọi xã phường
  const fetchDataCommune = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >(`/api/v1/TblDmCommune/get-select?id=${parsedData?.customerDistrictId}`);

    if (response && response.success) {
      let result = response.data;
      console.log(result, "result");
      setDataCommune(result);
    }
  };

  useEffect(() => {
    if (parsedData?.customerProvinceId) fetchDataDistrict();
  }, [parsedData?.customerProvinceId]);

  useEffect(() => {
    if (parsedData?.customerDistrictId) fetchDataCommune();
  }, [parsedData?.customerDistrictId]);

  useEffect(() => {
    fetchDataReceiptProvince();
  }, []);

  const rows = [
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian tạo
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {formatDateTransfer(dataOrderDetail?.createDate ?? "")}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã số khách hàng
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {checkDvtn ? parsedData?.customerId : dataOrderDetail?.cusCode}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian hoàn thành
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {formatDateTransfer(dataOutWard?.finishDate)}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Họ & Tên
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {checkDvtn ? parsedData?.customerName : dataOrderDetail?.cusName}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu xuất kho
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOutWard?.transactionCode}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Số điện thoại
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {checkDvtn
              ? parsedData?.telephoneNumber
              : dataOrderDetail?.cusTelephone}{" "}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã yêu cầu xuất kho
          </Text>
        ),
      },
      {
        content:
          !isEdit && !haveSourceCode ? (
            <Tooltip
              label={
                dataOutWard?.sourceCode
                  ? dataOutWard?.sourceCode
                  : "Vui lòng chọn phiếu"
              }
            >
              <Select
                w={"160px"}
                placeholder="Mã hóa đơn bán lẻ"
                data={dataSearchSourceCodeOption}
                className={classesButton.text_input}
                size="sm"
                disabled={
                  !dataOutWard.sourceType ||
                  dataOutWard.status === "LOCK" ||
                  sourceCode ||
                  isSave
                }
                searchable
                clearable
                // searchValue={searchSourceCode ?? ""}
                onSearchChange={(e) => {
                  if (setSearchSourceCode) {
                    setSearchSourceCode(e);
                  }
                }}
                onKeyDown={handleKeyDownSourceCode}
                value={sourceCode || dataOutWard?.sourceCode}
                onChange={(value) => {
                  handleChangeValueInput(value || "", "sourceCode");
                  if (setSourceCode) {
                    setSourceCode(value);
                  }
                }}
                nothingFoundMessage={"Không tìm thấy dữ liệu !"}
              />
            </Tooltip>
          ) : (
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOutWard.sourceCode}{" "}
            </Text>
          ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Tỉnh Thành
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {checkDvtn
              ? (
                  dataProvince?.find(
                    (item) =>
                      String(item.id) === String(parsedData?.customerProvinceId)
                  ) ?? {}
                ).nameprovince ?? null
              : dataOrderDetail?.cusProvinceName}{" "}
          </Text>
        ),
      },
    ],
    // [
    //   {
    //     content: (
    //       <Text
    //         fw={700}
    //         w={"100px"}
    //         size="sm"
    //         style={{ whiteSpace: "normal" }}
    //         lineClamp={2}
    //         truncate
    //       >
    //         Lý do
    //       </Text>
    //     ),
    //   },
    //   {
    //     content: !isDisabled ? (
    //       <Select
    //         searchable
    //         clearable
    //         w={160}
    //         className={classesButton.text_input}
    //         placeholder="Chọn lý do"
    //         disabled={
    //           dataOutWard.status === "LOCK" ||
    //           sourceCode ||
    //           isSave ||
    //           ["DETAIL", "EDIT"].includes(action)
    //         }
    //         data={updatedDataInventoryTransactionType}
    //         value={dataOutWard.sourceType?.toString() || ""}
    //         onChange={(value) => {
    //           handleChangeValueInput(value || "", "sourceType");
    //         }}
    //       />
    //     ) : (
    //       <Text
    //         fw={700}
    //         w={"100px"}
    //         size="sm"
    //         style={{ whiteSpace: "normal" }}
    //         lineClamp={2}
    //         truncate
    //       >
    //         {
    //           updatedDataInventoryTransactionType?.find(
    //             (t) => t.value === dataOutWard.sourceType?.toString()
    //           )?.label
    //         }
    //       </Text>
    //     ),
    //   },
    //   {
    //     content: null,
    //   },
    //   {
    //     content: null,
    //   },
    // ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chi nhánh
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.fromBranchName}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Quận Huyện
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {checkDvtn
              ? dataDistrict?.find(
                  (item) =>
                    String(item.value) ===
                    String(parsedData?.customerDistrictId)
                )?.text ?? null
              : dataOrderDetail?.cusDestrictName}{" "}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Địa điểm làm việc
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.fromBranchAddress}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Phường Xã
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {checkDvtn
              ? dataCommune?.find(
                  (item) =>
                    String(item.value) === String(parsedData?.customerCommuneId)
                )?.text ?? null
              : dataOrderDetail?.cusCommuneName}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Kho
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.fromInvCode
              ? `${dataOrderDetail.fromInvCode} - ${
                  dataOrderDetail.fromInvName || ""
                }`
              : dataOrderDetail.fromInvName || ""}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Địa chỉ cụ thể
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {checkDvtn
              ? parsedData?.customerAddress
              : dataOrderDetail?.cusAddress}{" "}
          </Text>
        ),
      },
    ],

    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Họ & Tên
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail?.createName
              ? dataOrderDetail?.createName +
                " - " +
                dataOrderDetail?.createCode
              : ""}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian nhận
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {formatDateTransfer(
              checkDvtn
                ? parsedData?.deliveryAppointmentTime
                : dataOrderDetail?.receiveDate || ""
            )}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chức danh
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail?.createRoleName || ""}
          </Text>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Giao hàng
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {" "}
            {getDeliveryText(dataOrderDetail?.delivery || "")}
          </Text>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Ghi chú
          </Text>
        ),
      },
      {
        content: (
          <TextInput
            error={
              dataOutWard?.description && dataOutWard.description.length > 300
                ? "Ghi chú không được vượt quá 300 kí tự!"
                : null
            }
            className={classesButton.text_input}
            value={dataOutWard?.description ?? ""}
            onChange={(e) =>
              handleChangeValueInput(e.target.value, "description")
            }
            disabled={isLocked}
            placeholder={isLocked ? "" : "Vui lòng nhập"}
          />
        ),
        colSpan: 3,
      },
    ],
  ];

  return <RenderTable headers={headers} rows={rows} />;
};

type SaleOrderOutwardProps = {
  dataSearchSourceCodeOption?: any[];
  dataPosition?: ComboboxItem[];
  dataOutWard: tblInventoryTransaction;
  dataOrderDetail: any;
  handleChangeValueInput: (value: string | boolean | null, key: string) => void;
  setSourceCode?: (value: any) => void;
  handleKeyDownSourceCode?: (e: any) => void;
  sourceCode?: any;
  isSave?: boolean;
  setSearchSourceCode?: (newValue: React.SetStateAction<string>) => void;
  isEdit?: boolean;
  isLocked?: boolean;
  haveSourceCode?: boolean;
  action: string;
  updatedDataInventoryTransactionType?: ComboboxItem[];
};

export default SaleOrderOutward;
