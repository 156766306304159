import {
  Box,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  TextInput,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";
import { SubmitProductsSerial } from "../types/UIType";
import { anyAsciiCode } from "../../../../_base/helper/AnyAscii";

const AddProductBySerialModal = ({
  onSubmit,
  item,
}: AddProductBySerialModalProps) => {
  const entity = {
    prefix: null,
    suffix: null,
    startNumber: 1,
    endNumber: 2,
    numberLength: null,
  };

  const [visible, { close, open }] = useDisclosure(false);
  const limitSerial = -(
    item?.scannedQuantity ||
    0 - item?.primaryQuantity ||
    0
  );

  const form = useForm<SubmitProductsSerial>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      prefix: (value?: string | null) => {
        if (!value) {
          return "Vui lòng nhập tiền tố!";
        }
        return hasLength(
          { max: 30 },
          "Tiền tố phải nhỏ hơn 30 kí tự!"
        )(value as string);
      },
      startNumber: (value?: number | null) => {
        if (value === null || value === undefined) {
          return "Vui lòng nhập số bắt đầu!";
        }
      },
      endNumber: (value?: number | null) => {
        if (value === null || value === undefined) {
          return "Vui lòng nhập số kết thúc!";
        }
      },
    },
  });

  const handleCreateTblDMPaymentMethod = async (
    dataSubmit: SubmitProductsSerial
  ) => {
    open();
    let serials = [] as string[];
    for (let i = 0; i < dataSubmit.endNumber; i++) {
      serials.push(
        `${dataSubmit.prefix}${String(dataSubmit.startNumber + i).padStart(
          dataSubmit.numberLength ?? 0,
          "0"
        )}${dataSubmit.suffix ?? ""}`
      );
    }
    onSubmit(serials);
    close();
    modals.closeAll();
  };

  return (
    <>
      <Box
        component="form"
        mx="auto"
        onSubmit={form.onSubmit((e: SubmitProductsSerial) => {
          handleCreateTblDMPaymentMethod(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={6}>
            <TextInput
              label={"Tiền tố"}
              placeholder={"Nhập tiền tố"}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              value={form.getValues()?.prefix}
              {...form.getInputProps("prefix")}
              onChange={(e) =>
                form.setValues((prev) => ({
                  ...prev,
                  prefix: anyAsciiCode(
                    e.currentTarget.value
                  ).toLocaleUpperCase(),
                }))
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label={"Hậu tố"}
              placeholder={"Nhập hậu tố"}
              type="text"
              w={"100%"}
              value={form.getValues()?.suffix}
              {...form.getInputProps("suffix")}
              onChange={(e) =>
                form.setValues((prev) => ({
                  ...prev,
                  suffix: anyAsciiCode(
                    e.currentTarget.value
                  ).toLocaleUpperCase(),
                }))
              }
            />
          </Grid.Col>
        </Grid>
        <Grid mt={10}>
          <Grid.Col span={4}>
            <NumberInput
              label={"Số bắt đầu"}
              placeholder={"Nhập số bắt đầu"}
              withAsterisk
              hideControls
              allowDecimal={false}
              allowNegative={false}
              onKeyDown={handleKeyDown}
              min={1}
              {...form.getInputProps("startNumber")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <NumberInput
              label={"Số kết thúc"}
              placeholder={"Nhập số kết thúc"}
              withAsterisk
              hideControls
              allowDecimal={false}
              allowNegative={false}
              // max={limitSerial - form.getValues()?.startNumber + 1 || 0}
              min={(form.getValues().startNumber || 0) + 1}
              w={"100%"}
              {...form.getInputProps("endNumber")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <NumberInput
              label={"Độ dài số"}
              placeholder={"Nhập độ dài số"}
              hideControls
              allowDecimal={false}
              allowNegative={false}
              onKeyDown={handleKeyDown}
              min={1}
              {...form.getInputProps("numberLength")}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default AddProductBySerialModal;

type AddProductBySerialModalProps = {
  onSubmit: any;
  item: any;
};
