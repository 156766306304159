import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Menu,
  rem,
  Select,
  Tooltip,
} from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import * as xlsx from "xlsx";

import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  IconFileExport,
  IconMapPin,
  IconSearch,
  IconTimeDuration15,
} from "@tabler/icons-react";
import { addMonths } from "date-fns";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { formatDateNotTimeZone } from "../../../../common/FormatDate/FormatDate";
import { BASE_API_MDM, BASE_API_POS } from "../../../../config";
import { _localization } from "../../../../config/location";
import { green } from "../../../../const/variables";
import { MessageResponse } from "../../../../model/MessageResponse";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { reportItemSales } from "../../../../model/TblOrderHeader";
import { IconCaretDown } from "@tabler/icons-react";
import { IconUsers } from "@tabler/icons-react";
const ReportSaleOrder = () => {
  const navigate = useNavigate();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<reportItemSales[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //chi nhánh
  // const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
  //   []
  // );
  const userLogin = JSON.parse(localStorage.getItem("userLogin") || "{}");
  //Kho
  const [dataInventory, setDataInventory] = useState<SelectListItemModel[]>([]);
  //Bộ phận
  const [dataDepartment, setDataDepartment] = useState<SelectListItemModel[]>(
    []
  );

  //chi nhánh
  const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
    []
  );

  //Khách hàng
  const [dataCustomer, setDataCustomer] = useState<SelectListItemModel[]>([]);
  const sizeGrid = { base: 6, sm: 3, md: 3, lg: 3, xl: 1.7 };
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      FromDate: "",
      ToDate: "",
      BranchCode: "",
      DeparmentCode: "",
    },
  });

  console.log("dataDepartment", dataDepartment);

  const [search, setSearch] = useState({
    Cnids: [], //chi nhánh
    KhoIds: [], //kho
    Bpids: [], //bộ phận
    KHids: [], //khách hàng
  });
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    try {
      let url = `/api/v1/SaleByCustByOrder/get-list?Skip=${
        pagination?.pageIndex * pagination.pageSize
      }&Take=${pagination.pageSize}`;

      if (formSeachInput.getValues().keySearch) {
        url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
      }
      if (formSeachInput.getValues().FromDate) {
        url += `&FromDate=${formSeachInput.getValues().FromDate}`;
      }

      if (formSeachInput.getValues().ToDate) {
        url += `&ToDate=${formSeachInput.getValues().ToDate}`;
      }
      if (formSeachInput.getValues().BranchCode) {
        url += `&BranchCode=${formSeachInput.getValues().BranchCode}`;
      }
      if (formSeachInput.getValues().DeparmentCode) {
        url += `&DeparmentCode=${formSeachInput.getValues().DeparmentCode}`;
      }

      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response && response.success) {
        console.log("response", response);
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const handleCheckView = async (row: any) => {
    if (row.original.soHd == "3") {
      const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.soHd}`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        navigate("/sell/view-return-order", {
          state: {
            id: row.original.orderId,
            orderNumber: row.original.soHd,
            actionType: "view",
          },
        });
      }
    } else {
      const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.soHd}`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        navigate("/sell/view-order", {
          state: {
            id: row.original.orderId,
            orderNumber: row.original.soHd,
            actionType: "view",
          },
        });
      }
    }
  };

  useEffect(() => {
    const handleCheckView = async () => {};
    handleCheckView();
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<reportItemSales>[]>(
    () => [
      // Nhóm 3: Thông tin hợp đồng
      {
        accessorKey: "soHd",
        header: "Số hợp đồng",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              variant="dot"
              radius="xs"
              onDoubleClick={() => handleCheckView(row)}
              style={{
                cursor: "pointer",
              }}
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "trangThaiDon",
        header: "Trạng thái đơn",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      // Nhóm 1: Thông tin tài chính
      {
        accessorKey: "conNo",
        header: "Còn nợ",
        Cell: ({ renderedCellValue }) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienBan",
        header: "Tiền bán",
        Cell: ({ renderedCellValue }) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienCk",
        header: "Tiền chiết khấu",
        Cell: ({ renderedCellValue }) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienCkGg",
        header: "Tiền chiết khấu giảm giá",
        Cell: ({ renderedCellValue }) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienDaTra",
        header: "Tiền đã trả",
        Cell: ({ renderedCellValue }) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienDonHang",
        header: "Tiền đơn hàng",
        Cell: ({ renderedCellValue }) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienGg",
        header: "Tiền giảm giá",
        Cell: ({ renderedCellValue }) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienVon",
        header: "Tiền vốn",
        Cell: ({ renderedCellValue }) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },

      // Nhóm 2: Thông tin khách hàng
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "telephoneNumber",
        header: "Số điện thoại",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },

      // Nhóm 4: Thông tin phòng ban, chi nhánh
      {
        accessorKey: "nguoiHuongSo",
        header: "Người hưởng số",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "nvKd",
        header: "Nhân viên kinh doanh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "deparmentCode",
        header: "Mã phòng ban",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "deparmentName",
        header: "Tên phòng ban",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maDtcn",
        header: "Mã đối tượng CN",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },

      // Nhóm 5: Thông tin khác
      {
        accessorKey: "ngayTao",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "ngayHoanThanh",
        header: "Ngày hoàn thành",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableColumnResizing: true,
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["SoHd"],
        // right: ["tTienDh", "action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //xuất excel
  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-bao-cao-ban-hang-theo-ngay.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  // // Lấy danh sách mã chi nhánh
  // const DataBranchCode = async () => {
  //   try {
  //     const response = await repositoryDelivery.get(
  //       `api/v1/TblDmMasterOrg/get-select`
  //     );

  //     if (response && response.success) {
  //       const transformedData = response.data.map((item: any) => ({
  //         value: item.value,
  //         label: item.value + " - " + item.text,
  //       }));
  //       setDataBranchCode(transformedData);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return;
  //   }
  // };
  // Lấy danh sách kho
  const fetchDataKho = async () => {
    try {
      // if (
      //   userLogin &&
      //   userLogin.branchId !== null &&
      //   userLogin.branchId !== undefined
      // ) {
      //   const response = await repositoryPos.get(
      //     `/api/v1/TblDmInventory/get-select-by-branch?branchId=${userLogin?.branchId}`
      //   );
      //   if (response && response.success) {
      //     const transformedData = response.data.map((item: any) => ({
      //       value: String(item.inventoryId),
      //       label: item.inventoryCode + " - " + item.inventoryName,
      //     }));
      //     setDataInventory(transformedData);
      //   } else {
      //     setDataInventory([]);
      //   }
      // } else {
      //   const response = await repositoryPos.get(
      //     `/api/v1/TblDmInventory/get-all?checkAuth=true`
      //   );
      //   if (response && response.success) {
      //     const transformedData = response.data.map((item: any) => ({
      //       value: item.value,
      //       label: item.text,
      //     }));
      //     setDataInventory(transformedData);
      //   } else {
      //     setDataInventory([]);
      //   }
      // }
      const response = await repositoryPos.get(
        `/api/v1/TblDmInventory/get-all?checkAuth=true`
      );
      if (response && response.success) {
        const transformedData = response.data.map((item: any) => ({
          value: item.value,
          label: item.text,
        }));
        setDataInventory(transformedData);
      } else {
        setDataInventory([]);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  // Lấy danh sách mã bộ phận
  const fetchDataBp = async () => {
    try {
      if (
        userLogin &&
        userLogin.branchId !== null &&
        userLogin.branchId !== undefined
      ) {
        const response = await repositoryPos.get(
          `/api/v1/TblDepartment/get-all?branchId=${userLogin?.branchId}`
        );
        if (response && response.success) {
          const transformedData = response.data.map((item: any) => ({
            value: String(item.deparmentCode),
            label: item.deparmentCode + " - " + item.name,
          }));
          setDataDepartment(transformedData);
        } else {
          setDataDepartment([]);
        }
      } else {
        const response = await repositoryPos.get(
          `/api/v1/TblDepartment/get-all`
        );
        if (response && response.success) {
          const transformedData = response.data.map((item: any) => ({
            value: String(item.deparmentCode),
            label: item.deparmentCode + " - " + item.name,
          }));
          setDataDepartment(transformedData);
        } else {
          setDataDepartment([]);
        }
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const DataBranchCode = async () => {
    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmInventory/get-select-branch?auth=true`
      );
      if (response && response.success && response.data) {
        console.log("transformedData", response.data);
        const transformedData = response.data
          .filter(
            (branch: any) => branch.att2 !== null && branch.text !== "Tất cả"
          )
          .map((branch: any) => ({
            value: branch.att2,
            label: branch.att2 + " - " + branch.text,
          }));

        setDataBranchCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  // Lấy danh sách mã khách hàng
  const fetchDataCustomer = async () => {
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmCustomer/get-list?Skip=0&Take=100`
      );
      if (response && response.success) {
        const transformedData = response.data
          .filter((item: any) => item.name !== null)
          .map((item: any) => ({
            value: String(item.id),
            label: String(item.code) + " - " + item.name,
          }))
          .filter(
            (item: any, index: number, self: any[]) =>
              index === self.findIndex((t) => t.value === item.value)
          );

        setDataCustomer(transformedData);
      } else {
        setDataCustomer([]);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  useEffect(() => {
    fetchData();
  }, [pagination]);

  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };

  const exportDataExcel = async () => {
    let params = new URLSearchParams();

    // Add the query parameters if they exist
    const keySearch = formSeachInput.getValues().keySearch;
    if (keySearch) {
      params.append("KeySearch", keySearch);
    }

    const FromDate = formSeachInput.getValues().FromDate;
    if (FromDate) {
      params.append("FromDate", FromDate);
    }

    const ToDate = formSeachInput.getValues().ToDate;
    if (ToDate) {
      params.append("ToDate", ToDate);
    }

    const BranchCode = formSeachInput.getValues().BranchCode;
    if (BranchCode) {
      params.append("BranchCode", BranchCode);
    }

    const baseUrl = params.toString()
      ? `/api/v1/SaleByCustByOrder/export-excel?${params.toString()}`
      : `/api/v1/SaleByCustByOrder/export-excel`;

    const _url = BASE_API_POS + baseUrl;

    const fullUrl = new URL(_url);
    const token = localStorage.getItem("token");
    fetch(fullUrl.toString(), {
      method: "GET", // Use the appropriate HTTP method
      headers: {
        Authorization: `Bearer ${token?.replace(/"/g, "")}`,
        "Content-Type": "application/vnd.ms-excel",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob(); // Assuming the response is a file or blob
        } else {
          throw new Error("Failed to fetch the resource");
        }
      })

      .then((blob) => {
        // Open the blob in a new tab
        const url = window.URL.createObjectURL(blob);
        let filename = "bao_cao_doanh_so_ban_hang.xlsx"; // Tên mặc định nếu không có header
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        style={{
          border: "1px solid #dee2e6",
          padding: "5px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Chức năng
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              // onClick={handleExport}
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
            {/* <Tooltip label={"Chức năng đang update"}>
              <Menu.Item
                leftSection={
                  <IconFileUpload style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Import Excel
              </Menu.Item>
            </Tooltip> */}
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          // marginTop: "10px",
        }}
      >
        <Grid style={{ width: "100%" }} mt={10}>
          <Grid.Col span={sizeGrid}>
            <Input
              size="sm"
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              key={formSeachInput.key("keySearch")}
              {...formSeachInput.getInputProps("keySearch")}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  fetchData();
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày bắt đầu"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
              onChange={(date) => {
                formSeachInput.setFieldValue(
                  "FromDate",
                  formatDateNotTimeZone(date) || ""
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày kết thúc"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
              // maxDate={new Date(formSeachInput.getValues().FromDate)}
              maxDate={
                formSeachInput.getValues().FromDate
                  ? addMonths(new Date(formSeachInput.getValues().FromDate), 1)
                  : undefined
              }
              onChange={(date) => {
                formSeachInput.setFieldValue(
                  "ToDate",
                  formatDateNotTimeZone(date) || ""
                );
              }}
              clearable
            />
          </Grid.Col>

          <Grid.Col span={sizeGrid}>
            <Select
              size="sm"
              placeholder="Chi nhánh"
              searchable
              clearable
              data={dataBranchCode}
              leftSection={<IconMapPin size={20} color="#15aabf" />}
              onClick={() => {
                if (dataBranchCode.length === 0) {
                  DataBranchCode();
                }
              }}
              {...formSeachInput.getInputProps("BranchCode")}
            />
          </Grid.Col>

          <Grid.Col span={sizeGrid}>
            <Select
              size="sm"
              placeholder="Vui lòng chọn bộ phận"
              searchable
              clearable
              leftSection={<IconUsers size={20} color="#15aabf" />}
              data={dataDepartment}
              onClick={() => {
                if (dataDepartment.length === 0) {
                  fetchDataBp();
                }
              }}
              {...formSeachInput.getInputProps("DeparmentCode")}
            />
          </Grid.Col>

          <Grid.Col span={sizeGrid}>
            <Button
              type="submit"
              leftSection={<IconSearch size={20} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>{" "}
          </Grid.Col>
        </Grid>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ReportSaleOrder;
