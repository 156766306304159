import {
  Badge,
  Button,
  Grid,
  GridCol,
  Input,
  Text,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconSearch } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { _localization } from "../../../../config/location";
import { search } from "../constants";
import useDynamicHeight from "../hooks/useDynamicHeight";
import { outward } from "../services";
import { GetListSerialOutwardParams } from "../types/GetListOutwardParams";
import { TblWarehouseOutwardGetList } from "../types/TblWarehouseOutward";

const SuggestItemWardModal = ({
  sourceType,
  sourceCode,
}: SuggestItemWardModalProps) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarehouseOutwardGetList[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const height = useDynamicHeight(headerRef, 300);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      {
        accessorKey: "positionCode",
        header: "Mã vị trí",
        size: 30,
        Cell: ({ row }) => (
          <Tooltip label={row.original.positionCode}>
            <Badge variant="light" color="#228be6">
              {row.original.positionCode}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "positionName",
        header: "Tên vị trí",
        size: 100,
        Cell: ({ row }) => (
          <Tooltip label={row.original.positionName}>
            <Text w={150}>{row.original.positionName}</Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
        Cell: ({ row }) => (
          <Badge variant="light" color="#228be6">
            {row.original.itemCode}
          </Badge>
        ),
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} td="underline">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        size: 100,
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text w={300}>{row.original.itemName}</Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "itemAge",
        header: "Tuổi tồn",
        size: 30,
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
      },
      {
        accessorKey: "primaryUomCode",
        header: "ĐVT1",
        size: 50,
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: () => <Text>1</Text>,
      },
      {
        accessorKey: "poPrice",
        header: sourceType === "XUAT_BAN" ? "Giá bán" : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text>
            {sourceType === "XUAT_BAN"
              ? row.original?.soPrice && row.original.soPrice?.toLocaleString()
              : row.original?.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },
      ...(sourceType === "XUAT_LINH_KIEN_SAN_XUAT"
        ? []
        : [
            {
              accessorKey: "subQuantity",
              header: "SL2",
              size: 30,
            },
            {
              accessorKey: "subUomName",
              header: "ĐVT2",
              size: 30,
            },
            {
              header: "Giá theo ĐVT2",
              size: 30,
            },
          ]),
    ],
    []
  );

  const form = useForm({
    mode: "uncontrolled",
    initialValues: search,
  });

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    try {
      const queryParams: GetListSerialOutwardParams = {
        Skip: pagination?.pageIndex * pagination?.pageSize,
        Take: pagination?.pageSize,
        KeySearch: form.getValues()?.keySearch,
        SourceCode: sourceCode,
        Type: sourceType,
      };
      const response = await outward.getListSerialOutward(queryParams);
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sorting, pagination.pageIndex, pagination.pageSize]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.transactionId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["index", "positionCode", "positionName", "itemCode", "serial"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    enableColumnActions: false,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "8px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      radius: "xl",
      size: "lg",
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <form>
          <Grid mt={"10px"}>
            <GridCol span={1.8}>
              <Input
                placeholder="Từ khoá"
                type="text"
                leftSection={<IconSearch color="#15aabf" />}
                key={form.key("keySearch")}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await fetchData();
                  }
                }}
                {...form.getInputProps("keySearch")}
              />
            </GridCol>
            <GridCol span={{ base: 4, sm: 4, md: 1.5, lg: 1 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
              >
                Tìm kiếm
              </Button>
            </GridCol>
          </Grid>
        </form>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default SuggestItemWardModal;

type SuggestItemWardModalProps = {
  sourceType: string;
  sourceCode: string;
};
