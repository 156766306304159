import {
  ActionIcon,
  Badge,
  Button,
  ComboboxItem,
  Divider,
  Flex,
  Grid,
  Menu,
  rem,
  Select,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import {
  IconBrandBootstrap,
  IconCalendar,
  IconCaretDown,
  IconClipboardList,
  IconEdit,
  IconFileExport,
  IconFileUpload,
  IconSearch,
} from "@tabler/icons-react";
import { green } from "../../../const/variables";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { getMasterOrgSelect } from "../../../service/getSelectApi";
import { useDebouncedState } from "@mantine/hooks";
import { MessageResponse } from "../../../model/MessageResponse";
import { modals } from "@mantine/modals";
import ModalVAT from "./ModalVAT";
import { useNavigate } from "react-router-dom";
import { DatePickerInput } from "@mantine/dates";

const WriteVAT = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [orderStatus] = useState<ComboboxItem[]>([
    { label: "Đơn hàng hoàn thành", value: "DON_HANG_HOAN_THANH" },
    { label: "Đơn hàng chốt", value: "DON_HANG_CHOT" },
    { label: "Đơn hàng hủy", value: "DON_HANG_HUY" },
    { label: "Đơn hàng tạm", value: "DON_HANG_TAM" },
    { label: "Đơn hàng chờ duyệt giảm", value: "DON_HANG_CHO_DUYET_GIAM" },
    { label: "Đơn hàng đã duyệt giảm", value: "DON_HANG_DA_DUYET_GIAM" },
    { label: "Đơn hàng đổi trả", value: "DON_HANG_DOI_TRA" },
  ]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    branchId: "",
    printInvoiceCheck: "",
  });

  const [keySearch, setKeySearch] = useDebouncedState<string>("", 300);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.index === -1 ? "" : row.index + 1}
          </Text>
        ),
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableResizing: false,
      },
      {
        accessorKey: "branchCode",
        header: "Chi nhánh",
        size: 80,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Đơn hàng",
        columns: [
          {
            accessorKey: "orderNumber",
            header: "Mã",
            size: 200,
            Cell: ({ renderedCellValue, row }) =>
              renderedCellValue && renderedCellValue !== null ? (
                <Badge
                  radius="sm"
                  variant="dot"
                  size="lg"
                  color={renderedCellValue === null ? "red" : green.base}
                  onDoubleClick={() => handleCheckView(row)}
                >
                  {renderedCellValue}
                </Badge>
              ) : (
                <></>
              ),
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            accessorKey: "orderDate",
            header: "Thời gian",
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
        ],
      },
      {
        header: "Đối tượng công nợ",
        columns: [
          {
            accessorKey: "debtCode",
            header: "Mã",
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            accessorKey: "debtName",
            header: "Tên",
            size: 250,
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
        ],
      },
      {
        header: "Thông tin viết hóa đơn VAT",
        columns: [
          {
            accessorKey: "dateWriteOrder",
            header: "Ngày viết hóa đơn",
            size: 200,
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            accessorKey: "customerName",
            header: "Tên doanh nghiệp",
            size: 225,
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            accessorKey: "address",
            header: "Địa chỉ",
            size: 450,
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            accessorKey: "taxCode",
            header: "Mã ST",
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            accessorKey: "email",
            header: "Email",
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
          // {
          //   accessorKey: "numberAmount",
          //   header: "Số tiền",
          //   Cell: ({ renderedCellValue }) => (
          //     <Text fw={500} size="12.5px">
          //       {Number(renderedCellValue)?.toLocaleString()} VNĐ
          //     </Text>
          //   ),
          //   enableColumnActions: false,
          //   enableColumnFilter: false,
          //   enableSorting: false,
          // },
        ],
      },
      {
        accessorKey: "amountTotal",
        header: "Tổng tiền đơn hàng",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {Number(renderedCellValue)?.toLocaleString()} VNĐ
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      // {
      //   accessorKey: "amountRemaining",
      //   header: "Tiền nhập lại",
      //   Cell: ({ renderedCellValue }) => (
      //     <Text fw={500} size="12.5px">
      //       {Number(renderedCellValue)?.toLocaleString()} VNĐ
      //     </Text>
      //   ),
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // },
      {
        accessorKey: "orderStatus",
        header: "Trạng thái chung đơn hàng",
        size: 200,
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              renderedCellValue?.toString() ?? "",
              orderStatus,
              "label"
            )}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Viết hóa đơn VAT",
        columns: [
          {
            accessorKey: "printInvoiceCheck",
            header: "Loại",
            size: 70,
            Cell: ({ renderedCellValue }) => (
              <Text fw={500} size="12.5px">
                {renderedCellValue === 0
                  ? "Không"
                  : renderedCellValue === 1
                  ? "Có"
                  : "Nợ"}
              </Text>
            ),
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            accessorKey: "writeNumberOrder",
            header: "Số hóa đơn",
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
          },
        ],
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        Cell: ({ row }) => (
          <Tooltip label="Chỉnh sửa">
            <ActionIcon
              variant="light"
              aria-label="Settings"
              color="orange"
              onClick={() => {
                modalsEdits(row.original.orderNumber);
              }}
            >
              <IconEdit size={20} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        ),
      },
    ],
    [orderStatus]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleCheckView = async (row: any) => {
    const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.orderNumber}`;
    const response = await repositoryPos.get<MessageResponse<any>>(url);

    if (response && response.success) {
      // Kiểm tra nếu orderNumber chứa "DT"
      if (row.original.orderNumber.includes("DT")) {
        navigate("/sell/view-order-return", {
          state: {
            id: row.original.orderId,
            orderNumber: row.original.orderNumber,
            orderType: row.original.orderType,
            actionType: "view",
          },
        });
      } else {
        navigate("/sell/view-order", {
          state: {
            id: row.original.orderId,
            orderNumber: row.original.orderNumber,
            orderType: row.original.orderType,
            actionType: "view",
          },
        });
      }
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate.length > 0) {
      url += `&StartTimeCreate=${search.startDate}`;
    }

    if (search.endDate.length > 0) {
      url += `&EndTimeCreate=${search.endDate}`;
    }

    if (search.branchId.length > 0) {
      url += `&BrandID=${search.branchId}`;
    }

    if (search.printInvoiceCheck.length > 0) {
      url += `&PrintInvoiceCheck=${search.printInvoiceCheck}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeaderVat/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const modalsEdits = (orderNumber: any) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Viết hóa đơn VAT</Title>
        </>
      ),
      size: "auto",
      children: (
        <ModalVAT orderNumber={orderNumber} onClose={setDeleteViewStatus} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const getDataBranchSelect = async () => {
    const res = await getMasterOrgSelect();
    setDataBranchSelect(
      res
        ?.filter(
          (item) =>
            item.value != null && item.text != null && item.value != "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (140 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={{ sm: "flex-end", lg: "flex-end" }}
            align="center"
            direction="row"
            wrap="wrap"
          >
            {/* <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu> */}
          </Flex>
        </Flex>
        <Divider label="Tìm kiếm" labelPosition="center" />
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              label="Từ ngày"
              size="sm"
              placeholder="Từ ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              value={
                search.startDate.length > 0
                  ? new Date(search.startDate ?? "")
                  : null
              }
              onChange={(e) => {
                handleChangeSearchValue(
                  e ? formatDateNotTimeZone(e ?? "") : "",
                  "startDate"
                );
              }}
              leftSection={<IconCalendar color="#15aabf" />}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 1.8 }}>
            <DatePickerInput
              label="Đến ngày"
              size="sm"
              placeholder="Đến ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              value={
                search.endDate.length > 0
                  ? new Date(search.endDate ?? "")
                  : null
              }
              onChange={(e) => {
                handleChangeSearchValue(
                  e ? formatDateNotTimeZone(e ?? "") : "",
                  "endDate"
                );
              }}
              leftSection={<IconCalendar color="#15aabf" />}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              label="Chi nhánh"
              placeholder="Chi nhánh"
              data={dataBranchSelect}
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              onClick={() => {
                if (dataBranchSelect.length === 0) {
                  getDataBranchSelect();
                }
              }}
              onChange={(e) => handleChangeSearchValue(e ?? "", "branchId")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              label="Loại"
              placeholder="Loại"
              data={[
                { label: "Có", value: "1" },
                { label: "Không", value: "0" },
                { label: "Nợ", value: "2" },
              ]}
              clearable
              leftSection={<IconClipboardList color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(e ?? "", "printInvoiceCheck")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              label="Tìm kiếm"
              placeholder="Nhập từ khóa"
              defaultValue={keySearch}
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                fetchData();
              }}
              mt={21}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
        <Divider
          label="Thông tin người viết hóa đơn VAT"
          labelPosition="center"
        />
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.2 }}>
            <TextInput
              label="Nhân viên"
              placeholder="Nhân viên"
              value={`${
                JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
                  ?.fullname
              } - ${
                JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
                  ?.code
              }`}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.2 }}>
            <TextInput
              label="Chức danh"
              placeholder="Chức danh"
              value={
                JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
                  ?.roleName
              }
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.2 }}>
            <TextInput
              label="Bộ phận"
              placeholder="Bộ phận"
              value={
                JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
                  ?.departmentName
              }
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.2 }}>
            <TextInput
              label="Chi nhánh"
              placeholder="Chi nhánh"
              value={
                JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
                  ?.brachName
              }
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3.2 }}>
            <TextInput
              label="Địa điểm"
              placeholder="Địa điểm"
              value={
                JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
                  ?.workAddress
              }
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
  });

  return (
    <>
      <div>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default WriteVAT;
