import { Badge, Box, Button, Input, Select } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  IconBarcode,
  IconBuildingSkyscraper,
  IconBuildingWarehouse,
  IconSearch,
  IconTimeDuration15,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  baseTheme,
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateNotTimeZone } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { SelectListItemModel } from "../../../model/SelectListItem";
import { green } from "../../../const/variables";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { toDate } from "date-fns";

import {
  AllCommunityModule,
  CellSelectionChangedEvent,
  ColDef,
  DefaultMenuItem,
  GetContextMenuItemsParams,
  GridReadyEvent,
  INumberCellEditorParams,
  RowSelectionOptions,
  StatusPanelDef,
  ValueFormatterParams,
} from "ag-grid-community";
import { AG_GRID_LOCALE_VN } from "@ag-grid-community/locale";
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import { ClientSideRowModelModule, ModuleRegistry } from "ag-grid-enterprise";
import { MessageResponse } from "../../../model/MessageResponse";

export interface InvBalancesGtmpModel {
  organizationId?: number; // decimal?
  orgCode: string; // string
  subinventoryCode: string; // string
  inventoryItemId?: number; // decimal?
  itemCode: string; // string
  itemName: string; // string
  sltonDau?: number; // decimal?
  gttonDau?: number; // decimal?
  slNhap?: number; // decimal?
  gtNhap?: number; // decimal?
  slXuat?: number; // decimal?
  gtXuat?: number; // decimal?
  sltonCuoi?: number; // decimal?
  gttonCuoi?: number; // decimal?
}

const ChangeInformation = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  // const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<InvBalancesGtmpModel[]>([]);
  const [dataInvCode, setDataInvCode] = useState<SelectListItemModel[]>([]);
  const [dataItemCode, setDataItemCode] = useState<SelectListItemModel[]>([]);
  const [dataSaleChannel, setDataBranchCode] = useState<SelectListItemModel[]>(
    []
  );
  const [rowCount, setRowCount] = useState(0);
  type InventoryItem = {
    organizationId: number;
    orgCode: string;
    subinventoryCode: string;
    inventoryItemId: number;
    itemCode: string;
    sltonDau: number;
    gttonDau: number;
    slNhap: number;
    gtNhap: number;
    slXuat: number;
    gtXuat: number;
    sltonCuoi: number;
    gttonCuoi: number;
  };
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      skip: 0,
      take: 25,
      orgId: "488",
      subInvCode: "",
      itemCode: "",
      itemName: "",
    },
  });

  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  // Hàm lấy dữ liệu
  const fetchData = async (value: any) => {
    setIsLoading(true);
    const paginatedValue = {
      ...value,
      skip: pagination?.pageIndex * pagination?.pageSize,
      take: pagination?.pageSize,
    };
    const queryString = new URLSearchParams(paginatedValue as any).toString();

    try {
      const response = await repositoryMdm.get<
        MessageResponse<InvBalancesGtmpModel[]>
      >(`/api/v1/SalesReport/get-list-inv_balance-report?${queryString}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      }
    } catch (error) {
      setData([]);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchData(form.getValues());
  // }, [pagination]);

  const DataInvCode = async () => {
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmInventory/get-all?checkAuth=true`
      );
      if (response && response.httpStatusCode === 200) {
        const transformedData = response.data
          .filter((i: any) => i.value && i.text)
          .map((item: any) => {
            return {
              value: item.att1,
              label: item.text,
            };
          });

        setDataInvCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataBrachCode = async () => {
    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmInventory/get-select-branch?auth=true`
      );
      if (response && response.success) {
        const transformedData = response.data
          .filter((i: any) => i.value && i.text)
          .map((item: any) => {
            return {
              value: item.value,
              label: item.att2 + " - " + item.text,
            };
          });

        setDataBranchCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    DataBrachCode();
  }, []);
  const DataItemCode = async (keySearch: string) => {
    try {
      let url = "";
      if (keySearch && keySearch.length > 0) {
        url += `&ItemCodes=${keySearch}`;
      }
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-list-item-by-inv?Skip=0&Take=50` +
          url
      );
      if (response && response.success) {
        const transformedData = response.data
          ?.filter((i: any) => i.maSp && i.tenSp)
          .map((branch: any) => ({
            value: branch.maSp,
            label: branch.maSp + " - " + branch.tenSp,
          }));
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.value))
        ).map((value) =>
          transformedData.find((item: any) => item.value === value)
        );
        setDataItemCode(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    if (form.getValues().itemCode?.length > 3) {
      setTimeout(() => {
        DataItemCode(form.getValues().itemCode);
      }, 500);
    }
  }, [form.getValues().itemCode]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);
  //#region  ag -grid
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    AllCommunityModule,
  ]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (350 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const gridRef = useRef<AgGridReact<InvBalancesGtmpModel>>(null);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: height, marginTop: "15px" }),
    [height]
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<InvBalancesGtmpModel[]>([]);
  const columnDefs = useMemo<ColDef<InvBalancesGtmpModel>[]>(
    () => [
      {
        field: "organizationId",
        headerName: "Mã tổ chức",
        filter: "agNumberColumnFilter",
        width: 150,
      },
      {
        field: "orgCode",
        headerName: "Mã chi nhánh",
        filter: "agTextColumnFilter",
        width: 150,
      },
      {
        field: "subinventoryCode",
        headerName: "Mã kho",
        filter: "agTextColumnFilter",
        width: 100,
      },
      {
        field: "itemCode",
        headerName: "Mã sản phẩm",
        filter: "agTextColumnFilter",
        width: 150,
      },
      {
        field: "itemName",
        headerName: "Tên sản phẩm",
        filter: "agTextColumnFilter",
        width: 500,
      },
      {
        field: "sltonDau",
        headerName: "Số lượng tồn đầu",
        filter: "agNumberColumnFilter",
        width: 200,
      },
      {
        field: "gttonDau",
        headerName: "Giá trị tồn đầu",
        filter: "agNumberColumnFilter",
        width: 150,
        cellRenderer: (
          params: CustomCellRendererProps<InvBalancesGtmpModel>
        ) => <>{formatCurrencyVND(params.value) || 0}</>,
      },
      {
        field: "slNhap",
        headerName: "Số lượng nhập",
        filter: "agNumberColumnFilter",
        width: 150,
      },
      {
        field: "gtNhap",
        headerName: "Giá trị nhập",
        filter: "agNumberColumnFilter",
        width: 150,
        cellRenderer: (
          params: CustomCellRendererProps<InvBalancesGtmpModel>
        ) => <>{formatCurrencyVND(params.value) || 0}</>,
      },
      {
        field: "slXuat",
        headerName: "Số lượng xuất",
        filter: "agNumberColumnFilter",
        width: 150,
      },
      {
        field: "gtXuat",
        headerName: "Giá trị xuất",
        filter: "agNumberColumnFilter",
        width: 150,
        cellRenderer: (
          params: CustomCellRendererProps<InvBalancesGtmpModel>
        ) => <>{formatCurrencyVND(params.value) || 0}</>,
      },
      {
        field: "sltonCuoi",
        headerName: "Số lượng tồn cuối",
        filter: "agNumberColumnFilter",
        width: 150,
      },
      {
        field: "gttonCuoi",
        headerName: "Giá trị tồn cuối",
        filter: "agNumberColumnFilter",
        width: 150,
        cellRenderer: (
          params: CustomCellRendererProps<InvBalancesGtmpModel>
        ) => <>{formatCurrencyVND(params.value) || 0}</>,
      },
    ],
    [rowData]
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      filter: true,
      floatingFilter: true,
    };
  }, []);

  const rowSelection = useMemo<
    RowSelectionOptions | "single" | "multiple"
  >(() => {
    return { mode: "multiRow" };
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent" },
        { statusPanel: "agTotalRowCountComponent" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    // const date = new Date();
    // form.setFieldValue("fromDate", formatDateNotTimeZone(date) || "");
    // form.setFieldValue("toDate", formatDateNotTimeZone(date) || "");

    await fetchData(form.getValues());
  }, []);

  //#endregion
  return (
    // <Box>
    //   <MantineReactTable table={table} />
    // </Box>
    <>
      <div>
        <_breadcrumb></_breadcrumb>
        <Box
          component="form"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
          onSubmit={form.onSubmit(async (e: any) => {
            await fetchData(e);
          })}
        >
          <Input
            style={{
              flex: 1,
              maxWidth: "180px",
              minWidth: "180px",
              display: "none",
            }}
            placeholder="Nhập tìm kiếm"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            {...form.getInputProps("keySearch")}
          />
          <Select
            placeholder="Chọn chi nhánh"
            data={dataSaleChannel}
            leftSection={<IconBuildingSkyscraper size={20} color="#15aabf" />}
            clearable
            searchable
            value={
              form.getValues().orgId ? form.getValues().orgId?.toString() : ""
            }
            onChange={(e) =>
              form.setValues((prev) => ({ ...prev, orgId: e ? e : "" }))
            }
          />
          <Select
            id="subInvCode"
            key={form.key("subInvCode")}
            placeholder="Chọn kho"
            data={dataInvCode}
            leftSection={<IconBuildingWarehouse size={20} color="#15aabf" />}
            clearable
            searchable
            {...form.getInputProps("subInvCode")}
            onClick={() => {
              if (dataInvCode.length === 0) {
                DataInvCode();
              }
            }}
          />
          <DateInput
            size="sm"
            placeholder="Chọn ngày bắt đầu"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            maxDate={new Date()}
            leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
            {...form.getInputProps("fromDate")}
            onChange={(date) => {
              form.setFieldValue("fromDate", formatDateNotTimeZone(date) || "");
            }}
            clearable
          />
          <DateInput
            size="sm"
            placeholder="Chọn ngày kết thúc"
            locale="vi"
            leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
            valueFormat="DD/MM/YYYY"
            maxDate={new Date()}
            {...form.getInputProps("toDate")}
            onChange={(date) => {
              form.setFieldValue("toDate", formatDateNotTimeZone(date) || "");
            }}
            clearable
          />
          <Select
            placeholder="Chọn mã sản phẩm"
            data={dataItemCode}
            leftSection={<IconBarcode size={20} color="#15aabf" />}
            value={
              form.getValues().itemCode
                ? form.getValues().itemCode?.toString()
                : ""
            }
            clearable
            searchable
            onChange={(e) =>
              form.setValues((prev) => ({ ...prev, itemCode: e ? e : "" }))
            }
            onSearchChange={(value) => {
              if (value.length >= 3 && form.getValues().itemCode === "") {
                DataItemCode(value);
                form.setValues((prev) => ({ ...prev, itemName: value }));
              } else if (
                form.getValues().itemCode?.length > 0 &&
                value === form.getValues().itemName
              ) {
                DataItemCode(form.getValues().itemCode);
              } else if (
                form.getValues().itemCode?.length > 0 &&
                value !== form.getValues().itemName
              ) {
                DataItemCode(value);
                form.setValues((prev) => ({ ...prev, itemName: value }));
              }
            }}
          />
          <Button color="blue" variant="outline" type="submit">
            Tìm kiếm
          </Button>
        </Box>
      </div>

      <div style={containerStyle}>
        <div style={gridStyle} className={"ag-theme-quartz"}>
          <AgGridReact<InvBalancesGtmpModel>
            ref={gridRef}
            rowData={data}
            theme={baseTheme}
            loading={isLoading}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressColumnVirtualisation={true}
            suppressRowVirtualisation={true}
            pagination={true}
            localeText={AG_GRID_LOCALE_VN}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            cacheBlockSize={paginationPageSize}
            maxBlocksInCache={3}
            rowSelection={undefined}
            statusBar={statusBar}
            //  onGridReady={onGridReady}
          />
        </div>
      </div>
    </>
  );
};

export default ChangeInformation;
