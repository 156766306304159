import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  NumberInput,
  Select,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { _localization } from "../../../config/location";
import { IconEdit, IconSearch, IconTrash } from "@tabler/icons-react";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { TblWarrantyItem } from "../../../model/TblWarrantyItem";
import { TblWarrantyServiceRequestForm } from "../../../model/TblWarrantyServiceRequestForm";
import { modals } from "@mantine/modals";
import OutsideProduct from "./OutsideProduct";
import {
  _timerDelayClick,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import ContinueCreate from "./ContinueCreate";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { DatePickerInput } from "@mantine/dates";

const ItemList = ({
  onSelect,
  dataItem,
  isDetail,
  onDelete,
  actionView,
  setDataWarrantyItem,
  serviceType,
  setFormValues,
  typeProduct,
  handleSearchByPhone,
}: ItemListProps) => {
  //#region state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarrantyItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [serial, setSerial] = useState("");
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "serial",
        header: "Serial",
        size: 30,
        enableColumnActions: false,
      },
      {
        accessorKey: "orderCode",
        header: "Hóa đơn",
        Cell: ({ renderedCellValue }) => (
          <Text size="11.5px">
            {renderedCellValue === null
              ? "Không có hóa đơn"
              : renderedCellValue}
          </Text>
        ),
        size: 30,
        enableColumnActions: false,
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 30,
        enableColumnActions: false,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={300}>
            <Tooltip label={row.original.itemName}>
              <Text size="11.5px" truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
        size: 250,
        enableColumnActions: false,
      },
      {
        accessorKey: "warrantyTerm",
        header: "Hạn bảo hành",
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue === null || renderedCellValue === 0
              ? "Không bảo hành"
              : `${renderedCellValue} tháng`}
          </>
        ),
        size: 30,
        enableColumnActions: false,
      },
      {
        accessorKey: "attribute4",
        header: "Bảo hành còn",
        Cell: ({ row }) => (
          <Text size="11.5px">
            {row.original.attribute4 === null ||
            row.original.attribute4 === undefined ||
            Number.isNaN(row.original.attribute4)
              ? "Không bảo hành"
              : row.original.attribute4 === 0
              ? "Hết hạn bảo hành"
              : `${row.original.attribute4} tháng`}
          </Text>
        ),
        size: 30,
        enableColumnActions: false,
      },
      {
        accessorKey: "serviceType",
        header: "Loại dịch vụ",
        Cell: ({ row }) => (
          <Select
            placeholder="Chọn loại dịch vụ"
            value={
              actionView !== "DETAIL"
                ? row.original.serviceType
                  ? row.original.serviceType
                  : null
                : serviceType !== undefined
                ? serviceType[row.index].serviceType?.toString()
                : null
            }
            data={[
              {
                label: "Bảo hành",
                value: "Bảo hành",
                disabled:
                  row.original.attribute4 === 0 ||
                  Number.isNaN(row.original.attribute4) ||
                  row.original.attribute4 === null,
              },
              { label: "Hỗ trợ", value: "Hỗ trợ" },
              { label: "Dịch vụ", value: "Dịch vụ" },
            ]}
            disabled={actionView === "DETAIL" || row.original.itemCode === null}
            onChange={(e) =>
              setDataWarrantyItem((prev: any) =>
                prev.map((item: any, index: number) =>
                  index === row.index ? { ...item, serviceType: e } : item
                )
              )
            }
          />
        ),
        size: 140,
        enableColumnActions: false,
      },
      {
        accessorKey: "attribute5",
        header: "Số ngày hẹn trả",
        Cell: ({ row }) => (
          <NumberInput
            placeholder="Nhập số ngày hẹn trả"
            hideControls
            allowNegative
            value={Number(row.original.attribute5)}
            min={1}
            suffix=" ngày"
            onChange={(e) => {
              const today = new Date();
              const entryDate = new Date();
              entryDate.setDate(today.getDate() + Number(e));

              setDataWarrantyItem((prev: any) =>
                prev.map((item: any, index: number) =>
                  index === row.index
                    ? {
                        ...item,
                        attribute5: e?.toString(),
                        entryDate: entryDate?.toISOString(),
                      }
                    : item
                )
              );
            }}
            disabled={actionView === "DETAIL" || row.original.itemCode === null}
          />
        ),
        enableColumnActions: false,
        size: 100,
      },
      {
        accessorKey: "entryDate",
        header: "Ngày hẹn trả",
        Cell: ({ row }) => (
          <DatePickerInput
            placeholder="Nhập ngày hẹn trả"
            valueFormat="DD/MM/YYYY"
            minDate={new Date()}
            value={
              row.original.entryDate ? new Date(row.original.entryDate) : null
            }
            onChange={(e) => {
              setDataWarrantyItem((prev: any) =>
                prev.map((item: any, index: number) =>
                  index === row.index
                    ? {
                        ...item,
                        attribute5: e
                          ? Math.floor(
                              (new Date(e).getTime() - new Date().getTime()) /
                                (1000 * 60 * 60 * 24)
                            )
                          : null,
                        entryDate: e ? new Date(e ?? "")?.toISOString() : null,
                      }
                    : item
                )
              );
            }}
            disabled={actionView === "DETAIL" || row.original.itemCode === null}
          />
        ),
        enableColumnActions: false,
        size: 125,
      },
      {
        accessorKey: "customerName",
        header: "Người mua",
        Cell: ({ row }) => (
          <Text size="11.5px">
            {actionView === "DETAIL"
              ? row.original.customerOrderName
              : !row.original.customerOrderName
              ? row.original.customerName
              : row.original.customerOrderName}
          </Text>
        ),
        size: 30,
        enableColumnActions: false,
      },
      {
        accessorKey: "buyingDate",
        header: "Ngày mua",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
        enableColumnActions: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {typeProduct === "HANG_NGOAI" && actionView !== "DETAIL" ? (
              <Tooltip label="Điền thông tin sản phẩm">
                <ActionIcon
                  variant="light"
                  color="green"
                  onClick={() => modalOutsideProduct(row.index)}
                >
                  <IconEdit size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            ) : (
              <></>
            )}
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={() => {
                  deleteData(row.original.serial ?? "");
                }}
                disabled={actionView === "DETAIL"}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [serviceType, typeProduct, dataItem]
  );

  const changeValue = () => {
    setSerial("");
    setDataWarrantyItem((prev: TblWarrantyItem[]) => [
      ...prev,
      {
        itemId: null,
        itemName: null,
        itemCode: null,
        orderId: null,
        orderCode: null,
        serial: serial,
        parentSerial: null,
        warrantyTerm: 0,
        customerId: null,
        phone: null,
        customerName: null,
        saleChanelId: null,
        buyingDate: null,
        serviceType: null,
      },
    ]);
    setFormValues((prev: TblWarrantyServiceRequestForm) => ({
      ...prev,
      attribute6: "HANG_NGOAI",
    }));
  };

  const deleteData = (serial: string) => {
    setData((prev) => prev.filter((e) => e?.serial !== serial));
    onSelect((prev: any) => prev.filter((e: any) => e?.serial !== serial));
    onDelete((prev: any) => prev.filter((e: any) => e?.serial !== serial));
  };

  const modalsCheckSerial = (dataSerial: any, typeSerial: string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xác nhận tiếp tục !</Title>
        </>
      ),
      size: "auto",
      children: (
        <ContinueCreate
          changeValue={typeSerial === "HANG_NGOAI" ? changeValue : fetchData}
          dataSerial={dataSerial}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const fetchData = async () => {
    const response = await repositoryPos.get(
      `/api/v1/TblWarrantyItem/search-order?Skip=0&Take=2&Serial=${serial}`
    );
    if (response && response.success) {
      const result = response.data;
      if (result.length === 1) {
        setDataWarrantyItem((prev: TblWarrantyItem[]) => [
          ...prev,
          ...result.map((item: any) => {
            const monthsDifference =
              (new Date().getFullYear() -
                new Date(item?.buyingDate ?? "").getFullYear()) *
                12 +
              (new Date().getMonth() -
                new Date(item?.buyingDate ?? "").getMonth());

            return {
              ...item,
              serviceType: null,
              customerOrderId: item.customerId,
              customerName: item.customerName,
              attribute4: Math.max(
                item?.warrantyTerm ?? 0 - monthsDifference + 1,
                0
              ),
            };
          }),
        ]);

        setFormValues((prev: TblWarrantyServiceRequestForm) => ({
          ...prev,
          attribute6: "HANG_HACOM",
        }));

        setSerial("");
      } else {
        NotificationExtension.Fails("Không tìm thấy sản phẩm !");
      }
    }
  };

  const handleCheckSerial = async (typeSerial: string) => {
    const res = await repositoryMdm.post<MessageResponse<any>>(
      `/api/v1/TblWarrantyServiceRequestForm/check-serial?branchId=${
        JSON.parse(localStorage.getItem("userLoginHeader") || "{}")?.branchId
      }`,
      [serial?.trim()]
    );

    if (res && res?.success) {
      const result = res?.data;

      if (result[0]?.status === 1) {
        if (typeSerial === "HANG_NGOAI") {
          changeValue();
        } else {
          fetchData();
        }
      } else if (result[0]?.status === 2) {
        modalsCheckSerial(result, typeSerial);
      } else if (result[0]?.status === 3) {
        NotificationExtension.Fails(
          `Serial ${result[0]?.serial} đã được tiếp nhận ở chi nhánh ${result[0]?.branchName}`
        );
        return;
      }
    } else {
      return;
    }
  };

  const modalOutsideProduct = (index: number) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thông tin sản phẩm</Title>
        </>
      ),
      size: "auto",
      children: (
        <OutsideProduct
          setDataWarrantyItem={setDataWarrantyItem}
          index={index}
          dataItem={dataItem}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList?.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (onSelect) onSelect(data);
  }, [data]);

  useEffect(() => {
    setData(
      dataItem?.filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => JSON.stringify(t) === JSON.stringify(value))
      )
    );
  }, [dataItem]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (395 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data: dataItem ? dataItem : [],
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Text p={"xs"} fw={"bold"}>
            Thông tin sản phẩm
          </Text>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: () =>
      !isDetail && (
        <Flex gap={"md"} align={"center"}>
          {actionView !== "DETAIL" ? (
            <>
              <Button
                onClick={() => handleSearchByPhone("truoc2025")}
                leftSection={<IconSearch size={14} />}
                disabled={typeProduct === "HANG_NGOAI"}
              >
                Tìm kiếm sản phẩm trước 2025
              </Button>
              <Button
                onClick={() => handleSearchByPhone("sau2025")}
                leftSection={<IconSearch size={14} />}
                disabled={typeProduct === "HANG_NGOAI"}
              >
                Tìm kiếm sản phẩm
              </Button>
              <Divider size="sm" orientation="vertical" />
              <TextInput
                placeholder="Nhập mã serial hoặc quét mã"
                value={serial}
                onChange={(e) => setSerial(e.currentTarget.value)}
              />
              <Button
                disabled={typeProduct === "HANG_NGOAI" || serial.length === 0}
                color={"green"}
                onClick={() => handleCheckSerial("HANG_HACOM")}
              >
                Quét mã
              </Button>
              <Text size="sm" c={"#BFBFBF"} fs={"italic"}>
                hoặc
              </Text>
              <Button
                color="blue"
                disabled={serial.length === 0 || typeProduct === "HANG_HACOM"}
                onClick={() => {
                  handleCheckSerial("HANG_NGOAI");
                }}
              >
                Quét hàng ngoài
              </Button>
            </>
          ) : (
            <></>
          )}
        </Flex>
      ),
    renderBottomToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowSelection: false,
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["serial"],
        right: ["action"],
      },
      // columnVisibility: getColumnVisibility(typeProduct ?? ""),
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "11px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  // React.useEffect(() => {
  //   const newColumnVisibility = getColumnVisibility(typeProduct ?? "");
  //   if (table) {
  //     table.setColumnVisibility(newColumnVisibility);
  //   }
  // }, [typeProduct, table]);

  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

type ItemListProps = {
  onSelect?: any;
  isDetail?: boolean;
  dataItem: TblWarrantyItem[];
  onDelete: any;
  actionView?: any;
  setDataWarrantyItem?: any;
  serviceType?: any;
  setFormValues?: any;
  typeProduct?: any;
  handleSearchByPhone?: any;
};
export default ItemList;
