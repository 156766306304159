import {
  Badge,
  Box,
  Button,
  Group,
  Input,
  Menu,
  MultiSelect,
  MultiSelectProps,
  rem,
  Text,
} from "@mantine/core";
import {
  IconBuildingSkyscraper,
  IconBuildingWarehouse,
  IconCalendar,
  IconCaretDown,
  IconFileExport,
  IconSearch,
  IconTimeDuration15,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { SelectListItemModel } from "../../../model/SelectListItem";
import { DatePickerInput } from "@mantine/dates";
import { BASE_API_MDM } from "../../../config";
const DeliveryPerformanceReport = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<Department[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [dataInvCode, setDataInvCode] = useState<SelectListItemModel[]>([]);
  const [dataSaleChannel, setDataBranchCode] = useState<SelectListItemModel[]>(
    []
  );
  const [search, setSearch] = useState({
    keySearch: "",
    StartTime: "",
    EndTime: "",
    branchCode: [],
    BpCode: [],
  });
  const handleChangeSearchValue = (
    value: string | null | string[],
    key: string
  ) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0 ? value : [],
    }));
  };

  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "ngayCt",
        header: "Ngày lập",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "ngayPc",
        header: "Ngày phân công",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Button
              variant="light"
              p="4px 8px"
              radius="xl"
              size="xs"
              color="violet"
              fw="bold"
            >
              {renderedCellValue}
            </Button>
          </>
        ),
      },
      {
        accessorKey: "tenBp",
        header: "Bộ phận giao hàng",
      },
      {
        accessorKey: "soCtGoc",
        header: "Số chứng từ gốc",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "maCt",
        header: "Số vận đơn",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "soHd",
        header: "Số giao dịch",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "tenNguoiGh",
        header: "Tên nhân viên giao hàng",
      },
      {
        accessorKey: "tenCn",
        header: "Đia chỉ nhận hàng",
      },
      {
        accessorKey: "dcGiao",
        header: "Đia chỉ giao hàng",
      },
      {
        accessorKey: "dcNhan",
        header: "Địa chỉ nhận",
      },
      {
        accessorKey: "ghiChuDh",
        header: "Ghi chú đơn hàng",
      },
      {
        accessorKey: "ghiChuGh",
        header: "Ghi chú giao hàng",
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
      },
      {
        accessorKey: "telephoneNumber",
        header: "SĐT khách hàng",
      },
      {
        accessorKey: "distance",
        header: "Số km",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (!value && value !== 0) return null;
          const distanceInKm = (Number(value) / 1000).toFixed(2);
          return `${distanceInKm} km`;
        },
      },
      {
        accessorKey: "receptionTime",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "ngayGh",
        header: "Ngày giao hàng",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "arrivalTime",
        header: "Ngày đến nơi",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "completionTime",
        header: "Ngày hoàn thành",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tgXuatKho",
        header: "Ngày giờ xuất kho",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tetCompletionTime",
        header: "Ngày giờ ktkt",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "ttGh",
        header: "Trạng thái đơn giao hàng",
        size: 225,
        Cell: ({ renderedCellValue }: any) => {
          const commonStyle = {
            padding: "2px 4px",
            borderRadius: "4px",
            border: "1px solid",
          };

          const specificStyle =
            renderedCellValue === "Hàng đã giao"
              ? {
                  color: "green",
                  backgroundColor: "#e0ffe0",
                  borderColor: "green",
                }
              : renderedCellValue === "Đã đến nơi"
              ? {
                  color: "blue",
                  backgroundColor: "#e0e0ff",
                  borderColor: "blue",
                }
              : renderedCellValue === "Đã phân công"
              ? {
                  color: "orange",
                  backgroundColor: "#fff0e0",
                  borderColor: "orange",
                }
              : renderedCellValue === "Đơn hàng giao chưa thành công do Hacom"
              ? {
                  color: "red",
                  backgroundColor: "#ffe0e0",
                  borderColor: "red",
                }
              : renderedCellValue === "Đang giao hàng"
              ? {
                  color: "#FF69B4",
                  backgroundColor: "#FFD1DC",
                  borderColor: "#FF69B4",
                }
              : {};

          const style = { ...commonStyle, ...specificStyle };

          return <span style={style}>{renderedCellValue}</span>;
        },
      },
      {
        accessorKey: "tienGiuiXe",
        header: "Tiền gửi xe",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue !== null &&
            renderedCellValue !== "0" &&
            renderedCellValue !== 0
              ? `${formatCurrencyVND(Number(renderedCellValue))} VNĐ`
              : ""}
          </Text>
        ),
      },
      {
        accessorKey: "hinhThucTt",
        header: "Hình thức thanh toán",
        size: 200,
      },
      {
        accessorKey: "tongTienDonHang",
        header: "Tổng tiền đơn hàng",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue !== null &&
            renderedCellValue !== "0" &&
            renderedCellValue !== 0
              ? `${formatCurrencyVND(Number(renderedCellValue))} VNĐ`
              : ""}
          </Text>
        ),
      },
      {
        accessorKey: "tongTienDaThanhToan",
        header: "Đã thanh toán",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue !== null &&
            renderedCellValue !== "0" &&
            renderedCellValue !== 0
              ? `${formatCurrencyVND(Number(renderedCellValue))} VNĐ`
              : ""}
          </Text>
        ),
      },
      {
        accessorKey: "tongPhaiThanhToan",
        header: "Tổng phải thanh toán",
        size: 200,
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue !== null &&
            renderedCellValue !== "0" &&
            renderedCellValue !== 0
              ? `${formatCurrencyVND(Number(renderedCellValue))} VNĐ`
              : ""}
          </Text>
        ),
      },
      {
        accessorKey: "tinhLuong",
        header: "Tính lương",
        Cell: ({ renderedCellValue }: any) => (
          <span
            style={{
              color: renderedCellValue ? "green" : "red",
              backgroundColor: renderedCellValue ? "#e0ffe0" : "#ffe0e0",
              border: "1px solid",
              borderColor: renderedCellValue ? "green" : "red",
              padding: "2px 4px",
              borderRadius: "4px",
            }}
          >
            {renderedCellValue ? "Có" : "Không"}
          </span>
        ),
      },
      {
        accessorKey: "writeNumberOrder",
        header: "Số hóa đơn VAT",
      },
      {
        accessorKey: "codeKd",
        header: "Mã KD",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Button
              variant="light"
              p="4px 8px"
              radius="xl"
              size="xs"
              color="blue"
              fw="bold"
            >
              {renderedCellValue}
            </Button>
          </>
        ),
      },
      {
        accessorKey: "tenKd",
        header: "Tên kd",
        Cell: ({ renderedCellValue }) => (
          <>
            <AvatarUtils
              value={renderedCellValue?.toString().split("-")[0] ?? ""}
            />
          </>
        ),
      },
    ],
    []
  );
  const DataInvCode = async () => {
    try {
      const response = await repositoryPos.get(`/api/v1/TblDepartment/get-all`);
      if (response && response.httpStatusCode === 200) {
        const transformedData = response.data
          .filter((i: any) => i.deparmentCode && i.name)
          .map((item: any) => {
            return {
              value: item.deparmentCode,
              label: item.deparmentCode + " - " + item.name,
            };
          });
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.value))
        ).map((value) =>
          transformedData.find((item: any) => item.value === value)
        );
        setDataInvCode(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataBrachCode = async () => {
    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmInventory/get-select-branch?auth=true`
      );
      if (response && response.success) {
        const transformedData = response.data
          .filter((i: any) => i.att2 && i.text)
          .map((item: any) => {
            return {
              value: item.att2,
              label: item.att2 + " - " + item.text,
            };
          });

        setDataBranchCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    if (search.StartTime) {
      url += `&startDate=${search.StartTime}`;
    }
    if (search.branchCode && search.branchCode.length > 0) {
      search.branchCode.forEach((branchCode) => {
        url += `&MaCns=${branchCode}`;
      });
    }
    if (search.BpCode && search.BpCode.length > 0) {
      search.BpCode.forEach((BpCode) => {
        url += `&MaBps=${BpCode}`;
      });
    }
    if (search.EndTime) {
      url += `&endDate=${search.EndTime}`;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/VEmployeeDeliveryPayroll/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    rowCount,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "soCtGoc"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Input
            style={{ flex: 1, maxWidth: "180px" }}
            placeholder="Nhập từ khóa"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "keySearch");
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />
          <MultiSelect
            placeholder="Chi nhánh"
            clearable
            searchable
            value={search.branchCode}
            data={dataSaleChannel.map((x) => x.value)}
            leftSection={<IconBuildingSkyscraper size={20} color="#15aabf" />}
            onChange={(value) => {
              handleChangeSearchValue(value, "branchCode");
            }}
            renderOption={renderMultiSelectOptionBrachCide}
            onClick={() => {
              if (dataSaleChannel.length === 0) {
                DataBrachCode();
              }
            }}
          />
          <MultiSelect
            size="sm"
            placeholder="Mã Bộ phận"
            searchable
            clearable
            data={dataInvCode.map((x) => x.value)}
            value={search.BpCode}
            renderOption={renderMultiSelectOptionInvCode}
            leftSection={<IconBuildingWarehouse size={20} color="#15aabf" />}
            onClick={() => {
              if (dataInvCode.length === 0) {
                DataInvCode();
              }
            }}
            onChange={(value) => {
              handleChangeSearchValue(value, "BpCode");
            }}
          />
          <DatePickerInput
            size="sm"
            placeholder="Chọn ngày bắt đầu"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(formatDateNotTimeZone(e), "StartTime");
            }}
            w={200}
            clearable
          />
          <DatePickerInput
            size="sm"
            placeholder="Chọn ngày kết thúc"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(formatDateNotTimeZone(e), "EndTime");
            }}
            w={200}
            clearable
          />
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Chức năng
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconFileExport style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => exportDataExcel()}
            >
              Export Excel
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
            >
              Đóng
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const exportDataExcel = async () => {
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    if (search.StartTime) {
      url += `&startDate=${search.StartTime}`;
    }
    if (search.branchCode && search.branchCode.length > 0) {
      search.branchCode.forEach((branchCode) => {
        url += `&MaCns=${branchCode}`;
      });
    }
    if (search.BpCode && search.BpCode.length > 0) {
      search.BpCode.forEach((BpCode) => {
        url += `&MaBps=${BpCode}`;
      });
    }
    if (search.EndTime) {
      url += `&endDate=${search.EndTime}`;
    }
    const baseUrl = `/api/v1/ExportExcel/export-employee-delivery-payroll-4${url}`;

    const _url = BASE_API_MDM + baseUrl;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    let filename = "bao_cao_thuc_hien_giao_van.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);
  useEffect(() => {
    fetchData();
  }, [pagination]);
  const renderMultiSelectOptionInvCode: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>
        {dataInvCode.find((x: any) => x.value === option.value)?.label}
      </Text>
    </Group>
  );
  const renderMultiSelectOptionBrachCide: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>
        {dataSaleChannel.find((x) => x.value === option.value)?.label}
      </Text>
    </Group>
  );
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DeliveryPerformanceReport;
type Department = {
  ngayCt: string;
  maCt: string;
  soCtGoc: string | null;
  maKh: string;
  tenKh: string;
  nhomKh: string;
  telephoneNumber: string;
  deptId: number | null;
  maBp: string | null;
  tenBp: string | null;
  soHd: string;
  ngayHd: string | null;
  dcNhan: string;
  deliveryDeptCode: string | null;
  deliveryDept: string | null;
  branchId: number;
  maCn: string;
  tenCn: string;
  tenDuong: string;
  tenPhuong: string;
  tenQuan: string;
  sourceType: number;
  dcGiao: string;
  ghiChuGh: string | null;
  ngayGh: string;
  ttGh: string;
  tTienDh: number | null;
  ttDh: string | null;
  trangThaiTt: string | null;
  ttTienMat: number | null;
  ttChuyenKhoan: number | null;
  tongTienTt: number | null;
  tgXuatKho: string;
  maNguoiGh: string;
  tenNguoiGh: string;
  tinhLuong: boolean;
  nguoiLaiXe: string | null;
  dienGiai: string | null;
  receptionTime: string;
  deliveryTime: string | null;
  arrivalTime: string | null;
  completionTime: string | null;
  writeNumberOrder: string | null;
  tetCompletionTime: string | null;
  distance: number;
  distanceTotal: number;
  tongPhaiThanhToan: number | null;
  tongTienDonHang: number | null;
  tongTienDaThanhToan: number | null;
};
