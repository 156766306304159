import { Image } from "@mantine/core";
import { formatDateTransfer } from "../../../../../common/FormatDate/FormatDate";

type Props = {
  componentRef: any;
  dataPrint: any;
};

const PrintXDCCNSerial = ({ componentRef, dataPrint }: Props) => {
  // console.log("dataPrint", dataPrint);
  const dataTransaction = dataPrint?.tblInventoryTransactionModel;
  const dataSerials = dataPrint?.inventoryTransactionSerialModels;

  return (
    <div
      ref={componentRef}
      style={{
        height: "100%",
        margin: "0 auto",
        marginLeft: "40px",
        marginRight: "30px",
        // marginTop: "10px",
        fontFamily: `"Times New Roman", Times, serif`,
        position: "relative",
      }}
    >
      <div
        style={{
          // paddingTop: "10px",
          width: "100%",
          position: "relative",
          margin: "0 auto",
          marginRight: "20px",
          paddingRight: "20px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2>PHIẾU XUẤT ĐIỀU CHUYỂN KHO BARCODE</h2>
        </div>
        <table
          style={{
            marginTop: "10px",
            width: "100%",
            borderCollapse: "collapse",
            // border: "1px solid black",
            marginBottom: "10px",
          }}
        >
          <thead>
            <tr>
              <th colSpan={2} style={{ width: "70%", border: "none" }}></th>
              <th colSpan={2} style={{ width: "30%", border: "none" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Người nhận hàng
              </td>
              <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              >
                {" "}
              </td>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
                colSpan={2}
              >
                Số phiếu: {dataTransaction?.transactionCode}
              </td>
              {/* <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              >
                {" "}
              </td> */}
            </tr>
            <tr>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Tên khách hàng
              </td>
              <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              >
                Công ty cổ phần đầu tư công nghệ HACOM_CN{" "}
                {dataTransaction?.toBranchName}
              </td>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
                colSpan={2}
              >
                Ngày: {formatDateTransfer(dataTransaction?.finishDate)}
              </td>
              {/* Lô hàng chưa có */}
              {/* <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              ></td> */}
            </tr>
            <tr>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Địa chỉ
              </td>
              <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
                colSpan={3}
              >
                {dataTransaction?.toInvAddress}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Diễn giải
              </td>
              <td
                style={{
                  border: "1px solid black",
                }}
                colSpan={3}
              >
                {" "}
                {dataTransaction?.description}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Xuất từ
              </td>
              <td
                style={{
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
                colSpan={3}
              >
                {`${dataTransaction?.fromInvCode}-${dataTransaction?.fromInvName}`}{" "}
                {"dến"}{" "}
                {`${dataTransaction?.toInvCode}-${dataTransaction?.toInvName}`}
              </td>
            </tr>
          </tbody>
        </table>

        {/* table product */}
        <div
          // className={styles.ProductTable}
          style={{
            marginTop: "20px",
            width: "100%",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid black",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  STT
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Mã vật tư
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Tên vật tư
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  SL
                </th>
                {/* <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Serial Number
                </th> */}
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Serial Number
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  LBHG
                </th>
              </tr>
            </thead>
            <tbody>
              {dataSerials?.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        padding: "0 2px",
                      }}
                    >
                      {item?.itemCode}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "left",
                        padding: "0 2px",
                      }}
                    >
                      {item?.itemName}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {item?.primaryQuantity}
                    </td>
                    {/* <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {item?.serial}
                    </td> */}
                    <td
                      style={{
                        border: "1px solid black",
                        padding: "0 2px",
                      }}
                    >
                      {item?.serial}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    ></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: "20px",
            pageBreakInside: "avoid",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              gap: "30px",
            }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontWeight: 700,
                  margin: 0,
                }}
              >
                Thủ kho xuất
              </p>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              {" "}
              <p
                style={{
                  fontWeight: 700,
                  margin: 0,
                }}
              >
                Người lập
              </p>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              {" "}
              <p
                style={{
                  fontWeight: 700,
                  margin: 0,
                }}
              >
                Thủ kho nhập
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        // className={styles.backgroudHacom}
        style={{
          position: "absolute",
          width: "100%",
          zIndex: -1,
          opacity: 0.1,
          top: "40%",
        }}
      >
        <Image
          radius="md"
          src="https://hanoicomputercdn.com/media/lib/17-10-2024/logo-hacom-since-2001_new.png"
          // className={styles.logo}
          style={{
            width: "90%",
            margin: "0 auto",
          }}
        />
      </div>
    </div>
  );
};

export default PrintXDCCNSerial;
