import { ComboboxItem, Select, Text, TextInput, Tooltip } from "@mantine/core";
import React, { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { MessageResponse } from "../../../../model/MessageResponse";
import { tblInventoryTransaction } from "../../../../model/TblOutWard";
import RenderTable from "./RenderTable";
import { SelectListItemBase } from "../../../../_base/model/_base/SelectListItemBase";
import { InventoryItem } from "../../WarehouseOutWard/types/TblWarehouseOutward";
import classesButton from "../../../../../src/Styles/StyleButton.module.css";
interface Inventory {
  inventoryId: number;
  inventoryName: string;
  parentId: number | null; // Nếu giá trị có thể null, sử dụng kiểu này
  inventoryCode: string;
}

const ExchangeReturnInWard = ({
  dataSearchSourceCodeOption,
  dataPosition,
  dataInWard,
  sourceCode,
  setSourceCode,
  handleKeyDownSourceCode,
  dataOrderDetail,
  handleChangeValueInput,
  isSave,
  setSearchSourceCode,
  isEdit,
  isLocked,
  haveStatus,
  haveSourceCode,
  action,
  dataInventoryTransactionType,
}: RequestOrderReturnInWardProps) => {
  const [idInventory, setIdInventory] = useState<string | null>(null);
  const [dataInventoryGroup, setDataInventoryGroup] = useState<Inventory[]>([]);

  const isDisabled =
    dataInWard.status === "LOCK" ||
    sourceCode ||
    isSave ||
    ["DETAIL", "EDIT"].includes(action);
  const headers = [
    { label: "", style: { width: "25%" } },
    { label: "Bên xuất", style: { width: "25%" } },
    { label: "", style: { width: "25%" } },
    { label: "Bên nhận", style: { width: "25%" } },
  ];

  const getTblDmExpense = async () => {
    const response = await repositoryPos.get<MessageResponse<Inventory[]>>(
      `/api/v1/TblDmInventory/get-select-by-branch?branchId=${dataOrderDetail.toBranchId}`
    );
    if (response && response.success) {
      let result = response.data;
      setDataInventoryGroup(result);
    }
  };

  useEffect(() => {
    if (dataOrderDetail.toBranchId) {
      getTblDmExpense();
    }
  }, [dataOrderDetail.toBranchId]);

  console.log(dataInWard.toInvId, dataInventoryGroup, "đây là dữ liệu");

  const getDeliveryText = (delivery: string) => {
    switch (delivery) {
      case "KHONG":
        return "Nhận tại cửa hàng";
      case "NOI_BO":
      case "HA_COM":
        return "Nội bộ";
      default:
        return delivery;
    }
  };

  const rows = [
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian tạo
          </Text>
        ),
      },
      { content: null },
      { content: null },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {formatDateTransfer(dataOrderDetail?.createDate ?? "")}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian hoàn thành
          </Text>
        ),
      },
      { content: null },
      { content: null },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {formatDateTransfer(dataInWard?.finishDate)}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu nhập kho
          </Text>
        ),
      },
      { content: null },
      { content: null },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataInWard?.transactionCode}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu yêu cầu nhập kho
          </Text>
        ),
      },
      {
        content:
          !isEdit && !haveSourceCode ? (
            <Tooltip
              label={
                dataInWard?.sourceCode
                  ? dataInWard?.sourceCode
                  : "Vui lòng chọn phiếu"
              }
            >
              <Select
                w={"160px"}
                placeholder="Mã phiếu yêu cầu nhập kho"
                data={dataSearchSourceCodeOption}
                size="sm"
                disabled={
                  !dataInWard.sourceType ||
                  dataInWard.status === "LOCK" ||
                  sourceCode ||
                  isSave
                }
                searchable
                clearable
                onSearchChange={(e) => {
                  if (setSearchSourceCode) {
                    setSearchSourceCode(e);
                  }
                }}
                onKeyDown={handleKeyDownSourceCode}
                value={sourceCode || dataInWard.sourceCode}
                onChange={(value) => {
                  if (handleChangeValueInput) {
                    handleChangeValueInput(value || "", "sourceCode");
                  }
                  if (setSourceCode) {
                    setSourceCode(value);
                  }
                }}
                nothingFoundMessage={"Không tìm thấy dữ liệu !"}
              />
            </Tooltip>
          ) : (
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataInWard.sourceCode}{" "}
            </Text>
          ),
      },
      { content: null },
      { content: null },
    ],
    // [
    //   {
    //     content: (
    //       <Text
    //         fw={700}
    //         w={"100px"}
    //         size="sm"
    //         style={{ whiteSpace: "normal" }}
    //         lineClamp={2}
    //         truncate
    //       >
    //         Lý do
    //       </Text>
    //     ),
    //   },
    //   {
    //     content: !isDisabled ? (
    //       <Select
    //         searchable
    //         clearable
    //         w={160}
    //         placeholder="Chọn lý do"
    //         disabled={
    //           dataInWard.status === "LOCK" ||
    //           sourceCode ||
    //           isSave ||
    //           ["DETAIL", "EDIT"].includes(action)
    //         }
    //         data={dataInventoryTransactionType}
    //         value={dataInWard.sourceType?.toString() || ""}
    //         onChange={(value) => {
    //           handleChangeValueInput(value || "", "sourceType");
    //         }}
    //       />
    //     ) : (
    //       <Text
    //         fw={700}
    //         w={"100px"}
    //         size="sm"
    //         style={{ whiteSpace: "normal" }}
    //         lineClamp={2}
    //         truncate
    //       >
    //         {
    //           dataInventoryTransactionType?.find(
    //             (t) => t.value === dataInWard.sourceType?.toString()
    //           )?.label
    //         }
    //       </Text>
    //     ),
    //   },
    //   {
    //     content: null,
    //   },
    //   {
    //     content: null,
    //   },
    // ],
    haveStatus
      ? [
          {
            content: (
              <Text
                fw={700}
                w={"100px"}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              >
                Tình trạng
              </Text>
            ),
          },
          { content: null },
          { content: null },
        ]
      : [],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã số khách hàng
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.cusCode}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chi nhánh
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.toBranchName}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Họ & Tên
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.cusName}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Kho <span style={{ color: "red" }}>*</span>
          </Text>
        ),
      },
      {
        content: (
          <Select
            key={idInventory}
            className={classesButton.text_input}
            searchable
            clearable
            w={160}
            placeholder="Chọn kho"
            disabled={dataInWard.status === "LOCK"}
            data={dataInventoryGroup.map((item) => ({
              value: item.inventoryId?.toString() || "",
              label: item.inventoryCode + " - " + item.inventoryName,
            }))}
            value={dataInWard.toInvId?.toString() || ""}
            onChange={(value) => {
              setIdInventory(value);
              handleChangeValueInput(value || "", "subInvId");
              handleChangeValueInput(value || "", "toInvId");
              handleChangeValueInput(null, "toPositionId");
            }}
          />
          // <Text
          //   w={"150px"}
          //   size="sm"
          //   style={{ whiteSpace: "normal" }}
          //   lineClamp={2}
          //   truncate
          // >
          //   {dataOrderDetail.toInvCode
          //     ? `${dataOrderDetail.toInvCode} - ${
          //         dataOrderDetail.toInvName || ""
          //       }`
          //     : dataOrderDetail.toInvName || ""}
          // </Text>
        ),
      },
    ],
    [
      { content: null },
      { content: null },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Vị trí <span style={{ color: "red" }}>*</span>
          </Text>
        ),
      },
      // {
      //   content: !isEdit ? (
      //     <Tooltip
      //       label={
      //         dataInWard?.toPositionId
      //           ? dataPosition?.find(
      //               (p) => p.value === dataInWard?.toPositionId?.toString()
      //             )?.label
      //           : "Vui lòng chọn vị trí"
      //       }
      //     >
      //       {/* <Select
      //         searchable
      //         clearable
      //         w={160}
      //         placeholder="Chọn lý do"
      //         disabled={
      //           dataInWard.status === "LOCK" ||
      //           sourceCode ||
      //           isSave ||
      //           ["DETAIL", "EDIT"].includes(action)
      //         }
      //         data={dataInventoryLocation?.map((item) => ({
      //           value: item.id?.toString() || "",
      //           label: item.positionName ?? "",
      //         }))}
      //         onChange={(value) => {
      //           handleChangeValueInput(value || "", "toPositionId");
      //         }}
      //       /> */}
      //       <Select
      //         w={"160px"}
      //         placeholder="Vị trí"
      //         data={dataPosition}
      //         size="sm"
      //         // disabled={
      //         //   !idInventory ||
      //         //   !dataInWard.sourceType ||
      //         //   dataInWard.status === "LOCK" ||
      //         //   isSave
      //         // }
      //         searchable
      //         clearable
      //         value={dataInWard.toPositionId?.toString()}
      //         onChange={(value) => {
      //           setIdInventory(value);
      //           handleChangeValueInput(value, "toPositionId");
      //         }}
      //         nothingFoundMessage={"Không tìm thấy dữ liệu !"}
      //       />
      //     </Tooltip>
      //   ) : (
      //     <Text
      //       w={"150px"}
      //       size="sm"
      //       style={{ whiteSpace: "normal" }}
      //       lineClamp={2}
      //       truncate
      //     >
      //       {dataInWard.toPositionName}{" "}
      //     </Text>
      //   ),
      // },
      {
        content: (
          <Select
            key={dataInWard.toPositionId?.toString()}
            w={"160px"}
            placeholder="Vị trí"
            data={dataPosition}
            className={classesButton.text_input}
            size="sm"
            disabled={
              !dataInWard.toInvId?.toString() || dataInWard.status === "LOCK"
            }
            // disabled={
            //   !idInventory ||
            //   !dataInWard.sourceType ||
            //   dataInWard.status === "LOCK" ||
            //   isSave
            // }
            searchable
            clearable
            value={dataInWard.toPositionId?.toString()}
            onChange={(value) => {
              handleChangeValueInput(value, "toPositionId");
            }}
            nothingFoundMessage={"Không tìm thấy dữ liệu !"}
          />
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Số điện thoại
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.cusTelephone}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            ĐĐ làm việc
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.toBranchAddress}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Quận/Huyện
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.cusDestrictName}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Người giao dịch
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.createName}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Phường/Xã
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.cusCommuneName}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chức danh
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.createRoleName}
          </Text>
        ),
      },
    ],

    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Địa chỉ cụ thể
          </Text>
        ),
      },

      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail?.cusAddress || ""}
          </Text>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Giao hàng
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {getDeliveryText(dataOrderDetail?.delivery || "")}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Ghi chú
          </Text>
        ),
      },
      {
        content: (
          <TextInput
            error={
              dataInWard?.description && dataInWard.description.length > 300
                ? "Ghi chú không được vượt quá 300 kí tự!"
                : null
            }
            className={classesButton.text_input}
            value={dataInWard?.description ?? ""}
            onChange={(e) => {
              handleChangeValueInput(e.target.value, "description");
            }}
            disabled={isLocked}
            placeholder={isLocked ? "" : "Vui lòng nhập"}
          />
        ),
        colSpan: 3,
      },
    ],
  ];

  return (
    <RenderTable
      headers={headers}
      rows={rows.filter((item) => item.length !== 0)}
    />
  );
};

type RequestOrderReturnInWardProps = {
  dataSearchSourceCodeOption?: any[];
  dataPosition?: ComboboxItem[];
  dataInWard: tblInventoryTransaction;
  dataOrderDetail: any;
  handleChangeValueInput: (value: string | boolean | null, key: string) => void;
  setSourceCode?: (value: any) => void;
  handleKeyDownSourceCode?: (e: any) => void;
  sourceCode?: any;
  isSave?: boolean;
  setSearchSourceCode?: (newValue: React.SetStateAction<string>) => void;
  isEdit?: boolean;
  isLocked?: boolean;
  haveStatus?: boolean;
  haveSourceCode?: boolean;
  action: string;
  dataInventoryTransactionType?: ComboboxItem[];
};

export default ExchangeReturnInWard;
