import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Menu,
  NumberFormatter,
  rem,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { paginationBase } from "../../../../../../_base/model/_base/BaseTable";
import { modals } from "@mantine/modals";
import ModalSearchItems from "../../Modals/SearchItems";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import { _localization } from "../../../../../../config/location";
import {
  tblPromotion,
  tblPromotionItemAddOnCommand,
  tblPromotionItemGiftCommand,
  tblPromotionItemPrimaryCommand,
} from "../../../../../../model/TblPromotion";
import ModalDiscountToAll from "../../Modals/ModalItemPrimaryDiscountToAll";
import { UseFormReturnType } from "@mantine/form";
import ModalItemGiftEditDiscount from "../../Modals/ModalItemGiftEditDiscount";

const TableItemGiftPromotion = ({
  dataPromotionItemGiftModels,
}: {
  dataPromotionItemGiftModels: tblPromotionItemGiftCommand[];
}) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });
  const [keySearch, setKeySearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [data, setData] = useState<tblPromotionItemGiftCommand[]>([]);
  const [dataTemp, setDataTemp] = useState<tblPromotionItemGiftCommand[]>([]);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemGiftCode",
        header: "Mã hàng",
        size: 60,
      },
      {
        accessorKey: "itemGiftName",
        header: "Tên hàng",
        size: 500,
      },
      {
        accessorKey: "quantityApply",
        header: "SL cho phép",
        enableGlobalFilter: false,
      },
      {
        accessorKey: "quantityMin",
        enableGlobalFilter: false,
        header: "SL tối thiểu 1 lần phải mua/1 Đ.Hàng",
      },
      {
        accessorKey: "quantityMax",
        enableGlobalFilter: false,
        header: "SLượng tối đa 1 lần được mua/1 Đ.Hàng",
      },
    ],
    []
  );
  const handleChangeSearch = (e: any) => {
    const value = e.target.value.toLowerCase() ?? "";
    setKeySearch(value);
    if (value) {
      const searchResult = dataTemp.filter(
        (product) =>
          product.itemGiftCode?.toLowerCase().includes(value) ||
          product.itemGiftName?.toLowerCase().includes(value)
      );
      setSelectIds([]);
      table.resetRowSelection();
      setData(searchResult);
    } else {
      setData(dataTemp);
      setSelectIds([]);
      table.resetRowSelection();
    }
  };

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (490 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setDataTemp(dataPromotionItemGiftModels);
    setData(dataPromotionItemGiftModels);
    setRowCount(dataPromotionItemGiftModels.length);

  }, [dataPromotionItemGiftModels]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Box>
            <Flex gap="md">
              <TextInput
                placeholder="Nhập từ khoá"
                value={keySearch}
                onChange={handleChangeSearch}
              />
            </Flex>
          </Box>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={"flex-end"}
          align="center"
          direction="row"
          wrap="wrap"
        ></Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowVirtualization: true,
    enableBatchRowSelection: true,
    enablePagination: true,
    enableRowSelection: false,
    getRowId: (row) => row?.idItemGift?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: [
          "mrt-row-select",
          "apply",
          "itemGiftCode",
          "namePurchase",
          "nameManufacturer",
        ],
        right: ["allocationRate", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      showGlobalFilter: true,
    },
    enableGlobalFilter: true,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onPaginationChange: setPagination,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "12px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: false,
      withEdges: true,
      rowsPerPageOptions: ["25"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: false,
  });

  return <MantineReactTable table={table} />;
};

export default TableItemGiftPromotion;
