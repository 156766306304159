import {
  ActionIcon,
  Button,
  Checkbox,
  Combobox,
  Flex,
  Group,
  Input,
  Menu,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Textarea,
  Tooltip,
  rem,
  useCombobox,
} from "@mantine/core";
import { DateTimePicker, DateValue } from "@mantine/dates";
import { useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCaretDown,
  IconDeviceFloppy,
  IconFileExport,
  IconFileUpload,
  IconPlus,
  IconSelect,
  IconTrash,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  baseTheme,
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import {
  isNullOrUndefined,
  isNullOrUndefinedArry,
} from "../../../_base/extension/StringExtension";
import { handleKeyDownNegativeNum } from "../../../_base/helper/FunctionHelper";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateNotTimeZone } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { tblItem } from "../../../model/TblItem";
import {
  tblPriceList,
  tblPriceListCommand,
  tblPriceListCommandList,
} from "../../../model/TblPriceList";
import ModelAddProduct from "./ModelAddProduct";
import { TblPriceListDetailCommand } from "../../../model/TblPriceListDetailCommand";

import ModelAddProductV2 from "./ModelAddProductV2";

import PriceListModelV2 from "../../../model/PriceListModelV2";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";



import { AllCommunityModule, GetContextMenuItemsParams, INumberCellEditorParams, ValueFormatterParams } from "ag-grid-community";
import { AG_GRID_LOCALE_VN } from '@ag-grid-community/locale';
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import { AdvancedFilterModule, ClientSideRowModelModule, ClipboardModule, ColDef, ColGroupDef, ColumnsToolPanelModule, ExcelExportModule, FiltersToolPanelModule, GetRowIdParams, GridChartsModule, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsRequest, MasterDetailModule, MenuItemDef, MenuModule, ModuleRegistry, MultiFilterModule, RangeSelectionModule, RichSelectModule, RowClassParams, RowGroupingModule, RowStyle, ServerSideRowModelModule, SetFilterModule, SparklinesModule, StatusBarModule } from "ag-grid-enterprise";


ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  AllCommunityModule
]);



const ListDetailsPriceDetai = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const combobox = useCombobox();
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;
  const isDetailProp = location.state && location.state.isDetailProp;
  const isEditProp = location.state && location.state.isEditProp;
  const [dataBranch, setDataBranch] = useState<any[]>([]);

  const [listDelete, setListDelete] = useState<number[]>([]);
  // const [listAdd, setListAdd] = useState<TblPriceListDetailCommand[]>([]);
  const [listEdit, setListEdit] = useState<TblPriceListDetailCommand[]>([]);

  //#region new

  const containerStyle = useMemo(
    () => ({ width: "100%", height: height, marginTop: "15px" }),
    [height]
  );
  function formatNumber(number: number) {
    return Math.floor(number).toLocaleString();
  }
  function currencyFormatter(params: ValueFormatterParams) {
    return formatNumber(params.value ?? 0) + " ₫";
    // return (
    //   <NumberFormatter
    //     value={params.value}
    //     thousandSeparator=","
    //     suffix=" ₫"
    //   />
    // );
  }

  let params = useParams();

  useEffect(() => {
    console.log("params", params);
    if (!params.id || Number.parseInt(params.id) < 1) {
      navigate(-1);
    } else {
      fetchDataCreatePromotion(Number.parseInt(params.id));
    }
  }, []);

  const fetchDataCreatePromotion = async (id: number) => {
    const dataApi = await repositoryPos.get<MessageResponse<PriceListModelV2>>(
      "/api/v1/TblPriceList/edit?id=" + id
    );
    if (dataApi && dataApi.success) {
      setDataPriceList({
        tblPriceListCommand: dataApi.data.tblPriceListModel,
      });
      setRowData(dataApi.data.tblPriceListModelList);
    } else {
      NotificationExtension.Fails("Bạn không có quyền thực hiện thao tác");
      navigate("/config-system/config-promotion-policy");
    }
  };

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<TblPriceListDetailCommand[]>([]);
  const columnDefs = useMemo<ColDef<TblPriceListDetailCommand>[]>(
    () => [
      {
        field: "itemCode",
        headerName: "Mã sản phẩm",
        editable: false,
        lockPinned: true,
        pinned: "left",
        filter: "agTextColumnFilter",
      },
      {
        field: "itemName",
        headerName: "Tên sản phẩm",
        editable: false,
        width: 350,
        filter: "agTextColumnFilter",
        cellRenderer: (
          params: CustomCellRendererProps<TblPriceListDetailCommand>
        ) => {
          return (
            <Tooltip label={params.data?.itemName ?? ""}>
              <div>{params.data?.itemName ?? ""}</div>
            </Tooltip>
          );
        },
      },
      {
        field: "listPrice",
        headerName: "Giá bán lẻ",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
      {
        field: "webPrice",
        headerName: "Giá niêm yết",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
      {
        field: "wholesalePrice1",
        headerName: "Giá Bán Buôn 1",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
      {
        field: "wholesalePrice2",
        headerName: "Giá Bán Buôn 2",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
      {
        field: "wholesalePrice3",
        headerName: "Giá Bán Buôn 3",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        } as INumberCellEditorParams,
        valueFormatter: currencyFormatter,
        filter: "agNumberColumnFilter",
      },
    ],
    [rowData]
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      filter: true,
      floatingFilter: true,
    };
  }, []);

  //#endregion

  const entity: tblPriceListCommand = {
    id: 0,
    priceListNum: null,
    description: null,
    status: 0,
    isDrap: 0,
    listIdBranch: null,
    activeDate: null,
    inactiveDate: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    attribute6: null,
    attribute7: null,
    attribute8: null,
    attribute9: null,
    attribute10: null,
    attribute11: null,
    attribute12: null,
    attribute13: null,
    attribute14: null,
    attribute15: null,
    approveBy: null,
    approveDate: null,
  };

  const [dataPriceList, setDataPriceList] = useState<tblPriceList>({
    tblPriceListCommand: entity,
    tblPriceListCommandList: [],
  });

  const [data, setData] = useState<tblPriceListCommandList[]>([]);
  const [dataAdd, setDataAdd] = useState<tblPriceListCommandList[]>([]);
  const [dataEdit, setDataEdit] = useState<tblPriceListCommandList[]>([]);
  const [dataDelete, setDataDelete] = useState<number[]>([]);

  const validateRequired = (value: string) =>
    value !== undefined && value !== null && value.length > 0;

  function validateUser(user: any) {
    return {
      firstName: !validateRequired(user.itemName)
        ? "First Name is Required"
        : "",
      lastName: !validateRequired(user.itemCode) ? "Last Name is Required" : "",
    };
  }

  const handleSaveUser: MRT_TableOptions<any>["onEditingRowSave"] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    const dataEdit = {
      ...dataPriceList,
      tblPriceListCommandList: dataPriceList?.tblPriceListCommandList?.map(
        (item: any) => {
          if (item.itemCode === values.itemCode) {
            return {
              ...item,
              ...values,
            };
          }
          return item;
        }
      ),
    };
    setDataPriceList(dataEdit);
    setData((prevData) =>
      prevData?.map((item) => {
        if (item.itemCode === values.itemCode) {
          setDataEdit((prevData) => {
            const dataPrev = prevData?.filter((item) => item?.type !== "ADD");
            const existingItemIndex = dataPrev.findIndex(
              (data) => data.itemId === values.itemId
            );

            if (existingItemIndex >= 0) {
              dataPrev[existingItemIndex] = {
                ...dataPrev[existingItemIndex],
                ...values,
              };
            } else {
              dataPrev.push({
                ...item,
                ...values,
              });
            }

            return dataPrev;
          });
          return {
            ...item,
            ...values,
          };
        }
        return item;
      })
    );
    table.setEditingRow(null);
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(
        dataPriceList?.tblPriceListModelList || []
      );
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-san-pham-bang-gia-POS.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleSavePriceList = async () => {
    console.log(rowData);
    let dataNewPriceList = {
      tblPriceListAddCommandList: rowData?.filter((x) => x.id === 0),
      tblPriceListUpdateCommandList: listEdit.filter((x) => x.id > 0),
      idsDelete: listDelete.filter((x) => x > 0),

      tblPriceListCommand: {
        ...dataPriceList.tblPriceListCommand,
        activeDate:
          typeof dataPriceList.tblPriceListCommand?.activeDate === "number"
            ? new Date(
                dataPriceList.tblPriceListCommand?.activeDate
              ).toISOString()
            : dataPriceList.tblPriceListCommand?.activeDate,
        inactiveDate:
          typeof dataPriceList.tblPriceListCommand?.inactiveDate === "number"
            ? new Date(
                dataPriceList.tblPriceListCommand?.inactiveDate
              ).toISOString()
            : dataPriceList.tblPriceListCommand?.inactiveDate,
      },
    };

    try {
      const { priceListNum, activeDate, inactiveDate, listIdBranch, isDrap } =
        dataNewPriceList.tblPriceListCommand;

      if (!priceListNum) {
        NotificationExtension.Fails("Vui lòng nhập số giao dịch !");
        return;
      }

      if (!activeDate) {
        NotificationExtension.Fails("Vui lòng nhập ngày hoạt động !");
        return;
      }

      if (!inactiveDate) {
        NotificationExtension.Fails("Vui lòng nhập ngày dừng hoạt !");
        return;
      }

      if (!listIdBranch || listIdBranch.length === 0) {
        NotificationExtension.Fails("Vui lòng nhập trung tâm !");
        return;
      }

      if (isNullOrUndefined(isDrap)) {
        NotificationExtension.Fails("Vui lòng chọn trạng thái bảng giá !");
        return;
      }

      const response = await repositoryPos.post(
        "/api/v1/TblPriceList/edit",
        dataNewPriceList
      );

      if (response && response.success) {
        NotificationExtension.Success("Lưu bảng giá thành công !");
        setTimeout(() => navigate("/config-system/config-price"), 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBranchSelect = (val: string) => {
    setDataPriceList((prevData) => {
      let listIdBranch = prevData?.tblPriceListCommand?.listIdBranch;
      let branchArray = listIdBranch ? listIdBranch.split(",") : [];

      if (branchArray.includes(val)) {
        // Remove the value
        branchArray = branchArray.filter((branch) => branch !== val);
      } else {
        // Add the value
        branchArray.push(val);
      }

      const updatedListIdBranch = branchArray?.join(",");

      return {
        ...prevData,
        tblPriceListCommand: {
          ...prevData.tblPriceListCommand,
          listIdBranch: updatedListIdBranch,
        },
      };
    });
  };

  const handleCreateUser: MRT_TableOptions<any>["onCreatingRowSave"] = async ({
    values,
    exitCreatingMode,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    exitCreatingMode();
  };

  const handleChangeDataPriceListCommand = (
    value: string | number | null,
    key: string
  ) => {
    setDataPriceList((prevData) => {
      return {
        ...prevData,
        tblPriceListCommand: {
          ...prevData?.tblPriceListCommand,
          [key]: value ? value : null,
        },
      };
    });
  };

  const handleChangeDataItem = (
    itemCode: string,
    newValue: number | string | null,
    key: string,
    type?: string
  ) => {
    setData((prevItems) =>
      prevItems.map((item) =>
        item.itemCode === itemCode
          ? { ...item, [key]: newValue ? newValue : null }
          : item
      )
    );
    if (type === "ADD") {
      setDataAdd((prevItems) =>
        prevItems.map((item) =>
          item.itemCode === itemCode
            ? { ...item, [key]: newValue ? newValue : null }
            : item
        )
      );
    }
  };

  const handleAddProductToData = (
    dataSelect: tblItem[],
    productId?: string
  ) => {
    if (productId) {
      if (dataSelect.length > 0) {
        const productToUpdate = dataSelect[0];

        const dataEdit = {
          ...dataPriceList,
          tblPriceListCommandList: dataPriceList?.tblPriceListCommandList?.map(
            (item: any) => {
              if (item.itemCode === productId) {
                return {
                  ...item,
                  itemCode: productToUpdate.itemCode,
                  itemName: productToUpdate.itemName,
                };
              }
              return item;
            }
          ),
        };

        setDataPriceList(dataEdit);
        setData((prevData) =>
          prevData?.map((item) => {
            if (item.itemCode === productId) {
              return {
                ...item,
                itemCode: productToUpdate.itemCode,
                itemName: productToUpdate.itemName,
              };
            }
            return item;
          })
        );
      }
    } else {
      const dataAddProduct = dataSelect?.map((product) => ({
        id: 0,
        idPriceLis: 0,
        itemId: product.itemId, // Set the correct itemId
        itemCode: product.itemCode,
        itemName: product.itemName,
        priceNoVat: 0,
        priceIncludeVat: 0,
        discountPercent: 0,
        discountAmount: 0,
        vatRate: 0,
        vatAmount: 0,
        wholesalePrice1: 0,
        wholesalePrice2: 0,
        wholesalePrice3: 0,
        listPrice: 0,
        webPrice: 0,
        demoPrice: 0,
        bonusRate: 0,
        bunus: 0, // Make sure this matches your interface spelling
        lastPoPrice: 0,
        onhandAvgPrice: 0, // Correct the spelling to match your interface
        priceMin: 0,
        priceMax: 0,
        approveStatus: null, // Ensure this matches the interface type
        approveBy: null, // Ensure this matches the interface type
        approveDate: null, // Ensure this matches the interface type
        attribute1: null,
        attribute2: null,
        attribute3: null,
        attribute4: null,
        attribute5: null,
        attribute6: null,
        attribute7: null,
        attribute8: null,
        attribute9: null,
        attribute10: null,
        attribute11: null,
        attribute12: null,
        attribute13: null,
        attribute14: null,
        attribute15: null,
        createBy: null,
        createDate: null,
        lastUpdateBy: null,
        lastUpdateDate: null,
        type: "ADD",
      }));

      setDataPriceList((prevData) => ({
        ...prevData,
        tblPriceListCommandList: [
          ...(prevData?.tblPriceListCommandList ?? []),
          ...dataAddProduct,
        ],
      }));
      setData((prevData) => [...dataAddProduct, ...prevData]);
      setDataAdd((prev) => [...prev, ...dataAddProduct]);
    }
    modals.closeAll();
  };

  const convertListId = (listId: string) => {
    const arrId = listId.split(",");
    const listBranch = arrId?.map(
      (item) =>
        dataBranch.find(
          (branch) => branch.value?.toString() === item?.toString()
        )?.text
    );
    return listBranch?.join(", ");
  };

  const handleRowDelete = (row: MRT_Row<any>) => {
    setData((prevData) => prevData.filter((_, index) => index !== row.index));
    setDataDelete((prev) => {
      if (row.original.type === "ADD") {
        setDataAdd((prevData) =>
          prevData.filter((item) => item.itemCode !== row.original.itemCode)
        );
        return [...prev];
      }
      return [...prev, row.original?.id];
    });

    setDataPriceList((prevData) => ({
      ...prevData,
      tblPriceListCommandList: (prevData?.tblPriceListCommandList ?? []).filter(
        (_, index) => index !== row.index
      ),
    }));
  };

  const handleAddProduct = (
    editTable: boolean,
    productId?: string,
    dataList?: any[]
  ) => {
    // modals.closeAll();
    modals.openConfirmModal({
      title: "Thêm sản phẩm",
      centered: true,
      size: "100%",
      fullScreen: false,
      children: (
        <ModelAddProductV2
          handleAddProduct={setRowData}
          listIdItem={rowData?.map((item) => item.itemId) ?? []}
        ></ModelAddProductV2>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  useHotkeys([
    [
      "F11",
      () => {
        handleAddProduct(false);
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setDataPriceList({
      tblPriceListCommand: entity,
      tblPriceListCommandList: [],
    });
    setRowCount(0);

    let url = `&Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    try {
      let callapi = await repositoryPos.get(
        `/api/v1/TblPriceList/detail?id=${id}${url}`
      );
      if (isNullOrUndefined(callapi) || isNullOrUndefinedArry(callapi?.data)) {
        setRowCount(0);
        setDataPriceList({
          tblPriceListCommand: entity,
          tblPriceListCommandList: [],
        });
      } else {
        setDataPriceList(
          callapi?.data
            ? {
                tblPriceListCommand: {
                  ...callapi?.data?.tblPriceListModel,
                  activeDate: callapi?.data?.tblPriceListModel?.activeDate
                    ? new Date(
                        callapi?.data?.tblPriceListModel?.activeDate
                      ).setHours(
                        new Date(
                          callapi?.data?.tblPriceListModel?.activeDate
                        ).getHours()
                      )
                    : null,
                  inactiveDate: callapi?.data?.tblPriceListModel?.inactiveDate
                    ? new Date(
                        callapi?.data?.tblPriceListModel?.inactiveDate
                      ).setHours(
                        new Date(
                          callapi?.data?.tblPriceListModel?.inactiveDate
                        ).getHours()
                      )
                    : null,
                },
                tblPriceListCommandList: callapi?.data?.tblPriceListModelList,
              }
            : {
                tblPriceListCommand: entity,
                tblPriceListCommandList: [],
              }
        );
        setData(callapi?.data?.tblPriceListModelList);
        setRowCount(callapi?.totalCount ?? 0);
      }
      return callapi?.dataPriceList;
    } catch (error: any) {
      setIsError(true);
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    const fetchDataBranch = async () => {
      const response = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmInventory/get-select-branch"
      );

      setDataBranch(response?.data);
    };

    fetchDataBranch();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const gridRef = useRef<AgGridReact<TblPriceListDetailCommand>>(null);

  const getContextMenuItems = useCallback(
    (
      params: GetContextMenuItemsParams<TblPriceListDetailCommand>
    ): (string | MenuItemDef)[] => {
      const _number = params?.node?.data?.itemCode || "";
      var result: (string | MenuItemDef)[] = [
        {
          name: "Thêm vật tư",
          action: () => {
            handleAddProduct(false);
          },
          cssClasses: ["red", "bold"],
        },
        // {
        //   // custom item
        //   name: "Sửa vật tư: " + _number,
        //   action: () => {
        //     const index = params?.node?.rowIndex;
        //     if (!index) {
        //       NotificationExtension.Fails("Vui lòng chọn vật tư !");
        //       return;
        //     }
        //     gridRef.current!.api.setFocusedCell(1, "make");
        //     gridRef.current!.api.startEditingCell({
        //       rowIndex: 1,
        //       colKey: "make",
        //     });
        //   },
        //   cssClasses: ["red", "bold"],
        // },
        {
          // custom item
          name: "Xóa vật tư: " + _number,
          action: () => {
            console.log(rowData);
            setRowData(
              rowData?.filter((item) => item.itemCode !== _number.trim())
            );
            const idDelete = rowData?.find(
              (item) => item.itemCode === _number.trim()
            );
            if (idDelete) {
              setListDelete([...listDelete, idDelete.id]);
              // setListAdd((prevData) =>
              //   prevData.filter((item) => item.itemId !== idDelete.itemId)
              // );
              setListEdit((prevData) =>
                prevData.filter((item) => item.itemId !== idDelete.itemId)
              );
            }
          },
          cssClasses: ["red", "bold"],
        },

        // {
        //   // custom item
        //   name: "Hủy đơn: " + _number,
        //   action: () => {
        //     window.alert("Alerting about " + params.value);
        //   },
        //   cssClasses: ["red", "bold"],
        // },
        // "cut",
        // "copy",
        // "copyWithHeaders",
        // "copyWithGroupHeaders",
        // "paste",
        // "separator",
        // "chartRange",

        // "export",
      ];
      return result;
    },
    [window, rowData]
  );

  return (
    <>
      <_breadcrumb></_breadcrumb>
      <PanelGroup direction="horizontal" id="group">
        <Panel
          defaultSize={20}
          minSize={15}
          maxSize={85}
          style={{ marginTop: "15px" }}
        >
          <Table striped highlightOnHover withTableBorder withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>Thông tin bảng giá</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Th>Mã bảng giá</Table.Th>
                <Table.Td>
                  <Text fw={"500"}>
                    {dataPriceList?.tblPriceListCommand?.priceListNum}
                  </Text>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Người lập</Table.Th>
                <Table.Td>
                  <TextInput
                    value={localStorage.getItem("fullName")?.toString() || ""}
                    readOnly
                    variant="filled"
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Ngày hiệu lực</Table.Th>
                <Table.Td>
                  <DateTimePicker
                    placeholder="Ngày hiệu lực"
                    clearable
                    locale="vi"
                    value={
                      dataPriceList?.tblPriceListCommand?.activeDate
                        ? new Date(dataPriceList.tblPriceListCommand.activeDate)
                        : null
                    }
                    valueFormat="DD/MM/YYYY hh:mm A"
                    minDate={new Date()}
                    onChange={(value: DateValue) => {
                      handleChangeDataPriceListCommand(
                        formatDateNotTimeZone(value) || null,
                        "activeDate"
                      );
                      handleChangeDataPriceListCommand("", "inactiveDate");
                    }}
                    readOnly={isDetailProp}
                    variant={isDetailProp ? "filled" : undefined}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Ngày hết hiệu lực</Table.Th>
                <Table.Td>
                  <DateTimePicker
                    placeholder="Ngày hết hiệu lực"
                    clearable
                    locale="vi"
                    minDate={
                      dataPriceList?.tblPriceListCommand?.activeDate
                        ? dayjs(dataPriceList.tblPriceListCommand.activeDate)
                            .add(1, "hour")
                            .toDate()
                        : dayjs().add(1, "hour").toDate()
                    }
                    value={
                      dataPriceList?.tblPriceListCommand?.inactiveDate
                        ? new Date(
                            dataPriceList.tblPriceListCommand.inactiveDate
                          )
                        : null
                    }
                    valueFormat="DD/MM/YYYY hh:mm A"
                    onChange={(value) => {
                      handleChangeDataPriceListCommand(
                        formatDateNotTimeZone(value) || null,
                        "inactiveDate"
                      );
                    }}
                    disabled={!dataPriceList?.tblPriceListCommand?.activeDate}
                    readOnly={isDetailProp}
                    variant={isDetailProp ? "filled" : undefined}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Chi nhánh áp dụng</Table.Th>
                <Table.Td>
                  <Combobox
                    store={combobox}
                    onOptionSubmit={handleBranchSelect}
                  >
                    <Combobox.Target>
                      <Textarea
                        placeholder="Chi nhánh áp dụng"
                        onClick={() => {
                          if (!isDetailProp) combobox.openDropdown();
                        }}
                        value={convertListId(
                          dataPriceList.tblPriceListCommand?.listIdBranch ?? ""
                        )?.split(",")}
                        onChange={(event) => {
                          setDataPriceList((prevData) => ({
                            ...prevData,
                            tblPriceListCommand: {
                              ...prevData.tblPriceListCommand,
                              listIdBranch: event.currentTarget.value,
                            },
                          }));
                          combobox.updateSelectedOptionIndex();
                        }}
                        readOnly={isDetailProp}
                        variant={isDetailProp ? "filled" : undefined}
                        rows={4}
                      />
                    </Combobox.Target>

                    <Combobox.Dropdown>
                      <Combobox.Options>
                        <ScrollArea.Autosize type="scroll" mah={300}>
                          {dataBranch?.map((branch: any) => (
                            <Combobox.Option
                              value={branch.value}
                              key={branch.value}
                              active={
                                dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                  branch.value
                                ) ||
                                dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                  "-1"
                                )
                              }
                              onMouseOver={() => combobox.resetSelectedOption()}
                              disabled={
                                (dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                  "-1"
                                ) &&
                                  branch.value !== "-1") ||
                                (!dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                  "-1"
                                ) &&
                                  !!dataPriceList?.tblPriceListCommand
                                    ?.listIdBranch &&
                                  branch.value === "-1" &&
                                  dataPriceList?.tblPriceListCommand
                                    ?.listIdBranch?.length > 0)
                              }
                            >
                              <Group gap="sm">
                                <Checkbox
                                  label={branch.text}
                                  checked={dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                    branch.value
                                  )}
                                  disabled={
                                    dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                      "-1"
                                    ) && branch.value !== "-1"
                                  }
                                  onChange={() => {}}
                                  aria-hidden
                                  tabIndex={-1}
                                  style={{ pointerEvents: undefined }}
                                />
                              </Group>
                            </Combobox.Option>
                          ))}
                        </ScrollArea.Autosize>
                      </Combobox.Options>
                    </Combobox.Dropdown>
                  </Combobox>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Trạng thái bảng giá</Table.Th>
                <Table.Td>
                  <Select
                    placeholder="Thông tin phê duyệt"
                    clearable
                    data={[
                      {
                        value: "0",
                        label: "Chưa duyệt",
                      },
                      {
                        value: "1",
                        label: "Đã duyệt",
                      },
                    ]}
                    defaultValue={"1"}
                    value={dataPriceList?.tblPriceListCommand?.status?.toString()}
                    onChange={(e) =>
                      handleChangeDataPriceListCommand(e, "status")
                    }
                    readOnly={isDetailProp}
                    variant={isDetailProp ? "filled" : undefined}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Mô tả</Table.Th>
                <Table.Td>
                  <Textarea
                    placeholder={!isDetailProp ? "Nhập mô tả..." : undefined}
                    value={
                      dataPriceList?.tblPriceListCommand?.description || ""
                    }
                    onChange={(e) => {
                      handleChangeDataPriceListCommand(
                        e.currentTarget.value,
                        "description"
                      );
                    }}
                    rows={16}
                    readOnly={isDetailProp}
                    variant={isDetailProp ? "filled" : undefined}
                  />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Panel>
        <PanelResizeHandle id="resize-handle" style={{ width: "5px" }} />
        <Panel defaultSize={70} minSize={25} maxSize={75}>
          {/* <MantineReactTable table={table} /> */}

          <div style={containerStyle}>
            <div style={gridStyle} className={"ag-theme-quartz"}>
              <AgGridReact<TblPriceListDetailCommand>
                ref={gridRef}
                rowData={rowData}
                theme={baseTheme}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                suppressColumnVirtualisation={true}
                suppressRowVirtualisation={true}
                pagination={true}
                localeText={AG_GRID_LOCALE_VN}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                cacheBlockSize={paginationPageSize}
                maxBlocksInCache={3}
                //  getContextMenuItems={getContextMenuItems}
              />
            </div>
          </div>
        </Panel>
      </PanelGroup>
    </>
  );
};

export default ListDetailsPriceDetai;
