import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Group,
  Menu,
  Select,
  SelectProps,
  TextInput,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconBarcode,
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconHandStop,
  IconMapRoute,
  IconReload,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryDelivery,
  repositoryPos,
} from "../../_base/_const/_constVar";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { getValueById } from "../../_base/helper/FunctionHelper";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../common/FormatDate/FormatDate";
import InputSearch from "../../common/InputSearch/InputSearch";
import { _localization } from "../../config/location";
import { green, orange, red, sky_blue } from "../../const/variables";
import { MessageResponse } from "../../model/MessageResponse";
import { TblDmEmployeeModel } from "../../model/TblDmEmployeeModel";
import { TblTestingTechniqueHeaderGetList } from "../../model/TblTestingTechniqueHeader";
import ModalEditTestingTechnique from "./ModalEditTestingTechnique";
import TableDetail from "./TableDetail";
import { BASE_API_DELIVERY } from "../../config";

function getColorStatus(text: any) {
  switch (text) {
    case "TAO_MOI":
      return sky_blue.base;
    case "PHAN_CONG":
      return orange.base;
    // case "TIEP_NHAN":
    //   return "#ffab09";
    case "DANG_KIEM_TRA":
      return "#d9d02f";
    // case "DA_KIEM_TRA":
    //   return "green";
    case "TEST_MOT_PHAN":
      return "#ffcc00";
    case "TEST_LOI":
      return "red";
    case "TEST_OK":
      return "#17a2b8";
    case "HUY_PHAN_CONG":
      return "red";
    case "":
      return "green";
    // case "THANH_CONG":
    //   return green.light;
    // case "THAT_BAI":
    //   return red.base;
    default:
      return sky_blue.base;
  }
}

function getStatus(text: any) {
  switch (text) {
    case "N":
      return "Tạo mới";
    case "A":
      return "Đã phân công";
    case "F":
      return "Hoàn thành";
    default:
      return "Tạo mới";
  }
}

const TestingTechnique = () => {
  const navigate = useNavigate();
  // Reference data from another table
  const [dataTblLocation, setDataTblLocation] = useState<ComboboxItem[]>([]);

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblTestingTechniqueHeaderGetList[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataEmployee, setDataEmployee] = useState<TblDmEmployeeModel[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const dataStatusOption = [
    { value: "TAO_MOI", label: "Tạo mới" },
    { value: "PHAN_CONG", label: "Phân công" },
    // { value: "TIEP_NHAN", label: "Tiếp nhận" },
    { value: "DANG_KIEM_TRA", label: "Đang kiểm tra" },
    // { value: "DA_KIEM_TRA", label: "Đã kiểm tra" },
    { value: "TEST_MOT_PHAN", label: "Test một phần" },
    { value: "TEST_LOI", label: "Test lỗi" },
    { value: "TEST_OK", label: "Test ok" },
    { value: "HUY_PHAN_CONG", label: "Hủy phân công" },
    // { value: "THANH_CONG", label: "Thành công" },
    // { value: "THAT_BAI", label: "Thất bại" },
  ];
  const dataSourceStatus = [
    { value: "DON_HANG_TAM", label: "Đơn hàng tạm" },
    { value: "DON_HANG_HOAN_THANH", label: "Đơn hàng hoàn thành" },
    {
      value: "THANH_TOAN_CHUA_HOAN_THANH",
      label: "Thanh toán chưa hoàn thành",
    },
    {
      value: "pending",
      label: "Chưa giải quyết",
    },
    {
      value: "DON_HANG_BAN_TAI_SHOP",
      label: "Đơn hàng bán tại shop",
    },
    {
      value: "KHO_XUAT_HANG",
      label: "Kho xuất hàng",
    },
    {
      value: "KY_THUAT_TIEP_NHAN",
      label: "Kỹ thuật tiếp nhận",
    },
    {
      value: "KY_THUAT_XONG",
      label: "Kỹ thuật xong",
    },
    {
      value: "GHND_TIEP_NHAN",
      label: "GHND tiếp nhận",
    },
    {
      value: "LOCK",
      label: "Phiếu khóa",
    },
    {
      value: "OPEN",
      label: "Đang nhập",
    },
  ];

  const sourceTypeData = [
    { label: "Đơn Hàng", value: "DON_HANG" },
    { label: "Nhập Kho Bảo Hành NCC", value: "NHAP_KHO_BH_NCC" },
    { label: "Nhập Kho Bảo Hành", value: "NHAP_KHO_BH" },
    { label: "Chuyển Đổi Dữ Liệu", value: "CHUYEN_DOI_DU_LIEU" },
  ];
  const ViewtechnicalRequired = (value: number) => {
    let result = "";
    switch (value) {
      case 0:
        result = "Không";
        break;
      case 1:
        result = "Lắp máy(mới)";
        break;
      case 2:
        result = "Kiểm tra(mới)";
        break;
      case 3:
        result = "Lắp ráp(mới)+kiểm tra(mới)";
        break;
      default:
        result = "Không yêu cầu";
        break;
    }
    return result;
  };
  const ViewtestDifficulty = (value: string) => {
    let result = "";
    switch (value) {
      case "KHONG_TEST":
        result = "Không Test (0) ";
        break;
      case "DE":
        result = "Dễ (1)";
        break;
      case "DON_GIAN":
        result = "Đơn giản (2)";
        break;
      case "BINH_THUONG":
        result = "Bình thường (3)";
        break;
      case "KHO":
        result = "Khó (5)";
        break;
      case "PHUC_TAP":
        result = "Phức tạp (10)";
        break;
      default:
        result = "Không xác định";
        break;
    }
    return result;
  };
  const [search, setSearch] = useState({
    keySearch: "",
    fromDate: "",
    endDate: "",
    sourceType: "",
    status: "",
    serial: "",
  });
  const [recordSelected, setRecordSelected] = useState<any[]>([]);

  const [expandedRow, setExpandedRow] = useState<string | null>(null); // mở hoặc đóng sản phẩm ở tableDetail

  const columns = React.useMemo<
    MRT_ColumnDef<TblTestingTechniqueHeaderGetList>[]
  >(
    () => [
      {
        accessorKey: "code",
        header: "Mã kiểm tra",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() =>
                detailData(row.original.id, row.original.sourceType)
              }
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "testDifficulty",
        header: "Mức độ test của phiếu",
        Cell: ({ renderedCellValue }) => (
          <Badge variant="default" color="blue" radius="sm">
            {ViewtestDifficulty(renderedCellValue as string)}
          </Badge>
        ),
      },
      {
        accessorKey: "sourceStatus",
        header: "Trạng thái số chứng từ",
        size: 225,
        Cell: ({ row }) => {
          const orderType = row.original.sourceStatus;
          let badgeColor;
          switch (orderType) {
            case "DON_HANG_TAM":
              badgeColor = "cyan";
              break;
            case "DON_HANG_HOAN_THANH":
              badgeColor = "green";
              break;
            case "THANH_TOAN_CHUA_HOAN_THANH":
              badgeColor = "red";
              break;
            case "pending":
              badgeColor = "yellow";
              break;
            case "DON_HANG_BAN_TAI_SHOP":
              badgeColor = "green";
              break;
            case "KHO_XUAT_HANG":
              badgeColor = sky_blue.medium;
              break;
            case "KY_THUAT_TIEP_NHAN":
              badgeColor = "blue";
              break;
            case "KY_THUAT_XONG":
              badgeColor = "green";
              break;
            case "LOCK":
              badgeColor = "gray";
              break;
            case "OPEN":
              badgeColor = "gray";
              break;
            default:
              badgeColor = "lime";
          }
          return (
            <Badge color={badgeColor} fs={"12,5px"} fw={"bold"} radius="sm">
              {row.original.sourceStatus !== null
                ? getValueById(
                    row.original.sourceStatus,
                    dataSourceStatus,
                    "label"
                  )
                : "Không xác định"}
            </Badge>
          );
        },
      },
      {
        accessorKey: "sourceCode",
        header: "Số chứng từ gốc",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "sourceType",
        header: "Nguồn chứng từ",
        size: 250,
        Cell: ({ renderedCellValue }) => {
          let displayText;
          let badgeColor;
          switch (renderedCellValue) {
            case "DON_HANG":
              displayText = "Đơn hàng";
              badgeColor = "cyan";
              break;
            case "NHAP_KHO_BH_NCC":
              displayText = "Nhập kho bảo hành NCC";
              badgeColor = "teal";
              break;
            case "NHAP_KHO_BH":
              displayText = "Nhập kho bảo hành";
              badgeColor = "orange";
              break;
            case "CHUYEN_DOI_DU_LIEU":
              displayText = "Chuyển đổi dữ liệu";
              badgeColor = "blue";
              break;
            default:
              displayText = "";
          }
          return (
            <>
              <Badge color={badgeColor}> {displayText}</Badge>
            </>
          );
        },
      },
      {
        accessorKey: "sourceDate",
        header: "Ngày lập số chứng từ",
        size: 200,
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
      },
      {
        accessorKey: "receptionTime",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
      },
      {
        accessorKey: "responsibleEmployee",
        header: "Người tiếp nhận",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "completionTime",
        header: "Thời gian hoàn thành",
        size: 200,
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
      },
      // {
      //   accessorKey: "technicalRequired",
      //   header: "Yêu cầu dịch vụ",
      //   Cell: ({ renderedCellValue }) => (
      //     <>
      //       {
      //         <>
      //           {(() => {
      //             if (renderedCellValue === 0) {
      //               return <Badge color="indigo">Không yêu cầu</Badge>;
      //             } else if (renderedCellValue === 1) {
      //               return <Badge color="green">Lắp ráp(mới)</Badge>;
      //             } else if (renderedCellValue === 2) {
      //               return <Badge color="cyan">Kiểm tra(mới)</Badge>;
      //             } else if (renderedCellValue === 3) {
      //               return (
      //                 <Badge color={sky_blue.medium}>
      //                   Lắp ráp(mới)+Kiểm tra(mới)
      //                 </Badge>
      //               );
      //             } else {
      //               return null;
      //             }
      //           })()}
      //         </>
      //       }
      //     </>
      //   ),
      //   size: 30,
      // },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "subInvName",
        header: "Kho",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "actribute4",
        header: "Bộ phận giao hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      //   {
      //     accessorKey: "createName",
      //     header: "Người lập",
      //     // Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      //     size: 30,
      //   },

      {
        accessorKey: "customerName",
        header: "Tên khách hàng",
      },
      {
        accessorKey: "customerTelephone",
        header: "SĐT",
      },

      {
        accessorKey: "description",
        header: "Ghi chú",
      },
      {
        accessorKey: "testingStatus",
        header: "Trạng thái KT",
        Cell: ({ row }) => (
          <>
            <Badge
              color={getColorStatus(row.original.testingStatus)}
              radius={"sm"}
            >
              {getValueById(
                row.original.testingStatus?.toString() ?? "",
                dataStatusOption,
                "label"
              )}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 130,
        enableResizing: false,
        Cell: ({ row }) => {
          const { sourceType, testingStatus, canUpdate } = row.original;
          const isReturnToSupplier = sourceType === "NHAP_KHO_BH_NCC";
          const isSaleOrder = sourceType === "BAN_HANG";
          const isToSupplier = sourceType === "NHAP_KHO_BH";

          // Điều kiện disabled:
          // const isDisabled = !(
          //   (
          //     testingStatus === "DANG_KIEM_TRA" ||
          //     testingStatus === "TEST_MOT_PHAN" || // Cả hai loại phiếu đều cho cập nhật khi đang kiểm tra hoặc test một phần
          //     (canUpdate &&
          //       (testingStatus === "TEST_OK" || testingStatus === "TEST_LOI"))
          //   ) // Phiếu xuất trả nhà cung cấp cho phép cập nhật nếu canUpdate = true
          // );
          const isDisabled = !(
            testingStatus === "DANG_KIEM_TRA" ||
            testingStatus === "TEST_MOT_PHAN"
          );
          return (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* <Tooltip label="Chỉnh sửa">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="orange"
                    onClick={async () => {
                      editData(row.original.id);
                    }}
                    disabled={row?.original?.testingStatus !== "PHAN_CONG"}
                  >
                    <IconEdit size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip> */}

              <Tooltip label="Chi tiết">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="cyan"
                  onClick={async () => {
                    detailData(row.original.id, row.original.sourceType);
                  }}
                >
                  <IconEye size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>

              <Tooltip label="Phân công">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="violet"
                  disabled={
                    row.original.testingStatus !== "TAO_MOI" &&
                    row.original.testingStatus !== "HUY_PHAN_CONG"
                  }
                  onClick={async () => {
                    assignEmployee(row.original.id, row.original.sourceType);
                  }}
                >
                  <IconHandStop size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>

              <Tooltip label="Cập nhật kiểm tra">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="teal"
                  disabled={isDisabled}
                  onClick={async () => {
                    updateTesting(
                      row.original.id,
                      row.original.sourceType,
                      row.original.canUpdate
                    );
                  }}
                >
                  <IconReload size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>

              {/* <Tooltip label="Xóa">
                    <ActionIcon
                      variant="light"
                      aria-label="Settings"
                      color="red"
                      onClick={async () => {
                        await deleteData([row.original.transactionId]);
                      }}
                    >
                      <IconTrash size={20} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip> */}
            </Box>
          );
        },
      },
    ],
    [dataTblLocation, dataEmployee, dataStatusOption, dataSourceStatus]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = async () => {
    // try {
    //   const worksheet = xlsx.utils.json_to_sheet(data);
    //   const workbook = xlsx.utils.book_new();
    //   xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
    //   xlsx.writeFile(workbook, "danh-sach-ky-thuat-kiem-tra.xlsx");
    //   toast.success("Export excel thành công", { autoClose: 1500 });
    // } catch (error) {
    //   toast.error("Export excel thất bại", { autoClose: 1500 });
    // }
    let url = `/api/v1/TblTestingTechniqueHeader/export-testing-technique-headers?Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.fromDate) {
      url += `&FromDate=${search.fromDate}`;
    }
    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }
    if (search.status) {
      url += `&Status=${search.status}`;
    }
    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }

    const _url = BASE_API_DELIVERY + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "Danh_sach_Ky_thuat_kiem_tra.xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const optionTestingStatus: Record<
    | "Tạo mới"
    | "Phân công"
    | "Đang kiểm tra"
    | "Test một phần"
    | "Test lỗi"
    | "Test ok"
    | "Hủy phân công",
    string
  > = {
    "Tạo mới": "#3B9BDD",
    "Phân công": "#FD8622",
    "Đang kiểm tra": "#d9d02f",
    "Test một phần": "#ffcc00",
    "Test lỗi": "red",
    "Test ok": "#17a2b8",
    "Hủy phân công": red.dark,
  };

  const renderSelectOptionTestingStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionTestingStatus[
            option.label as
              | "Tạo mới"
              | "Phân công"
              | "Đang kiểm tra"
              | "Test một phần"
              | "Test lỗi"
              | "Test ok"
              | "Hủy phân công"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const createData = () => {
    // navigate("/warehouse-config/create-warehouse-out-ward");
  };

  const editData = (id: string | number) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa kỹ thuật kiểm tra</Title>
        </>
      ),
      size: "auto",
      children: (
        <ModalEditTestingTechnique id={id} onClose={setDeleteViewStatus} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const detailData = (id: string | number, type: string | null) => {
    navigate("/testing/detail-testing-technique", {
      state: {
        id: id,
        dataStatusOption: dataStatusOption,
        dataSourceStatus: dataSourceStatus,
        typeSourceType: type,
      },
    });
  };

  const assignEmployee = (id: string | number, type: string | null) => {
    navigate("/testing/assign-testing-technique", {
      state: {
        id: id,
        dataStatusOption: dataStatusOption,
        dataSourceStatus: dataSourceStatus,
        typeSourceType: type,
      },
    });
  };

  const updateTesting = (
    id: string | number,
    type: string | null,
    testingStatusBHNCC: any
  ) => {
    navigate("/testing/update-testing-technique", {
      state: {
        id: id,
        dataStatusOption: dataStatusOption,
        dataSourceStatus: dataSourceStatus,
        typeSourceType: type,
        testingStatusBHNCC: testingStatusBHNCC,
      },
    });
  };
  const updateStatus = async (ids: (string | number)[], status: string) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        const dataSubmit = {
          ids: ids,
          status: status,
        };
        const res = await repositoryDelivery.post<MessageResponse<boolean>>(
          "/api/v1/TblTestingTechniqueHeader/update-status-header",
          dataSubmit
        );
        if (res)
          if (res.success) {
            NotificationExtension.Success("Cập nhật thành công !");
            fetchData();
          } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const handleCancelAssignment = () => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>
            Bạn có muốn hủy phân công KTKT các đơn hàng này ?
          </Title>
        </>
      ),
      size: "400px",
      children: (
        <Box>
          {/* <Text fw={500} size="18px" mt={10}>
            Bạn có muốn hủy phân công KTKT các đơn hàng này ?
          </Text> */}
          <Flex justify={"end"} mt={10} gap={"md"}>
            <Button
              variant="outline"
              leftSection={<IconArrowLeft size={"14px"} />}
              onClick={() => modals.closeAll()}
              color="gray"
            >
              Hủy
            </Button>
            <Button
              variant="outline"
              leftSection={<IconX size={"14px"} />}
              onClick={() => cancelAssign()}
              color="red"
            >
              Xác nhận
            </Button>
          </Flex>
        </Box>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      classNames: {
        title: "custom-modal-title",
        body: "custom-modal-body",
      },
    });
  };

  useHotkeys([
    [
      "F11",
      () => {
        createData();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.fromDate) {
      url += `&FromDate=${search.fromDate}`;
    }
    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }
    if (search.status) {
      url += `&Status=${search.status}`;
    }
    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }
    if (search.serial) {
      url += `&Serial=${search.serial}`;
    }

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
      url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    }

    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblTestingTechniqueHeader/get-list-v2${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        // Promise.all([dataLocation()]);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const cancelAssign = async () => {
    const req = await repositoryDelivery.post<MessageResponse<any>>(
      "/api/v1/TblTestingTechniqueHeader/cancel-assign",
      { ids: selectIds }
    );

    if (req && req?.success) {
      NotificationExtension.Success("Hủy phân công KTKT thành công !");
      setTimeout(() => {
        modals.closeAll();
        setDeleteViewStatus(!deleteViewStatus);
      }, 1500);
    }
  };

  const fetchEmployee = async () => {
    const url = `/api/v1/TblDmEmployee/get-all`;
    try {
      const searchResult = await repositoryPos.get<
        MessageResponse<TblDmEmployeeModel[]>
      >(url);
      if (searchResult?.data) {
        setDataEmployee(searchResult.data);
      } else {
        setDataEmployee([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataEmployee([]);
    }
  };

  useEffect(() => {
    // fetchEmployee();

    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (155 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      const dataSelected = data.filter((row) =>
        valuesList.includes(row?.id.toString())
      );
      setRecordSelected(dataSelected);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);
  useEffect(() => {
    fetchData();
    // fetchDeliveryDepartment();
  }, [sorting, pagination.pageIndex, pagination.pageSize, deleteViewStatus]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,

    renderDetailPanel: ({ row }) => {
      return (
        <>
          {/* <ListProductDetail
      dataDetail={row.original.tblTestingTechniqueDetailModels}
      isDetail={false}
    /> */}
          {/* <TableDetail
            dataDetail={row.original.tblTestingTechniqueDetailModels}
            isDetail={false}
            typeSourceType={row.original.sourceType ?? ""}
          /> */}
          <TableDetail idDetail={row.original.id}
          typeSourceType={row.original.sourceType ?? ""}
          />
        </>
      );
    },

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand", "code"],
        right: ["testingStatus", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: true,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const isAccept = useMemo(
    () =>
      recordSelected?.every(({ testingStatus }) =>
        ["TAO_MOI", "PHAN_CONG"].includes(testingStatus)
      ),
    [recordSelected]
  );

  const isCheck = useMemo(
    () =>
      recordSelected?.every(
        ({ testingStatus }) => testingStatus === "TIEP_NHAN"
      ),
    [recordSelected]
  );

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            {/* <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                createData();
              }}
            >
              Tạo mới (F11)
            </Button> */}

            {/* Tạo mới || Đã phân công */}
            {/* {isAccept && selectIds?.length !== 0 ? (
              <Button
                leftSection={<IconReceiptRefund size={14} />}
                variant="outline"
                color="blue"
                disabled={selectIds.length === 0}
                onClick={() => {
                  updateStatus(selectIds, "TIEP_NHAN");
                }}
              >
                Tiếp nhận
              </Button>
            ) : isCheck && selectIds?.length !== 0 ? (
              <Button
                leftSection={<IconZoomCheck size={14} />}
                variant="outline"
                color="blue"
                disabled={selectIds.length === 0}
                onClick={() => {
                  updateStatus(selectIds, "DANG_KIEM_TRA");
                }}
              >
                Kiểm tra
              </Button>
            ) : null} */}
            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={() => {
                handleCancelAssignment();
              }}
              disabled={selectIds.length < 1}
            >
              Hủy phân công
            </Button>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <Grid mt={10}>
          <GridCol span={{ base: 12, md: 6, lg: 1.825 }}>
            <InputSearch
              width="100%"
              placeholder="Từ khoá"
              onSearch={fetchData}
              onChange={(value) => {
                handleChangeSearchValue(value, "keySearch");
              }}
            />
          </GridCol>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.825 }}>
            <TextInput
              placeholder="Serial"
              leftSection={<IconBarcode color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(e.currentTarget.value, "serial")
              }
            />
          </Grid.Col>
          <GridCol span={{ base: 12, md: 6, lg: 1.825 }}>
            <Select
              placeholder={"Nguồn chứng từ"}
              clearable
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={sourceTypeData}
              leftSection={<IconMapRoute color="#15aabf" />}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "sourceType")
              }
              renderOption={(option) => (
                <Group flex="1" gap="xs">
                  <Badge
                    color={
                      option.option.value === "DON_HANG"
                        ? "cyan"
                        : option.option.value === "NHAP_KHO_BH"
                        ? "orange"
                        : option.option.value === "CHUYEN_DOI_DU_LIEU"
                        ? "blue"
                        : option.option.value === "NHAP_KHO_BH_NCC"
                        ? "teal"
                        : ""
                    }
                    radius="sm"
                    variant="filled"
                  >
                    {option.option.label}
                  </Badge>
                </Group>
              )}
            />
          </GridCol>

          <GridCol span={{ base: 12, md: 6, lg: 1.825 }}>
            <Select
              placeholder={"Trạng thái KT"}
              clearable
              leftSection={<IconStatusChange color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataStatusOption}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "status")
              }
              renderOption={renderSelectOptionTestingStatus}
            />
          </GridCol>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.825 }}>
            <DatePickerInput
              size="sm"
              placeholder="Từ ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "fromDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.825 }}>
            <DatePickerInput
              size="sm"
              placeholder="Đến ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <GridCol span={{ base: 12, md: 12, lg: 1.05 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default TestingTechnique;
