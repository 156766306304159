import { Badge, Button, Flex } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { _localization } from "../../../../config/location";
import { green } from "../../../../const/variables";
import { repositoryMdm } from "../../../../_base/_const/_constVar";

const BorrowedGoodList = ({
  keySearch,
  setKeySearch,
  setDataReceiveTicket,
  setDataForm,
  setDataProduct,
  setSerialSelect,
}: {
  keySearch: any;
  setKeySearch: any;
  setDataReceiveTicket: any;
  setDataForm: any;
  setDataProduct: any;
  setSerialSelect: any;
}) => {
  //data and fetching state
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [data, setData] = useState<any[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "errorNumber",
        header: "Số phiếu tiếp nhận hàng lỗi",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "createByName",
        header: "Người tiếp nhận",
      },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng hẹn gặp",
      },
      {
        accessorKey: "custPhone",
        header: "Số điện thoại",
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ hẹn gặp",
      },
      {
        accessorKey: "action",
        header: "Chọn phiếu",
        Cell: ({ row }) => (
          <Flex justify={"center"}>
            <Button
              variant="filled"
              color="teal"
              disabled={
                row.original.borrowed || row.original.attribute6 !== "DA_NHAP"
              }
              onClick={() => {
                setSerialSelect(new Set());
                setKeySearch(row.original.errorNumber);
                setDataReceiveTicket(row.original);
                setDataForm((prev: any) => ({
                  ...prev,
                  receiveId: row.original.id,
                  inventoryId: row.original.inventoryId,
                  branchId: row.original.branchId,
                }));
                setDataProduct(
                  row.original.tblWarrantyReceiveTicketDetailModels?.map(
                    (item: any) => ({
                      id: 0,
                      status: "Đã qua sử dụng",
                      actualPrice: 0,
                      note: item.note,
                      type: true,
                      sellingPrice: 0,
                      attribute1: item.acessory,
                      attribute2: null,
                      attribute3: null,
                      attribute4: 1,
                      attribute5: null,
                      attribute6: null,
                      deposit: null,
                      orgPrice: item.latestPrice,
                      serial: item.serial,
                      itemBorrowId: null,
                      itemCode: item.itemCode,
                      itemName: item.itemName,
                      uomName: item.uomName,
                      quantity: 1,
                    })
                  )
                );
              }}
            >
              {row.original.borrowed
                ? "Đã chọn"
                : row.original.attribute6 === "DA_TIEP_NHAN"
                ? "Chưa nhập kho"
                : row.original.attribute6 === "DA_TRA_KHACH"
                ? "Đã trả khách"
                : "Chọn"}
            </Button>
          </Flex>
        ),
        enableColumnActions: false,
        enableSorting: false,
        size: 50,
      },
    ],
    []
  );

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=0&Take=50&SelectToBorrow=true&BranchId=${
      JSON.parse(localStorage.getItem("userLoginHeader") || "{}")?.branchId
    }`;

    if (keySearch) {
      url += `&KeySearch=${keySearch.trim()}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyReceiveTicket/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [keySearch]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableColumnActions: false,
    enableSorting: false,
    enableTopToolbar: false,
    renderBottomToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 250, minHeight: 100 },
    },
    enableStickyHeader: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default BorrowedGoodList;
