import React from "react";
import { Modal, Button, List, Text, ScrollArea } from "@mantine/core";

interface ItemSelectionModalProps {
  isVisible: boolean;
  data: any[];
  onSelectItem: (item: any) => void;
  onClose: () => void;
}

function ItemSelectionModal({
  isVisible,
  data,
  onSelectItem,
  onClose,
}: ItemSelectionModalProps) {
  return (
    <Modal
      opened={isVisible}
      onClose={onClose}
      title="Chọn đơn hàng gốc khách đã mua lần trước"
      size="lg"
      overlayProps={{
        opacity: 0.3,
        blur: 3,
      }}
    >
      <ScrollArea
        style={{
          maxHeight: "500px", // Giới hạn chiều cao cuộn
          overflowY: "auto", // Bật cuộn dọc khi nội dung vượt quá
          border: "1px solid #ddd", // Thêm viền cho ScrollArea để dễ nhận biết
          borderRadius: "8px", // Bo góc
          padding: "10px", // Khoảng cách bên trong
          backgroundColor: "#fff", // Nền trắng cho dễ nhìn
        }}
      >
        <List spacing="sm" style={{ padding: "10px" }}>
          {data.map((item, index) => (
            <List.Item
              key={index}
              onClick={() => onSelectItem(item)}
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                marginBottom: "10px",
                backgroundColor: "#f9f9f9",
                cursor: "pointer",
                listStyleType: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Cột bên trái: Mã sản phẩm và Mã đơn hàng */}
                <Text>
                  <strong>Mã đơn hàng:</strong> {item.attribute19}
                </Text>

                {/* Cột bên phải: Giá bán */}
                <div
                  style={{ flex: 1, textAlign: "right", marginLeft: "20px" }}
                >
                  <Text>
                    <strong>Giá bán:</strong>{" "}
                    <span style={{ color: "#d32f2f", fontWeight: "bold" }}>
                      {item.priceFinal?.toLocaleString()} ₫
                    </span>
                  </Text>
                </div>
              </div>
            </List.Item>
          ))}
        </List>
      </ScrollArea>

      <Button variant="outline" color="red" fullWidth mt="md" onClick={onClose}>
        Đóng
      </Button>
    </Modal>
  );
}

export default ItemSelectionModal;
