import style from "./RequestReceiptForm.module.css";
import logo from "../../../assets/images/logo-hacom-2024.png";
import { IconCheckbox, IconSquare } from "@tabler/icons-react";

const PrinterRequestReceiptForm = ({
  innerRef,
  dataPrint,
}: {
  innerRef: any;
  dataPrint: any;
}) => {
  return (
    <div ref={innerRef} className={style.main}>
      <div className={style.header}>
        <img src={logo} alt="logo-hacom-2024" width={150} height={"auto"} />
        <div>
          <div className={style.line}>
            <h3>CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ HACOM</h3>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Trụ sở: </p>
            <p>129+131 Lê Thanh Nghị, Q.Hai Bà Trưng, TP.Hà Nội</p>
          </div>
          <div className={style.line}>
            <div>
              <p className={style.cText}>Tel: </p>
              <p>19001903</p>
            </div>
            <div>
              <p className={style.cText}>Email:</p>
              <p>info@hacom.vn</p>
            </div>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Website:</p>
            <p>www.hacom.vn</p>
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div>
          <h2>PHIẾU TIẾP NHẬN YÊU CẦU</h2>
          <p style={{ fontStyle: "italic", marginTop: "-20px" }}>
            Số phiếu:{" "}
            {dataPrint?.attribute10 !== null && dataPrint?.attribute10 !== ""
              ? dataPrint?.attribute10
              : "Không có số phiếu"}
          </p>
        </div>
        <div>
          <div className={style.infoForm}>
            <div>
              <div>
                <p>Mã phiếu tiếp nhận yêu cầu:</p>
                <p>{dataPrint?.requestNum}</p>
              </div>
              <div>
                <p>Nhân viên tiếp nhận:</p>
                <p>{dataPrint?.createByName}</p>
              </div>
              <div>
                <p>Bộ phận/Phòng ban:</p>
                <p>{dataPrint?.depname}</p>
              </div>
            </div>
            <div>
              <div>
                <p>Ngày tiếp nhận:</p>
                <p>
                  {" "}
                  {String(new Date(dataPrint?.createDate).getDate()).padStart(
                    2,
                    "0"
                  )}
                  /
                  {String(
                    new Date(dataPrint?.createDate).getMonth() + 1
                  ).padStart(2, "0")}
                  /{new Date(dataPrint?.createDate).getFullYear()}{" "}
                  {String(new Date(dataPrint?.createDate).getHours()).padStart(
                    2,
                    "0"
                  )}
                  :
                  {String(
                    new Date(dataPrint?.createDate).getMinutes()
                  ).padStart(2, "0")}
                </p>
              </div>
              <div>
                <p>Chức danh:</p>
                <p>{dataPrint?.rolename}</p>
              </div>
              <div>
                <p>Chi nhánh xử lý yêu cầu:</p>
                <p>{dataPrint?.branchName}</p>
              </div>
            </div>
          </div>
          <div className={style.mainProcessingMethod}>
            <p className={style.processingMethod}>Hình thức xử lý:</p>
            <div>
              {dataPrint?.processingMethodName === "Xử lý từ xa" ? (
                <IconCheckbox size={14} />
              ) : (
                <IconSquare size={14} />
              )}
              <p>Xử lý từ xa</p>
            </div>
            <div>
              {dataPrint?.processingMethodName === "Xử lý tại CH/CN" ? (
                <IconCheckbox size={14} />
              ) : (
                <IconSquare size={14} />
              )}
              <p>Xử lý tại CH/CN</p>
            </div>
            <div>
              {dataPrint?.processingMethodName === "Xử lý tại nhà" ? (
                <IconCheckbox size={14} />
              ) : (
                <IconSquare size={14} />
              )}
              <p>Xử lý tại nhà</p>
            </div>
          </div>
        </div>
        <h3 className={style.titleCust}>Thông tin khách hàng</h3>
        <div className={style.infoCust}>
          <div className={style.nameAndPhone}>
            <div>
              <p>Khách hàng hẹn gặp:</p>
              <p>{dataPrint?.custMeeting}</p>
            </div>
            <div>
              <p>Số điện thoại:</p>
              <p>{dataPrint?.custPhone}</p>
            </div>
          </div>
          <div className={style.addr}>
            <p>Địa chỉ:</p>
            <p>{`${
              dataPrint?.additionalAddress &&
              dataPrint?.additionalAddress !== null
                ? `${dataPrint?.additionalAddress} -`
                : ""
            } ${
              dataPrint?.streetName && dataPrint?.streetName !== null
                ? `${dataPrint?.streetName} -`
                : ""
            } ${dataPrint?.communeName} - ${dataPrint?.districtName} - ${
              dataPrint?.provinceName
            }`}</p>
          </div>
        </div>
        <h3 className={style.titleCust}>Thông tin sản phẩm</h3>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Serial</th>
              <th>Tên sản phẩm</th>
              <th>Mã sản phẩm</th>
              <th>Đơn vị tính</th>
              <th>Thời hạn bảo hành</th>
              <th>Tình trạng ban đầu</th>
              <th>Loại dịch vụ</th>
            </tr>
          </thead>
          <tbody>
            {dataPrint?.tblWarrantyServiceRequestFormDetailModels?.map(
              (item: any, index: any) => (
                <tr key={index}>
                  <td>{item.serial}</td>
                  <td>{item.itemName}</td>
                  <td>{item.itemCode}</td>
                  <td>{item.uomName}</td>
                  <td>{item.warrantyTerm} tháng</td>
                  <td>{item.listErrorCode}</td>
                  <td>{item.serviceType}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
        <div className={style.note}>
          <p>Ghi chú:</p>
          <p>{dataPrint?.content}</p>
        </div>
        <div className={style.importantNote}>
          <p className={style.fw}>Lưu ý quan trọng miễn trừ trách nhiệm:</p>
          <p>Phiếu tiếp nhận này có</p>
          <p className={style.fw}>giá trị trong vòng 30 ngày</p>
          <p>
            sau thời gian này sản phẩm không được quý khách hàng nhận lại sẽ
            được xử lý hủy theo Quy định của Công ty Cổ phần Đầu tư Công nghệ
            HACOM.
          </p>
          <p className={style.fw}>
            HACOM không chịu trách nhiệm trong mọi trường hợp về dữ liệu, phần
            mềm chuyên dụng.
          </p>
        </div>
        <div className={style.importantNote}>
          <p className={style.fw}>
            Quý khách khi nhận lại sản phẩm phải có phiếu này.
          </p>
          <p>Mọi chi tiết xin liên hệ 19001903 phím 3.</p>
        </div>
        <div className={style.daySignature}>
          <div>
            <p>{"               "}</p>
          </div>
          <div>
            <p className={style.fw}>
              Hà Nội, ngày {String(new Date().getDate()).padStart(2, "0")} tháng{" "}
              {String(new Date().getMonth() + 1).padStart(2, "0")} năm{" "}
              {new Date().getFullYear()}
            </p>
          </div>
        </div>
        <div className={style.signature}>
          <div>
            <p className={style.fw}>Khách hàng</p>
            <p>
              {"("}Ký & ghi rõ họ tên{")"}
            </p>
          </div>
          <div>
            <p className={style.fw}>Kỹ thuật tiếp nhận</p>
            <p>
              {"("}Ký & ghi rõ họ tên{")"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrinterRequestReceiptForm;
