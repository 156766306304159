import React from "react";

type Props = {
  dataPrint: any;
  componentRef2: any;
  sourceCode: string;
};

const PrintPrOutWard = ({ dataPrint, componentRef2, sourceCode }: Props) => {
  // PHIẾU IN CÁC SẢN PHẨM CẦN XUẤT KHO ĐỂ CHO NHÂN VIÊN KHO ĐI LẤY HÀNG TRƯỚC KHI XUẤT KHO BÁN
  // console.log("dataPrint", dataPrint);
  //   console.log("sourceCode", sourceCode);
  return (
    <div
      ref={componentRef2}
      style={{
        height: "100%",
        margin: "0 auto",
        marginLeft: "40px",
        marginRight: "30px",
        // marginTop: "10px",
        fontFamily: `"Times New Roman", Times, serif`,
        position: "relative",
      }}
    >
      <div
        style={{
          // paddingTop: "10px",
          width: "100%",
          position: "relative",
          margin: "0 auto",
          marginRight: "20px",
          paddingRight: "20px",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Mã đơn hàng: {sourceCode ?? ""}
          </p>
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid black",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  STT
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    width: "100px",
                  }}
                >
                  Kho
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    width: "100px",
                  }}
                >
                  Vị trí
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Mã hàng
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Tên hàng hóa
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  ĐVT
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Số lượng
                </th>
              </tr>
            </thead>
            <tbody>
              {dataPrint?.map((item: any, index: number) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    {item?.invName}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                    }}
                  ></td>{" "}
                  {/* Vị trí chưa có */}
                  <td
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    {item?.itemCode}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    {item?.itemName}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    {item?.primaryUom}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.primaryQuantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PrintPrOutWard;
