import {
  Badge,
  Box,
  Button,
  Flex,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { paginationBase } from "../../_base/model/_base/BaseTable";
import { _localization } from "../../config/location";
import { green } from "../../const/variables";
import { UpdateStatusDetails } from "./ModalUpdateTestingTechnique";
import { IconEdit } from "@tabler/icons-react";

const ListProductDetail = ({
  typeSourceType,
  dataDetail,
  isDetail,
  form,
  height,
  datatestDifficultys,
  testingStatus,
  testingStatusBHNCC,
}: {
  typeSourceType?: string;
  dataDetail: any;
  isDetail: boolean;
  form?: UseFormReturnType<
    UpdateStatusDetails,
    (values: UpdateStatusDetails) => UpdateStatusDetails
  >;
  height?: number;
  datatestDifficultys?: any;
  testingStatus?: any;
  testingStatusBHNCC?: any;
}) => {
  // console.log("typeSourceType", typeSourceType);
  // console.log("dataDetail", dataDetail);
  // console.log("testingStatusBHNCC", testingStatusBHNCC);
  const [isEditStatus, setIsEditStatus] = useState(true);
  const testingStatuss = React.useMemo(
    () => [
      {
        label: "Test ok",
        value: "TEST_OK",
      },
      {
        label: "Test lỗi",
        value: "TEST_LOI",
      },
    ],
    []
  );
  const testDifficultys = React.useMemo(
    () => [
      {
        label: "Không test (0)",
        value: "KHONG_TEST",
      },
      {
        label: "Dễ (1)",
        value: "DE",
      },
      {
        label: "Đơn giản (2)",
        value: "DON_GIAN",
      },
      {
        label: "Bình thường (3)",
        value: "BINH_THUONG",
      },
      {
        label: "Khó (5)",
        value: "KHO",
      },
      {
        label: "Phức tạp (10)",
        value: "PHUC_TAP",
      },
    ],
    []
  );

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
      },

      {
        accessorKey: "serial",
        header: "Số serial",
      },
      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "quality",
        header: "Số lượng",
      },
      // {
      //   accessorKey: "lineTyle",
      //   header: "Loại mặt hàng",
      // },
      ...(typeSourceType == "DON_HANG"
        ? [
            {
              accessorKey: "lineTyle",
              header: "Loại mặt hàng", // Tiêu đề hiển thị
            },
          ]
        : []),
      // ...(typeSourceType == "NHAP_KHO_BH_NCC"
      //   ? [
      //       {
      //         accessorKey: "serialStatus",
      //         header: "Trạng thái Serial", // Tiêu đề hiển thị
      //       },
      //     ]
      //   : []),
      ...(typeSourceType == "CHUYEN_DOI_DU_LIEU"
        ? [
            {
              accessorKey: "invPositionName",
              header: "Vị trí trong kho", // Tiêu đề hiển thị
            },
          ]
        : []),
      {
        accessorKey: "testingStatus",
        header: "Trạng thái kiểm tra",
        Cell: ({ renderedCellValue, row }) =>
          form ? (
            <Select
              clearable
              searchable
              disabled={
                // row.original.canUpdate === false || // Nếu canUpdate là false, không cho chỉnh sửa
                row.original.testingStatus === "TEST_OK" && isEditStatus // Điều kiện disable cũ
                  ? true
                  : false
              }
              // placeholder="Chọn mức độ"
              data={testingStatuss}
              value={form
                ?.getValues()
                ?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.find(
                  (d) => d.id === row.original?.id
                )
                ?.testingStatus?.toString()}
              onChange={(value) =>
                form.setValues((prev) => ({
                  ...prev,
                  updateStatusTblTestingTechniqueHeaderDetailCommandModels:
                    prev?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.map(
                      (item) =>
                        item.id === row.original?.id
                          ? { ...item, testingStatus: value }
                          : item
                    ),
                }))
              }
            />
          ) : (
            <Text>
              {
                testingStatuss?.find(
                  (item) =>
                    item.value.toString() === row?.original?.testingStatus
                )?.label
              }
            </Text>
          ),
      },
      {
        accessorKey: "lastUpdateByName",
        header: "Người kiểm tra",
      },
      // {
      //   accessorKey: "testDifficulty",
      //   header: "Mức độ test",
      //   Cell: ({ renderedCellValue, row }) =>
      //     form ? (
      //       <Select
      //         clearable
      //         searchable
      //         placeholder="Chọn mức độ"
      //         data={testDifficultys}
      //         value={form
      //           ?.getValues()
      //           ?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.find(
      //             (d) => d.id === row.original?.id
      //           )
      //           ?.testDifficulty?.toString()}
      //         onChange={(value) =>
      //           form.setValues((prev) => ({
      //             ...prev,
      //             updateStatusTblTestingTechniqueHeaderDetailCommandModels:
      //               prev?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.map(
      //                 (item) =>
      //                   item.id === row.original?.id
      //                     ? { ...item, testDifficulty: value }
      //                     : item
      //               ),
      //           }))
      //         }
      //       />
      //     ) : (
      //       <Text>
      //         {
      //           testDifficultys?.find(
      //             (item) =>
      //               item.value.toString() === row?.original?.testDifficulty
      //           )?.label
      //         }
      //       </Text>
      //     ),
      // },

      {
        accessorKey: "noteByEmployee",
        header: "Ghi chú",
        Cell: ({ renderedCellValue, row }) =>
          form ? (
            <TextInput
              placeholder="Nhập ghi chú"
              value={form
                ?.getValues()
                ?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.find(
                  (d) => d.id === row.original?.id
                )
                ?.noteByEmployee?.toString()}
              onChange={(e) =>
                form.setValues((prev) => ({
                  ...prev,
                  updateStatusTblTestingTechniqueHeaderDetailCommandModels:
                    prev?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.map(
                      (item) =>
                        item.id === row.original?.id
                          ? { ...item, noteByEmployee: e.target?.value }
                          : item
                    ),
                }))
              }
              // disabled={row.original.canUpdate === false}
            />
          ) : (
            <Text>{renderedCellValue}</Text>
          ),
      },
    ],
    [isEditStatus, form]
  );
  //data and fetching state
  const [data] = useState<any[]>([dataDetail]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);
  // const handleUpdateAllStatuses = (value: any) => {
  //   form?.setValues((prev) => {
  //     return {
  //       ...prev,
  //       updateStatusTblTestingTechniqueHeaderDetailCommandModels:
  //         prev?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.map(
  //           (item) => ({
  //             ...item,
  //             testingStatus:
  //               item.testingStatus === "TEST_OK" ? item.testingStatus : value,
  //           })
  //         ),
  //     };
  //   });
  // };
  const handleUpdateAllStatuses = (value: any, rows: any[]) => {
    form?.setValues((prev) => {
      return {
        ...prev,
        updateStatusTblTestingTechniqueHeaderDetailCommandModels:
          prev?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.map(
            (item) => {
              // Lấy trạng thái ban đầu từ hàng tương ứng
              const row = rows.find((row) => row.original.id === item.id);
              const initialStatus = row?.original.testingStatus;

              // Nếu trạng thái ban đầu là TEST_OK, giữ nguyên
              return {
                ...item,
                testingStatus:
                  initialStatus === "TEST_OK" || initialStatus === "TEST_LOI"
                    ? initialStatus
                    : value,
              };
            }
          ),
      };
    });
  };

  const table = useMantineReactTable({
    columns,
    data: dataDetail ? dataDetail : [],
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    enableRowActions: true,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    renderToolbarInternalActions: () => <></>,
    renderTopToolbarCustomActions: ({ table }) => {
      const rows = table.getRowModel().rows;
      return (
        <>
          {form !== undefined || testingStatus !== undefined ? (
            <>
              <Flex direction={"row"} justify={"start"} gap={"md"}>
                <div>
                  <Select
                    label="Mức độ test của phiếu"
                    clearable
                    withAsterisk
                    data={testDifficultys}
                    readOnly={form !== undefined ? false : true}
                    searchable
                    value={
                      form !== undefined
                        ? form?.getValues()?.testDifficulty
                        : datatestDifficultys
                    }
                    onChange={(value) => {
                      form?.setFieldValue("testDifficulty", value);
                    }}
                  />
                  <span
                    style={{
                      fontSize: "10px",
                      color: "red",
                      marginLeft: "7px",
                      display: `${
                        form !== undefined &&
                        form.getValues()?.testDifficulty == null
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    {form !== undefined &&
                    form.getValues()?.testDifficulty == null
                      ? "Vui lòng chọn mức độ test"
                      : ""}
                  </span>
                </div>
                {form !== undefined ? (
                  <>
                    <Select
                      label="Trạng thái kiểm tra"
                      clearable
                      searchable
                      readOnly={form !== undefined ? false : true}
                      data={testingStatuss}
                      // onChange={(e) => handleUpdateAllStatuses(e)}
                      onChange={(value) => {
                        handleUpdateAllStatuses(value, rows); // Truyền danh sách hàng vào
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Flex>
            </>
          ) : null}
        </>
      );
    },
    enableTopToolbar:
      form !== undefined || testingStatus !== undefined ? true : false,
    renderRowActions: ({ row }) => {
      return (
        <>
          {form !== undefined ? (
            <>
              <Flex gap="4">
                {row.original.testingStatus == "TEST_OK" ? (
                  <>
                    {/* <Tooltip label="Chỉnh sửa">
                      <IconEdit
                        color={
                          row.original.canUpdate === false ? "gray" : "orange"
                        } // Thay đổi màu sắc nếu không thể chỉnh sửa
                        style={{
                          cursor:
                            row.original.canUpdate === false
                              ? "not-allowed"
                              : "pointer", // Cập nhật lại cursor
                        }}
                        onClick={() => setIsEditStatus(!isEditStatus)}
                      ></IconEdit>
                    </Tooltip> */}
                    <IconEdit
                      color={"orange"} // Thay đổi màu sắc nếu không thể chỉnh sửa
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => setIsEditStatus(!isEditStatus)}
                    ></IconEdit>
                  </>
                ) : (
                  <>
                    <Tooltip label="Chỉnh sửa">
                      <IconEdit
                        color="gray"
                        style={{
                          cursor: "not-allowed",
                        }}
                        onClick={() => {}}
                      ></IconEdit>
                    </Tooltip>
                  </>
                )}
              </Flex>
            </>
          ) : (
            <>
              <Flex gap="4">
                <>
                  <Tooltip label="Chỉnh sửa">
                    <IconEdit
                      color="gray"
                      style={{
                        cursor: "not-allowed",
                      }}
                      onClick={() => {}}
                    ></IconEdit>
                  </Tooltip>
                </>
              </Flex>
            </>
          )}
        </>
      );
    },
    localization: _localization,
    enableRowSelection: false,
    enablePagination: true,
    enableEditing: true,
    enableBottomToolbar: isDetail,
    enableFilters: false,
    enableColumnActions: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["itemCode"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: !isDetail ? 255 : height,
        minHeight: !isDetail ? 100 : height,
      },
    },
    enableStickyHeader: true,
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    rowCount: data?.length,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineTableProps: () => ({
      striped: true,
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <Box>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default ListProductDetail;
