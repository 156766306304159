import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";

const DeleteView = ({ idItem, sourceType, onClose }: DeleteProduct) => {
  const handleDeleteBranch = async () => {
    const url = getOutwardApiUrl(sourceType);
    const res = await repositoryPos.post(url);
    if (res)
      if (res.success) {
        NotificationExtension.Success("Xóa thành công !");
        onClose((prev: any) => !prev);
      } else NotificationExtension.Fails("Xóa thất bại !");
    modals.closeAll();
  };

  const getOutwardApiUrl = (sourceType: string | null): string => {
    const url = "delete";
    switch (sourceType) {
      case "XUAT_BAN":
        return `/api/v1/Outward/${url}-sale-order-out-ward?Ids=${idItem}&SourceType=${sourceType}`;
      case "XUAT_HUY":
        return `/api/v1/Outward/${url}-request-cancellation-outward?Ids=${idItem}&SourceType=${sourceType}`;
      case "XUAT_DIEU_CHINH":
        return `/api/v1/Outward/${url}-request-adjustment-outward?Ids=${idItem}&SourceType=${sourceType}`;
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return `/api/v1/Outward/${url}-request-transfer-outward?Ids=${idItem}&SourceType=${sourceType}`;
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return `api/v1/Outward/${url}-request-transfer-internal-outward?Ids=${idItem}&SourceType=${sourceType}`;
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return `/api/v1/Outward/${url}-request-transfer-serial-outward?Ids=${idItem}&SourceType=${sourceType}`;
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return `/api/v1/Outward/${url}-request-transfer-internal-serial-outward?Ids=${idItem}&SourceType=${sourceType}`;
      case "XUAT_LINH_KIEN_SAN_XUAT":
        return `/api/v1/Outward/${url}-production-order-outward?Ids=${idItem}&SourceType=${sourceType}`;
      case "XUAT_TRA_NCC":
        return `/api/v1/Outward/${url}-request-vendor-return-outward?Ids=${idItem}&SourceType=${sourceType}`;
      case "XUAT_KHAC":
        return `/api/v1/Outward/${url}-request-vendor-return-outward?Ids=${idItem}&SourceType=${sourceType}`;
      default:
        throw new Error("Unknown source type");
    }
  };

  return (
    <div>
      <Text size="20px" mt={5}>
        Bạn có chắc chắn muốn xóa phiếu xuất kho này?
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="red"
          onClick={handleDeleteBranch}
          leftSection={<IconCheck size={18} />}
        >
          Xóa
        </Button>
      </Group>
    </div>
  );
};

type DeleteProduct = {
  idItem: number | string;
  onClose: any;
  sourceType: string;
};

export default DeleteView;
