
import { Badge, Card, Group, Select, SelectProps } from "@mantine/core";
import { IconCheck, IconMenuOrder } from "@tabler/icons-react";
import { IAfterGuiAttachedParams, IDoesFilterPassParams } from "ag-grid-enterprise";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import React, { useCallback, useRef } from "react";

export default ({ model, onModelChange, getValue }: CustomFilterProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const { node } = params;
      const filterText: string = model;
      const value: string = getValue(node).toString().toLowerCase();
      // make sure each word passes separately, ie search for firstname, lastname
      const result = filterText
        .toLowerCase()
        .split(" ")
        .every((filterWord) => value.indexOf(filterWord) >= 0);
      return result;
    },
    [model]
  );

  const afterGuiAttached = useCallback((params?: IAfterGuiAttachedParams) => {
    if (!params || !params.suppressFocus) {
      // Focus the input element for keyboard navigation.
      // Can't do this in an effect,
      // as the component is not recreated when hidden and then shown again
      refInput.current?.focus();
    }
  }, []);

  // register filter handlers with the grid
  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });
  const orderTypes = [
    { value: "1", label: "Online", color: "blue" },
    { value: "2", label: "Bán lẻ", color: "indigo" },
    { value: "3", label: "Đơn đổi trả", color: "green" },
  ];
  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={orderTypes.find((x) => x.value === option.value)?.color}
        radius="sm"
        variant="filled"
      ></Badge>
      {option.label}{" "}
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );
  return (
    <div>
      <Select
        searchable
        clearable
        placeholder="Loại đơn hàng"
        comboboxProps={{
          transitionProps: { transition: "fade-up", duration: 200 },
        }}
        mt={"5px"}
        leftSection={<IconMenuOrder color="#15aabf" />}
        data={orderTypes}
        multiple={true}
        onChange={(value) => {
          onModelChange({
            filter: value === null ? "" : value,
            filterType: "text",
            type: "contains",
          });
        }}
        nothingFoundMessage="Dữ liệu không có !"
        renderOption={renderSelectOption}
      />
    </div>
  );
};
