import { ActionIcon, Badge, Box, Flex, Tooltip } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { IconRefresh } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { formatDateNotTimeZone } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";

const GetDataCheckingExit = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  // const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [pagination, setPagination] = useState(paginationBase);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [visible, { toggle, open, close }] = useDisclosure(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [isEditing, setIsEditing] = useState(false);

  const [data, setData] = useState<any[]>([
    {
      index: 1,
      tenBang: "TblSalesOrder",
      noiDung: "Bảng trong database tên là Tbl_Don_mua_hang",
      ngayBatDau: null,
      ngayKetThuc: null,
    },
    {
      index: 2,
      tenBang: "TblCashReceipt",
      noiDung: "Bảng trong database tên là Tbl_cash_disbursement",

      ngayBatDau: null,
      ngayKetThuc: null,
    },
    // {
    //   index: 3,
    //   tenBang: "TblCreditNote",
    //   noiDung: "Bảng trong database tên là Tbl_Credit_note",

    //   ngayBatDau: null,
    //   ngayKetThuc: null,
    // },
    {
      index: 3,
      tenBang: "TblDeliveryNote",
      noiDung: "Bảng trong database tên là Tbl_Phieu_Xuat_Kho",

      ngayBatDau: null,
      ngayKetThuc: null,
    },
  ]);
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        size: 100,
        enableEditing: false,
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenBang",
        header: "Tên bảng",
        size: 200,
        enableEditing: false,

        Cell: ({ renderedCellValue }) => (
          <>
            <Badge variant="dot" color="green" size="lg" radius="sm">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "noiDung",
        header: "Nội dung",
        size: 320,
        enableEditing: false,

        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "ngayBatDau",
        header: "Ngày bắt đầu",
        size: 200,
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            {" "}
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày bắt đầu"
              locale="vi"
              w={250}
              valueFormat="DD/MM/YYYY"
              onChange={(date) => {
                handleChange(row.original.tenBang, {
                  ngayBatDau: formatDateNotTimeZone(date) || "",
                });
              }}
              clearable
            />
          </>
        ),
      },
      {
        accessorKey: "ngayKetThuc",
        header: "Ngày kết thúc",
        size: 200,
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày kết thúc"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              w={250}
              minDate={row.original.ngayBatDau}
              onChange={(date) => {
                handleChange(row.original.tenBang, {
                  ngayKetThuc: formatDateNotTimeZone(date) || "",
                });
              }}
              clearable
            />
          </>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        Cell: ({ row }) => (
          <Box>
            <Flex direction={"row"} align={"center"} gap={"sm"}>
              <Tooltip label="Lấy dữ liệu">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="violet"
                  onClick={() =>
                    handleGetData(
                      row.original.tenBang,
                      row.original.ngayBatDau,
                      row.original.ngayKetThuc
                    )
                  }
                >
                  <IconRefresh size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Box>
        ),
      },
    ],
    [data, rowCount]
  );

  const handleChange = (tenBang: string, updates: Record<string, any>) => {
    const updatedData = data.map((item: any) =>
      item.tenBang?.toString() === tenBang?.toString()
        ? {
            ...item,
            ...updates,
          }
        : item
    );
    setData(updatedData);
  };

  const handleGetData = async (tenBang: any, startDate: any, endDate: any) => {
    if (startDate === null || endDate === null) {
      NotificationExtension.Fails(
        "Vui lòng chọn ngày bắt đầu và ngày kết thúc"
      );
      return;
    }
    const formDataSubmit = {
      typeMessage: tenBang,
      ma_cty: null,
      startDate: startDate,
      endDate: endDate,
      pageSize: 0,
      pageNumber: 0,
    };
    const response = await repositoryMdm.post(
      "/api/v1/SyncData/get-data-pay-order-warehouse",
      formDataSubmit
    );
    if (response && response.success) {
      NotificationExtension.Success("Lấy dữ liệu thành công");
    } else {
      NotificationExtension.Fails("Lấy dữ liệu thất bại");
    }
  };
  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableEditing: false,
    enableColumnResizing: true,
    enableTopToolbar: false,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "data_json"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 550,
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (260 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default GetDataCheckingExit;
