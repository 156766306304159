import React, { useState } from "react";
import { Tooltip, ActionIcon, Modal, Select, Button } from "@mantine/core";
import { IconLock } from "@tabler/icons-react";
import { repositoryPos } from "../../../../_base/_const/_constVar";

const CloseOrderButton = ({ row }: { row: any }) => {
  const [opened, setOpened] = useState(false); // Trạng thái mở popup
  const [selectedReason, setSelectedReason] = useState(""); // Lý do được chọn

  const reasons = [
    { value: "T1", label: "T1 Bom hàng / Trả hàng hủy cọc" },
    { value: "T2", label: "T2 Thao tác nhầm(xử lý tại chỗ)" },
    { value: "T6", label: "T6 KH đổi ý(xử lý tại chỗ)" },
  ];

  const handleCloseOrder = async () => {
    if (!selectedReason) {
      alert("Vui lòng chọn lý do đóng đơn!");
      return;
    }

    const url = `/api/v1/CreateSellItem/close?id=${row.id}&reason=${selectedReason}`;
    try {
      const response = await repositoryPos.get(url);
      if (response?.status === 200) {
        console.log("Đơn hàng đã được đóng thành công.");
        // Thực hiện các hành động khác (nếu cần)
      } else {
        console.error(
          "Lỗi khi đóng đơn:",
          response?.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Lỗi khi gọi API đóng đơn:", error);
    }

    setOpened(false); // Đóng modal sau khi hoàn tất
  };

  return (
    <>
      {/* Nút mở popup */}
      <Tooltip label="Đóng đơn">
        <ActionIcon
          variant="light"
          aria-label="Đóng đơn"
          color="red"
          onClick={() => setOpened(true)}
        >
          <IconLock size={20} />
        </ActionIcon>
      </Tooltip>

      {/* Popup chọn lý do */}
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Chọn lý do đóng đơn"
        centered
      >
        <Select
          mt={15}
          data={reasons}
          placeholder="Chọn lý do"
          value={selectedReason}
          onChange={(value) => {
            setSelectedReason(value || "");
          }} // Xử lý giá trị null
          searchable
        />
        <Button
          fullWidth
          mt="md"
          color="red"
          onClick={handleCloseOrder}
          disabled={!selectedReason}
        >
          Xác nhận đóng đơn
        </Button>
      </Modal>
    </>
  );
};

export default CloseOrderButton;
