import {
  ActionIcon,
  Badge,
  Box,
  Button,
  FileButton,
  Flex,
  Group,
  Menu,
  rem,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCaretDown,
  IconFilePlus,
  IconPlus,
  IconRefresh,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";

type dataSubmit = {
  keySearch: string;
  typeMessage: string;
  startDate: string;
  endDate: string;
  status: string;
};

const formatDateNotTimeZoneee = (
  date: Date | string | null | undefined
): string => {
  if (date === null || date === undefined) {
    return "";
  }
  return moment(date).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
};

const ImportSyncChecking = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [pagination, setPagination] = useState(paginationBase);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [visible, { toggle, open, close }] = useDisclosure(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [selectIds, setSelectIds] = useState<string[]>([]); // lưu những dữ liệu đã tích

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "so_ct",
        header: "Dữ liệu",
        minSize: 200,
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge variant="dot" color="green" size="lg" radius="sm">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "ngay_ct",
        header: "Ngày chứng từ",
        minSize: 300,

        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        // size: 20,
        Cell: ({ row }) => (
          <Box>
            <Flex direction={"row"} align={"center"} gap={"sm"}>
              <Tooltip label="Đồng bộ">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="violet"
                  onClick={() => handleDongBoAll(0, [row.original.so_ct])}
                >
                  <IconUpload size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Box>
        ),
      },
    ],
    [data, rowCount]
  );
  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableColumnResizing: true,

    enableTopToolbar: false,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "so_ct"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: true,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 550,
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //Import excel
  const handleFileUpload = (file: File | null) => {
    if (!file) {
      NotificationExtension.Warn("Không có tập tin nào được chọn");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const binaryStr = event.target?.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });

        // Lấy sheet đầu tiên
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Chuyển dữ liệu từ sheet thành JSON
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        // Map dữ liệu vào state `data`
        setData(
          jsonData.map((row: any, index: number) => ({
            id: index + 1, // Thêm ID nếu cần
            ...row, // Các cột từ file Excel
          }))
        );

        NotificationExtension.Success("Import dữ liệu thành công!");
      } catch (err) {
        console.error(err);
        NotificationExtension.Warn("Có lỗi xảy ra khi xử lý file.");
      }
    };

    reader.readAsBinaryString(file);
  };

  //Đồng bộ tất cả
  const handleDongBoAll = async (type: number, Codes?: any) => {
    const valueSubmit = {
      codes: selectIds.length > 0 ? selectIds : Codes,
      type: type, //type 0 là tạo mới, 1 là cập nhật,2 là delete
    };
    if (valueSubmit) {
      try {
        const response = await repositoryMdm.post(
          "/api/v1/SyncData/repeat-sync-checking-exist",
          valueSubmit
        );
        console.log("response", response);
        if (response && response.success) {
          if (type === 1) {
            NotificationExtension.Success("Cập nhật thành công");
          } else if (type === 0) {
            NotificationExtension.Success("Tạo mới thành công");
          }
        } else {
          NotificationExtension.Fails("Có lỗi xảy ra.Vui lòng thử lại!");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    setIsLoading(false);
    setIsRefetching(false);
  };
  useEffect(() => {
    // Lấy danh sách chỉ số của các hàng được chọn
    const selectedIndexes = Object.keys(rowSelection).map(Number);
    // Lấy danh sách ID dựa trên các chỉ số
    const so_cts = selectedIndexes.map((index) => data[index]?.so_ct);
    // Cập nhật mảng ID đã chọn
    setSelectIds(so_cts);
  }, [rowSelection, data]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (260 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <Flex
        direction={"row"}
        align={"center"}
        justify={"end"}
        style={{
          border: "1px solid #dee2e6",
          overflowX: "hidden",
          padding: "10px 10px",
        }}
        gap={"md"}
      >
        <Group>
          <FileButton
            onChange={(file) => file && handleFileUpload(file)}
            accept=".xlsx, .xls"
          >
            {(props) => (
              <Button
                leftSection={<IconFilePlus size={20} />}
                variant="light"
                {...props}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Import Excel
              </Button>
            )}
          </FileButton>
        </Group>
        <Menu shadow="md" width={220}>
          <Menu.Target>
            <Button
              // size="xs"
              rightSection={
                <IconCaretDown style={{ width: rem(16), height: rem(16) }} />
              }
            >
              Chức năng đồng bộ
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              leftSection={
                <IconPlus style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleDongBoAll(0)}
            >
              Tạo lại đơn
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconRefresh style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleDongBoAll(1)}
            >
              Cập nhật đơn
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleDongBoAll(2)}
            >
              Xóa đơn
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ImportSyncChecking;
