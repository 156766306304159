import React, { useEffect } from "react";
import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";

interface TblShipCompany {
  id: number;
  chon: string;
  DoiTacVanChuyen: string;
}

interface ShipCompanyTableProps {
  onSelect: (selectedData: TblShipCompany) => void;
}

const ChooseShipDepartment: React.FC<ShipCompanyTableProps> = ({
  onSelect,
}) => {
  const data: TblShipCompany[] = [
    {
      id: 1,
      chon: "Chọn",
      DoiTacVanChuyen: "Kỹ thuật",
    },
    {
      id: 2,
      chon: "Chọn",
      DoiTacVanChuyen: "NV điều vận",
    },
    {
      id: 3,
      chon: "Chọn",
      DoiTacVanChuyen: "Kỹ thuật thi công",
    },
    {
      id: 4,
      chon: "Chọn",
      DoiTacVanChuyen: "Dự án",
    },
  ];

  const columns = React.useMemo<MRT_ColumnDef<TblShipCompany>[]>(
    () => [
      {
        accessorKey: "chon",
        header: "Chọn",
        Cell: ({ row }) => (
          <Button
            size="xs"
            onClick={() => {
              onSelect(row.original);
              modals.closeAll();
            }}
          >
            Chọn
          </Button>
        ),
        size: 50,
      },
      {
        accessorKey: "DoiTacVanChuyen",
        header: "Bộ phận",
        size: 200,
      },
    ],
    [onSelect]
  );

  useEffect(() => {
    const fetchDataCreate = async () => {
      const url = `/api/v1/CreateSellItem/shipping-vendor-quantity`;
      try {
        const response = await repositoryPos.get<MessageResponse<any>>(url);
        if (response && response?.success) {
          console.log("response", response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataCreate();
  }, []);

  return (
    <MantineReactTable
      columns={columns}
      data={data}
      enableColumnFilters={false}
      enableSorting={false}
      enablePagination={false}
      enableGlobalFilter={false}
      enableRowActions={false}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      mantinePaperProps={{
        shadow: "sm",
        radius: "md",
      }}
    />
  );
};

export default ChooseShipDepartment;
