"use strict";

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import { createRoot } from "react-dom/client";

import {
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { randomId } from "@mantine/hooks";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { ReportInventorySerialOnhandModel } from "../../model/Report/ReportInventorySerialOnhandModel";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import {
  ReportOrderInfoModel,
  ResponseReportOrderInfoModel,
} from "../../model/Report/ResponseReportOrderInfoModel";
import { NumberFormatter } from "@mantine/core";

import {
  AllCommunityModule,
  CellSelectionOptions,
  ClientSideRowModelModule,
  ColDef,
  ColGroupDef,
  DefaultMenuItem,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  ModuleRegistry,
  ValidationModule,
  createGrid,
} from "ag-grid-community";
import { AG_GRID_LOCALE_VN } from "@ag-grid-community/locale";
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import {
  AdvancedFilterModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  GetRowIdParams,
  GridChartsModule,
  IServerSideDatasource,
  IServerSideGetRowsRequest,
  MasterDetailModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowClassParams,
  RowGroupingModule,
  RowStyle,
  ServerSideRowModelModule,
  SetFilterModule,
  SparklinesModule,
  StatusBarModule,
} from "ag-grid-enterprise";

ModuleRegistry.registerModules([
  ColumnsToolPanelModule,
  MenuModule,
  ServerSideRowModelModule,
  RichSelectModule,
  SetFilterModule,
  AdvancedFilterModule,
  FiltersToolPanelModule,
  MultiFilterModule,
  MasterDetailModule,
  ClientSideRowModelModule,
  ExcelExportModule,
  GridChartsModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  SparklinesModule,
  ClipboardModule,
]);

export const ReportSalesListView = () => {
  const [loading, setLoading] = useState(true);
  const gridRef = useRef<AgGridReact<ReportOrderInfoModel>>(null);
  const [height, setHeight] = useState(800);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 800;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  const containerStyle = useMemo(() => ({ width: "100%", height: height }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const fetchData = async (request: IServerSideGetRowsRequest) => {
    try {
      const queryParams = {
        Request: request,
      };

      return await repositoryPos.post<
        MessageResponse<ResponseReportOrderInfoModel>
      >("/api/v1/report/order-info", queryParams);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getServerSideDatasource: () => IServerSideDatasource = () => {
    return {
      getRows: async (params) => {
        var response = await fetchData(params.request);
        if (response && response.success) {
          setTotalCount(response.data.count);
          params.success({
            rowData: response.data.reportOrderInfoModel ?? [],
            rowCount: response.data.count ?? 0,
          });
          setLoading(false);
        } else {
          params.fail();
          setLoading(false);
        }
      },
    };
  };
  const getRowId = useCallback(
    (params: GetRowIdParams<ReportOrderInfoModel>) => {
      if (params.data.orderNumber != null) {
        return "leaf-" + params.data.orderNumber;
      }
      return randomId();
    },
    []
  );

  const filterParamsDefault = {
    buttons: ["clear", "reset", "apply"],
    debounceMs: 1000,
    maxNumConditions: 5,
  };

  const [columnDefs, setColumnDefs] = useState<ColDef<ReportOrderInfoModel>[]>([
    {
      field: "orderNumber",
      colId: "orderNumber",
      headerName: "Số hóa đơn",
      filter: "agTextColumnFilter",
      pinned: true,
      //   cellRenderer: (params: CustomCellRendererProps) => {
      //     return (
      //       <>
      //         {params.data.subInventoryCode
      //           ? "[" + params.data.subInventoryCode + "]"
      //           : ""}{" "}
      //         {params.data.subInventoryName}
      //       </>
      //     );
      //   },
    },

    {
      field: "orderDate",
      headerName: "Ngày lập hóa đơn",
      filter: "agDateColumnFilter",
      cellRenderer: (params: CustomCellRendererProps) => {
        if (typeof params.value === "string" || params.value instanceof Date) {
          return <> {params.value && formatDateTransfer(params.value)}</>;
        }
        return <></>;
      },
    },
    {
      field: "departmentCode",
      headerName: "Mã bộ phận",
      filter: "agTextColumnFilter",
    },
    {
      field: "departmentName",
      headerName: "Bộ phận",
      width: 350,
      filter: "agTextColumnFilter",
    },
    {
      field: "customerCode",
      headerName: "Mã khách hàng",
      filter: "agTextColumnFilter",
    },
    {
      field: "customerName",
      headerName: "Tên khách hàng",
      filter: "agTextColumnFilter",
    },
    {
      field: "telephoneNumber",
      headerName: "Điện thoại",
      filter: "agTextColumnFilter",
    },
    {
      field: "orgCode",
      headerName: "Mã chi nhánh",
      filter: "agTextColumnFilter",
    },
    {
      field: "orgName",
      headerName: "Chi nhánh",
      filter: "agTextColumnFilter",
    },
    {
      field: "customerName",
      headerName: "Người mua hàng",
      filter: "agTextColumnFilter",
    },
    {
      field: "printInvoiceCheck",
      headerName: "Lấy hóa đơn",
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <>
            {params.data?.printInvoiceCheck
              ? "Lấy hóa đơn"
              : "Không lấy hóa đơn"}{" "}
          </>
        );
      },
    },
    {
      field: "totalAmount",
      headerName: "Doanh thu",
      filter: "agNumberColumnFilter",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <NumberFormatter
            prefix=""
            value={params.value ?? 0}
            thousandSeparator
          />
        );
      },
    },
    {
      field: "amountDiscount",
      headerName: "Tổng tiền chiết khấu theo mã hàng",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      filter: "agNumberColumnFilter",
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <NumberFormatter
            prefix=""
            value={
              (params.data?.amountDiscount ?? 0) +
              (params.data?.discountAdded ?? 0)
            }
            thousandSeparator
          />
        );
      },
    },
    {
      field: "discountAddedTotal",
      headerName: "Tổng tiền chiết khấu theo tổng đơn",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      filter: "agNumberColumnFilter",
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <NumberFormatter
            prefix=""
            value={
              (params.data?.amountDiscount ?? 0) +
              (params.data?.discountAddedTotal ?? 0)
            }
            thousandSeparator
          />
        );
      },
    },
    {
      field: "amountVoucher",
      headerName: "Tổng tiền áp dụng Voucher",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      filter: "agNumberColumnFilter",
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <NumberFormatter
            prefix=""
            value={params.value ?? 0}
            thousandSeparator
          />
        );
      },
    },
    {
      field: "amountTotal",
      headerName: "Tổng tiền trừ chiết khấu",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      filter: "agNumberColumnFilter",
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <NumberFormatter
            prefix=""
            value={params.value ?? 0}
            thousandSeparator
          />
        );
      },
    },
    {
      field: "totalCostPrice",
      filter: "agNumberColumnFilter",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      headerName: "Giá vốn",
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <NumberFormatter
            prefix=""
            value={params.value ?? 0}
            thousandSeparator
          />
        );
      },
    },
    {
      field: "netAmount",
      filter: "agNumberColumnFilter",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      headerName: "LNG",
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <NumberFormatter
            prefix=""
            value={params.value ?? 0}
            thousandSeparator
          />
        );
      },
    },

    {
      field: "profitPercent",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      filter: "agNumberColumnFilter",
      headerName: "Tỷ lệ %",
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <NumberFormatter
            prefix=""
            value={params.value ?? 0}
            thousandSeparator
          />
        );
      },
    },

    {
      field: "taxCode",
      headerName: "Mã số thuế KH",
      filter: "agTextColumnFilter",
    },

    {
      field: "shippingType",
      headerName: "Hình thức giao hàng",
      // filter: "agTextColumnFilter",
    },

    {
      field: "shippingStatus",
      headerName: "Trạng thái giao hàng",
      //      filter: "agTextColumnFilter",
    },

    {
      field: "saleChannelCode",
      headerName: "Mã kênh bán hàng",
      filter: "agTextColumnFilter",
    },

    {
      field: "saleChannelName",
      headerName: "Tên kênh bán hàng",
      filter: "agTextColumnFilter",
    },

    {
      field: "createDate",
      headerName: "Ngày tạo",
      filter: "agDateColumnFilter",
      cellRenderer: (params: CustomCellRendererProps) => {
        if (typeof params.value === "string" || params.value instanceof Date) {
          return <> {params.value && formatDateTransfer(params.value)}</>;
        }
        return <></>;
      },
    },

    {
      field: "lastUpdateDate",
      headerName: "Ngày sửa",
      filter: "agDateColumnFilter",
      cellRenderer: (params: CustomCellRendererProps) => {
        if (typeof params.value === "string" || params.value instanceof Date) {
          return <> {params.value && formatDateTransfer(params.value)}</>;
        }
        return <></>;
      },
    },
    {
      field: "orderTypeLabel",
      headerName: "Loại hóa đơn",
    },
    {
      field: "customerAddress",
      headerName: "Địa chỉ KH",
      filter: "agTextColumnFilter",
    },

    {
      field: "managerName",
      filter: "agTextColumnFilter",
      headerName: "Người duyệt giảm giá",
      cellRenderer: (params: CustomCellRendererProps<ReportOrderInfoModel>) => {
        return (
          <>
            {params.data?.managerCode
              ? "[" + params.data.managerCode + "]"
              : ""}{" "}
            {params.data?.managerName}
          </>
        );
      },
    },
    {
      field: "approveDate",
      filter: "agDateColumnFilter",
      headerName: "Ngày duyệt giảm giá",
      cellRenderer: (params: CustomCellRendererProps) => {
        if (typeof params.value === "string" || params.value instanceof Date) {
          return <> {params.value && formatDateTransfer(params.value)}</>;
        }
        return <></>;
      },
    },
    {
      field: "provinceCode",
      headerName: "Mã thành phố/tỉnh",
      filter: "agTextColumnFilter",
    },
    {
      field: "provinceName",
      headerName: "Thành phố/Tỉnh",
      filter: "agTextColumnFilter",
    },
    {
      field: "districtCode",
      headerName: "Mã quận/huyện",
      filter: "agTextColumnFilter",
    },
    {
      field: "districtName",
      headerName: "Quận/Huyện",
      filter: "agTextColumnFilter",
    },

    {
      field: "communeCode",
      headerName: "Mã phường/xã",
      filter: "agTextColumnFilter",
    },
    {
      field: "communeName",
      headerName: "Phường/Xã",
      filter: "agTextColumnFilter",
    },
  ]);
  //   useEffect(() => {
  //     var columnToolPanel = gridRef.current!.api.getToolPanelInstance("columns")!;
  //     if (gridRef) {
  //       columnToolPanel?.setPivotModeSectionVisible(false);
  //       columnToolPanel?.setRowGroupsSectionVisible(false);
  //       columnToolPanel?.setValuesSectionVisible(false);
  //       columnToolPanel?.setPivotSectionVisible(false);
  //     }
  //   }, []);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      autoHeaderHeight: true,
      wrapHeaderText: true,
      // allowedAggFuncs: ["sum", "min", "max", "avg"],
      filter: true,
      enableValue: true,
      floatingFilter: true,
      floatingFilterComponentParams: {
        showDisabledCheckboxes: true,
      },
      filterParams: filterParamsDefault,
      autoHeight: true,
    };
  }, []);

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    var datasource = getServerSideDatasource();
    // register the datasource with the grid
    params.api!.setGridOption("serverSideDatasource", datasource);
  }, []);
  const statusBar = useMemo(
    () => ({
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent" },
        { statusPanel: "agTotalRowCountComponent" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    }),
    []
  );
  const getContextMenuItems = useCallback(
    (
      params: GetContextMenuItemsParams<ReportOrderInfoModel>
    ): (DefaultMenuItem | MenuItemDef<ReportOrderInfoModel>)[] => {
      const _number = params.node?.data?.orderNumber;
      const result: (DefaultMenuItem | MenuItemDef<ReportOrderInfoModel>)[] = [
        {
          // custom item
          name: "Thanh toán đơn: " + _number,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          cssClasses: ["red", "bold"],
        },
        {
          // custom item
          name: "Chỉnh sửa đơn: " + _number,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          //  icon: iconElement,
          cssClasses: ["red", "bold"],
        },
        {
          // custom item
          name: "Hủy đơn: " + _number,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          cssClasses: ["red", "bold"],
        },
        "cut",
        "copy",
        "copyWithHeaders",
        "copyWithGroupHeaders",
        "paste",
        "separator",
        "chartRange",

        "export",
      ];
      return result;
    },
    [window]
  );
  const pinnedTopRowData = useMemo<any[]>(() => {
    return [
      {
        id: "1",
        quantity: 0,
      },
    ];
  }, []);
  const pinnedBottomRowData = useMemo<any[]>(() => {
    return [
      {
        id: "1",
        quantity: 0,
      },
    ];
  }, []);
  return (
    <div style={containerStyle}>
      <_breadcrumb></_breadcrumb>
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact<ReportOrderInfoModel>
          ref={gridRef}
          loading={loading}
          suppressNoRowsOverlay
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowModelType={"serverSide"}
          pagination={true}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          cacheBlockSize={paginationPageSize}
          maxBlocksInCache={3}
          onGridReady={onGridReady}
          localeText={AG_GRID_LOCALE_VN}
          sideBar={true}
          suppressAggFuncInHeader={true}
          blockLoadDebounceMillis={200}
          suppressServerSideFullWidthLoadingRow={false}
          maxConcurrentDatasourceRequests={1}
          animateRows={true}
          rowBuffer={paginationPageSize}
          purgeClosedRowNodes={true}
          pivotPanelShow={"never"}
          statusBar={statusBar}
          enableRangeSelection
          enableCharts
          allowContextMenuWithControlKey={true}
          getContextMenuItems={getContextMenuItems}
          suppressColumnVirtualisation
          paginationAutoPageSize={true}
          maintainColumnOrder={true}
          rowGroupPanelShow="never"
        />
      </div>
    </div>
  );
};
