import { Badge, ComboboxItem, Text } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { DeliveryListModel } from "../../../model/TblDeliveryAssignment";
import moment from "moment";
import {
  getDeliveryStatusSelect,
  getTblLocationSelect,
} from "../../../service/getSelectApi";
import { green } from "../../../const/variables";

const DeliveryList = ({ dataDetail, isSetHeight }: DeliveryListProps) => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<DeliveryListModel[]>([]);
  const [dataTemp, setDataTemp] = useState<DeliveryListModel[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);

  // Reference data from another table
  const [dataTblDeliveryStatusSelect, setDataTblDeliveryStatusSelect] =
    useState<ComboboxItem[]>([]);
  const [dataTblCustomer, setDataTblCustomer] = useState<ComboboxItem[]>([]);
  const [dataTblLocation, setDataTblLocation] = useState<ComboboxItem[]>([]);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "sourceCode",
        header: "Số chứng từ gốc",
        Cell: ({ renderedCellValue }: any) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "customerName",
        header: "Tên khách hàng",
        enableColumnFilter: false,
      },
      {
        accessorKey: "telephoneNumber",
        header: "SĐT",
        enableColumnFilter: false,
      },
      {
        accessorKey: "toAddress",
        header: "Địa điểm giao hàng",
        size: 400,
      },
      {
        accessorKey: "transactionContent",
        header: "Nội dung giao",
      },
      {
        accessorKey: "deliveryStatusName",
        header: "Trạng thái đơn hàng",
        Cell: ({ row }) => (
          <Badge color={getColorStatus(row.original.deliveryStatusName)}>
            {row.original.deliveryStatusName}
          </Badge>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute8",
        header: "Tiền hàng",
        Cell: ({ renderedCellValue }) => (
          <Text
            fw={500}
            size="12.5px"
          >
            {Number(renderedCellValue)?.toLocaleString()} VNĐ
          </Text>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "paymentAmount",
        header: "Tiền phải thu",
        Cell: ({ renderedCellValue }) => (
          <Text
            fw={500}
            size="12.5px"
          >
            {Number(renderedCellValue)?.toLocaleString()} VNĐ
          </Text>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "description",
        header: "Ghi chú đơn hàng",
        enableColumnFilter: false,
      },
      {
        accessorKey: "fromAddress",
        header: "Địa chỉ chi nhánh",
        enableColumnFilter: false,
        size: 300,
      },
      {
        accessorKey: "debt",
        header: "Công nợ",
        enableColumnFilter: false,
      },
      {
        accessorKey: "transactionNumber",
        header: "Số giao dịch",
        Cell: ({ renderedCellValue }: any) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
    ],
    [dataTblDeliveryStatusSelect, dataTblCustomer, dataTblLocation]
  );

  function getColorStatus(value: string) {
    switch (value) {
      case "Đã phân công":
        return "lime";
      case "Lập đơn giao hàng":
        return "green";
      case "Đang giao hàng":
        return "teal";
      case "Đơn hàng giao chưa thành công do Khách hàng":
        return "blue";
      case "Đơn hàng giao chưa thành công do Hacom":
        return "indigo";
      case "Hàng đã giao":
        return "violet";
      case "Hủy phân công":
        return "red";
      case "Đã đến nơi":
        return "#2bdd66";
      case null:
        return "rgba(0, 0, 0, 0)";
    }
  }

  const formatDateString = (isoString: string) => {
    const date = new Date(isoString);
    const adjustedDate = date.setHours(date.getHours() + 7);
    return moment(new Date(adjustedDate)).format("DD-MM-YYYY");
  };

  const dataDeliveryStatusSelect = async () => {
    try {
      const getData = await getDeliveryStatusSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDeliveryStatusSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const dataLocation = async () => {
    try {
      const getData = await getTblLocationSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblLocation(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    Promise.all([dataDeliveryStatusSelect(), dataLocation()]);
  }, []);

  useEffect(() => {
    if (isSetHeight) {
      const headerHeight = headerRef.current?.offsetHeight || 0;
      const handleResize = () => {
        // 160 là chiều cao của phần phân trang
        // headerHeight là chiều cao của phần header
        setHeight(window.innerHeight - (490 + headerHeight));
      };

      handleResize(); // Set initial height
      window.addEventListener("resize", handleResize); // Update height on window resize

      return () => {
        window.removeEventListener("resize", handleResize); // Clean up event listener
      };
    }
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data: dataDetail?.filter((element: any) => element.employee !== null),
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["sourceCode"],
      },
      columnVisibility: { id: false, "mrt-row-select": false },
      density: "xs",
      sorting: sorting,
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: isSetHeight ? height : 500,
        minHeight: isSetHeight ? height : 100,
      },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableTopToolbar: false,
    rowCount,
    enablePagination: false,
    enableBottomToolbar: false,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

type DeliveryListProps = {
  dataDetail: any;
  isSetHeight: any;
};

export default DeliveryList;
