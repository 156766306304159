import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Divider,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  ScrollArea,
  Table,
  TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowBack, IconReceiptRefund } from "@tabler/icons-react";
import cx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "../../Styles/TableScrollArea.module.css";
import { repositoryDelivery } from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { getValueById } from "../../_base/helper/FunctionHelper";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { MessageResponse } from "../../model/MessageResponse";
import { TblTestingTechniqueHeaderGetList } from "../../model/TblTestingTechniqueHeader";
import { getTechnicalTestingDifficultySelect } from "../../service/getSelectApi";
import ListProductDetail from "./ListProductDetail";

const ModalDetailTestingTechnique = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location?.state && location.state.id;
  const dataStatusOption = location?.state && location.state.dataStatusOption;
  const dataSourceStatus = location?.state && location.state.dataSourceStatus;
  const [visible, { close, open }] = useDisclosure(false);
  const [dataTestingTechnique, setDataTestingTechnique] = useState<any>();
  //trạng thái test cả phiếu
  const [datatestDifficultys, setdatatestDifficultys] = useState<any>(null);
  const [dataDeliverPriority] = useState<ComboboxItem[]>([
    { label: "Cao", value: "1" },
    { label: "Thấp", value: "2" },
  ]);
  const [
    dataTechnicalTestingDifficultySelect,
    setDataTechnicalTestingDifficultySelect,
  ] = useState<ComboboxItem[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const initialScreenHeightRef = useRef<number>(window.innerHeight);
  const boxTopRef = useRef<number>(0);
  const [bottomBoxHeight, setBottomBoxHeight] = useState(0);
  const fetchDataDetail = async () => {
    const url = `/api/v1/TblTestingTechniqueHeader/get-detail-v2?id=${id}`;
    open();
    try {
      const response = await repositoryDelivery.get<
        MessageResponse<TblTestingTechniqueHeaderGetList>
      >(url);
      if (response?.success && response?.data) {
        setDataTestingTechnique(response.data);
        setdatatestDifficultys(response.data?.testDifficulty);

        Promise.all([getDataTechnicalTestingDifficultySelect()]);
      } else {
        setDataTestingTechnique(null);
        navigate("/testing/testing-technique");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataTestingTechnique(null);
    }
    close();
  };
  const ViewtechnicalRequired = (value: number) => {
    let result = "";
    switch (value) {
      case 0:
        result = "Không";
        break;
      case 1:
        result = "Lắp máy(mới)";
        break;
      case 2:
        result = "Kiểm tra(mới)";
        break;
      case 3:
        result = "Lắp ráp(mới)+kiểm tra(mới)";
        break;
      default:
        result = "Không yêu cầu";
        break;
    }
    return result;
  };
  const updateStatus = async (status: string) => {
    try {
      const dataSubmit = {
        ids: [id],
        status: status,
      };
      const res = await repositoryDelivery.post<MessageResponse<boolean>>(
        "/api/v1/TblTestingTechniqueHeader/update-status-header",
        dataSubmit
      );
      if (res)
        if (res.success) {
          NotificationExtension.Success("Tiếp nhận thành công !");
          setTimeout(() => {
            navigate("/testing/testing-technique");
          }, 1000);
        }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const getDataTechnicalTestingDifficultySelect = async () => {
    const res = await getTechnicalTestingDifficultySelect();
    setDataTechnicalTestingDifficultySelect(
      res
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };
  useEffect(() => {
    if (id) fetchDataDetail();
  }, [id]);

  useEffect(() => {
    const updateHeight = () => {
      if (boxTopRef.current) {
        const boxTopHeight = boxTopRef.current;
        const totalHeight = window.innerHeight;
        const calculatedBottomHeight = totalHeight - boxTopHeight;
        setBottomBoxHeight(calculatedBottomHeight);
      }
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, [dataTestingTechnique?.tblTestingTechniqueAssignmentModels]);
  console.log("dataTestingTechnique", dataTestingTechnique);
  return (
    <Box>
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Box
        ref={(el) => {
          if (el) boxTopRef.current = el.offsetHeight;
        }}
      >
        {dataTestingTechnique?.tblTestingTechniqueAssignmentModels?.length >
        0 ? (
          <Grid>
            <Grid.Col span={{ base: 12 }} mb={0} pb={0}>
              <Group justify="end" style={{ backgroundColor: "white" }}>
                {dataTestingTechnique?.testingStatus === "PHAN_CONG" && (
                  <>
                    <Button
                      leftSection={<IconReceiptRefund size={14} />}
                      variant="outline"
                      color="blue"
                      onClick={() => {
                        updateStatus("DANG_KIEM_TRA");
                      }}
                    >
                      Tiếp nhận
                    </Button>
                  </>
                )}
                <Button
                  type="button"
                  color="red"
                  loading={visible}
                  onClick={() => {
                    navigate("/testing/testing-technique");
                  }}
                  leftSection={
                    !visible ? <IconArrowBack size={18} /> : undefined
                  }
                >
                  Quay lại
                </Button>
              </Group>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 5 }} mt={0} pt={0} mb={10}>
              <Fieldset legend={<Badge>Thông tin phân công</Badge>}>
                <Grid mt={"10px"}>
                  <Grid.Col span={{ base: 12, md: 12, lg: 6 }} mb={0}>
                    <TextInput
                      readOnly
                      label="Mã kiểm tra"
                      value={dataTestingTechnique?.code}
                    ></TextInput>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                    <TextInput
                      readOnly
                      label="Ngày phân công"
                      value={formatDateTransfer(
                        dataTestingTechnique?.processingDate
                      )}
                    ></TextInput>
                  </Grid.Col>

                  <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                    <TextInput
                      readOnly
                      label="Yêu cầu dịch vụ"
                      // value={ViewtechnicalRequired(
                      //   Number(dataTestingTechnique.technicalRequired)
                      // )}
                      value={
                        location?.state.typeSourceType == "DON_HANG"
                          ? ViewtechnicalRequired(
                              Number(dataTestingTechnique.technicalRequired)
                            )
                          : "Kiểm tra sản phẩm BH NCC"
                      }
                    ></TextInput>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                    <TextInput
                      readOnly
                      label="Nội dung phân công"
                      value={dataTestingTechnique.note}
                    ></TextInput>
                  </Grid.Col>
                </Grid>
              </Fieldset>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 7 }} mt={0} pt={0} mb={10}>
              <Fieldset
                legend={<Badge color="orange">Thông tin kỹ thuật</Badge>}
                h={"calc(100% - 17px)"}
              >
                <ScrollArea
                  h={150}
                  onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                  scrollbars="y"
                  mt={7.5}
                >
                  <Table
                    striped
                    highlightOnHover
                    withTableBorder
                    withColumnBorders
                  >
                    <Table.Thead
                      className={cx(classes.header, {
                        [classes.scrolled]: scrolled,
                      })}
                    >
                      <Table.Tr>
                        {/* <Table.Th>Người chịu trách nhiệm</Table.Th> */}
                        <Table.Th>Mã nhân viên</Table.Th>
                        <Table.Th>Tên nhân viên</Table.Th>
                        <Table.Th>Chức danh</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {dataTestingTechnique?.tblTestingTechniqueAssignmentModels &&
                        dataTestingTechnique
                          ?.tblTestingTechniqueAssignmentModels.length > 0 &&
                        dataTestingTechnique?.tblTestingTechniqueAssignmentModels.map(
                          (emp: any, index: any) => (
                            <Table.Tr key={emp.id}>
                              {/* <Table.Td>
                                <Checkbox
                                  checked={
                                    dataTestingTechnique?.responsibleEmployeeId ===
                                    emp?.emloyeeId
                                  }
                                  readOnly
                                />
                              </Table.Td> */}
                              <Table.Td>{emp.emloyeeCode}</Table.Td>
                              <Table.Td>{emp.emloyeeName}</Table.Td>
                              <Table.Td>{emp.emloyeeRole}</Table.Td>
                            </Table.Tr>
                          )
                        )}
                    </Table.Tbody>
                  </Table>
                </ScrollArea>
              </Fieldset>
            </Grid.Col>
          </Grid>
        ) : (
          <Flex
            align="center"
            w="100%"
            justify="center"
            p="25px 0px"
            pos="relative"
          >
            <Badge fw={700} size="lg" color="orange">
              Chưa phân công KTKT
            </Badge>
            <Flex gap={"md"} pos={"absolute"} right={0}>
              <Button
                leftSection={<IconReceiptRefund size={14} />}
                variant="outline"
                color="blue"
                onClick={() => {
                  updateStatus("DANG_KIEM_TRA");
                }}
              >
                Tiếp nhận
              </Button>
              <Button
                type="button"
                color="red"
                loading={visible}
                onClick={() => {
                  navigate("/testing/testing-technique");
                }}
                leftSection={!visible ? <IconArrowBack size={18} /> : undefined}
              >
                Quay lại
              </Button>
            </Flex>
          </Flex>
        )}

        <Divider
          label={
            <Badge color="green" size="md">
              {location?.state.typeSourceType == "DON_HANG"
                ? " Thông tin chi tiết đơn hàng"
                : location?.state.typeSourceType == "CHUYEN_DOI_DU_LIEU"
                ? "Thông tin chuyển đổi dữ liệu"
                : location?.state.typeSourceType == "NHAP_KHO_BH"
                ? "Thông tin nhập kho bảo hành"
                : location?.state.typeSourceType == "NHAP_KHO_BH_NCC"
                ? "Thông tin nhập kho bảo hành NCC"
                : ""}
            </Badge>
          }
          labelPosition="center"
        />

        <Grid mt={10} mb={10}>
          {location?.state.typeSourceType == "DON_HANG" ? (
            <>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Mã đơn hàng"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.sourceCode || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Chi nhánh"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.branchName || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Kho"
                  size="xs"
                  readOnly
                  value={`${dataTestingTechnique?.subInvName ?? ""}` || ""}
                />
              </Grid.Col>{" "}
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Bộ phận giao hàng"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.actribute4 || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Tên khách hàng"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.customerName || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Số điện thoại"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.customerTelephone || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Trạng thái kiểm tra"
                  size="xs"
                  readOnly
                  value={getValueById(
                    dataTestingTechnique?.testingStatus?.toString(),
                    dataStatusOption,
                    "label"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Ghi chú"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.description || ""}
                />
              </Grid.Col>
            </>
          ) : location?.state.typeSourceType == "CHUYEN_DOI_DU_LIEU" ? (
            <>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Chi nhánh"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.branchName || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Kho"
                  size="xs"
                  readOnly
                  // value={dataTestingTechnique?.subInvName || ""}
                  value={`${dataTestingTechnique?.subInvName ?? ""}` || ""}
                />
              </Grid.Col>
              {/* <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                <TextInput
                  label="Vị trí trong kho"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.invPositionName || ""}
                />
              </Grid.Col> */}
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Trạng thái kiểm tra"
                  size="xs"
                  readOnly
                  value={getValueById(
                    dataTestingTechnique?.testingStatus?.toString(),
                    dataStatusOption,
                    "label"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <TextInput
                  label="Ghi chú"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.description || ""}
                />
              </Grid.Col>
            </>
          ) : (
            <>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Mã nhập BH NCC"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.sourceCode || ""}
                />
              </Grid.Col>

              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Chi nhánh"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.branchName || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Kho"
                  size="xs"
                  readOnly
                  // value={dataTestingTechnique?.subInvName || ""}
                  value={`${dataTestingTechnique?.subInvName ?? ""}` || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Bộ phận giao hàng"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.actribute4 || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Trạng thái kiểm tra"
                  size="xs"
                  readOnly
                  value={getValueById(
                    dataTestingTechnique?.testingStatus?.toString(),
                    dataStatusOption,
                    "label"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Ghi chú"
                  size="xs"
                  readOnly
                  value={dataTestingTechnique?.description || ""}
                />
              </Grid.Col>
            </>
          )}
        </Grid>
      </Box>

      <ListProductDetail
        typeSourceType={location?.state.typeSourceType}
        datatestDifficultys={datatestDifficultys}
        dataDetail={dataTestingTechnique?.tblTestingTechniqueDetailModels}
        isDetail={true}
        height={bottomBoxHeight - 140}
        testingStatus={dataTestingTechnique?.testingStatus}
      />
    </Box>
  );
};

export default ModalDetailTestingTechnique;
