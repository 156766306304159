import {
  ActionIcon,
  Button,
  Flex,
  Group,
  NumberInput,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { _localization } from "../../../../../config/location";
import { TblInventoryChildSerialOnhandCommand } from "../../../../../model/TblInventorySerialOnHand";
import { IconAlertSquareRounded, IconPlus, IconTrash } from "@tabler/icons-react";
import { useDebouncedValue } from "@mantine/hooks";
import {
  SerialOnHand,
  TblInventoryChildSerialOnhandCommands,
} from "./warehouseInterFace";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { notifications } from "@mantine/notifications";

interface Props {
  WarehouseInWardSerialChildren: SerialOnHand;
}

export const WarehouseInWardSerialChildren: React.FC<Props> = ({
  WarehouseInWardSerialChildren,
}) => {
  const [data, setData] = useState<TblInventoryChildSerialOnhandCommands[]>([]);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const handleAddRow = () => {
    const newRow = {
      parentSerial: WarehouseInWardSerialChildren?.serial,
      serial: "",
      itemCode: "",
      itemName: "",
      uomCode: "",
      quantity: null,
    };
    setData((prev) => [...prev, newRow]);
  };

  console.log(data, "data");

  const handleEditRow = (index: number, field: string, value: any) => {
    setData((prev) => {
      const updatedData = [...prev];
      updatedData[index] = { ...updatedData[index], [field]: value };
      return updatedData;
    });
  };

  const columns = React.useMemo<
    MRT_ColumnDef<TblInventoryChildSerialOnhandCommand>[]
  >(
    () => [
      {
        accessorKey: "STT",
        enableColumnActions: false,
        header: "STT",
        size: 80,
        Cell: ({ row }) => <>{row.index + 1}</>,
      },
      {
        accessorKey: "parentSerial",
        enableColumnActions: false,
        header: "Serial cha",
        size: 150,
      },
      {
        accessorKey: "serial",
        enableColumnActions: false,
        header: "Serial con",
        size: 150,
        Cell: ({ cell, row, renderedCellValue }) => {
          const [value, setValue] = useState<any>(renderedCellValue || "");
          const [debounced] = useDebouncedValue(value, 500);
      
          useEffect(() => {
            if (value.trim() !== "") { 
              const checkDataSerial = data?.some((e) => e.serial === value);
              if (checkDataSerial) {
                notifications.show({
                  title: <Title order={4}>Thông báo!</Title>,
                  message: <Text fw={600} c="#000" size="sm">{`Trùng mã Serial "${value}"`}</Text>,
                  color: "orange",
                  icon: <IconAlertSquareRounded size={20} />,
                  autoClose: 4000,
                });
                setValue("");
              }
            }
          }, [value]);
      
          useEffect(() => {
            handleEditRow(row.index, "serial", debounced);
          }, [debounced]);
      
          useEffect(() => {
            setValue(renderedCellValue);
          }, [renderedCellValue]);
      
          return (
            <TextInput
              key={row.original.id}
              flex={1}
              fw={600}
              value={value}
              style={{ whiteSpace: "normal" }}
              placeholder="Vui lòng nhập"
              onChange={(e) => setValue(e.target.value)}
              // error={errors[row.index]?.originalDocuments}
            />
          );
        },
      },
      
      {
        accessorKey: "itemCode",
        enableColumnActions: false,
        header: "Mã sản phẩm",
        size: 250,
        Cell: ({ cell, row, renderedCellValue }) => {
          const [value, setValue] = useState<any>(renderedCellValue || "");
          const [debounced] = useDebouncedValue(value, 500);

          useEffect(() => {
            handleEditRow(row.index, "itemCode", debounced);
          }, [debounced]);

          useEffect(() => {
            setValue(renderedCellValue);
          }, [renderedCellValue]);

          return (
            <TextInput
              key={row.original.id}
              flex={1}
              fw={600}
              value={value}
              style={{ whiteSpace: "normal" }}
              placeholder="Vui lòng nhập"
              onChange={(e) => setValue(e.target.value)}
              // error={errors[row.index]?.originalDocuments}
            />
          );
        },
      },
      {
        accessorKey: "itemName",
        enableColumnActions: false,
        header: "Tên sản phẩm",
        size: 350,
        Cell: ({ cell, row, renderedCellValue }) => {
          const [value, setValue] = useState<any>(renderedCellValue || "");
          const [debounced] = useDebouncedValue(value, 500);

          useEffect(() => {
            handleEditRow(row.index, "itemName", debounced);
          }, [debounced]);

          useEffect(() => {
            setValue(renderedCellValue);
          }, [renderedCellValue]);

          return (
            <TextInput
              key={row.original.id}
              flex={1}
              fw={600}
              value={value}
              style={{ whiteSpace: "normal" }}
              placeholder="Vui lòng nhập"
              onChange={(e) => setValue(e.target.value)}
              // error={errors[row.index]?.originalDocuments}
            />
          );
        },
      },
      {
        accessorKey: "uomCode",
        enableColumnActions: false,
        header: "Đơn vị tính",

        Cell: ({ cell, row, renderedCellValue }) => {
          const [value, setValue] = useState<any>(renderedCellValue || "");
          const [debounced] = useDebouncedValue(value, 500);

          useEffect(() => {
            handleEditRow(row.index, "uomCode", debounced);
          }, [debounced]);

          useEffect(() => {
            setValue(renderedCellValue);
          }, [renderedCellValue]);

          return (
            <TextInput
              key={row.original.id}
              flex={1}
              fw={600}
              value={value}
              style={{ whiteSpace: "normal" }}
              placeholder="Vui lòng nhập"
              onChange={(e) => setValue(e.target.value)}
              // error={errors[row.index]?.originalDocuments}
            />
          );
        },
        size: 150,
      },
      {
        accessorKey: "quantity",
        enableColumnActions: false,
        header: "Số lượng",
        size: 150,
        Cell: ({ cell, row, renderedCellValue }) => {
          const [value, setValue] = useState<any>(renderedCellValue || "");
          const [debounced] = useDebouncedValue(value, 500);

          useEffect(() => {
            handleEditRow(row.index, "quantity", debounced);
          }, [debounced]);

          useEffect(() => {
            setValue(renderedCellValue);
          }, [renderedCellValue]);

          return (
            <NumberInput
              hideControls
              key={row.original.id}
              flex={1}
              min={0}
              fw={600}
              value={value}
              style={{ whiteSpace: "normal" }}
              placeholder="Vui lòng nhập"
              onChange={(e) => setValue(e)}
              // error={errors[row.index]?.originalDocuments}
            />
          );
        },
      },
      {
        accessorKey: "actions",
        enableColumnActions: false,
        header: "Thao tác",
        size: 120,
        Cell: ({ cell, row, renderedCellValue }) => {
          return (
            <Tooltip label="Xóa">
              <ActionIcon color="red" variant="light" aria-label="Settings">
                <IconTrash
                  style={{ width: "70%", height: "70%" }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Tooltip>
          );
        },
      },
    ],
    [data]
  );

  useEffect(() => {
    if (WarehouseInWardSerialChildren?.tblInventoryChildSerialOnhandCommands)
      setData(
        WarehouseInWardSerialChildren?.tblInventoryChildSerialOnhandCommands
      );
  }, [WarehouseInWardSerialChildren]);

  const table = useMantineReactTable<TblInventoryChildSerialOnhandCommand>({
    columns,
    data: data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: true,
    enableFullScreenToggle: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Button
          variant="filled"
          color="#228be6"
          leftSection={<IconPlus size={14} />}
          onClick={() => {
            handleAddRow();
          }}
        >
          Thêm
        </Button>
      </>
    ),
    enableGlobalFilterModes: true,
    positionGlobalFilter: "left",
    localization: _localization,
    paginationDisplayMode: "pages",
    mantinePaginationProps: {
      withEdges: false,
      showRowsPerPage: false,
    },
    getRowId: (row) => row.id?.toString(),
    initialState: {
      columnPinning: {
        left: ["STT", "id"],
        right: ["actions"],
      },
      columnVisibility: { id: true },
      density: "xs",
      showGlobalFilter: true,
    },
    mantineSearchTextInputProps: {
      placeholder: `Vui lòng tìm kiếm`,
      variant: "filled",
    },
    enablePagination: true,
    enableRowVirtualization: true,
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onPaginationChange: setPagination,
    state: {
      pagination,
      isLoading,
    },
    rowCount: data.length,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "8px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: isLoading
      ? { color: "blue", children: "Đang tải dữ liệu..." }
      : undefined,
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (300 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  const paginate = (allData: any[]) => {
    const start = pagination.pageIndex * pagination.pageSize;
    const end = start + pagination.pageSize;
    return allData.slice(start, end);
  };

  // useEffect(() => {
  //   if (data.length > 0) {
  //     setData(paginate(data)); //
  //   }
  // }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        ></Flex>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
