import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Title,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowBack, IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblWarrantyItem } from "../../../model/TblWarrantyItem";
import { TblWarrantyServiceRequestForm } from "../../../model/TblWarrantyServiceRequestForm";
import {
  getBranchSelectMdm,
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
  getStreetSelect,
  getTblDmProcessingMethodSelect,
  getTblDmSaleChanelSelect,
} from "../../../service/getSelectApi";
import ItemList from "./ItemList";
import WarrantyItem from "./WarrantyItemList";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import CustMeeting from "./CustMeeting";

const CreateDataView = ({ onClose, onSuccess }: CreateDataViewProps) => {
  const entity = {
    id: 0,
    custId: null,
    deliveryId: 0,
    branchId: null,
    inventoryId: null,
    requestStatus: null,
    deliveryDept: null,
    deliveryDate: null,
    custMeeting: null,
    meetingDate: null,
    custPhone: null,
    provinceId: null,
    districtId: null,
    communeId: null,
    streetId: null,
    additionalAddress: null,
    meetingAddress: null,
    processingMethod: null,
    processingMethodName: null,
    employeeIncharge: null,
    requestNum: "",
    note: null,
    vendorId: null,
    message: null,
    address: null,
    priorityId: null,
    warrantyStatus: null,
    locationId: null,
    channel: null,
    channelName: null,
    employeeSupport: null,
    content: null,
    noteAssignRequest: null,
    receiveBranch: null,
    assignDate: null,
    empAssign: null,
    isReceive: false,
    customerGroup: null,
    attribute10: null,
    createDate: new Date()?.toISOString(),
    tblWarrantyServiceRequestFormDetailCommands: [],
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataWarrantyItem, setDataWarrantyItem] = useState<TblWarrantyItem[]>(
    []
  );
  const [dataWarrantyItemSubmit, setDataWarrantyItemSubmit] = useState<any[]>(
    []
  );
  const [dataTblProcessingMethod, setDataTblProcessingMethod] = useState<
    ComboboxItem[]
  >([]);
  const [dataWarrantyCustomerGroup, setDataWarrantyCustomerGroup] =
    useState<any>([]);
  const [dataTblSaleChannel, setDataTblSaleChannel] = useState<ComboboxItem[]>(
    []
  );

  const [provinceId, setProvinceId] = useState<string | null>(null);
  const [districtId, setDistrictId] = useState<string | null>(null);
  const [communeId, setCommuneId] = useState<string | null>(null);
  const [dataMeetingAddress, setDataMeetingAddress] = useState<any>({
    province: null,
    district: null,
    commune: null,
    street: null,
  });
  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataDistrictSelect, setDataDistrictSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataCommuneSelect, setDataCommuneSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataStreetSelect, setDataStreetSelect] = useState<ComboboxItem[]>([]);

  const form = useForm<TblWarrantyServiceRequestForm>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      meetingDate: new Date(
        new Date(values.meetingDate ?? "").getTime() + 7 * 60 * 60 * 1000
      ).toISOString(),
      createDate: new Date(
        new Date(values.createDate ?? "").getTime() + 7 * 60 * 60 * 1000
      ).toISOString(),
    }),

    validate: {
      custMeeting(value, values, path) {
        if (!value) return "Vui lòng nhập người hẹn gặp!";
      },
      channel(value, values, path) {
        if (!value) return "Vui lòng chọn kênh tiếp nhận!";
      },
      customerGroup(value, values, path) {
        if (!value) return "Vui lòng chọn nhóm khách hàng!";
      },
      provinceId(value, values, path) {
        if (!value) return "Vui lòng chọn tỉnh/thành phố!";
      },
      districtId(value, values, path) {
        if (!value) return "Vui lòng chọn quận/huyện!";
      },
      communeId(value, values, path) {
        if (!value) return "Vui lòng chọn phường/xã!";
      },
      processingMethod(value, values, path) {
        if (!value) return "Vui lòng chọn hình thức xử lý!";
      },
      custPhone: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập số điện thoại khách hàng hẹn gặp!";
        }
        if (!/^\d{8,11}$/.test(value ?? "") && value) {
          return "Số điện thoại phải có từ 8 đến 11 chữ số!";
        }
      },
      meetingDate(value) {
        if (!value) return "Vui lòng chọn ngày hẹn gặp!";
      },
    },
  });

  const handleOnChange = (value: any, key: string) => {
    if (value !== "Invalid date")
      setDataMeetingAddress((prevData: any) => ({ ...prevData, [key]: value }));
    else {
      setDataMeetingAddress((prevData: any) => ({ ...prevData, [key]: "" }));
    }
  };

  const processingMethodSelect = async () => {
    try {
      const getData = await getTblDmProcessingMethodSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblProcessingMethod(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const saleChannelSelect = async () => {
    try {
      const getData = await getTblDmSaleChanelSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblSaleChannel(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const getDataWarrantyCustomerGroup = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblWarrantyCustomerGroup/get-all"
    );

    if (res && res?.success) {
      const getData = res.data;
      setDataWarrantyCustomerGroup(getData);
    }
  };

  const modalCustMeeting = () => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thông tin khách hàng hẹn gặp</Title>
        </>
      ),
      size: "auto",
      children: (
        <CustMeeting
          setDataMeetingAddress={setDataMeetingAddress}
          infoCust={form.setValues}
          setProvinceId={setProvinceId}
          setDistrictId={setDistrictId}
          setCommuneId={setCommuneId}
          view={"CREATE"}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleSearchByPhone = (type: string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Danh sách hóa đơn</Title>
        </>
      ),
      children: (
        <Box mt={"18px"}>
          <WarrantyItem
            custId={form.getValues().custId ?? ""}
            selectWarrantyItem={setDataWarrantyItem}
            setFormValues={form.setValues}
            type={type}
          />
        </Box>
      ),
      size: "auto",
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<
      MessageResponse<TblWarrantyServiceRequestForm>
    >("/api/v1/TblWarrantyServiceRequestForm/create?prefix=PYCXL");
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        await Promise.all([
          processingMethodSelect(),
          saleChannelSelect(),
          getDataProvinceSelect(),
          getDataWarrantyCustomerGroup(),
        ]);
        form.setValues({
          ...dataApi,
          meetingDate: new Date()?.toISOString(),
          createDate: new Date()?.toISOString(),
        });
      }
      close();
    }
  };

  const handleCreateTblWarrantyServiceRequestForm = async (
    dataSubmit: TblWarrantyServiceRequestForm
  ) => {
    open();

    if (
      dataWarrantyItemSubmit.some(
        (item) => item.serviceType === null || item.itemCode === null
      )
    ) {
      NotificationExtension.Fails(
        "Vui lòng nhập đầy đủ thông tin cho từng sản phẩm !"
      );
      close();
      return;
    }

    if (dataWarrantyItemSubmit.length > 0) {
      const dataItemSubmit = dataWarrantyItemSubmit.map((item) => ({
        id: 0,
        requestFormId: 0,
        serial: item.serial ?? null,
        parentSerial: item.parentSerial ?? null,
        orderId: item.orderId ?? null,
        orderCode: item.orderCode ?? null,
        handleDate: null,
        conclusionError: null,
        resolution: null,
        technicalFeedback: null,
        scheduleTime: null,
        brandId: null,
        empBrand: null,
        phoneBrand: null,
        addressBrand: null,
        noteBrand: null,
        po: null,
        itemId: item.itemId ?? 0,
        empCall: null,
        dateCall: null,
        deadline: null,
        buyingDate: item.buyingDate ?? null,
        warrantyTerm: item.warrantyTerm ?? null,
        serviceType: item.serviceType ?? null,
        entryDate: item.entryDate ?? null,
        attribute1: item.attribute1 ?? null,
        attribute5: item.attribute5 ?? null,
        attribute3: item.attribute3 ?? null,
        attribute4: item.attribute4 ?? null,
        customerOrderId: item.customerOrderId ?? null,
      }));
      dataSubmit = {
        ...dataSubmit,
        tblWarrantyServiceRequestFormDetailCommands: dataItemSubmit,
      };
    } else {
      NotificationExtension.Warn(
        "Vui lòng chọn sản phẩm bảo hành từ hóa đơn mua hàng."
      );
      close();
      return;
    }

    const dataApi = await repositoryMdm.post<
      MessageResponse<TblWarrantyServiceRequestForm>
    >("/api/v1/TblWarrantyServiceRequestForm/create", dataSubmit);
    if (dataApi?.success) {
      NotificationExtension.Success("Lập phiếu tiếp nhận yêu cầu thành công !");
      setTimeout(() => {
        onSuccess((prev: any) => !prev);
        onClose("");
      }, 1500);
    }
    close();
  };

  const getDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataProvinceSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataDistrictSelect = async () => {
    if (provinceId !== null) {
      const getData = await getDistrictSelect(provinceId ?? "");
      setDataDistrictSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataCommuneSelect = async () => {
    if (districtId !== null) {
      const getData = await getCommuneSelect(districtId ?? "");
      setDataCommuneSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataStreetSelect = async () => {
    if (communeId !== null) {
      const getData = await getStreetSelect(communeId ?? "");
      setDataStreetSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  useEffect(() => {
    callApiGetData();
  }, []);

  useEffect(() => {
    if (provinceId !== null) {
      getDataDistrictSelect();
    }
  }, [provinceId]);

  useEffect(() => {
    if (districtId !== null) {
      getDataCommuneSelect();
    }
  }, [districtId]);

  useEffect(() => {
    if (communeId !== null) {
      getDataStreetSelect();
    }
  }, [communeId]);

  useEffect(() => {
    if (dataMeetingAddress.province !== null) {
      const toAddress = `${getValueById(
        dataMeetingAddress.street?.toString() ?? "",
        dataStreetSelect,
        "label"
      )} - ${getValueById(
        dataMeetingAddress.commune?.toString() ?? "",
        dataCommuneSelect,
        "label"
      )} - ${getValueById(
        dataMeetingAddress.district?.toString() ?? "",
        dataDistrictSelect,
        "label"
      )} - ${getValueById(
        dataMeetingAddress.province?.toString(),
        dataProvinceSelect,
        "label"
      )}`;
      form.setValues((prev) => ({
        ...prev,
        meetingAddress: toAddress
          .replace(/\s*-\s*-\s*/g, " - ")
          .replace(/^\s*-\s*/, ""),
      }));
    }
    form.setValues((prev) => ({
      ...prev,
      branchId: JSON.parse(localStorage.getItem("userLoginHeader") || "{}")
        ?.branchId,
      // branchId: 491,
      isReceive: false,
      streetId: Number(dataMeetingAddress.street),
      communeId: Number(dataMeetingAddress.commune),
      districtId: Number(dataMeetingAddress.district),
      provinceId: Number(dataMeetingAddress.province),
    }));
  }, [
    dataMeetingAddress,
    dataProvinceSelect,
    dataDistrictSelect,
    dataCommuneSelect,
    dataStreetSelect,
  ]);

  useEffect(() => {
    const seenSerials = new Set();
    const uniqueData = dataWarrantyItem.filter((item) => {
      if (seenSerials.has(item.serial)) {
        return false;
      }
      seenSerials.add(item.serial);
      return true;
    });
    setDataWarrantyItem(uniqueData);
  }, [dataWarrantyItem]);

  useEffect(() => {
    if (
      new Date(form.getValues().createDate ?? "") >
      new Date(form.getValues().meetingDate ?? "")
    ) {
      form.setValues((prev) => ({ ...prev, meetingDate: null }));
    }
  }, [form.getValues().createDate]);

  useEffect(() => {
    if (dataWarrantyItem?.length === 0) {
      form.setValues((prev) => ({
        ...prev,
        attribute6: null,
      }));
    }
  }, [dataWarrantyItem]);

  useEffect(() => {
    if (
      form.getValues()?.attribute8 === "LCN" &&
      form.getValues()?.customerGroup !== null
    ) {
      form.setValues((prev) => ({
        ...prev,
        custMeeting: getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "text"
        ),
        custPhone: getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "attribute6"
        ),
        additionalAddress: getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "attribute5"
        ),
      }));
      setProvinceId(
        getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "attribute1"
        )
      );
      handleOnChange(
        getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "attribute1"
        ),
        "province"
      );
      setDistrictId(
        getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "attribute2"
        )
      );
      handleOnChange(
        getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "attribute2"
        ),
        "district"
      );
      setCommuneId(
        getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "attribute3"
        )
      );
      handleOnChange(
        getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "attribute3"
        ),
        "commune"
      );
      handleOnChange(
        getValueById(
          form.getValues()?.customerGroup?.toString() ?? "",
          dataWarrantyCustomerGroup,
          "attribute4"
        ),
        "street"
      );
    }
  }, [form.getValues()?.customerGroup, form.getValues()?.attribute8]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        mt={-7.5}
        p={"0px 10px"}
        onSubmit={form.onSubmit((e: TblWarrantyServiceRequestForm) => {
          handleCreateTblWarrantyServiceRequestForm(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex justify={"space-between"} align={"center"}>
          <Badge
            variant="gradient"
            gradient={{ from: "blue", to: "cyan", deg: 90 }}
          >
            Lập phiếu tiếp nhận yêu cầu
          </Badge>
          <Group
            justify="space-between"
            align="center"
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
            }}
          >
            <Flex w={"100%"} justify={"flex-end"} gap={"md"}>
              <Button
                type="button"
                color="red"
                loading={visible}
                onClick={() => {
                  onClose("");
                }}
                leftSection={!visible ? <IconArrowBack size={18} /> : undefined}
              >
                Thoát
              </Button>
              <Button
                type="submit"
                color="teal"
                loading={visible}
                leftSection={!visible ? <IconCheck size={18} /> : undefined}
              >
                Lưu
              </Button>
            </Flex>
          </Group>
        </Flex>
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 5.25 }}>
            <Fieldset legend="Thông tin yêu cầu xử lý">
              <Grid>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Chi nhánh xử lý yêu cầu"
                    value={
                      JSON.parse(localStorage.getItem("userLoginHeader") ?? "")
                        ?.brachName
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <DateTimePicker
                    label="Ngày tiếp nhận"
                    locale="vi"
                    valueFormat="DD-MM-YYYY hh:mm A"
                    minDate={(() => {
                      const date = new Date();
                      date.setDate(date.getDate() - 5);
                      return date;
                    })()}
                    value={
                      form.getValues().createDate
                        ? new Date(form.getValues()?.createDate ?? "")
                        : null
                    }
                    onChange={(e) =>
                      form.setValues((prev) => ({
                        ...prev,
                        createDate: e ? new Date(e ?? "")?.toISOString() : null,
                      }))
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Người tiếp nhận yêu cầu"
                    readOnly
                    value={
                      JSON.parse(
                        localStorage.getItem("userLoginHeader") || "{}"
                      )?.fullname || ""
                    }
                    {...form.getInputProps("createBy")}
                    variant="filled"
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Mã phiếu tiếp nhận yêu cầu"
                    readOnly
                    variant="filled"
                    {...form.getInputProps("requestNum")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <Select
                    label="Kênh tiếp nhận"
                    placeholder="Chọn kênh tiếp nhận"
                    clearable
                    searchable
                    data={dataTblSaleChannel}
                    {...form.getInputProps("channel")}
                    comboboxProps={{ width: 210 }}
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <Select
                    label="Hình thức xử lý"
                    clearable
                    searchable
                    placeholder="Chọn hình thức xử lý"
                    data={dataTblProcessingMethod}
                    {...form.getInputProps("processingMethod")}
                    onChange={(e) => {
                      form.setValues((prev) => ({
                        ...prev,
                        processingMethod: e ? e?.toString() : null,
                      }));
                    }}
                    withAsterisk
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Số phiếu tiếp nhận yêu cầu"
                    placeholder="Số phiếu tiếp nhận yêu cầu"
                    type="number"
                    {...form.getInputProps("attribute10")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Select
                    label="Nhóm khách hàng"
                    placeholder="Nhập nhóm khách hàng"
                    data={dataWarrantyCustomerGroup
                      ?.filter(
                        (item: any) => item.text !== null && item.value !== null
                      )
                      ?.map((item: any) => ({
                        label: item.text,
                        value: item.value?.toString(),
                      }))}
                    value={form.getValues().customerGroup?.toString() ?? null}
                    {...form.getInputProps("customerGroup")}
                    onChange={(value) => {
                      form.setValues((prev) => ({
                        ...prev,
                        customerGroup: value ? value : null,
                        attribute8: getValueById(
                          value?.toString() ?? "",
                          dataWarrantyCustomerGroup,
                          "attribute7"
                        ),
                      }));
                    }}
                    withAsterisk
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 6.75 }}>
            <Fieldset legend="Thông tin khách hàng hẹn gặp">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Người hẹn gặp"
                    placeholder={"Nhập người hẹn gặp"}
                    onDoubleClick={() => modalCustMeeting()}
                    {...form.getInputProps("custMeeting")}
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Số điện thoại"
                    placeholder={"Nhập số điện thoại"}
                    {...form.getInputProps("custPhone")}
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <DateTimePicker
                    label="Ngày hẹn gặp"
                    placeholder="Nhập ngày hẹn gặp"
                    locale="vi"
                    valueFormat="DD-MM-YYYY hh:mm A"
                    minDate={new Date(form.getValues().createDate ?? "")}
                    value={
                      form.getValues().meetingDate
                        ? new Date(form.getValues().meetingDate ?? "")
                        : null
                    }
                    {...form.getInputProps("meetingDate")}
                    onChange={(e) =>
                      form.setValues((prev) => ({
                        ...prev,
                        meetingDate: e ? new Date(e ?? "").toISOString() : null,
                      }))
                    }
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <Select
                    label="Tỉnh/TP"
                    placeholder="Nhập tỉnh/thành phố"
                    data={dataProvinceSelect}
                    disabled={form.getValues().attribute8 === "LCN"}
                    value={
                      dataMeetingAddress.province
                        ? dataMeetingAddress?.province?.toString()
                        : null
                    }
                    searchable
                    clearable
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    withAsterisk
                    {...form.getInputProps("provinceId")}
                    onChange={(e) => {
                      setProvinceId(e);
                      handleOnChange(e, "province");
                      handleOnChange(null, "district");
                      handleOnChange(null, "commune");
                      handleOnChange(null, "street");
                    }}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <Select
                    label="Quận/Huyện"
                    placeholder="Nhập quận/huyện"
                    data={dataDistrictSelect}
                    value={
                      dataMeetingAddress.district
                        ? dataMeetingAddress.district?.toString()
                        : null
                    }
                    searchable
                    clearable
                    disabled={
                      provinceId === null ||
                      form.getValues().attribute8 === "LCN"
                    }
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    withAsterisk
                    {...form.getInputProps("districtId")}
                    onChange={(e) => {
                      setDistrictId(e);
                      handleOnChange(e, "district");
                      handleOnChange(null, "commune");
                      handleOnChange(null, "street");
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <Select
                    label="Phường/Xã"
                    placeholder="Nhập phường/xã"
                    data={dataCommuneSelect}
                    value={
                      dataMeetingAddress.commune
                        ? dataMeetingAddress.commune?.toString()
                        : null
                    }
                    searchable
                    clearable
                    disabled={
                      districtId === null ||
                      provinceId === null ||
                      form.getValues().attribute8 === "LCN"
                    }
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    withAsterisk
                    {...form.getInputProps("communeId")}
                    onChange={(e) => {
                      setCommuneId(e);
                      handleOnChange(e, "commune");
                      handleOnChange(null, "street");
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <Select
                    label="Tên đường"
                    placeholder="Nhập tên đường"
                    data={dataStreetSelect}
                    value={
                      dataMeetingAddress.street
                        ? dataMeetingAddress.street?.toString()
                        : null
                    }
                    disabled={
                      communeId === null ||
                      districtId === null ||
                      provinceId === null ||
                      form.getValues().attribute8 === "LCN"
                    }
                    {...form.getInputProps("streetId")}
                    onChange={(e) => handleOnChange(e, "street")}
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Địa chỉ cụ thể"
                    placeholder="Nhập địa chỉ cụ thể"
                    disabled={
                      communeId === null ||
                      districtId === null ||
                      provinceId === null ||
                      form.getValues().attribute8 === "LCN"
                    }
                    {...form.getInputProps("additionalAddress")}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label="Nội dung"
                    placeholder={"Nhập nội dung"}
                    {...form.getInputProps("content")}
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
        </Grid>
        <Grid mt={10}>
          <Grid.Col span={12}>
            <ItemList
              handleSearchByPhone={handleSearchByPhone}
              onSelect={setDataWarrantyItemSubmit}
              dataItem={dataWarrantyItem}
              setDataWarrantyItem={setDataWarrantyItem}
              onDelete={setDataWarrantyItem}
              setFormValues={form.setValues}
              typeProduct={form.getValues()?.attribute6}
            />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
  onSuccess: any;
};
