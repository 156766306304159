import { Box, Divider, Grid, Select, TextInput } from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { DateTimePicker } from "@mantine/dates";
import { useResizeObserver } from "@mantine/hooks";
import DetailProduct from "./DetailProduct";

const DeliveryDetailByRequestTransfer = ({
  sourceCode,
  id,
  setParkingFee,
}: {
  sourceCode: string;
  id: any;
  setParkingFee: any;
}) => {
  const [dataRequestTransfer, setDataRequestTransfer] = useState<any>();

  const heightRes = useRef<HTMLDivElement | null>(null);

  const [spanLg, setSpanLg] = useState(1.5);
  const [spanNote, setSpanNote] = useState(12);
  const [ref, { width }] = useResizeObserver();

  const handleResize = useCallback(() => {
    if (width > 1528 && width < 1910) {
      setSpanLg(3);
      setSpanNote(12);
    } else if (width > 1910) {
      setSpanLg(4);
      setSpanNote(8);
    } else {
      setSpanLg(1.5);
      setSpanNote(12);
    }
  }, [width]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    setParkingFee(dataRequestTransfer?.parkingFee);
  }, [dataRequestTransfer]);

  useEffect(() => {
    const updateHeight = () => {
      if (heightRes.current) {
        const height = window.innerHeight - 610;
        heightRes.current.style.height = `${height}px`;
      }
    };
    const timeoutId = setTimeout(updateHeight, 100);
    window.addEventListener("resize", updateHeight);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <>
      <Box w={"100%"}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
            <TextInput
              w={"100%"}
              label="Số phiếu điều chuyển"
              value={dataRequestTransfer?.sourceCode ?? ""}
              variant="filled"
              size="xs"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
            <DateTimePicker
              label="Thời gian lập phiếu"
              value={
                dataRequestTransfer?.createDate
                  ? new Date(dataRequestTransfer?.createDate)
                  : null
              }
              valueFormat="DD-MM-YYYY HH:mm"
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
            <Select
              label="Loại phiếu điều chuyển"
              data={[
                {
                  label: "Điều chuyển chi nhánh",
                  value: "DIEU_CHUYEN_CHI_NHANH",
                },
                {
                  label: "Điều chuyển chi nhánh serial",
                  value: "DIEU_CHUYEN_CHI_NHANH_SERIAL",
                },
                {
                  label: "Điều chuyển nội bộ",
                  value: "DIEU_CHUYEN_NOI_BO",
                },
                {
                  label: "Điều chuyển nội bộ serial",
                  value: "DIEU_CHUYEN_NOI_BO_SERIAL",
                },
                {
                  label: "Xuất kho hủy",
                  value: "XUAT_HUY",
                },
                {
                  label: "Xuất kho điều chỉnh",
                  value: "XUAT_DIEU_CHINH",
                },
                {
                  label: "Xuất trả nhà cung cấp",
                  value: "XUAT_TRA_NCC",
                },
              ]}
              value={dataRequestTransfer?.sourceType}
              variant="filled"
              readOnly
              size="xs"
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Chi nhánh xuất"
              value={dataRequestTransfer?.fromBranchName}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Kho xuất"
              value={dataRequestTransfer?.fromInvName}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Chi nhánh nhận"
              value={dataRequestTransfer?.toBranchName}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Kho nhận"
              value={dataRequestTransfer?.toInvName}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Divider my="xs" label="Danh sách sản phẩm" labelPosition="center" />
        <DetailProduct
          id={id}
          sourceCode={sourceCode}
          sourceType={2}
          isDetail={true}
          setDataOrder={setDataRequestTransfer}
        />
        {/* <ScrollArea
          mt={15}
          ref={heightRes}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped highlightOnHover withTableBorder withColumnBorders>
            <Table.Thead
              className={cx(classes.header, {
                [classes.scrolled]: scrolled,
              })}
            >
              <Table.Tr>
                <Table.Th>Tên sản phẩm</Table.Th>
                <Table.Th>Số lượng</Table.Th>
                <Table.Th>Đơn vị tính</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {dataRequestTransfer?.tblRequestTransferDetailModels?.map(
                (value: any, index: any) => (
                  <Table.Tr key={index}>
                    <Table.Th>{value.itemName}</Table.Th>
                    <Table.Th>{value.primaryQuantity}</Table.Th>
                    <Table.Th>{value.uom}</Table.Th>
                  </Table.Tr>
                )
              )}
            </Table.Tbody>
          </Table>
        </ScrollArea> */}
      </Box>
    </>
  );
};

export default DeliveryDetailByRequestTransfer;
