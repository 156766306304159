import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { repositoryMaster } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { SocketExtension } from "../../../../_base/socket/socket";

export const UpdateApproveStatusView = ({
  onClose,
  identsList,
  mode,
}: UpdateApproveStatusViewProps) => {
  const handleToggleAprove = async () => {
    const url =
      mode === 1 ? "/api/v1/Auth/un-approve-user" : "/api/v1/Auth/approve-user";
    const res = await repositoryMaster.post<MessageResponse<boolean>>(url, {
      approves: identsList,
    });
    if (res)
      if (res.success) {
        for (let index = 0; index < identsList.length; index++) {
          SocketExtension.SendMessageToUserName<any>(
            identsList[index].userName,
            {
              message: "",
              logout: true,
            }
          );
        }

        NotificationExtension.Success("Thành công !");
        onClose((prev: any) => !prev);
      } else NotificationExtension.Fails("Thất bại !");
    modals.closeAll();
  };
  return (
    <div>
      <Text size="20px" mt={5}>
        {`Bạn có chắc chắn ${mode === 1 ? "từ chối" : "phê duyệt"} ${
          identsList.length === 1 ? "" : "các"
        } phiên đăng nhập này?`}
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color={mode === 1 ? "red" : "green"}
          onClick={handleToggleAprove}
          leftSection={<IconCheck size={18} />}
        >
          {mode === 1 ? "Từ chối" : "Chấp thuận"}
        </Button>
      </Group>
    </div>
  );
};

type UpdateApproveStatusViewProps = {
  onClose: any;
  identsList: { userName: string; ip: string }[];
  mode?: number;
};
