import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Group,
  Menu,
  NumberFormatter,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconDeviceFloppy,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { anyAsciiCode } from "../../../_base/helper/AnyAscii";
import { _localization } from "../../../config/location";
import { sky_blue } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderDetailOutWardNew } from "../../../model/SaleOrderModel";
import {
  DataItemItemTransaction,
  ResponseDataTblItemTransaction,
  TblInventorySerialItemDetailOnHand,
  TblInventorySerialOrderDetailOnHand,
} from "../../../model/TblInventorySerialOnHand";
import {
  detailOrderForOutWardModel,
  TblOutward,
  TblOutwardDetail,
} from "../../../model/TblOutWard";
import { getTblInventoryTransactionTypeInWard } from "../../../service/getSelectApi";
import WarehoseEntry from "./warehouseEntry/warehoseEntry";

interface TblInventorySerialOrderDetailOnHandEdit
  extends TblInventorySerialOrderDetailOnHand {
  type?: string;
  poPrice1?: number;
}

interface UserData {
  code: string;
  depName: string;
  departmentId: number;
  fullName: string;
  id: number;
  managerId: number | null;
  managerName: string | null;
  roleId: number;
  roleName: string;
}

const WarehouseInWardPlus = () => {
  const navigate = useNavigate();
  const localtion = useLocation();
  const sourceCode = localtion.state && localtion.state?.sourceCode;
  const sourceType = localtion.state && localtion.state?.sourceType;
  const Detail = localtion.state && localtion.state?.detail;
  const MaCty = localtion.state && localtion.state?.MaCty;
  const locker = localtion.state && localtion.state.isLocked;
  const idDetail = localtion.state && localtion.state.id;
  const [dataInWard, setDataInWard] = useState<TblOutwardDetail | undefined>();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isItemSelected, setIsItemSelected] = useState<boolean>(false);
  const [dataSerialStatus, setDataSerialStatus] = useState<ComboboxItem[]>([]);
  const [handleInventoryId, setHandeInventoryId] = useState<string>("");
  const [dataInWardEdit, setDataInwardEdit] = useState<TblOutwardDetail>();
  const [totalDeviationAmount, setTotalDeviationAmount] = useState<
    number | string
  >();
  const hasFetchedSerialData = React.useRef(false);
  //#region render ui outward
  //#endregion
  /////////////////////////////////////////////////////////////////////////////////////////
  //#region state
  const outWardEntity = {
    sourceId: 39,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: sourceCode || "",
      sourceType: sourceType || "",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "NEW",
      description: "",
      // atribute1: "",
      // atribute2: "",
      // atribute3: "",
      // atribute4: "",
      // atribute5: "",
      // atribute6: "",
      // atribute7: "",
      // atribute8: "",
      // atribute9: "",
      // atribute10: "",
      createBy: 0,
      createName: "",
      createDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  };

  const [dataOutWard, setDataOutWard] = useState<TblOutward>(outWardEntity);
  const [dataInWardCommand, setDataInWardCommand] = useState<TblOutward>({
    sourceId: 0,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: sourceCode || "",
      sourceType: sourceType || "",
      description: "",
      atribute1: "",
      atribute2: "",
      atribute3: "",
      atribute4: "",
      atribute5: "",
      atribute6: "",
      atribute7: "",
      atribute8: "",
      atribute9: "",
      atribute10: "",
      branchId: Number(
        dataInWard?.tblInventoryTransactionModel?.toBranchId ?? ""
      ),
      subInvId: Number(dataInWard?.tblInventoryTransactionModel?.toInvId ?? ""),
      createDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
      departmentId: 0,
      createBy: 0,

      createName: "",
      status: "NEW",
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  });

  const [descriptionValueIn, setDescriptionValueIn] = useState<string>();

  const handleAdjust =
    localtion.pathname === "/warehouse-config/create-warehouse-in-ward" &&
    dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
      "NHAP_DIEU_CHUYEN_CHI_NHANH";

  //Đường dẫn tới nhập hàng điều chuyển
  const handleWarehouse =
    localtion.pathname === "/warehouse-config/create-warehouse-in-ward";
  //Nhập thành phẩm sản xuất
  const handleWareFinished =
    localtion.pathname === "/warehouse-config/create-finished-product-receipt";
  //Đường dẫn điều chỉnh tới nhập hàng điều chuyển
  const handleDetailEdit =
    localtion.pathname === "/warehouse-config/edit-warehouse-in-ward";
  //Nhập hàng nhà cung cấp
  const handeSupplierRquest =
    localtion.pathname === "/warehouse-config/supplier-request-form";
  //Nhập điều chuyển nội bộ
  const handelEditInternalInward =
    localtion.pathname ===
    "/api/v1/Inward/edit-request-transfer-internal-inward";

  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [handleItemCode, setHandleItemCode] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>();
  const [responseStatus, SetReponseStatus] = useState<boolean>(false);
  //table state
  const entityOrderOutWard = {
    tblInventoryTransactionModel: {},
    inventoryTransactionRequestSourceModels: [],
    invCode: null,
    invId: null,
    invName: null,
    outWardCode: null,
    receiver: null,
    sourceCode: null,
    sourceId: null,
    subInvId: null,
    toAddess: null,
  };

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWardNew>(entityOrderOutWard);

  const [dataOrderDetailIn, setDataOrderDetailIn] =
    useState<OrderDetailOutWardNew>({
      ...entityOrderOutWard,
      inventoryTransactionRequestSourceModels:
        entityOrderOutWard.inventoryTransactionRequestSourceModels?.map(
          (model: detailOrderForOutWardModel) => ({
            ...model,
            primaryQuantity: 1,
          })
        ),
    });

  const [serial, setSerial] = useState<string>("");
  const [keySearch, setKeySearch] = useDebouncedState("", 5);
  const [searchSourceCode, setSearchSourceCode] = useDebouncedState("", 500);
  const [dataItem, setDataItem] = useState<
    TblInventorySerialItemDetailOnHand[]
  >([]);
  const [dataItemTransaction, setDataItemTransaction] = useState<
    DataItemItemTransaction[]
  >([]);
  const [dataSearchSourceCodeOption, setDataSearchSourceCodeOption] = useState<
    any[]
  >([]);

  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOrderDetailOnHandEdit[]
  >([]);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<string[]>([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);
  const [handekVoucherCode, setHandekVoucherCode] = useState<string>(
    sourceCode ?? ""
  );
  const [isSave, setIsSave] = useState(false);
  const [sourceCodeRef, setSourceCode] = useState(
    localtion.state && localtion.state.sourceCode
  );
  const headerRef = React.useRef<HTMLDivElement>(null);
  //#endregion
  // func render ui => title
  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              <Text c={"red"} fw={700}>
                Loại phiếu
              </Text>
              <Select
                searchable
                clearable
                w={200}
                size="xs"
                placeholder="Chọn lý do"
                disabled={
                  responseStatus
                    ? true
                    : dataOutWard.tblItemTransactionHeaderCommand.status ===
                        "LOCK" ||
                      handleDetailEdit ||
                      handeSupplierRquest ||
                      sourceCode ||
                      handleWareFinished
                }
                value={
                  dataInventoryTransactionType.find(
                    (ele) => ele.value === sourceType
                  )?.value
                }
                data={dataInventoryTransactionType}
                onChange={(value) => {
                  handleChangeValueInput(value || "", "sourceType");
                  // handleChangeValueInput("", "sourceCode");
                }}
              />
            </Group>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              variant="outline"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
            <Button
              type="submit"
              leftSection={<IconDeviceFloppy size={14} />}
              color="blue"
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                locker ||
                Detail
              }
              onClick={() => {
                handleCreateInWard("OPEN");
              }}
            >
              Lưu (Ctrl + S)
            </Button>
            <Button
              type="button"
              color="green"
              variant="outline"
              leftSection={<IconCheck size={14} />}
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                locker ||
                (dataInWard?.tblInventoryTransactionModel?.toInvId === null &&
                  handeSupplierRquest) ||
                Detail ||
                (dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
                  "NHAP_HANG_DOI_TRA" &&
                  isExceedQuantity) ||
                !dataSerialOnHand.some((item) => !item.type)
              }
              onClick={() => {
                if (handleDetailEdit) {
                  handleCreateInWard("LOCK", true);
                } else {
                  if (dataSerialOnHand.some((item) => !item.type)) {
                    if (
                      handleAdjust ||
                      handeSupplierRquest ||
                      dataOutWard.tblItemTransactionHeaderCommand
                        ?.sourceType === "NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL" ||
                      dataOutWard.tblItemTransactionHeaderCommand
                        ?.sourceType === "NHAP_DIEU_CHUYEN_NOI_BO" ||
                      dataOutWard.tblItemTransactionHeaderCommand
                        ?.sourceType === "NHAP_HANG_DOI_TRA"
                    ) {
                      handleCreateInWard("LOCK", true);
                    }
                  } else {
                    NotificationExtension.Fails("Chưa có sản phẩm để xuất kho");
                    return;
                  }
                }
              }}
            >
              Hoàn thành (Ctrl + Q)
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  // func render ui => select item inward
  const TableSelectCenterTransfer = () => (
    <Table.ScrollContainer minWidth={600} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
            {handeSupplierRquest && <Table.Th>Hủy</Table.Th>}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItemTransaction?.map((item, index) => (
            <Table.Tr
              key={item.serial}
              bg={
                checkDuplicateCode.some((select) => select === item.serial)
                  ? "var(--mantine-color-blue-light)"
                  : undefined
              }
            >
              <Table.Td>
                {handeSupplierRquest || sourceType === "NHAP_HANG_NCC" ? (
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      if (!checkDuplicateCode.includes(item.itemCode)) {
                        notFetchDataSerialOnhand(item);
                      }
                    }}
                    disabled={checkDuplicateCode.includes(item.itemCode)}
                  >
                    {checkDuplicateCode.includes(item.itemCode)
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                ) : (
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      fetchDataSerialOnHand(item.serial);
                    }}
                    disabled={checkDuplicateCode.includes(item.serial)}
                  >
                    {checkDuplicateCode.includes(item.serial)
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                )}
              </Table.Td>
              <Table.Td>{item.itemCode}</Table.Td>
              <Table.Td>
                <Tooltip label={item.itemName}>
                  <Text size="sm" w={150} lineClamp={2} truncate="end">
                    {item.itemName}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>{item.serial}</Table.Td>
              <Table.Td>1</Table.Td>
              <Table.Td>
                <Text size="sm" w={125} truncate="end">
                  {/* {item.subInvName} */}
                </Text>
              </Table.Td>
              <Table.Td>1</Table.Td>
              <Table.Td>
                {sourceType === "NHAP_HANG_NCC" && (
                  <Tooltip label="Hủy">
                    <ActionIcon
                      variant="transparent"
                      color="red"
                      onClick={() =>
                        handleCancelSelection(item.itemCode, item.serial)
                      }
                    >
                      <IconTrash size={20} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                )}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
        {dataItemTransaction?.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  // func render ui => text input transaction header
  const TextTransactionHeader = () => (
    <TextInput
      placeholder="Nhập mã vạch"
      size="xs"
      w={200}
      disabled={
        dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" || Detail
      }
      value={anyAsciiCode(serial)}
      onKeyDown={handleKeyDown}
      onChange={(e) => {
        const value = e.currentTarget.value.replace(/\s/g, "");
        setSerial(value.toUpperCase());
      }}
    />
  );

  // func render ui => select item transaction header
  const MenuDataOutWart = () => (
    <Menu
      trapFocus={false}
      trigger="hover"
      shadow="md"
      width={"800"}
      position="bottom-start"
    >
      <Menu.Target>
        <TextInput
          placeholder="Hoặc tìm kiếm mã sản phẩm"
          size="xs"
          w={200}
          disabled={
            dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
            Detail
          }
          value={keySearch}
          onChange={async (e) => {
            const _key = e.target.value ?? "";
            setKeySearch(_key);
          }}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <TableSelectCenterTransfer />
      </Menu.Dropdown>
    </Menu>
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleKeyDownSourceCode = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      // Kiểm tra nếu nhấn Enter
      if (dataSearchSourceCodeOption.length === 1) {
        handleChangeValueInput(searchSourceCode || "", "sourceCode");
      }
    }
  };

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const changeVoucherCode = (value: string) => {
    setHandekVoucherCode(value);
    // if (handekVoucherCode) {
    //   fetchDataInWard();
    // }
  };

  const handleChangeValueInput = (
    value: null | string | boolean,
    key: string
  ) => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
    }));

    setDataInWard(undefined);
    setDataSerialOnHand([]);
    if (handleWarehouse || handleDetailEdit) {
      handleCreateInWard(key, value);
    }
  };

  const handleBranchChange = (value: string) => {
    setDataInWardCommand((prev) => ({
      ...prev,
      tblItemTransactionHeaderCommand: {
        ...prev.tblItemTransactionHeaderCommand,
        branchId: Number(value),
      },
    }));
  };

  const handleOnInventory = (value: string) => {
    setHandeInventoryId(value);
    setDataInWardCommand((prev) => ({
      ...prev,
      tblItemTransactionHeaderCommand: {
        ...prev.tblItemTransactionHeaderCommand,
        subInvId: Number(value),
      },
    }));
  };

  const handleChangeStatus = async (value: string, row: any, label: string) => {
    setDataSerialOnHand((prev) =>
      prev.map((item, i) =>
        i === row.index
          ? {
              ...item,
              serialStatus: value,
            }
          : item
      )
    );
  };

  const handleCancelSelection = (itemCode: string, serial: string) => {
    // setDataSerialOnHand((prevData) =>
    //   prevData.filter(
    //     (item) => item.itemCode !== itemCode && item.serial !== serial
    //   )
    // );
    setSelectedItem([""]);
    setKeySearch("");
    setSerial("");
    setCheckDuplicateCode([""]);
    setCheckDuplicateCode((prevCodes) =>
      prevCodes.filter((code) => code !== itemCode && code !== serial)
    );
  };

  const notFetchDataSerialOnhand = (item: any) => {
    if (!checkDuplicateCode.includes(item.itemCode)) {
      setSelectedItem(item);
      setCheckDuplicateCode((prev) => [...prev, item.itemCode]);
      setIsItemSelected(true);
    }
  };

  const handleSerialInput = (serial: string) => {
    if (selectedItem && serial) {
      const isSerialDuplicate = dataSerialOnHand.some(
        (item) => item.serial === serial
      );
      if (isSerialDuplicate) {
        NotificationExtension.Warn(
          "Mã serial đã tồn tại, vui lòng nhập mã khác"
        );
        return;
      }
      const matchingItemInArray2 =
        dataOrderDetailIn?.inventoryTransactionRequestSourceModels?.find(
          (item) => item.itemCode === selectedItem.itemCode
        );

      if (matchingItemInArray2) {
        const totalAddedQuantityForItem =
          dataSerialOnHand
            ?.filter((item) => item.itemCode === selectedItem.itemCode)
            ?.reduce((sum, item) => sum + (item.primaryQuantity || 0), 0) || 0;

        const totalPrimaryQuantityForItem =
          matchingItemInArray2.primaryQuantity || 0;

        if (totalAddedQuantityForItem < totalPrimaryQuantityForItem) {
          setDataSerialOnHand((prevData) => [
            {
              ...selectedItem,
              serial,
              invId: dataInWard?.tblInventoryTransactionModel?.sourceCode,
              primaryQuantity: 1, // Luôn là 1 khi thêm mới
            },
            ...prevData,
          ]);
          setIsItemSelected(false);
          setSerial("");
        } else {
          NotificationExtension.Fails(
            "Sản phẩm vượt quá số lượng cần nhập, vui lòng kiểm tra lại"
          );
        }
      } else {
        NotificationExtension.Fails(
          "Không tìm thấy sản phẩm trong danh sách nhập, vui lòng kiểm tra lại"
        );
      }
    }
  };

  const handleAddProduct = () => {
    if (
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode ||
      dataInWard?.tblInventoryTransactionModel?.sourceCode
    ) {
      if (checkDuplicateCode.length === 0) {
        fetchDataSerialOnHand();
        setSerial("");
      } else if (checkDuplicateCode.length > 0) {
        if (checkDuplicateCode.includes(serial)) {
          NotificationExtension.Fails("Mã Serial đã được quét !");
        } else {
          fetchDataSerialOnHand();
          setSerial("");
        }
      }
    } else {
      NotificationExtension.Fails("Chưa nhập số chứng từ");
    }
  };

  const deleteItemSerial = (serial: string) => {
    setDataSerialOnHand((prevData) => {
      return prevData.filter((item) => item.serial !== serial);
    });
    setCheckDuplicateCode((prevData) =>
      prevData.filter((item) => item !== serial)
    );
  };

  const handleInputChange = (value: string) => {
    setDescriptionValueIn(value);
  };

  const checkVarianceItem = (items: detailOrderForOutWardModel[]) => {
    let variance = false;
    let variaceItems: { itemName: string; varianceQuantity: number }[] = [];
    items.forEach((item) => {
      const _varianceQuantity =
        (item.scannedQuantity || 0) - (item.primaryQuantity || 0);
      if (_varianceQuantity !== 0) {
        variaceItems = [
          ...variaceItems,
          {
            itemName: item.itemName,
            varianceQuantity: _varianceQuantity,
          },
        ];
        variance = true;
      }
    });
    return {
      isVariance: variance,
      variaceItems: variaceItems,
    };
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      serial.length >= 3 &&
      dataOutWard.tblItemTransactionHeaderCommand.status !== "LOCK"
    ) {
      if (sourceType === "NHAP_HANG_NCC") {
        handleSerialInput(serial);
      } else {
        handleAddProduct();
      }
    }
  };

  const openCofirmModal = (variaceItems: any[]): Promise<boolean> => {
    return new Promise((resolve) => {
      modals.openConfirmModal({
        size: "40%",
        children: (
          <Box component="form" mx="auto" style={{ position: "relative" }}>
            <Text size="20px" fw={"bold"} my={10}>
              Có những sản phẩm sau có lệch?
            </Text>
            <Box mt={3}>
              <Table
                highlightOnHover
                striped
                withColumnBorders
                horizontalSpacing="md"
                verticalSpacing="sm"
                bd={"1px solid #E0E0E0"}
              >
                <thead>
                  <tr style={{ backgroundColor: "#F8F9FD" }}>
                    <th
                      style={{
                        textAlign: "left",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Tên sản phẩm
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Số lượng lệch
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {variaceItems?.map((item, index) => {
                    const varianceQuantity =
                      handeSupplierRquest ||
                      (handleDetailEdit && sourceType === "NHAP_HANG_NCC")
                        ? item.scannedQuantity
                        : item.varianceQuantity || 0;
                    const primaryQuantity = item.primaryQuantity || 0;

                    //Tổng các số lượng lệch
                    const deviationAmount =
                      handleWarehouse ||
                      handleDetailEdit ||
                      handeSupplierRquest ||
                      sourceType === "NHAP_HANG_NCC"
                        ? varianceQuantity - primaryQuantity
                        : varianceQuantity;

                    setTotalDeviationAmount(deviationAmount);

                    return (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "12px",
                            fontWeight: "bold",
                            textAlign: "left",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {item.itemName || item.itemCode}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "red",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {deviationAmount}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Box>

            <Group
              justify="end"
              mt="xs"
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <Button
                type="button"
                color="gray"
                onClick={() => {
                  // handleChangeValueInput("", "status");
                  modals.closeAll();
                  // resolve(false);
                }}
                leftSection={<IconX size={18} />}
              >
                Hủy
              </Button>
              {sourceType !== "NHAP_HANG_NCC" && (
                <Button
                  onClick={() => {
                    handleChangeValueInput("LOCK", "status");
                    modals.closeAll();
                    resolve(true);
                  }}
                  color={sky_blue.base}
                  leftSection={<IconCheck size={18} />}
                >
                  Xác nhận
                </Button>
              )}
            </Group>
          </Box>
        ),

        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
        // onClose() {
        //   handleChangeValueInput("", "status");
        // },
      });
    });
  };

  const checkExceedQuantityFound = (data: any[]) => {
    const _exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = data
            .filter((item) => item.itemId === order.itemId && !item.type)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

            return remainQuantity < 0;
          }

          return false;
        }
      );

    if (_exceedQuantityFound) {
      return true;
    }
    return false;
  };

  //#region Sửa các phiếu
  const fetchDataEditInDetail = async () => {
    let url = "";
    if (Detail) {
      if (handleDetailEdit && sourceType === "NHAP_HANG_NCC") {
        url = `/api/v1/Inward/detail-in-ward-po?id=${idDetail}`;
      } else if (
        handleDetailEdit &&
        sourceType === "NHAP_DIEU_CHUYEN_CHI_NHANH"
      ) {
        url = `/api/v1/Inward/detail-in-ward-request-transfer?id=${idDetail}`;
      } else if (
        handleDetailEdit &&
        sourceType === "NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL"
      ) {
        url = `/api/v1/Inward/detail-in-ward-request-transfer-serial?id=${idDetail}`;
      } else if (handleDetailEdit && sourceType === "NHAP_DIEU_CHUYEN_NOI_BO") {
        url = `/api/v1/Inward/detail-request-transfer-internal-inward?id=${idDetail}`;
      } else if (
        handleDetailEdit &&
        sourceType === "NHAP_DIEU_CHUYEN_NOI_BO_SERIAL"
      ) {
        url = `/api/v1/Inward/detail-request-transfer-internal-inward-serial?id=${idDetail}`;
      } else if (handleDetailEdit && sourceType === "NHAP_HANG_DOI_TRA") {
        url = `/api/v1/Inward/detail-in-ward-return?id=${idDetail}`;
      }
    } else {
      if (handleDetailEdit && sourceType === "NHAP_HANG_NCC") {
        url = `/api/v1/Inward/edit-in-ward-po?id=${idDetail}`;
      } else if (
        handleDetailEdit &&
        sourceType === "NHAP_DIEU_CHUYEN_CHI_NHANH"
      ) {
        url = `/api/v1/Inward/edit-in-ward-request-transfer?id=${idDetail}`;
      } else if (
        handleDetailEdit &&
        sourceType === "NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL"
      ) {
        url = `/api/v1/Inward/edit-in-ward-request-transfer-serial?id=${idDetail}`;
      } else if (handleDetailEdit && sourceType === "NHAP_DIEU_CHUYEN_NOI_BO") {
        url = `/api/v1/Inward/edit-request-transfer-internal-inward?id=${idDetail}`;
      } else if (
        handleDetailEdit &&
        sourceType === "NHAP_DIEU_CHUYEN_NOI_BO_SERIAL"
      ) {
        url = `/api/v1/Inward/edit-request-transfer-internal-inward-serial?id=${idDetail}`;
      } else if (handleDetailEdit && sourceType === "NHAP_HANG_DOI_TRA") {
        url = `/api/v1/Inward/edit-in-ward-return?id=${idDetail}`;
      }
    }
    try {
      const response = await repositoryPos.get<
        MessageResponse<TblOutwardDetail>
      >(url);
      if (response?.success && response?.data) {
        const newTransactionHeader = {
          transactionId:
            response.data.tblInventoryTransactionModel.transactionId || 0,
          transactionCode:
            response.data.tblInventoryTransactionModel.transactionCode || "",
          transactionDate:
            response.data.tblInventoryTransactionModel.transactionDate || "",
          sourceCode:
            sourceCode ||
            response.data.tblInventoryTransactionModel.sourceCode ||
            "",
          sourceType:
            response.data.tblInventoryTransactionModel.sourceType || "",
          branchId:
            response.data.tblInventoryTransactionModel.toBranchId || null,
          subInvId: response.data.tblInventoryTransactionModel.toInvId || null,
          departmentId:
            response.data.tblInventoryTransactionModel.departmentId || 0,
          status: response.data.tblInventoryTransactionModel.status || "NEW",
          description:
            response.data.tblInventoryTransactionModel.description || "",
        };

        setDataInWard(response.data);
        setDescriptionValueIn(
          response?.data?.tblInventoryTransactionModel?.description ?? ""
        );

        setDataInWardCommand((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: newTransactionHeader,
        }));

        setDataOrderDetailIn((prevData) => ({
          ...prevData,
          inventoryTransactionRequestSourceModels:
            response.data.inventoryTransactionRequestSourceModels.map(
              (order) => {
                const matchingItem = dataSerialOnHand
                  .filter(
                    (item) =>
                      item.serial === order.serial ||
                      item.itemCode === order.itemCode
                  )
                  .map((item) => item.primaryQuantity || 0);

                const totalQuantity = matchingItem.reduce(
                  (acc, curr) => acc + curr,
                  0
                );
                const orderQuantity = order.primaryQuantity ?? 0;
                const orderTransferQuantity = order.transactionQuantity ?? 0;
                const remainQuantity =
                  orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

                return {
                  ...order,
                  scannedQuantity: totalQuantity,
                  remainQuantity: remainQuantity,
                };
              }
            ),
        }));

        const newSerialData =
          response.data.inventoryTransactionSerialModels.map((item) => ({
            ...item,
            primaryQuantity: 1,
            type: "scanned",
          }));

        if (
          JSON.stringify(newSerialData) !== JSON.stringify(dataSerialOnHand)
        ) {
          setDataSerialOnHand(newSerialData);
        }

        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.inventoryTransactionSerialModels
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
      } else {
        setDataOrderDetail(entityOrderOutWard);
      }
    } catch (error) {
      setDataOrderDetail(entityOrderOutWard);
    }
  };

  const fetchDataSupplier = async () => {
    let url = "";

    if (handleWareFinished) {
      url = `/api/v1/Inward/create-in-ward-mfg-finished-goods?SourceCode=${idDetail}&SourceType=${sourceType}&prefix=NK`;
    } else {
      url = `/api/v1/Inward/create-in-ward-po?SourceCode=${idDetail}&SourceType=${sourceType}&BranchCode=${MaCty}&prefix=NK`;
    }
    try {
      const response = await repositoryPos.get<
        MessageResponse<TblOutwardDetail>
      >(url);
      if (response?.success && response?.data) {
        const {
          transactionId,
          transactionCode,
          transactionDate,
          sourceCode,
          sourceType,
          fromInvId,
          fromBranchId,
          branchId,
          subInvId,
          toInvId,
          toBranchId,
          departmentId,
          status,
          description,
        } = response.data.tblInventoryTransactionModel;

        const newTransactionHeader = {
          transactionId: transactionId || 0,
          transactionCode: transactionCode || "",
          transactionDate: transactionDate || "",
          sourceCode: sourceCode || "",
          subSourceId: idDetail || "",
          sourceType: sourceType || "",
          branchId: toBranchId || null,
          subInvId: toInvId || null,
          departmentId: departmentId || 0,
          status: status || "NEW",
          description: description || "",
        };

        setDataInWard(response?.data);
        setDataInWardCommand((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: newTransactionHeader,
        }));

        setDataOrderDetailIn((prevData) => ({
          ...prevData,
          inventoryTransactionRequestSourceModels:
            response?.data.inventoryTransactionRequestSourceModels.map(
              (order) => ({
                ...order,
                scannedQuantity: 0,
                remainQuantity:
                  (order.primaryQuantity || 0) -
                  (order.transactionQuantity || 0),
              })
            ),
        }));

        setDataSerialOnHand(
          response.data.inventoryTransactionSerialModels.map((item) => ({
            ...item,
            type: "scanned",
          })) || []
        );
        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.inventoryTransactionSerialModels
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
      } else {
        setDataOrderDetail(entityOrderOutWard);
      }
    } catch (error) {
      setDataOrderDetail(entityOrderOutWard);
    }
  };

  useEffect(() => {
    if (handleDetailEdit) {
      handleCreateInWard();
    }
  }, [dataSerialOnHand]);

  const SerialStatus = async () => {
    const url = `/api/v1/TblDmSerialStatus/get-select`;
    try {
      const reponse = await repositoryMdm.get<MessageResponse<any>>(url);
      if (reponse && reponse.success) {
        const mapData = reponse.data?.map((item: any) => {
          return {
            label: item.text.toString() ?? "",
            value: item.value.toString() ?? "",
          };
        });
        setDataSerialStatus(mapData);
      }
    } catch (error) {
      return [];
    }
  };

  const fetchDataSerialOnHand = async (serialCode?: string) => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?SourceCode=${
      dataInWard?.tblInventoryTransactionModel?.sourceCode
    }&Type=${dataOutWard.tblItemTransactionHeaderCommand.sourceType}&Serial=${
      serialCode || serial
    }`;

    try {
      const response = await repositoryPos.post<
        MessageResponse<TblInventorySerialOrderDetailOnHand>
      >(`api/v1/TblItemTransaction/search-serial-item-transaction`, {
        serial: serialCode || serial,
        sourceCode: dataInWard?.tblInventoryTransactionModel?.sourceCode,
        type: dataOutWard.tblItemTransactionHeaderCommand.sourceType,
        invId: dataOutWard.tblItemTransactionHeaderCommand?.fromInvId,
        branchId: 0,
        isNoSerial:
          dataOrderDetail?.inventoryTransactionRequestSourceModels?.find(
            (item) => item?.itemCode === keySearch
          )?.isNoSerial ?? "",
      });

      if (response && response.success) {
        const responseData = response.data;

        if (responseData.warning) {
          NotificationExtension.Fails(responseData.warning);
        } else {
          const dataDetail = {
            ...responseData,
            subInvName: responseData.invName,
          };
          const checkData = [...dataSerialOnHand, { ...dataDetail }];
          if (checkExceedQuantityFound(checkData)) {
            NotificationExtension.Warn(
              "Sản phẩm vượt quá số lượng cần xuất, vui lòng kiểm tra lại"
            );
          } else {
            setDataSerialOnHand((prevData) => [...prevData, { ...dataDetail }]);
            setCheckDuplicateCode((prev) => [...prev, serialCode || serial]);
            // NotificationExtension.Success("Quét mã serial thành công");
          }
        }
      } else {
        NotificationExtension.Fails(response?.message || "");
      }
    } catch (error) {
      setIsError(true);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleCreateInWard = async (status?: string, isLocked?: any) => {
    if (!dataSerialOnHand.some((item) => item.warning)) {
      if (!isExceedQuantity) {
        let dataCreate;
        if (status) {
          dataCreate = {
            ...dataInWardCommand,
            tblItemTransactionHeaderCommand: {
              ...dataInWardCommand.tblItemTransactionHeaderCommand,
              // subInvId: dataInWard?.tblInventoryTransactionModel?.toInvId ?? "",
              // branchId:
              //   dataInWard?.tblInventoryTransactionModel?.toBranchId ?? "",
              attribute4:
                dataInWard?.tblInventoryTransactionModel?.fromInvName ?? "",
              attribute5:
                dataInWard?.tblInventoryTransactionModel?.toInvName ?? "",
              transactionDate:
                status === "LOCK"
                  ? moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss")
                  : "",
              description: descriptionValueIn ?? "",
              status: status,
              departmentId: userData?.departmentId ?? null,
            },
            serials: dataSerialOnHand
              // .filter((item) =>
              //   handleDetailEdit ? item.type === "scanned" : !item.type
              // )
              .map((item) => ({
                primaryQuantity: handeSupplierRquest ? 1 : item.primaryQuantity,
                subQuantity: item.subQuantity,
                serial: item.serial,
                invId: dataInWard?.tblInventoryTransactionModel?.toInvId ?? "",
                serialStatus: item.serialStatus,
                seritalNote: "",
                itemCode: item.itemCode,
              })),
            isLock: isLocked,
          };
        } else {
          dataCreate = {
            ...dataInWardCommand,
            tblItemTransactionHeaderCommand: {
              ...dataInWardCommand.tblItemTransactionHeaderCommand,
              transactionCode:
                dataInWard?.tblInventoryTransactionModel?.transactionCode ?? "",
              sourceCode:
                dataInWard?.tblInventoryTransactionModel?.sourceCode ?? "",
              sourceType:
                dataInWard?.tblInventoryTransactionModel?.sourceType ?? "",
              branchId:
                dataInWard?.tblInventoryTransactionModel?.toBranchId ?? 0,
              subInvId: dataInWard?.tblInventoryTransactionModel?.toInvId ?? "",
              description: descriptionValueIn ?? "",
              status: status,
              departmentId: userData?.departmentId ?? null,
            },
            serials: dataSerialOnHand
              .filter((item) => !item.type)
              .map((item) => ({
                primaryQuantity: item.primaryQuantity,
                subQuantity: item.subQuantity,
                serial: item.serial,
                invId: dataInWard?.tblInventoryTransactionModel?.toInvId ?? "",
                serialStatus: "",
                seritalNote: "",
                itemCode: item.itemCode,
              })),
          };
        }

        try {
          let data = "";
          if (status === "LOCK") {
            const variance = checkVarianceItem(
              handeSupplierRquest || handleDetailEdit
                ? dataOrderDetailIn.inventoryTransactionRequestSourceModels ??
                    []
                : dataInWard?.inventoryTransactionRequestSourceModels ?? []
            );

            if (variance.isVariance) {
              const confirm = await handleMismatch();
              if (handleDetailEdit ? !confirm : confirm) {
                handleChangeValueInput("OPEN", "status");
                return;
              }
            }

            if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_HANG_NCC"
            ) {
              data = "/api/v1/Inward/create-in-ward-po";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_DIEU_CHUYEN_CHI_NHANH"
            ) {
              data = "/api/v1/Inward/create-in-ward-request-transfer";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL"
            ) {
              data =
                "/api/v1/Inward/create-request-transfer-internal-inward-serial";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_DIEU_CHUYEN_NOI_BO"
            ) {
              data = "/api/v1/Inward/create-request-transfer-internal-inward";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_DIEU_CHUYEN_NOI_BO_SERIAL"
            ) {
              data =
                "/api/v1/Inward/create-request-transfer-internal-inward-serial";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_HANG_DOI_TRA"
            ) {
              data = "/api/v1/Inward/create-in-ward-return";
            }

            const response = await repositoryPos.post<MessageResponse<any>>(
              data,
              dataCreate
            );

            if (response?.success) {
              SetReponseStatus(response?.success);
              NotificationExtension.Success("Hoàn thành phiếu nhập kho");
              setTimeout(
                () => navigate("/warehouse-config/warehouse-in-ward"),
                3000
              );
            }
          } else if (status === "OPEN") {
            if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_HANG_NCC"
            ) {
              data = "/api/v1/Inward/create-in-ward-po";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_DIEU_CHUYEN_CHI_NHANH"
            ) {
              data = "/api/v1/Inward/create-in-ward-request-transfer";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL"
            ) {
              data =
                "/api/v1/Inward/create-request-transfer-internal-inward-serial";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_DIEU_CHUYEN_NOI_BO"
            ) {
              data = "/api/v1/Inward/create-request-transfer-internal-inward";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_DIEU_CHUYEN_NOI_BO_SERIAL"
            ) {
              data =
                "/api/v1/Inward/create-request-transfer-internal-inward-serial";
            } else if (
              dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
              "NHAP_HANG_DOI_TRA"
            ) {
              data = "/api/v1/Inward/create-in-ward-return";
            }

            const response = await repositoryPos.post<MessageResponse<any>>(
              data,
              dataCreate
            );

            if (response?.success) {
              SetReponseStatus(response?.success);
              NotificationExtension.Success("Lưu phiếu nhập kho thành công");
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        // NotificationExtension.Fails("Có sản phẩm vượt quá số lượng cần xuất");
      }
    } else {
      NotificationExtension.Fails("Có sản phẩm không hợp lệ");
    }
  };

  //#region  data In ward
  const fetchDataInWard = async () => {
    let url = "";
    // if (id) {
    //   url = `/api/v1/Inward/edit-in-ward-request-transfer?id=${id}`;
    // } else {
    // }
    if (handekVoucherCode) {
      if (
        dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
        "NHAP_DIEU_CHUYEN_CHI_NHANH"
      ) {
        url = `/api/v1/Inward/create-in-ward-request-transfer?SourceCode=${handekVoucherCode}&SourceType=${dataOutWard?.tblItemTransactionHeaderCommand?.sourceType}&prefix=NK`;
      } else if (
        dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
        "NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL"
      ) {
        url = `/api/v1/Inward/create-in-ward-request-transfer-serial?SourceCode=${handekVoucherCode}&SourceType=${dataOutWard?.tblItemTransactionHeaderCommand?.sourceType}&prefix=NK`;
      } else if (
        dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
        "NHAP_DIEU_CHUYEN_NOI_BO"
      ) {
        url = `/api/v1/Inward/create-request-transfer-internal-inward?SourceCode=${handekVoucherCode}&SourceType=${dataOutWard?.tblItemTransactionHeaderCommand?.sourceType}&prefix=NK`;
      } else if (
        dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
        "NHAP_DIEU_CHUYEN_NOI_BO_SERIAL"
      ) {
        url = `/api/v1/Inward/create-request-transfer-internal-serial-inward?SourceCode=${handekVoucherCode}&SourceType=${dataOutWard?.tblItemTransactionHeaderCommand?.sourceType}&prefix=NK`;
      } else if (
        dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
        "NHAP_HANG_DOI_TRA"
      ) {
        url = `/api/v1/Inward/create-in-ward-return?SourceCode=${handekVoucherCode}&SourceType=${dataOutWard?.tblItemTransactionHeaderCommand?.sourceType}&prefix=NK`;
      }
    }

    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        const data = response.data;
        const {
          transactionId,
          transactionCode,
          transactionDate,
          sourceCode,
          sourceType,
          branchId,
          toBranchId,
          toInvId,
          fromBranchId,
          fromBranchName,
          toBranchName,
          fromInvId,
          subInvId,
          departmentId,
          status,
          description,
        } = response.data.tblInventoryTransactionModel;

        const newTransactionHeader = {
          transactionId: transactionId || 0,
          transactionCode: transactionCode || "",
          transactionDate: transactionDate || "",
          sourceCode: sourceCode || "",
          sourceType: sourceType || "",
          branchId: toBranchId || null,
          subInvId: toInvId || null,
          departmentId: departmentId || 0,
          status: status || "NEW",
          description: description || "",
          atribute4: fromBranchName ?? "",
          atribute5: userInfo?.fullName ?? "",
        };

        setDataInWardCommand((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: newTransactionHeader,
        }));

        setDataInWard(data);
      }
      // } else {
      //   NotificationExtension.Fails("Bạn không có quyền !");
      //   navigate(-1);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchdataInventoryTransactionType = async () => {
    const responseInWard = await getTblInventoryTransactionTypeInWard();

    if (
      localtion.pathname === "/warehouse-config/create-warehouse-in-ward" ||
      handleDetailEdit ||
      handeSupplierRquest ||
      handleWareFinished
    ) {
      setDataInventoryTransactionType(
        responseInWard.map((type) => ({ value: type.value, label: type.text }))
      );
    }
  };

  // Get Data source code
  const fetchDataSourceCode = async () => {
    try {
      const _sourceType =
        dataOutWard.tblItemTransactionHeaderCommand.sourceType;
      let url = `api/v1/RequestInventoryTransaction/get-list-code-transaction-request?Type=${_sourceType}&Page=30`;
      if (searchSourceCode && sourceCode) {
        url += `?Key=${searchSourceCode || sourceCode}`;
      }
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);

      if (response?.success) {
        setDataSearchSourceCodeOption(response.data.map((data) => data) || []);
      }
    } catch (error) {
      setDataSearchSourceCodeOption([]);
    }
  };

  // Get data sản phẩm
  const fetchDataItem = async () => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand?.sourceType;
    const _sourceCode =
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode ||
      handekVoucherCode;
    const _soureId = dataInWard?.tblInventoryTransactionModel?.sourceCode;
    if (
      handleAdjust ||
      handeSupplierRquest ||
      handleDetailEdit ||
      handleWareFinished ||
      dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
        "NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL" ||
      dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
        "NHAP_DIEU_CHUYEN_NOI_BO" ||
      dataOutWard.tblItemTransactionHeaderCommand?.sourceType ===
        "NHAP_DIEU_CHUYEN_NOI_BO_SERIAL"
    ) {
      if (keySearch) {
        try {
          const response =
            await repositoryPos.get<ResponseDataTblItemTransaction>(
              `api/v1/TblItemTransaction/search-list-serial-item-transaction?KeySearch=${
                keySearch ?? ""
              }&SourceCode=${
                sourceType === "NHAP_HANG_NCC" && handleDetailEdit
                  ? dataInWard?.tblInventoryTransactionModel?.subSourceId
                  : handeSupplierRquest ||
                    handleDetailEdit ||
                    handleWareFinished
                  ? handeSupplierRquest || handleWareFinished
                    ? idDetail
                    : _soureId
                  : handekVoucherCode ?? ""
              }&Page=30&Type=${
                dataOutWard?.tblItemTransactionHeaderCommand?.sourceType ||
                sourceType
              }`
            );
          if (response) {
            setDataItemTransaction(response?.data);
          }
        } catch (error) {
          setDataItemTransaction([]);
        }
      }
    } else {
      if (keySearch) {
        try {
          const response = await repositoryPos.get(
            `api/v1/TblItemTransaction/search-list-serial-item-transaction?KeySearch=${
              keySearch ?? ""
            }&SourceCode=${
              _sourceCode ?? sourceCodeRef
            }&Page=30&Type=${_sourceType}`
          );

          setDataItemTransaction(response?.data);
        } catch (error) {
          setDataItemTransaction([]);
        }
      }
    }
  };

  //#endregion

  //#region useEffects
  useEffect(() => {
    SerialStatus();
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([fetchdataInventoryTransactionType()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) setUserInfo(JSON.parse(userLogin));

    fetchAllData();
  }, []);

  useEffect(() => {
    if (handleDetailEdit) {
      fetchDataEditInDetail();
    } else if (handeSupplierRquest || handleWareFinished) {
      fetchDataSupplier();
    }
  }, [handleDetailEdit, idDetail]);

  //#region  Hàm gọi lại phiếu
  useEffect(() => {
    if (handleDetailEdit) {
      fetchDataEditInDetail();
    }
  }, [handleDetailEdit]);

  //#region trừ lệch
  useEffect(() => {
    const inventoryTransactionModels = handeSupplierRquest
      ? dataOrderDetailIn?.inventoryTransactionRequestSourceModels
      : dataInWard?.inventoryTransactionRequestSourceModels;
    //&& !item.type
    const exceedQuantityFound = inventoryTransactionModels?.some((order) => {
      const matchingOrders = dataSerialOnHand
        .filter(
          (item) =>
            item.serial === order.serial ||
            (handeSupplierRquest && item.itemCode === order.itemCode)
        )
        .map((item) => item.primaryQuantity || 0);

      if (matchingOrders.length) {
        const totalQuantity = matchingOrders.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const orderQuantity = order.primaryQuantity ?? 0;
        const orderTransferQuantity = order.transactionQuantity ?? 0;
        const remainQuantity =
          orderQuantity - (orderTransferQuantity + totalQuantity);

        return remainQuantity < 0;
      }
      return false;
    });

    if (exceedQuantityFound) {
      setIsExceedQuantity(true);
    } else {
      setIsExceedQuantity(false);
    }
    if (handeSupplierRquest || handleDetailEdit) {
      setDataOrderDetailIn((prevData: OrderDetailOutWardNew) => {
        if (!prevData) return prevData;
        const updatedModels =
          prevData.inventoryTransactionRequestSourceModels?.map((order) => {
            const quantities = dataSerialOnHand
              .filter(
                (item) =>
                  sourceType === "NHAP_HANG_NCC"
                    ? item.itemCode === order.itemCode
                    : item.serial === order.serial
                // ? item.serial === order.serial
                // : item.itemCode === order.itemCode
              )
              .map((item) => item.primaryQuantity || 0);

            const totalQuantity = quantities.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity);

            return {
              ...order,
              scannedQuantity: totalQuantity,
              remainQuantity: remainQuantity,
            };
          }) ?? [];
        return {
          ...prevData,
          inventoryTransactionRequestSourceModels: updatedModels,
          tblInventoryTransactionModel: prevData.tblInventoryTransactionModel,
          inventoryTransactionSerial:
            prevData.inventoryTransactionRequestSourceModels,
        };
      });
    } else {
      setDataInWard((prevData: TblOutwardDetail | undefined) => {
        if (!prevData) return prevData;
        const updatedModels =
          prevData.inventoryTransactionRequestSourceModels?.map((order) => {
            const quantities = dataSerialOnHand
              .filter((item) => item.serial === order.serial && !item.type)
              .map((item) => item.primaryQuantity || 0);

            const totalQuantity = quantities.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity);

            return {
              ...order,
              scannedQuantity: totalQuantity,
              remainQuantity: remainQuantity,
            };
          }) ?? [];
        return {
          ...prevData,
          inventoryTransactionRequestSourceModels: updatedModels,
          tblInventoryTransactionModel: prevData.tblInventoryTransactionModel,
          inventoryTransactionSerial: prevData.inventoryTransactionSerialModels,
        };
      });
    }
  }, [dataSerialOnHand]);

  useEffect(() => {
    if (!searchSourceCode && searchSourceCode.length > 3) {
      fetchDataSourceCode();
    }
  }, [searchSourceCode]);

  useEffect(() => {
    if (handleItemCode) {
      const autoSelectItems = dataItemTransaction?.filter(
        (item) => !checkDuplicateCode.includes(item.itemCode)
      );
      // if (autoSelectItems) {
      //   NotificationExtension.Success(`Mã hàng đã được thêm thành công!`);
      // }
      autoSelectItems.forEach((item) => {
        notFetchDataSerialOnhand(item);
        setCheckDuplicateCode((prev) => [...prev, item.itemCode]);
      });
    }
  }, [dataItemTransaction, handleItemCode]);

  useEffect(() => {
    if (
      dataInWard?.tblInventoryTransactionModel?.toInvId === null &&
      handeSupplierRquest
    ) {
      NotificationExtension.Fails("Thông tin kho chưa hiển thị");
    }
  }, [dataInWard?.tblInventoryTransactionModel?.toInvId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (keySearch && keySearch.length > 2) {
        fetchDataItem();
      } else {
        setDataItem([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [keySearch]);

  useEffect(() => {
    if (handekVoucherCode) {
      if (
        handleAdjust ||
        dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
          "NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL" ||
        dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
          "NHAP_DIEU_CHUYEN_NOI_BO" ||
        dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
          "NHAP_DIEU_CHUYEN_NOI_BO_SERIAL" ||
        dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
          "NHAP_HANG_DOI_TRA"
      ) {
        fetchDataInWard();
      }
    }
  }, [handekVoucherCode]);

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  useEffect(() => {
    const totalPrimaryQuantity =
      dataInWard?.inventoryTransactionRequestSourceModels.reduce(
        (total, item) => total + (item.primaryQuantity || 0),
        0
      );
    if (totalPrimaryQuantity === dataSerialOnHand?.length) {
      if (handleAdjust) {
        NotificationExtension.Success("Đã quét đủ Serial!");
      }
    }
  }, [dataSerialOnHand, dataInWard?.tblInventoryTransactionModel.sourceType]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Hàm tính lệch serial trong phiếu nhập kho và sửa phiếu nhập kho
  const handleMismatch = async () => {
    let hasUnmatchedItems = false;
    const handelBill =
      handeSupplierRquest || sourceType === "NHAP_HANG_NCC"
        ? dataOrderDetailIn?.inventoryTransactionRequestSourceModels
        : dataInWard?.inventoryTransactionRequestSourceModels;

    dataSerialOnHand.forEach((serialOnHand) => {
      const primaryQuantity = serialOnHand.primaryQuantity ?? 0;

      const matchingItem = handelBill?.find((item) =>
        sourceType === "NHAP_HANG_NCC"
          ? item.itemCode === serialOnHand.itemCode
          : item.serial === serialOnHand.serial
      );

      if (matchingItem) {
        const difference = matchingItem.primaryQuantity - primaryQuantity;
        if (difference !== 0) {
          hasUnmatchedItems = true;
        }
      }
    });

    const unmatchedItems = handelBill?.filter(
      (itemInWard) =>
        !dataSerialOnHand.some(
          (serialOnHand) => serialOnHand.serial === itemInWard.serial
        )
    );

    if (unmatchedItems?.length) {
      await openCofirmModal(unmatchedItems);
    }

    return hasUnmatchedItems;
  };

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },

      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 30,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 35,
      },
      {
        accessorKey: "itemName",
        header: "Tên SP",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "primaryUomName",
        header: "ĐVT1",
        size: 30,
        Cell: ({ row }) =>
          row?.original?.primaryUomName ? (
            <Text size="sm" w={300}>
              {row.original.primaryUomName}
            </Text>
          ) : (
            row?.original.primaryUomCode
          ),
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: ({ row }) => row?.original.primaryQuantity,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUomName",
        header: "ĐVT2",
        size: 30,
      },
      {
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        header: "Thành tiền",
        size: 30,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 150,
        Cell: ({ renderedCellValue, row }) =>
          handeSupplierRquest ? (
            <Select
              searchable
              flex={1}
              clearable
              disabled={row.original.lineType === "KM"}
              data={dataSerialStatus}
              onClick={() => {
                if (dataSerialStatus.length === 0) {
                  setDataSerialStatus([]);
                }
              }}
              onChange={(value) => {
                const selectedOption = dataSerialStatus.find(
                  (option) => option.value === value
                );
                if (selectedOption) {
                  handleChangeStatus(
                    selectedOption.value,
                    row,
                    selectedOption.label
                  );
                }
              }}
              onBlur={() => {
                table.setEditingCell(null);
              }}
            />
          ) : (
            <>{renderedCellValue}</>
          ),
      },
      {
        header: "Ghi chú",
        size: 150,
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                disabled={Detail}
                color="red"
                onClick={async () => {
                  deleteItemSerial(
                    handeSupplierRquest
                      ? row.original.serial
                      : row.original.serial
                  );
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataOutWard.tblItemTransactionHeaderCommand.sourceType, dataSerialStatus]
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      ...(sourceType === "NHAP_HANG_NCC"
        ? [
            {
              accessorKey: "itemCode",
              header: "Mã hàng",
              size: 30,
              Cell: ({ row }: any) => (
                <Text
                  onClick={() => {
                    setHandleItemCode(true);
                    setKeySearch(row.original.itemCode);
                    setSerial("");
                  }}
                  size="sm"
                  style={{ cursor: "pointer" }}
                  w={300}
                >
                  {row.original.itemCode}
                </Text>
              ),
            },
          ]
        : [
            {
              accessorKey: "itemCode",
              header: "Mã hàng",
              size: 30,
            },
          ]),
      ...(sourceType === "NHAP_HANG_NCC"
        ? []
        : [
            {
              accessorKey: "serial",
              header: "Serial",
              size: 35,
            },
          ]),
      {
        accessorKey: "itemName",
        header: "Tên SP",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "primaryUom",
        header: "ĐVT1",
        size: 30,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUom",
        header: "ĐVT2",
        size: 30,
      },
      {
        accessorKey: "subPrice",
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        accessorKey: "totalAmount",
        header: "Thành tiền",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 &&
                (
                  Number(row.original.soPrice1) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "itemStatus",
        header: "Tình trạng",
        size: 100,
      },
      {
        header: "Ghi chú",
      },
      {
        accessorKey: "sldanhap",
        header: "SL đã nhập",
        Cell: ({ row }) => {
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {row.original.scannedQuantity || 0}
            </Text>
          );
        },
        size: 30,
      },
      {
        accessorKey: "deviated",
        header: "Lệch",
        Cell: ({ row }) => {
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {(row.original.scannedQuantity || 0) -
                (row.original.primaryQuantity || 0)}
            </Text>
          );
        },
        size: 30,
      },
    ],
    [dataSerialOnHand, dataInWard?.tblInventoryTransactionModel.sourceType]
  );

  const table = useMantineReactTable({
    columns,
    data: dataSerialOnHand,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    renderTopToolbarCustomActions: () => (
      <Group align="center" justify="space-between" w={"100%"} ref={headerRef}>
        <Flex gap={20}>
          <Box>
            {handeSupplierRquest || sourceType === "NHAP_HANG_NCC"
              ? MenuDataOutWart()
              : TextTransactionHeader()}
          </Box>
          <Box>
            {handeSupplierRquest || sourceType === "NHAP_HANG_NCC" ? null : (
              <Button
                leftSection={<IconPlus size={14} />}
                size="xs"
                w={200}
                onClick={handleAddProduct}
                disabled={
                  serial?.length < 3 ||
                  dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
                }
              >
                Thêm barcode
              </Button>
            )}
          </Box>
          <Box>
            {sourceType === "NHAP_HANG_NCC"
              ? TextTransactionHeader()
              : MenuDataOutWart()}
          </Box>
          <Box>
            {sourceType === "NHAP_HANG_NCC" ? (
              <Button
                onClick={() => {
                  handleSerialInput(serial);
                }}
                size="xs"
                w={200}
                disabled={Boolean(serial?.length < 3)}
                leftSection={<IconPlus size={14} />}
              >
                Thêm
              </Button>
            ) : null}
          </Box>
        </Flex>
        <Flex align="center" pr={20} gap={15}>
          <Text fw={700}>Số lượng:</Text>
          <Text c="red" fw={700}>
            <NumberFormatter
              value={dataSerialOnHand?.length || 0}
              thousandSeparator
            />
          </Text>
        </Flex>
      </Group>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["action", "deviated"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height * 0.55, minHeight: height * 0.55 },
    },
    mantineTableProps: {
      striped: true,
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    enableColumnPinning: true,

    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableOrderDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data:
      handleDetailEdit || handeSupplierRquest
        ? dataOrderDetailIn?.inventoryTransactionRequestSourceModels
        : handleAdjust || handleWareFinished || handleWarehouse
        ? dataInWard?.inventoryTransactionRequestSourceModels ?? []
        : dataOrderDetail?.inventoryTransactionRequestSourceModels ?? [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["index", "mrt-row-select"],
        right: ["sldanhap", "deviated"],
      },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.55,
        minHeight: height * 0.55,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
        // pointerEvents: "none",
        // userSelect: "none",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    enableColumnPinning: true,
    mantineTableProps: {
      striped: true,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useHotkeys([
    [
      "ctrl+S",
      () => {
        handleCreateInWard("OPEN");
      },
    ],
    [
      "ctrl+Q",
      () => {
        if (dataSerialOnHand.some((item) => !item.type)) {
          handleCreateInWard("LOCK", true);
        } else {
          NotificationExtension.Fails("Chưa có sản phẩm để xuất kho");
          return;
        }
      },
    ],
  ]);

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>
      <Box
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        <PanelGroup direction="horizontal" id="group">
          <Panel defaultSize={35} minSize={24} maxSize={40}>
            <Fieldset h={height + 180} legend={"Thông tin chung"} p={5}>
              <WarehoseEntry
                descriptionValueIn={descriptionValueIn}
                roleName={userInfo?.roleName || ""}
                fullName={userInfo?.fullName || ""}
                searchSourceCode={searchSourceCode}
                data={dataInWard}
                dataSourch={
                  dataOutWard.tblItemTransactionHeaderCommand.sourceType
                }
                disible={Detail}
                responseStatus={responseStatus}
                onInputChange={handleInputChange}
                onBranchChange={handleBranchChange}
                onInventory={handleOnInventory}
                valueVoucherCode={changeVoucherCode}
                isHasCode={!!sourceCode}
              />
            </Fieldset>
          </Panel>
          <PanelResizeHandle id="resize-handle" />
          <Panel defaultSize={65} minSize={35} maxSize={85}>
            <Box>
              <Fieldset p={10} pb={0} legend={"Bên nhận"}>
                <MantineReactTable table={table} />
              </Fieldset>
              <Fieldset p={10} pb={0} legend={"Bên xuất"}>
                <MantineReactTable table={tableOrderDetail} />
              </Fieldset>
            </Box>
          </Panel>
        </PanelGroup>
      </Box>
    </Box>
  );
};
export default WarehouseInWardPlus;
