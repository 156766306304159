import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Grid,
  Input,
  NumberFormatter,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowNarrowRight,
  IconFileExport,
  IconPackageExport,
  IconReceipt,
  IconSearch,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { modals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import { repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import InputSearch from "../../../common/InputSearch/InputSearch";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import DetailSupplierSlip from "./DetailSupplierSlip.tsx";

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

interface InvoiceReceiptsPaymentModel {
  orderNumber: string;
  orderDate: string; // Use string if the date is in ISO format, or use Date if you parse it.
  totalAmount: number;
  amountPaid: number;
  amountRe: number;
  allocatedAmount: number;
  notes: string | null;
}

interface dataItemDetail {
  id: string;
  maCty: string;
  maCt: string;
  maNCC: string;
  tenNCC: string;
  nguoiGd: string;
  maHttt: string;
  tenHttt: string;
  tkPt: string | null;
  dienGiai: string | null;
  soSeri: string | null;
  soCt: string;
  ngayCt: string; // Date string in ISO format
  ngayLct: string; // Date string in ISO format
  ngayHd: string; // Date string in ISO format
  soHd: string | null;
  maTtPo: string;
  hanCk: number;
  tlCk: number;
  hanTt: number;
  lsQh: number;
  maNt: string;
  tyGia: number;
  tTienNt0: number;
  tTien0: number;
  tCpNt: number;
  tCp: number;
  tTienNt: number;
  tTien: number;
  tThueNt: number;
  tThue: number;
  tTienCkNt: number;
  tTienCk: number;
  tTienCkDhNt: number;
  tTienCkDh: number;
  tkCk: string;
  tGg: number;
  tGgNt: number;
  tTt: number;
  tTtNt: number;
  tSoLuong: number;
  suaTien: string;
  suaThue: string;
  taoTuDh: string;
  ghiChu: string | null;
  trangThai: string;
  ssNcc: string | null;
  hdGtgt: string;
  tenHdGtgt: string;
  noHd: string;
  khongLayHd: string;
  barcode: string;
  httt: string;
  maBp: string;
  soCtKh: string | null;
  soCtDhm: string | null;
  tenKl: string | null;
  nguoiDuyetPo1: string;
  tenNguoiDuyetPo1: string;
  ngayDuyetPo1: string; // Date string in ISO format
  duyetPo2: string;
  nguoiDuyetPo2: string;
  tenNguoiDuyetPo2: string;
  ngayDuyetPo2: string; // Date string in ISO format
  nguoiHuyDuyetPo2: string | null;
  tenNguoiHuyDuyetPo2: string | null;
  ngayHuyDuyetPo2: string; // Date string in ISO format
}

interface DataList {
  data: dataItemDetail[];
  totalCount: number;
}

export const SupplierSlipImport = () => {
  //#region  table
  //data and fetching state
  const [data, setData] = useState<dataItemDetail[]>([]);
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);

  const [search, setSearch] = useState({
    keySearch: "",
    customerId: "",
    startDate: "",
    endDate: "",
    codefund: "",
    Manv: "",
    accountfund: "",
    deartment: "",
    branch: "",
    type: "",
    workplace: "",
    creator: "",
    Type: 0,
  });

  const sizeGrid = { base: 6, sm: 2, md: 2, lg: 2, xl: 2 };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        KeySearch: search.keySearch,
        MaNCC: search.Manv,
      };

      let sortingQuery = "";
      if (sorting && sorting.length > 0) {
        const { id, desc } = sorting[0] || {};
        if (id) {
          sortingQuery = `&sort=${encodeURIComponent(id)}&isOrder=${desc}`;
        }
      }

      const url =
        Object.entries(queryParams)
          .reduce((acc, [key, value]) => {
            if (value !== undefined && value !== "") {
              return `${acc}&${key}=${encodeURIComponent(value)}`;
            }
            return acc;
          }, "/api/v1/ImportBill/get-list?Type=1")
          .replace("?&", "?") + sortingQuery;

      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        setData(response?.data);
        setRowCount(response?.totalCount);
        table.resetRowSelection();
      } else {
        setData([]);
        setRowCount(0);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const openModal = (id: string) =>
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chi tiết phiếu nhà cung cấp!</Title>
        </>
      ),
      size: "90%",
      children: <DetailSupplierSlip id={id} />,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        enableResizing: false,
        Cell: ({ row }) => {
          return <>{row.index + 1}</>;
        },
        size: 70,
      },
      {
        accessorKey: "soCt",
        header: "Số chứng từ",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              style={{ cursor: "pointer" }}
              onDoubleClick={() => openModal(row.original.id)}
              variant="filled"
              radius="xl"
            >
              {renderedCellValue === null
                ? "Không xác định"
                : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        size: 120,
      },
      {
        accessorKey: "plNhapTra",
        header: "Loại phiếu",
        size: 230,
        Cell: ({ row }: { row: { original: { plNhapTra?: number } } }) => {
          const { plNhapTra } = row?.original || {};

          const statusMap: Record<number, { color: string; label: string }> = {
            1: { color: "blue", label: "Nhập hàng nhà cung cấp" },
            3: { color: "violet", label: "Nhập khác" },
          };

          const { color = "gray", label = "Không xác định" } =
            statusMap[plNhapTra!] || {};

          return (
            <Badge variant="default" color={color}>
              {label}
            </Badge>
          );
        },
      },
      {
        accessorKey: "maNCC",
        header: "Mã nhà cung cấp",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={600} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "tenNCC",
        header: "Tên nhà cung cấp",
        Cell: ({ renderedCellValue }) => {
          return (
            <Text fw={600} style={{ whiteSpace: "normal" }}>
              {renderedCellValue}
            </Text>
          );
        },
        size: 300,
      },
      {
        accessorKey: "ttNhapHang",
        header: "Tình trạng nhập hàng",
        Cell: ({ renderedCellValue }) => {
          const payerType = renderedCellValue;
          let badgeColor;
          let text;

          switch (payerType) {
            case "HOAN_THANH":
              badgeColor = "green";
              text = "Hoàn thành";
              break;
            case "CHUA_NHAP":
              badgeColor = "red";
              text = "Chưa nhập";
              break;
            case "DA_NHAP":
              badgeColor = "blue";
              text = "Đã nhập";
              break;
            case "CHUA_XUAT":
              badgeColor = "gray";
              text = "Chưa xuất";
              break;
            case "DANG_NHAP":
              badgeColor = "orange";
              text = "Đang nhập";
              break;
            default:
              badgeColor = "dark";
              text = "Không xác định";
          }

          return (
            <Badge
              variant="light"
              bd={`1px solid ${badgeColor}`}
              color={badgeColor}
            >
              {text}
            </Badge>
          );
        },
      },
      {
        accessorKey: "maCty",
        header: "Mã chi nhánh",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            style={{ cursor: "pointer" }}
            variant="light"
            radius="xl"
            color="violet"
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "id",
        header: "ID giao dịch",
        Cell: ({ renderedCellValue }) => {
          return <Text fw={600}>{renderedCellValue}</Text>;
        },
      },

      {
        accessorKey: "ngayCt",
        header: "Ngày chứng từ",
        Cell: ({ renderedCellValue }: any) => {
          return <Text>{formatDateTransfer(renderedCellValue)}</Text>;
        },
      },
      {
        accessorKey: "httt",
        header: "Hình thức thanh toán",
        Cell: ({ renderedCellValue }) => {
          return <Text fw={600}>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "nguoiGd",
        header: "Người giao dịch",
        Cell: ({ renderedCellValue }) => {
          return <Text fw={600}>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "tTien0",
        header: "Tổng tiền ",
        size: 150,
        Cell: ({ renderedCellValue }) => (
          <Text fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "tTt",
        header: "Tổng thanh toán",
        size: 150,
        Cell: ({ renderedCellValue }) => (
          <Text fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableResizing: false,
        size: 100,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) =>
          row?.original.plNhapTra && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Tooltip label={"Tạo phiếu nhập"}>
                <ActionIcon
                  variant="light"
                  disabled={
                    row.original.ttNhapHang === "HOAN_THANH" ||
                    row.original.ttNhapHang === "DANG_NHAP"
                  }
                  aria-label="Request"
                  color="blue"
                  onClick={async () => {
                    navigate(`/warehouse-config/create-warehouse-in-ward`, {
                      state: {
                        branchCode: row.original.maCty,
                        sourceType:
                          row.original.plNhapTra === 1
                            ? "NHAP_HANG_NCC"
                            : "NHAP_KHAC",
                        sourceCode: row.original.id,
                      },
                    });
                  }}
                >
                  <IconArrowNarrowRight size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            </Box>
          ),
      },
    ],
    []
  );

  const table = useMantineReactTable<any>({
    columns,
    data,
    enableColumnResizing: true,

    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false,
    enableColumnActions: false,
    // getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["index", "soCt", "mrt-row-select"],
        right: ["tTien0", "tTt", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    // enableExpanding: true,
    // getSubRows: (originalRow) => originalRow.orderDetail,
    mantineTableProps: {
      striped: false,
    },
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (pagination.pageIndex === 0 && data.length < pagination.pageSize) {
      fetchData();
    } else {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  console.log(data, "sdfsdfdsf");

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <_breadcrumb></_breadcrumb>
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
            <Grid>
              <Grid.Col span={sizeGrid}>
                <InputSearch
                  width="100%"
                  placeholder="Từ khoá"
                  onChange={(value) => {
                    handleChangeSearchValue(value, "keySearch");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Mã nhà cung cấp"
                  type="text"
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "Manv");
                  }}
                  leftSection={<IconSearch color="#15aabf" />}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
