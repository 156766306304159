import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { _localization } from "../../../config/location";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Input,
  Menu,
  rem,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { green } from "../../../const/variables";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import {
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconFileExport,
  IconPlus,
  IconSearch,
  IconTimeDuration15,
} from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { DatePickerInput } from "@mantine/dates";
import { modals } from "@mantine/modals";
import CreateProductWarrantyHistory from "./CreateProductWarrantyHistory";
import EditProductWarrantyHistory from "./EditProductWarrantyHistory";
import { IconSquareX } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { ProductWarrantyHistoryModel } from "../../../model/PurchaseHistory";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

const ProductWarrantyHistory = () => {
  const navigate = useNavigate();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<ProductWarrantyHistoryModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const sizeGrid = { base: 6, sm: 3, md: 3, lg: 3, xl: 1.7 };
  //Form Search
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      StartTime: "",
      EndTime: "",
    },
  });

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }
    if (formSeachInput.getValues().StartTime) {
      url += `&startDate=${formSeachInput.getValues().StartTime}`;
    }

    if (formSeachInput.getValues().EndTime) {
      url += `&endDate=${formSeachInput.getValues().EndTime}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblDmWarrantyHistory/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<ProductWarrantyHistoryModel>[]>(
    () => [
      {
        accessorKey: "branchCode",
        header: "Mã đơn vị",
        size: 120,

        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge
              variant="dot"
              radius="xs"
              // onDoubleClick={() => handleCheckView(row)}
              style={{
                cursor: "pointer",
              }}
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        header: "Khách hàng",
        id: "Khachhang",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              backgroundColor: "#f1f3f5",
            },
          };
        },
        columns: [
          {
            accessorKey: "name",
            enableColumnActions: false,
            header: "Tên",
            Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
          },
          {
            accessorKey: "phone",
            enableColumnActions: false,
            header: "Số điện thoại",
            Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
          },
        ],
      },
      {
        accessorKey: "itemCode",
        header: "Mã Vật tư",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "nameItem",
        header: "Tên vật tư",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "replaceItemCode",
        header: "Mã vật tư thay thế",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "replaceItemName",
        header: "Tên vật tư thay thế",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "replaceSerial",
        header: "Serial thay thế",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "warrantyDate",
        header: "Ngày nhận bảo hành (1)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "vendorDate",
        header: "Ngày nhận từ nhà cung cấp (2)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        header: "Nhà cung cấp nhập",
        id: "MaNCC",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              backgroundColor: "#f1f3f5",
            },
          };
        },
        columns: [
          {
            accessorKey: "vendorCode",
            enableColumnActions: false,
            header: "Mã nhà cung cấp",
            Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
          },
          {
            accessorKey: "vendorName",
            enableColumnActions: false,
            header: "Tên nhà cung cấp",
            Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
          },
        ],
      },
      {
        accessorKey: "warrantyReturnDate",
        header: "Ngày trả bảo hành",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "warrantyAddDate",
        header: "Ngày cộng bảo hành (2-1)",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        Cell: ({ row }) => (
          <>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* <Tooltip label="Chi tiết">
                      <ActionIcon variant="light" aria-label="Settings" color="cyan">
                        <IconEye size={20} stroke={1.5} />
                      </ActionIcon>
                    </Tooltip> */}
              <Tooltip
                label="Cập nhật"
                onClick={() => openModalCreate("edit", row.original.id)}
              >
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="orange"
                >
                  <IconEdit size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip
                label="Xóa"
                onClick={() => {
                  onhandleDelete(row.original.id);
                }}
              >
                <ActionIcon variant="light" aria-label="Settings" color="red">
                  <IconSquareX size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            </Box>
          </>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableColumnResizing: true,

    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginBottom: 5,
        display: "none",
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "branchCode"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    renderRowActions: ({ row }) => {
      return (
        <>
          <Flex>
            <Button
              size="xs"
              color="blue"
              variant="outline"
              onClick={() => {
                // handleEdit(row);
              }}
            >
              Xem
            </Button>
            <Button
              size="xs"
              color="red"
              variant="outline"
              onClick={() => {
                // handleDelete(row);
              }}
            >
              Xóa
            </Button>
          </Flex>
        </>
      );
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  const openModalCreate = (type: string, id?: number) => {
    if (type === "create") {
      modals.openConfirmModal({
        title: <Title order={5}>Thêm mới lịch sử sản phẩm bảo hành</Title>,
        children: <CreateProductWarrantyHistory fetchData={fetchData} />,
        size: "auto",
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    } else {
      modals.openConfirmModal({
        title: <Title order={5}>Chỉnh sửa lịch sử sản phẩm bảo hành</Title>,
        children: (
          <EditProductWarrantyHistory idEdit={id!} fetchData={fetchData} />
        ),
        size: "auto",
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    }
  };
  const onhandleDelete = (id: number) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xóa lịch sử sản phẩm bảo hành!</Title>
        </>
      ),
      size: "auto",
      children: (
        <Box size={"auto"}>
          <Text size="18px" mt={5}>
            Bạn có chắc chắn muốn xóa lịch sử sản phẩm bảo hành này ?
          </Text>
          <Group justify="center" mt="lg">
            <Button
              type="button"
              color="gray"
              onClick={() => modals.closeAll()}
              leftSection={<IconX size={18} />}
            >
              Thoát
            </Button>
            <Button
              type="button"
              color="red"
              onClick={() => handleDelete(id)}
              leftSection={<IconCheck size={18} />}
            >
              Xóa
            </Button>
          </Group>
        </Box>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  const handleDelete = async (id: number) => {
    setIsLoading(true);
    setIsRefetching(true);
    try {
      const response = await repositoryMdm.post(
        "/api/v1/TblDmWarrantyHistory/delete",
        [id]
      );
      if (response && response.success) {
        NotificationExtension.Success("Xóa thành công");

        setTimeout(() => {
          modals.closeAll();
          fetchData();
        }, 1000);
      }
    } catch (error) {
      NotificationExtension.Fails("Xóa thất bại");
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (190 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        style={{
          border: "1px solid #dee2e6",
          padding: "5px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
        <div onClick={() => openModalCreate("create")}>
          <Button variant="outline" leftSection={<IconPlus size={15} />}>
            Thêm mới
          </Button>
        </div>
      </Flex>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          // marginTop: "10px",
        }}
      >
        <Grid style={{ width: "100%" }} mt={10}>
          <Grid.Col span={sizeGrid}>
            <Input
              size="sm"
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              key={formSeachInput.key("keySearch")}
              {...formSeachInput.getInputProps("keySearch")}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  fetchData();
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày bắt đầu"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
              onChange={(date) => {
                formSeachInput.setFieldValue(
                  "StartTime",
                  formatDateNotTimeZone(date) || ""
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              size="sm"
              placeholder="Chọn ngày kết thúc"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 size={20} color="#15aabf" />}
              onChange={(date) => {
                formSeachInput.setFieldValue(
                  "EndTime",
                  formatDateNotTimeZone(date) || ""
                );
              }}
              clearable
            />
          </Grid.Col>

          <Grid.Col span={sizeGrid}>
            <Button
              type="submit"
              leftSection={<IconSearch size={20} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>{" "}
          </Grid.Col>
        </Grid>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ProductWarrantyHistory;
