import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { modals } from "@mantine/modals";
import {
  IconCalendar,
  IconEdit,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateNotTimeZone } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import "../../../Styles/tab.component.css";
import CreateWarehouseLocationList from "./CreateWarehouseLocationList";
import DeleteWarehouseLocation from "./DeleteWarehouseLocation";
import EditWarehouseLocation from "./EditWarehouseLocation";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";

const WarehouseLocationList = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<InventoryPosition[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<Warehouse[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState({
    keySearch: "",
    startDate: "",
    endDate: "",
    maViTriList: "",
    maKhoList: "",
    statusName: "",
  });
  type InventoryPosition = {
    id: number;
    invId: number;
    invCode: string;
    subInvId: number;
    subInvCode: string;
    positionCode: string;
    positionName: string;
    active: boolean | null;
    createDate: string | null;
    createBy: number | null;
    lastUpdateDate: string | null;
    lastUpdateBy: number | null;
  };
  type Warehouse = {
    att1: string | null;
    att2: string | null;
    att3: number;
    att4: number;
    att5: string | null;
    disabled: boolean;
    group: string | null;
    selected: boolean;
    text: string;
    value: string;
  };
  const handleChangeSearchValue = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };
  const DataWarehouse = async () => {
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblDmInventory/get-select`
      );
      if (response && response.success) {
        setDataWarehouse(response.data);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    DataWarehouse();
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      // {
      //   accessorKey: "invId",
      //   header: "invId",
      //   size: 50,
      // },
      // {
      //   accessorKey: "invCode",
      //   header: "Kho tổng",
      //   size: 30,
      // },
      {
        accessorKey: "id",
        header: "id",
      },
      {
        accessorKey: "subInvId",
        header: "Kho",
        Cell: ({ cell }) => {
          const subInvId = cell.getValue() as any;
          const subInventory = dataWarehouse.find(
            (item) => Number(item.value) === subInvId
          );
          return subInventory ? subInventory.text : "Không tìm thấy";
        },
      },

      // {
      //   accessorKey: "subInvCode",
      //   header: "Mã kho con",
      //   size: 30,
      // },
      {
        accessorKey: "positionCode",
        header: "Mã vị trí Kho",
      },
      {
        accessorKey: "positionName",
        header: "Tên vị trí kho",
      },
      {
        accessorKey: "active",
        header: "Trạng thái",
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "green" : "gray",
              color: "white",
              borderRadius: "50px",
              padding: "5px",
              textAlign: "center",
              width: "100px",
            }}
          >
            {cell.getValue() ? "Hoạt động" : "Không hoạt động"}
          </div>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
        Cell: ({ cell }) => {
          const createDate = cell.getValue(); // Lấy giá trị ngày tạo
          return (
            <div>
              {createDate &&
              typeof createDate === "string" &&
              createDate.trim() !== ""
                ? createDate
                : "Không xác định"}
            </div>
          );
        },
      },
      // {
      //   accessorKey: "createBy",
      //   header: "Id người Tạo",
      //   size: 30,
      // },
      {
        accessorKey: "lastUpdateDate",
        header: "Ngày Cập Nhật",
        Cell: ({ cell }) => {
          const lastUpdateDate = cell.getValue(); // Lấy giá trị ngày cập nhật
          return (
            <div>
              {lastUpdateDate &&
              typeof lastUpdateDate === "string" &&
              lastUpdateDate.trim() !== ""
                ? lastUpdateDate
                : "Không xác định"}
            </div>
          );
        },
      },
      // {
      //   accessorKey: "lastUpdateBy",
      //   header: "Id người cập nhật",
      //   size: 30,
      // },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableResizing: false,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {processTaskActionMenu(row)}
          </Box>
        ),
      },
    ],
    [data]
  );

  function processTaskActionMenu(row: MRT_Row<any>): any {
    return (
      <>
        <Tooltip label="Sửa">
          <ActionIcon variant="light" aria-label="Settings" color="yellow">
            <IconEdit size={20} onClick={() => handleEdit(row?.original.id)} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xóa">
          <ActionIcon variant="light" aria-label="Settings" color="red">
            <IconTrash
              size={20}
              onClick={() => handleDelete([row?.original.id])}
            />
          </ActionIcon>
        </Tooltip>
      </>
    );
  }
  const handleEdit = (id: number | string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa</Title>
        </>
      ),
      size: "auto",
      children: <EditWarehouseLocation id={id} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  const handleAdd = () => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Tạo mới</Title>
        </>
      ),
      size: "auto",
      children: <CreateWarehouseLocationList onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleDelete = (ids: (number | string)[]) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xóa bảng vị trí kho</Title>
        </>
      ),
      children: (
        <DeleteWarehouseLocation idItems={ids} onClose={setDeleteViewStatus} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const fetchDataWarehouse = async () => {
    setIsLoading(true);
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch?.trim()?.toLowerCase()}`;
    }
    if (search.startDate) {
      url += `&StartTime=${search.startDate}`;
    }
    if (search.endDate) {
      url += `&EndTime=${search.endDate}`;
    }
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblDmItemPosition/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataWarehouse();
  }, [deleteViewStatus, pagination]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableSorting: true,
    rowCount,
    enableColumnActions: true,
    positionToolbarAlertBanner: "bottom",
    enableColumnResizing: true,

    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Input
            style={{ flex: 1, maxWidth: "180px" }}
            placeholder="Nhập tìm kiếm"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "keySearch");
            }}
          />
          <DatePickerInput
            type="range"
            size="sm"
            placeholder="Từ ngày - Đến ngày"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            onChange={(e) => {
              handleChangeSearchValue(
                formatDateNotTimeZone(e[0]) ?? "",
                "startDate"
              );
              handleChangeSearchValue(
                formatDateNotTimeZone(e[1]) ?? "",
                "endDate"
              );
            }}
            leftSection={<IconCalendar color="#15aabf" />}
            w={250}
            clearable
          />
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchDataWarehouse();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={() => handleAdd()}
          >
            Thêm
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
      total: rowCount,
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: false,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (220 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default WarehouseLocationList;
