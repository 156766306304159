import {
  Badge,
  Box,
  Button,
  Group,
  Input,
  MultiSelect,
  MultiSelectProps,
  Text,
} from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import { IconPackage, IconSearch } from "@tabler/icons-react";
import {
  repositoryDelivery,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { _localization } from "../../../../config/location";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { ItemDataList } from "../../../../model/TblOrderHeader";

const ReportByPeriod = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<ItemDataList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [columnsChange, setColumnsChange] = useState<
    MRT_ColumnDef<ItemDataList>[]
  >([]);

  const userLogin = JSON.parse(localStorage.getItem("userLogin") || "{}");
  const [dataItemCode, setDataItemCode] = useState<SelectListItemModel[]>([]);
  //chi nhánh
  const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
    []
  );
  //Kho
  const [dataInventory, setDataInventory] = useState<SelectListItemModel[]>([]);
  //Bộ phận
  const [dataDepartment, setDataDepartment] = useState<SelectListItemModel[]>(
    []
  );

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  //Khách hàng
  const [dataCustomer, setDataCustomer] = useState<SelectListItemModel[]>([]);
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      StartTime: "",
      EndTime: "",
    },
  });

  const [search, setSearch] = useState({
    itemVt: [], //chi nhán
    maVt: [],
  });
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }
    if (search.maVt && search.maVt.length > 0) {
      search.maVt.forEach((maVt) => {
        url += `&maVt=${maVt}`;
      });
    }
    // if (search.itemVt && search.itemVt.length > 0) {
    //    search.itemVt.forEach((itemVt) => {
    //       url += `&ItemId=${itemVt}`;
    //    });
    // }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/get-list-sales-period${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };

  const DataItemCode = async (keySearch: string) => {
    try {
      let url = "";
      if (keySearch && keySearch.length > 0) {
        url += `&ItemCodes=${keySearch}`;
      }
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-list-item-by-inv?Skip=0&Take=50` +
          url
      );
      if (response && response.success) {
        const transformedData = response.data?.map((item: any) => ({
          value: item.maSp,
          label: item.maSp + " - " + item.tenSp,
        }));
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.value))
        ).map((value) =>
          transformedData.find((item: any) => item.value === value)
        );
        setDataItemCode(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<ItemDataList>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 100,
      },
      {
        accessorKey: "maVt",
        header: "Mã vật tư",
        size: 150,
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <Badge variant="light" color="blue">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tenVt",
        header: "Tên vật tư",
        size: 450,
        Cell: ({ renderedCellValue }) => (
          <div
            style={{
              whiteSpace: "normal", // Cho phép nội dung xuống dòng
              wordBreak: "break-word", // Ngắt từ nếu quá dài
              maxWidth: "500px", // Giới hạn chiều rộng
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },

      {
        accessorKey: "tenNhomPl1",
        header: "Tên nhóm phân loại 1",
        size: 200,
      },

      {
        accessorKey: "tenNhomPl2",
        header: "Tên nhóm phân loại 2",
        size: 200,
      },

      {
        accessorKey: "tenNhomPl3",
        header: "Tên nhóm phân loại 3",
        size: 200,
      },
      {
        accessorKey: "thang102024TongSl",
        header: "Tổng số lượng tháng 10/2024",
      },
      {
        accessorKey: "thang102024TongTien",
        header: "Tổng tiền tháng 10/2024",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "thang112024TongSl",
        header: "Tổng số lượng tháng 11/2024",
      },
      {
        accessorKey: "thang112024TongTien",
        header: "Tổng tiền tháng 11/2024",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "thang122024TongSl",
        header: "Tổng số lượng tháng 12/2024",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              backgroundColor: "#f1f3f5",
            },
          };
        },
      },
      {
        accessorKey: "thang122024TongTien",
        header: "Tổng tiền tháng 12/2024",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              backgroundColor: "#f1f3f5",
            },
          };
        },
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatCurrencyVND(renderedCellValue)}</>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["itemCode"],
        // right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "10px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      radius: "xl",
      size: "lg",
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (160 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataItemCode.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        <Input
          style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
          placeholder="Từ khoá"
          type="text"
          leftSection={<IconSearch color="#15aabf" />}
          key={formSeachInput.key("keySearch")}
          {...formSeachInput.getInputProps("keySearch")}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              fetchData();
            }
          }}
        />
        {/* 
          <MultiSelect
            size="sm"
            placeholder="Mã vật tư"
            clearable
            searchable
            data={dataItemCode} // Hiển thị kết quả từ API
            value={search.maVt}
            renderOption={renderMultiSelectOption}
            leftSection={<IconPackage size={20} color="#15aabf" />}
            onSearchChange={(value) => {
              if (value.length >= 1) {
                DataItemCode(value); // Gọi API với từ khóa tìm kiếm
              }
            }}
            onChange={(value) => {
              handleChangeSearchValue(value ? value : null, "maVt");
            }}
          /> */}
        <Button
          type="submit"
          leftSection={<IconSearch size={14} />}
          color="blue"
          variant="outline"
          onClick={async () => {
            await fetchData();
          }}
        >
          Tìm kiếm
        </Button>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ReportByPeriod;
