import React from "react";
import styles from "./PrintStyle.module.css";
import { IconPhone, IconWorldWww } from "@tabler/icons-react";
import { IconMail } from "@tabler/icons-react";
import { Image } from "@mantine/core";
type Props = {
  componentRef: any;
  dataPrint: any;
};
const PrintMfgWip = ({ componentRef, dataPrint }: Props) => {
  const dataTransaction = dataPrint?.tblInventoryTransactionModel;
  const dataInv = dataPrint?.inventoryTransactionSerialModels;
  return (
    <div
      ref={componentRef}
      className={styles.containerPrint}
      style={{
        height: "100%",
        marginLeft: "40px !important",
        marginRight: "30px !important",
        // marginTop: "10px !important",
        fontFamily: `"Times New Roman", Times, serif`,
      }}
    >
      <div
        // className={styles.contentMain}
        style={{
          // paddingTop: "5px",
          width: "100%",
          position: "relative",
          margin: "0 auto",
          marginRight: "20px",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1.5fr 2fr",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h6 style={{ margin: 0, fontSize: "10px", fontWeight: 600 }}>
              Công Ty Cổ Phần Đầu Tư Công Nghệ HACOM
            </h6>
            <p style={{ margin: 0, fontSize: "9px" }}>
              {/* CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà Trưng, TP. Hà Nội */}
              CN: {dataTransaction?.fromBranchAddress}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                alignItems: "center",
                fontSize: "10px",
                marginTop: "-5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <IconPhone
                  stroke={2}
                  style={{
                    width: "10px",
                  }}
                />
                <p style={{ margin: 0, fontSize: "10px" }}>19001903{" |"}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <IconMail
                  stroke={2}
                  style={{
                    width: "10px",
                  }}
                />
                <p style={{ margin: 0, fontSize: "10px" }}>
                  dichvukhachhang@hacom.vn{" |"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "3px",
                }}
                // className={styles.text}
              >
                <IconWorldWww
                  stroke={2}
                  style={{
                    width: "10px",
                  }}
                />
                <p style={{ margin: 0, fontSize: "10px" }}>Hacom.vn</p>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "15px" }}>
            <h1
              // className={styles.text}
              style={{
                fontWeight: "700",
                lineHeight: "1",
                fontSize: "20px",
                textAlign: "center",
                alignItems: "center",
                margin: 0,
              }}
            >
              PHIẾU LẮP MÁY
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              fontSize: "10px",
            }}
          >
            <p
              style={{
                margin: 0,
                lineHeight: "1.3",
              }}
            >
              <span> Số phiếu lắp máy:</span> {dataTransaction?.transactionCode}
            </p>
            <p
              style={{
                margin: 0,
                lineHeight: "1.3",
              }}
            >
              <span> Số phiếu yclm:</span> {dataTransaction?.sourceCode}
            </p>
            <p
              style={{
                margin: 0,
                lineHeight: "1.2",
              }}
            >
              <span> Số phiếu xuất lm:</span> {dataTransaction?.transactionCode}
            </p>
          </div>
        </div>

        {/* thông tin  */}
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontSize: "13px",
          }}
        >
          <p className={styles.text}>
            -Khách hàng: {dataTransaction?.attribute16}
          </p>
          <p className={styles.text}>
            -Người giao dịch: {dataTransaction?.createName}{" "}
          </p>
          <p className={styles.text}>
            -Diễn giải: {dataTransaction?.description}{" "}
          </p>
          <p className={styles.text}>-Bộ máy:{dataTransaction?.attribute17} </p>
          <p className={styles.text}>
            -Serial:{dataTransaction?.attribute13} -Lô hàng:{" "}
          </p>
          <p className={styles.text}>
            -Số phiếu yclm: {dataTransaction?.sourceCode} -Số phiếu xuất lm:
          </p>
        </div> */}
        <table
          style={{
            marginTop: "10px",
            width: "100%",
            borderCollapse: "collapse",
            // border: "1px solid black",
            marginBottom: "10px",
          }}
        >
          <thead>
            <tr>
              <th colSpan={2} style={{ width: "50%", border: "none" }}></th>
              <th colSpan={2} style={{ width: "50%", border: "none" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Khách hàng:
              </td>
              <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              >
                {" "}
                {dataTransaction?.attribute16}
              </td>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Người giao dịch:
              </td>
              <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              >
                {" "}
                {dataTransaction?.createName}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Bộ máy
              </td>
              <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              >
                {" "}
                {dataTransaction?.attribute17}
              </td>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Lô hàng
              </td>
              {/* Lô hàng chưa có */}
              <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              ></td>
            </tr>
            <tr>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Serial
              </td>
              <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              >
                {dataTransaction?.attribute13}
              </td>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                }}
              ></td>
              <td
                style={{
                  padding: "5px",
                  textAlign: "left",
                  border: "1px solid black",
                }}
              ></td>
            </tr>
            <tr>
              <td
                style={{
                  width: "16%",
                  maxWidth: "30%",
                  border: "1px solid black",
                  paddingLeft: "3px",
                }}
              >
                Ghi chú
              </td>
              <td
                style={{
                  border: "1px solid black",
                }}
                colSpan={3}
              >
                {" "}
                {dataTransaction?.description}
              </td>
            </tr>
          </tbody>
        </table>

        {/* table product */}
        <div
          // className={styles.ProductTable}
          style={{
            marginTop: "10px",
            width: "100%",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid black",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  STT
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Tên vật tư, hàng hóa
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Mã số
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Serial
                </th>
              </tr>
            </thead>
            <tbody>
              {dataInv?.map((item: any, index: number) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      width: "400px",
                    }}
                  >
                    {item?.itemName}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.itemCode}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.serial}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            style={{
              textAlign: "right",
              marginTop: "8px",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                margin: 0,
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                {" "}
                Tổng cộng:
              </span>{" "}
              {dataInv?.length || 0} sản phẩm
            </p>
          </div>
        </div>

        {/* Chữ ký */}
        <div
          style={{
            marginTop: "20px",
            pageBreakInside: "avoid",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "100px",
            }}
          >
            <p
              style={{
                fontStyle: "italic",
                margin: 0,
              }}
            >
              Ngày .......tháng .......năm ........
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              gap: "30px",
            }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontWeight: 700,
                  margin: 0,
                }}
              >
                Cán bộ KCS
              </p>
              <p
                style={{
                  fontSize: "13.5px",
                  fontStyle: "italic",
                  margin: 0,
                }}
              >
                (Ký, họ tên)
              </p>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              {" "}
              <p
                style={{
                  fontWeight: 700,
                  margin: 0,
                }}
              >
                Cán bộ phụ trách
              </p>
              <p
                style={{
                  fontSize: "13.5px",
                  fontStyle: "italic",
                  margin: 0,
                }}
              >
                (Ký, họ tên)
              </p>
            </div>
          </div>
        </div>
        <div
          className={styles.backgroudHacom}
          style={{
            position: "absolute",
            width: "100%",
            zIndex: -1,
            opacity: 0.1,
            top: "40%",
          }}
        >
          <Image
            radius="md"
            src="https://hanoicomputercdn.com/media/lib/17-10-2024/logo-hacom-since-2001_new.png"
            className={styles.logo}
            style={{
              width: "90%",
              margin: "0 auto",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PrintMfgWip;
