import {
  Tooltip,
  Text,
  Box,
  TextInput,
  NumberInput,
  Group,
  Button,
  Select,
  Badge,
  Flex,
  Menu,
  Table,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowBackUp, IconCheck } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { _localization } from "../../../config/location";
import { green } from "../../../const/variables";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { modals } from "@mantine/modals";

const InWardWarrantyVendor = ({
  back,
  dataRowProps,
  setDataRowProps,
  handleWarrantyInward,
}: {
  back: any;
  dataRowProps: any;
  setDataRowProps: any;
  handleWarrantyInward: any;
}) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [data, setData] = useState<any>(dataRowProps);
  const [dataProduct, setDataProduct] = useState<any[]>([]);

  const [opened, setOpened] = useState(false);
  const [visible, { toggle, close, open }] = useDisclosure(false);

  const checkAllFields = () => {
    return data.every(
      (item: any) =>
        item.serial &&
        item.serialNew !== null &&
        item.warrantyItemNew !== null &&
        item.serialNew !== "" &&
        item.warrantyItemNew !== ""
    );
  };

  const confirmChangeSerial = () => {
    return data.every((item: any) => item.formChange === "KHONG_DOI");
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemTransactionHeaderCode",
        header: "Số phiếu xuất BH NCC",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue}
          </Badge>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "formChange",
        header: "Hình thức đổi",
        Cell: ({ row }) => (
          <Select
            placeholder="Hình thức đổi trả"
            data={[
              { label: "Không đổi", value: "KHONG_DOI" },
              { label: "Đổi tương đương", value: "DOI_TUONG_DUONG" },
              { label: "Đổi mới", value: "DOI_MOI" },
            ]}
            defaultValue={row.original.formChange}
            onChange={(e) => {
              handleChange(row.original.serial?.toString(), {
                formChange: e ? e : "KHONG_DOI",
              });
            }}
          />
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute21",
        header: "Số phiếu nhà cung cấp",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm mới",
        Cell: ({ row }) => {
          return row.original.formChange === "KHONG_DOI" ? (
            <Text fw={500} size="12.5px">
              {row.original.itemCode}
            </Text>
          ) : (
            <Menu width={800} trigger="hover">
              <Menu.Target>
                <TextInput
                  placeholder="Mã sản phẩm mới"
                  value={row.original.itemCode}
                  onMouseEnter={() => {
                    getDataItem(
                      row.original.itemCode === null
                        ? ""
                        : row.original.itemCode
                    );
                  }}
                  onChange={(e) => {
                    const newValue = e.currentTarget.value;
                    handleChange(row.original.serial?.toString(), {
                      itemCode: newValue,
                    });
                    if (newValue.length > 3) {
                      getDataItem(row.original.itemCode);
                    }
                  }}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <Table.ScrollContainer minWidth={500} mah={480} type="native">
                  <Table striped withTableBorder>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Chọn</Table.Th>
                        <Table.Th>Mã sản phẩm</Table.Th>
                        <Table.Th>Tên sản phẩm</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {dataProduct &&
                        dataProduct.length > 0 &&
                        dataProduct?.map((item, index) => (
                          <Table.Tr key={index}>
                            <Table.Td>
                              <Button
                                variant="filled"
                                onClick={() => {
                                  handleChange(
                                    row.original.serial?.toString(),
                                    {
                                      attribute4: item.itemId,
                                      itemCode: item.itemCode,
                                    }
                                  );
                                }}
                              >
                                Chọn
                              </Button>
                            </Table.Td>
                            <Table.Td>{item.itemCode}</Table.Td>
                            <Table.Td>{item.itemName}</Table.Td>
                          </Table.Tr>
                        ))}
                    </Table.Tbody>
                  </Table>
                </Table.ScrollContainer>
              </Menu.Dropdown>
            </Menu>
          );
        },
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute5",
        header: "Serial mới",
        Cell: ({ row }) =>
          row.original.formChange === "KHONG_DOI" ? (
            <Text fw={500} size="12.5px">
              {row.original.serial}
            </Text>
          ) : (
            <TextInput
              placeholder="Serial nhập từ NCC"
              value={row.original.attribute5}
              onChange={(e) =>
                handleChange(row.original.serial?.toString(), {
                  attribute5: e.currentTarget.value.toUpperCase(),
                })
              }
            />
          ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      // {
      //   accessorKey: "attribute6",
      //   header: "Hạn BH mới",
      //   Cell: ({ row }) =>
      //     row.original.formChange === "KHONG_DOI" ? (
      //       <></>
      //     ) : (
      //       <NumberInput
      //         placeholder="Hạn BH mới"
      //         hideControls
      //         min={0}
      //         max={36}
      //         suffix=" tháng"
      //         allowNegative={false}
      //         onChange={(e) =>
      //           handleChange(row.original.serial?.toString(), {
      //             attribute6: e,
      //           })
      //         }
      //       />
      //     ),
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      // },
      {
        accessorKey: "serial",
        header: "Serial",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 100,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={275}>
            <Tooltip label={row.original.itemName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        Cell: ({ row }) => (
          <TextInput
            placeholder="Ghi chú"
            onChange={(e) =>
              handleChange(row.original.serial?.toString(), {
                note: e.currentTarget.value,
              })
            }
          />
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
    ],
    [data, dataProduct, opened]
  );

  const handleChange = (serial: string, updates: Record<string, any>) => {
    const updatedData = data.map((item: any) =>
      item.serial?.toString() === serial?.toString()
        ? {
            ...item,
            ...updates,
          }
        : item
    );
    setData(updatedData);
    setDataRowProps(updatedData);
  };

  const handleConfirmChangeSerial = () => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xác nhận thay đổi serial</Title>
        </>
      ),
      size: "auto",
      children: (
        <Box p={10}>
          <Text fw={500} size="18px">
            Bạn có chắc chắn các thông tin đổi mới hoặc đổi tương đương đã chính
            xác ? Bấm xác nhận để cập nhật đổi mới !
          </Text>
          <Flex justify={"end"} mt={15}>
            <Button
              leftSection={<IconCheck size={20} />}
              onClick={() => {
                handleWarrantyInward();
                modals.closeAll();
              }}
              color="green"
            >
              Xác nhận
            </Button>
          </Flex>
        </Box>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const getDataItem = async (keySearch: any) => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblItem/get-list?Take=25&KeySearch=${keySearch}`
    );

    if (res && res?.success) {
      const result = res.data;
      setDataProduct(result);
    }
  };

  useEffect(() => {
    getDataItem("");
  }, []);

  useEffect(() => {
    setData(
      dataRowProps.filter(
        (value: any, index: any, self: any) =>
          index ===
          self.findIndex(
            (t: any) => JSON.stringify(t) === JSON.stringify(value)
          )
      )
    );
  }, [dataRowProps]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data: data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    renderTopToolbarCustomActions: ({ table }) => <></>,
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <Box>
      <Group
        justify="space-between"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Badge
          variant="gradient"
          gradient={{ from: "blue", to: "cyan", deg: 90 }}
        >
          Nhập kho bảo hành NCC
        </Badge>
        <Flex gap="md">
          <Button
            type="button"
            color="red"
            loading={visible}
            onClick={() => back(false)}
            leftSection={!visible ? <IconArrowBackUp size={18} /> : undefined}
          >
            Quay lại
          </Button>
          <Button
            color="teal"
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
            onClick={() => {
              if (checkAllFields()) {
                if (confirmChangeSerial()) {
                  handleWarrantyInward();
                } else {
                  handleConfirmChangeSerial();
                }
              } else {
                NotificationExtension.Fails(
                  "Thông tin serial nhập về từ NCC và hạn bảo hành mới chưa đầy đủ !"
                );
                return;
              }
            }}
          >
            Lưu
          </Button>
        </Flex>
      </Group>
      <Box mt={10}>
        <MantineReactTable table={table} />
      </Box>
    </Box>
  );
};

export default InWardWarrantyVendor;
