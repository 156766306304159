"use strict";

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import { createRoot } from "react-dom/client";
import {
  IconActivity,
  IconEdit,
  IconHandStop,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import {
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { randomId } from "@mantine/hooks";
import HomeView from "../home/HomeView";
import customerFilter from "./customerFilter";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { sky_blue } from "../../const/variables";

import {
  AllCommunityModule,
  CellSelectionOptions,
  ClientSideRowModelModule,
  ColDef,
  ColGroupDef,
  DefaultMenuItem,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  ModuleRegistry,
  ValidationModule,
  createGrid,
} from "ag-grid-community";
import { AG_GRID_LOCALE_VN } from "@ag-grid-community/locale";
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import {
  AdvancedFilterModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  GetRowIdParams,
  GridChartsModule,
  IServerSideDatasource,
  IServerSideGetRowsRequest,
  MasterDetailModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowClassParams,
  RowGroupingModule,
  RowStyle,
  ServerSideRowModelModule,
  SetFilterModule,
  SparklinesModule,
  StatusBarModule,
} from "ag-grid-enterprise";
import { Badge, Button } from "@mantine/core";



ModuleRegistry.registerModules([
  ColumnsToolPanelModule,
  MenuModule,
  ServerSideRowModelModule,
  RichSelectModule,
  SetFilterModule,
  AdvancedFilterModule,
  FiltersToolPanelModule,
  MultiFilterModule,
  MasterDetailModule,
  ClientSideRowModelModule,
  ExcelExportModule,
  GridChartsModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  SparklinesModule,
  ClipboardModule,
]);

export const GridExample = () => {
  const [loading, setLoading] = useState(true);
  const gridRef = useRef<AgGridReact<any>>(null);
  const [height, setHeight] = useState(800);
  const headerRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 800;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  const containerStyle = useMemo(() => ({ width: "100%", height: height }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const fetchData = async (request: IServerSideGetRowsRequest) => {
    try {
      console.log(request);
      const queryParams = {
        Request: request,
      };

      return await repositoryPos.post<MessageResponse<any[]>>(
        "/api/v1/CreateSellItem/get-list-1",
        queryParams
      );
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getServerSideDatasource: () => IServerSideDatasource = () => {
    return {
      getRows: async (params) => {
        var response = await fetchData(params.request);
        // adding delay to simulate real server call

        if (response && response.success) {
          params.success({
            rowData: response.data,
            rowCount: response.totalCount,
          });
          setLoading(false);
        } else {
          // inform the grid request failed
          params.fail();
          setLoading(false);
        }
      },
    };
  };
  const getRowId = useCallback((params: GetRowIdParams) => {
    if (params.data.id != null) {
      return "leaf-" + randomId();
    }
    const rowGroupCols = params.api.getRowGroupColumns();
    const rowGroupColIds = rowGroupCols.map((col) => col.getId()).join("-");
    const thisGroupCol = rowGroupCols[params.level];
    return (
      "group-" +
      rowGroupColIds +
      "-" +
      (params.parentKeys || []).join("-") +
      params.data[thisGroupCol.getColDef().field!]
    );
  }, []);

  const filterParamsDefault = {
    buttons: ["clear", "reset", "apply"],
    debounceMs: 1000,
    maxNumConditions: 5,
  };
  const orderTypes = [
    { value: "1", label: "Online", color: "blue" },
    { value: "2", label: "Bán lẻ", color: "indigo" },
    { value: "3", label: "Đơn đổi trả", color: "green" },
  ];

  const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      headerName: "Thông tin đơn hàng",
      children: [
        {
          field: "orderNumber",
          headerName: "Mã đơn hàng",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
          cellRenderer: "agGroupCellRenderer",
          filter: "agTextColumnFilter",
        },
        {
          field: "orderStatus",
          headerName: "Trạng thái đơn hàng",
          //  pinned: true,
          filter: "agTextColumnFilter",
          cellRenderer: (params: CustomCellRendererProps) => {
            const orderStatus = params.value;
            let badgeColor;
            switch (orderStatus) {
              case "DON_HANG_TAM":
                badgeColor = "cyan";
                break;
              case "DON_HANG_KHOA":
                badgeColor = "green";
                break;
              case "THANH_TOAN_CHUA_HOAN_THANH":
                badgeColor = "red";
                break;
              case "pending":
                badgeColor = "yellow";
                break;
              case "DON_HANG_BAN_TAI_SHOP":
                badgeColor = "green";
                break;
              case "KHO_XUAT_HANG":
                badgeColor = sky_blue.medium;
                break;
              default:
                badgeColor = "gray";
            }
            return (
              <Badge color={badgeColor} fs={"12,5px"} fw={"bold"} radius="sm">
                {params.value !== null ? params.value : "Không HỢP LỆ"}
              </Badge>
            );
          },
        },
        {
          // call api=> values = list label, truoc khi send to api thi map lai thanh id
          field: "orderTypeId",
          headerName: "Loại đơn hàng",
          width: 220,
          filter: customerFilter,
          floatingFilterComponent: customerFilter,
          cellRenderer: (params: CustomCellRendererProps) => {
            //  console.log("render: " + randomId());
            const orderType = orderTypes.find(
              (type) => type.value === params.value?.toString()
            );

            return (
              <>
                {orderType?.color && orderType?.label ? (
                  <Badge color={orderType.color}>{orderType.label}</Badge>
                ) : (
                  <></>
                )}
              </>
            );
          },
        },

        {
          field: "orderDate",
          headerName: "Ngày đặt hàng",
          filter: "agDateColumnFilter",
          cellRenderer: (params: CustomCellRendererProps) => {
            if (
              typeof params.value === "string" ||
              params.value instanceof Date
            ) {
              return <> {params.value && formatDateTransfer(params.value)}</>;
            }
            return <></>;
          },
        },
      ],
    },
    {
      headerName: "Thông tin thanh toán",
      children: [
        {
          field: "amountTotal",
          headerName: "Tổng tiền",
          filter: "agNumberColumnFilter",
          // aggFunc: "sum",
          // enableValue: true,
          // enablePivot: true,
        },
        {
          field: "amountPaid",
          headerName: "Đã thanh toán",
          filter: "agNumberColumnFilter",
        },
        {
          field: "amountRemaining",
          headerName: "Còn lại",
          filter: "agNumberColumnFilter",
        },
        { field: "paymentStatus", headerName: "Trạng thái thanh toán" },
      ],
    },
    {
      headerName: "Thông tin khách hàng",
      children: [
        { field: "orgAddress", headerName: "Kho" },
        { field: "orgCode", headerName: "Mã kho" },
        { field: "orgName", headerName: "Chi nhánh" },
        { field: "customerName", headerName: "Tên khách hàng" },
        { field: "customerTelephoneNumber", headerName: "Số điện thoại" },
        // { field: "address", headerName: "Địa chỉ giao hàng" },
      ],
    },

    {
      headerName: "Thông tin vận chuyển",
      children: [
        { field: "shipingVendor", headerName: "Nhà vận chuyển" },
        { field: "deliveryApppointmentDate", headerName: "Ngày hẹn giao hàng" },
        { field: "deliveryStatus", headerName: "Trạng thái giao hàng" },
      ],
    },

    {
      headerName: "Thao tác",
      pinned: "right",
      filter: false,
      // width: 1520,
      children: [
        {
          headerName: "",
          field: "actions",
          //  pinned: "right",
          cellRenderer: () => (
            <div>
              <Button
                leftSection={<IconPlus size={14} />}
                color="blue"
                variant="outline"
              >
                Thêm mới
              </Button>

              <Button
                leftSection={<IconActivity size={14} />}
                color="green"
                variant="outline"
              >
                Hoạt động
              </Button>
              <Button
                leftSection={<IconHandStop size={14} />}
                color="red"
                variant="outline"
              >
                Dừng hoạt động
              </Button>
              <Button
                leftSection={<IconTrash size={14} />}
                color="red"
                variant="outline"
              >
                Xóa nhóm (đã chọn)
              </Button>
            </div>
          ),
        },
      ],
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // flex: 1,
      // minWidth: 90,
      //flex: 1,
      //  filter: true,
      // suppressHeaderMenuButton: true,
      // suppressHeaderContextMenu: true,
      autoHeaderHeight: true,
      allowedAggFuncs: ["sum", "min", "max", "avg"],
      filter: true,
      enableRowGroup: true,
      enableValue: true,
      floatingFilter: true,
      floatingFilterComponentParams: {
        showDisabledCheckboxes: true,
      },
      filterParams: filterParamsDefault,
      // checkboxSelection: true,
      //  initialWidth: 200,
      //  autoHeight:true
      // editable: true,
    };
  }, []);
  const defaultColGroupDef = useMemo<Partial<ColGroupDef>>(() => {
    return {
      headerName: "A shared prop for all Groups",
    };
  }, []);

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    var datasource = getServerSideDatasource();
    // register the datasource with the grid
    params.api!.setGridOption("serverSideDatasource", datasource);
  }, []);
  const detailCellRendererParams = {
    detailGridOptions: {
      columnDefs: [
        { field: "id" },
        { field: "itemCode" },
        { field: "itemId" },
        { field: "lineTyle" },
        { field: "primaryUomCode" },
        { field: "subInvId" },
        { field: "totalLineAmount" },
        { field: "priceFinal" },
        { field: "quality" },
      ],
      defaultColDef: {
        suppressHeaderMenuButton: true,
        suppressHeaderContextMenu: true,
        autoHeaderHeight: true,
      },
    },
    getDetailRowData: async (params: any) => {
      // supply data to the detail grid
      const data = await repositoryPos.get<MessageResponse<any[]>>(
        "/api/v1/CreateSellItem/details-order?orderNumber=" +
          params.data.orderNumber
      );
      if (data && data.data) params.successCallback(data?.data);
    },
  };
  const statusBar = useMemo(
    () => ({
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent" },
        { statusPanel: "agTotalRowCountComponent" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    }),
    []
  );
  const iconElement = () => {
    return <IconEdit />;
  };
  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (DefaultMenuItem | MenuItemDef)[] => {
      const _number = params.node?.data.orderNumber;
      var result: (DefaultMenuItem | MenuItemDef)[] = [
        {
          // custom item
          name: "Thanh toán đơn: " + _number,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          cssClasses: ["red", "bold"],
        },
        {
          // custom item
          name: "Chỉnh sửa đơn: " + _number,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          //  icon: iconElement,
          cssClasses: ["red", "bold"],
        },
        {
          // custom item
          name: "Hủy đơn: " + _number,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          cssClasses: ["red", "bold"],
        },
        "cut",
        "copy",
        "copyWithHeaders",
        "copyWithGroupHeaders",
        "paste",
        "separator",
        "chartRange",

        "export",
      ];
      return result;
    },
    [window]
  );

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact<any>
          ref={gridRef}
          loading={loading}
          suppressNoRowsOverlay
          columnDefs={columnDefs}
          defaultColGroupDef={defaultColGroupDef}
          defaultColDef={defaultColDef}
          rowModelType={"serverSide"}
          pagination={true}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          cacheBlockSize={paginationPageSize}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
          localeText={AG_GRID_LOCALE_VN}
          sideBar={true}
          enableAdvancedFilter={false}
          masterDetail={true}
          detailCellRendererParams={detailCellRendererParams}
          rowSelection={"multiple"}
          suppressAggFuncInHeader={true}
          getRowId={getRowId}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          blockLoadDebounceMillis={200}
          suppressServerSideFullWidthLoadingRow={false}
          maxConcurrentDatasourceRequests={1}
          animateRows={true}
          rowBuffer={paginationPageSize}
          purgeClosedRowNodes={true}
          pivotPanelShow={"always"}
          rowGroupPanelShow={"always"}
          statusBar={statusBar}
          enableRangeSelection
          enableCharts
          allowContextMenuWithControlKey={true}
          getContextMenuItems={getContextMenuItems}
          suppressColumnVirtualisation
          paginateChildRows={true}
          paginationAutoPageSize={true}
        />
      </div>
    </div>
  );
};
function extractKeys(mappings: Record<string, string>) {
  return Object.keys(mappings);
}
function lookupValue(mappings: Record<string, string>, key: string) {
  return mappings[key];
}
