import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Input,
  Menu,
  Select,
  SelectProps,
  TextInput,
  Tooltip,
  rem,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconCalendar,
  IconCalendarPlus,
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconLocationFilled,
  IconPlus,
  IconSearch,
  IconSettingsCheck,
  IconSettingsX,
  IconStatusChange,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DatePickerInput } from "@mantine/dates";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDMExpense } from "../../../model/TblDMExpense";
import {
  getBranchSelectMdm,
  getTblDmEmployeeSelect,
  getTblDmProcessingMethodSelect,
} from "../../../service/getSelectApi";

import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { cobalt_blue, green } from "../../../const/variables";
import CreateReceiptForm from "./CreateReceiptForm";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import DetailProductWarranty from "./DetailProductWarranty";
import EditReceiptForm from "./EditReceiptForm";
import DetailDataView from "./DetailDataView";
import { IconTransferIn } from "@tabler/icons-react";
import { CreateInWardWarranty } from "../../../model/CreateInWardWarranty";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { exportDataToExcel } from "../../../common/ExportExcelToAPI/ExportExcelToAPI";
import { BASE_API_MDM } from "../../../config";

const ReceiveTicketList = () => {
  //#region State
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMExpense[]>([]);
  const [dataTemp, setDataTemp] = useState<TblDMExpense[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [idEdit, setIdEdit] = useState(0);
  const [idDetail, setIdDetail] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [dataTblDmEmployee, setDataTblDmEmployee] = useState<any>();
  const [callApi, setCallApi] = useState(false);
  const [isKTKT, setIsKTKT] = useState(false);

  const [dataTblDmRequestStatus, setDataTblDmRequestStatus] = useState<{
    isLoad: boolean;
    data: any[];
  }>({
    isLoad: false,
    data: [],
  });
  const [dataTblProcessingMethod, setDataTblProcessingMethod] = useState<{
    isLoad: boolean;
    data: any[];
  }>({
    isLoad: false,
    data: [],
  });
  const [dataSubmitInWard, setDataSubmitInWard] =
    useState<CreateInWardWarranty>({
      sourceId: 0,
      tblItemTransactionHeaderCommand: {
        transactionId: null,
        transactionCode: null,
        transactionDate: new Date()?.toISOString(),
        subSourceId: null,
        sourceCode: null,
        sourceType: null,
        branchId: null,
        subInvId: null,
        departmentId: null,
        status: null,
        description: null,
        atribute1: null,
        atribute2: null,
        atribute3: null,
        atribute4: null,
        atribute5: null,
        atribute6: null,
        atribute7: null,
        atribute8: null,
        atribute9: null,
        atribute10: null,
        createBy:
          JSON.parse(localStorage.getItem("userLogin") || "{}")?.id || "",
        createDate: new Date().toISOString(),
        lastUpdateDate: null,
        lastUpdateBy: null,
        type: null,
        finishDate: null,
      },
      serials: [
        {
          primaryQuantity: 0,
          subQuantity: 0,
          serial: null,
          invId: 0,
          serialStatus: null,
          seritalNote: null,
          itemCode: null,
          locationId: 0,
        },
      ],
      isLock: true,
    });
  const [dataTblBranch, setDataTblBranch] = useState<any>();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  // Search
  const [search, setSearch] = useState({
    requestNum: "",
    startDate: "",
    endDate: "",
    status: "",
    keySearch: "",
    itemStatus: "",
    processingMethod: "",
    branch: "",
  });

  // Grid span
  const gridSpan = { base: 12, sm: 6, md: 3, lg: 3, xl: 3 };

  //viewState
  const [actionView, setActionView] = useState("");

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "requestNum",
        header: "Số phiếu tiếp nhận yêu cầu",
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            radius="xs"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "KHÔNG XÁC ĐỊNH" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 200,
      },
      {
        accessorKey: "transactionCode",
        header: "Số phiếu nhập kho",
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            radius="xs"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Chưa nhập kho" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "processingMethodName",
        header: "Hình thức xử lý",
        Cell: ({ row }) => (
          <Badge
            color={getColorProcessingMethod(row.original.processingMethodName)}
            radius={"sm"}
          >
            {row.original.processingMethodName}
          </Badge>
        ),
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Xử lý tại nhà", value: "Xử lý tại nhà" },
            { label: "Xử lý tại CH/CN", value: "Xử lý tại CH/CN" },
            { label: "Hãng xử lý", value: "Hãng xử lý" },
            { label: "Xử lý trực tiếp", value: "Xử lý trực tiếp" },
            { label: "Xử lý từ xa", value: "Xử lý từ xa" },
          ],
        },
        filterVariant: "select",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo phiếu",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "errorNumber",
        header: "Số phiếu tiếp nhận hàng lỗi",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="xs"
              variant="dot"
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailItem(row.original.id)}
            >
              {renderedCellValue === null
                ? "KHÔNG XÁC ĐỊNH"
                : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 200,
      },
      {
        accessorKey: "createByName",
        header: "Người tiếp nhận",
        Cell: ({ row }: any) => (
          <AvatarUtils value={row.original.createByName} />
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 225,
      },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng hẹn gặp",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "custPhone",
        header: "Số điện thoại",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ hẹn gặp",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 550,
      },
      {
        accessorKey: "attribute6",
        header: "Trạng thái phiếu",
        Cell: ({ renderedCellValue }) => (
          <Badge
            color={
              renderedCellValue === "DA_NHAP"
                ? "green"
                : renderedCellValue === "DA_TRA_KHACH"
                ? "teal"
                : renderedCellValue === "TRA_MOT_PHAN"
                ? "orange"
                : "blue"
            }
            variant="outline"
            radius={"sm"}
          >
            {renderedCellValue === "DA_NHAP"
              ? "Đã nhập kho"
              : renderedCellValue === "DA_TRA_KHACH"
              ? "Đã trả khách"
              : renderedCellValue === "TRA_MOT_PHAN"
              ? "Trả một phần"
              : "Đã tiếp nhận"}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableResizing: false,
        size: 130,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Nhập kho">
              <Menu>
                <Menu.Target>
                  <ActionIcon
                    variant="light"
                    color="green"
                    disabled={row.original.attribute6 !== "DA_TIEP_NHAN"}
                  >
                    <IconTransferIn
                      size={20}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={
                      <IconSettingsX
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                    onClick={() => {
                      setDataSubmitInWard((prev: any) => {
                        const dataPrev = prev?.tblItemTransactionHeaderCommand;
                        const dataSerialForm =
                          row.original.tblWarrantyReceiveTicketDetailModels
                            ?.filter((item: any) => item.isError === false)
                            ?.map((item: any) => ({
                              primaryQuantity: 1,
                              subQuantity: 0,
                              serial: item.serial,
                              invId: row.original.inventoryId,
                              serialStatus: "Chờ test",
                              seritalNote: null,
                              itemCode: item.itemCode,
                              locatorId: row.original.idItemPosition,
                              locationId: null,
                            }));
                        const updatedModels = {
                          ...dataPrev,
                          sourceCode: row.original.errorNumber,
                          sourceType: "NHAP_KHO_BAO_HANH",
                          branchId: row.original.branchId,
                          subInvId: row.original.inventoryId,
                        };

                        return {
                          ...prev,
                          tblItemTransactionHeaderCommand: updatedModels,
                          serials: dataSerialForm,
                          isLock: true,
                        };
                      });
                      setCallApi(true);
                      setIsKTKT(true);
                    }}
                  >
                    Chưa kiểm tra
                  </Menu.Item>
                  <Menu.Item
                    leftSection={
                      <IconSettingsCheck
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                    onClick={() => {
                      setDataSubmitInWard((prev: any) => {
                        const dataPrev = prev?.tblItemTransactionHeaderCommand;
                        const dataSerialForm =
                          row.original.tblWarrantyReceiveTicketDetailModels
                            ?.filter((item: any) => item.isError === false)
                            ?.map((item: any) => ({
                              primaryQuantity: 1,
                              subQuantity: 0,
                              serial: item.serial,
                              invId: row.original.inventoryId,
                              serialStatus: "Test lỗi",
                              seritalNote: null,
                              itemCode: item.itemCode,
                              locatorId: row.original.idItemPosition,
                              locationId: null,
                            }));
                        const updatedModels = {
                          ...dataPrev,
                          sourceCode: row.original.errorNumber,
                          sourceType: "NHAP_KHO_BAO_HANH",
                          branchId: row.original.branchId,
                          subInvId: row.original.inventoryId,
                        };

                        return {
                          ...prev,
                          tblItemTransactionHeaderCommand: updatedModels,
                          serials: dataSerialForm,
                          isLock: true,
                        };
                      });
                      setCallApi(true);
                      setIsKTKT(false);
                    }}
                  >
                    Đã kiểm tra
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Tooltip>
            <Tooltip
              label={
                row.original.attribute6 === "DA_NHAP"
                  ? "Gia hạn thêm"
                  : "Chỉnh sửa"
              }
            >
              <ActionIcon
                variant="light"
                color="yellow"
                onClick={async () => await editItem(row.original.id)}
                disabled={
                  row.original.attribute6 !== "DA_TIEP_NHAN" &&
                  row.original.attribute6 !== "DA_NHAP"
                }
              >
                {row.original.attribute6 === "DA_NHAP" ? (
                  <IconCalendarPlus
                    size={20}
                    stroke={1.5}
                  />
                ) : (
                  <IconEdit
                    size={20}
                    stroke={1.5}
                  />
                )}
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xem chi tiết">
              <ActionIcon
                variant="light"
                color="cyan"
                onClick={async () => await detailItem(row.original.id)}
              >
                <IconEye
                  size={20}
                  stroke={1.5}
                />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  function getColorProcessingMethod(value: string) {
    switch (value) {
      case "Xử lý tại nhà":
        return "lime";
      case "Hãng xử lý":
        return "green";
      case "Xử lý trực tiếp":
        return "teal";
      case "Xử lý tại CH/CN":
        return "cyan";
      case "Xử lý từ xa":
        return "orange";
      case "":
      case null:
        return "rgba(255, 255, 255, 0)";
    }
  }

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-tiep-nhan-bao-hanh.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const getAllRequestStatus = async () => {
    const url = "api/v1/TblDmRequestStatus/get-all";
    const callapi = await repositoryMdm.get<MessageResponse<any[]>>(url);
    if (callapi?.success) return callapi?.data;
    return [];
  };

  const dataRequestStatusSelect = async () => {
    try {
      const getData = await getAllRequestStatus();
      const filteredData = getData?.filter(
        (item) => item.code != null && item.name != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.code.toString(),
        label: item.name.toString(),
      }));
      setDataTblDmRequestStatus((prev) => ({
        ...prev,
        data: mappedData,
      }));
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const dataEmployeeSelect = async () => {
    try {
      const getData = await getTblDmEmployeeSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDmEmployee(
        new Map(mappedData.map((item) => [item.value, item.label]))
      );
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const dataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataTblBranch(
      getData
        ?.filter(
          (item) =>
            item.value != null && item.text != null && item.value != "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataProcessingMethodSelect = async () => {
    try {
      const getData = await getTblDmProcessingMethodSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.text,
        label: item.text,
      }));
      setDataTblProcessingMethod((prev) => ({
        ...prev,
        data: mappedData,
      }));
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const navigateKTKT = async (id: number | string) => {
    const res = await repositoryDelivery.post<MessageResponse<any>>(
      "/api/v1/TblTestingTechniqueHeader/create-from-transaction",
      { transactionId: id }
    );

    if (res && res?.success) {
      setIsKTKT(false);
    }
  };

  const handleInWardWarranty = async () => {
    const res = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Inward/create-in-ward-warranty",
      dataSubmitInWard
    );

    if (res && res?.success) {
      if (isKTKT) {
        navigateKTKT(res?.data);
      }
      setTimeout(() => {
        setDeleteViewStatus(!deleteViewStatus);
      }, 1500);
      NotificationExtension.Success("Nhập kho thành công !");
    }
  };

  const fetchData = async () => {
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim()}`;
    }

    if (search.status) {
      url += `&RequestStatus=${search.status}`;
    }

    if (search.itemStatus) {
      url += `&ItemStatus=${search.itemStatus}`;
    }

    if (search.processingMethod) {
      url += `&ProcessingMethod=${search.processingMethod}`;
    }

    if (search.branch) {
      url += `&BranchId=${search.branch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyReceiveTicket/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        await Promise.all([dataEmployeeSelect(), dataBranchSelect()]);
        setData(result);
        setDataTemp(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    startCountdown();
  };

  const handleExportData = async () => {
    let url = `/api/v1/TblWarrantyReceiveTicket/export-tbl-warranty-receive-ticket?Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim()}`;
    }

    if (search.status) {
      url += `&RequestStatus=${search.status}`;
    }

    if (search.itemStatus) {
      url += `&ItemStatus=${search.itemStatus}`;
    }

    if (search.processingMethod) {
      url += `&ProcessingMethod=${search.processingMethod}`;
    }

    if (search.branch) {
      url += `&BranchId=${search.branch}`;
    }

    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "danh_muc_phieu_tiep_nhan_hang_loi" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const ActionViews = React.useCallback(() => {
    switch (actionView) {
      case "CREATE":
        return (
          <Box h={window.innerHeight - 180}>
            <CreateReceiptForm
              onClose={setActionView}
              onSuccess={setDeleteViewStatus}
            />
          </Box>
        );
      case "EDIT":
        return (
          <Box h={window.innerHeight - 180}>
            <EditReceiptForm
              id={idEdit}
              onClose={setActionView}
              onSuccess={setDeleteViewStatus}
            />
          </Box>
        );
      case "DETAIL":
        return (
          <Box h={window.innerHeight - 180}>
            <DetailDataView
              id={idDetail}
              onClose={setActionView}
              actionView={actionView}
            />
          </Box>
        );
      default:
        return null;
    }
  }, [actionView]);

  const createItem = React.useCallback(() => {
    setActionView("CREATE");
  }, []);

  const editItem = React.useCallback((id: string | number) => {
    setActionView("EDIT");
    setIdEdit(Number(id));
  }, []);

  const detailItem = React.useCallback((id: string | number) => {
    setActionView("DETAIL");
    setIdDetail(Number(id));
  }, []);

  const optionColorsAccount: Record<
    | "Xử lý tại nhà"
    | "Hãng xử lý"
    | "Xử lý tại chỗ"
    | "Xử lý tại CH/CN"
    | "Xử lý từ xa",
    string
  > = {
    "Xử lý tại nhà": "lime",
    "Hãng xử lý": "green",
    "Xử lý tại chỗ": "teal",
    "Xử lý tại CH/CN": "cyan",
    "Xử lý từ xa": "orange",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group
      flex="1"
      gap="xs"
    >
      <Badge
        color={
          optionColorsAccount[
            option.label as
              | "Xử lý tại nhà"
              | "Hãng xử lý"
              | "Xử lý tại chỗ"
              | "Xử lý tại CH/CN"
              | "Xử lý từ xa"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck
          size={"14px"}
          style={{ marginInlineStart: "auto" }}
        />
      )}
    </Group>
  );

  const colorsItemStatus: Record<
    "Đã tiếp nhận" | "Đã nhập kho" | "Trả một phần" | "Đã trả khách",
    string
  > = {
    "Đã tiếp nhận": "blue",
    "Đã nhập kho": "green",
    "Trả một phần": "orange",
    "Đã trả khách": "teal",
  };

  const renderSelectOptionItemStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group
      flex="1"
      gap="xs"
    >
      <Badge
        color={
          colorsItemStatus[
            option.label as
              | "Đã tiếp nhận"
              | "Đã nhập kho"
              | "Trả một phần"
              | "Đã trả khách"
          ]
        }
        radius="sm"
        variant="outline"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck
          size={"14px"}
          style={{ marginInlineStart: "auto" }}
        />
      )}
    </Group>
  );

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus, pagination]);

  useEffect(() => {
    if (
      dataTblDmRequestStatus.isLoad &&
      dataTblDmRequestStatus.data?.length === 0
    )
      dataRequestStatusSelect();
  }, [dataTblDmRequestStatus.isLoad]);

  useEffect(() => {
    if (
      dataTblProcessingMethod.isLoad &&
      dataTblProcessingMethod.data?.length === 0
    )
      dataProcessingMethodSelect();
  }, [dataTblProcessingMethod.isLoad]);

  useEffect(() => {
    if (callApi) {
      handleInWardWarranty();
      setCallApi(false);
    }
  }, [callApi]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (140 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderDetailPanel: ({ row }) => (
      <DetailProductWarranty
        dataDetail={row.original.tblWarrantyReceiveTicketDetailModels}
      />
    ),
    enableExpandAll: false,
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },

    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "errorNumber"],
        right: ["attribute6", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      pagination,
      showAlertBanner: isError,
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <>
      {(!actionView.isNullOrEmpty() && <ActionViews />) || (
        <>
          <div
            ref={headerRef}
            style={{ border: "1px solid #dee2e6", padding: "10px" }}
          >
            <Flex
              w={"100%"}
              justify={"space-between"}
              wrap={"wrap"}
              align={"center"}
            >
              <_breadcrumb></_breadcrumb>
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  leftSection={<IconPlus size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    createItem();
                  }}
                >
                  Thêm mới
                </Button>
                <Menu
                  shadow="md"
                  width={200}
                >
                  <Menu.Target>
                    <Button
                      // size="xs"
                      rightSection={
                        <IconCaretDown
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                    >
                      Chức năng
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      leftSection={
                        <IconFileExport
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                      onClick={() => handleExportData()}
                    >
                      Export Excel
                    </Menu.Item>
                    <Tooltip label={"Chức năng đang update"}>
                      <Menu.Item
                        leftSection={
                          <IconFileUpload
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                      >
                        Import Excel
                      </Menu.Item>
                    </Tooltip>
                    <Menu.Item
                      leftSection={
                        <IconEye style={{ width: rem(14), height: rem(14) }} />
                      }
                      onClick={() => detailItem(selectIds[0])}
                      disabled={selectIds.length !== 1}
                    >
                      Xem chi tiết
                    </Menu.Item>

                    <Menu.Item
                      leftSection={
                        <IconX style={{ width: rem(14), height: rem(14) }} />
                      }
                    >
                      Đóng
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Flex>
            <Grid
              w={"100%"}
              mt={10}
            >
              <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
                <DatePickerInput
                  size="sm"
                  placeholder="Từ ngày"
                  leftSection={
                    <IconCalendar
                      size={20}
                      style={{ color: cobalt_blue.base }}
                    />
                  }
                  locale="vi"
                  valueFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleChangeSearchValue(
                      formatDateNotTimeZone(e) ?? "",
                      "startDate"
                    );
                  }}
                  clearable
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
                <DatePickerInput
                  size="sm"
                  placeholder="Đến ngày"
                  leftSection={
                    <IconCalendar
                      size={20}
                      style={{ color: cobalt_blue.base }}
                    />
                  }
                  locale="vi"
                  valueFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleChangeSearchValue(
                      formatDateNotTimeZone(e) ?? "",
                      "endDate"
                    );
                  }}
                  clearable
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
                <Select
                  placeholder="Chi nhánh"
                  searchable
                  clearable
                  leftSection={
                    <IconBrandBootstrap
                      size={20}
                      style={{ color: cobalt_blue.base }}
                    />
                  }
                  data={dataTblBranch}
                  onChange={(e) =>
                    handleChangeSearchValue(e?.toString() ?? "", "branch")
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
                <Select
                  placeholder="Hình thức xử lý"
                  type="text"
                  w={"100%"}
                  clearable
                  searchable
                  leftSection={
                    <IconLocationFilled
                      size={20}
                      style={{ color: cobalt_blue.base }}
                    ></IconLocationFilled>
                  }
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataTblProcessingMethod.data}
                  onClick={() =>
                    setDataTblProcessingMethod((prev) => ({
                      ...prev,
                      isLoad: true,
                    }))
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(
                      e?.toString() ?? "",
                      "processingMethod"
                    );
                  }}
                  renderOption={renderSelectOptionStatus}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
                <Select
                  placeholder="Trạng thái phiếu"
                  data={[
                    { label: "Đã tiếp nhận", value: "DA_TIEP_NHAN" },
                    { label: "Đã nhập kho", value: "DA_NHAP" },
                    { label: "Trả một phần", value: "TRA_MOT_PHAN" },
                    { label: "Đã trả khách", value: "DA_TRA_KHACH" },
                  ]}
                  leftSection={
                    <IconStatusChange
                      size={20}
                      style={{ color: cobalt_blue.base }}
                    />
                  }
                  onChange={(e) =>
                    handleChangeSearchValue(e?.toString() ?? "", "itemStatus")
                  }
                  renderOption={renderSelectOptionItemStatus}
                />
              </Grid.Col>

              <Grid.Col span={{ base: 12, md: 6, lg: 1.8 }}>
                <TextInput
                  placeholder="Nhập từ khóa tìm kiếm"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconSearch
                      size={20}
                      style={{ color: cobalt_blue.base }}
                    ></IconSearch>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 1.2 }}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={() => {
                    fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </div>
          <div>
            <MantineReactTable table={table} />
          </div>
        </>
      )}
    </>
  );
};
export default ReceiveTicketList;
