import {
  Badge,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Menu,
  rem,
  Select,
  SelectProps,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { TblDMExpense } from "../../../model/TblDMExpense";
import {
  IconBrandBootstrap,
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconFileExport,
  IconFileUpload,
  IconPackageExport,
  IconSearch,
} from "@tabler/icons-react";
import { useDebouncedState } from "@mantine/hooks";
import {
  beige,
  green,
  navy_blue,
  sky_blue,
  teal,
} from "../../../const/variables";
import { BASE_API_MDM } from "../../../config";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { DatePickerInput } from "@mantine/dates";
import { getBranchSelectMdm } from "../../../service/getSelectApi";

const DeliveryPayKT = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMExpense[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //select
  const [dataTblBranch, setDataTblBranch] = useState<ComboboxItem[]>([]);
  //search
  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    branchCode: "",
    sourceType: "",
  });

  const [keySearch, setKeySearch] = useDebouncedState<string>("", 300);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.index === -1 ? "" : row.index + 1}
          </Text>
        ),
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        enableResizing: false,
      },
      {
        accessorKey: "branchCode",
        header: "Mã chi nhánh",
      },
      {
        accessorKey: "branchName",
        header: "Tên chi nhánh",
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "assignmentDate",
        header: "Ngày phân công",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "soChungTuGoc",
        header: "Số chứng từ gốc",
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            radius="sm"
            variant="dot"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "sourceType",
        header: "Nguồn chứng từ",
        Cell: ({ row }) => (
          <Badge
            color={getColorSourceType(row.original.sourceType)}
            radius={"sm"}
          >
            {row.original.sourceType}
          </Badge>
        ),
      },
      {
        accessorKey: "code",
        header: "Số vận đơn",
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            radius="sm"
            variant="dot"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "transactionNumber",
        header: "Số giao dịch",
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            radius="sm"
            variant="dot"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "employee1",
        header: "Nhân viên giao hàng",
        Cell: ({ renderedCellValue }) => (
          <AvatarUtils
            value={renderedCellValue?.toString() ?? ""}
            sizeXS={true}
          />
        ),
      },
      {
        accessorKey: "employee2",
        header: "Nhân viên hỗ trợ",
        Cell: ({ renderedCellValue }) => (
          <AvatarUtils
            value={renderedCellValue?.toString() ?? ""}
            sizeXS={true}
          />
        ),
      },
      {
        accessorKey: "deliveryWorkName",
        header: "Công việc",
      },
      {
        accessorKey: "deliveryWorkPoint",
        header: "Điểm",
        size: 125,
      },
      {
        accessorKey: "toAddress",
        header: "Địa chỉ giao hàng",
        size: 500,
      },
      {
        accessorKey: "noteByEmp",
        header: "Ghi chú đơn hàng",
      },
      {
        accessorKey: "noteByDelivery",
        header: "Ghi chú giao hàng",
      },
      {
        accessorKey: "customerName",
        header: "Tên khách hàng",
      },
      {
        accessorKey: "telephoneNumber",
        header: "SĐT khách hàng",
      },
      {
        accessorKey: "distanceTotal",
        header: "Quãng đường",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {Number(renderedCellValue) / 1000} km
          </Text>
        ),
      },
      {
        accessorKey: "receptionTime",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "deliveryTime",
        header: "Ngày giao hàng",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "arrivalTime",
        header: "Ngày đến nơi",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "completionTime",
        header: "Ngày hoàn thành",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "transactionFinishDate",
        header: "Ngày xuất kho",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "processingDate",
        header: "Ngày KTKT",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "deliveryStatusName",
        header: "Trạng thái đơn giao hàng",
        Cell: ({ renderedCellValue }) => (
          <Badge
            color={getColorStatus(renderedCellValue?.toString() ?? "")}
            radius={"sm"}
          >
            {renderedCellValue}
          </Badge>
        ),
        size: 350,
      },
      {
        accessorKey: "tienGuiXe",
        header: "Tiền gửi xe",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {Number(renderedCellValue)?.toLocaleString()} VNĐ
          </Text>
        ),
      },
      {
        accessorKey: "debt",
        header: "Công nợ",
      },
      {
        accessorKey: "amountTotal",
        header: "Tổng tiền đơn hàng",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {Number(renderedCellValue)?.toLocaleString()} VNĐ
          </Text>
        ),
      },
      {
        accessorKey: "amountPaid",
        header: "Đã thanh toán",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {Number(renderedCellValue)?.toLocaleString()} VNĐ
          </Text>
        ),
      },
      {
        accessorKey: "attribute8",
        header: "Tổng phải thanh toán",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {Number(renderedCellValue)?.toLocaleString()} VNĐ
          </Text>
        ),
        size: 225,
      },
      {
        accessorKey: "paymentStatus",
        header: "Tình trạng thanh toán",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {getDataPaymentStatus(renderedCellValue?.toString() ?? "")}
          </Text>
        ),
        size: 225,
      },
      {
        accessorKey: "payroll",
        header: "Tính lương",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue === "1" ? "Có" : "Không"}
          </Text>
        ),
      },
      {
        accessorKey: "createByName",
        header: "Kinh doanh tạo đơn",
        Cell: ({ renderedCellValue }) => (
          <AvatarUtils
            value={renderedCellValue?.toString() ?? ""}
            sizeXS={true}
          />
        ),
        size: 200,
      },
      {
        accessorKey: "assignTime",
        header: "Thời gian phân công",
        size: 200,
      },
      {
        accessorKey: "contactTime",
        header: "Thời gian liên hệ",
      },
      {
        accessorKey: "transferTime",
        header: "Thời gian di chuyển",
        size: 200,
      },
      {
        accessorKey: "workTime",
        header: "Thời gian thực hiện công việc",
        size: 275,
      },
      {
        accessorKey: "settingTime",
        header: "Thời gian lắp cài",
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  function getColorSourceType(value: string) {
    switch (value) {
      case "Đơn hàng":
        return beige.base;
      case "Điều chuyển":
        return sky_blue.light_alt;
      case "Khác":
        return navy_blue.base;
      case "Xuất bảo hành":
        return "#7b2eda";
      case "Xuất trả khách":
        return "#c91a52";
    }
  }

  function getDataPaymentStatus(value: string) {
    switch (value) {
      case "CHUA_THANH_TOAN":
        return "Chưa thanh toán";
      case "KHONG_CAN_THANH_TOAN":
        return "Không cần thanh toán";
      case "THANH_TOAN_MOT_PHAN":
        return "Thanh toán một phần";
      case "DA_THANH_TOAN":
        return "Đã thanh toán";
    }
  }

  function getColorStatus(value: string) {
    switch (value) {
      case "Đã phân công":
        return "lime";
      case "Lập đơn giao hàng":
        return "green";
      case "Đang giao hàng":
        return "teal";
      case "Đơn hàng giao chưa thành công do Khách hàng":
        return "blue";
      case "Đơn hàng giao chưa thành công do Hacom":
        return "indigo";
      case "Hàng đã giao":
        return "violet";
      case "Hủy phân công":
        return "red";
      case "Đã đến nơi":
        return "#2bdd66";
      case "Hủy đơn":
        return "#c91a25";
      case null:
        return "rgba(0, 0, 0, 0)";
    }
  }

  const optionColorSourceType: Record<
    "Đơn hàng" | "Điều chuyển" | "Khác" | "Xuất bảo hành" | "Xuất trả khách",
    string
  > = {
    "Đơn hàng": "#E2E2B6",
    "Điều chuyển": "#6EACDA",
    Khác: "#03346E",
    "Xuất bảo hành": "#7b2eda",
    "Xuất trả khách": "#C91A52",
  };

  const renderSelectOptionSourceType: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorSourceType[
            option.label as
              | "Đơn hàng"
              | "Điều chuyển"
              | "Khác"
              | "Xuất bảo hành"
              | "Xuất trả khách"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate.length > 0) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate.length > 0) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.branchCode.length > 0) {
      url += `&BranchCode=${search.branchCode}`;
    }

    if (search.sourceType.length > 0) {
      url += `&SourceType=${search.sourceType}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/VReportDelivery/get-list-delivery${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const handleExportData = async () => {
    let url = `/api/v1/VReportDelivery/export-bao-cao-ki-thuat-giao-hang?Skip=${
      pagination?.pageIndex * pagination?.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.startDate.length > 0) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate.length > 0) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.branchCode.length > 0) {
      url += `&BranchCode=${search.branchCode}`;
    }

    if (search.sourceType.length > 0) {
      url += `&SourceType=${search.sourceType}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    const _url = BASE_API_MDM + url;
    const token = localStorage.getItem("token");

    const response = await fetch(_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }
    const blob = await response.blob();
    const urlss = window.URL.createObjectURL(blob);
    const _name = new Date().toISOString().slice(0, 10);
    let filename = "bao_cao_tinh_luong_ky_thuat_giao_hang" + _name + ".xlsx";
    const link = document.createElement("a");
    link.href = urlss;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const dataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataTblBranch(
      getData
        ?.filter(
          (item) => item.att2 != null && item.text != null && item.value != "-1"
        )
        ?.map((item) => ({
          value: item.att2,
          label: `${item.att2} - ${item.text}`,
        }))
    );
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (140 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <_breadcrumb></_breadcrumb>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() => handleExportData()}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>
            </Menu.Dropdown>
          </Menu>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Từ ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "startDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <DatePickerInput
              size="sm"
              placeholder="Đến ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Chi nhánh"
              searchable
              clearable
              data={dataTblBranch}
              onClick={() => {
                if (dataTblBranch.length === 0) {
                  dataBranchSelect();
                }
              }}
              leftSection={
                <IconBrandBootstrap size={20} style={{ color: teal.base }} />
              }
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "branchCode")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Nguồn hàng"
              data={[
                { label: "Đơn hàng", value: "Đơn hàng" },
                { label: "Điều chuyển", value: "Điều chuyển" },
                { label: "Khác", value: "Khác" },
                { label: "Xuất bảo hành", value: "Xuất bảo hành" },
                { label: "Xuất trả khách", value: "Xuất trả khách" },
              ]}
              leftSection={<IconPackageExport color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "sourceType")
              }
              clearable
              renderOption={renderSelectOptionSourceType}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              defaultValue={keySearch}
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
export default DeliveryPayKT;
