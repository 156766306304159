import {
  Box,
  Button,
  Fieldset,
  Grid,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import React, { useEffect } from "react";
import { sky_blue } from "../../../const/variables";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { DateInput, DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { PurchaseHistoryModel } from "../../../model/PurchaseHistory";
import { formatDateNotTimeZone } from "../../../common/FormatDate/FormatDate";
import { useDisclosure } from "@mantine/hooks";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
type Props = {
  setLoadData: any;
  idEdit: number;
  fetchData?: () => void;
};
const EditPurchaseHistory = ({ setLoadData, idEdit, fetchData }: Props) => {
  const [visible, { close, open }] = useDisclosure(false);

  const entity = {
    id: 0,
    branchCode: "", // Mã chi nhánh
    custCode: "", // Mã khách hàng
    custName: "", // Tên khách hàng
    itemCode: "", // Mã sản phẩm
    itemName: "", // Tên sản phẩm
    serial: "", // Serial sản phẩm
    exNumber: "", // Số xuất kho
    price: null, // Giá sản phẩm
    saleDate: "", // Ngày bán (mặc định là ngày hiện tại)
    warrantyDate: null, // Ngày bảo hành
    vendorCode: "", // Mã nhà cung cấp
    vendorName: "", // Tên nhà cung cấp
    poPrice: null, // Giá nhập
  };
  const form = useForm<PurchaseHistoryModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      branchCode: (value) => (value ? null : "Vui lòng hãy điền mã đơn vị"),
      custName: (value) => (value ? null : "Vui lòng hãy điền tên khách hàng"),
      custCode: (value) => {
        if (value && !/^\d{8,11}$/.test(value)) {
          return "Số điện thoại phải có từ 8 đến 11 chữ số!";
        }

        if (!value) {
          return "Vui lòng nhập số điện thoại!";
        }
        return null;
      },
      vendorCode: (value) =>
        value ? null : "Vui lòng hãy điền mã nhà cung cấp",
      vendorName: (value) =>
        value ? null : "Vui lòng hãy điền tên nhà cung cấp",
      itemCode: (value) => (value ? null : "Vui lòng hãy điền mã vật tư"),
      itemName: (value) => (value ? null : "Vui lòng hãy điền tên vật tư"),
      serial: (value) => (value ? null : "Vui lòng hãy điền serial"),
      exNumber: (value) => (value ? null : "Vui lòng hãy điền số phiếu xuất"),
      price: (value) => (value ? null : "Vui lòng hãy điền giá bán"),
      saleDate: (value) => (value ? null : "Vui lòng hãy chọn ngày bán"),
      warrantyDate: (value) =>
        value ? null : "Vui lòng hãy điền thời hạn bảo hành",
      poPrice: (value) => (value ? null : "Vui lòng hãy điền giá nhập mua"),
    },
  });
  const handleEditPurchaseHistory = async (value: any) => {
    open();
    try {
      const response = await repositoryMdm.post(
        `/api/v1/TblDmPurchaseHistory/update?id=${idEdit}`,
        value
      );
      if (response && response.success) {
        NotificationExtension.Success("Cập nhật lịch sử mua hàng thành công");
        setTimeout(() => {
          modals.closeAll();
          fetchData && fetchData();
        }, 1000);
      }
    } catch (error) {
      NotificationExtension.Fails("Cập nhật lịch sử mua hàng thất bại");
      setTimeout(() => {
        modals.closeAll();
      }, 1000);
      console.log("error", error);
    } finally {
      close();
    }
  };

  useEffect(() => {
    const getDataDetail = async () => {
      const response = await repositoryMdm.get(
        `/api/v1/TblDmPurchaseHistory/update?id=${idEdit}`
      );
      console.log("response", response);
      if (response && response.success) {
        form.setValues({
          ...response.data,
          saleDate: response.data.saleDate
            ? new Date(response.data.saleDate?.toString()) // Chuyển chuỗi thành Date
            : null,
        });
      } else {
        NotificationExtension.Fails("Lấy dữ liệu thất bại");
      }
    };
    getDataDetail();
  }, [idEdit]);
  return (
    <div>
      <Box
        component="form"
        mx="auto"
        w={{
          base: "300px",
          xs: "400px",
          sm: "500px",
          md: "650px",
          lg: "800px",
        }}
        onSubmit={form.onSubmit((e: any) => {
          handleEditPurchaseHistory(e);
        })}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Mã đơn vị"}
              placeholder={"Nhập mã đơn vị"}
              {...form.getInputProps("branchCode")}
              withAsterisk
            ></TextInput>
            <Fieldset legend="Khách hàng" mt={10}>
              <Grid gutter={"sm"}>
                <Grid.Col span={12}>
                  <TextInput
                    label={"Tên khách hàng"}
                    placeholder={"Nhập tên khách hàng"}
                    {...form.getInputProps("custName")}
                    withAsterisk
                  ></TextInput>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    label={"SĐT"}
                    type="number"
                    placeholder={"Nhập SĐT"}
                    {...form.getInputProps("custCode")}
                    withAsterisk
                  ></TextInput>
                </Grid.Col>
              </Grid>
            </Fieldset>
            <Fieldset legend="Nhà cung cấp nhập" mt={10}>
              <Grid gutter={"sm"}>
                <Grid.Col span={12}>
                  <TextInput
                    label={"Mã nhà cung cấp"}
                    placeholder={"Nhập mã nhà cung cấp"}
                    {...form.getInputProps("vendorCode")}
                    withAsterisk
                  ></TextInput>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    label={"Tên nhà cung cấp"}
                    placeholder={"Nhập tên nhà cung cấp"}
                    {...form.getInputProps("vendorName")}
                    withAsterisk
                  ></TextInput>
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Grid gutter={"md"}>
              <Grid.Col span={12}>
                <TextInput
                  label={"Mã vật tư"}
                  placeholder={"Nhập mã vật tư"}
                  {...form.getInputProps("itemCode")}
                  withAsterisk
                ></TextInput>
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  label={"Tên vật tư"}
                  placeholder={"Nhập tên vật tư"}
                  {...form.getInputProps("itemName")}
                  withAsterisk
                ></TextInput>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      label={"Serial"}
                      placeholder={"Nhập serial"}
                      {...form.getInputProps("serial")}
                      withAsterisk
                    ></TextInput>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label={"Số phiếu xuất"}
                      placeholder={"Nhập số phiếu xuất"}
                      {...form.getInputProps("exNumber")}
                      withAsterisk
                    ></TextInput>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  label={"Giá bán"}
                  type="number"
                  placeholder={"Nhập giá bán"}
                  {...form.getInputProps("price")}
                  withAsterisk
                ></TextInput>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid>
                  <Grid.Col span={6}>
                    <DateInput
                      size="sm"
                      label="Ngày bán"
                      placeholder="Chọn ngày bán"
                      locale="vi"
                      valueFormat="DD/MM/YYYY"
                      value={
                        form.getValues().saleDate
                          ? new Date(
                              form.getValues().saleDate?.toString() || ""
                            )
                          : undefined
                      }
                      {...form.getInputProps("saleDate")}
                      onChange={(date) => {
                        form.setFieldValue(
                          "saleDate",
                          formatDateNotTimeZone(date) || ""
                        );
                      }}
                      clearable
                      withAsterisk
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      type="number"
                      label={"Thời hạn bảo hành (tháng)"}
                      placeholder={"Thời hạn bảo hành"}
                      {...form.getInputProps("warrantyDate")}
                      withAsterisk
                    ></TextInput>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  type="text"
                  label={"Giá nhập mua"}
                  placeholder={"Nhập giá nhập mua"}
                  {...form.getInputProps("poPrice")}
                  withAsterisk
                ></TextInput>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            // loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
          >
            Quay lại
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            // loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
        </Group>
      </Box>
    </div>
  );
};

export default EditPurchaseHistory;
