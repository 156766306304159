import style from "./Printer.module.css";
import logo from "../../../assets/images/logo-hacom-2024.png";

const PrinterReturnCustomers = ({
  innerRef,
  dataPrint,
  isCheck,
}: {
  innerRef: any;
  dataPrint: any;
  isCheck?: any;
}) => {
  return (
    <div ref={innerRef} className={style.main}>
      <div className={style.header}>
        <img src={logo} alt="logo-hacom-2024" width={150} height={"auto"} />
        <div>
          <div className={style.line}>
            <h3>CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ HACOM</h3>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Chi nhánh: </p>
            <p>
              {
                JSON.parse(localStorage.getItem("userLogin") || "{}")
                  ?.branchName
              }
            </p>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Bộ phận/Phòng ban:</p>
            <p>Phòng kỹ thuật bảo hành</p>
          </div>
          <div className={style.line}>
            <div>
              <p className={style.cText}>Tel: </p>
              <p>19001903</p>
            </div>
            <div>
              <p className={style.cText}>Máy lẻ:</p>
              <p>25393</p>
            </div>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Email:</p>
            <p>baohanh@hacom.vn</p>
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.title}>
          <h2>PHIẾU XUẤT TRẢ BẢO HÀNH</h2>
          <p className={`${style.italic} ${style.mtt20}`}>
            Ngày {String(new Date().getDate()).padStart(2, "0")} tháng{" "}
            {String(new Date().getMonth() + 1).padStart(2, "0")} năm{" "}
            {new Date().getFullYear()}
          </p>
          <p className={`${style.mtt15}`}>Mã: {dataPrint?.requestNum}</p>
        </div>
        <div className={style.info}>
          <div className={`${style.flexGap5} ${style.mbt25}`}>
            <p>Khách hàng:</p>
            <p>{dataPrint?.custMeeting}</p>
          </div>
          <div className={`${style.flexGap5} ${style.mbt25}`}>
            <p>Địa chỉ:</p>
            {!isCheck ? (
              <p>{`${
                dataPrint?.additionalAddress &&
                dataPrint?.additionalAddress !== null
                  ? `${dataPrint?.additionalAddress} -`
                  : ""
              } ${
                dataPrint?.streetName && dataPrint?.streetName !== null
                  ? `${dataPrint?.streetName} -`
                  : ""
              } ${dataPrint?.communeName} - ${dataPrint?.districtName} - ${
                dataPrint?.provinceName
              }`}</p>
            ) : (
              <p>{dataPrint?.meetingAddress}</p>
            )}
          </div>
          <div className={`${style.flexGap5} ${style.mbt25}`}>
            <p>Nhân viên xuất trả:</p>
            <p>
              {JSON.parse(localStorage.getItem("userLogin") || "{}")?.fullName}
            </p>
          </div>
          <div className={`${style.mbt25} ${style.flexGap50}`}>
            <div className={`${style.flexGap5}`}>
              <p>Người nhận:</p>
              <p>{dataPrint?.custMeeting}</p>
            </div>
            <div className={`${style.flexGap5}`}>
              <p>Số điện thoại:</p>
              <p>{dataPrint?.custPhone}</p>
            </div>
          </div>
          <div className={`${style.mbt25} ${style.flexGap50}`}>
            <div className={`${style.flexGap5}`}>
              <p>Diễn giải:</p>
              <p>{dataPrint?.note}</p>
            </div>
          </div>
        </div>
        <div className={`${style.product} ${style.mt15}`}>
          <p className={`${style.italic} ${style.fw} ${style.mbt25}`}>
            Dưới đây là bảng kê chi tiết sản phẩm trả cho quý khách hàng:
          </p>
          {!isCheck ? (
            <table className={style.table}>
              <thead>
                <tr>
                  <th rowSpan={2}>STT</th>
                  <th colSpan={3}>Sản phẩm nhận bảo hành</th>
                  <th rowSpan={2}>Ngày mua</th>
                  <th rowSpan={2}>Mã phiếu nhập</th>
                  <th colSpan={3}>Sản phẩm trả bảo hành</th>
                  <th rowSpan={2}>Loại dịch vụ</th>
                  <th rowSpan={2}>Ghi chú</th>
                </tr>
                <tr>
                  <th>Tên sản phẩm</th>
                  <th>Serial</th>
                  <th>ĐVT</th>
                  <th>Tên sản phẩm</th>
                  <th>Serial</th>
                  <th>ĐVT</th>
                </tr>
              </thead>
              <tbody>
                {dataPrint?.tblWarrantyServiceRequestFormDetailModels
                  ?.filter((item: any) => item?.attribute10 === "TRA_KHACH")
                  ?.map((item: any, index: any) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.itemName}</td>
                      <td>{item.serial}</td>
                      <td>{item.uomName}</td>
                      <td>
                        {item.buyingDate !== null
                          ? `${String(
                              new Date(item?.buyingDate).getDate()
                            ).padStart(2, "0")}/${String(
                              new Date(item?.buyingDate).getMonth() + 1
                            ).padStart(2, "0")}/${new Date(
                              item?.buyingDate
                            ).getFullYear()}`
                          : "Khhông có ngày mua"}
                      </td>
                      <td>Không có</td>
                      <td>{item.itemName}</td>
                      <td>{item.serial}</td>
                      <td>{item.uomName}</td>
                      <td>{item.serviceType}</td>
                      <td>{item.note}</td>
                    </tr>
                  ))}
                <tr>
                  <td style={{ border: "none" }}>Tổng cộng</td>
                  <td
                    style={{ alignItems: "center", border: "none" }}
                    colSpan={3}
                  >
                    {
                      dataPrint?.tblWarrantyServiceRequestFormDetailModels?.filter(
                        (item: any) => item?.attribute10 === "TRA_KHACH"
                      )?.length
                    }
                  </td>
                  <td colSpan={2} style={{ border: "none" }}></td>
                  <td
                    style={{ alignItems: "center", border: "none" }}
                    colSpan={3}
                  >
                    {
                      dataPrint?.tblWarrantyServiceRequestFormDetailModels?.filter(
                        (item: any) => item?.attribute10 === "TRA_KHACH"
                      )?.length
                    }
                  </td>
                  <td colSpan={2} style={{ border: "none" }}></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className={style.table}>
              <thead>
                <tr>
                  <th rowSpan={2}>STT</th>
                  <th colSpan={3}>Sản phẩm nhận bảo hành</th>
                  <th rowSpan={2}>Ngày mua</th>
                  <th rowSpan={2}>Mã phiếu nhập</th>
                  <th colSpan={3}>Sản phẩm trả bảo hành</th>
                  <th rowSpan={2}>Loại dịch vụ</th>
                  <th rowSpan={2}>Ghi chú</th>
                </tr>
                <tr>
                  <th>Tên sản phẩm</th>
                  <th>Serial</th>
                  <th>ĐVT</th>
                  <th>Tên sản phẩm</th>
                  <th>Serial</th>
                  <th>ĐVT</th>
                </tr>
              </thead>
              <tbody>
                {dataPrint?.tblWarrantyReceiveTicketDetailModels
                  ?.filter((item: any) => item?.serialStatus === "Trả khách")
                  ?.map((item: any, index: any) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.itemName}</td>
                      <td>{item?.serial}</td>
                      <td>{item?.uomName}</td>
                      <td>
                        {String(new Date(item?.buyingDate).getDate()).padStart(
                          2,
                          "0"
                        )}
                        /
                        {String(
                          new Date(item?.buyingDate).getMonth() + 1
                        ).padStart(2, "0")}
                        /{new Date(item?.buyingDate).getFullYear()}
                      </td>
                      <td></td>
                      <td>{item?.itemName}</td>
                      <td>
                        {item.newSerial !== null
                          ? item?.newSerial
                          : item?.serial}
                      </td>
                      <td>{item?.uomName}</td>
                      <td>{item?.serviceType}</td>
                      <td>{item?.note}</td>
                    </tr>
                  ))}
                <tr>
                  <td style={{ border: "none" }}>Tổng cộng</td>
                  <td
                    style={{ alignItems: "center", border: "none" }}
                    colSpan={3}
                  >
                    {
                      dataPrint?.tblWarrantyReceiveTicketDetailModels?.filter(
                        (item: any) => item?.serialStatus === "Trả khách"
                      ).length
                    }
                  </td>
                  <td colSpan={2} style={{ border: "none" }}></td>
                  <td
                    style={{ alignItems: "center", border: "none" }}
                    colSpan={3}
                  >
                    {
                      dataPrint?.tblWarrantyReceiveTicketDetailModels?.filter(
                        (item: any) => item?.serialStatus === "Trả khách"
                      ).length
                    }
                  </td>
                  <td colSpan={2} style={{ border: "none" }}></td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className={`${style.mbt25} ${style.flexGap50}`}>
          <div className={`${style.flexGap5}`}>
            <p className={`${style.fw}`}>Lưu ý:</p>
            <p>
              Quý khách kiểm tra kỹ thông số, cấu hình, tình trạng, số lượng
              hàng hóa đầy đủ khi nhận lại hàng (linh kiện, phụ kiện đi kèm, đĩa
              cài, sách, vỏ hộp,... nếu có).
            </p>
          </div>
        </div>
      </div>
      <div className={style.signature}>
        <div>
          <p className={style.fw}>Trưởng bộ phận</p>
          <p>
            {"("}Ký & ghi rõ họ tên{")"}
          </p>
        </div>
        <div>
          <p className={style.fw}>Người lập phiếu</p>
          <p>
            {"("}Ký & ghi rõ họ tên{")"}
          </p>
        </div>
        <div>
          <p className={style.fw}>Thủ kho</p>
          <p>
            {"("}Ký & ghi rõ họ tên{")"}
          </p>
        </div>
        <div>
          <p className={style.fw}>Người nhận hàng</p>
          <p>
            {"("}Ký & ghi rõ họ tên{")"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrinterReturnCustomers;
